import { Measurement, VitalsHistory, VitalType, FullHistory, FullHistoryItem } from 'api/interfaces';
import { Nullable } from 'components-ts/utils';
import { useContext } from 'react';
import { PatientWithActiveVisitAndHistoryContextValue, PatientContext } from './PatientContext';

export interface PatientHistoryContextValue {
  patientHistory: Nullable<FullHistory>;
  getLastVital: (measurementType: VitalType) => Nullable<Measurement>;
  getAllergies: () => Nullable<Array<FullHistoryItem>>;
  isLoading: boolean;
  error: Nullable<string>;
  onErrorClose?: () => void;
  refetch: () => void;
}

export const usePatientHistoryContext = () => {
  const ctx = useContext(PatientContext);
  validateContextValue(ctx);

  const { data, isLoading, error, refetch } = ctx;
  const { patientHistory } = data;

  const getLastVital = (measurementType: VitalType) => {
    if (!patientHistory || !Array.isArray(patientHistory?.vitals)) return null;

    const allMeasurements = (data.patientHistory.vitals as Array<VitalsHistory>).reduce((measurements, current) => {
      return [...measurements, ...(current.vitals?.measurements ?? [])];
    }, [] as Array<Measurement>);

    return allMeasurements.find((measurement) => measurement.type === measurementType) ?? null;
  };

  const getAllergies = () => {
    return data?.patientHistory?.allergies ?? [];
  };

  const value = {
    patientHistory: data.patientHistory,
    getLastVital,
    getAllergies,
    isLoading,
    error,
    refetch,
  };

  return value;
};

function validateContextValue(
  value: Nullable<PatientWithActiveVisitAndHistoryContextValue>
): asserts value is PatientWithActiveVisitAndHistoryContextValue {
  if (value === null) {
    throw TypeError('Using patient history context out of the provider');
  }
}
