import { Nullable } from '../../components-ts/utils';

export interface Signature {
  given_name: string;
  family_name: string;
  user_id: string;
  timestamp: string;
}

export enum Sex {
  FEMALE = 'Female',
  MALE = 'Male',
}

export type PaginatedResult<T> = {
  docs: Array<T>;
  count: number;
};

export interface PaginatedVariables {
  q?: string;
  offset?: number;
  limit?: number;
}
export interface VoidResponse {
  done: Nullable<boolean>;
  updated: Nullable<Signature>;
}

export interface TranslatableText {
  type: string;
  text: Nullable<string>;
}

export interface FullReference {
  name: Nullable<TranslatableText>;
  link: Nullable<string>;
  image: Nullable<string>;
}

export interface FullComment {
  id: string;
  type: string;
  text: string;
  created: Signature;
  updated: Nullable<Signature>;
}

export interface SignedRequestFields {
  bucket: Nullable<string>;
  XAmzAlgorithm: Nullable<string>;
  XAmzCredential: Nullable<string>;
  XAmzDate: Nullable<string>;
  XAmzSignature: Nullable<string>;
  XAmzSecurityToken: Nullable<string>;
  key: Nullable<string>;
  Policy: Nullable<string>;
  ACL: Nullable<string>;
}
export interface SignedRequest {
  url: string;
  field: Nullable<SignedRequestFields>;
}
