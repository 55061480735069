import { gql } from '@apollo/client';
import {
  BasicPatient,
  FullBulkRegistrationHistory,
  FullPatientWithActiveVisit,
  FullAddress,
  FullWeight,
  FullVisitHistory,
  WaitingPatient,
} from '../../utils/GQLFragments';

export const GET_PATIENTS = gql`
  ${BasicPatient}
  ${FullAddress}
  query patients($q: String, $offset: Int, $limit: Int, $location: [String]) {
    patients(q: $q, offset: $offset, limit: $limit, location: $location) {
      docs {
        ...BasicPatient
      }
      count
    }
  }
`;
export const GET_INPATIENTS = gql`
  ${WaitingPatient}
  query inpatients($q: String, $offset: Int, $limit: Int, $location: String, $visitType: String) {
    inpatients(q: $q, offset: $offset, limit: $limit, location: $location, visitType: $visitType) {
      docs {
        ...WaitingPatient
      }
      count
    }
  }
`;

export const GET_ALL_PATIENT_DATA = gql`
  ${FullPatientWithActiveVisit}
  ${FullVisitHistory}
  query patientWithActiveVisitAndHistory($id: String!) {
    patient(id: $id) {
      ...FullPatientWithActiveVisit
    }
    patientHistory(patientId: $id) {
      ...FullVisitHistory
    }
  }
`;

export const REGISTER_PATIENT = gql`
  ${FullPatientWithActiveVisit}
  mutation registerPatient($newPatient: NewPatientPersonalInfo!) {
    registerPatient(newPatient: $newPatient) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_PERSONAL_INFO = gql`
  ${FullPatientWithActiveVisit}
  mutation updatePatientPersonalInfo($id: ID!, $newPersonalInfo: NewPatientPersonalInfo!) {
    updatePatientPersonalInfo(id: $id, newPersonalInfo: $newPersonalInfo) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_PATIENT_BPJS_SETTINGS = gql`
  ${FullPatientWithActiveVisit}
  mutation updatePatientBpjsSettings($patientId: ID!, $bpjsSettings: NewPatientBpjsSettings!) {
    updatePatientBpjsSettings(patientId: $patientId, bpjsSettings: $bpjsSettings) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const DELETE_PATIENT = gql`
  mutation deletePatient($id: ID!) {
    deletePatient(id: $id) {
      done
    }
  }
`;

export const CLEAN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation cleanActiveVisit($patientId: ID!) {
    cleanActiveVisit(patientId: $patientId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_CUSTOM_FIELDS = gql`
  ${FullPatientWithActiveVisit}
  mutation updateCustomFields(
    $patientId: ID!
    $newCustomFieldBoolean: [NewCustomFieldBooleanWithValue]!
    $newCustomFieldNumber: [NewCustomFieldNumberWithValue]!
    $newCustomFieldText: [NewCustomFieldTextWithValue]!
    $newCustomFieldDate: [NewCustomFieldDateWithValue]!
    $newCustomFieldTextList: [NewCustomFieldTextListWithValue]!
  ) {
    updatePatientCustomFields(
      patientId: $patientId
      newCustomFieldBoolean: $newCustomFieldBoolean
      newCustomFieldNumber: $newCustomFieldNumber
      newCustomFieldText: $newCustomFieldText
      newCustomFieldDate: $newCustomFieldDate
      newCustomFieldTextList: $newCustomFieldTextList
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACT = gql`
  ${FullPatientWithActiveVisit}
  mutation updatePatientEmergencyContact($id: ID!, $newEmergencyContact: NewEmergencyContact!) {
    updatePatientEmergencyContact(id: $id, newEmergencyContact: $newEmergencyContact) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const UPDATE_PAYMENT_INFO = gql`
  ${FullPatientWithActiveVisit}
  mutation updatePatientPaymentInfo($id: ID!, $newPatientPaymentInfo: NewPatientPaymentInfo!) {
    updatePatientPaymentInfo(id: $id, newPatientPaymentInfo: $newPatientPaymentInfo) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const BULK_REGISTRATION_HISTORY = gql`
  ${FullBulkRegistrationHistory}
  query bulkRegistrationHistory($systemId: String!, $locationId: String!) {
    bulkRegistrationHistory(systemId: $systemId, locationId: $locationId) {
      ...FullBulkRegistrationHistory
    }
  }
`;

export const BULK_REGISTRATION = gql`
  mutation bulkRegistration(
    $patients: [NewPatientPersonalInfo!]!
    $systemId: String!
    $locationId: String!
    $bulkId: String
  ) {
    bulkRegistration(patients: $patients, systemId: $systemId, locationId: $locationId, bulkId: $bulkId) {
      done
    }
  }
`;

export const BULK_DELETE = gql`
  mutation bulkDelete($bulkId: String!) {
    bulkDelete(bulkId: $bulkId) {
      done
    }
  }
`;

export const GET_PATIENT_LAST_WEIGHT = gql`
  query getLastWeight($id: String!) {
    getLastWeight(id: $id) {
      ...FullWeight
    }
  }
  ${FullWeight}
`;
