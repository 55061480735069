import { QueryHookOptions, useQuery } from '@apollo/client';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { AVAILABLE_SLOTS } from 'scenes/ScheduledVisits/requests';
import { useMySystemSettings } from '../HealthSystems';
import { AvailableSlot, getDurationInMinutes, getSlotDurationFromSettings, normalizeSlots } from './utils';
import { endOfDay, startOfDay } from 'components-ts/DateAndTime';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

export interface AvailableSlotsVariables {
  room: string;
  from: string;
  to: string;
  ignoreVisit?: string;
}

interface AvailableSlotsData {
  availableSlots: Array<AvailableSlot>;
}

export type UseAvailableSlotsParams = QueryHookOptions<AvailableSlotsData, AvailableSlotsVariables> & {
  roomId: string;
  date: string;
  ignoreVisit?: string;
};

export const useAvailableSlots = (params: UseAvailableSlotsParams) => {
  const { date, roomId, ignoreVisit, ...rest } = params;

  const { scheduler } = useMySystemSettings();

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors);
    translateError(firstError);

    if (typeof rest?.onError === 'function') {
      rest.onError(errors);
    }
  };

  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery<AvailableSlotsData, AvailableSlotsVariables>(AVAILABLE_SLOTS, {
    ...rest,
    variables: {
      room: roomId,
      from: startOfDay(new Date(date)).toISOString(),
      to: endOfDay(new Date(date)).toISOString(),
      ignoreVisit,
    },
    skip: !date || !roomId,
    fetchPolicy: 'no-cache',
    onError,
  });

  const onErrorClose = () => {
    setError(null);
  };

  /**
   * generate all the possible times using the slot config and the backend response
   */
  const slotDuration = getSlotDurationFromSettings(scheduler);
  const slotDurationInMinutes = getDurationInMinutes(slotDuration);
  const normalizedSlots = normalizeSlots(data?.availableSlots ?? [], slotDurationInMinutes);

  /**
   * Our datetime picker needs the slot duration for time selection
   * If we export the duration object, we are spreading luxon for everywhere
   * as we did with moment in the past. So, using minutes is enough now
   */
  return {
    isLoading,
    normalizedSlots,
    availableSlots: data?.availableSlots ?? [],
    slotDurationInMinutes,
    error,
    refetch,
    onErrorClose,
  };
};
