import React from 'react';

interface ReferenceLinkProps {
  url: string;
  className?: string;
}

export const ReferenceLink: React.FC<ReferenceLinkProps> = (props) => {
  const className = `d-flex align-items-center my-1 ${props.className ?? ''}`;
  return (
    <a href={props.url} rel="noopener noreferrer" target="_blank" className={className}>
      {props.children}
    </a>
  );
};
