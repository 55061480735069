import { gql } from '@apollo/client';
import { FullPatientWithActiveVisit } from '../../utils/GQLFragments';

export const CANCEL_ORDER = gql`
  ${FullPatientWithActiveVisit}
  mutation cancelOrder($patientId: ID!, $visitId: ID!, $orderId: ID!) {
    cancelOrder(patientId: $patientId, visitId: $visitId, orderId: $orderId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ACKNOWLEDGE_ORDER = gql`
  ${FullPatientWithActiveVisit}
  mutation acknowledgeOrder($patientId: ID!, $visitId: ID!, $orderId: ID!) {
    acknowledgeOrder(patientId: $patientId, visitId: $visitId, orderId: $orderId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADMINISTER_ORDER = gql`
  ${FullPatientWithActiveVisit}
  mutation administerOrder($patientId: ID!, $visitId: ID!, $orderId: ID!) {
    administerOrder(patientId: $patientId, visitId: $visitId, orderId: $orderId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const RECEIVE_ORDER = gql`
  ${FullPatientWithActiveVisit}
  mutation receiveOrder($patientId: ID!, $visitId: ID!, $orderId: ID!) {
    receiveOrder(patientId: $patientId, visitId: $visitId, orderId: $orderId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_COMMENT_TO_ORDER = gql`
  ${FullPatientWithActiveVisit}
  mutation addCommentToOrder($patientId: ID!, $visitId: ID!, $orderId: ID!, $newComment: String!) {
    addCommentToOrder(patientId: $patientId, visitId: $visitId, orderId: $orderId, newComment: $newComment) {
      ...FullPatientWithActiveVisit
    }
  }
`;
