import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { useDoctors } from 'hooks';
import { Nullable } from 'components-ts/utils';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from '.';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  doctor: {
    id: 'UI.doctor_label',
    defaultMessage: 'Doctor',
  },
});

interface Doctor {
  id: string;
  family_name: string;
  given_name: string;
}

type DoctorSelectorProps = SelectorProps<string> & {
  location: Array<string>;
  className?: string;
};

const formatOption = (doctor: Doctor): ReactSelectInternalSingleValue => {
  const { id, family_name, given_name } = doctor;

  return {
    label: `${given_name} ${family_name}`,
    value: id,
  };
};

export const DoctorSelector: React.FC<DoctorSelectorProps> = props => {
  const { value, location, isClearable, isDisabled, className } = props;

  const intl = useIntl();

  const { doctors, loading, getDoctor } = useDoctors(
    { location },
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const options = React.useMemo(() => {
    if (Array.isArray(doctors)) {
      return doctors.map(value => formatOption(value as Doctor));
    }

    return null;
  }, [doctors]);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return null;
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, doctorId: string) => {
        const doctor = getDoctor(doctorId);

        if (doctor) {
          return [...prev, formatOption(doctor)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      const doctor = getDoctor(value as string);

      if (doctor) {
        return formatOption(doctor);
      }

      return null;
    }
  };

  const _onChange = (v: ReactSelectInternalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(v)) {
        return props.onChange([]);
      }

      const selectedValues = v as Array<ReactSelectInternalSingleValue>;
      const newValues = selectedValues.map(item => item.value);

      props.onChange(newValues);
    } else {
      const selectedValue = v as ReactSelectInternalSingleValue;

      props.onChange(selectedValue.value);
    }
  };

  return (
    <FormGroup>
      <Label>
        <T id={messages.doctor.id}>{intl.formatMessage(messages.doctor)}</T>
      </Label>
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isLoading={loading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    </FormGroup>
  );
};
