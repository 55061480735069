export const CUSTOM_FIELD_TYPE = Object.freeze({
  PRIMITIVE: 'PrimitiveCustomField',
  NON_PRIMITIVE: 'NonPrimitiveCustomField'
})

export const CUSTOM_FIELD_PRIMITIVE_TYPE = Object.freeze({
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean'
})

export const CUSTOM_FIELD_NON_PRIMITIVE_TYPE = Object.freeze({
  TEXT_LIST: 'textList'
})
