import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'hooks';

enum Filter {
  Q = 'q',
  LIMIT = 'limit',
  OFFSET = 'offset',
}

export enum URLPaginationParams {
  Q = 'q',
  PAGE = 'page',
}

interface PaginationFilter {
  [Filter.Q]: string;
  [Filter.OFFSET]: number;
  [Filter.LIMIT]: number;
}

interface UsePaginationFilter {
  filter: PaginationFilter;
  itemsPerPage: number;
  initialPage: number;
  initialQ: string;
  onSearch: (qstring: string) => void;
  onPageChange: (offset: number) => void;
  queryParams: URLSearchParams;
}

const ITEMS_PER_PAGE = 10;

/**
 * URL synchronized filter for pagination
 */
export const usePaginationFilter = (): UsePaginationFilter => {
  const queryParams = useQueryParams();
  const history = useHistory();

  const fromURL = {
    q: queryParams.get(URLPaginationParams.Q),
    page: parseInt(queryParams.get(URLPaginationParams.PAGE) ?? ''),
  };

  const initialQ = fromURL.q ?? '';
  const initialPage = isNaN(fromURL.page) ? 0 : fromURL.page;

  const [filter, setFilter] = React.useState<PaginationFilter>({
    q: initialQ,
    offset: initialPage * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE,
  });

  const onQChange = (q: string) => {
    if (q === filter.q) return;

    setFilter((prev) => ({
      ...prev,
      q,
      offset: 0,
    }));

    if (q.length > 0) {
      queryParams.set(URLPaginationParams.Q, q);
    } else {
      queryParams.delete(URLPaginationParams.Q);
    }

    /**
     * These ones depend on the selected location
     */
    queryParams.set(URLPaginationParams.PAGE, '0');

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onOffsetChange = (page: number) => {
    const offset = page * ITEMS_PER_PAGE;

    setFilter((prev) => ({
      ...prev,
      offset,
    }));

    queryParams.set(URLPaginationParams.PAGE, page.toString());

    history.replace({
      search: queryParams.toString(),
    });
  };

  return {
    filter,
    initialQ,
    initialPage,
    onSearch: onQChange,
    onPageChange: onOffsetChange,
    itemsPerPage: ITEMS_PER_PAGE,
    queryParams,
  };
};
