import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// error log
import { init as initSentry, configureScope as configureSentryScope } from '@sentry/browser';

import App from './App';
import './index.scss';

// from window
const { release, releaseType, region, role, sentryDsn, sentrySampleRate } = window.serverConfig;

// Error logging support through sentry
if (sentryDsn) {
  // sentry initialization
  initSentry({
    dsn: sentryDsn,
    environment: releaseType,
    release,
    sampleRate: parseFloat(sentrySampleRate) || 1.0,
  });
  // add tags
  configureSentryScope(scope => {
    scope.setTag({ region, role });
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
