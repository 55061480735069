import React from 'react';
import { DoseUnit as DoseUnitEnum } from 'api/interfaces';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';

const messages: Record<DoseUnitEnum, MessageDescriptor> = defineMessages({
  [DoseUnitEnum.APPLICATION]: {
    id: `DoseUnits.${DoseUnitEnum.APPLICATION}`,
    defaultMessage: DoseUnitEnum.APPLICATION,
  },
  [DoseUnitEnum.MG_KG]: {
    id: `DoseUnits.${DoseUnitEnum.MG_KG}`,
    defaultMessage: DoseUnitEnum.MG_KG,
  },
  [DoseUnitEnum.G_KG]: {
    id: `DoseUnits.${DoseUnitEnum.G_KG}`,
    defaultMessage: DoseUnitEnum.G_KG,
  },
  [DoseUnitEnum.ML_KG]: {
    id: `DoseUnits.${DoseUnitEnum.ML_KG}`,
    defaultMessage: DoseUnitEnum.ML_KG,
  },
  [DoseUnitEnum.MCG_KG]: {
    id: `DoseUnits.${DoseUnitEnum.MCG_KG}`,
    defaultMessage: DoseUnitEnum.MCG_KG,
  },
  [DoseUnitEnum.UNITS_KG]: {
    id: `DoseUnits.${DoseUnitEnum.UNITS_KG}`,
    defaultMessage: DoseUnitEnum.UNITS_KG,
  },
  [DoseUnitEnum.UNITS_KG_HR]: {
    id: `DoseUnits.${DoseUnitEnum.UNITS_KG_HR}`,
    defaultMessage: DoseUnitEnum.UNITS_KG_HR,
  },
  [DoseUnitEnum.MCG_KG_MIN]: {
    id: `DoseUnits.${DoseUnitEnum.MCG_KG_MIN}`,
    defaultMessage: DoseUnitEnum.MCG_KG_MIN,
  },
  [DoseUnitEnum.MCG_MIN]: {
    id: `DoseUnits.${DoseUnitEnum.MCG_MIN}`,
    defaultMessage: DoseUnitEnum.MCG_MIN,
  },
  [DoseUnitEnum.MG_MIN]: {
    id: `DoseUnits.${DoseUnitEnum.MG_MIN}`,
    defaultMessage: DoseUnitEnum.MG_MIN,
  },
  [DoseUnitEnum.MG_HR]: {
    id: `DoseUnits.${DoseUnitEnum.MG_HR}`,
    defaultMessage: DoseUnitEnum.MG_HR,
  },
  [DoseUnitEnum.MG_KG_HR]: {
    id: `DoseUnits.${DoseUnitEnum.MG_KG_HR}`,
    defaultMessage: DoseUnitEnum.MG_KG_HR,
  },
  [DoseUnitEnum.MG]: {
    id: `DoseUnits.${DoseUnitEnum.MG}`,
    defaultMessage: DoseUnitEnum.MG,
  },
  [DoseUnitEnum.ML]: {
    id: `DoseUnits.${DoseUnitEnum.ML}`,
    defaultMessage: DoseUnitEnum.ML,
  },
  [DoseUnitEnum.L]: {
    id: `DoseUnits.${DoseUnitEnum.L}`,
    defaultMessage: DoseUnitEnum.L,
  },
  [DoseUnitEnum.MEQ]: {
    id: `DoseUnits.${DoseUnitEnum.MEQ}`,
    defaultMessage: DoseUnitEnum.MEQ,
  },
  [DoseUnitEnum.MEQ_KG]: {
    id: `DoseUnits.${DoseUnitEnum.MEQ_KG}`,
    defaultMessage: DoseUnitEnum.MEQ_KG,
  },
  [DoseUnitEnum.JOULES_KG]: {
    id: `DoseUnits.${DoseUnitEnum.JOULES_KG}`,
    defaultMessage: DoseUnitEnum.JOULES_KG,
  },
  [DoseUnitEnum.JOULES]: {
    id: `DoseUnits.${DoseUnitEnum.JOULES}`,
    defaultMessage: DoseUnitEnum.JOULES,
  },
  [DoseUnitEnum.MCG]: {
    id: `DoseUnits.${DoseUnitEnum.MCG}`,
    defaultMessage: DoseUnitEnum.MCG,
  },
  [DoseUnitEnum.G]: {
    id: `DoseUnits.${DoseUnitEnum.G}`,
    defaultMessage: DoseUnitEnum.G,
  },
  [DoseUnitEnum.GRAMS]: {
    id: `DoseUnits.${DoseUnitEnum.GRAMS}`,
    defaultMessage: DoseUnitEnum.GRAMS,
  },
  [DoseUnitEnum.DROPS]: {
    id: `DoseUnits.${DoseUnitEnum.DROPS}`,
    defaultMessage: DoseUnitEnum.DROPS,
  },
  [DoseUnitEnum.PERCENT]: {
    id: `DoseUnits.${DoseUnitEnum.PERCENT}`,
    defaultMessage: DoseUnitEnum.PERCENT,
  },
  [DoseUnitEnum.TABLETS]: {
    id: `DoseUnits.${DoseUnitEnum.TABLETS}`,
    defaultMessage: DoseUnitEnum.TABLETS,
  },
  [DoseUnitEnum.SPRAYS]: {
    id: `DoseUnits.${DoseUnitEnum.SPRAYS}`,
    defaultMessage: DoseUnitEnum.SPRAYS,
  },
  [DoseUnitEnum.DAB]: {
    id: `DoseUnits.${DoseUnitEnum.DAB}`,
    defaultMessage: DoseUnitEnum.DAB,
  },
  [DoseUnitEnum.OTHER]: {
    id: `DoseUnits.${DoseUnitEnum.OTHER}`,
    defaultMessage: DoseUnitEnum.OTHER,
  },
  [DoseUnitEnum.UNITS]: {
    id: `DoseUnits.${DoseUnitEnum.UNITS}`,
    defaultMessage: DoseUnitEnum.UNITS,
  },
});

type DoseUnitProps = {
  doseUnit: string;
  className?: string;
};

export const DoseUnit: React.FC<DoseUnitProps> = (props) => {
  const { doseUnit, className } = props;
  const intl = useIntl();

  const isSystemDoseUnit = Object.values<string>(DoseUnitEnum).includes(doseUnit);

  if (isSystemDoseUnit) {
    return (
      <T id={messages[doseUnit].id} className={className}>
        {intl.formatMessage(messages[doseUnit])}
      </T>
    );
  }

  return <span className={className}>{doseUnit}</span>;
};
