import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { Nullable } from 'components-ts/utils';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from '.';
import { useIntl, defineMessages } from 'react-intl';
import { usePatients } from 'components-ts/Patients';
import { useSession } from 'components-ts/Auth';
import { BasicPatient } from 'api/interfaces';

const messages = defineMessages({
  patient: {
    id: 'PatientSelector.patient_label',
    defaultMessage: 'Patient',
  },
});

type PatientSelectorProps = SelectorProps<string> & {
  className?: string;
};

const formatOption = (patient: BasicPatient): ReactSelectInternalSingleValue => {
  const { id, last_name, first_name } = patient;

  return {
    label: `${first_name} ${last_name}`,
    value: id,
  };
};

export const PatientSelector: React.FC<PatientSelectorProps> = props => {
  const { value, isClearable, isDisabled, className } = props;

  const intl = useIntl();

  const { getUserLocation } = useSession();
  const { id: location } = getUserLocation();

  const { patients, isLoading, getPatient } = usePatients({
    location: [location],
  });

  const options = React.useMemo(() => {
    if (Array.isArray(patients)) {
      return patients.map(value => formatOption(value as BasicPatient));
    }

    return null;
  }, [patients]);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return null;
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, patientId: string) => {
        const patient = getPatient(patientId);

        if (patient) {
          return [...prev, formatOption(patient)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      const patient = getPatient(value as string);

      if (patient) {
        return formatOption(patient);
      }

      return null;
    }
  };

  const _onChange = (v: Nullable<ReactSelectInternalValue>): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(v)) {
        return props.onChange([]);
      }

      const newValues = v.map(item => item.value);

      props.onChange(newValues);
    } else {
      if (v === null) {
        return props.onChange('');
      }

      props.onChange((v as ReactSelectInternalSingleValue).value);
    }
  };

  return (
    <FormGroup>
      <Label>
        <T id={messages.patient.id}>{intl.formatMessage(messages.patient)}</T>
      </Label>
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    </FormGroup>
  );
};
