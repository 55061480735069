import React from 'react';
import Select from 'react-select';
import { FormGroup, Label, FormText } from 'reactstrap';
import { useSession } from 'hooks';
import { useSystems } from 'components-ts/HealthSystems';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from '.';
import { DefaultSystemOrLocationAttr } from 'components-ts/Auth/utils';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { Location } from 'api/interfaces';

const messages = defineMessages({
  location: {
    id: 'LocationSelector.location_label',
    defaultMessage: 'Facility',
  },
});

type LocationSelectorProps = SelectorProps<string> & {
  systemId?: string;
  helper?: MessageDescriptor;
  className?: string;
  filter?: (location: Location, index?: number, items?: Array<Location>) => boolean;
  menuPlacement?: string;
};

const formatOption = (location: DefaultSystemOrLocationAttr): ReactSelectInternalSingleValue => {
  const { id, name } = location;

  return {
    value: id,
    label: name,
  };
};

export const LocationSelector: React.FC<LocationSelectorProps> = (props) => {
  const { value, filter, isClearable, isDisabled, helper, className, menuPlacement } = props;

  const intl = useIntl();

  const { getUserSystem } = useSession() as any;
  const { isLoading, getSystem, getLocation } = useSystems();

  const systemId = props.systemId || getUserSystem().id;
  const options = React.useMemo(() => {
    const system = getSystem(systemId);

    if (system && Array.isArray(system.locations)) {
      if (filter) {
        return system.locations.filter(filter).map(formatOption);
      }

      return system.locations.map(formatOption);
    }

    return [];
  }, [systemId, getSystem, filter]);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): any => {
    if (options === null) {
      return {};
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return {};
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, locationId: string) => {
        const location = getLocation(systemId, locationId);

        if (location) {
          return [...prev, formatOption(location)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      const location = getLocation(systemId, value as string);

      if (location) {
        return formatOption(location);
      }

      return {};
    }
  };

  const _onChange = (v: ReactSelectInternalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(v)) {
        return props.onChange([]);
      }

      const selectedValues = v as Array<ReactSelectInternalSingleValue>;
      const newValues = selectedValues.map((item) => item.value);

      props.onChange(newValues);
    } else {
      if (!v) {
        return props.onChange('');
      }

      props.onChange((v as ReactSelectInternalSingleValue).value);
    }
  };

  return (
    <FormGroup>
      <Label>
        <T id={messages.location.id}>{intl.formatMessage(messages.location)}</T>
      </Label>
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
        menuPlacement={menuPlacement}
        styles={{ option: (provided) => (isDisabled ? provided : { ...provided, color: 'black' }) }}
      />
      {helper && (
        <FormText className="text-muted">
          <T id={helper.id}>{intl.formatMessage(helper)}</T>
        </FormText>
      )}
    </FormGroup>
  );
};
