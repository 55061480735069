import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Button, InputGroup, InputGroupAddon, Label, FormGroup, Input } from 'reactstrap';
import { FrequencyShow } from 'api/interfaces';

const debug = require('debug')('wd:FrequencySelector');

const messages = defineMessages({
  frequency: {
    id: 'FrequencySelector.label_frequency',
    defaultMessage: 'Frequency',
  },
  dosesPerDay: {
    id: 'FrequencySelector.label_doses_per_day',
    defaultMessage: 'Doses per day',
  },
  doses: {
    id: 'FrequencySelector.label_doses',
    defaultMessage: 'Doses',
  },
  perDayAddon: {
    id: 'FrequencySelector.label_per_day',
    defaultMessage: 'per day',
  },
  dosesAddon: {
    id: 'FrequencySelector.label_doses_addon',
    defaultMessage: 'doses',
  },
  everyAddon: {
    id: 'FrequencySelector.label_every',
    defaultMessage: 'Every',
  },
  hoursAddon: {
    id: 'FrequencySelector.label_hours',
    defaultMessage: 'hours',
  },
  asNeeded: {
    id: 'FrequencySelector.label_as_needed',
    defaultMessage: 'As needed',
  },
});

interface FrequencySelectorProps {
  frequencyShow: FrequencyShow;
  dosesPerDay: number;
  asNeeded?: boolean;
  className?: string;
  isDisabled?: boolean;
  onFrequencyShowChange?: (value: number) => void;
  onDosesPerDayChange?: (value: number) => void;
  onAsNeededChange?: (value: boolean) => void;
}
export const FrequencySelector: React.FC<FrequencySelectorProps> = (props) => {
  const { frequencyShow, dosesPerDay, asNeeded, className, isDisabled } = props;

  const intl = useIntl();

  const onFrequencyShowChange = (e) => {
    const { value } = e.target;
    if (typeof props.onFrequencyShowChange === 'function') props.onFrequencyShowChange(value);
  };

  const onDosesPerDayChange = (e) => {
    const { value } = e.target;

    const dosesPerDay = frequencyShow === FrequencyShow.FREQUENCY && value > 0 ? 24 / value : value;

    if (typeof props.onDosesPerDayChange === 'function') props.onDosesPerDayChange(dosesPerDay);
  };

  const onAsNeededChange = (e) => {
    const { checked } = e.target;
    if (typeof props.onAsNeededChange === 'function') props.onAsNeededChange(checked);
  };

  const parseFrequency = (dosesPerDay) => {
    if (dosesPerDay === 0) return 0;

    const period =
      Math.abs(24 / dosesPerDay) < 1 ? Number(Math.abs(24 / dosesPerDay).toFixed(3)) : Math.abs(24 / dosesPerDay);

    return isNaN(period) ? 0 : period;
  };

  const shownDosesPerDay = frequencyShow === FrequencyShow.FREQUENCY ? parseFrequency(dosesPerDay) : dosesPerDay;
  debug({ frequencyShow, dosesPerDay, asNeeded });

  return (
    <FormGroup>
      <Label className="d-flex">
        <InputGroup className="d-flex align-items-center justify-content-around">
          <Label check className="mx-1">
            <Input
              type="radio"
              name="frequency"
              defaultChecked={frequencyShow === FrequencyShow.FREQUENCY}
              value={FrequencyShow.FREQUENCY}
              onChange={onFrequencyShowChange}
              disabled={isDisabled}
            />
            <T id={messages.frequency.id}>{intl.formatMessage(messages.frequency)}</T>
          </Label>
          <Label check className="mx-1">
            <Input
              type="radio"
              name="frequency"
              defaultChecked={frequencyShow === FrequencyShow.DOSES_PER_DAY}
              value={FrequencyShow.DOSES_PER_DAY}
              onChange={onFrequencyShowChange}
              disabled={isDisabled}
            />
            <T id={messages.dosesPerDay.id}>{intl.formatMessage(messages.dosesPerDay)}</T>
          </Label>
          <Label check className="mx-1">
            <Input
              type="radio"
              name="frequency"
              defaultChecked={frequencyShow === FrequencyShow.DOSES}
              value={FrequencyShow.DOSES}
              onChange={onFrequencyShowChange}
              disabled={isDisabled}
            />
            <T id={messages.doses.id}>{intl.formatMessage(messages.doses)}</T>
          </Label>
        </InputGroup>
      </Label>

      {frequencyShow === FrequencyShow.DOSES_PER_DAY || frequencyShow === FrequencyShow.DOSES ? (
        <InputGroup>
          <Input
            type="number"
            value={shownDosesPerDay}
            min={0}
            step={0.1}
            onChange={onDosesPerDayChange}
            className={` ${className}`}
            disabled={isDisabled}
          />
          <InputGroupAddon addonType="append">
            <Button disabled>
              {frequencyShow === FrequencyShow.DOSES_PER_DAY ? (
                <T id={messages.perDayAddon.id}>{intl.formatMessage(messages.perDayAddon)}</T>
              ) : (
                <T id={messages.dosesAddon.id}>{intl.formatMessage(messages.dosesAddon)}</T>
              )}
            </Button>
          </InputGroupAddon>
        </InputGroup>
      ) : (
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <Button disabled>
              <T id={messages.everyAddon.id}>{intl.formatMessage(messages.everyAddon)}</T>
            </Button>
          </InputGroupAddon>
          <Input
            type="number"
            value={shownDosesPerDay}
            min={0}
            step={1}
            onChange={onDosesPerDayChange}
            className={` ${className}`}
            disabled={isDisabled}
          />
          <InputGroupAddon addonType="append">
            <Button disabled>
              <T id={messages.hoursAddon.id}>{intl.formatMessage(messages.hoursAddon)}</T>
            </Button>
          </InputGroupAddon>
        </InputGroup>
      )}

      <Label check className="d-flex mt-1 ml-3 pointer">
        <Input type="checkbox" checked={asNeeded} onChange={onAsNeededChange} disabled={isDisabled} />
        <T id={messages.asNeeded.id}>{intl.formatMessage(messages.asNeeded)}</T>
      </Label>
    </FormGroup>
  );
};
