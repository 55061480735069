import { useMySystemSettings } from 'components-ts/HealthSystems'
import {
  CUSTOM_FIELD_TYPE,
  CUSTOM_FIELD_PRIMITIVE_TYPE,
  CUSTOM_FIELD_NON_PRIMITIVE_TYPE
} from 'wd-common/src/logic/health-systems/definitions'

function useCustomFields () {
  const { registration } = useMySystemSettings()

  const customFields = registration.fields || []
  /**
   * Return a custom field by id.
   * @param {String} id Custom field id
   */

  const getField = (id) => {
    return customFields.find((customField) => customField.id === id)
  }

  const getUIType = (customField) => {
    const typeName = (customField || {}).__typename

    switch (typeName) {
      case CUSTOM_FIELD_TYPE.PRIMITIVE:
        return customField.type

      case 'TextListCustomField':
        return CUSTOM_FIELD_NON_PRIMITIVE_TYPE.TEXT_LIST

      default:
        return null
    }
  }

  const parseValue = (value, customFieldId) => {
    const customField = getField(customFieldId)
    const UIType = getUIType(customField)

    if (UIType === CUSTOM_FIELD_PRIMITIVE_TYPE.NUMBER) return parseFloat(value)

    return value
  }

  return {
    customFields,
    getField,
    parseValue,
    getUIType
  }
}
export default useCustomFields
