import { gql } from '@apollo/client';
import { FullTranslatableText, UserSignature } from './common';

export const FullXenditIntegrationSettings = gql`
  fragment FullXenditIntegrationSettings on XenditIntegrationSettings {
    isEnabled
  }
`;

export const FullRoomEvent = gql`
  fragment FullRoomEvent on RoomEvent {
    id
    name
    description
    recurrence
    start
    end
    allDay
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullRoom = gql`
  fragment FullRoom on Room {
    id
    name
    description
    available
    events {
      ...FullRoomEvent
    }
  }
  ${FullRoomEvent}
  ${UserSignature}
`;

export const FullItemSubscriptions = gql`
  fragment FullItemSubscriptions on ItemSubscriptions {
    items
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullTagSubscriptions = gql`
  fragment FullTagSubscriptions on TagSubscriptions {
    tags {
      ...FullTranslatableText
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
  ${FullTranslatableText}
`;

export const FullSubscriptionSettings = gql`
  fragment FullSubscriptionSettings on SubscriptionSettings {
    lab {
      ...FullItemSubscriptions
    }
    procedures {
      ...FullItemSubscriptions
    }
    triage {
      ...FullItemSubscriptions
    }
    checklists {
      ...FullItemSubscriptions
    }
    visitTypes {
      ...FullItemSubscriptions
    }
  }
  ${FullItemSubscriptions}
`;

export const FullPaymentSettings = gql`
  fragment FullPaymentSettings on PaymentSettings {
    defaultPricelist
    currency
    xenditSubaccount
  }
`;

export const FullSlot = gql`
  fragment FullSlot on Slot {
    length
    unit
  }
`;

export const FullBusinessHour = gql`
  fragment FullBusinessHour on BusinessHours {
    daysOfWeek
    startTime
    endTime
  }
`;

export const FullSchedulerSettings = gql`
  fragment FullSchedulerSettings on SchedulerSettings {
    slot {
      ...FullSlot
    }
    notifications
    businessHours {
      ...FullBusinessHour
    }
  }
  ${FullSlot}
  ${FullBusinessHour}
`;

export const FullLocationBPJSSettings = gql`
  fragment FullLocationBPJSSettings on LocationBPJSSettings {
    polyclinicId
    credentials {
      cons_id
      secret
      userkey
      username
      password
    }
  }
`;

export const FullLocationIntegrationsSettings = gql`
  fragment FullLocationIntegrationsSettings on LocationIntegrationsSettings {
    bpjs {
      ...FullLocationBPJSSettings
    }
  }
  ${FullLocationBPJSSettings}
`;

export const FullLocationSettings = gql`
  fragment FullLocationSettings on LocationSettings {
    payment {
      ...FullPaymentSettings
    }
    scheduler {
      ...FullSchedulerSettings
    }
    integrations {
      ...FullLocationIntegrationsSettings
    }
  }
  ${FullPaymentSettings}
  ${FullSchedulerSettings}
  ${FullLocationIntegrationsSettings}
`;

export const FullLocation = gql`
  fragment FullLocation on Location {
    id
    name
    address
    phoneNumber
    mainContact

    settings {
      ...FullLocationSettings
    }

    subscriptions {
      ...FullSubscriptionSettings
    }

    rooms {
      ...FullRoom
    }
    updated {
      ...UserSignature
    }
  }
  ${FullRoom}
  ${UserSignature}
  ${FullLocationSettings}
  ${FullSubscriptionSettings}
`;

export const BasicHealthSystem = gql`
  fragment BasicSystem on System {
    id
    name
    country
    locale
    timezone
    logoFileId
    firstStepsDone
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
    deleted {
      ...UserSignature
    }
  }
`;

export const FullRegistrationGroup = gql`
  fragment FullRegistrationGroup on RegistrationGroup {
    id
    name
    customFields
  }
`;

export const FullRegistrationSettings = gql`
  fragment FullRegistrationSettings on RegistrationSettings {
    groups {
      ...FullRegistrationGroup
    }
    fields {
      ... on PrimitiveCustomField {
        id
        name
        type
        required
      }
      ... on TextListCustomField {
        id
        name
        options
        required
      }
    }
  }
  ${FullRegistrationGroup}
`;

export const FullPharmacySettings = gql`
  fragment FullPharmacySettings on PharmacySettings {
    format
    defaultPharmacy {
      id
      name
    }
    pharmacyEducation {
      ...FullItemSubscriptions
    }
  }
  ${FullItemSubscriptions}
`;

export const FullBPJSIntegrationSettings = gql`
  fragment FullBPJSIntegrationSettings on BPJSIntegrationSettings {
    isEnabled
  }
`;

export const FullIntegrationsSettings = gql`
  fragment FullIntegrationsSettings on IntegrationsSettings {
    bpjs {
      ...FullBPJSIntegrationSettings
    }
    xendit {
      ...FullXenditIntegrationSettings
    }
  }
  ${FullBPJSIntegrationSettings}
  ${FullXenditIntegrationSettings}
`;

export const FullDailyReportWebhookSettings = gql`
  fragment FullDailyReportWebhookSettings on DailyReportWebhookSettings {
    isEnabled
    parser
    url
    authorizationKey
    emailToNotify
  }
`;

export const FullWebhooksSettings = gql`
  fragment FullWebhooksSettings on WebhooksSettings {
    dailyReport {
      ...FullDailyReportWebhookSettings
    }
  }
  ${FullDailyReportWebhookSettings}
`;

export const FullHealthSystemSettings = gql`
  fragment FullHealthSystemSettings on HealthSystemSettings {
    registration {
      ...FullRegistrationSettings
    }
    pharmacy {
      ...FullPharmacySettings
    }
    integrations {
      ...FullIntegrationsSettings
    }
    webhooks {
      ...FullWebhooksSettings
    }
  }
  ${FullRegistrationSettings}
  ${FullPharmacySettings}
  ${FullIntegrationsSettings}
  ${FullWebhooksSettings}
`;

export const FullHealthSystem = gql`
  fragment FullSystem on System {
    ...BasicSystem
    locations {
      ...FullLocation
    }
    settings {
      ...FullHealthSystemSettings
    }
  }
  ${BasicHealthSystem}
  ${FullLocation}
  ${FullHealthSystemSettings}
`;

export const FullStoreStatus = gql`
  fragment FullStoreStatus on StoreStatus {
    id
    isReady
  }
`;
