import React from 'react';
import Select from 'react-select';
import {
  ReactSelectInternalValue,
  ReactSelectInternalSingleValue,
  SingleSelectorProps,
} from 'components-ts/Selectors/utils';
import { getEmojiFlag } from 'countries-list';
import { Nullable } from 'components-ts/utils';
import { SUPPORTED_LOCALES, LocaleInfo, Locales } from 'components-ts/i18n';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { FormGroup, Label } from 'reactstrap';

const messages = defineMessages({
  locale: {
    id: 'LocaleSelector.label',
    defaultMessage: 'Language',
  },
});

type FullLocale = LocaleInfo & {
  id: Locales;
};
export interface LocaleSelectorProps extends SingleSelectorProps<Locales> {
  filter?: (localeId: Locales) => boolean;
  autoSelect?: boolean;
}

export const LocaleSelector: React.FC<LocaleSelectorProps> = (props) => {
  const { value, autoSelect } = props;

  const intl = useIntl();
  const formatOption = (locale: FullLocale): ReactSelectInternalSingleValue => {
    return {
      value: locale.id,
      label: `${getEmojiFlag(locale.countryCode)} ${locale.language} (${locale.country})`,
    };
  };

  const getOptions = (filter?: (localeId: Locales) => boolean) => {
    if (filter) {
      return Object.entries(SUPPORTED_LOCALES)
        .filter(([id]) => filter(id as Locales))
        .map(([id, info]) => formatOption({ id: id as Locales, ...info }));
    }

    return Object.entries(SUPPORTED_LOCALES).map(([id, info]) => formatOption({ id: id as Locales, ...info }));
  };

  const options = getOptions(props.filter);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: Locales): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    const locale = SUPPORTED_LOCALES[value];
    if (locale) {
      return formatOption({ id: value, ...locale });
    }

    return null;
  };

  const _onChange = React.useCallback(
    (value) => {
      const selectedValue = value;
      props.onChange(selectedValue.value as Locales);
    },
    [props]
  );

  React.useEffect(() => {
    if (!value && autoSelect && options.length > 0) {
      _onChange(options[0]);
    }
  }, [options, autoSelect, _onChange, value]);
  return (
    <FormGroup>
      <Label>
        <T id={messages.locale.id}>{intl.formatMessage(messages.locale)}</T>
      </Label>
      <Select
        options={options}
        value={parseValue(value)}
        onChange={_onChange}
        isLoading={props.isLoading}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        isSearchable={props.isSearchable}
        aria-invalid={props.isInvalid}
        placeholder={props.placeholder}
        blurInputOnSelect={props.blurInputOnSelect}
      />
    </FormGroup>
  );
};
