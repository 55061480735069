import React from 'react';
import { Footer, FooterProps } from './Footer';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useSubscribeToProceduresBatch } from 'components-ts/HealthSystems/useProceduresActions';
import { useSystems } from 'components-ts/HealthSystems';
import { defineMessages, useIntl } from 'react-intl';
import { useTranslatedProcedures } from 'components-ts/HealthSystems/useTranslatedProcedures';
import { IdTagTranslatedSubscriptionForm } from 'components-ts/HealthSystems/IdTagTranslatedSubscriptionForm';

const messages = defineMessages({
  title: { id: 'ProceduresFirstStep.title', defaultMessage: 'Procedures' },
  description: {
    id: 'ProceduresFirstStep.description',
    defaultMessage:
      "Let's set up the procedures available in your health facility. You can manually select the procedures one-by-one from a comprehensive library. Or, you can import a WD pre-prepared list of procedures and add or deselect items at your convenience. This will save you time, so we recommend this.",
  },
  tagsTitle: {
    id: 'ProceduresFirstStep.tags_title',
    defaultMessage: 'You can choose from our predefined selection groups',
  },
  onlySelectedLabel: {
    id: 'UI.show_only_selected',
    defaultMessage: 'Only selected',
  },
});

type ProceduresStepProps = {
  footerProps: FooterProps;
};

interface UseStepActionParams {
  footerProps: FooterProps;
}

const useStepAction = (params: UseStepActionParams) => {
  const { footerProps } = params;

  const { getMySystem, getMyLocation } = useSystems();
  const system = getMySystem();
  const location = getMyLocation();

  const { onSubmit, isLoading, error } = useSubscribeToProceduresBatch({
    systemId: system.id,
    locationId: location.id,
    onCompleted: footerProps.next,
  });

  const onNext = (selectedIds: Set<string>) => {
    if (selectedIds.size > 0) {
      onSubmit(Array.from(selectedIds));
    } else {
      footerProps.next();
    }
  };

  return {
    onNext,
    isSubmitting: isLoading,
    submittingError: error,
  };
};

export const ProceduresStep: React.FC<ProceduresStepProps> = (props) => {
  const { footerProps } = props;

  const intl = useIntl();

  const { getMyLocation } = useSystems();

  const location = getMyLocation();

  const {
    translatedProcedures,
    isLoading: isLoadingProcedures,
    error: errorLoadingProcedures,
  } = useTranslatedProcedures();

  const { onNext, isSubmitting, submittingError } = useStepAction({ footerProps });

  const error = errorLoadingProcedures ?? submittingError;

  return (
    <>
      <div className="mb-4">
        <h3>
          <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
        </h3>
        <p className="mt-2">
          <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
        </p>
      </div>
      <IdTagTranslatedSubscriptionForm
        onSubmit={(selectedIds) => onNext(new Set(selectedIds))}
        items={translatedProcedures}
        isLoading={isLoadingProcedures}
        error={error}
        initialValues={location.subscriptions.procedures.items}
      >
        <Footer {...footerProps} next={() => false} isNextLoading={isSubmitting} isStepLoading={isLoadingProcedures} />
      </IdTagTranslatedSubscriptionForm>
    </>
  );
};
