import { captureException } from '@sentry/browser';
import { useSystems } from 'components-ts/HealthSystems';
import { getEnvs } from 'components-ts/envs';
import { useCallback } from 'react';

type BPJSTokenResponseMetadata = {
  token: string;
};

type BPJSTokenResponse = {
  code: number;
  message: Array<string>;
  metadata: BPJSTokenResponseMetadata;
};

export const useBPJSApiToken = () => {
  const { getMyLocation } = useSystems();

  const location = getMyLocation();
  const { bridgingUrl: baseUrl } = getEnvs();

  const getToken = useCallback(async () => {
    if (!baseUrl) {
      const e = new Error('Missing bridging url');
      captureException(e);
      throw e;
    }

    return fetch(`${baseUrl}/api/token`, {
      method: 'POST',
      body: new URLSearchParams({ user: location.id }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(async (res) => {
      if (!res.ok) {
        throw new Error('Bridging get token error');
      }

      return res.json().then((v: BPJSTokenResponse) => {
        return v.metadata.token;
      });
    });
  }, [location.id, baseUrl]);

  return {
    getToken,
  };
};
