import React, { useState } from 'react';
import { LazyQueryHookOptions, MutationHookOptions, useLazyQuery, useMutation } from '@apollo/client';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { HealthSystemFormValues } from 'components-ts/HealthSystems/HealthSystemForm';
import {
  CREATE_SYSTEM,
  GET_LOGO_DOWNLOADING_URL,
  GET_SYSTEMS,
  SAVE_LOGO,
  SET_FIRST_STEPS_DONE,
  UPDATE_SYSTEM,
} from '../../api/request/systems';
import { HealthSystem } from 'api/interfaces';

const messages = defineMessages({
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  notDeletedError: {
    id: 'HealthSystemViewer.not_deleted',
    defaultMessage: 'Could not delete this health system.',
  },
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Please reload',
  },
  systemInitializationFailed: {
    id: 'HealthSystemViewer.could_not_initialize_the_system',
    defaultMessage: 'Could not initialize the system.',
  },
  systemUpdateFailed: {
    id: 'HealthSystemViewer.could_not_update_the_system',
    defaultMessage: 'Could not update the system.',
  },
  systemUploadLogo: {
    id: 'HealthSystemViewer.could_not_upload_system_logo',
    defaultMessage: 'Could not upload the system logo. Please, retry',
  },
  setStoreUpError: {
    id: 'HealthSystemViewer.could_not_set_up_store',
    defaultMessage: 'Could not set up the system store. Try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

interface UpdateHealthSystemData {
  updateHeathSystem: HealthSystem;
}

interface UpdateHealthSystemVariables {
  systemId: string;
  basicInfo: HealthSystemFormValues;
}

type UseUpdateHealthSystemProps = MutationHookOptions<UpdateHealthSystemData, UpdateHealthSystemVariables> & {
  system: Nullable<HealthSystem>;
};

export const useUpdateHealthSystem = (params: UseUpdateHealthSystemProps) => {
  const { system, ...rest } = params;

  const intl = useIntl();

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const initialValues = {
    name: system?.name ?? '',
    country: system?.country ?? '',
    locale: system?.locale ?? '',
    timezone: system?.timezone ?? '',
  };

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_creating_system':
        return setError(messages.systemUpdateFailed);

      default:
        return setError(messages.unexpectedError);
    }
  };

  const validate: ValidateFn<HealthSystemFormValues> = async (values) => {
    const errors: ValidationError<HealthSystemFormValues> = {};

    if (!values.name) {
      errors.name = intl.formatMessage(messages.required);
    }

    if (!values.country) {
      errors.country = intl.formatMessage(messages.required);
    }

    if (!values.timezone) {
      errors.timezone = intl.formatMessage(messages.required);
    }

    return errors;
  };

  const onCompleted = (data: UpdateHealthSystemData) => {
    onErrorClose();
    if (typeof rest?.onCompleted === 'function') {
      rest.onCompleted(data);
    }
  };

  const [updateHealthSystem, { loading: isLoading }] = useMutation<UpdateHealthSystemData, UpdateHealthSystemVariables>(
    UPDATE_SYSTEM,
    {
      ...rest,
      onError,
      onCompleted,
    }
  );

  const onSubmit = (values: HealthSystemFormValues) => {
    if (system === null) {
      return;
    }

    setError(null);

    const { name, country, locale, timezone } = values;

    const basicInfo = {
      name,
      country,
      timezone,
      locale,
    };

    const variables = {
      systemId: system.id,
      basicInfo,
    };

    updateHealthSystem({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
    initialValues,
  };
};

interface UseSetFirstStepsDoneData {
  setFirstsStepsDoneData: HealthSystem;
}

interface UseSetFirstStepsDoneVariables {
  systemId: string;
}

type UseSetFirstStepsDoneParams = MutationHookOptions<UseSetFirstStepsDoneData, UseSetFirstStepsDoneVariables> & {
  systemId: string;
};

export const useSetFirstStepsDone = (params: UseSetFirstStepsDoneParams) => {
  const { systemId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlErrors) => {
    const errorCode = extractFirstErrorCode(graphqlErrors);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (rest.onCompleted) rest.onCompleted(data);
  };

  const [execSetFirstStepsDone, { loading: isLoading }] = useMutation<
    UseSetFirstStepsDoneData,
    UseSetFirstStepsDoneVariables
  >(SET_FIRST_STEPS_DONE, { ...rest, onError, onCompleted });

  const setFirstStepsDone = () => execSetFirstStepsDone({ variables: { systemId } });

  return {
    setFirstStepsDone,
    error,
    isLoading,
  };
};

export type NewSystem = {
  name: string;
  country: string;
  locale: string;
  timezone: string;
};
interface UseCreateHealthSystemData {
  createHealthSystem: HealthSystem;
}

interface UseCreateHealthSystemVariables {
  basicInfo: NewSystem;
}

type UseCreateHealthSystemParams = MutationHookOptions<UseCreateHealthSystemData, UseCreateHealthSystemVariables>;

export const useCreateHealthSystem = (params: UseCreateHealthSystemParams) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlErrors) => {
    const errorCode = extractFirstErrorCode(graphqlErrors);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onErrorClose = () => {
    setError(null);
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [createHS, { loading: isLoading }] = useMutation<UseCreateHealthSystemData, UseCreateHealthSystemVariables>(
    CREATE_SYSTEM,
    { ...params, refetchQueries: [GET_SYSTEMS], onError, onCompleted }
  );

  const onSubmit = (values) => {
    createHS({
      variables: {
        basicInfo: values,
      },
    });
  };

  const validate: ValidateFn<NewSystem> = () => {
    const errors: ValidationError<NewSystem> = {};

    return errors;
  };

  return {
    onSubmit,
    validate,
    onErrorClose,
    isLoading,
    error,
  };
};

/**
 * Update logo
 *
 */

interface UseUpdateSystemLogoData {
  saveLogo: HealthSystem;
}

interface UseUpdateSystemLogoVariables {
  systemId: string;
  fileId: string;
}

type UseUpdateSystemLogoParams = MutationHookOptions<UseUpdateSystemLogoData, UseUpdateSystemLogoVariables> & {
  systemId: string;
};

export const useUpdateSystemLogo = (params: UseUpdateSystemLogoParams) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlErrors) => {
    const errorCode = extractFirstErrorCode(graphqlErrors);

    switch (errorCode) {
      default:
        return setError(messages.systemUploadLogo);
    }
  };

  const onErrorClose = () => {
    setError(null);
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [saveLogo, { loading: isLoading }] = useMutation<UseUpdateSystemLogoData, UseUpdateSystemLogoVariables>(
    SAVE_LOGO,
    { ...params, onError, onCompleted }
  );

  const onSubmit = (fileId) => {
    saveLogo({
      variables: {
        systemId: params.systemId,
        fileId,
      },
    });
  };

  return {
    onSubmit,
    onErrorClose,
    isLoading,
    error,
  };
};

interface UseLazyGetLogoDownloadUrlData {
  logoDownloadUrl: {
    url: string;
  };
}

interface UseLazyGetLogoDownloadUrlVariables {
  systemId: string;
}

type UseLazyGetLogoDownloadUrlParams = LazyQueryHookOptions<
  UseLazyGetLogoDownloadUrlData,
  UseLazyGetLogoDownloadUrlVariables
>;

export const useLazyGetLogoDownloadUrl = (params: UseLazyGetLogoDownloadUrlParams) => {
  const [error, setError] = React.useState<MessageDescriptor | null>(null);

  const onError = (errors) => {
    const errorCode = extractFirstErrorCode(errors);

    switch (errorCode) {
      default:
        setError(messages.unexpectedError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [getUrl, { loading: isLoading, data }] = useLazyQuery<
    UseLazyGetLogoDownloadUrlData,
    UseLazyGetLogoDownloadUrlVariables
  >(GET_LOGO_DOWNLOADING_URL, {
    ...params,
    onError,
    onCompleted,
  });

  return {
    getUrl,
    isLoading,
    url: data?.logoDownloadUrl.url ?? null,
    error,
  };
};
