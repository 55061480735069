import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Button } from 'reactstrap'
import {
  FaSearch as SearchIcon,
  FaAngleLeft as BackIcon
} from 'react-icons/fa'

// static translations
const messages = defineMessages({
  documentNotFound: {
    id: 'DocumentNotFound.document_not_found',
    defaultMessage: 'Document not found'
  },
  buttonCreateNew: {
    id: 'DocumentNotFound.button_create_a_new',
    defaultMessage: 'Create a new'
  },
  buttonBack: {
    id: 'DocumentNotFound.button_back',
    defaultMessage: 'Back'
  }
})

/**
 * This component will shown when a specific product page does not found the corresponding document
 * From here the user should be allow to return to the creation page of the current type of document
 *
 * For example:
 * .../scheduled-visit/fake-doc-id => will allow to return to .../scheduled-visits/new
 *
 * @param {id: String} props
 */
const DocumentNotFound = (props) => {
  // props
  const { id, allowBack, intl } = props
  const { formatMessage } = intl

  // hooks
  const history = useHistory()
  const { location } = history

  // go to create a new one
  const { pathname } = location
  const redirectURL = pathname.replace(id, 'new')

  return <div className='animated fadeIn container my-5 p-5 d-flex flex-column align-items-center justify-content-center text-center'>
    <SearchIcon className='text-secondary h4' />
    <p className='text-muted text-bold'>
      <T id={messages.documentNotFound.id}>{formatMessage(messages.documentNotFound)}</T>
    </p>
    <p className='text-muted text-bold font-italic mt-1'>
      {id}
    </p>

    {
      id && <Link to={redirectURL}>
        <T id={messages.buttonCreateNew.id}>{formatMessage(messages.buttonCreateNew)}</T>
      </Link>
    }
    {
      allowBack && <Button className='btn btn-link d-flex align-items-center' color='link' onClick={e => history.goBack()}>
        <BackIcon />
        <T id={messages.buttonBack.id}>{formatMessage(messages.buttonBack)}</T>
      </Button>
    }
  </div>
}
export default injectIntl(DocumentNotFound)
