import React, { useEffect } from 'react';
import { useTable, useGroupBy, useExpanded, useSortBy, usePagination } from 'react-table';
import { LoadingInline } from '.';
import {
  FaChevronDown as DownIcon,
  FaChevronRight as RightIcon,
  FaSortAlphaDown as SortAlfaDownIcon,
  FaSortAlphaUp as SortAlfaUpIcon,
} from 'react-icons/fa';
import ResourcePaginator from './ResourcePaginator';
import { injectIntl, defineMessages } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';

// paginator pages to show
const PAGES_INDICATORS = 5;

// items per page
const ITEMS_PER_PAGE = 15;

// static translations
const messages = defineMessages({
  showingResultCount: {
    id: 'DefaultTable.showing_result_count',
    defaultMessage: 'Showing {start}-{end} out of {total} results.',
  },
});

const DefaultTable = props => {
  const {
    itemsPerPage = ITEMS_PER_PAGE,
    columns,
    data,
    loading,
    groupedBy,
    hiddenColumns = [],
    intl,
    rowsRef,
    className = '',
  } = props;
  const { formatMessage } = intl;

  const initialState = {
    pageIndex: 0,
    pageSize: itemsPerPage,
  };

  const params = {
    columns,
    data,
    initialState,
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    // for paginator
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { pageIndex },
    setPageSize,
    rows,
  } = useTable(params, useGroupBy, useSortBy, useExpanded, usePagination);

  useEffect(() => {
    if (rowsRef && Array.isArray(rows)) {
      rowsRef.current = rows.map(row => (row || {}).values);
    }
  }, [rows, rowsRef]);

  // sync with the groupBy changes
  useEffect(() => {
    // set the data length as the limit
    if (groupedBy) setPageSize((data || []).length || 10000);
    // set the default value
    else setPageSize(itemsPerPage);
  }, [groupedBy, setPageSize, data, itemsPerPage]);

  // paginator info
  const showingStart = data.length > 0 ? pageIndex * itemsPerPage + 1 : 0;
  const showingEnd = showingStart + itemsPerPage < data.length ? showingStart + itemsPerPage - 1 : data.length;

  const pagesCount = Math.ceil(data.length / itemsPerPage);

  // Render the UI for your table
  return (
    <div className={className}>
      {loading && (
        <div className='w-100 d-flex justify-content-center mb-2'>
          <LoadingInline />
        </div>
      )}
      <table className='table table-striped' {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                const { id, isGrouped } = column;
                if (column.toggleGroupBy && isGrouped !== (groupedBy === id)) {
                  column.toggleGroupBy();
                }

                if (hiddenColumns.includes(id)) column.toggleHidden();
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span className='text-primary ma1'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortAlfaUpIcon className='m-1' />
                        ) : (
                          <SortAlfaDownIcon className='m-1' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()} className='text-primary mr1' />
                    ) : null}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className='position-relative'>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={
                        cell.isGrouped
                          ? 'bg-primary font-weight-bold'
                          : cell.isAggregated
                          ? ''
                          : cell.isPlaceholder
                          ? 'bg-secondary'
                          : ''
                      }
                    >
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <div>
                          <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <DownIcon /> : <RightIcon />}
                          </span>{' '}
                          {cell.render('Cell')} ({row.subRows.length})
                        </div>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      {
        /* paginator */
        !groupedBy && data.length > itemsPerPage && (
          <div className='d-flex flex-column justify-items-center text-center section-not-printable'>
            <ResourcePaginator
              selectedPage={pageIndex}
              onPageChange={page => gotoPage(page)}
              pagesCount={pagesCount}
              pagesToShow={PAGES_INDICATORS}
              disableBack={!canPreviousPage}
              disableNext={!canNextPage}
            />
            <T id={messages.showingResultCount.id}>
              {formatMessage(messages.showingResultCount, {
                start: showingStart,
                end: showingEnd,
                total: data.length,
              })}
            </T>
          </div>
        )
      }
    </div>
  );
};
export default injectIntl(DefaultTable);
