import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { StudyItemEditor, StudyFileUploader, StudyFilesViewer } from 'components/Study';
import { Button, Col, Card, CardBody, CardHeader, CardFooter, Input } from 'reactstrap';
import { OrderActionMenu, OrderComments, OrderStatus } from 'components-ts/ActiveVisit/Orders';
import { useRouteMatch } from 'react-router-dom';
import { StudyPrintButton } from 'components-ts/Lab';
import { useStudy } from 'components-ts/Lab/useStudy';
import { FullStudyWithOrder } from 'api/interfaces';
import { CollapseCard } from 'components';
import {
  ConfirmationModal,
  ErrorViewer,
  LastUpdated,
  Loading,
  LoadingInline,
  NotFound,
  useDisclosure,
} from 'components-ts';
import { useAddFileToStudy, useAddNoteToStudy, useCompleteStudy } from 'components-ts/Lab/useStudyActions';
import { DynamicTranslation } from 'components-ts/i18n';
import { PatientInfo } from 'components-ts/Patients/PatientInfo';
import { FaRegCheckCircle as CompleteIcon } from 'react-icons/fa';
import { useDebounceExececute } from 'hooks';

const messages = defineMessages({
  results: {
    id: 'StudyEditor.results',
    defaultMessage: 'Results',
  },
  requestedBy: {
    id: 'StudyEditor.requested_by',
    defaultMessage: 'Requested by:',
  },
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Notes',
  },
  completeStudyButton: {
    id: 'UI.complete_study_button',
    defaultMessage: 'Complete study',
  },
  lastModifiedBy: {
    id: 'StudyEditor.last_modified_by',
    defaultMessage: 'Last modified by',
  },
  completedBy: {
    id: 'StudyEditor.completed_by',
    defaultMessage: 'Completed by',
  },
  commentsTitle: {
    id: 'StudyEditor.comments_title',
    defaultMessage: 'Comments',
  },

  completeStudyConfirmationMessage: {
    id: 'StudyEditor.complete_study_confirmation_message',
    defaultMessage:
      'This study will be permanently set as completed and you are not going to be able to update it. Are you sure?',
  },
});

export const StudyEditor: React.VFC = () => {
  const { params } = useRouteMatch();

  const { study, isLoading } = useStudy({ id: params.studyId });

  if (isLoading) {
    return <Loading className="text-primary" />;
  }

  if (!study) {
    return <NotFound resourceId={params.studyId} />;
  }

  return <StudyCard study={study} />;
};

interface StudyCardProps {
  study: FullStudyWithOrder;
  withPatientInfo?: boolean;
  isDisable?: boolean;
  isActionable?: boolean;
  className?: string;
}

export const StudyCard: React.FC<StudyCardProps> = (props) => {
  const { study, isDisable, withPatientInfo, className = '', isActionable = true } = props;

  const intl = useIntl();

  const { patient, visitId, name, description, updated, items, order, completed, note, isActive } = study;

  const { onSubmit, error, isLoading } = useAddNoteToStudy({ id: study.id });
  const { execute } = useDebounceExececute(500);
  const onNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    execute(() => onSubmit({ note: value }));
  };

  return (
    <Card className={`w-100 ${className}`}>
      <CardHeader>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div>
            <h4 className="m-0 font-weight-bold">{name?.text && <DynamicTranslation text={name.text} />}</h4>
            <p className="text-muted mb-0">{description?.text && <DynamicTranslation text={description.text} />}</p>
          </div>
          {isActive && !order.filled && isActionable && (
            <OrderActionMenu
              patientId={patient.id}
              visitId={visitId}
              order={order}
              isCancellable={!completed && !order.received}
              isAcknowledgeable
              isCommentable
              isReceivable={!order.canceled}
            />
          )}
        </div>

        <OrderStatus
          className="mt-2"
          acknowledged={!!order?.acknowledged}
          canceled={!!order?.canceled}
          received={!!order?.received}
          filled={!!study.completed}
        />
      </CardHeader>
      <CardBody>
        {withPatientInfo && <PatientInfo patient={patient} className="my-2" />}
        <div className="my-2">
          <h4 className="border-bottom">
            <T id={messages.results.id}>{intl.formatMessage(messages.results)}</T>
          </h4>
          {(items || []).length > 0 && (
            <div className="d-flex flex-wrap">
              {items.map((item) => (
                <Col md={6} className="p-1" key={item.id}>
                  <StudyItemEditor
                    readOnly={(isDisable || !!completed) ?? order?.canceled}
                    item={item}
                    studyId={study.id}
                  />
                </Col>
              ))}
            </div>
          )}
        </div>
        <div className="p-1">
          <span>
            <T id={messages.noteLabel.id}>{intl.formatMessage(messages.noteLabel)}</T>
          </span>
          <Input type="textarea" disabled={isDisable} rows={2} defaultValue={note} onChange={onNoteChange} />
        </div>
        {Array.isArray(order.comments) && (
          <CollapseCard
            className="my-2"
            title={
              <h5 className="m-0">
                <T id={messages.commentsTitle.id}>
                  {intl.formatMessage(messages.commentsTitle)} ({order.comments.length})
                </T>
              </h5>
            }
          >
            <OrderComments comments={order.comments} />
          </CollapseCard>
        )}
        <StudyFiles study={study} isDisable={isDisable} />
        {!!completed && (
          <div className="d-flex justify-content-start alert alert-success my-2 flex-wrap">
            <span className="mr-1">
              <T id={messages.completedBy.id}>{intl.formatMessage(messages.completedBy)}:</T>
            </span>
            <b>
              {`${completed.given_name} ${completed.family_name} - ${intl.formatDate(new Date(completed.timestamp), {
                dateStyle: 'medium',
                timeStyle: 'short',
              })}`}
            </b>
          </div>
        )}
        {error && <ErrorViewer error={error} />}
      </CardBody>
      <CardFooter className="d-flex justify-content-between">
        {!!completed ? (
          <StudyPrintButton study={study} patient={patient} />
        ) : isDisable ? (
          <div />
        ) : (
          <CompleteStudyButton id={study.id} isDisabled={!!order.canceled} />
        )}
        {isLoading ? (
          <div className="mx-4">
            <LoadingInline />
          </div>
        ) : (
          updated && <LastUpdated signature={updated} />
        )}
      </CardFooter>
    </Card>
  );
};

interface CompleteStudyButtonProps {
  id: string;
  isDisabled?: boolean;
}
export const CompleteStudyButton: React.FC<CompleteStudyButtonProps> = (props) => {
  const { id, isDisabled } = props;

  const intl = useIntl();
  const { onSubmit, isLoading, error } = useCompleteStudy({ id });
  const { isOpen, onClose, onOpen } = useDisclosure();

  const completeStudyProps = {
    onConfirm: onSubmit,
    message: messages.completeStudyConfirmationMessage,
    loading: isLoading,
    error: error,
    onClose: onClose,
    className: 'btn-success',
  };

  return (
    <>
      <Button disabled={isDisabled} onClick={onOpen} color="success">
        <CompleteIcon className="mr-1" />
        <T id={messages.completeStudyButton.id}>{intl.formatMessage(messages.completeStudyButton)}</T>
      </Button>
      {isOpen && <ConfirmationModal {...completeStudyProps} />}
    </>
  );
};

interface StudyFiles {
  study: FullStudyWithOrder;
  isDisabled?: boolean;
}
export const StudyFiles = (props) => {
  const { study, isDisable } = props;
  const { id, patient, files } = study || {};

  const { onSubmit, isLoading, error } = useAddFileToStudy({ id });

  const onChange = (fileId: string) => {
    onSubmit({ fileId });
  };

  return (
    <div className="d-flex flex-column p-1">
      <div className="d-flex justify-content-end align-items-center">
        <StudyFilesViewer studyId={id} files={files} isLoading={isLoading} />
      </div>
      {!isDisable && <StudyFileUploader patientId={(patient || {}).id} onChange={onChange} />}
      {error && <ErrorViewer error={error} />}
    </div>
  );
};
