import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { SEX } from 'wd-common/src/logic/patient/definitions'
import { isAValidSex } from 'wd-common/src/logic/patient/validations'
const debug = require('debug')('wd:SexSelector')

export const messages = defineMessages({
  female: {
    id: 'Sex.Female',
    defaultMessage: 'Female'
  },
  male: {
    id: 'Sex.Male',
    defaultMessage: 'Male'
  }
})

const Sex = (props) => {
  const { sex, intl } = props

  const translateOption = sex => {
    switch (sex) {
      case SEX.FEMALE:
        return messages.female

      case SEX.MALE:
        return messages.male

      default:
        return ''
    }
  }

  debug(sex)
  const message = isAValidSex(sex) ? translateOption(sex) : null
  return message
    ? <T id={message.id}>{intl.formatMessage(message)}</T>
    : sex
}

export default injectIntl(Sex)
