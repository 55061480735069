import React, { useState } from 'react'
import Select from 'react-select'
import { FormGroup, Label } from 'reactstrap'
import { useSession } from '../hooks'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { useSystems } from 'components-ts/HealthSystems'

const debug = require('debug')('wd:LocationSelector')

// static translations
const messages = defineMessages({
  location: {
    id: 'LocationSelector.location_label',
    defaultMessage: 'Location'
  }
})

/**
 * Component for allow to select a location depending of the health system id received
 *
 * @component
 * @example
 * const systemId = '59cedfba9ae80d05757f54e9' // demo => if this is not specified the component will take as the default value the userSystem.id from the session
 * const location = '59cedfba9ae80d05757f54e6' // demo clinic #1

 * // if isMulti === true, locations is an Array<string>
 * const onChange = ([...locations]) => debug(locations[0])

 * // else, locations is a string
 * const onChange = (location) => debug(location)
 * return (
 *   <LocationSelector
 *      systemId={systemId}
 *      location={location}
 *      onChange={onChange}
 *   />
 * )
 */
const LocationSelector = (props) => {
  // props
  const { value: _location, onChange, isMulti, systemId, isClearable, className, intl } = props

  const { formatMessage } = intl

  if (!_location) debug('Missing prop: location')
  if (typeof onChange !== 'function') debug('Missing prop: onChange')

  // hooks
  const { getSystem, getLocation } = useSystems()
  const { getUserSystem } = useSession()

  /**
   * TO DO
   * async initialization using onCompleted
   */
  const userSystemId = (getUserSystem() || {}).id
  const userSystem = getSystem(userSystemId)
  const system = systemId ? getSystem(systemId) : userSystem
  const options = (system.locations || []).map(({ id, name }) => ({ value: id, label: name }))

  // state
  const initialValue = _location
    ? {
      value: _location,
      label: (getLocation(userSystemId, _location) || {}).name
    }
    : ''

  const [location, setLocation] = useState(isMulti ? [initialValue] : initialValue)

  const _onChange = (val) => {
    setLocation(val)
    if (typeof onChange === 'function') onChange(isMulti && val ? val.map((v) => v.value) : (val || {}).value)
  }
  debug(location, system, options, userSystem)
  return (
    <FormGroup>
      <Label>
        <T id={messages.location.id}>{formatMessage(messages.location)}</T>
      </Label>
      <Select
        value={location}
        className={`dropdownZIndex ${className}`}
        onChange={_onChange}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable}
        isSearchable
      />
    </FormGroup>
  )
}

LocationSelector.defaultProps = {
  location: '',
  onChange: () => {},
  systemId: '',
  isMulti: false,
  isClearable: true
}
export default injectIntl(LocationSelector)
