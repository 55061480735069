import { UserData } from 'amazon-cognito-identity-js';
import { DefaultSystemOrLocation, SubscriptionStatus } from '../../api/interfaces';
import { Nullable, validateNotNil } from '../utils';
import { Locales } from '../i18n/utils';
import { UnitSystems } from '../i18n';
import { AccountStatus, Subscription } from './subscription';
import { Permissions } from './permissions';

export enum SessionState {
  BOOTING = 'booting',
  LOGGED_OUT = 'loggedOut',
  LOGGED_IN = 'loggedIn',
}

export interface SignUpParams {
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  locale: string;
  invite?: string;
}

export interface LogInParams {
  username: string;
  password: string;
}

export interface LogInResponse {
  totpRequired: boolean;
}

export interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}
export interface ConfirmRegistrationParams {
  code: string;
}

export interface ForgotPasswordParams {
  username: string;
}

interface CodeDetails {
  Destination: string;
}
export interface ForgotPasswordResponse {
  CodeDeliveryDetails: CodeDetails;
}

export enum VerifiableAttribute {
  EMAIL = 'email',
  PHONE = 'phone_number',
}
export interface RequestAttributeVerificationParams {
  attributeName: VerifiableAttribute;
}

export interface ResetPasswordParams {
  verificationCode: string;
  newPassword: string;
}

export interface UpdateAttributeParams {
  attributeName: string;
  value: string;
}

export interface UpdateLocaleParams {
  locale: Locales;
}

export interface VerifyAttributeParams {
  attributeName: VerifiableAttribute;
  code: string;
}

export interface VerifyMFATokenParams {
  totpCode: string;
  friendlyDeviceName: string;
}

export interface SendMFATokenParams {
  confirmationCode: string;
}

/**
 * AWS Types file is wrong. The verify token fn doesn't
 * return a congnito session promise. It returns a promise
 * of this
 */
export interface SetUserMfaPreferenceResponse {
  Status: 'SUCCESS';
}

export interface VerifyMFATokenResponse {
  Status: 'SUCCESS';
}

export interface DecodedUserPayload {
  aud: string;
  auth_time: number;
  ['cognito:username']: string;
  ['custom:location']?: string;
  ['custom:permissions']: string;
  ['custom:system']?: string;
  ['custom:subscription']?: string;
  ['custom:unitSystem']?: string;
  ['custom:invite']?: string;
  ['custom:bpjsDoctorId']?: string;
  ['custom:licenseNumber']?: string;
  ['custom:clinicUserId']?: string;
  email: string;
  email_verified: boolean;
  event_id: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  locale: string;
  phone_number: string;
  phone_number_verified: boolean;
  sub: string;
  token_use: string;
}

/**
 * It returns a string that can be parsed to a DefaultSystemOrLocationAttr
 * or it throws an error
 * @param value unknown
 */
export function validateSystemOrLocationAttr(value: unknown): asserts value is string {
  validateNotNil(value);

  const attr = JSON.parse(value as string);
  if (typeof attr?.id !== 'string' || typeof attr?.name !== 'string') {
    throw new Error('Invalid attribute', attr);
  }
}

export interface Session {
  associateMFAToken: () => Promise<string>;
  changePassword: (params: ChangePasswordParams) => Promise<void>;
  confirmRegistration: (params: ConfirmRegistrationParams) => Promise<void>;
  disableMFA: () => Promise<SetUserMfaPreferenceResponse>;
  enableMFA: () => Promise<SetUserMfaPreferenceResponse>;
  forgotPassword: (params: ForgotPasswordParams) => Promise<ForgotPasswordResponse>;
  getAccountStatus: () => AccountStatus;
  getInvite: () => Nullable<string>;
  getUser: () => DecodedUserPayload;
  getSubscriptionActionUrl: () => Nullable<string>;
  getSubscriptionStatus: () => Nullable<SubscriptionStatus>;
  getSystemIDs: () => Array<string>;
  getToken: () => string;
  getUserData: () => Promise<UserData>;
  getUserEmail: () => string;
  getUserFamilyName: () => string;
  getUserFullName: () => string;
  getUserGivenName: () => string;
  getUserId: () => string;
  getUserLocale: () => Locales;
  getUserLocation: () => DefaultSystemOrLocation;
  getUsername: () => string;
  getUsernameFromCognitoUser: () => string;
  getUserPhoneNumber: () => string;
  getUserSystem: () => DefaultSystemOrLocation;
  getUserUnitSystem: () => Nullable<UnitSystems>;
  getUserSubscription: () => Nullable<Subscription>;
  getUserBpjsDoctorId: () => Nullable<string>;
  getUserPermissions: () => Permissions;
  isAdmin: () => boolean;
  isBooting: () => boolean;
  isCognitoUserSet: () => boolean;
  isEmailVerified: () => boolean;
  isLoggedIn: () => boolean;
  isPhoneNumberVerified: () => boolean;
  logIn: (params: LogInParams) => Promise<LogInResponse>;
  logOut: () => void;
  refresh: (callback?: () => void) => Promise<void>;
  requestAttributeVerification: (params: RequestAttributeVerificationParams) => Promise<void>;
  resendAttributeVerificationCode: (params: RequestAttributeVerificationParams) => Promise<void>;
  resendConfirmationCode: (params: void) => Promise<void>;
  resetPassword: (params: ResetPasswordParams) => Promise<string>;
  sendMFACode: (params: SendMFATokenParams) => Promise<void>;
  signUp: (params: SignUpParams) => Promise<void>;
  state: SessionState;
  updateAttribute: (params: UpdateAttributeParams | Array<UpdateAttributeParams>) => Promise<void>;
  updateUserLocale: (params: UpdateLocaleParams) => Promise<void>;
  changeUserUnitSystem: (unitSystem: UnitSystems) => Promise<void>;
  updateUserBpjsDoctorId: (newBpjsDoctorId: string) => Promise<void>;
  userHasRole: (role: string, systemId?: string, locationId?: string) => boolean;
  verifyAttribute: (params: VerifyAttributeParams) => Promise<void>;
  verifyMFAToken: (params: VerifyMFATokenParams) => Promise<VerifyMFATokenResponse>;
}
