import { FullChecklistEntry } from 'utils/GQLFragments';
import { Nullable } from '../../components-ts/utils';
import { TranslatableText, FullReference } from './common';
import { SystemDrugGuideline } from './pharmacy';

export enum ChecklistEntryType {
  CITATION = 'citation',
  DIVIDER = 'divider',
  DRUG = 'drug',
  PROCEDURE = 'procedure',
  STUDY = 'study',
  TEXT = 'text',
  DIFFERENTIAL_DIAGNOSIS = 'differential_diagnosis',
}

export enum ChecklistSections {
  DIFFERENTIAL_DIAGNOSIS = 'differential_diagnosis',
  HISTORY = 'history',
  PHYSICAL_EXAM = 'physical_exam',
  STUDIES = 'studies',
  TREATMENT = 'treatment',
  EDUCATION = 'education',
}

export enum QualityCheckItemType {
  OR = 'equation_or',
  AND = 'equation_and',
  XOR = 'equation_xor',
  ALL = 'equation_all',
  ANY = 'equation_any',
  NOT = 'equation_not',
}

export enum QualityCheckNames {
  DIAGNOSIS = 'Diagnosis',
  TREATMENT = 'Treatment',
  EDUCATION = 'Education',
}

export interface FullChecklistEntry {
  type: ChecklistEntryType;
  entryId: string;
  name: TranslatableText;
  reference: Nullable<FullReference>;
  general_tags: Nullable<Array<TranslatableText>>;
  billing_tags: Nullable<Array<string>>;
  history_codes: Nullable<Array<string>>;
  physical_exam_codes: Nullable<Array<string>>;
  quality_codes: Nullable<Array<string>>;
  checked: Nullable<boolean>;
  key: Nullable<number>;
}

export interface QualityChecksActions {
  clinical_status: Nullable<string>;
}

export interface NewQualityChecksActions {
  clinical_status: string;
}

export interface QualityChecksColors {
  error: Nullable<string>;
  true: Nullable<string>;
}

export interface NewQualityChecksColors {
  error: string;
  true: string;
}

export interface QualityChecksEquationItems {
  itemId: string;
  type: QualityCheckItemType;
  codes: Nullable<Array<TranslatableText>>;
  items: Nullable<Array<QualityChecksEquationItems>>;
}

export interface QualityChecksEquation {
  itemId: string;
  type: QualityCheckItemType;
  items: Nullable<Array<QualityChecksEquationItems>>;
}

export interface NewQualityChecksEquationItems {
  type: QualityCheckItemType;
  itemId?: string;
  codes?: Array<string>;
  items?: Array<NewQualityChecksEquationItems>;
}

export interface NewQualityChecksEquation {
  type: QualityCheckItemType;
  itemId?: string;
  items?: Array<NewQualityChecksEquationItems>;
}

export interface NewQualityCheck {
  name: string;
  message?: string;
  show_alert?: boolean;
  actions?: NewQualityChecksActions;
  colors?: NewQualityChecksColors;
  equation?: NewQualityChecksEquation;
}

export interface QualityCheck {
  qualityCheckId: string;
  name: TranslatableText;
  message: Nullable<TranslatableText>;
  show_alert: Nullable<boolean>;
  actions: Nullable<QualityChecksActions>;
  colors: Nullable<QualityChecksColors>;
  equation: Nullable<QualityChecksEquation>;
}

export interface TypedName {
  type: string;
  name: TranslatableText;
}

export interface QualityEquationValue {
  val: Nullable<boolean>;
  missing: Nullable<Array<string>>;
}

export interface QualityEquationResult {
  name: TranslatableText;
  value: Nullable<QualityEquationValue>;
}

export interface BasicChecklist {
  id: string;
  name: TranslatableText;
  background: Nullable<TranslatableText>;
  general_tags: Nullable<Array<TranslatableText>>;
  category: Nullable<TranslatableText>;
  differential_diagnosis: Nullable<Array<FullChecklistEntry>>;
}

export interface FullChecklist {
  id: string;
  name: TranslatableText;
  background: Nullable<TranslatableText>;
  general_tags: Nullable<Array<TranslatableText>>;
  category: Nullable<TranslatableText>;
  differential_diagnosis: Nullable<Array<FullChecklistEntry>>;
  checklistId: Nullable<string>;

  history: Nullable<Array<FullChecklistEntry>>;
  physical_exam: Nullable<Array<FullChecklistEntry>>;
  studies: Nullable<Array<FullChecklistEntry>>;
  education: Nullable<Array<FullChecklistEntry>>;
  treatment: Nullable<Array<FullChecklistEntry | SystemDrugGuideline>>;

  note: Nullable<string>;
  authors: Nullable<string>;
  quality_checks: Array<QualityCheck>;
  quality_equation_results: Array<QualityEquationResult>;
}

export function isDrugChecklistEntry(e: any): e is SystemDrugGuideline {
  return typeof e === 'object' && Object.keys(e).includes('type') && e.type === ChecklistEntryType.DRUG;
}

export function isQualityEquationItem(e: any): e is QualityChecksEquationItems {
  return typeof e === 'object' && Object.keys(e).includes('codes');
}

export const qualityCheckItemTypeAllowCodes = (type?: QualityCheckItemType) => {
  return type && [QualityCheckItemType.ALL, QualityCheckItemType.ANY].includes(type);
};

export const equationTypeAllowsChildren = (type: QualityCheckItemType) => {
  return ![QualityCheckItemType.ANY, QualityCheckItemType.ALL].includes(type);
};
