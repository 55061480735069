import React from 'react';
// dynamic import is still in stage 3 of the standardization process.
// ESLint has a policy of only implementing features in their parser once they're in stage 4.
// this module allows us to use the standard prettier extension to fix the code style
export const AsyncAuth = React.lazy(() => import('../../scenes/Auth/Index'));
export const AsyncBackoffice = React.lazy(() => import('../../scenes/Backoffice/Index'));
export const AsyncBilling = React.lazy(() => import('../../scenes/Billing/Index'));
export const AsyncChecklists = React.lazy(() => import('../../scenes/Checklists/Index'));
export const AsyncContentLibraries = React.lazy(() => import('../../scenes/ContentLibraries/Index'));
export const AsyncExams = React.lazy(() => import('../../scenes/Exams/Index'));
export const AsyncHealthSystem = React.lazy(() => import('../../scenes/HealthSystems/Index'));
export const AsyncLab = React.lazy(() => import('../../scenes/Lab/Index'));
export const AsyncMetrics = React.lazy(() => import('../../scenes/Metrics/Index'));
export const AsyncPatients = React.lazy(() => import('../../scenes/Patients'));
export const AsyncPharmacy = React.lazy(() => import('../../scenes/Pharmacy/Index'));
export const AsyncPreferences = React.lazy(() => import('../../scenes/Preferences/Index'));
export const AsyncPricelists = React.lazy(() => import('../../scenes/Pricing/Index'));
export const AsyncProcedures = React.lazy(() => import('../../scenes/Procedures/Index'));
export const AsyncQuickpicks = React.lazy(() => import('../../scenes/Quickpicks/Index'));
export const AsyncReports = React.lazy(() => import('../../scenes/Reports/Index'));
export const AsyncScheduledVisits = React.lazy(() => import('../../scenes/ScheduledVisits/Index'));
export const AsyncSetup = React.lazy(() => import('../../scenes/Setup/Index'));
export const AsyncTelehealth = React.lazy(() => import('../../scenes/Telehealth/Index'));
export const AsyncTranslations = React.lazy(() => import('../../scenes/Translations/Index'));
export const AsyncUnauth = React.lazy(() => import('../../scenes/Unauth/Index'));
export const AsyncVisits = React.lazy(() => import('../../scenes/Visits/Index'));
export const AsyncLists = React.lazy(() => import('../../scenes/Lists/Index'));
export const AsyncNotes = React.lazy(() => import('../../scenes/Notes/Index'));
