import React from 'react';
import { FaLanguage as TranslateIcon } from 'react-icons/fa';
import { useTranslationModeContext } from './TranslationModeContext';

/**
 *
 * Wrapper to show a quick link to a specific translation in the translation editor
 *
 */

interface TranslationWrapper extends React.HTMLAttributes<HTMLSpanElement> {
  id?: string;
}
export const TranslationWrapper: React.FC<TranslationWrapper> = (props) => {
  const { id, className = '', ...attrs } = props;

  const { devVisibility, onTranslateOpen } = useTranslationModeContext();

  const onClick = (id) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    onTranslateOpen(id);
  };

  return devVisibility && id ? (
    <span {...attrs} className={`${className} position-relative border-top border-primary`} onClick={onClick(id)}>
      <span className="translation-wrapper">
        <TranslateIcon className="text-primary" />
      </span>
      {props.children}
    </span>
  ) : (
    <span
      {...attrs}
      className={className}
      style={{
        maxWidth: '100%',
        wordWrap: 'break-word',
      }}
    >
      {props.children}
    </span>
  );
};
