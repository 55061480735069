import { BasicStudyTemplate } from 'api/interfaces';
import { useStudyTemplates } from 'components-ts/Lab';
import { useDynamicTranslation } from 'components-ts/i18n';
import { Nullable } from 'components-ts/utils';
import { useMemo } from 'react';

type TranslatedStudy = BasicStudyTemplate & { translatedName: string; translatedDescription: Nullable<string> };

export const useTranslatedStudies = () => {
  const { translateDynamic } = useDynamicTranslation();

  const { studies, isLoading, error } = useStudyTemplates();

  const translatedStudies: Array<TranslatedStudy> = useMemo(() => {
    const translated = studies.map((study) => {
      const { name, description } = study;

      const translatedName = translateDynamic(name?.text ?? '');
      const translatedDescription = translateDynamic(description?.text ?? '');

      return {
        ...study,
        translatedName: translatedName ?? name.text ?? '',
        translatedDescription: translatedDescription ?? description?.text ?? null,
      };
    });

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      if (b.translatedName > a.translatedName) {
        return -1;
      }

      return 0;
    });

    return sorted;
  }, [studies, translateDynamic]);

  return {
    studies,
    translatedStudies,
    isLoading,
    error,
  };
};
