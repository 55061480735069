import { Nullable } from 'components-ts/utils';
import { Sex, Signature } from './common';
import { CustomFieldWithValue } from './customFields';
import { ActiveVisit, WaitingPatientVisit } from './visits';
import { Integrations } from './integrations';

export interface PatientBpjsSettings {
  cardNumber: Nullable<string>;
}

export interface PatientIntegrationsSettings {
  [Integrations.BPJS]: PatientBpjsSettings;
}

export interface FullAddress {
  line_1: Nullable<string>;
  line_2: Nullable<string>;
  line_3: Nullable<string>;
  locality: Nullable<string>;
  postal_code: Nullable<string>;
  province: Nullable<string>;
  country: Nullable<string>;
}

export interface PatientEmergencyContact {
  first_name: Nullable<string>;
  last_name: Nullable<string>;
  relationship_to_patient: Nullable<string>;
  phone_number: Nullable<string>;
}

export interface BasicPatient {
  id: string;
  mr: Nullable<number>;
  first_name: string;
  last_name: string;
  birthdate: string;
  sex: Sex;
  original_location: Nullable<string>;
  phone_number: Nullable<string>;
  phone_number_e164: Nullable<string>;
  email: Nullable<string>;
  national_id: Nullable<string>;
  address: Nullable<FullAddress>;
  updated: Nullable<Signature>;
  created: Nullable<Signature>;
  integrations: Nullable<PatientIntegrationsSettings>;
}

export interface FullPatient extends BasicPatient {
  customFields: Nullable<Array<CustomFieldWithValue>>;
  emergency_contact: Nullable<PatientEmergencyContact>;
  clinic_id: Nullable<string>;
  national_id: Nullable<string>;
  jkn: Nullable<string>;
  private_insurance_number: Nullable<string>;
  pricelist: Nullable<string>;
}

export type BasicPatientWithoutId = Omit<BasicPatient, 'id'> & { patientId: string };

export interface FullPatientWithoutId extends BasicPatientWithoutId {
  customFields: Nullable<Array<CustomFieldWithValue>>;
  emergency_contact: Nullable<PatientEmergencyContact>;
  clinic_id: Nullable<string>;
  national_id: Nullable<string>;
  jkn: Nullable<string>;
  private_insurance_number: Nullable<string>;
  pricelist: Nullable<string>;
}

export interface FullPatientWithActiveVisit extends FullPatient {
  active_visit: ActiveVisit;
}

export interface WaitingPatient extends BasicPatient {
  active_visit: WaitingPatientVisit;
}
export interface NewFullAddress {
  line_1?: string;
  line_2?: string;
  line_3?: string;
  locality?: string;
  postal_code?: string;
  province?: string;
  country?: string;
}

export interface NewPatientPersonalInfo {
  first_name: string;
  last_name: string;
  sex: string;
  birthdate: string;
  address?: NewFullAddress;
  email?: string;
  phone_number?: string;
  phone_number_e164?: string;
  original_location?: string;
  registration_start: string;
}

export interface NewEmergencyContact {
  first_name?: string;
  last_name?: string;
  relationship_to_patient?: string;
  phone_number?: string;
}

export interface NewPatientPaymentInfo {
  clinic_id?: string;
  national_id?: string;
  jkn?: string;
  private_insurance_number?: string;
  pricelist?: string;
}
