import React, { useContext } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { BasicChecklist, ComplaintLists, FullExam, FullList, PaginatedResult } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { WD_CONTENT } from 'api/request/wdContent';
import { useMySystemSettings } from 'components-ts/HealthSystems';
import { AppScenes } from './sitemap';
import { useSitemapAccess } from './useSitemap';
import { Alert, Button } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { LoadingInline } from 'components-ts/Loading';

const messages = defineMessages({
  error: {
    id: 'ChartProvider.walking_doctors_content_could_not_be_loaded',
    defaultMessage: 'Something went wrong while getting the chart content.',
  },
  reload: {
    id: 'UI.button_reload',
    defaultMessage: 'Reload',
  },
});

type Variables = {
  ids: Array<string>;
};

type Data = {
  checklists: PaginatedResult<BasicChecklist>;
  complaintLists: ComplaintLists;
  lists: PaginatedResult<FullList>;
  exams: PaginatedResult<FullExam>;
};

export type ChartContextValue = {
  data: Nullable<Data>;
  isLoading: boolean;
  error: Nullable<MessageDescriptor>;
  refetch: () => void;
};

export const ChartContext = React.createContext<Nullable<ChartContextValue>>(null);

export const ChartProvider: React.FC = (props) => {
  const { subscriptions } = useMySystemSettings();
  const items = subscriptions.checklists.items ?? [];

  const { canUserAccessToRoute } = useSitemapAccess();
  const isNeeded = canUserAccessToRoute(AppScenes.PATIENTS, 'exam');

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);
  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(messages.error);
    }
  };

  const onCompleted = () => {
    setError(null);
  };

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<Data, Variables>(WD_CONTENT, {
    onError,
    onCompleted,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    skip: !isNeeded,
    variables: {
      ids: items,
    },
  });

  const value: ChartContextValue = {
    data: data ?? null,
    isLoading,
    refetch,
    error,
  };

  return <ChartContext.Provider value={value}>{props.children}</ChartContext.Provider>;
};

export const ChartProviderErrorHandler: React.FC = (props) => {
  const intl = useIntl();

  const ctx = useContext(ChartContext);
  validateContextValue(ctx);

  const { error, refetch, isLoading } = ctx;

  const onClick = () => {
    refetch();
  };
  return (
    <>
      {props.children}
      {error && (
        <Alert
          className={'alert-danger d-flex align-items-center w-100 position-absolute m-0 justify-content-between'}
          style={{ bottom: 50 }}
        >
          <T id={error.id}>{intl.formatMessage(error)}</T>
          <Button className={'d-flex align-items-center'} onClick={onClick}>
            <T id={messages.reload.id} className="mr-1">
              {intl.formatMessage(messages.reload)}
            </T>
            {isLoading && <LoadingInline />}
          </Button>
        </Alert>
      )}
    </>
  );
};

function validateContextValue(value: Nullable<ChartContextValue>): asserts value is ChartContextValue {
  if (value === null) {
    throw TypeError('Using context out of the provider');
  }
}
