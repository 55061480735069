import React from 'react';
import { ItemSubscriptions } from 'api/interfaces';
import { defineMessages, useIntl } from 'react-intl';
import { useSubscribeToProceduresBatch } from './useProceduresActions';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useDisclosure, LastUpdated } from 'components-ts';
import { Badge, Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { LoadingInline } from 'components-ts/Loading';
import { useSystems } from './useSystems';
import { useTranslatedProcedures } from './useTranslatedProcedures';
import { IdTagTranslatedSubscriptionForm } from './IdTagTranslatedSubscriptionForm';

const messages = defineMessages({
  title: {
    id: 'ProceduresTab.label_procedure_subscriptions',
    defaultMessage: 'Procedure',
  },
  description: {
    id: 'ProceduresTab.choose_the_procedures_you_want_to_show_in_your_system',
    defaultMessage: 'Library of procedures available in your facility',
  },
  noSubscriptions: {
    id: 'UI.label_no_subscriptions',
    defaultMessage: 'No Subscriptions',
  },
  subscribedLabel: {
    id: 'UI.label_subscribed',
    defaultMessage: 'Subscribed ({count})',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
});

type ProceduresSubscriptionsProps = {
  systemId: string;
  locationId: string;
  subscriptions: ItemSubscriptions;
  isDisabled?: boolean;
};

const ProceduresSubscriptions: React.FC<ProceduresSubscriptionsProps> = props => {
  const { systemId, locationId, subscriptions, isDisabled } = props;

  const intl = useIntl();

  const { getLocation } = useSystems();

  const location = getLocation(systemId, locationId);

  const {
    translatedProcedures,
    isLoading: isLoadingProcedures,
    error: errorLoadingProcedures,
  } = useTranslatedProcedures();

  const {
    onSubmit,
    isLoading,
    error: submittingError,
  } = useSubscribeToProceduresBatch({
    systemId: systemId,
    locationId: locationId,
  });

  const error = errorLoadingProcedures ?? submittingError;

  return (
    <ModalBody>
      <IdTagTranslatedSubscriptionForm
        error={error}
        onSubmit={onSubmit}
        isLoading={isLoadingProcedures}
        items={translatedProcedures}
        initialValues={location?.subscriptions.procedures.items}
      >
        <div className='d-flex align-items-center justify-content-between'>
          <Button type='submit' color='primary' disabled={isDisabled}>
            <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
            {isLoading && <LoadingInline className='ml-1' />}
          </Button>
          {subscriptions.updated && <LastUpdated signature={subscriptions.updated} />}
        </div>
      </IdTagTranslatedSubscriptionForm>
    </ModalBody>
  );
};

interface ProcedureSubscriptionSectionProps {
  systemId: string;
  locationId: string;
  subscriptions: ItemSubscriptions;
  className?: string;
  isDisabled?: boolean;
}

export const ProcedureSubscriptionSection: React.FC<ProcedureSubscriptionSectionProps> = props => {
  const { systemId, locationId, subscriptions, className = '', isDisabled } = props;

  const intl = useIntl();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const count = subscriptions.items?.length ?? 0;
  return (
    <div
      onClick={onOpen}
      className={'d-flex align-items-center justify-content-between pointer animated-border-on-hover rounded p-3'}
    >
      <div className='flex-wrap'>
        <h6 className='mb-1'>
          <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
        </h6>
        <p className='text-muted mb-2'>
          <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
        </p>
      </div>

      {count > 0 ? (
        <Badge color='success' className={`no-wrap ${className}`}>
          <T id={messages.subscribedLabel.id}>{intl.formatMessage(messages.subscribedLabel, { count })}</T>
        </Badge>
      ) : (
        <Badge className={`no-wrap ${className}`}>
          <T id={messages.noSubscriptions.id}>{intl.formatMessage(messages.noSubscriptions)}</T>
        </Badge>
      )}
      {isOpen && (
        <Modal isOpen={true} toggle={onClose}>
          <ModalHeader toggle={onClose}>
            <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
          </ModalHeader>
          <ProceduresSubscriptions
            isDisabled={isDisabled}
            systemId={systemId}
            locationId={locationId}
            subscriptions={subscriptions}
          />
        </Modal>
      )}
    </div>
  );
};
