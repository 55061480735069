import { Nullable } from 'components-ts/utils';
import { useContext } from 'react';
import { PharmacyItemsContext, PharmacyItemsContextValue } from './PharmacyItemsContext';

export const usePharmacyItems = () => {
  const value = useContext(PharmacyItemsContext);
  validateContextValue(value);

  return value;
};

function validateContextValue(value: Nullable<PharmacyItemsContextValue>): asserts value is PharmacyItemsContextValue {
  if (value === null) {
    throw TypeError('Using pharmacy context out of the provider');
  }
}
