import React from 'react';
import { DynamicTranslation } from 'components-ts/i18n';
import { TranslatableText } from 'api/interfaces';
import { Nullable } from 'components-ts/utils';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { ClickableItemWrapper } from 'components-ts/SearchableList';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Button, ButtonGroup, FormGroup, Label, Input } from 'reactstrap';
import Highlighter from 'react-highlight-words';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { ScrollableList } from 'components-ts/ScrollableList';

const messages = defineMessages({
  tagsTitle: {
    id: 'StudiesFirstStep.tags_title',
    defaultMessage: 'You can choose from our predefined selection groups',
  },
  onlySelectedLabel: {
    id: 'UI.show_only_selected',
    defaultMessage: 'Only selected',
  },
});

const PERCENTAGE_TO_SELECT_TAG = 0.7;

type TranslatedItem = {
  id: string;
  tags: Nullable<Array<TranslatableText>>;
  name: TranslatableText;
  translatedName: string;
  translatedDescription: Nullable<string>;
};

type Props = {
  items: Array<TranslatedItem>;
  onSubmit: (selectedIds: Array<string>) => void;
  error: Nullable<MessageDescriptor>;
  initialValues?: Array<string>;
  isLoading?: boolean;
  title?: MessageDescriptor;
  children?: (() => React.ReactNode) | React.ReactNode;
};

interface TagSelectionParams {
  items: Array<TranslatedItem>;
  onFilterOnlySelected: (onlySelected: boolean) => void;
  initialValues?: Array<string>;
}

const useTagSelection = (params: TagSelectionParams) => {
  const { items, onFilterOnlySelected, initialValues } = params;

  const tags = React.useMemo(() => {
    return items.reduce((set, item) => {
      item.tags?.forEach(trTag => {
        if (trTag.text) {
          set.add(trTag.text);
        }
      });

      return set;
    }, new Set<string>());
  }, [items]);

  const initialSelectedIds = new Set(initialValues);

  const [selectedIds, setSelectedIds] = React.useState<Set<string>>(initialSelectedIds);
  const [selectedTags, setSelectedTags] = React.useState<Set<string>>(new Set());

  const onUpdateAll = (newSelection: Set<string>) => {
    setSelectedIds(newSelection);
  };

  const onAddItem = id => {
    setSelectedIds(prev => {
      prev.add(id);
      return new Set(prev);
    });
  };

  const onDeleteItem = id => {
    if (selectedIds.size === 1 && selectedIds.has(id)) {
      onFilterOnlySelected(false);
      setSelectedTags(new Set());
    }

    selectedTags.forEach(t => {
      const thisTagItems = items.filter(i => i.tags?.map(t => t.text).includes(t));

      const thisTagSelectedItems = thisTagItems.filter(i => i.id !== id && selectedIds.has(i.id));

      const selectedItemsPercentage = thisTagSelectedItems.length / thisTagItems.length;

      if (selectedItemsPercentage < PERCENTAGE_TO_SELECT_TAG) {
        removeTagFromList(t);
      }
    });

    setSelectedIds(prev => {
      prev.delete(id);

      return new Set(prev);
    });
  };

  const removeTagFromList = (tag: string) => {
    setSelectedTags(prev => {
      prev.delete(tag);
      return new Set(prev);
    });
  };

  const onTagClick = tag => {
    if (selectedTags.has(tag)) {
      // update tag list
      setSelectedTags(prev => {
        prev.delete(tag);
        return new Set(prev);
      });

      // find and remove the corresponding ones
      const newSelectedIds = new Set(selectedIds);
      items.forEach(study => {
        const isTagIncluded = study.tags?.some(trTag => trTag.text === tag);
        if (isTagIncluded) {
          newSelectedIds.delete(study.id);
        }
      });

      if (newSelectedIds.size === 0) {
        onFilterOnlySelected(false);
      }

      // update actual state
      onUpdateAll(newSelectedIds);
    } else {
      // update tag list
      setSelectedTags(prev => {
        prev.add(tag);
        return new Set(prev);
      });

      // find and add the new ones
      const newSelectedIds = new Set(selectedIds);
      items.forEach(study => {
        const isTagIncluded = study.tags?.some(trTag => trTag.text === tag);
        if (isTagIncluded) {
          newSelectedIds.add(study.id);
        }
      });

      // update actual state
      onUpdateAll(newSelectedIds);

      // only show selected automatically
      onFilterOnlySelected(true);
    }
  };

  React.useEffect(() => {
    if (initialValues && initialValues.length > 0 && tags.size > 0 && items.length > 0) {
      const initialSelectedTags = new Set(
        Array.from(tags).reduce((acc, tag) => {
          const thisTagItems = items.filter(i => i.tags?.map(t => t.text).includes(tag));
          const thisTagSelectedItems = thisTagItems.filter(i => initialValues?.includes(i.id));

          const selectedItemsPercentage =
            thisTagItems.length > 0 ? thisTagSelectedItems.length / thisTagItems.length : 0;

          const isPercentageToSelectTag = selectedItemsPercentage >= PERCENTAGE_TO_SELECT_TAG;

          if (isPercentageToSelectTag) {
            return [...acc, tag];
          }

          return acc;
        }, [] as Array<string>)
      );

      setSelectedTags(initialSelectedTags);
    }
  }, [initialValues, tags, items]);

  return {
    tags,
    selectedIds,
    selectedTags,
    onTagClick,
    onAddItem,
    onDeleteItem,
    removeTagFromList,
  };
};

export const IdTagTranslatedSubscriptionForm: React.VFC<Props> = props => {
  const { onSubmit, error, items, isLoading, initialValues, title } = props;
  const intl = useIntl();

  const [onlySelected, setOnlySelected] = React.useState(false);
  const onFilterOnlySelected = (onlySelected: boolean) => {
    setOnlySelected(onlySelected);
  };

  const { tags, selectedIds, selectedTags, onTagClick, onAddItem, onDeleteItem } = useTagSelection({
    items,
    onFilterOnlySelected,
    initialValues,
  });

  const shownItems = React.useMemo(() => {
    if (onlySelected) {
      return items.filter(study => selectedIds.has(study.id));
    }

    return items;
  }, [onlySelected, items, selectedIds]);

  const isTagSelected = (tag: string) => selectedTags.has(tag);
  const isSelected = (item: TranslatedItem) => selectedIds.has(item.id);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(Array.from(selectedIds));
      }}
    >
      <div>
        <div className='d-flex flex-wrap justify-content-between mb-2'>
          <div>
            <h6>
              <T id={messages.tagsTitle.id}>{intl.formatMessage(messages.tagsTitle)}</T>
            </h6>

            <div className='d-flex flex-wrap align-items-center'>
              {Array.from(tags).map(tag => (
                <TagButton key={tag} isSelected={isTagSelected(tag)} onClick={onTagClick} tag={tag} />
              ))}
            </div>
          </div>
        </div>
        <div className='mt-3'>
          <div className='d-flex flex-wrap justify-content-between'>
            {title && (
              <h6 className='font-weight-bolder'>
                <T id={title.id}>{intl.formatMessage(title)}</T>
              </h6>
            )}
            <FormGroup check inline className='mx-0 mb-1'>
              <Input
                id='only-selected-checkbox'
                type='checkbox'
                checked={onlySelected}
                onChange={e => onFilterOnlySelected(e.target.checked)}
                className='m-0 mr-1 pointer'
                disabled={selectedIds.size === 0}
              />
              <Label check className='d-flex align-items-center pointer' for='only-selected-checkbox'>
                <T id={messages.onlySelectedLabel.id} className='mr-1'>
                  {intl.formatMessage(messages.onlySelectedLabel)}
                </T>
                {`(${selectedIds.size})`}
              </Label>
            </FormGroup>
          </div>
          <ScrollableList
            items={shownItems}
            searchableProperties={['translatedName']}
            itemsToShow={5}
            isURLSync={false}
            isLoading={isLoading}
            renderItem={(item, _idx, searchTerms) => (
              <CustomItem
                onSubscribe={onAddItem}
                onUnsubscribe={onDeleteItem}
                key={item.id}
                item={item}
                searchTerms={searchTerms}
                isSelected={isSelected(item)}
              />
            )}
          />
        </div>
        {error && <ErrorViewer error={error} />}
      </div>
      {props.children ? typeof props.children === 'function' ? <>{props.children()}</> : <>{props.children}</> : null}
    </form>
  );
};

type CustomItemProps = {
  item: TranslatedItem;
  searchTerms: Array<string>;
  onSubscribe: (id: string) => void;
  onUnsubscribe: (id: string) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
};

const CustomItem: React.FC<CustomItemProps> = props => {
  const { item, isSelected, searchTerms, onSubscribe, onUnsubscribe, isDisabled } = props;
  const { id, name, translatedName } = item;

  const onClick = () => {
    if (isSelected) {
      onUnsubscribe(id);
    } else {
      onSubscribe(id);
    }
  };

  const translationId = `Dynamic.${name.text}`;

  return (
    <ClickableItemWrapper
      key={translationId}
      onClick={onClick}
      className='d-flex align-items-center justify-content-between'
      isDisabled={isDisabled}
    >
      <T id={translationId}>
        <Highlighter
          className='text-capitalize font-weight-bold'
          searchWords={searchTerms}
          autoEscape
          textToHighlight={translatedName}
        />
      </T>
      {isSelected && <FaCheckCircle style={{ minWidth: 24, maxWidth: 24 }} className='text-success h4 m-0' />}
    </ClickableItemWrapper>
  );
};

type TagButtonProps = {
  tag: string;
  onClick: (tag: string) => void;
  isSelected: boolean;
};

const TagButton: React.FC<TagButtonProps> = props => {
  const { tag, isSelected, onClick } = props;

  if (isSelected) {
    return (
      <ButtonGroup size='sm' className='mr-1 mb-1' onClick={() => onClick(tag)}>
        <Button color='secondary' outline className='btn-danger'>
          <FaTimes />
        </Button>
        <Button color='secondary'>{tag && <DynamicTranslation text={tag} />}</Button>
      </ButtonGroup>
    );
  }

  return (
    <Button color='secondary' outline={!isSelected} className='btn-sm mr-1 mb-1' onClick={() => onClick(tag)}>
      {tag && <DynamicTranslation text={tag} />}
    </Button>
  );
};
