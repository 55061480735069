import { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'

const useLazyQueryAsPromise = (query) => {
  const [lazyQuery, { data, error }] = useLazyQuery(query)

  const promiseRef = useRef(null)

  useEffect(() => {
    if (promiseRef.current) {
      if (data && typeof promiseRef.current.resolve === 'function') {
        promiseRef.current.resolve(data)
      }

      if (error && typeof promiseRef.current.reject === 'function') {
        promiseRef.current.reject(error)
      }
    }
  }, [data, error])

  const fn = async (options) => {
    lazyQuery(options)

    return new Promise((resolve, reject) => {
      promiseRef.current = {
        resolve,
        reject
      }
    })
  }

  // return initial value
  return useRef(fn).current
}

export default useLazyQueryAsPromise
