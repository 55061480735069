import React from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { FullPatientWithActiveVisit, PaginatedResult } from '../../api/interfaces';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { GET_INPATIENTS } from 'api/request/patients';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

export interface InpatientsVariables {
  q?: string;
  offset?: number;
  limit?: number;
  location: string;
}

interface InpatientsQueryData {
  inpatients: PaginatedResult<FullPatientWithActiveVisit>;
}

export type UseInpatientsParams = QueryHookOptions<InpatientsQueryData, InpatientsVariables>;

interface ReturnedValue {
  patients: Array<FullPatientWithActiveVisit>;
  count: number;
  isLoading: boolean;
  error: Nullable<MessageDescriptor>;
  onErrorClose: () => void;
  refetch: (newArgs?: InpatientsVariables) => void;
}

export const useInpatients = (params: UseInpatientsParams): ReturnedValue => {
  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors);
    translateError(firstError);
  };

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<InpatientsQueryData, InpatientsVariables>(GET_INPATIENTS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    ...params,
    onError,
  });

  const onErrorClose = () => {
    setError(null);
  };

  return {
    isLoading,
    count: data && data.inpatients ? data.inpatients.count : 0,
    patients: data && data.inpatients ? data.inpatients.docs : [],
    error,
    onErrorClose,
    refetch,
  };
};
