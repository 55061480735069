import React from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { FullStudyWithOrder } from 'api/interfaces';
import { GET_PATIENT_STUDY_HISTORY } from 'api/request/lab';
const messages = defineMessages({
  error: {
    id: 'StudyHistory.error_getting_study_history',
    defaultMessage: 'Could not get the patient history',
  },
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Please, try again.',
  },
});
interface StudyHistoryVariables {
  id: string;
  offset?: number;
  limit?: number;
}

interface Result {
  docs: Array<FullStudyWithOrder>;
  count: number;
}

interface StudyHistoryData {
  studyHistory: Result;
}

export type UseStudyHistoryParams = QueryHookOptions<StudyHistoryData, StudyHistoryVariables> & StudyHistoryVariables;

export function usePatientStudyHistory(params: UseStudyHistoryParams) {
  const { id, limit, offset, ...rest } = params;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_getting_study_history':
        return setError(messages.error);
      case 'invalid_signature':
      default:
        return setError(messages.unexpectedError);
    }
  };

  const onCompleted = (data) => {
    if (typeof rest.onCompleted === 'function') {
      rest.onCompleted(data);
    }
  };

  const { data, loading: isLoading } = useQuery(GET_PATIENT_STUDY_HISTORY, {
    variables: {
      id,
      limit,
      offset,
    },
    ...rest,
    skip: !id,
    onError,
    onCompleted,
  });

  return {
    isLoading,
    docs: data ? data.studyHistory.docs : [],
    count: data ? data.studyHistory.count : 0,
    error,
  };
}
