import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { AiFillPrinter } from 'react-icons/ai';
import { Button } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';

const messages = defineMessages({
  printVisitButton: {
    id: 'UI.print_button',
    defaultMessage: 'Print',
  },
});

export const PAGE_STYLE_A4_LANDSCAPE = `@page {
  size: A4 landscape;
}`;

type PrintButtonProps = {
  componentToPrint: React.RefObject<HTMLDivElement>;
  pageStyle?: string;
  className?: string;
  onBeforeGetContent?: () => Promise<void>;
  onAfterPrint?: () => void;
};

export const PrintButton: React.FC<PrintButtonProps> = (props) => {
  const { componentToPrint, pageStyle, className = '', onBeforeGetContent, onAfterPrint } = props;

  const intl = useIntl();

  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
    pageStyle,
    onBeforeGetContent,
    onAfterPrint,
  });

  return (
    <Button className={className} onClick={() => handlePrint()}>
      <AiFillPrinter className="mr-1" />
      {intl.formatMessage(messages.printVisitButton)}
    </Button>
  );
};
