import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Button } from 'reactstrap'
import { LoadingInline } from '../components-ts/Loading'
import { SUBMIT_BUTTON_STATES, SUBMIT_BUTTON_ACTIONS } from 'utils/components/definitions'
/**
 * Simple abstraction of the submit button used on every form
 */

const debug = require('debug')('wd:SubmitButon')

// static translations
const messages = defineMessages({
  createButton: {
    id: 'UI.button_create',
    defaultMessage: 'Create'
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save'
  },
  addButton: {
    id: 'UI.button_add',
    defaultMessage: 'Add'
  },
  editButton: {
    id: 'UI.button_edit',
    defaultMessage: 'Edit'
  },
  submitButton: {
    id: 'NonStaffUI.button_submit',
    defaultMessage: 'Submit'
  }
})

const SubmitButton = (props) => {
  // props
  const {
    state = SUBMIT_BUTTON_STATES.IDLE,
    customLabel,
    action,
    onClick,
    disabled,
    type,
    className,
    intl
  } = props

  const { formatMessage } = intl

  const color = disabled
    ? 'light'
    : state === SUBMIT_BUTTON_STATES.DONE
      ? 'success'
      : 'primary'

  const getLabel = action => {
    switch (action) {
      case SUBMIT_BUTTON_ACTIONS.CREATE:
        return <T id={messages.createButton.id}>{formatMessage(messages.createButton)}</T>
      case SUBMIT_BUTTON_ACTIONS.SAVE:
        return <T id={messages.saveButton.id}>{formatMessage(messages.saveButton)}</T>
      case SUBMIT_BUTTON_ACTIONS.ADD:
        return <T id={messages.addButton.id}>{formatMessage(messages.addButton)}</T>
      case SUBMIT_BUTTON_ACTIONS.EDIT:
        return <T id={messages.editButton.id}>{formatMessage(messages.editButton)}</T>
      default:
        return <T id={messages.submitButton.id}>{formatMessage(messages.submitButton)}</T>
    }
  }
  return <Button
    color={color}
    type={type}
    className={className}
    onClick={typeof onClick === 'function' ? onClick : () => debug('onClick not defined')}
    disabled={disabled}
  >
    { customLabel
      ? <span className='mr-1'>{customLabel}</span>
      : <span className='mr-1'>
        {getLabel(action)}
      </span>
    }
    {
      // loading icon
      state === SUBMIT_BUTTON_STATES.LOADING && <LoadingInline />
    }
  </Button>
}

export default injectIntl(SubmitButton)
