import React from 'react';
import { Field, Form } from 'react-final-form';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import { ErrorViewer } from '../ErrorViewer';
import { ValidateFn } from 'components-ts/Forms/utils';
import { Nullable } from 'components-ts/utils';
import { Button, Col, FormGroup, Label, Input } from 'reactstrap';
import { TimeUnitSelector } from 'components-ts/Selectors';
import { LoadingInline } from 'components-ts/Loading';
import { NewSchedulerSettingsFormValues } from 'components-ts/HealthSystems/useSchedulerActions';

const messages = defineMessages({
  slotUnit: {
    id: 'TelehealthTab.label_slot_unit',
    defaultMessage: 'Slot unit',
  },
  slotLength: {
    id: 'TelehealthTab.label_slot_length',
    defaultMessage: 'Slot length',
  },
  notifications: {
    id: 'TelehealthTab.label_patient_notifications',
    defaultMessage: 'Appointment Reminders',
  },
  businessHours: {
    id: 'TelehealthTab.label_businessHours',
    defaultMessage: 'Show business hours only',
  },
  businessHoursDescription: {
    id: 'TelehealthTab.label_businessHours_description',
    defaultMessage: 'Limits calendar view to business hours, Mon-Fri, 7AM-8PM',
  },
  notificationsDescriptions: {
    id: 'TelehealthTab.label_patient_notifications_description',
    defaultMessage:
      'Enables notifications, sending reminders to patients 3 days and 2 hours before their scheduled appointment',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
});

interface SchedulerSettingsFormProps {
  initialValues?: NewSchedulerSettingsFormValues;
  onSubmit: (values: NewSchedulerSettingsFormValues) => void;
  isLoading?: boolean;
  validate: ValidateFn<NewSchedulerSettingsFormValues>;
  error: Nullable<MessageDescriptor>;
  onErrorClose: () => void;
}

export const SchedulerSettingsForm: React.FC<SchedulerSettingsFormProps> = (props) => {
  const { initialValues, validate, error, onSubmit, isLoading } = props;

  const intl = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field name="length">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.slotLength.id}>{intl.formatMessage(messages.slotLength)}</T>
                    </Label>
                    <Input
                      {...input}
                      type={'number'}
                      className="form-control"
                      autoComplete="off"
                      placeholder={intl.formatMessage(messages.slotLength)}
                      maxLength={100}
                    />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="unit">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.slotUnit.id}>{intl.formatMessage(messages.slotUnit)}</T>
                    </Label>
                    <TimeUnitSelector {...input} />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="businessHours">
              {({ input, meta }) => (
                <Col className="px-0 mb-4">
                  <FormGroup className="d-flex align-items-center m-0 my-1">
                    <Label className="switch switch-pill switch-primary m-0">
                      <Input
                        type="checkbox"
                        className="switch-input"
                        onChange={(e) => input.onChange(e.target.checked)}
                        defaultChecked={meta.initial}
                      />
                      <span className="switch-slider m-0 d-flex" />
                    </Label>

                    <div className="d-flex flex-column">
                      <span className="m-0 ml-2">
                        <T id={messages.businessHours.id}>{intl.formatMessage(messages.businessHours)}</T>
                      </span>
                      <small className="ml-2 text-muted">
                        <T id={messages.businessHoursDescription.id}>
                          {intl.formatMessage(messages.businessHoursDescription)}
                        </T>
                      </small>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Field>
            <Field name="notifications">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="d-flex align-items-center m-0 my-1">
                    <div className="d-flex flex-grow-1">
                      <Label className="switch switch-pill switch-primary  m-0">
                        <Input
                          type="checkbox"
                          className="switch-input"
                          onChange={(e) => input.onChange(e.target.checked)}
                          defaultChecked={meta.initial}
                        />
                        <span className="switch-slider m-0 d-flex" />
                      </Label>
                    </div>

                    <div className="d-flex flex-column">
                      <span className="m-0 ml-2">
                        <T id={messages.notifications.id}>{intl.formatMessage(messages.notifications)}</T>
                      </span>
                      <small className="ml-2 text-muted text-wrap">
                        <T id={messages.notificationsDescriptions.id}>
                          {intl.formatMessage(messages.notificationsDescriptions)}
                        </T>
                      </small>
                    </div>
                  </FormGroup>
                </Col>
              )}
            </Field>
            {error && <ErrorViewer error={error} />}

            <div className="mt-4">
              <Button color={'primary'} className="ml-1" type="submit" disabled={pristine}>
                <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
                {isLoading && <LoadingInline className="ml-1" />}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};
