import React from 'react';
import { getEnvs } from 'components-ts/envs';

export const Footer = () => {
  const { release, landing } = getEnvs();
  return (
    <footer className="app-footer hidden-print d-flex justify-content-between ml-0">
      <div className="text-muted">
        &copy; {new Date().getFullYear()}{' '}
        <a href={landing} rel="noreferrer noopener" target="_blank">
          Walking Doctors
        </a>
      </div>
      {release ? (
        <div className="text-muted">
          {' '}
          v <b>{release}</b>{' '}
        </div>
      ) : (
        <span />
      )}
    </footer>
  );
};
