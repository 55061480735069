import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { ADD_PROCEDURE_ORDER_TO_ACTIVE_VISIT } from 'scenes/Patients/ActiveVisit/requests';
import { FullPatientWithActiveVisit, NewProcedureOrder } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';

const messages = defineMessages({
  studyNotFound: {
    id: 'UseOrderActions.study_not_found',
    defaultMessage: 'The selected study was not found.',
  },
  patientNotFound: {
    id: 'UseOrderActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  errorAddingStudyOrder: {
    id: 'UseOrderActions.error_adding_study_order',
    defaultMessage: 'We could not create the study, try again later.',
  },
  invalidProcedureId: {
    id: 'UseOrderActions.invalid_procedure_id',
    defaultMessage: 'You must select a valid procedure.',
  },
  invalidStudyTemplateId: {
    id: 'UseOrderActions.invalid_study_template_id',
    defaultMessage: 'You must select a valid procedure.',
  },
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Try again',
  },
});
interface AddProcedureOrderData {
  addProcedureOrderToActiveVisit: FullPatientWithActiveVisit;
}

interface AddProcedureOrderVariables {
  patientId: string;
  visitId: string;
  procedureOrder: NewProcedureOrder;
}

type UseAddProcedureOrderProps = MutationHookOptions<AddProcedureOrderData, AddProcedureOrderVariables> & {
  patientId: string;
  visitId: string;
};

export const useAddProcedureOrder = (props: UseAddProcedureOrderProps) => {
  const { patientId, visitId, ...rest } = props;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'patient_not_found':
        return setError(messages.patientNotFound);

      case 'invalid_procedure_id':
        return setError(messages.invalidProcedureId);

      default:
        return setError(messages.unexpectedError);
    }
  };

  const onCompleted = (data: AddProcedureOrderData) => {
    setError(null);
    if (typeof rest?.onCompleted === 'function') {
      rest.onCompleted(data);
    }
  };

  const [addProcedureOrder, { loading: isLoading }] = useMutation(ADD_PROCEDURE_ORDER_TO_ACTIVE_VISIT, {
    ...rest,
    onError,
    onCompleted,
  });

  const onSubmit = (procedureOrder: NewProcedureOrder) => {
    setError(null);

    const variables = {
      patientId,
      visitId,
      procedureOrder,
    };

    addProcedureOrder({ variables });
  };

  return {
    error,
    onSubmit,
    isLoading,
  };
};
