import React, { useState } from 'react'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ErrorViewer, LoadingInline } from 'components'
import { injectIntl, defineMessages } from 'react-intl'
const debug = require('debug')('wd:ConfirmationModal')

// static translations
const messages = defineMessages({
  confirmation: {
    id: 'ConfirmationModal.title',
    defaultMessage: 'Confirmation'
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel'
  },
  confirmButton: {
    id: 'UI.button_confirm',
    defaultMessage: 'Confirm'
  }
})

const ConfirmationModal = (props) => {
  // props
  const { action, message, error, loading, disabled, submitClassName, className } = props
  const { formatMessage } = props.intl

  // state
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = event => {
    if (typeof props.onShow === 'function') props.onShow(event)
    setIsOpen(!isOpen)
  }

  const onConfirm = e => {
    if (typeof props.onConfirm === 'function') props.onConfirm(e)
  }

  return <Button color='primary' className={className} onClick={toggleModal} disabled={isOpen || disabled}>
    {action}
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop>
      <ModalHeader toggle={toggleModal}>
        <T id={messages.confirmation.id}>{formatMessage(messages.confirmation)}</T>
      </ModalHeader>
      <ModalBody>
        {message}
        {error && <ErrorViewer error={error} />}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' className='mr-1' onClick={toggleModal}>
          <T id={messages.cancelButton.id}>{formatMessage(messages.cancelButton)}</T>
        </Button>
        <Button onClick={onConfirm} className={submitClassName || 'btn-danger'}>
          <T id={messages.confirmButton.id}>{formatMessage(messages.confirmButton)}</T>
          {loading && <LoadingInline className='ml-2' />}
        </Button>
      </ModalFooter>
    </Modal>
  </Button>
}

ConfirmationModal.default = {
  onConfirm: () => debug('confirmed'),
  action: 'Action',
  message: 'Need confirmation'
}

export default injectIntl(ConfirmationModal)
