import React, { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Badge } from 'reactstrap';
import { ErrorViewer, LoadingInline } from 'components';
import { DrugDescription, DrugGuideline, DrugRecommendation } from 'components/Pharmacy';
import { EditableDrugRx, useAdministerRx, usePrescribeRx } from './';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { usePatientVitals } from 'components-ts/Patients/usePatientVitals';
import Highlighter from 'react-highlight-words';
import { FaChevronLeft as LeftArrowIcon } from 'react-icons/fa';
import { initialCalculation } from 'wd-common/src/logic/pharmacy/utils';
import { validateRXForm } from 'wd-common/src/logic/pharmacy/validations';
import { usePharmacyItems } from 'components-ts/Pharmacy';
import { usePatientContext } from 'components-ts/Patients/usePatientContext';
import { useSystems } from 'components-ts/HealthSystems';
import {
  Drug,
  Integrations,
  NewDrug,
  NewRX,
  NewRXDetails,
  NewUserGuidelineRX,
  SystemDrugGuideline,
  VitalType,
} from 'api/interfaces';
import { ScrollableList } from 'components-ts/ScrollableList';

const messages = defineMessages({
  searchDrugs: {
    id: 'RxModal.search_drugs',
    defaultMessage: 'Search drugs',
  },
  guideline: {
    id: 'RxModal.label_guideline',
    defaultMessage: 'Guideline',
  },
  pickADifferentDrug: {
    id: 'RxModal.pick_a_different_drug',
    defaultMessage: 'Pick a different drug',
  },
  administerButton: {
    id: 'UI.button_administer',
    defaultMessage: 'Administer',
  },
  prescribeButton: {
    id: 'UI.button_prescribe',
    defaultMessage: 'Prescribe',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  inStock: {
    id: 'RxModal.in_stock',
    defaultMessage: 'Stock',
  },
  outOfStock: {
    id: 'RxModal.out_of_stock',
    defaultMessage: 'Out of Stock',
  },
  bpjsLabel: {
    id: 'UI.bpjs',
    defaultMessage: 'BPJS',
  },
});

const getProperGuideline = (checklistGuideline, selectedDrug) => {
  if (checklistGuideline) return checklistGuideline;

  if (selectedDrug && selectedDrug.guideline) {
    /* eslint-disable camelcase */
    const {
      type,
      userGuidelineName,
      preText,
      doseUnits,
      doseRangeMin,
      doseRangeMax,
      doses_per_day,
      max_dose,
      max_dose_units,
      route,
      postText,
      frequency_show,
      as_needed,
      days,
    } = selectedDrug.guideline;
    /* eslint-enable camelcase */

    return {
      type,
      name: userGuidelineName,
      pre_text: preText,
      post_text: postText,
      dose_units: doseUnits,
      dose_range_min: doseRangeMin,
      dose_range_max: doseRangeMax,
      doses_per_day,
      max_dose,
      max_dose_units,
      route,
      frequency_show,
      as_needed,
      days,
    };
  }

  return null;
};

const getGuidelineName = (guideline) => {
  if (!guideline) {
    return '';
  }

  if (typeof guideline.name === 'string') {
    return guideline.name;
  }

  if (guideline.name) {
    return guideline.name.text;
  }

  return '';
};

/**
 * We tried to simplify the v1 rx store which calculates the dosing
 * That's why we split the calculation in two steps:
 * 1 - initial calculation based on the match drug and guideline
 * 2 - any single change will only modify the others corresponding fields
 *     (the rx store recalculates everything in each iteration)
 *
 * So, we use a memorized initialDetails to get the match and the dosing
 * and the 2nd step is handle by the editable drug form.
 */
interface ManualTabProps {
  locationId: string;
  guideline?: SystemDrugGuideline;
  onCompleted?: () => void;
}

export const ManualTab: React.FC<ManualTabProps> = (props) => {
  const { guideline, locationId } = props;

  const intl = useIntl();
  const [details, setDetails] = useState<NewRXDetails | null>();
  const [selectedDrug, setSelectedDrug] = useState<Drug | null>(null);

  // get the patient from cache
  const { patient, activeVisit } = usePatientContext();

  const patientId = patient.id;
  const visitId = activeVisit?.id;

  const { getVital } = usePatientVitals();
  const patientWeight = getVital(VitalType.WEIGHT);

  const properGuideline = React.useMemo(() => getProperGuideline(guideline, selectedDrug), [guideline, selectedDrug]);

  const initialDetails = useMemo(() => {
    return selectedDrug ? initialCalculation(properGuideline, selectedDrug, patientWeight) : null;
  }, [selectedDrug, patientWeight, properGuideline]);

  React.useEffect(() => {
    setDetails(initialDetails as NewRXDetails);
  }, [initialDetails]);

  const onDrugChange = (drug) => {
    setSelectedDrug(drug);
  };

  const onDetailsChange = (details) => {
    setDetails(details);
  };

  const pickADifferentDrug = () => {
    setDetails(null);
    setSelectedDrug(null);
  };

  const args = {
    onCompleted: props.onCompleted,
  };

  const { prescribeRX, isLoading: isPrescribing, error: prescribingError } = usePrescribeRx(args);
  const { administerRX, isLoading: iAdministering, error: administeringError } = useAdministerRx(args);

  const areActionsDisabled = !selectedDrug || !visitId || !details;

  const onPrescribeRX = () => {
    if (areActionsDisabled) {
      return null;
    }

    const validationErrors = validateRXForm(details);
    if (!validationErrors) {
      // clean drug
      const {
        id,
        class: drugClass,
        name,
        unit,
        retail,
        category,
        dosing,
        interactions,
        comments,
        recommended_dosing, //eslint-disable-line
        stock_unit, //eslint-disable-line
        minimum_units_to_dispense, //eslint-disable-line
      } = selectedDrug;

      const newDrug: NewDrug = {
        id,
        class: drugClass,
        name,
        unit,
        retail,
        category,
        dosing,
        interactions,
        comments,
        recommended_dosing, //eslint-disable-line
        stock_unit, //eslint-disable-line
        minimum_units_to_dispense, //eslint-disable-line
      };

      const rx: NewRX | NewUserGuidelineRX = {
        drug: newDrug,
        guideline: properGuideline,
        details,
        mixId: null,
        mixNotes: null,
      };

      prescribeRX(patientId, visitId, rx, locationId);
    }
  };

  const onAdministerRX = () => {
    if (areActionsDisabled) {
      return null;
    }

    const validationErrors = validateRXForm(details);
    if (!validationErrors) {
      const {
        id,
        class: drugClass,
        name,
        unit,
        retail,
        category,
        dosing,
        interactions,
        comments,
        recommended_dosing, //eslint-disable-line
        stock_unit, //eslint-disable-line
        minimum_units_to_dispense, //eslint-disable-line
      } = selectedDrug;

      const newDrug: NewDrug = {
        id,
        class: drugClass,
        name,
        unit,
        retail,
        category,
        dosing,
        interactions,
        comments,
        recommended_dosing, //eslint-disable-line
        stock_unit, //eslint-disable-line
        minimum_units_to_dispense, //eslint-disable-line
      };

      const rx: NewRX | NewUserGuidelineRX = {
        drug: newDrug,
        guideline: properGuideline,
        details,
        mixId: null,
        mixNotes: null,
      };

      administerRX(patientId, visitId, rx, locationId);
    }
  };

  const guidelineName = getGuidelineName(properGuideline);

  const error = prescribingError || administeringError;
  return (
    <div>
      {selectedDrug ? (
        <div>
          <DrugDescription drug={selectedDrug} guideline={properGuideline} />
          <DrugRecommendation drug={selectedDrug} />
          {!!properGuideline && (
            <Alert color="success">
              <b>
                <T id={messages.guideline.id}>{intl.formatMessage(messages.guideline)}</T>
                {':'}
              </b>
              <DrugGuideline drug={properGuideline} />
            </Alert>
          )}
          <EditableDrugRx
            patientId={patientId}
            guideline={properGuideline}
            drug={selectedDrug}
            initialDetails={initialDetails}
            onDetailsChange={onDetailsChange}
            patientWeight={patientWeight}
          />
        </div>
      ) : (
        <DrugSelection name={guidelineName} onSelect={onDrugChange} />
      )}

      {error && <ErrorViewer error={error} />}

      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex">
          {selectedDrug && (
            <Button onClick={pickADifferentDrug} className="d-flex align-items-center btn-danger mr-2">
              <LeftArrowIcon className="mr-1" />
              <span className="d-none d-sm-flex">
                <T id={messages.pickADifferentDrug.id}>{intl.formatMessage(messages.pickADifferentDrug)}</T>
              </span>
            </Button>
          )}
        </div>
        <ButtonGroup>
          {selectedDrug && (
            <Button
              color="primary"
              type="button"
              disabled={areActionsDisabled}
              onClick={onAdministerRX}
              className="mr-1"
            >
              <T id={messages.administerButton.id} className={isPrescribing ? 'mr-1' : ''}>
                {intl.formatMessage(messages.administerButton)}
              </T>
              {iAdministering && <LoadingInline />}
            </Button>
          )}
          {selectedDrug && (
            <Button color="primary" type="button" disabled={areActionsDisabled} onClick={onPrescribeRX}>
              <T id={messages.prescribeButton.id} className={iAdministering ? 'mr-1' : ''}>
                {intl.formatMessage(messages.prescribeButton)}
              </T>
              {isPrescribing && <LoadingInline />}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
};

interface DrugSelectionProps {
  name: string;
  onSelect: (value: Drug) => void;
}

const DrugSelection: React.FC<DrugSelectionProps> = (props) => {
  const { name, onSelect } = props;

  const intl = useIntl();
  const { pharmacyItems, isLoading } = usePharmacyItems();

  return (
    <div className="animated">
      <ScrollableList
        searcherPlaceholder={intl.formatMessage(messages.searchDrugs)}
        searchableProperties={['name', 'class']}
        items={pharmacyItems}
        itemsToShow={10}
        isLoading={isLoading}
        initialSearch={name}
        isURLSync={false}
        renderItem={(item, _, searchTerms) => (
          <CustomItem key={item.id} searchTerms={searchTerms} drug={item} onDrugChange={onSelect} />
        )}
      />
    </div>
  );
};

interface CustomItemProps {
  drug: Drug;
  onDrugChange: (drug: Drug) => void;
  searchTerms: Array<string>;
}
const CustomItem: React.FC<CustomItemProps> = (props) => {
  const { drug, onDrugChange, searchTerms } = props;

  const intl = useIntl();
  const { systemHasIntegration } = useSystems();

  const { id, name, class: drugClass, stockInfo, integrations } = drug;

  /**
   * We'll only receive the current location stock info.
   */
  const { stock } = stockInfo || {};
  const currentStock = stock ? Math.round(stock * 100) / 100 : null;

  const outOfStock = !currentStock || currentStock <= 0;

  const onClick = () => {
    if (typeof onDrugChange === 'function') {
      onDrugChange(drug);
    }
  };

  const dynamicStyles = `${outOfStock ? 'not-allowed' : 'pointer'}`;

  return (
    <div
      className={`d-flex justify-content-between align-items-center p-2 rounded border mb-1 ${dynamicStyles}`}
      key={id}
      onClick={onClick}
    >
      <div className="d-flex flex-column">
        <Highlighter
          className="text-capitalize font-weight-bold"
          searchWords={searchTerms}
          autoEscape
          textToHighlight={name}
        />
        <span className="small text-muted">{drugClass}</span>
      </div>
      <div className="d-flex flex-column align-items-end">
        {outOfStock ? (
          <span className="ml-6 font-weight-bold text-danger">
            <T id={messages.outOfStock.id}>{intl.formatMessage(messages.outOfStock)}</T>
          </span>
        ) : (
          <span className="ml-6 font-weight-bold text-primary">
            <T id={messages.outOfStock.id}>{intl.formatMessage(messages.inStock)}</T>:
            <span className="ml-1">{currentStock}</span>
            {drug.stock_unit && <span className="ml-1">{drug.stock_unit}</span>}
          </span>
        )}
        {systemHasIntegration(Integrations.BPJS) && ((integrations || {}).bpjs || {}).bpjsId && (
          <div className="ml-2">
            <Badge color="secondary" size="sm">
              <T id={messages.bpjsLabel.id}>{intl.formatMessage(messages.bpjsLabel)}</T>
            </Badge>
          </div>
        )}
      </div>
    </div>
  );
};
