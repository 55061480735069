import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Button, ButtonGroup } from 'reactstrap';
const messages = defineMessages({
  previous: {
    id: 'UI.previous_text',
    defaultMessage: 'Previous',
  },
  next: {
    id: 'UI.next_text',
    defaultMessage: 'Next',
  },
  resultCount: {
    id: 'ResourceList.showing_result_count',
    defaultMessage: 'Showing {start}-{end} out of {total} results.',
  },
});
interface ResourcePaginatorProps {
  selectedPage: number;
  onPageChange: (index: number) => void;
  itemsToShow: number;
  itemsCount: number;
  pagesToShow: number;
  isDisabled?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

export const ResourcePaginator: React.FC<ResourcePaginatorProps> = (props) => {
  const {
    selectedPage,
    onPageChange,
    itemsToShow,
    itemsCount,
    pagesToShow,
    isDisabled,
    className = '',
    size = 'md',
  } = props;

  const intl = useIntl();
  const pagesCount = Math.ceil(itemsCount / itemsToShow);

  const canNext = pagesCount > selectedPage + 1 && !isDisabled;
  const canBack = selectedPage > 0 && !isDisabled;

  const onNext = () => {
    if (canNext) {
      onPageChange(selectedPage + 1);
    }
  };

  const onBack = () => {
    if (canBack) {
      onPageChange(selectedPage - 1);
    }
  };

  const onSelect = (index) => {
    onPageChange(index);
  };

  const pages =
    pagesCount <= pagesToShow
      ? Array(pagesCount).fill(0) // we should show all page indicators
      : // else the target is [I-2 I-1 I I+1 I+2] (I is the current index)
        [...Array(pagesToShow)].map((_, index) => {
          const middle = Math.ceil(pagesToShow / 2);

          // should add an offset to center the selected item
          if (selectedPage > middle && index + middle < pagesCount) {
            return index - middle;
          } else {
            return index;
          }
        });

  const showingStart = itemsCount ? selectedPage * itemsToShow + 1 : 0;
  const showingEnd = showingStart + itemsToShow < itemsCount ? showingStart + itemsToShow - 1 : itemsCount;

  // wrong indexes entered manually
  React.useEffect(() => {
    if (pagesCount < selectedPage) {
      onPageChange(pagesCount - 1);
    }
  }, [pagesCount, selectedPage, onPageChange]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <ButtonGroup className={className} aria-label="Page navigation">
        <Button
          color="primary"
          size={size}
          outline
          className="ml-0"
          disabled={!canBack}
          onClick={onBack}
          aria-label="Previous"
        >
          <span aria-hidden="true">&laquo;</span>
          <span className="sr-only">
            <T id={messages.previous.id}>{intl.formatMessage(messages.previous)}</T>
          </span>
        </Button>

        {pages.map((val, i) => {
          // if the first element is negative, we've to displace paginator
          const index = Number(pages[0] >= 0 ? i : val + selectedPage);

          // avoid indexes bigger than pagesCount
          return index < pagesCount ? (
            // if disableNext & disableBack are true, all page should be disabled too
            <Button
              key={`paginator-page-${index}`}
              className={`${selectedPage === index ? 'active' : ''} ml-0 border-left-0`}
              color="primary"
              size={size}
              outline
              onClick={() => onSelect(index)}
              disabled={isDisabled}
            >
              {index + 1}
            </Button>
          ) : null;
        })}
        <Button
          color="primary"
          size={size}
          outline
          className="ml-0 border-left-0"
          disabled={!canNext}
          onClick={onNext}
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">
            <T id={messages.next.id}>{intl.formatMessage(messages.next)}</T>
          </span>
        </Button>
      </ButtonGroup>
      <div className="row d-flex justify-content-center mt-1">
        <T id={messages.resultCount.id}>
          {intl.formatMessage(messages.resultCount, {
            start: showingStart,
            end: showingEnd,
            total: itemsCount,
          })}
        </T>
      </div>
    </div>
  );
};
