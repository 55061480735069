import { gql } from '@apollo/client';
import { BasicChecklist, FullChecklist, FullSignedRequest } from 'utils/GQLFragments';
export const GET_CHECKLISTS = gql`
  ${BasicChecklist}
  query checklists($q: String, $offset: Int, $limit: Int, $tags: [String]) {
    checklists(q: $q, offset: $offset, limit: $limit, tags: $tags) {
      docs {
        ...BasicChecklist
      }
      count
    }
  }
`;

export const GET_CHECKLIST_BY_ID = gql`
  ${FullChecklist}
  query checklist($id: ID!) {
    checklist(id: $id) {
      ...FullChecklist
    }
  }
`;

export const GET_CHECKLISTS_GENERAL_TAGS = gql`
  query getChecklistsGeneralTags {
    getChecklistsGeneralTags {
      text
    }
  }
`;

export const GET_CHECKLISTS_PHYSICAL_CODES = gql`
  query getChecklistsPhysicalCodes {
    getChecklistsPhysicalCodes
  }
`;

export const GET_CHECKLISTS_HISTORY_CODES = gql`
  query getChecklistsHistoryCodes {
    getChecklistsHistoryCodes
  }
`;

export const CREATE_CHECKLIST = gql`
  ${FullChecklist}
  mutation createChecklist($checklist: NewChecklist!) {
    createChecklist(checklist: $checklist) {
      ...FullChecklist
    }
  }
`;

export const UPDATE_CHECKLIST = gql`
  ${FullChecklist}
  mutation updateChecklist($id: String!, $checklist: NewChecklist!) {
    updateChecklist(id: $id, checklist: $checklist) {
      ...FullChecklist
    }
  }
`;

export const DELETE_CHECKLIST = gql`
  mutation deleteChecklist($id: String!) {
    deleteChecklist(id: $id) {
      done
    }
  }
`;

export const ADD_ENTRY_TO_CHECKLIST = gql`
  ${FullChecklist}
  mutation addEntryToChecklist($id: String!, $section: String!, $entry: NewEditorChecklistEntry!, $position: Int) {
    addEntryToChecklist(id: $id, section: $section, entry: $entry, position: $position) {
      ...FullChecklist
    }
  }
`;

export const ADD_DRUG_ENTRY_TO_CHECKLIST = gql`
  ${FullChecklist}
  mutation addDrugEntryToChecklist(
    $id: String!
    $section: String!
    $entry: NewEditorChecklistDrugEntry!
    $position: Int
  ) {
    addDrugEntryToChecklist(id: $id, section: $section, entry: $entry, position: $position) {
      ...FullChecklist
    }
  }
`;

export const UPDATE_CHECKLIST_ENTRY = gql`
  ${FullChecklist}
  mutation updateEntryInSection($id: String!, $section: String!, $entryId: String!, $entry: NewEditorChecklistEntry!) {
    updateEntryInSection(id: $id, section: $section, entryId: $entryId, entry: $entry) {
      ...FullChecklist
    }
  }
`;

export const UPDATE_CHECKLIST_DRUG_ENTRY = gql`
  ${FullChecklist}
  mutation updateDrugEntryInSection(
    $id: String!
    $section: String!
    $entryId: String!
    $entry: NewEditorChecklistDrugEntry!
  ) {
    updateDrugEntryInSection(id: $id, section: $section, entryId: $entryId, entry: $entry) {
      ...FullChecklist
    }
  }
`;

export const DELETE_CHECKLIST_ENTRY = gql`
  ${FullChecklist}
  mutation deleteEntryInSection($id: String!, $section: String!, $entryId: String!) {
    deleteEntryInSection(id: $id, section: $section, entryId: $entryId) {
      ...FullChecklist
    }
  }
`;

export const ADD_QUALITY_CHECK_TO_CHECKLIST = gql`
  ${FullChecklist}
  mutation addQualityCheckToChecklist($id: String!, $qualityCheck: NewQualityCheck!) {
    addQualityCheckToChecklist(id: $id, qualityCheck: $qualityCheck) {
      ...FullChecklist
    }
  }
`;

export const UPDATE_QUALITY_CHECK_FROM_CHECKLIST = gql`
  ${FullChecklist}
  mutation updateQualityCheckFromChecklist($id: String!, $qualityCheckId: String!, $qualityCheck: NewQualityCheck!) {
    updateQualityCheckFromChecklist(id: $id, qualityCheckId: $qualityCheckId, qualityCheck: $qualityCheck) {
      ...FullChecklist
    }
  }
`;

export const DELETE_QUALITY_CHECK_FROM_CHECKLIST = gql`
  ${FullChecklist}
  mutation deleteQualityCheckFromChecklist($id: String!, $qualityCheckId: String!) {
    deleteQualityCheckFromChecklist(id: $id, qualityCheckId: $qualityCheckId) {
      ...FullChecklist
    }
  }
`;

export const SET_QUALITY_CHECK_EQUATION = gql`
  ${FullChecklist}
  mutation setQualityCheckEquation($id: String!, $qualityCheckId: String!, $equation: NewQualityChecksEquation!) {
    setQualityCheckEquation(id: $id, qualityCheckId: $qualityCheckId, equation: $equation) {
      ...FullChecklist
    }
  }
`;

export const GET_CHECKLIST_REFERENCE_IMAGE_UPLOAD_URL = gql`
  ${FullSignedRequest}
  query getChecklistsReferenceImageUploadUrl($fileName: String!) {
    getChecklistsReferenceImageUploadUrl(fileName: $fileName) {
      ...FullSignedRequest
    }
  }
`;

export const DUPLICATE_CHECKLIST = gql`
  ${FullChecklist}
  mutation duplicateChecklist($id: String!) {
    duplicateChecklist(id: $id) {
      ...FullChecklist
    }
  }
`;

export const MOVE_ITEM_IN_SECTION = gql`
  ${FullChecklist}
  mutation moveItemInSection($id: String!, $section: String!, $entryId: String!, $position: Int!) {
    moveItemInSection(id: $id, section: $section, entryId: $entryId, position: $position) {
      ...FullChecklist
    }
  }
`;

export const DELETE_CHECKLIST_ENTRY_REFERENCE_IMAGE = gql`
  ${FullChecklist}
  mutation deleteEntryReferenceImage($id: String!, $section: String!, $entryId: String!) {
    deleteEntryReferenceImage(id: $id, section: $section, entryId: $entryId) {
      ...FullChecklist
    }
  }
`;
