import React from 'react'
const debug = require('debug')('wd:ResourcePaginator')

const ResourcePaginator = (props) => {
  const {
    selectedPage,
    onPageChange,
    pagesCount: _pagesCount,
    pagesToShow,
    disableBack,
    disableNext,
    className
  } = props

  const pagesCount = Math.ceil(_pagesCount)
  // next page
  const onNext = (event) => {
    debug(pagesCount, selectedPage + 1)
    if (pagesCount > selectedPage + 1) onPageChange(selectedPage + 1)
  }

  // previous page
  const onBack = (event) => {
    debug(pagesCount, selectedPage - 1)
    if (selectedPage > 0) onPageChange(selectedPage - 1)
  }

  // specific page
  const onSelect = (index) => {
    debug(pagesCount, index)
    onPageChange(index)
  }
  const pages = pagesCount <= pagesToShow
  // we should show all page indicators
    ? Array(pagesCount).fill(0)
  // else the target is [I-2 I-1 I I+1 I+2] (I is the current index)
    : [...Array(pagesToShow)].map((_, index) => {
      const middle = parseInt(pagesToShow / 2)
      // should add an offset to center the selected item
      if (selectedPage > middle && index + middle < pagesCount) {
        return index - middle
      } else {
        return index
      }
    })

  debug(props, pages)
  if (typeof onPageChange !== 'function') throw new Error('onPageChange must be a function')
  return <nav className={className} aria-label='Page navigation'>
    <ul className='pagination justify-content-center'>
      <li className='page-item'>
        <button
          className='page-link'
          disabled={disableBack || selectedPage === 0}
          onClick={onBack}
          aria-label='Previous'>
          <span aria-hidden='true'>&laquo;</span>
          <span className='sr-only'>Previous</span>
        </button>
      </li>

      {pages.map((val, i) => {
        // if the first element is negative, we've to displace paginator
        const index = pages[0] >= 0 ? i : val + selectedPage

        // avoid indexes bigger than pagesCount
        return index < pagesCount
        // if disableNext & disableBack are true, all page should be disabled too
          ? <li className={`page-item ${selectedPage === index ? 'active' : ''} `} key={`paginator-page-${index}`}>
            <button
              className='page-link'
              onClick={e => onSelect(index)}
              disabled={disableBack && disableNext}
            >
              {index + 1}
            </button>
          </li>
          : null
      })}
      <li className='page-item'>
        <button
          className='page-link'
          disabled={disableNext || pagesCount <= selectedPage + 1}
          onClick={onNext}
          aria-label='Next'>
          <span aria-hidden='true'>&raquo;</span>
          <span className='sr-only'>Next</span>
        </button>
      </li>
    </ul>
  </nav>
}

ResourcePaginator.defaultProps = {
  selectedPage: 0,
  pagesCount: 1,
  pagesToShow: 5,
  disableNext: false,
  disableBack: false,
  className: '',
  onPageChange: () => debug('onPageChange not found')
}

export default ResourcePaginator
