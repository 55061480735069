import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Alert, Col, Row } from 'reactstrap'

const messages = defineMessages({
  recomendedDosing: {
    id: 'DrugRecommendation.label_recommended_dosing',
    defaultMessage: 'Recommended Dosing'
  },
  interactions: {
    id: 'DrugRecommendation.label_interactions',
    defaultMessage: 'Interactions'
  },
  comments: {
    id: 'DrugRecommendation.label_comments',
    defaultMessage: 'Comments'
  }

})

const DrugRecommendation = props => {
  const { drug, intl } = props

  return <Row>
    <Col className='mt-1'>
      <Alert color='info'>
        <Row>
          <Col>
            <b>
              <T id={messages.recomendedDosing.id}>{intl.formatMessage(messages.recomendedDosing)}</T>
            </b>
            {': '}
            <br />
            <span >
              {drug.recommended_dosing || '--'}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>
              <T id={messages.interactions.id}>{intl.formatMessage(messages.interactions)}</T>
            </b>
            {': '}
            <br />
            <span >
              {drug.interactions || '--'}
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>
              <T id={messages.comments.id}>{intl.formatMessage(messages.comments)}</T>
            </b>
            {': '}
            <br />
            <span>
              {drug.comments || '--'}
            </span>
          </Col>
        </Row>
      </Alert>
    </Col>
  </Row>
}

export default injectIntl(DrugRecommendation)
