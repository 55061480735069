import { isValidDate } from 'components-ts/DateAndTime'
import React from 'react'
import { FaHistory as HistoryIcon, FaUserAlt as UserIcon } from 'react-icons/fa'
import { useIntl } from 'react-intl'

const Signature = (props) => {
  // props
  const { signature, shortName, className } = props

  const intl = useIntl()

  const givenName = (signature || {}).given_name || '' // eslint-disable-line
  const familyName = (signature || {}).family_name || '' // eslint-disable-line
  const timestamp = (signature || {}).timestamp

  const formattedName = shortName && givenName && familyName // eslint-disable-line
    ? `${givenName[0]}${familyName[0]}`.toUpperCase()
    : `${givenName} ${familyName}`  // eslint-disable-line

  const date = new Date(timestamp)

  return formattedName || timestamp
    ? <small className={`text-muted d-flex align-items-center ${className || ''}`}>
      <span className='mr-1 d-flex align-items-center'>
        {(givenName || familyName) &&  // eslint-disable-line
          <UserIcon className='m-1 ml-0' />}
        {formattedName && <span>{formattedName}</span>}
      </span>
      {date && isValidDate(date) &&
        <span className='mr-1 d-flex align-items-center'>
          <HistoryIcon className='m-1 ml-2' />
          {intl.formatDate(date)}
        </span>}
    </small>
    : null
}

export default Signature
