import { FullComment, Signature, TranslatableText } from './common';
import { Nullable } from 'components-ts/utils';
import { FullChecklistEntry } from './checklists';
import { BasicPatient, BasicPatientWithoutId } from './patients';
import { OrderType } from './visits';

export interface BasicStudyTemplate {
  id: string;
  name: TranslatableText;
  description: Nullable<TranslatableText>;
  method: Nullable<TranslatableText>;
  tags: Nullable<Array<TranslatableText>>;
  created: Nullable<Signature>;
  updated: Nullable<Signature>;
}

export enum StudyResultType {
  NUMERICAL = 'numerical',
  CATEGORICAL = 'categorical',
  CATEGORICAL_BASED_ON_NUMBER = 'categoricalBasedOnNumber',
}

interface StudyNumericalRange {
  minimum: number;
  maximum: number;
}

export interface StudyOutcomeBasedOnNumberReference {
  outcome: TranslatableText;
  range: StudyNumericalRange;
}

export interface StudyNumericalReference {
  minimum: number;
  maximum: number;
}

export interface StudyCategoricalReference {
  possibleOutcomes: Array<TranslatableText>;
}

export interface StudyCategoricalBasedOnNumberReference {
  possibleOutcomesWithRange: Array<StudyOutcomeBasedOnNumberReference>;
}

type StudyResultReference =
  | StudyNumericalReference
  | StudyCategoricalReference
  | StudyCategoricalBasedOnNumberReference;

export interface StudyTemplateResult {
  reference: StudyResultReference;
}

export interface StudyTemplateItem {
  id: string;
  name: TranslatableText;
  type: StudyResultType;
  result: StudyTemplateResult;
  unit: Nullable<TranslatableText>;
}

export interface FullStudyTemplate extends BasicStudyTemplate {
  items: Array<StudyTemplateItem>;
}

/**
 * Study instance
 * template + results
 */
export interface StudyNumericalResult {
  number: number;
}

export interface StudyCategoricalResult {
  outcome: TranslatableText;
}

export interface StudyCategoricalBasedOnNumberResult {
  outcome: TranslatableText;
  number: number;
}

type StudyResultValue = StudyNumericalResult | StudyCategoricalResult | StudyCategoricalBasedOnNumberResult;

export interface StudyResult {
  value: Nullable<StudyResultValue>;
  reference: StudyResultReference;
}

export interface StudyItem {
  id: string;
  templateItemId: string;
  name: TranslatableText;
  type: StudyResultType;
  result: StudyResult;
  unit: Nullable<TranslatableText>;
}

export interface FullStudyOrder {
  id: string;
  studyId: string;
  type: OrderType;
  guideline: Nullable<FullChecklistEntry>;
  order: FullComment;
  acknowledged: Nullable<boolean>;
  canceled: Nullable<boolean>;
  received: Nullable<boolean>;
  filled: Nullable<boolean>;
  comments: Nullable<Array<FullComment>>;
  updated: Nullable<Signature>;
}

export interface BasicStudy {
  id: string;
  name: TranslatableText;
  description: Nullable<TranslatableText>;
  method: Nullable<TranslatableText>;
  location: string;
  templateId: string;
  visitId: string;
  patient: BasicPatient;
  updated: Nullable<Signature>;
  completed: Nullable<Signature>;
  note: Nullable<string>;
}

export interface BasicStudyWithOrder extends BasicStudy {
  isActive: Nullable<Boolean>;
  order: FullStudyOrder;
}
export interface FullStudy extends BasicStudyWithOrder {
  items: Array<StudyItem>;
  comment: Nullable<string>;
  created: Signature;
  files: Array<string>;
  studyId: string;
}
export interface FullStudyWithOrder extends FullStudy {
  order: FullStudyOrder;
}

export interface NewStudyOrder {
  templateId: string;
  orderTitle: string;
  comment?: string;
  guideline?: FullChecklistEntry;
}

export interface NewStudyTemplate {
  name: string;
  description?: string;
  method?: string;
  tags?: Array<string>;
}

export type BasicStudiesGroupedByVisit = {
  visitId: string;
  patient: BasicPatientWithoutId;
  studies: Array<BasicStudyWithOrder>;
};

export type FullStudiesGroupedByVisit = {
  studies: Array<FullStudyWithOrder>;
};
