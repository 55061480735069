import { gql } from '@apollo/client';
import {
  FullPatientWithActiveVisit,
  FullVisitHistory,
  FullList,
  FullComplaintLists,
  FullHistoryItem,
} from 'utils/GQLFragments';

/*
 *
 * All these request are related to an active visit
 *
 */
export const COMPLETE_VISIT = gql`
  mutation completeActiveVisit($patientId: ID!, $visitId: ID!, $icd10s: [NewVisitICD10]) {
    completeActiveVisit(patientId: $patientId, visitId: $visitId, icd10s: $icd10s) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const COMPLETE_ADMISSION = gql`
  mutation completeAdmission($patientId: ID!, $visitId: ID!) {
    completeAdmission(patientId: $patientId, visitId: $visitId) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const REMOVE_VISIT = gql`
  mutation removeActiveVisit($patientId: ID!, $visitId: ID!) {
    removeActiveVisit(patientId: $patientId, visitId: $visitId) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_VALUE_TO_CHARACTERISTIC_IN_COMPLAINT_SECTION = gql`
  mutation addValueToCharacteristicInComplaintSection(
    $patientId: ID!
    $visitId: ID!
    $complaintId: ID!
    $characteristicId: ID!
    $characteristicValue: NewListItem!
  ) {
    addValueToCharacteristicInComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      characteristicId: $characteristicId
      characteristicValue: $characteristicValue
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const REMOVE_VALUE_FROM_CHARACTERISTIC_IN_COMPLAINT_SECTION = gql`
  mutation removeValueFromCharacteristicInComplaintSection(
    $patientId: ID!
    $visitId: ID!
    $complaintId: ID!
    $characteristicId: ID!
    $characteristicKey: Float!
  ) {
    removeValueFromCharacteristicInComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      characteristicId: $characteristicId
      characteristicKey: $characteristicKey
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_CHARACTERISTIC_TO_COMPLAINT_SECTION = gql`
  mutation addCharacteristicToComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $list: NewListItem!) {
    addCharacteristicToComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      list: $list
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const REMOVE_CHARACTERISTIC_FROM_COMPLAINT_SECTION = gql`
  mutation removeCharacteristicFromComplaintSection(
    $patientId: ID!
    $visitId: ID!
    $complaintId: ID!
    $characteristicId: ID!
  ) {
    removeCharacteristicFromComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      characteristicId: $characteristicId
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_COMPLAINT_SECTION = gql`
  mutation addComplaintSection(
    $patientId: ID!
    $visitId: ID!
    $type: String!
    $name: NewTranslatableText!
    $complaintKey: Float!
  ) {
    addComplaintSection(
      patientId: $patientId
      visitId: $visitId
      type: $type
      name: $name
      complaintKey: $complaintKey
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_DEGREE_TO_COMPLAINT_SECTION = gql`
  mutation setDegreeToComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $degree: NewListItem) {
    setDegreeToComplaintSection(patientId: $patientId, visitId: $visitId, complaintId: $complaintId, degree: $degree) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_TIMING_TO_COMPLAINT_SECTION = gql`
  mutation addTimingToComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $timing: NewListItem!) {
    addTimingToComplaintSection(patientId: $patientId, visitId: $visitId, complaintId: $complaintId, timing: $timing) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const REMOVE_TIMING_FROM_COMPLAINT_SECTION = gql`
  mutation removeTimingFromComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $timingKey: Float!) {
    removeTimingFromComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      timingKey: $timingKey
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_POSITION_TO_COMPLAINT_SECTION = gql`
  mutation addPositionToComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $position: NewListItem!) {
    addPositionToComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      position: $position
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const REMOVE_POSITION_FROM_COMPLAINT_SECTION = gql`
  mutation removePositionFromComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $positionKey: Float!) {
    removePositionFromComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      positionKey: $positionKey
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_BODY_PART_TO_COMPLAINT_SECTION = gql`
  mutation addBodyPartToComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $bodyPart: NewListItem!) {
    addBodyPartToComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      bodyPart: $bodyPart
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const REMOVE_BODY_PART_FROM_COMPLAINT_SECTION = gql`
  mutation removeBodyPartFromComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $bodyPartKey: Float!) {
    removeBodyPartFromComplaintSection(
      patientId: $patientId
      visitId: $visitId
      complaintId: $complaintId
      bodyPartKey: $bodyPartKey
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_NOTE_TO_COMPLAINT_SECTION = gql`
  mutation addNoteToComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!, $note: String!) {
    addNoteToComplaintSection(patientId: $patientId, visitId: $visitId, complaintId: $complaintId, note: $note) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const REMOVE_COMPLAINT_SECTION = gql`
  mutation removeComplaintSection($patientId: ID!, $visitId: ID!, $complaintId: ID!) {
    removeComplaintSection(patientId: $patientId, visitId: $visitId, complaintId: $complaintId) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const GET_COMPLAINTS_LISTS = gql`
  query complaintLists {
    complaintLists {
      ...FullComplaintLists
    }
  }
  ${FullComplaintLists}
`;

export const START_EXAM = gql`
  mutation startExam($patientId: ID!, $visitId: ID!) {
    startExam(patientId: $patientId, visitId: $visitId) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const START_ADMISSION_PROCESS = gql`
  mutation startAdmissionProcess($patientId: String!, $visitId: String!, $roomId: String!, $diagnosis: String) {
    startAdmissionProcess(patientId: $patientId, visitId: $visitId, roomId: $roomId, diagnosis: $diagnosis) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const UPDATE_STANDING_ORDER = gql`
  mutation updateStandingOrder($patientId: ID!, $visitId: ID!, $standingOrder: NewStandingOrder!) {
    updateStandingOrder(patientId: $patientId, visitId: $visitId, standingOrder: $standingOrder) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_NOTE = gql`
  mutation addNote($patientId: String!, $visitId: String!, $note: NewNote!) {
    addNote(patientId: $patientId, visitId: $visitId, note: $note) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const COMPLETE_NOTE = gql`
  ${FullPatientWithActiveVisit}
  mutation completeNote($patientId: String!, $visitId: String!, $noteId: String!) {
    completeNote(patientId: $patientId, visitId: $visitId, noteId: $noteId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const DELETE_NOTE = gql`
  ${FullPatientWithActiveVisit}
  mutation deleteNote($patientId: String!, $visitId: String!, $noteId: String!) {
    deleteNote(patientId: $patientId, visitId: $visitId, noteId: $noteId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const SET_NOTE_ITEM_VALUE = gql`
  mutation setNoteItemValue($patientId: String!, $visitId: String!, $noteId: String!, $item: NewNoteItemValue!) {
    setNoteItemValue(patientId: $patientId, visitId: $visitId, noteId: $noteId, item: $item) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const CHECK_NOTE_ITEM = gql`
  mutation checkNoteItem($patientId: String!, $visitId: String!, $noteId: String!, $item: NewNoteItemCheck!) {
    checkNoteItem(patientId: $patientId, visitId: $visitId, noteId: $noteId, item: $item) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_WEIGHT = gql`
  mutation setNoteWeight($patientId: String!, $visitId: String!, $noteId: String!, $measurement: NewNoteWeight!) {
    setNoteWeight(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_HEIGHT = gql`
  mutation setNoteHeight($patientId: String!, $visitId: String!, $noteId: String!, $measurement: NewNoteHeight!) {
    setNoteHeight(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_BLOOD_PRESSURE = gql`
  mutation setNoteBloodPressure(
    $patientId: String!
    $visitId: String!
    $noteId: String!
    $measurement: NewNoteBloodPressure!
  ) {
    setNoteBloodPressure(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_HEART_RATE = gql`
  mutation setNoteHeartRate($patientId: String!, $visitId: String!, $noteId: String!, $measurement: NewNoteHeartRate!) {
    setNoteHeartRate(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_TEMPERATURE = gql`
  mutation setNoteTemperature(
    $patientId: String!
    $visitId: String!
    $noteId: String!
    $measurement: NewNoteTemperature!
  ) {
    setNoteTemperature(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_O2_SATURATION = gql`
  mutation setNoteO2Saturation(
    $patientId: String!
    $visitId: String!
    $noteId: String!
    $measurement: NewNoteO2Saturation!
  ) {
    setNoteO2Saturation(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_HEAD_CIRCUMFERENCE = gql`
  mutation setNoteHeadCircumference(
    $patientId: String!
    $visitId: String!
    $noteId: String!
    $measurement: NewNoteHeadCircumference!
  ) {
    setNoteHeadCircumference(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_MID_UPPER_ARM_CIRCUMFERENCE = gql`
  mutation setNoteMidUpperArmCircumference(
    $patientId: String!
    $visitId: String!
    $noteId: String!
    $measurement: NewNoteMidUpperArmCircumference!
  ) {
    setNoteMidUpperArmCircumference(
      patientId: $patientId
      visitId: $visitId
      noteId: $noteId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_RESPIRATORY_RATE = gql`
  mutation setNoteRespiratoryRate(
    $patientId: String!
    $visitId: String!
    $noteId: String!
    $measurement: NewNoteRespiratoryRate!
  ) {
    setNoteRespiratoryRate(patientId: $patientId, visitId: $visitId, noteId: $noteId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SET_NOTE_EXAM = gql`
  mutation setNoteExam($patientId: String!, $visitId: String!, $noteId: String!, $item: NewNoteExam!) {
    setNoteExam(patientId: $patientId, visitId: $visitId, noteId: $noteId, item: $item) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const ADD_COMMENT_TO_NOTE = gql`
  mutation addCommentToNote($patientId: String!, $visitId: String!, $noteId: String!, $comment: String!) {
    addCommentToNote(patientId: $patientId, visitId: $visitId, noteId: $noteId, comment: $comment) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const TAKE_OVER_EXAM = gql`
  mutation takeOverExam($patientId: ID!, $visitId: ID!) {
    takeOverExam(patientId: $patientId, visitId: $visitId) {
      ...FullPatientWithActiveVisit
    }
  }
  ${FullPatientWithActiveVisit}
`;

export const SELECT_GENERAL_REVIEW_OF_SYSTEMS_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation selectGeneralReviewOfSystemInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $sectionKey: Float!
    $groupKey: Float!
    $choiceKey: Float!
    $selected: Boolean!
  ) {
    selectGeneralReviewOfSystemInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      sectionKey: $sectionKey
      groupKey: $groupKey
      choiceKey: $choiceKey
      selected: $selected
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const GET_GENERAL_REVIEW_OF_SYSTEM_LIST = gql`
  query getGeneralReviewOfSystemsList {
    getGeneralReviewOfSystemsList {
      ...FullList
    }
  }
  ${FullList}
`;

export const GET_VISIT_HISTORY = gql`
  query patientHistory($patientId: String) {
    patientHistory(patientId: $patientId) {
      ...FullVisitHistory
    }
  }
  ${FullVisitHistory}
`;

export const START_NEW_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation startNewVisit($patientId: ID!, $newVisit: NewVisit!) {
    startNewVisit(patientId: $patientId, newVisit: $newVisit) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_CLINICAL_STATUS = gql`
  ${FullPatientWithActiveVisit}
  mutation updateClinicalStatus($patientId: ID!, $visitId: ID!, $clinicalStatus: NewClinicalStatus!) {
    updateClinicalStatus(patientId: $patientId, visitId: $visitId, clinicalStatus: $clinicalStatus) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_CHIEF_COMPLAINT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateChiefComplaint($patientId: ID!, $visitId: ID!, $chiefComplaint: NewChiefComplaintLegacy!) {
    updateChiefComplaint(patientId: $patientId, visitId: $visitId, chiefComplaint: $chiefComplaint) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_DISPOSITION = gql`
  ${FullPatientWithActiveVisit}
  mutation updateDisposition($patientId: ID!, $visitId: ID!, $disposition: NewDisposition!) {
    updateDisposition(patientId: $patientId, visitId: $visitId, disposition: $disposition) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_FOLLOW_UP = gql`
  ${FullPatientWithActiveVisit}
  mutation updateFollowUp($patientId: ID!, $visitId: ID!, $followUp: String!) {
    updateFollowUp(patientId: $patientId, visitId: $visitId, followUp: $followUp) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_STATUS_ASSESSMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateStatusAssessment($patientId: ID!, $visitId: ID!, $status: String!) {
    updateStatusAssessment(patientId: $patientId, visitId: $visitId, status: $status) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const SELECT_EXAM_CHOICE_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation selectExamChoiceInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $sectionKey: Float!
    $groupKey: Float!
    $choiceKey: Float!
    $selected: Boolean!
  ) {
    selectExamChoiceInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      sectionKey: $sectionKey
      groupKey: $groupKey
      choiceKey: $choiceKey
      selected: $selected
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_TOOTH_SECTION_TO_EXAM = gql`
  ${FullPatientWithActiveVisit}
  mutation addToothSectionToExam($patientId: ID!, $visitId: ID!, $toothName: String!) {
    addToothSectionToExam(patientId: $patientId, visitId: $visitId, toothName: $toothName) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_ENTRY_TO_DIET_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addDiet($patientId: ID!, $visitId: ID!, $content: String!) {
    addDiet(patientId: $patientId, visitId: $visitId, content: $content) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const ADD_ENTRY_TO_SOCIAL_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addSocial($patientId: ID!, $visitId: ID!, $content: String!) {
    addSocial(patientId: $patientId, visitId: $visitId, content: $content) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const ADD_ENTRY_TO_DEVELOPMENT_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addDevelopment($patientId: ID!, $visitId: ID!, $content: String!) {
    addDevelopment(patientId: $patientId, visitId: $visitId, content: $content) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const ADD_ENTRY_TO_FAMILY_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addFamily($patientId: ID!, $visitId: ID!, $content: String!) {
    addFamily(patientId: $patientId, visitId: $visitId, content: $content) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const ADD_ENTRY_TO_ALLERGIES_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addAllergies($patientId: ID!, $visitId: ID!, $content: String!) {
    addAllergies(patientId: $patientId, visitId: $visitId, content: $content) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const ADD_ENTRY_TO_MANUAL_PROBLEMS_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addManualProblems($patientId: ID!, $visitId: ID!, $content: String!) {
    addManualProblems(patientId: $patientId, visitId: $visitId, content: $content) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const ADD_ENTRY_TO_MANUAL_MEDICATIONS_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addManualMedication($patientId: ID!, $visitId: ID!, $content: String!) {
    addManualMedication(patientId: $patientId, visitId: $visitId, content: $content) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const SET_MATERNAL_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation setMaternalHistory($patientId: ID!, $visitId: ID!, $maternal: NewMaternalHistory!) {
    setMaternalHistory(patientId: $patientId, visitId: $visitId, maternal: $maternal) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_ENTRY_FROM_DIET_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeDiet($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeDiet(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const REMOVE_ENTRY_FROM_SOCIAL_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeSocial($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeSocial(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const REMOVE_ENTRY_FROM_DEVELOPMENT_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeDevelopment($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeDevelopment(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const REMOVE_ENTRY_FROM_FAMILY_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeFamily($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeFamily(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const REMOVE_ENTRY_FROM_ALLERGIES_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeAllergies($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeAllergies(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const REMOVE_ENTRY_FROM_MANUAL_PROBLEMS_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeManualProblems($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeManualProblems(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_HISTORY_MANUAL_PROBLEM = gql`
  mutation removeHistoryManualProblem($patientId: ID!, $historyId: ID!) {
    removeHistoryManualProblem(patientId: $patientId, historyId: $historyId) {
      ...FullHistoryItem
    }
  }
  ${FullHistoryItem}
`;

export const REMOVE_HISTORY_MANUAL_MEDICATION = gql`
  mutation removeHistoryManualMedication($patientId: ID!, $historyId: ID!) {
    removeHistoryManualMedication(patientId: $patientId, historyId: $historyId) {
      ...FullHistoryItem
    }
  }
  ${FullHistoryItem}
`;

export const REMOVE_HISTORY_ALLERGY = gql`
  mutation removeHistoryAllergy($patientId: ID!, $historyId: ID!) {
    removeHistoryAllergy(patientId: $patientId, historyId: $historyId) {
      ...FullHistoryItem
    }
  }
  ${FullHistoryItem}
`;

export const REMOVE_HISTORY_DIET = gql`
  mutation removeHistoryDiet($patientId: ID!, $historyId: ID!) {
    removeHistoryDiet(patientId: $patientId, historyId: $historyId) {
      ...FullHistoryItem
    }
  }
  ${FullHistoryItem}
`;

export const REMOVE_HISTORY_DEVELOPMENT = gql`
  mutation removeHistoryDevelopment($patientId: ID!, $historyId: ID!) {
    removeHistoryDevelopment(patientId: $patientId, historyId: $historyId) {
      ...FullHistoryItem
    }
  }
  ${FullHistoryItem}
`;

export const REMOVE_HISTORY_SOCIAL = gql`
  mutation removeHistorySocial($patientId: ID!, $historyId: ID!) {
    removeHistorySocial(patientId: $patientId, historyId: $historyId) {
      ...FullHistoryItem
    }
  }
  ${FullHistoryItem}
`;

export const REMOVE_HISTORY_FAMILY = gql`
  mutation removeHistoryFamily($patientId: ID!, $historyId: ID!) {
    removeHistoryFamily(patientId: $patientId, historyId: $historyId) {
      ...FullHistoryItem
    }
  }
  ${FullHistoryItem}
`;

export const REMOVE_ENTRY_FROM_MANUAL_MEDICATIONS_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeManualMedication($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeManualMedication(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_EXAM_SECTION = gql`
  ${FullPatientWithActiveVisit}
  mutation removeExamSection($patientId: ID!, $visitId: ID!, $sectionKey: Float!) {
    removeExamSection(patientId: $patientId, visitId: $visitId, sectionKey: $sectionKey) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const SELECT_EXAM_DETAIL_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation selectExamDetailsInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $sectionKey: Float!
    $groupKey: Float!
    $choiceKey: Float!
    $detailsKey: Float!
    $listId: ID!
    $listItemKey: Float!
    $selected: Boolean!
  ) {
    selectExamDetailsInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      sectionKey: $sectionKey
      groupKey: $groupKey
      choiceKey: $choiceKey
      detailsKey: $detailsKey
      listId: $listId
      listItemKey: $listItemKey
      selected: $selected
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_NOTE_TO_EXAM_SECTION = gql`
  ${FullPatientWithActiveVisit}
  mutation addNoteToExamSection($patientId: ID!, $visitId: ID!, $sectionKey: Float!, $note: String!) {
    addNoteToExamSection(patientId: $patientId, visitId: $visitId, sectionKey: $sectionKey, note: $note) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const CHANGE_EXAM_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation changeExamInActiveVisit($patientId: ID!, $visitId: ID!, $examId: ID!) {
    changeExamInActiveVisit(patientId: $patientId, visitId: $visitId, examId: $examId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_EXAM = gql`
  ${FullPatientWithActiveVisit}
  mutation updateExam($patientId: String!, $visitId: String!, $exam: NewExam!) {
    updateExam(patientId: $patientId, visitId: $visitId, exam: $exam) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_MANUAL_DIAGNOSE = gql`
  ${FullPatientWithActiveVisit}
  mutation addNewManualDiagnoseToActiveVisit($patientId: ID!, $visitId: ID!, $diagnose: String!, $treatment: String!) {
    addNewManualDiagnoseToActiveVisit(
      patientId: $patientId
      visitId: $visitId
      diagnose: $diagnose
      treatment: $treatment
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_MANUAL_DIAGNOSE = gql`
  ${FullPatientWithActiveVisit}
  mutation removeManualDiagnoseFromActiveVisit($patientId: ID!, $visitId: ID!, $diagnoseId: ID!) {
    removeManualDiagnoseFromActiveVisit(patientId: $patientId, visitId: $visitId, diagnoseId: $diagnoseId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_CHECKLIST_TO_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation addChecklistToActiveVisit($patientId: ID!, $visitId: ID!, $checklistId: ID!) {
    addChecklistToActiveVisit(patientId: $patientId, visitId: $visitId, checklistId: $checklistId) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const REMOVE_CHECKLIST_FROM_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation removeChecklistFromActiveVisit($patientId: ID!, $visitId: ID!, $checklistId: ID!) {
    removeChecklistFromActiveVisit(patientId: $patientId, visitId: $visitId, checklistId: $checklistId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_CHECKLIST_HISTORY_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateChecklistHistoryInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $checklistId: ID!
    $checklistItem: NewChecklistItemValue!
  ) {
    updateChecklistHistoryInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      checklistId: $checklistId
      checklistItem: $checklistItem
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_CHECKLIST_PHYSICAL_EXAM_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateChecklistPhysicalExamInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $checklistId: ID!
    $checklistItem: NewChecklistItemValue!
  ) {
    updateChecklistPhysicalExamInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      checklistId: $checklistId
      checklistItem: $checklistItem
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const UPDATE_CHECKLIST_STUDY_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateChecklistStudyInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $checklistId: ID!
    $checklistItem: NewChecklistItemValue!
  ) {
    updateChecklistStudyInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      checklistId: $checklistId
      checklistItem: $checklistItem
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_CHECKLIST_TREATMENT_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateChecklistTreatmentInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $checklistId: ID!
    $checklistItem: NewChecklistItemValue!
  ) {
    updateChecklistTreatmentInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      checklistId: $checklistId
      checklistItem: $checklistItem
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_CHECKLIST_EDUCATION_IN_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateChecklistEducationInActiveVisit(
    $patientId: ID!
    $visitId: ID!
    $checklistId: ID!
    $checklistItem: NewChecklistItemValue!
  ) {
    updateChecklistEducationInActiveVisit(
      patientId: $patientId
      visitId: $visitId
      checklistId: $checklistId
      checklistItem: $checklistItem
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_NURSE_ORDER_TO_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation addNurseOrderToActiveVisit($patientId: ID!, $visitId: ID!, $nurseOrder: NewNurseOrder!) {
    addNurseOrderToActiveVisit(patientId: $patientId, visitId: $visitId, nurseOrder: $nurseOrder) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_STUDY_ORDER_TO_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation addStudyOrderToActiveVisit($patientId: ID!, $visitId: ID!, $studyOrder: NewStudyOrder!) {
    addStudyOrderToActiveVisit(patientId: $patientId, visitId: $visitId, studyOrder: $studyOrder) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_PROCEDURE_ORDER_TO_ACTIVE_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation addProcedureOrderToActiveVisit($patientId: ID!, $visitId: ID!, $procedureOrder: NewProcedureOrder!) {
    addProcedureOrderToActiveVisit(patientId: $patientId, visitId: $visitId, procedureOrder: $procedureOrder) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const PRESCRIBE_RX = gql`
  ${FullPatientWithActiveVisit}
  mutation prescribeRX($patientId: ID!, $visitId: ID!, $rx: NewRX!, $locationId: String!) {
    prescribeRX(patientId: $patientId, visitId: $visitId, rx: $rx, locationId: $locationId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const PRESCRIBE_USER_GUIDELINE_RX = gql`
  ${FullPatientWithActiveVisit}
  mutation prescribeUserGuidelineRX($patientId: ID!, $visitId: ID!, $rx: NewUserGuidelineRX!, $locationId: String!) {
    prescribeUserGuidelineRX(patientId: $patientId, visitId: $visitId, rx: $rx, locationId: $locationId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const PRESCRIBE_MIX = gql`
  ${FullPatientWithActiveVisit}
  mutation prescribeMix($patientId: ID!, $visitId: ID!, $rxs: [NewRX!]!, $locationId: String!) {
    prescribeMix(patientId: $patientId, visitId: $visitId, rxs: $rxs, locationId: $locationId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADMINISTER_RX = gql`
  ${FullPatientWithActiveVisit}
  mutation administerRX($patientId: ID!, $visitId: ID!, $rx: NewRX!, $locationId: String!) {
    administerRX(patientId: $patientId, visitId: $visitId, rx: $rx, locationId: $locationId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADMINISTER_USER_GUIDELINE_RX = gql`
  ${FullPatientWithActiveVisit}
  mutation administerUserGuidelineRX($patientId: ID!, $visitId: ID!, $rx: NewUserGuidelineRX!, $locationId: String!) {
    administerUserGuidelineRX(patientId: $patientId, visitId: $visitId, rx: $rx, locationId: $locationId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

/**
 * Triage
 */

export const ADD_BLOOD_PRESSURE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addBloodPressureMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewBloodPressure!) {
    addBloodPressureMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_WEIGHT_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addWeightMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewWeight!) {
    addWeightMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_HEIGHT_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addHeightMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewHeight!) {
    addHeightMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_BMI_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addBmiMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewBmi!) {
    addBmiMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_HEAD_CIRCUMFERENCE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addHeadCircumferenceMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewHeadCircumference!) {
    addHeadCircumferenceMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_MUA_CIRCUMFERENCE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addMidUpperArmCircumferenceMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurement: NewMidUpperArmCircumference!
  ) {
    addMidUpperArmCircumferenceMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_TEMPERATURE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addTemperatureMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewTemperature!) {
    addTemperatureMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_HEART_RATE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addHeartRateMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewHeartRate!) {
    addHeartRateMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_RESPIRATORY_RATE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addRespiratoryRateMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewRespiratoryRate!) {
    addRespiratoryRateMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_O2_SATURATION_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addO2SaturationMeasurement($patientId: ID!, $visitId: ID!, $measurement: NewO2Saturation!) {
    addO2SaturationMeasurement(patientId: $patientId, visitId: $visitId, measurement: $measurement) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_BLOOD_PRESSURE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateBloodPressureMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurementId: ID!
    $measurement: NewBloodPressure!
  ) {
    updateBloodPressureMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_WEIGHT_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateWeightMeasurement($patientId: ID!, $visitId: ID!, $measurementId: ID!, $measurement: NewWeight!) {
    updateWeightMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_HEIGHT_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateHeightMeasurement($patientId: ID!, $visitId: ID!, $measurementId: ID!, $measurement: NewHeight!) {
    updateHeightMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_BMI_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateBmiMeasurement($patientId: ID!, $visitId: ID!, $measurementId: ID!, $measurement: NewBmi!) {
    updateBmiMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_HEAD_CIRCUMFERENCE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateHeadCircumferenceMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurementId: ID!
    $measurement: NewHeadCircumference!
  ) {
    updateHeadCircumferenceMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_MUA_CIRCUMFERENCE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateMidUpperArmCircumferenceMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurementId: ID!
    $measurement: NewMidUpperArmCircumference!
  ) {
    updateMidUpperArmCircumferenceMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_TEMPERATURE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateTemperatureMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurementId: ID!
    $measurement: NewTemperature!
  ) {
    updateTemperatureMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_HEART_RATE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateHeartRateMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurementId: ID!
    $measurement: NewHeartRate!
  ) {
    updateHeartRateMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_RESPIRATORY_RATE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateRespiratoryRateMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurementId: ID!
    $measurement: NewRespiratoryRate!
  ) {
    updateRespiratoryRateMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_O2_SATURATION_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateO2SaturationMeasurement(
    $patientId: ID!
    $visitId: ID!
    $measurementId: ID!
    $measurement: NewO2Saturation!
  ) {
    updateO2SaturationMeasurement(
      patientId: $patientId
      visitId: $visitId
      measurementId: $measurementId
      measurement: $measurement
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_MEASUREMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation removeMeasurement($patientId: ID!, $visitId: ID!, $measurementId: ID!) {
    removeMeasurement(patientId: $patientId, visitId: $visitId, measurementId: $measurementId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

/**
 * Progress notes
 */
export const ADD_PROGRESS_NOTE = gql`
  ${FullPatientWithActiveVisit}
  mutation addProgressNote($patientId: ID!, $visitId: ID!, $note: String!) {
    addProgressNote(patientId: $patientId, visitId: $visitId, note: $note) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_PROGRESS_NOTE = gql`
  ${FullPatientWithActiveVisit}
  mutation updateProgressNote($patientId: ID!, $visitId: ID!, $note: String!, $progressNoteId: ID!) {
    updateProgressNote(patientId: $patientId, visitId: $visitId, note: $note, progressNoteId: $progressNoteId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_PROGRESS_NOTE = gql`
  ${FullPatientWithActiveVisit}
  mutation removeProgressNote($patientId: ID!, $visitId: ID!, $progressNoteId: ID!) {
    removeProgressNote(patientId: $patientId, visitId: $visitId, progressNoteId: $progressNoteId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

/**
 * History comments
 */
export const ADD_HISTORY_COMMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addHistoryComment($patientId: ID!, $visitId: ID!, $comment: String!) {
    addHistoryComment(patientId: $patientId, visitId: $visitId, comment: $comment) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_HISTORY_COMMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation removeHistoryComment($patientId: ID!, $visitId: ID!, $commentId: ID!) {
    removeHistoryComment(patientId: $patientId, visitId: $visitId, commentId: $commentId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_HISTORY_COMMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation updateHistoryComment($patientId: ID!, $visitId: ID!, $commentId: ID!, $comment: String!) {
    updateHistoryComment(patientId: $patientId, visitId: $visitId, commentId: $commentId, comment: $comment) {
      ...FullPatientWithActiveVisit
    }
  }
`;

/**
 * Surveys
 */

export const ADD_SURVEY_TO_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation addSurveyToHistory($patientId: ID!, $visitId: ID!, $surveyId: String!) {
    addSurveyToHistory(patientId: $patientId, visitId: $visitId, surveyId: $surveyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_SURVEY_CHECKBOX_IN_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation updateSurveyCheckboxInHistory(
    $patientId: ID!
    $visitId: ID!
    $historyId: String!
    $blockId: String!
    $checked: Boolean!
  ) {
    updateSurveyCheckboxInHistory(
      patientId: $patientId
      visitId: $visitId
      historyId: $historyId
      blockId: $blockId
      checked: $checked
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const UPDATE_SURVEY_INPUT_IN_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation updateSurveyInputInHistory(
    $patientId: ID!
    $visitId: ID!
    $historyId: String!
    $blockId: String!
    $value: String!
  ) {
    updateSurveyInputInHistory(
      patientId: $patientId
      visitId: $visitId
      historyId: $historyId
      blockId: $blockId
      value: $value
    ) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_SURVEY_FROM_HISTORY = gql`
  ${FullPatientWithActiveVisit}
  mutation removeSurveyFromHistory($patientId: ID!, $visitId: ID!, $historyId: ID!) {
    removeSurveyFromHistory(patientId: $patientId, visitId: $visitId, historyId: $historyId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const REMOVE_ASSESSMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation removeAssessmentFromActiveVisit($patientId: ID!, $visitId: ID!, $assessmentId: ID!) {
    removeAssessmentFromActiveVisit(patientId: $patientId, visitId: $visitId, assessmentId: $assessmentId) {
      ...FullPatientWithActiveVisit
    }
  }
`;
export const REMOVE_TREATMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation removeTreatmentFromActiveVisit($patientId: ID!, $visitId: ID!, $treatmentId: ID!) {
    removeTreatmentFromActiveVisit(patientId: $patientId, visitId: $visitId, treatmentId: $treatmentId) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_MANUAL_TREATMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addNewManualTreatmentToActiveVisit($patientId: ID!, $visitId: ID!, $treatment: String!) {
    addNewManualTreatmentToActiveVisit(patientId: $patientId, visitId: $visitId, treatment: $treatment) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_MANUAL_ASSESSMENT = gql`
  ${FullPatientWithActiveVisit}
  mutation addNewManualAssessmentToActiveVisit($patientId: ID!, $visitId: ID!, $assessment: String!) {
    addNewManualAssessmentToActiveVisit(patientId: $patientId, visitId: $visitId, assessment: $assessment) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const ADD_VITALS_QUICK_INPUT = gql`
  ${FullPatientWithActiveVisit}
  mutation addAllVitalsAtOnce($patientId: ID!, $visitId: ID!, $measurements: AllVitals!) {
    addAllVitalsAtOnce(patientId: $patientId, visitId: $visitId, measurements: $measurements) {
      ...FullPatientWithActiveVisit
    }
  }
`;
