import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { PaginatedResult, ScheduledVisit } from '../../api/interfaces';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { GET_SCHEDULED_VISITS } from 'scenes/ScheduledVisits/requests';
import { useSession } from 'hooks';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

export interface ScheduledVisitsVariables {
  location: string;
  room: string;
  from: string;
  to: string;
}

interface ScheduledVisitsData {
  scheduledVisits: PaginatedResult<ScheduledVisit>;
}

export type UseScheduledVisitsParams = QueryHookOptions<ScheduledVisitsData, ScheduledVisitsVariables> & {
  roomId: Nullable<string>;
  from: string;
  to: string;
};

export const useScheduledVisits = (params: UseScheduledVisitsParams) => {
  const { roomId, from, to, ...rest } = params;

  const { getUserLocation } = useSession() as any;
  const { id: locationId } = getUserLocation();

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors);
    translateError(firstError);

    if (typeof rest?.onError === 'function') {
      rest.onError(errors);
    }
  };

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<ScheduledVisitsData, ScheduledVisitsVariables>(GET_SCHEDULED_VISITS, {
    ...rest,
    variables: {
      location: locationId,
      room: roomId ?? '',
      from,
      to,
    },
    skip: !roomId || !from || !to,
    fetchPolicy: 'cache-and-network',
    onError,
  });

  const onErrorClose = () => {
    setError(null);
  };

  return {
    isLoading,
    scheduledVisits: data?.scheduledVisits?.docs ?? [],
    error,
    refetch,
    onErrorClose,
  };
};
