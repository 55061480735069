import React from 'react';
import { useMySystemSettings } from '../HealthSystems';
import { EventInput } from '@fullcalendar/common';
import { getSlotDurationFromSettings, scheduledVisitToCalendarEvent, useSchedulerUtils } from './utils';
import { useScheduledVisits } from './useScheduledVisits';
import { Nullable } from 'components-ts/utils';
import { useSession } from 'hooks';
import { RoomEvent, ScheduledVisit } from 'api/interfaces';
import { EventType } from './Calendar/utils';
import { addDays, endOfMonth, startOfMonth, substractDays } from 'components-ts/DateAndTime';
export type EventFilter = Record<EventType, boolean>;
export interface SchedulerContextValue {
  currentDate: Date;
  selectedEvent: Nullable<string>;
  selectedStartDate: Nullable<Date>;
  selectedEndDate: Nullable<Date>;
  selectedAllDay?: boolean;
  roomId: Nullable<string>;
  eventFilter: EventFilter;
  events: Array<EventInput>;
  locale: string;
  slotDuration: any;
  onEventFilterChange: (filter: EventFilter) => void;
  onEventSelect: (event: string) => void;
  onEventChange: (event: EventInput) => void;
  onCurrentDateChange: (current: Date) => void;
  onRoomChange: (roomId: string) => void;
  onPeriodChange: (start: Date, end: Date, allDay?: boolean) => void;
  onReset: () => void;
  onRefresh: () => void;
}

const initialEventFilter = {
  [EventType.IN_PERSON]: true,
  [EventType.PERSONAL_APPOINTMENT]: true,
  [EventType.TELEHEALTH]: true,
};
export const SchedulerContext = React.createContext<Nullable<SchedulerContextValue>>(null);
export const SchedulerProvider: React.FC = (props) => {
  // current room id
  const [roomId, setRoomId] = React.useState<Nullable<string>>(null);

  // current calendar date
  const [currentDate, setCurrentDate] = React.useState<Date>(new Date());

  // event dates selection
  const [selectedStartDate, setSelectedStartDate] = React.useState<Nullable<Date>>(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Nullable<Date>>(null);
  const [selectedAllDay, setSelectedAllDay] = React.useState<boolean | undefined>(false);

  // event selection
  const [selectedEvent, setSelectedEvent] = React.useState<Nullable<string>>(null);

  // event filter
  const [eventFilter, setEventFilter] = React.useState<EventFilter>(initialEventFilter);

  const { getUserLocale } = useSession() as any;
  const locale = getUserLocale();

  const { events, slotDuration, refetchVisits } = useRoomSettings({ roomId, currentDate, eventFilter });

  const onCurrentDateChange = React.useCallback((current: Date): void => {
    setCurrentDate(current);
  }, []);

  const onRoomChange = React.useCallback((roomId: string): void => {
    setRoomId(roomId);
  }, []);

  const onPeriodChange = React.useCallback((start: Date, end: Date, allDay?: boolean): void => {
    /**
     * visits must start and end in the same day.
     * if the end date is 00hs, we actually save 23:59 of the previous day
     */
    const is00 = end.getHours() === 0 && end.getMinutes() === 0;
    const fixedEnd = is00 ? new Date(end.getTime() - 60_000) : end;

    if (start.getDate() !== fixedEnd.getDate()) {
      return;
    }

    setSelectedStartDate(start);
    setSelectedEndDate(fixedEnd);
    setSelectedAllDay(allDay);
  }, []);

  const onEventSelect = React.useCallback((eventId: string) => {
    setSelectedEvent(eventId);
  }, []);

  const onEventChange = React.useCallback((event: EventInput) => {
    setSelectedEvent(event.id ?? null);
  }, []);

  const onEventFilterChange = (filter: EventFilter) => {
    setEventFilter(filter);
  };

  const onReset = React.useCallback(() => {
    setSelectedEndDate(null);
    setSelectedStartDate(null);
    setSelectedEvent(null);
  }, []);

  const value = {
    currentDate,
    roomId,
    selectedEvent,
    selectedStartDate,
    selectedEndDate,
    selectedAllDay,
    slotDuration,
    locale,
    events,
    eventFilter,
    onEventFilterChange,
    onEventSelect,
    onEventChange,
    onCurrentDateChange,
    onRoomChange,
    onPeriodChange,
    onReset,
    onRefresh: refetchVisits,
  };

  return <SchedulerContext.Provider value={value}>{props.children}</SchedulerContext.Provider>;
};

interface RoomSettingsParams {
  roomId: Nullable<string>;
  currentDate: Date;
  eventFilter: EventFilter;
}

const useRoomSettings = (params: RoomSettingsParams) => {
  const { roomId, currentDate, eventFilter } = params;

  /**
   * The largest visible period is close to a month plus 2 weeks:
   * Monthly view with the last week of the previous month and the first
   * one of the next month. So, if we want our calendar to correctly show all the
   * events in range, we have to fetch from -(startOfMonth - 1w) to + (endOfMonth + 1w)
   */
  const from = substractDays(startOfMonth(new Date(currentDate)), 7).toISOString();
  const to = addDays(endOfMonth(new Date(currentDate)), 7).toISOString();

  const visitParams = {
    roomId,
    from,
    to,
  };

  const { roomEventToCalendarEvent } = useSchedulerUtils();

  const { scheduledVisits, refetch } = useScheduledVisits(visitParams);
  const eventsFromVisits = filterAndFormatVisit(scheduledVisits, eventFilter);

  const { scheduler, rooms } = useMySystemSettings();
  const slotDuration = getSlotDurationFromSettings(scheduler);

  const room = rooms.find((room) => room.id === roomId);
  const eventsFromRoom = filterAndFormatRoomEvents(room?.events ?? [], eventFilter, roomEventToCalendarEvent);

  return {
    events: [...eventsFromVisits, ...eventsFromRoom],
    slotDuration,
    refetchVisits: refetch,
  };
};

const filterAndFormatVisit = (scheduledVisits: Array<ScheduledVisit>, filter: EventFilter): Array<EventInput> => {
  if (filter.inPerson === filter.telehealth) {
    return filter.telehealth ? scheduledVisits.map(scheduledVisitToCalendarEvent) : [];
  }

  return scheduledVisits
    .filter((visit) => visit.registrationData.telehealth === filter.telehealth)
    .map(scheduledVisitToCalendarEvent);
};

const filterAndFormatRoomEvents = (
  roomEvents: Array<RoomEvent>,
  filter: EventFilter,
  parser: any
): Array<EventInput> => {
  if (filter.personalAppointment) {
    return roomEvents.map(parser);
  }

  return [];
};
