import { BasicPatient, BasicPatientWithoutId, FullPatient, FullPatientWithoutId } from 'api/interfaces';
import React from 'react';
import { useSystems } from './HealthSystems';
import { ClinicInfo } from './HealthSystems/ClinicInfo';
import { PatientInfo } from './Patients/PatientInfo';

type PrintHeaderProps = {
  locationId: string;
  patient: BasicPatient | FullPatient | BasicPatientWithoutId | FullPatientWithoutId;
};

export const PrintHeader: React.FC<PrintHeaderProps> = (props) => {
  const { locationId, patient } = props;

  const { getMySystem, getMySystemLogo } = useSystems();

  const system = getMySystem();
  const logo = getMySystemLogo();

  return (
    <div className="row">
      <div className="col-8">
        <ClinicInfo systemId={system.id} locationId={locationId} className="mb-3" />
        <PatientInfo patient={patient} />
      </div>
      <div className="col-4 text-right">
        {logo && (
          <img
            className="img-fluid"
            style={{ maxWidth: '50px', objectFit: 'scale-down' }}
            src={logo}
            alt={'system logo'}
          />
        )}
      </div>
    </div>
  );
};
