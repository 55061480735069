import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import {
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col
} from 'reactstrap'
import { StudyFile, StudyItemEditor } from 'components/Study'
import { DynamicTranslation } from 'components/DynamicTranslation'

const messages = defineMessages({
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close'
  },
  attachedFiles: {
    id: 'UI.label_attached_files',
    defaultMessage: 'Atacched files ({count})'
  }
})

const StudyResultModal = (props) => {
  const { toggle, study, intl } = props

  return (
    <Modal isOpen={!!study} toggle={toggle}>
      {study &&
        <ModalHeader toggle={toggle}>
          <DynamicTranslation text={(study.name || {}).text} />
        </ModalHeader>}
      {study && (
        <ModalBody>
          <Row className='mx-0'>
            {(study.items || []).map((item) => (
              <Col md={6} className='p-1' key={item.id}>
                <StudyItemEditor readOnly item={item} studyId={study.studyId} />
              </Col>
            ))}
          </Row>
          {study.note && (
            <Row className='mx-0 mt-2'>
              <p className='m-0'>{study.note}</p>
            </Row>
          )}
          <StudyFiles files={study.files} />
        </ModalBody>
      )}
      <ModalFooter>
        <Button color='secondary' className='mr-1 ' onClick={toggle}>
          <T id={messages.closeButton.id}>
            {intl.formatMessage(messages.closeButton)}
          </T>
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const StudyFiles = injectIntl(props => {
  const { files, intl } = props

  if (!Array.isArray(files)) {
    return null
  }

  return <div className={'mt-3'}>
    <h6 className='text-muted'>
      <T id={messages.attachedFiles.id}>
        {intl.formatMessage(messages.attachedFiles, { count: files.length })}
      </T>
    </h6>
    {
      files.map(file => {
        const alt = `study-image-${file}`
        return <StudyFile fileId={file} key={file} alt={alt} className='mt-1' />
      })
    }
  </div>
})
export default injectIntl(StudyResultModal)
