import React from 'react';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { UPDATE_TRANSLATION } from 'scenes/Translations/requests';
import { FullTranslation } from 'api/interfaces/translations';
import { extractCodeFromGraphQLError } from 'utils/errors';

interface UpdateTranslationVariables {
  id: string;
  locale: string;
  message: string;
  defaultMessage: string;
}

interface TranslationQueryData {
  updateTranslation: FullTranslation;
}

export type UseUpdateMutationParams = MutationHookOptions<TranslationQueryData, UpdateTranslationVariables>;

export function useUpdateTranslation(params?: UseUpdateMutationParams) {
  const [error, setError] = React.useState<Array<string>>([]);

  const onError = (errors) => {
    if (Array.isArray(errors)) {
      const errorCode = extractCodeFromGraphQLError(errors[0]);
      setError(errorCode);
    }
  };

  const [updateTranslation, { loading: isLoading }] = useMutation<TranslationQueryData, UpdateTranslationVariables>(
    UPDATE_TRANSLATION,
    {
      ...params,
      onError,
    }
  );

  const onUpdate = (values: UpdateTranslationVariables) => {
    setError([]);

    if (values.message.length === 0) {
      return;
    }

    const variables = {
      ...values,
    };
    updateTranslation({ variables });
  };

  return {
    onUpdate,
    isLoading,
    error,
  };
}
