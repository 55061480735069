import { MutationHookOptions, useMutation } from '@apollo/client';
import { Drug } from 'api/interfaces';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { CREATE_PHARMACY_ITEM_STOCK } from 'scenes/Pharmacy/requests';
import { usePharmacyItems } from './usePharmacyItems';

const messages = defineMessages({
  updateError: {
    id: 'CreatePharmacyItemStock.update_error',
    defaultMessage: 'An error ocurred while updating the item',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  noAmountError: {
    id: 'CreatePharmacyItemStock.no_amount_error',
    defaultMessage: 'Please provide an amount',
  },
  requiredError: {
    id: 'UI.required_error',
    defaultMessage: 'You must enter a value for this field.',
  },
});

export type CreatePharmacyItemStockParams = {
  container: string;
  containerCount: number;
  containerCapacity: number;
  stock_unit: string;
  amount: number;
  item: string;
  notes?: string;
  lotNumber?: string;
  lotExpirationDate?: string;
};

export type CreatePharmacyItemStockValidatedValues = {
  container: string;
  containerCount: number;
  containerCapacity: number;
  stock_unit: string;
  amount: number;
  notes?: string;
  lotNumber?: string;
  lotExpirationDate?: Date;
};

export type CreatePharmacyItemStockInitialValues = Partial<CreatePharmacyItemStockValidatedValues>;

interface CreatePharmacyItemStockVariables {
  pharmacyStock: CreatePharmacyItemStockParams;
}

interface CreatePharmacyItemStockData {
  createStock: Drug;
}

type CreatePharmacyItemStockMutationParams = MutationHookOptions<
  CreatePharmacyItemStockData,
  CreatePharmacyItemStockVariables
> & {
  item: string;
};

export const useCreatePharmacyItemStock = (params: CreatePharmacyItemStockMutationParams) => {
  const { item } = params;
  const intl = useIntl();
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'update_error':
        return setError(messages.updateError);
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const [createPharmacyItemStock, { loading }] = useMutation<
    CreatePharmacyItemStockData,
    CreatePharmacyItemStockVariables
  >(CREATE_PHARMACY_ITEM_STOCK, {
    ...params,
    onError,
  });

  const onAddStockSubmit = (values: CreatePharmacyItemStockValidatedValues) => {
    const variables: CreatePharmacyItemStockVariables = {
      pharmacyStock: {
        amount: values.amount,
        container: values.container,
        containerCapacity: values.containerCapacity,
        containerCount: values.containerCount,
        lotExpirationDate: values.lotExpirationDate ? values.lotExpirationDate.toISOString() : undefined,
        stock_unit: values.stock_unit,
        lotNumber: values.lotNumber,
        notes: values.notes,
        item,
      },
    };

    return createPharmacyItemStock({
      variables,
    });
  };

  const onRemoveStockSubmit = (values: CreatePharmacyItemStockValidatedValues) => {
    const variables: CreatePharmacyItemStockVariables = {
      pharmacyStock: {
        amount: Number(values.amount) * -1,
        container: values.container,
        containerCapacity: values.containerCapacity,
        containerCount: values.containerCount,
        lotExpirationDate: values.lotExpirationDate ? values.lotExpirationDate.toISOString() : undefined,
        stock_unit: values.stock_unit,
        lotNumber: values.lotNumber,
        notes: values.notes,
        item,
      },
    };

    return createPharmacyItemStock({
      variables,
    });
  };

  const validate: ValidateFn<CreatePharmacyItemStockInitialValues> = (values) => {
    const errors: ValidationError<CreatePharmacyItemStockInitialValues> = {};

    if (!values.amount) {
      errors.amount = intl.formatMessage(messages.noAmountError);
    }

    if (!values.containerCapacity) {
      errors.containerCapacity = intl.formatMessage(messages.requiredError);
    }

    if (!values.containerCount) {
      errors.containerCount = intl.formatMessage(messages.requiredError);
    }

    return errors;
  };

  const { getPharmacyItem } = usePharmacyItems();
  const drug = getPharmacyItem(item);

  const initialValues: CreatePharmacyItemStockInitialValues = {
    stock_unit: drug?.stock_unit ?? undefined,
  };

  return {
    validate,
    createPharmacyItemStock,
    onAddStockSubmit,
    onRemoveStockSubmit,
    loading,
    error,
    initialValues,
  };
};
