import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { GET_QUICKPICKS_WITH_DRUG } from '../scenes/Quickpicks/requests'
/**
 *
 * @param {
 *  queryVariables: { location: location filter, q: search string, offset: skipped results, limit: max-length for results },
 *  queryOptions: { onCompleted, onError }
 * }
 *
 */

function useQuickpicksWithDrug (variables = {}, options = {}) {
  const { q, offset, limit, location } = variables

  const { loading, data, error, refetch } = useQuery(GET_QUICKPICKS_WITH_DRUG, {
    variables: {
      location,
      q,
      offset,
      limit
    },
    ...options
  })

  // get by ID
  const getQuickpick = useCallback((id) => {
    if (!data || !data.quickpicks) return undefined
    return data.quickpicks.docs.find(quickpick => quickpick.id === id)
  }, [data])

  return {
    loading,
    count: (data && data.quickpicksWithDrug) ? data.quickpicksWithDrug.count : 0,
    quickpicks: (data && data.quickpicksWithDrug) ? data.quickpicksWithDrug.docs : [],
    error,
    getQuickpick,
    refetch
  }
}
export default useQuickpicksWithDrug
