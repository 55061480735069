export const STUDY_RESULT_TYPE = Object.freeze({
  NUMERICAL: 'numerical',
  CATEGORICAL: 'categorical',
  CATEGORICAL_BASED_ON_NUMBER: 'categoricalBasedOnNumber'
})

/**
 * Shared config to be used in request generation
 * and client form
 * Max file length 2mb
 */
export const MAX_STUDY_FILE_LENGHT_2_MB = 2000000
