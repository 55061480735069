import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FaPhone } from 'react-icons/fa';
import { defineMessages, useIntl } from 'react-intl';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { isValidPhoneNumber, sanitizePhone } from './utils';
import { guessCountry } from 'components-ts/i18n';

const messages = defineMessages({
  phoneLabel: {
    id: 'UI.phoneNumber',
    defaultMessage: 'Phone number',
  },
  phoneHelp: {
    id: 'PhoneNumberField.help_text',
    defaultMessage: 'E.164 format: {phone}',
  },
});

type PhoneNumberFieldProps = Partial<HTMLInputElement> &
  FieldRenderProps<string> & {
    withIcon?: boolean;
    country?: string;
  };

export const PhoneNumberField: React.FC<PhoneNumberFieldProps> = (props) => {
  const { withIcon, withLabel, input, meta, labelChildren, ...htmlInputProps } = props;

  const intl = useIntl();

  /**
   * if does not receive the country from props, we should take it from the browser
   * to allow users to enter their local number without +countryCode
   */
  const country = props.country ?? guessCountry() ?? undefined;

  /**
   * If the form does not contain a format validation, we should not
   * show the helper because it may be wrong
   */
  const shouldShowHelper = isValidPhoneNumber(input.value, country);
  return (
    <>
      <InputGroup>
        {withIcon && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaPhone />
            </InputGroupText>
          </InputGroupAddon>
        )}
        <Input {...htmlInputProps} {...input} type="phone" placeholder={intl.formatMessage(messages.phoneLabel)} />
      </InputGroup>
      {shouldShowHelper && (
        <small className="text-success">
          <T id={messages.phoneHelp.id}>
            {intl.formatMessage(messages.phoneHelp, { phone: sanitizePhone(input.value, country) })}
          </T>
        </small>
      )}
    </>
  );
};
