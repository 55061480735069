import React, { useState, useEffect, useCallback } from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import Select from 'react-select'
import { FormGroup, Label } from 'reactstrap'
import { usePricelists } from '../components-ts/Pricelists/usePricelists'

const debug = require('debug')('wd:PricelistSelector')

// static translations
const messages = defineMessages({
  paymentMethod: {
    id: 'PricelistSelector.payment_method',
    defaultMessage: 'Payment method'
  }
})

/**
 * Component for allow to select a payment method / pricelist
 *
 * @component
 * @example

 * // if isMulti === true, pricelists is an Array<string>
 * const onChange = ([...pricelists]) => debug(pricelists[0])

 * // else, pricelists is a string
 * const onChange = (pricelist) => debug(pricelist)
 *
 * return (
 *   <PricelistSelector
 *      value={pricelist}
 *      onChange={onChange}
 *   />
 * )
 */
const PricelistSelector = (props) => {
  // props
  const {
    value: pricelistId,
    systemId,
    onChange,
    customLabel,
    isMulti,
    isClearable,
    className,
    isDisabled,
    intl,
    menuPlacement = 'bottom'
  } = props

  const { formatMessage } = intl

  if (!pricelistId) debug('Missing prop: pricelist')
  if (typeof onChange !== 'function') debug('Missing prop: onChange')

  // hooks
  const { docs: list, getPricelist } = usePricelists({ systemId })
  const options = (list || []).map(({ id, name }) => ({ value: id, label: name }))

  const formatOptioon = useCallback(pricelistId => {
    const { id, name } = getPricelist(pricelistId) || {}
    return name ? {
      value: id,
      label: name
    } : ''
  }, [getPricelist])
  // state
  const [pricelist, setPricelist] = useState('')

  // If pricelistId is received in props, then updates state with the proper pricelist object
  useEffect(() => {
    setPricelist(pricelistId
      ? formatOptioon(pricelistId)
      : ''
    )
  }, [pricelistId, formatOptioon])

  const _onChange = (val) => {
    setPricelist(val)
    if (typeof onChange === 'function') onChange(isMulti && val ? val.map(v => v.value) : (val || {}).value)
  }

  debug({ pricelist, options })

  return <FormGroup>
    <Label>
      {
        customLabel || <T id={messages.paymentMethod.id}>{formatMessage(messages.paymentMethod)}</T>
      }
    </Label>
    <Select
      menuPlacement={menuPlacement}
      value={options.length ? pricelist : ''}
      className={`dropdownZIndex ${className}`}
      onChange={_onChange}
      options={options}
      isMulti={isMulti}
      isDisabled={isDisabled || !options.length}
      isClearable={isClearable}
      blurInputOnSelect
      isSearchable
    />
  </FormGroup>
}

PricelistSelector.defaultProps = {
  pricelist: '',
  onChange: () => {},
  isMulti: false,
  isClearable: false,
  isDisabled: false
}
export default injectIntl(PricelistSelector)
