export type FormattedValue = string | number;
export type MutatorFn<T> = (item: T) => FormattedValue;

export type Mutator<T extends {}> = Record<string, MutatorFn<T>>;

type Mutated<T extends {}, M extends Mutator<T>> = {
  [Key in keyof M]: FormattedValue;
};

type Params<T, M> = {
  items: Array<T>;
  mutators: M;
};

type ReturnedValue<T, M extends Mutator<T>> = Array<Mutated<T, M>>;

function applyMutators<T extends {}, M extends Mutator<T>>(params: Params<T, M>): ReturnedValue<T, M> {
  const { items, mutators } = params;

  const formattedItems = items.map((item) => {
    const initalValue = {} as Mutated<T, M>;
    const newObj = Object.keys(mutators).reduce((newItem, key) => {
      const mutate = mutators[key];
      const newValue = mutate(item);

      return {
        ...newItem,
        [key]: newValue,
      };
    }, initalValue);

    return newObj;
  });

  return formattedItems;
}

type UseMutatorAPI<T, M extends Mutator<T>> = {
  applyMutators: (params: Params<T, M>) => ReturnedValue<T, M>;
};

export function useMutatorsAPI<T, M extends Mutator<T>>(): UseMutatorAPI<T, M> {
  return {
    applyMutators,
  };
}
