import React from 'react';
import { Nullable, validateNotNil } from 'components-ts/utils';
import { useDevVisibility } from './TranslationModeSwitch';
import { TranslationModal } from './TranslationModal';

type TranslationContextValue = {
  devVisibility: boolean;
  onTranslateOpen: (id: string) => void;
  onTranslateClose: () => void;
  translationId: Nullable<string>;
};

export const TranslationModeContext = React.createContext<Nullable<TranslationContextValue>>(null);
export const TranslationModeContextProvider: React.FC = (props) => {
  const [translationId, setTranslationId] = React.useState<Nullable<string>>(null);
  const onTranslateClose = () => setTranslationId(null);
  const devVisibility = useDevVisibility();

  const value = {
    devVisibility,
    onTranslateOpen: setTranslationId,
    onTranslateClose,
    translationId,
  };

  return (
    <TranslationModeContext.Provider value={value}>
      {translationId && <TranslationModal id={translationId} isOpen onToggle={onTranslateClose} />}
      {props.children}
    </TranslationModeContext.Provider>
  );
};

export const useTranslationModeContext = () => {
  const ctx = React.useContext(TranslationModeContext);

  validateNotNil(ctx);

  return ctx;
};
