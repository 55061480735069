import React from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import {
  CREATE_SCHEDULED_VISIT,
  DELETE_SCHEDULED_VISIT,
  UPDATE_SCHEDULED_VISIT,
} from '../../scenes/ScheduledVisits/requests';
import { BasicPatient, HowLong, NewRegistrationData, ScheduledVisit, VisitType } from '../../api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { useSession } from 'hooks';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { usePatients } from 'components-ts/Patients';
import { calcDateDiffInMinutes } from 'components-ts/DateAndTime';

export const messages = defineMessages({
  invalidDateTime: {
    id: 'ScheduledVisitsRegistrationTab.invalid_date_time',
    defaultMessage: 'Invalid date/time',
  },
  invalidDuration: {
    id: 'ScheduledVisitsRegistrationTab.invalid_duration',
    defaultMessage: 'Invalid Duration',
  },
  invalidLocation: {
    id: 'ScheduledVisitsRegistrationTab.invalid_location',
    defaultMessage: 'Invalid location',
  },
  invalidRoom: {
    id: 'ScheduledVisitsRegistrationTab.invalid_room',
    defaultMessage: 'Invalid room',
  },
  roomNotAvailable: {
    id: 'ScheduledVisitsRegistrationTab.room_not_available_during_this_period',
    defaultMessage: 'The room is not available during this period',
  },
  slotOccupiedByScheduledVisit: {
    id: 'ScheduledVisitsRegistrationTab.slot_occupied_by_scheduled_visit',
    defaultMessage: 'This slot is already occupied by a Scheduled visit',
  },
  errorsOccurred: {
    id: 'ScheduledVisitsRegistrationTab.cannot_save_scheduled_event_reason',
    defaultMessage: 'Cannot save scheduled event. Reason:',
  },
  cantCreateScheduledVisit: {
    id: 'error_creating_scheduled_visit',
    defaultMessage: 'Error creating scheduled visit. Try again',
  },
  smsDeliveryFailed: {
    id: 'scheduled_created_sms_delivery_failed',
    defaultMessage: 'Scheduled visit was created. But SMS delivery failed',
  },
  deleteError: {
    id: 'ScheduledVisitsRegistrationTab.error_deleting_scheduled_event',
    defaultMessage: 'Error deleting scheduled visit. Try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  noPatientSelected: {
    id: 'PatientSelector.no_patient_selected_error',
    defaultMessage: 'You must select a valid patient',
  },
  invalidDate: {
    id: 'form_validation.invalid_date',
    defaultMessage: 'Invalid value',
  },
});

export interface RegistrationDataFormValues {
  type?: VisitType;
  start: Nullable<string>;
  end: Nullable<string>;
  patientId?: string;
  telehealth?: boolean;
  complaint?: string;
  howLong?: HowLong;
}

/**
 * i'm sure it may exist a better way to do this
 */
export interface ValidatedRegistrationDataFormValues {
  type: VisitType;
  start: string;
  end: string;
  patientId: string;
  telehealth?: boolean;
  complaint?: string;
  howLong?: HowLong;
}

/**
 * Create
 */
export interface CreateScheduledVisitVariables {
  newScheduledVisit: NewRegistrationData;
}

interface ScheduledVisitWithWarning {
  visit: ScheduledVisit;
  warning: string;
}

interface CreateScheduledVisitData {
  createScheduledVisit: ScheduledVisitWithWarning;
}

export type UseCreateScheduledVisitParams = MutationHookOptions<
  CreateScheduledVisitData,
  CreateScheduledVisitVariables
> & {
  roomId: string;
};

export const useCreateScheduledVisit = (params: UseCreateScheduledVisitParams) => {
  const { roomId, ...rest } = params;

  const intl = useIntl();

  const { getUserLocation } = useSession();
  const { id: location } = getUserLocation();

  const { getPatient } = usePatients({ location: [location] });
  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof params?.onError === 'function') {
      params.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'invalid_date_time':
        return setError(messages.invalidDateTime);

      case 'invalid_duration':
        return setError(messages.invalidDuration);

      case 'invalid_location':
        return setError(messages.invalidLocation);

      case 'invalid_room':
        return setError(messages.invalidRoom);

      case 'slot_occupied_by_scheduled_visit':
        return setError(messages.slotOccupiedByScheduledVisit);

      case 'error_creating_scheduled_visit':
        return setError(messages.cantCreateScheduledVisit);

      case 'sms_delivery_failed':
        return setError(messages.smsDeliveryFailed);

      default:
        return setError(messages.internalServerError);
    }
  };

  const validate: ValidateFn<RegistrationDataFormValues> = async (values) => {
    const errors: ValidationError<RegistrationDataFormValues> = {};

    if (!values.type) {
      errors.type = intl.formatMessage(messages.required);
    }

    if (!values.start) {
      errors.start = intl.formatMessage(messages.required);
    }

    if (!values.end) {
      errors.end = intl.formatMessage(messages.required);
    }

    if (!values.patientId) {
      errors.patientId = intl.formatMessage(messages.required);
    }

    const patient = getPatient(values.patientId ?? '');
    if (!patient) {
      errors.patientId = intl.formatMessage(messages.required);
    }

    // validate using slot
    if (values.start && values.end) {
      const start = new Date(values.start);
      const end = new Date(values.end);
      const duration = calcDateDiffInMinutes(end, start);

      if (duration <= 0) {
        errors.end = intl.formatMessage(messages.required);
      }

      if (!(start.getTime() < end.getTime())) {
        errors.end = intl.formatMessage(messages.invalidDate);
      }
    }

    return errors;
  };

  const [createScheduledVisit, { loading: isLoading }] = useMutation<
    CreateScheduledVisitData,
    CreateScheduledVisitVariables
  >(CREATE_SCHEDULED_VISIT, {
    ...rest,
    onError,
  });

  const onSubmit = (newEvent: ValidatedRegistrationDataFormValues) => {
    setError(null);

    const { type, start, end, patientId, telehealth, complaint, howLong } = newEvent;

    const patient = getPatient(patientId) as BasicPatient;

    const newScheduledVisit: NewRegistrationData = {
      type,
      location,
      room: roomId,
      patient,
      start: start,
      end: end,
      telehealth: !!telehealth,
    };

    if (complaint && howLong) {
      newScheduledVisit.chiefComplaint = {
        howLong,
        complaint,
      };
    }

    const variables = {
      newScheduledVisit,
    };

    createScheduledVisit({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  };
};

/**
 * Update
 */
export interface UpdateScheduledVisitVariables {
  id: string;
  registrationData: NewRegistrationData;
}

interface ScheduledVisitWithWarning {
  visit: ScheduledVisit;
  warning: string;
}

interface UpdateScheduledVisitData {
  UpdateScheduledVisit: ScheduledVisitWithWarning;
}

export type UseUpdateScheduledVisitParams = MutationHookOptions<
  UpdateScheduledVisitData,
  UpdateScheduledVisitVariables
> & {
  roomId: string;
  scheduledVisitId: string;
};

export const useUpdateScheduledVisit = (params: UseUpdateScheduledVisitParams) => {
  const { roomId, scheduledVisitId, ...rest } = params;

  const intl = useIntl();

  const { getUserLocation } = useSession() as any;
  const { id: location } = getUserLocation();

  const { getPatient } = usePatients({ location: [location] });
  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof params?.onError === 'function') {
      params.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'invalid_date_time':
        return setError(messages.invalidDateTime);

      case 'invalid_duration':
        return setError(messages.invalidDuration);

      case 'invalid_location':
        return setError(messages.invalidLocation);

      case 'invalid_room':
        return setError(messages.invalidRoom);

      case 'room_not_available_during_this_period':
        return setError(messages.roomNotAvailable);

      case 'slot_occupied_by_scheduled_visit':
        return setError(messages.slotOccupiedByScheduledVisit);

      case 'sms_delivery_failed':
        return setError(messages.smsDeliveryFailed);

      default:
        return setError(messages.internalServerError);
    }
  };

  const validate: ValidateFn<RegistrationDataFormValues> = async (values) => {
    const errors: ValidationError<RegistrationDataFormValues> = {};

    if (!values.type) {
      errors.type = intl.formatMessage(messages.required);
    }

    if (!values.start) {
      errors.start = intl.formatMessage(messages.required);
    }

    if (!values.end) {
      errors.end = intl.formatMessage(messages.required);
    }

    if (!values.patientId) {
      errors.patientId = intl.formatMessage(messages.required);
    }

    const patient = getPatient(values.patientId ?? '');
    if (!patient) {
      errors.patientId = intl.formatMessage(messages.required);
    }

    // validate using slot
    if (values.start && values.end) {
      const start = new Date(values.start);
      const end = new Date(values.end);
      const duration = calcDateDiffInMinutes(end, start);

      if (duration <= 0) {
        errors.end = intl.formatMessage(messages.required);
      }

      if (!(start.getTime() < end.getTime())) {
        errors.end = intl.formatMessage(messages.invalidDate);
      }
    }

    return errors;
  };

  const [updateScheduledVisit, { loading: isLoading }] = useMutation<
    UpdateScheduledVisitData,
    UpdateScheduledVisitVariables
  >(UPDATE_SCHEDULED_VISIT, {
    ...rest,
    onError,
  });

  const onSubmit = (newEvent: ValidatedRegistrationDataFormValues) => {
    setError(null);

    const { type, start, end, patientId, telehealth, complaint, howLong } = newEvent;

    const patient = getPatient(patientId) as BasicPatient;

    const registrationData: NewRegistrationData = {
      type,
      location,
      room: roomId,
      patient,
      start,
      end,
      telehealth: !!telehealth,
    };

    if (complaint || howLong) {
      registrationData.chiefComplaint = {
        howLong: howLong ?? null,
        complaint: complaint ?? '',
      };
    }

    const variables = {
      id: scheduledVisitId,
      registrationData,
    };

    updateScheduledVisit({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  };
};

/**
 * Delete
 */
interface DeleteScheduledVisitMutationData {
  deleteScheduledVisit: {
    done: boolean;
  };
}

interface DeleteScheduledVisitVariables {
  id: string;
}

export type UseDeleteScheduledVisitParams = MutationHookOptions<
  DeleteScheduledVisitMutationData,
  DeleteScheduledVisitVariables
> & {
  id: string;
};

export const useDeleteScheduledVisit = (params: UseDeleteScheduledVisitParams) => {
  const { id, ...rest } = params;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_deleting_scheduled_event':
        return setError(messages.deleteError);

      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const [deleteScheduledVisit, { loading: isLoading }] = useMutation<
    DeleteScheduledVisitMutationData,
    DeleteScheduledVisitVariables
  >(DELETE_SCHEDULED_VISIT, {
    ...rest,
    onError,
  });

  const onDelete = () => {
    setError(null);

    const variables = {
      id,
    };

    deleteScheduledVisit({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    onDelete,
    error,
    isLoading,
    onErrorClose,
  };
};
