import { AllRoles, CustomerRole, GlobalRole, Roles } from '../Auth/permissions';
export enum AppScenes {
  AUTH = 'auth',
  BACKOFFICE = 'backoffice',
  BILLING = 'billing',
  HEALTH_SYSTEMS = 'healthSystems',
  LAB = 'lab',
  MEDICAL_REFERENCE = 'medicalReference',
  METRICS = 'metrics',
  PATIENTS = 'patients',
  PHARMACY = 'pharmacy',
  PREFERENCES = 'preferences',
  PRICING = 'pricing',
  PROCEDURES = 'procedures',
  QUICKPICKS = 'quickpicks',
  REPORTS = 'reports',
  SCHEDULER = 'scheduler',
  SETUP = 'setup',
  NOTES = 'notes',
  TELEHEALTH = 'telehealth',
  TRANSLATION = 'translation',
  // UI_REFERENCE = 'uiReference',
  UNAUTH = 'unauth',
  VISITS = 'visits',
  CONTENT_LIBRARIES = 'contentLibraries',
}

/**
 * Creating this as object will make the ts
 * migration easier
 *
 * If all the routes in the scene need the same permissions
 * they would be attached to the scene instead of duplicating
 * the array for each route
 *
 * In case the scene has no 'roles', when we validate the access
 * we'll check if the role exist in any of the internal routes
 */

interface Route {
  name: string;
  path: string;
  buildPath?: (...args: any) => string;
  roles?: Array<Roles>;
}

interface Scene {
  name: string;
  path: string;
  routes: Record<string, Route>;
  roles?: Array<Roles>;
}

export const sitemap = {
  [AppScenes.AUTH]: {
    name: 'Auth',
    path: '/auth',
    roles: AllRoles,
    routes: {
      changePassword: {
        path: '/auth/change-password',
        name: 'Change password',
      },
      emailVerification: {
        path: '/auth/email-verification',
        name: 'Verify email address thru TOTP code',
      },
      phoneVerification: {
        path: '/auth/phone-number-verification',
        name: 'Verify phone number thru TOTP code',
      },
    },
  },
  [AppScenes.BACKOFFICE]: {
    name: 'Backoffice',
    path: '/backoffice',
    roles: [GlobalRole.ADMIN],
    routes: {
      sales: {
        name: 'Sales',
        path: '/backoffice/sales',
      },
      users: {
        name: 'Users',
        path: '/backoffice/users',
        routes: {
          profile: {
            name: 'User profile',
            path: '/backoffice/users/:username',
            buildPath: (username: string) => `/backoffice/users/${username}`,
          },
        },
      },
    },
  },
  [AppScenes.BILLING]: {
    name: 'Billing',
    path: '/billing',
    roles: [
      GlobalRole.ADMIN,
      CustomerRole.SYSTEM_ADMIN,
      CustomerRole.LOCAL_ADMIN,
      CustomerRole.BILLING,
      CustomerRole.BILLING_ADMIN,
    ],
    routes: {
      list: {
        name: 'Billing list',
        path: '/billing',
      },
      bill: {
        name: 'Bill Editor',
        path: '/billing/:id',
        buildPath: (visitId: string) => `/billing/${visitId}`,
      },
    },
  },
  [AppScenes.HEALTH_SYSTEMS]: {
    name: 'Health Systems',
    path: '/health-systems',
    roles: AllRoles,
    routes: {
      systems: {
        name: 'Heath Systems',
        path: '/health-systems',
        roles: AllRoles,
      },
      systemEditor: {
        name: 'Heath System Editor',
        path: '/health-systems/:systemId',
        buildPath: id => `/health-systems/${id}`,
        roles: AllRoles,
      },
      locationEditor: {
        name: 'Location Editor',
        path: '/health-systems/:systemId/location/:locationId',
        buildPath: (systemId, locationId) => `/health-systems/${systemId}/location/${locationId}`,
        roles: AllRoles,
      },
      roomEditor: {
        name: 'Room Editor',
        path: '/health-systems/:systemId/location/:locationId/room/:roomId',
        buildPath: (systemId, locationId, roomId) =>
          `/health-systems/${systemId}/location/${locationId}/room/${roomId}`,
        roles: AllRoles,
      },
      systemStaff: {
        name: 'Staff',
        path: '/health-systems/:systemId/staff',
        buildPath: systemId => `/health-systems/${systemId}/staff`,
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN],
      },
      systemUserProfile: {
        name: 'User Profile',
        path: '/health-systems/:systemId/staff/:username',
        buildPath: (systemId, username) => `/health-systems/${systemId}/staff/${username}`,
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN],
      },
      systemIntegrations: {
        name: 'Integrations',
        path: '/health-systems/:systemId/integrations',
        buildPath: systemId => `/health-systems/${systemId}/integrations`,
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN],
      },
      systemWebhooks: {
        name: 'Webhooks',
        path: '/health-systems/:systemId/webhooks',
        buildPath: systemId => `/health-systems/${systemId}/webhooks`,
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN],
      },
    },
  },
  [AppScenes.LAB]: {
    name: 'Lab',
    path: '/lab',
    routes: {
      templates: {
        name: 'Study Template List',
        path: '/lab/templates',
        roles: [GlobalRole.ADMIN],
      },
      templateEditor: {
        name: 'Study Template Editor',
        path: '/lab/templates/:templateId',
        buildPath: (id: string) => `/lab/templates/${id}`,
        roles: [GlobalRole.ADMIN],
      },
      studies: {
        name: 'Study List',
        path: '/lab/studies',
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN, CustomerRole.LAB],
      },
      fillStudy: {
        name: 'Study',
        path: '/lab/studies/:studyId',
        buildPath: (id: string) => `/lab/studies/${id}`,
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN, CustomerRole.LAB],
      },
      filledStudiesByVisit: {
        name: 'Studies by visit',
        path: '/lab/studies/visit/:visitId',
        buildPath: (visitId: string) => `/lab/studies/visit/${visitId}`,
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN, CustomerRole.LAB],
      },
    },
  },
  [AppScenes.MEDICAL_REFERENCE]: {
    name: 'Medical References',
    path: '/',
    roles: [GlobalRole.ADMIN],
    routes: {
      vitalsReference: {
        name: 'Vitals Reference',
        path: '/vitals-reference',
      },
      lists: {
        name: 'Lists',
        path: '/lists',
        routes: {
          listEditor: {
            name: 'List Editor',
            path: '/lists/:listId',
            buildPath: (listId: string) => `/lists/${listId}`,
          },
        },
      },
      histories: {
        name: 'Histories',
        path: '/histories',
      },
      exams: {
        name: 'Exams',
        path: '/exams',
        roles: [GlobalRole.ADMIN],
        routes: {
          editor: {
            name: 'Exam editor',
            path: '/exams/:examId',
            buildPath: (examId: string) => `/exams/${examId}`,
          },
        },
      },
      checklists: {
        name: 'Checklists',
        path: '/checklists',
        routes: {
          editor: {
            name: 'Checklist editor',
            path: '/checklists/:checklistId',
            buildPath: (checklistId: string) => `/checklists/${checklistId}`,
            roles: [GlobalRole.ADMIN],
          },
          reference: {
            name: 'Checklists reference',
            path: '/checklists/reference',
          },
          preview: {
            name: 'Checklist preview',
            path: '/checklists/:checklistId/preview',
            buildPath: (checklistId: string) => `/checklists/${checklistId}/preview`,
          },
        },
      },
      codes: {
        name: 'Codes',
        path: '/codes',
      },
    },
  },
  [AppScenes.METRICS]: {
    name: 'Metrics',
    path: '/metrics',
    roles: [
      GlobalRole.ADMIN,
      CustomerRole.SYSTEM_ADMIN,
      CustomerRole.LOCAL_ADMIN,
      CustomerRole.DOCTOR,
      CustomerRole.NURSE,
      CustomerRole.REGISTRAR,
      CustomerRole.BILLING,
      CustomerRole.BILLING_ADMIN,
      CustomerRole.LAB,
      CustomerRole.PHARMACIST,
    ],
    routes: {
      systemMetrics: {
        name: 'Metrics',
        path: '/metrics',
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
          CustomerRole.BILLING,
          CustomerRole.BILLING_ADMIN,
          CustomerRole.LAB,
          CustomerRole.PHARMACIST,
        ],
      },
    },
  },
  [AppScenes.PATIENTS]: {
    name: 'Patients',
    path: '/patients',
    routes: {
      allPatients: {
        name: 'Patient List',
        path: '/patients',
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      waitingPatients: {
        name: 'Waiting Patient',
        path: '/patients/waiting',
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      newPatient: {
        name: 'Patient Registration',
        path: '/patients/new',
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.REGISTRAR,
          CustomerRole.DOCTOR,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      profile: {
        name: 'Patient profile',
        path: '/patients/:patientId',
        buildPath: (id: string) => `/patients/${id}`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      registration: {
        name: 'Patient registration',
        path: '/patients/:patientId/registration',
        buildPath: (id: string) => `/patients/${id}/registration`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      queue: {
        name: 'Patient queue',
        path: '/patients/queue',
        roles: AllRoles,
      },
      history: {
        name: 'Patient History',
        path: '/patients/:patientId/history',
        buildPath: (id: string) => `/patients/${id}/history`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
        ],
      },
      nursing: {
        name: 'Nursing',
        path: '/patients/:patientId/nursing',
        buildPath: (id: string) => `/patients/${id}/nursing`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
        ],
      },
      notes: {
        name: 'Notes',
        path: '/patients/:patientId/notes',
        buildPath: (id: string) => `/patients/${id}/notes`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
        ],
      },
      fillNote: {
        name: 'Fill note',
        path: '/patients/:patientId/notes/:noteId',
        buildPath: (patientId: string, noteId: string) => `/patients/${patientId}/notes/${noteId}`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
        ],
      },
      exam: {
        name: 'Exam',
        path: '/patients/:patientId/exam',
        buildPath: (id: string) => `/patients/${id}/exam`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.NURSE,
        ],
      },
      orders: {
        name: 'Orders',
        path: '/patients/:patientId/orders',
        buildPath: (id: string) => `/patients/${id}/orders`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.NURSE,
        ],
      },
      results: {
        name: 'Results',
        path: '/patients/:patientId/results',
        buildPath: (id: string) => `/patients/${id}/results`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.SYSTEM_ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.PHARMACIST,
          CustomerRole.DOCTOR,
          CustomerRole.NURSE,
        ],
      },
    },
  },
  [AppScenes.PHARMACY]: {
    name: 'Pharmacy',
    path: '/pharmacy',
    routes: {
      items: {
        name: 'Pharmacy Items',
        path: '/pharmacy/items',
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.PHARMACIST,
          CustomerRole.PHARMACIST_ADMIN,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      pharmacyStock: {
        name: 'Stock',
        path: '/pharmacy/items/:id/stock',
        buildPath: id => `/pharmacy/items/${id}/stock`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.PHARMACIST,
          CustomerRole.PHARMACIST_ADMIN,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      pharmacyItem: {
        name: 'Pharmacy Item',
        path: '/pharmacy/items/:id/:tabType(stock)?',
        buildPath: (id, bpjs?: boolean) => `/pharmacy/items/${id}${bpjs ? '?bpjs=true' : ''}`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.PHARMACIST,
          CustomerRole.PHARMACIST_ADMIN,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      prescriptions: {
        name: 'Prescriptions',
        path: '/pharmacy/prescriptions',
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.PHARMACIST,
          CustomerRole.PHARMACIST_ADMIN,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      fillPrescription: {
        name: 'Fill prescription',
        path: '/pharmacy/prescriptions/:visitId/fill',
        buildPath: (visitId, origin) => `/pharmacy/prescriptions/${visitId}/fill?origin=${origin}`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.PHARMACIST,
          CustomerRole.PHARMACIST_ADMIN,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      viewPrescription: {
        name: 'View prescription',
        path: '/pharmacy/prescriptions/:visitId',
        buildPath: (visitId, origin) => `/pharmacy/prescriptions/${visitId}?origin=${origin}`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.PHARMACIST,
          CustomerRole.PHARMACIST_ADMIN,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
    },
  },
  [AppScenes.PREFERENCES]: {
    path: '/preferences',
    name: 'Preferences',
    // no access restriction
    routes: {
      userPreferences: {
        name: 'User preferences',
        path: '/preferences',
      },
    },
  },
  [AppScenes.PRICING]: {
    name: 'Pricing',
    path: '/pricing',
    roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN],
    routes: {
      pricelists: {
        name: 'Payment Types',
        path: '/pricing/pricelists',
      },
      pricelistEditor: {
        name: 'Payment Type Editor',
        path: '/pricing/pricelists/:pricelistId',
        buildPath: (id: string) => `/pricing/pricelists/${id}`,
      },
      priceEditor: {
        name: 'Price Editor',
        path: '/pricing/prices',
        buildPath: (q?: string, category?: string) => {
          const queryParams = new URLSearchParams();

          if (q) {
            queryParams.set('q', q);
          }

          if (category) {
            queryParams.set('category', category);
          }

          return `/pricing/prices${q || category ? `?${queryParams.toString()}` : ''}`;
        },
      },
    },
  },
  [AppScenes.PROCEDURES]: {
    name: 'Procedures',
    path: '/procedures',
    roles: [GlobalRole.ADMIN],
    routes: {
      templates: {
        name: 'Study Template List',
        path: '/procedures/list',
        roles: [GlobalRole.ADMIN],
      },
      newTemplate: {
        name: 'New Study Template',
        path: '/procedures/new',
        roles: [GlobalRole.ADMIN],
      },
      templateEditor: {
        name: 'Study Template Editor',
        path: '/procedures/:templateId',
        buildPath: (id: string) => `/procedures/${id}`,
        roles: [GlobalRole.ADMIN],
      },
    },
  },
  [AppScenes.QUICKPICKS]: {
    name: 'QuickPicks',
    path: '/quickpicks',
    roles: [
      GlobalRole.ADMIN,
      CustomerRole.SYSTEM_ADMIN,
      CustomerRole.LOCAL_ADMIN,
      CustomerRole.DOCTOR,
      CustomerRole.NURSE,
    ],
    routes: {
      list: {
        name: 'Quickpick List',
        path: '/quickpicks',
      },
      newQuickpick: {
        name: 'New Quickpick',
        path: '/quickpicks/new',
      },
      quickpickEditor: {
        name: 'Quickpick Editor',
        path: '/quickpicks/:quickpickId',
        buildPath: (id: string) => `/quickpicks/${id}`,
      },
    },
  },
  [AppScenes.REPORTS]: {
    name: 'Reports',
    path: '/reports',
    routes: {
      commodities: {
        name: 'Commodities',
        path: '/reports/commodities',
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN],
      },
      diseases: {
        name: 'Diseases',
        path: '/reports/diseases',
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
      expenses: {
        name: 'Expenses',
        path: '/reports/expenses',
        roles: [GlobalRole.ADMIN, CustomerRole.SYSTEM_ADMIN, CustomerRole.LOCAL_ADMIN],
      },
      outpatientRevenue: {
        name: 'Outpatients revenue',
        path: '/reports/outpatient-revenue',
        roles: [GlobalRole.ADMIN, CustomerRole.BILLING, CustomerRole.LOCAL_ADMIN, CustomerRole.SYSTEM_ADMIN],
      },
      pastVisits: {
        name: 'Past visits',
        path: '/reports/past-visits',
        roles: [GlobalRole.ADMIN, CustomerRole.BILLING, CustomerRole.LOCAL_ADMIN, CustomerRole.SYSTEM_ADMIN],
      },
    },
  },
  [AppScenes.SCHEDULER]: {
    name: 'Scheduled Visits',
    path: '/scheduler',
    routes: {
      calendar: {
        name: 'Calendar',
        path: '/scheduler/calendar',
        buildPath: (patientId: string) => `/scheduler/calendar?patient=${patientId}`,
        roles: [
          GlobalRole.ADMIN,
          CustomerRole.DOCTOR,
          CustomerRole.LOCAL_ADMIN,
          CustomerRole.NURSE,
          CustomerRole.REGISTRAR,
          CustomerRole.SYSTEM_ADMIN,
        ],
      },
    },
  },
  [AppScenes.SETUP]: {
    name: 'Setup',
    path: '/setup',
    routes: {
      checkout: {
        path: '/setup/checkout',
        name: 'Payment checkout',
      },
      healthSystem: {
        path: '/setup/health-system',
        name: 'Health System',
      },
      location: {
        path: '/setup/health-system/:systemId/location',
        buildPath: (id: string) => `/setup/health-system/${id}/location`,
        name: 'Facility',
      },
    },
  },
  [AppScenes.NOTES]: {
    path: '/notes',
    name: 'notes',
    roles: [GlobalRole.ADMIN],
    routes: {
      list: {
        name: 'Notes list',
        path: '/notes',
      },
      editor: {
        name: 'Edit Note',
        path: '/notes/:noteId',
        buildPath: (id: string) => `/notes/${id}`,
      },
    },
  },
  [AppScenes.TELEHEALTH]: {
    path: '/telehealth',
    name: 'Telehealth',
    roles: [
      GlobalRole.ADMIN,
      CustomerRole.SYSTEM_ADMIN,
      CustomerRole.LOCAL_ADMIN,
      CustomerRole.DOCTOR,
      CustomerRole.NURSE,
    ],
    routes: {
      videoCall: {
        name: 'Video Call',
        path: '/telehealth/videoCall/:patientId/:visitId',
        buildPath: (id: string, visitId: string) => `/telehealth/videoCall/${id}/${visitId}`,
      },
    },
  },
  [AppScenes.TRANSLATION]: {
    name: 'Translations',
    path: '/translation',
    roles: [GlobalRole.ADMIN, GlobalRole.TRANSLATOR],
    routes: {
      list: {
        name: 'Translations list',
        path: '',
        roles: [GlobalRole.ADMIN, GlobalRole.TRANSLATOR],
      },
      editor: {
        name: 'Translations',
        path: '/translation',
      },
    },
  },
  [AppScenes.UNAUTH]: {
    path: '/unauth',
    name: 'Unauth scene',
    routes: {
      forgotPassword: {
        path: '/unauth/forgot-password',
        name: 'Forgot password',
      },
      login: {
        path: '/unauth/login',
        name: 'Log In',
      },
      mfa: {
        path: '/unauth/multi-factor-authentication',
        name: 'Multi-factor Authentication',
      },
      passwordRestore: {
        path: '/unauth/password-restore',
        name: 'Password restore',
      },
      signup: {
        path: '/unauth/signup',
        name: 'Sign Up',
      },
      signUpConfirm: {
        path: '/unauth/signup-confirm',
        name: 'Sign up confirm',
      },
      patientVideoCall: {
        path: '/unauth/patient-video-call',
        name: 'Sign up confirm',
      },
    },
  },
  [AppScenes.VISITS]: {
    name: 'Visits',
    path: '/visits',
    roles: [
      GlobalRole.ADMIN,
      CustomerRole.DOCTOR,
      CustomerRole.LOCAL_ADMIN,
      CustomerRole.NURSE,
      CustomerRole.REGISTRAR,
      CustomerRole.SYSTEM_ADMIN,
    ],
    routes: {
      pastVisits: {
        name: 'Past Visits',
        path: '/visits/past',
        buildPath: (queryParams: string) => `/visits/past?${queryParams}`,
      },
    },
  },
  [AppScenes.CONTENT_LIBRARIES]: {
    name: 'Content Libraries',
    path: '/content-libraries',
    roles: [GlobalRole.ADMIN],
    routes: {
      pharmacyItems: {
        name: 'Shared Pharmacy Items',
        path: '/content-libraries/pharmacyItems',
      },
      pharmacyItem: {
        name: 'Shared Pharmacy Item',
        path: '/content-libraries/pharmacyItems/:pharmacyItemId',
        buildPath: (pharmacyItemId: string) => `/content-libraries/pharmacyItems/${pharmacyItemId}`,
      },
    },
  },
};

export const getRolesByScene = (sceneName: AppScenes): Array<Roles> => {
  const scene = (sitemap as Record<AppScenes, Scene>)[sceneName];
  if (Array.isArray(scene.roles)) return scene.roles;

  const roles = new Set<Roles>();
  Object.values(scene.routes || {}).forEach(route => {
    if (Array.isArray(route.roles)) {
      route.roles.forEach(role => roles.add(role));
    }
  });

  return Array.from(roles);
};

export const getRolesByRoute = (sceneName: AppScenes, routeName: string): Array<Roles> => {
  const scene = (sitemap as Record<AppScenes, Scene>)[sceneName];
  const route = scene.routes[routeName];

  if (typeof route !== 'object') throw new Error('Invalid route');

  if (Array.isArray(route.roles)) return route.roles;

  /**
   * if there are no specific permissions, we can use the
   * ones from the scene
   */
  if (Array.isArray(scene.roles)) return scene.roles;

  throw new Error('There are no permissions set for this route');
};
