export const extractCodeFromGraphQLError = (error) => ((error || {}).extensions || {}).code || ''

const networkErrorHasErrors = networkError =>
  networkError &&
  networkError.result &&
  networkError.result.errors

/**
 *  This function receives an ApolloErorr object and a formatter.
 *  a formatter expects an error code that will be used as a key for i18n support,
 *  it should return the matched code message.
 *
 * It returns an array of formatted errors with {id, message}
 */
export const graphQLErrorFormatter = ({ graphQLErrors, networkError }, formatter) => {
  const errorExtractor = error => formatter(extractCodeFromGraphQLError(error))

  let translatedErrors = (graphQLErrors || []).map(errorExtractor)

  if (networkErrorHasErrors(networkError)) {
    const networkErrors = networkError.result.errors.map(errorExtractor)
    translatedErrors = [...translatedErrors, ...networkErrors]
  }

  return translatedErrors
}
