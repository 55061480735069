import React, { useState, useEffect, useRef } from 'react';
import { WordTokenizer } from 'wd-common/src/vendor/natural/tokenizers/regexp_tokenizer';
import useKeyEvent from '../hooks/useKeyEvent';
import useDebounce from '../hooks/useDebounce';
import { LoadingInline } from '.';

const debug = require('debug')('wd:ResourceSearcher');

const ResourceSearcher = props => {
  const { placeHolder, onSearch, onLeave, isSearching, debounceTime = 500, initialFocus, q, className } = props;

  // to give the initial focus
  const inputRef = useRef(null);
  const [value, setValue] = useState(q || '');
  const debouncedValue = useDebounce(value, debounceTime);
  const searchWords = new WordTokenizer().tokenize(value);

  debug(searchWords);

  // change the input value
  const onChange = event => {
    setValue(event.target.value);
  };

  // trigger search method when the user stops typing
  useEffect(() => {
    if (typeof onSearch === 'function') onSearch(debouncedValue);
  }, [debouncedValue, onSearch]);

  // initial focus
  useEffect(() => {
    if (initialFocus && inputRef && inputRef.current) inputRef.current.focus();
  }, [inputRef, initialFocus]);

  // sync the input with the props
  useEffect(() => {
    if (q) setValue(prev => (prev !== q ? q : prev));
  }, [q]);

  // keydown event
  useKeyEvent('keydown', {
    Enter: onLeave,
  });
  debug('isSearching', isSearching);
  return (
    <div className={`input-group mb-3 ${className} position-relative`}>
      <input
        value={value}
        onChange={onChange}
        ref={inputRef}
        type='text'
        className='form-control'
        placeholder={placeHolder}
        aria-label={placeHolder}
        aria-describedby='loading-suffix'
      />
      {isSearching && (
        <div
          className='input-group-append position-absolute'
          style={{ top: '50%', transform: 'translateY(-50%)', right: '.5em' }}
        >
          <LoadingInline id='loading-suffix' />
        </div>
      )}
    </div>
  );
};
ResourceSearcher.defaultProps = {
  placeHolder: 'Search',
  isSearching: false,
  initialFocus: true,
  className: '',
  onSearch: null,
  onLeave: null,
};
export default ResourceSearcher;
