import { useProcedures, UseProceduresParams } from './useProcedures';
import { useMySystemSettings } from 'components-ts/HealthSystems';

export const useProcedureSubscriptions = (params?: UseProceduresParams) => {
  const { subscriptions } = useMySystemSettings();

  const ids = subscriptions.procedures.items;
  const updated = subscriptions.procedures.updated;

  const { isLoading, procedures, count, error } = useProcedures({ ...params, ids });

  return {
    isLoading,
    count,
    procedures,
    updated,
    error,
  };
};
