import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import {
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap'
import { STUDY_RESULT_TYPE } from 'wd-common/src/logic/lab/definitions'
import Select from 'react-select'
import {
  useStudyItemsActions,
  getOutcomeFromNumber,
  normalizeDataByType,
  checkForRange
} from './useStudyItemsActions'
import { useDebounceExececute } from 'hooks/useDebounce'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { FaExclamationTriangle as WarningIcon } from 'react-icons/fa'
import { DynamicTranslation } from 'components/DynamicTranslation'
import ErrorViewer from 'components/ErrorViewer'

const messages = defineMessages({
  expectedValues: {
    id: 'StudyItemEditor.expected_values',
    defaultMessage: 'Expected values between'
  },
  invalidNumericalValue: {
    id: 'StudyItemEditor.invalid_numerical_value',
    defaultMessage: 'The value is not inside the boundaries'
  },
  invalidIntervalNumericalValue: {
    id: 'StudyItemEditor.invalid_interval_numerical_value',
    defaultMessage: 'The value is not inside any of the boundaries'
  }
})

const StudyItemEditor = (props) => {
  const { item, studyId, readOnly } = props
  const { getMutationByType, error } = useStudyItemsActions({
    studyId
  })
  const { execute } = useDebounceExececute(300)

  const onSubmit = (data) => {
    const update = getMutationByType(item.type)
    const normalizedData = normalizeDataByType(item, data)
    execute(() => update(normalizedData))
  }

  return <div>
    <StudyItem readOnly={readOnly} onSubmit={onSubmit} item={item} />
    {error && <ErrorViewer error={error} className='mt-2' />}
  </div>
}

const StudyItem = (props) => {
  const { item, onSubmit, readOnly } = props
  const { type } = item

  switch (type) {
    case STUDY_RESULT_TYPE.NUMERICAL:
      return (
        <NumericalItem readOnly={readOnly} item={item} onSubmit={onSubmit} />
      )

    case STUDY_RESULT_TYPE.CATEGORICAL:
      return (
        <CategoricalItemInput
          readOnly={readOnly}
          item={item}
          onSubmit={onSubmit}
        />
      )

    case STUDY_RESULT_TYPE.CATEGORICAL_BASED_ON_NUMBER:
      return (
        <CategoricalNumberItemInput
          readOnly={readOnly}
          item={item}
          onSubmit={onSubmit}
        />
      )

    default:
      return <div>invalid</div>
  }
}

const NumericalItem = injectIntl((props) => {
  const { onSubmit, item, readOnly } = props
  const { minimum, maximum } = item.result.reference
  const {
    unit,
    result: { value: itemValue }
  } = item

  const { number: value } = itemValue || {}

  const warning =
    !value || value === ''
      ? false
      : !checkForRange({ minimum, maximum }, value)

  return (
    <div>
      <DynamicTranslation text={(item.name || {}).text} />
      <div>
        <InputGroup className='mb-1 rounded'>
          <Input
            readOnly={readOnly}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck='false'
            type='number'
            defaultValue={value}
            onChange={(e) => {
              const { value } = e.target
              onSubmit({
                number: value
              })
            }}
          />
          {
            (unit || {}).text &&
            <InputGroupAddon addonType='append'>
              <InputGroupText>
                <DynamicTranslation text={unit.text} />
              </InputGroupText>
            </InputGroupAddon>
          }
        </InputGroup>
      </div>
      <NumericalReference
        name={item.id}
        maximum={maximum}
        minimum={minimum}
        warning={warning}
      />
    </div>
  )
})

const CategoricalItemInput = injectIntl((props) => {
  const { onSubmit, item, readOnly } = props

  const formatOption = option => {
    if (!option) return ''

    return {
      value: option.text,
      label: <DynamicTranslation text={option.text} />
    }
  }
  const options = ((item.result.reference || {}).possibleOutcomes || [])
    .map(formatOption)

  const value = (item.result.value || {}).outcome

  return (
    <div>
      <DynamicTranslation text={(item.name || {}).text} />
      <Select
        defaultValue={formatOption(value)}
        options={options}
        isDisabled={readOnly}
        onChange={(event) => {
          onSubmit({
            outcome: event.value
          })
        }}
      />
    </div>
  )
})

const CategoricalNumberItemInput = injectIntl((props) => {
  const { onSubmit, item, readOnly } = props
  const { result, unit } = item
  const { reference, value: itemValue } = result
  const { possibleOutcomesWithRange } = reference
  const { number: value } = itemValue || {}

  const [error, setError] = React.useState()
  const wantedOutcome = (value) => {
    if (value === '') return value

    return getOutcomeFromNumber(item, value)
  }

  const onChange = (e) => {
    const { value } = e.target
    const outcome = getOutcomeFromNumber(item, value)
    if (value) {
      if (outcome) {
        setError(false)
        onSubmit({
          number: value
        })
      } else {
        setError(true)
      }
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <span>
          <DynamicTranslation text={(item.name || {}).text} />
        </span>
        <span>
          <DynamicTranslation text={(wantedOutcome(value) || {})} />
        </span>
      </div>
      <div className='mb-1'>
        <InputGroup className='mb-1 rounded'>
          <Input
            readOnly={readOnly}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck='false'
            type='number'
            defaultValue={value}
            invalid={!!error}
            onChange={onChange}
          />
          {
            (unit || {}).text &&
            <InputGroupAddon addonType='append'>
              <InputGroupText>
                <DynamicTranslation text={unit.text} />
              </InputGroupText>
            </InputGroupAddon>
          }
        </InputGroup>

      </div>
      <CategoricalNumericalReference
        name={item.id}
        possibleOutcomesWithRange={possibleOutcomesWithRange}
        warning={!!error}
      />
    </div>
  )
})

const CategoricalNumericalReference = (props) => {
  const { possibleOutcomesWithRange, warning } = props

  return (
    <div className='d-flex flex-wrap'>
      {possibleOutcomesWithRange.map((posibleOutcome) => {
        const { outcome, range } = posibleOutcome
        return (
          <FormText className='mr-2' key={(outcome || {}).text}>
            <DynamicTranslation text={(outcome || {}).text} /> - [{range.minimum}, {range.maximum}]
          </FormText>
        )
      })}
      {warning && <WarningIcon className='mt-1 text-danger' />}
    </div>
  )
}

const NumericalReference = injectIntl((props) => {
  const { intl, maximum, minimum, warning } = props
  const { formatMessage } = intl
  return (
    <div className='d-flex align-items-center'>
      <FormText className='m-0'>
        <T id={messages.expectedValues.id}>
          {formatMessage(messages.expectedValues)}
        </T>
        {` ${minimum} - ${maximum}`}{' '}
      </FormText>
      {warning && <WarningIcon className='ml-2 text-danger' />}
    </div>
  )
})

export default injectIntl(StudyItemEditor)
