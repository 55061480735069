import { gql } from '@apollo/client';
import { UserSignature } from './common';

export const FullPricelist = gql`
  fragment FullPricelist on Pricelist {
    id
    name
    description
    currency
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;
