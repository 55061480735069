import React from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { useSession } from 'components-ts/Auth';
import { Badge, DropdownToggle, DropdownMenu, DropdownItem, Nav, UncontrolledDropdown } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { sitemap } from 'components-ts/Routing/sitemap';
import { AppHeader, AppSidebarToggler } from '@coreui/react';
import wdLogo from '../../img/wdlogo.png';
import { FaSignOutAlt as LogoutIcon, FaUserAlt as UserIcon, FaCircle as NotificationIcon } from 'react-icons/fa';
import { HelpReferences } from 'components-ts/Help';
import { SubscriptionStatus } from 'api/interfaces';

const messages = defineMessages({
  account: {
    id: 'Router.account',
    defaultMessage: 'Account',
  },
  logOut: {
    id: 'Router.logout',
    defaultMessage: 'Logout',
  },
  pastDue: {
    id: 'SubscriptionStatus.past_due',
    defaultMessage: 'Past due',
  },
});

export const Header: React.VFC = () => {
  const intl = useIntl();

  const { isLoggedIn, logOut, getUserFamilyName, getUserGivenName, getSubscriptionStatus } = useSession();

  const subscriptionStatus = getSubscriptionStatus();

  const loggedIn = isLoggedIn();

  return (
    <AppHeader fixed className="d-flex justify-content-between">
      {loggedIn && <AppSidebarToggler className="d-lg-none" display="md" mobile />}
      {loggedIn && <AppSidebarToggler className="d-md-down-none" display="lg" />}
      {loggedIn && <HelpReferences />}
      <img
        src={wdLogo}
        width="auto"
        height="42px"
        alt="Walking Doctors logo"
        style={{
          position: 'absolute',
          right: 0,
          left: 0,
          margin: 'auto',
        }}
      />
      {loggedIn && (
        <Nav className="ml-auto mr-3 pointer" navbar>
          <UncontrolledDropdown setActiveFromChild>
            <DropdownToggle tag="a" className="nav-link text-capitalize d-flex align-items-center" caret>
              {subscriptionStatus === SubscriptionStatus.PAST_DUE && (
                <sup className="mr-1">
                  <NotificationIcon className="text-warning" />
                </sup>
              )}
              {`${getUserGivenName()} ${getUserFamilyName()[0]}`}
            </DropdownToggle>
            <DropdownMenu>
              <Link to={sitemap.preferences.path}>
                <DropdownItem>
                  <UserIcon className="mr-2" />

                  <T id={messages.account.id}>{intl.formatMessage(messages.account)}</T>

                  {subscriptionStatus === SubscriptionStatus.PAST_DUE && (
                    <Badge className="mr-1" color="warning">
                      {intl.formatMessage(messages.pastDue)}
                    </Badge>
                  )}
                </DropdownItem>
              </Link>
              <DropdownItem onClick={logOut}>
                <LogoutIcon className="mr-2" />
                <T id={messages.logOut.id}>{intl.formatMessage(messages.logOut)}</T>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      )}
    </AppHeader>
  );
};
