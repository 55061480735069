import React from 'react';
import { FieldInputProps } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import Creatable from 'react-select/creatable';
import { FormText } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { DoseUnit } from './DoseUnit';

const messages = defineMessages({
  help_dosing_unit: {
    id: 'PharmacyItem.help_unit',
    defaultMessage: 'The smallest unit a pharmacist prescribes, e.g. tablet, capsule, cc, teaspon, bag, etc.',
  },
  no_units_found: {
    id: 'DoseUnitSelector.no_units_found',
    defaultMessage: 'No units found',
  },
  placeholder: {
    id: 'DoseUnitSelector.placeholder',
    defaultMessage: 'Dose Unit',
  },
});

type DoseUnitSelectorProps = FieldInputProps<any, HTMLElement> & {
  doseUnits: Set<string>;
  loading?: boolean;
  isDisabled?: boolean;
};

export const DoseUnitSelector: React.FC<DoseUnitSelectorProps> = (props) => {
  const { doseUnits, loading, isDisabled, ...input } = props;

  const intl = useIntl();

  const options = React.useMemo(
    () => Array.from(doseUnits.values()).map((v) => ({ value: v, label: <DoseUnit doseUnit={v} /> })),
    [doseUnits]
  );

  return (
    <>
      <Creatable
        {...input}
        placeholder={intl.formatMessage(messages.placeholder)}
        // format the options
        options={options}
        components={{
          NoOptionsMessage: () => (
            <p className="text-center pt-3">
              <T id={messages.no_units_found.id}>{intl.formatMessage(messages.no_units_found)}</T>
            </p>
          ),
        }}
        blurInputOnSelect
        isSearchable
        isClearable
        // react-select uses {value: string, label: string} as value
        // react-final-form uses traditional string only
        // so we need to handle this with some hacky moves
        onChange={(option) => input.onChange(option ? option.value : '')}
        value={options ? options.find((option) => option.value === input.value) : ''}
        isDisabled={loading || isDisabled}
      />
      <FormText className="text-muted">
        <T id={messages.help_dosing_unit.id}>{intl.formatMessage(messages.help_dosing_unit)}</T>
      </FormText>
    </>
  );
};
