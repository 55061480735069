import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const StackedChartPortion = (props) => {
  const { id, percentage, text, tooltipText = '', backgroundColor, showLabels, showLegendsOnHover } = props

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  const containerStyle = {
    backgroundColor: backgroundColor,
    color: 'white',
    width: percentage + '%',
    height: 40 + 'px'
  }

  const t = {
    class: 'bg-success',
    placement: 'top',
    isOpen: tooltipOpen,
    target: id,
    toggle: toggle,
    text: tooltipText
  }

  return <div id={id} className='d-flex align-items-center justify-content-center' style={containerStyle}>
    { showLabels
      ? <span style={{ fontSize: 14 + 'px', cursor: 'default' }}>
        {text}
      </span>
      : null }
    { showLegendsOnHover
      ? <Tooltip className={t.class} placement={t.placement} isOpen={t.isOpen} target={t.target} toggle={t.toggle}>
        {t.text}
      </Tooltip>
      : null }
  </div>
}

const StackedBarChart = (props) => {
  const { chartName, items, showLabels, showLegendsOnHover } = props

  return <div id={`${chartName}-chart`} className='d-flex'>
    { items.map((item, index) => {
      return <StackedChartPortion
        key={`${index}`}
        id={`${chartName}-${index}`}
        percentage={item.percentage}
        text={item.text}
        tooltipText={item.tooltipText}
        backgroundColor={item.backgroundColor}
        showLabels={showLabels}
        showLegendsOnHover={showLegendsOnHover}
      />
    })}
  </div>
}

export default StackedBarChart
