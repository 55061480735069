import React from 'react'
import { useSystems } from 'components-ts/HealthSystems'
import { Address } from '.'
import { FaPhone as PhoneIcon, FaHospital as LocationIcon } from 'react-icons/fa'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'

// static translations
const messages = defineMessages({
  clinicName: {
    id: 'ClinicInfo.clinic_name_label',
    defaultMessage: 'Clinic: '
  },
  phoneNumber: {
    id: 'ClinicInfo.phone_number_label',
    defaultMessage: 'Phone: '
  }
})

const ClinicInfo = (props) => {
  // props
  const { systemId, locationId, withIcon, className, intl } = props
  const { formatMessage } = intl

  // validate props
  if (!locationId) throw new Error('Invalid system')
  if (!locationId) throw new Error('Invalid location')

  // hooks
  const { getSystem, getLocation } = useSystems()

  const system = getSystem(systemId)
  const location = getLocation(system.id, locationId)

  const { country } = system
  const { name, phoneNumber, address } = location

  const splitAddress = (address || '').split('\n')
  const addressInfo = {
    country: country,
    line_1: splitAddress.length > 0 ? splitAddress[0] : null,
    line_2: splitAddress.length > 1 ? splitAddress[1] : null,
    line_3: splitAddress.length > 2 ? splitAddress[2] : null
  }

  return (
    <div className={className || ''}>
      <div className='d-flex'>
        {withIcon ? (
          <LocationIcon style={{ margin: '.2em 0' }} className='text-muted' />
        ) : (
          <T id={messages.clinicName.id}>{formatMessage(messages.clinicName)}</T>
        )}
        <b className='ml-2'>{name}</b>
      </div>
      <Address address={addressInfo} withIcon={withIcon} />
      {phoneNumber && (
        <div className='d-flex'>
          {withIcon ? (
            <PhoneIcon style={{ margin: '.2em 0' }} className='text-muted' />
          ) : (
            <T id={messages.phoneNumber.id}>{formatMessage(messages.phoneNumber)}</T>
          )}
          <span className='ml-2'>{phoneNumber}</span>
        </div>
      )}
    </div>
  )
}

export default injectIntl(ClinicInfo)
