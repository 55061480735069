import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useSubscribeToChecklistBatch } from 'components-ts/HealthSystems/useChecklistTagsSubscriptions';
import { ItemSubscriptions } from 'api/interfaces';
import { Badge, Button, Modal, ModalBody, ModalHeader, Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { useDisclosure, LastUpdated, LoadingInline } from 'components-ts';
import { useTranslatedChecklists } from './useTranslatedChecklists';
import { IdTagTranslatedSubscriptionForm } from './IdTagTranslatedSubscriptionForm';
import { useSubscribeToTriageChecklistsBatch } from './useTriageActions';

const messages = defineMessages({
  title: {
    id: 'ChecklistsTab.label_checklists_subscriptions',
    defaultMessage: 'Checklists',
  },
  description: {
    id: 'ChecklistsTab.choose_the_checklists_you_want_to_show_in_your_system',
    defaultMessage: 'Library of clinical checklists available in your facility',
  },
  noSubscriptions: {
    id: 'UI.label_no_subscriptions',
    defaultMessage: 'No Subscriptions',
  },
  subscribedLabel: {
    id: 'UI.label_subscribed',
    defaultMessage: 'Subscribed ({count})',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  triage: {
    id: 'ActiveVisitNavBar.triage_tab',
    defaultMessage: 'Triage',
  },
  exam: {
    id: 'ActiveVisitNavBar.exam_tab',
    defaultMessage: 'Exam',
  },
});

interface ChecklistsSubscriptionSectionProps {
  systemId: string;
  locationId: string;
  exam: ItemSubscriptions;
  triage: ItemSubscriptions;
  className?: string;
  isDisabled?: boolean;
}

enum Tabs {
  EXAM = 'exam',
  TRIAGE = 'triage',
}

const calculateActualNumberOfSubscriptions = (exam: ItemSubscriptions, triage: ItemSubscriptions) => {
  const examCount = exam.items?.length ?? 0;
  const triageCount = triage.items?.length ?? 0;

  return examCount + triageCount;
};

export const ChecklistsSubscriptionSection: React.FC<ChecklistsSubscriptionSectionProps> = (props) => {
  const { systemId, locationId, exam, triage, className, isDisabled } = props;
  const intl = useIntl();

  const [tab, setTab] = React.useState<Tabs>(Tabs.EXAM);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onTabChange = (tab: Tabs) => {
    setTab(tab);
  };

  const count = calculateActualNumberOfSubscriptions(exam, triage);
  return (
    <div
      onClick={onOpen}
      className={'d-flex align-items-center justify-content-between pointer animated-border-on-hover rounded p-3'}
    >
      <div className="flex-wrap">
        <h6 className="mb-1">
          <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
        </h6>
        <p className="text-muted mb-2">
          <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
        </p>
      </div>

      {count > 0 ? (
        <Badge color="success" className={`no-wrap ${className}`}>
          <T id={messages.subscribedLabel.id}>{intl.formatMessage(messages.subscribedLabel, { count })}</T>
        </Badge>
      ) : (
        <Badge className={`no-wrap ${className}`}>
          <T id={messages.noSubscriptions.id}>{intl.formatMessage(messages.noSubscriptions)}</T>
        </Badge>
      )}

      {isOpen && (
        <Modal className="modal-lg" isOpen={true} toggle={onClose}>
          <ModalHeader toggle={onClose}>
            <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
          </ModalHeader>
          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink onClick={() => onTabChange(Tabs.EXAM)} className={tab === Tabs.EXAM ? 'active' : ''}>
                  {intl.formatMessage(messages.exam)}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => onTabChange(Tabs.TRIAGE)} className={tab === Tabs.TRIAGE ? 'active' : ''}>
                  {intl.formatMessage(messages.triage)}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={Tabs.EXAM}>
                {tab === Tabs.EXAM && (
                  <ExamSubscriptions
                    isDisabled={isDisabled}
                    systemId={systemId}
                    locationId={locationId}
                    subscriptions={exam}
                  />
                )}
              </TabPane>
              <TabPane tabId={Tabs.TRIAGE}>
                {tab === Tabs.TRIAGE && (
                  <TriageSubscriptions
                    isDisabled={isDisabled}
                    systemId={systemId}
                    locationId={locationId}
                    subscriptions={triage}
                  />
                )}
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

type SubscriptionProps = {
  systemId: string;
  locationId: string;
  subscriptions: ItemSubscriptions;
  isDisabled?: boolean;
};

const ExamSubscriptions: React.VFC<SubscriptionProps> = (props) => {
  const { systemId, locationId, subscriptions, isDisabled } = props;

  const intl = useIntl();

  const { isLoading: loadingChecklists, translatedChecklists, error: getTagsError } = useTranslatedChecklists();

  const { onSubmit, error: subscribeOpError, isLoading } = useSubscribeToChecklistBatch({ systemId, locationId });

  const error = getTagsError ?? subscribeOpError;
  return (
    <>
      <IdTagTranslatedSubscriptionForm
        error={error}
        onSubmit={onSubmit}
        isLoading={loadingChecklists}
        items={translatedChecklists}
        initialValues={subscriptions.items}
      >
        <div className="d-flex align-items-center justify-content-between">
          <Button type="submit" color="primary" disabled={isDisabled}>
            <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
            {isLoading && <LoadingInline className="ml-1" />}
          </Button>
          {subscriptions.updated && <LastUpdated signature={subscriptions.updated} />}
        </div>
      </IdTagTranslatedSubscriptionForm>
    </>
  );
};

const TriageSubscriptions: React.VFC<SubscriptionProps> = (props) => {
  const { systemId, locationId, subscriptions, isDisabled } = props;

  const intl = useIntl();

  const { isLoading: loadingChecklists, translatedChecklists, error: getTagsError } = useTranslatedChecklists();

  const {
    onSubsbribeBatch,
    error: subscribeOpError,
    isLoading,
  } = useSubscribeToTriageChecklistsBatch({ systemId, locationId });

  const error = getTagsError ?? subscribeOpError;
  return (
    <>
      <IdTagTranslatedSubscriptionForm
        error={error}
        onSubmit={onSubsbribeBatch}
        isLoading={loadingChecklists}
        items={translatedChecklists}
        initialValues={subscriptions.items}
      >
        <div className="d-flex align-items-center justify-content-between">
          <Button type="submit" color="primary" disabled={isDisabled}>
            <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
            {isLoading && <LoadingInline className="ml-1" />}
          </Button>
          {subscriptions.updated && <LastUpdated signature={subscriptions.updated} />}
        </div>
      </IdTagTranslatedSubscriptionForm>
    </>
  );
};
