import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Select from 'react-select';
import { EventRecurrence } from '../../api/interfaces';
import { eventRecurrenceTranslations } from './EventRecurrence';
import { SingleSelectorProps, ReactSelectInternalSingleValue, ReactSelectInternalValue } from 'components-ts/Selectors';
import { isValidEventRecurrence } from './utils';
import { Nullable } from 'components-ts/utils';

const messages = defineMessages({
  recurrenceLabel: {
    id: 'RoomEventEditor.recurrence_label',
    defaultMessage: 'Recurrence',
  },
});

export type EventRecurrenceSelectorProps = SingleSelectorProps<EventRecurrence>;

export const EventRecurrenceSelector: React.FC<EventRecurrenceSelectorProps> = (props) => {
  const { value } = props;

  const intl = useIntl();

  const formatOption = (recurrence: EventRecurrence): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(eventRecurrenceTranslations[recurrence]),
      value: recurrence,
    };
  };

  const options = Object.values(EventRecurrence).map(formatOption);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    if (isValidEventRecurrence(value as string)) {
      return formatOption(value as EventRecurrence);
    }

    return null;
  };

  const _onChange = (value) => {
    if (!value) {
      props.onChange('' as EventRecurrence);
      return;
    }

    const selectedValue = value;

    props.onChange(selectedValue.value as EventRecurrence);
  };

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={props.isLoading}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={intl.formatMessage(messages.recurrenceLabel)}
      blurInputOnSelect={props.blurInputOnSelect}
      isMulti={false}
    />
  );
};
