import React from 'react'
import { FaMapMarkerAlt as LocationIcon } from 'react-icons/fa'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { injectIntl, defineMessages } from 'react-intl'
import { countries as countriesList } from 'countries-list' // countries and timezones

// static translations
const messages = defineMessages({
  address: {
    id: 'Address.address_label',
    defaultMessage: 'Adress'
  }
})

const Address = (props) => {
  // props
  const { address, withIcon, withLabel, inline, intl } = props
  const { formatMessage } = intl

  const {
    line_1: l1,
    line_2: l2,
    line_3: l3,
    locality,
    postal_code: postalCode,
    province,
    country
  } = address || {}

  // If country is in a code format lookup for complete name
  const countryName = ((country || '').length === 2) ? countriesList[country].name : country

  return (<div className='d-flex'>
    {withIcon && <LocationIcon style={{ margin: '.2em 0' }} className='text-muted' />}
    {withLabel && <div style={{ marginLeft: '.5em' }}>
      <T id={messages.address.id}>
        {formatMessage(messages.address)}
      </T>
    </div>
    }
    <div className={`ml-2 ${inline && 'd-flex'}`}>
      {(l1 || l2) && <div>
        {l1 && <span>{l1}</span>}{l1 && l2 && <Separator />}
        {l2 && <span>{l2}</span>}
        { inline && <Separator />}
      </div>}

      {(l3 || locality || postalCode) && <div>
        {l3 && <span>{l3}</span>}{l3 && locality && <Separator />}
        {locality && <span>{locality}</span>}{locality && postalCode && <Separator />}
        {postalCode && <span>{postalCode}</span>}
        { inline && <Separator />}
      </div>}

      {(province || countryName) && <div>
        {province && <span>{province}</span>}{province && countryName && <Separator />}
        {countryName && <span>{countryName}</span>}
      </div>}
    </div>
  </div>
  )
}

const Separator = props => {
  return <span className='mr-1'>
    {','}
  </span>
}
export default injectIntl(Address)
