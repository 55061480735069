import React from 'react'
import { useHistory } from 'react-router-dom'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Button } from 'reactstrap'
import { FaAngleLeft as BackIcon } from 'react-icons/fa'

const messages = defineMessages({
  backButton: {
    id: 'UI.button_back',
    defaultMessage: 'Back'
  }
})

/**
 * This button is use to go back using the react router history
 */
const BackButton = (props) => {
  const { formatMessage } = props.intl

  const history = useHistory()
  return <Button className='btn btn-link d-flex align-items-center p-0' color='link' onClick={e => history.goBack()}>
    <BackIcon />
    <T id={messages.backButton.id}>{formatMessage(messages.backButton)}</T>
  </Button>
}

export default injectIntl(BackButton)
