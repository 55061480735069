import React from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_TRANSLATION_BY_ID } from 'scenes/Translations/requests';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { FullTranslation } from 'api/interfaces/translations';

interface TranslationQueryVariables {
  id: string;
}

interface TranslationQueryData {
  translation: FullTranslation;
}

export type UseTranslationParams = QueryHookOptions<TranslationQueryData, TranslationQueryVariables> & {
  id: string;
};

interface ReturnedValue {
  translation: Nullable<FullTranslation>;
  isLoading: boolean;
  error: Nullable<string>;
}

export function useTranslation(params: UseTranslationParams): ReturnedValue {
  const [error, setError] = React.useState<Nullable<string>>(null);

  const onError = (errors) => {
    const errorCode = extractFirstErrorCode(errors);

    if (errorCode === 'translation_not_found') {
      return setError('Translation not found');
    }

    setError('An internal error has ocurred.');
  };

  const { loading: isLoading, data } = useQuery(GET_TRANSLATION_BY_ID, {
    variables: {
      id: params.id,
    },
    ...params,
    onError,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return {
    isLoading,
    translation: data ? data.translation : null,
    error,
  };
}
