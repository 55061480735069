import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { useDisclosure } from 'components-ts/useDisclosure';
import { PharmacyAddStockEventForm } from './PharmacyAddStockEventForm';
import { UpdatePharmacyItemStockInitialValues, useUpdatePharmacyItemStock } from './useUpdatePharmacyStock';
import { PharmacyItemStockEntry } from 'api/interfaces';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { FaPen } from 'react-icons/fa';
import { PharmacyRemoveStockEventForm } from './PharmacyRemoveStockEventForm';

const messages = defineMessages({
  editButtonLabel: {
    id: 'UI.button_edit',
    defaultMessage: 'Edit',
  },
  modalTitle: {
    id: 'EditStockModal.title',
    defaultMessage: 'Edit Stock',
  },
  deleteMessage: {
    id: 'UI.delete_item_message',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
  deleteButton: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  addStockLabel: {
    id: 'label_add_stock',
    defaultMessage: 'Add Stock',
  },
  removeStockLabel: {
    id: 'label_remove_stock',
    defaultMessage: 'Remove Stock',
  },
});

type EditStockModalProps = {
  pharmacyStock: PharmacyItemStockEntry;
  onToggle: () => void;
  onCompleted?: () => void;
};

export const EditStockModal: React.FC<EditStockModalProps> = (props) => {
  const { onToggle, pharmacyStock, onCompleted } = props;

  const intl = useIntl();

  const { onAddStockSubmit, onRemoveStockSubmit, validate, error, loading } = useUpdatePharmacyItemStock({
    id: pharmacyStock.id,
    item: pharmacyStock.item,
    onCompleted: () => {
      if (onCompleted) onCompleted();
      onToggle();
    },
  });

  const initialValues: UpdatePharmacyItemStockInitialValues = {
    containerCount: pharmacyStock.containerCount ?? undefined,
    containerCapacity: pharmacyStock.containerCapacity ?? undefined,
    container: pharmacyStock.container ?? undefined,
    stock_unit: pharmacyStock.stock_unit ?? undefined,
    amount: Math.abs(pharmacyStock.amount),
    notes: pharmacyStock.notes ?? undefined,
    lotExpirationDate: pharmacyStock.lotExpirationDate ? new Date(pharmacyStock.lotExpirationDate) : undefined,
    lotNumber: pharmacyStock.lotNumber ?? undefined,
  };

  return (
    <Modal isOpen toggle={onToggle} autoFocus={false}>
      <ModalHeader toggle={onToggle}>
        <T id={messages.modalTitle.id}>{intl.formatMessage(messages.modalTitle)}</T>
      </ModalHeader>
      <ModalBody>
        {pharmacyStock.amount >= 0 && (
          <PharmacyAddStockEventForm
            onSubmit={onAddStockSubmit}
            validate={validate}
            initialValues={initialValues}
            error={error}
            loading={loading}
          />
        )}
        {pharmacyStock.amount < 0 && (
          <PharmacyRemoveStockEventForm
            onSubmit={onRemoveStockSubmit}
            validate={validate}
            initialValues={initialValues}
            error={error}
            loading={loading}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

type EditStockModalButtonProps = {
  pharmacyStock: PharmacyItemStockEntry;
  onCompleted?: (...params: any) => any;
};

export const EditStockModalButton: React.FC<EditStockModalButtonProps> = (props) => {
  const { pharmacyStock, onCompleted } = props;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Button color="primary" onClick={onToggle} className="btn btn-ghost-primary ">
        <FaPen />
      </Button>
      {isOpen && <EditStockModal onToggle={onToggle} pharmacyStock={pharmacyStock} onCompleted={onCompleted} />}
    </>
  );
};
