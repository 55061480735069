// button states
export const SUBMIT_BUTTON_STATES = {
  IDLE: 'idle',
  LOADING: 'loading',
  DONE: 'done'
}

// commong types
// Take in account that 'submit' is used on the forms
export const SUBMIT_BUTTON_ACTIONS = {
  CREATE: 'create',
  SAVE: 'save',
  ADD: 'add',
  EDIT: 'edit'
}
