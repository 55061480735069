import React from 'react';
import { FieldInputProps } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import Creatable from 'react-select/creatable';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { FormText } from 'reactstrap';

const messages = defineMessages({
  no_categories_found: {
    id: 'PharmacyItemCategorySelector.no_categories_found',
    defaultMessage: 'No categories found',
  },
  help_category: {
    id: 'PharmacyItem.help_tag',
    defaultMessage: 'Use category to help you group items e.g. BPJS Approved, Restricted, etc.',
  },
  placeholder: {
    id: 'PharmacyItemCategorySelector.placeholder',
    defaultMessage: 'Category',
  },
});

type PharmacyItemCategorySelectorProps = FieldInputProps<any, HTMLElement> & {
  categories: Set<string>;
  isDisabled?: boolean;
};

export const PharmacyItemCategorySelector: React.FC<PharmacyItemCategorySelectorProps> = (props) => {
  const { categories, isDisabled, ...input } = props;

  const intl = useIntl();
  const options = React.useMemo(
    () => Array.from(categories.values()).map((v) => ({ value: v, label: v })),
    [categories]
  );
  return (
    <>
      <Creatable
        {...input}
        placeholder={intl.formatMessage(messages.placeholder)}
        // format the options
        options={options}
        components={{
          NoOptionsMessage: () => (
            <p className="text-center pt-3">
              <T id={messages.no_categories_found.id}>{intl.formatMessage(messages.no_categories_found)}</T>
            </p>
          ),
        }}
        blurInputOnSelect
        isSearchable
        isClearable
        // react-select uses {value: string, label: string} as value
        // react-final-form uses traditional string only
        // so we need to handle this with some hacky moves
        onChange={(option) => input.onChange(option ? option.value : '')}
        value={options ? options.find((option) => option.value === input.value) : ''}
        isDisabled={isDisabled}
      />
      <FormText className="text-muted">{intl.formatMessage(messages.help_category)}</FormText>
    </>
  );
};
