import React from 'react';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { Modal, ModalBody } from 'reactstrap';
import { useSession } from 'components-ts/Auth';
import { calcDateDiffInDays } from 'components-ts/DateAndTime';
import { useSystems } from 'components-ts/HealthSystems';
import { DrugImportStep } from './DrugImportStep';
import { StorageStep } from './StorageStep';
import { ProceduresStep } from './ProceduresStep';
import { ChecklistsStep } from './ChecklistsStep';
import { useSteps } from './useSteps';
import { LabsStep } from './LabsStep';
import { LoadingInline } from 'components-ts/Loading';

export const FirstSteps: React.FC = (props) => {
  const { getMySystem } = useSystems();

  const { firstStepsDone, created } = getMySystem();
  const { userHasRole } = useSession();

  const hasRole = userHasRole('Admin') || userHasRole('SystemAdmin');
  const isInPeriod = created ? calcDateDiffInDays(new Date(), created.timestamp) <= 7 : false;
  const showModal = !firstStepsDone && isInPeriod && hasRole;

  return (
    <>
      {showModal && <FirstStepsModal />}
      {props.children}
    </>
  );
};

const FirstStepsModal: React.VFC = () => {
  const { isLoading, currentStep, next, prev, stepCount, finishing, errorFinishing } = useSteps();

  const footerProps = {
    current: currentStep,
    next,
    prev,
    count: stepCount,
    finishing,
  };

  return (
    <Modal isOpen={true} size="lg">
      {isLoading ? (
        <ModalBody className="d-flex justify-content-center">
          <LoadingInline />
        </ModalBody>
      ) : (
        <ModalBody className="p-lg-4 p-3">
          {currentStep === 0 && <DrugImportStep footerProps={footerProps} />}
          {currentStep === 1 && <ChecklistsStep footerProps={footerProps} />}
          {currentStep === 2 && <ProceduresStep footerProps={footerProps} />}
          {currentStep === 3 && <LabsStep footerProps={footerProps} />}
          {currentStep === 4 && <StorageStep footerProps={footerProps} />}
          {errorFinishing && <ErrorViewer error={errorFinishing} />}
        </ModalBody>
      )}
    </Modal>
  );
};
