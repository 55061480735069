import React from 'react';
import { BasicPatient, PaginatedResult } from '../../api/interfaces';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_PATIENTS } from 'api/request/patients';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

export interface PatientsVariables {
  q?: string;
  offset?: number;
  limit?: number;
  location: Array<string>;
}

interface PatientsQueryData {
  patients: PaginatedResult<BasicPatient>;
}

export type UsePatientsParams = QueryHookOptions<PatientsQueryData, PatientsVariables> & PatientsVariables;

interface ReturnedValue {
  patients: Array<BasicPatient>;
  count: number;
  isLoading: boolean;
  error: Nullable<MessageDescriptor>;
  getPatient: (patientId: string) => Nullable<BasicPatient>;
  refetch: (newArgs?: PatientsVariables) => void;
  onErrorClose: () => void;
}

export const usePatients = (params: UsePatientsParams): ReturnedValue => {
  const { location, q, offset, limit, ...rest } = params;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const translateError = (code) => {
    switch (code) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors);
    translateError(firstError);

    if (typeof params?.onError === 'function') {
      params.onError(errors);
    }
  };

  const onCompleted = () => {
    setError(null);
  };

  const { loading, data, refetch } = useQuery<PatientsQueryData, PatientsVariables>(GET_PATIENTS, {
    variables: {
      location,
      q,
      offset,
      limit,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    ...rest,
    onError,
    onCompleted,
  });

  const getPatient = React.useCallback(
    (patientId: string): Nullable<BasicPatient> => {
      if (!data?.patients) {
        return null;
      }

      const { docs } = data.patients;
      const found = docs.find((patient) => patient.id === patientId);

      return found ?? null;
    },
    [data]
  );

  const onErrorClose = () => {
    setError(null);
  };

  return {
    isLoading: loading,
    patients: data?.patients.docs ?? [],
    count: data?.patients.count ?? 0,
    error,
    getPatient,
    refetch,
    onErrorClose,
  };
};
