import { gql } from '@apollo/client';

/**
 *  We need to rewrite the fragments.
 *  Sometimes JSON doesn't allow us to reuse them
 *
 **/
export const FullListItem = `
  fragment FullListItem on ListItem {
    type
    key
    listId
    name {
      text
      type
    }
  }
`;

export const UserSignature = `
  fragment UserSignature on Signature {
    family_name
    given_name
    timestamp
    user_id
  }
`;

export const FullList = `
 fragment FullList on List {
    id
    history_codes
    name {
      text
    }
    items {
      ...FullListItem
    }
    updated {
      ...UserSignature
    }
    default {
      ...FullListItem
    }
  }
  ${UserSignature}
  ${FullListItem}
`;

export const FullReference = `
  fragment FullReference on Reference {
    name {
      type
      text
    }
    link
    image
  }
`;

export const FullAddress = `
  fragment FullAddress on Address {
    line_1
    line_2
    line_3
    locality
    postal_code
    province
    country
  }
`;

export const FullEmergencyContact = `
  fragment FullEmergencyContact on EmergencyContact {
    first_name
    last_name
    relationship_to_patient
    phone_number
  }
`;
// Patient
export const FullCustomFieldTextListWithValue = `
  fragment FullCustomFieldTextListWithValue on CustomFieldTextListWithValue {
    customField {
      id
      name
      required
      options
    }
    textList
  }
`;

export const FullPatientBpjsSettings = `
fragment FullPatientBpjsSettings on PatientBpjsSettings {
  cardNumber
}
`;

export const FullPatientIntegrationsSettings = `
fragment FullPatientIntegrationsSettings on PatientIntegrationsSettings {
  bpjs {
    ...FullPatientBpjsSettings
  }
}
${FullPatientBpjsSettings}
`;

export const BasicPatientWithoutId = `
fragment BasicPatientWithoutId on PatientWithoutId {
  patientId
  mr
  first_name
  last_name
  birthdate
  sex
  original_location
  phone_number
  phone_number_e164
  email
  address {
    ...FullAddress
  }
  pricelist
  national_id
  jkn
  clinic_id
  private_insurance_number
  integrations {
    ...FullPatientIntegrationsSettings
  }
}
${FullAddress}
${FullPatientIntegrationsSettings}
`;

export const FullPatientWithoutId = `
  fragment FullPatientWithoutId on PatientWithoutId {
    ...BasicPatientWithoutId
    emergency_contact {
      ...FullEmergencyContact
    }
    customFields {
      ... on CustomFieldTextListWithValue {
        customField {
          id
          name
          required
          options
        }
        textList
      }
      ... on CustomFieldBooleanWithValue {
        customField {
          id
          name
          required
          type
        }
        boolean
      }
      ... on CustomFieldNumberWithValue {
        customField {
          id
          name
          required
          type
        }
        number
      }
      ... on CustomFieldDateWithValue {
        customField {
          id
          name
          required
          type
        }
        date
      }
      ... on CustomFieldTextWithValue {
        customField {
          id
          name
          required
          type
        }
        text
      }
    }
    updated {
      ...UserSignature
    }
    created {
      ...UserSignature
    }
  }
  ${BasicPatientWithoutId}
  ${FullEmergencyContact}
  ${UserSignature}
`;

export const BasicPatient = `
  fragment BasicPatient on Patient {
    id
    mr
    first_name
    last_name
    birthdate
    sex
    original_location
    phone_number
    phone_number_e164
    email
    address {
      ...FullAddress
    }
    pricelist
    national_id
    jkn
    clinic_id
    private_insurance_number
    integrations {
      ...FullPatientIntegrationsSettings
    }
  }
  ${FullAddress}
  ${FullPatientIntegrationsSettings}
  `;

export const FullPatient = `
  fragment FullPatient on Patient {
    ...BasicPatient
    emergency_contact {
      ...FullEmergencyContact
    }
    customFields {
      ... on CustomFieldTextListWithValue {
        customField {
          id
          name
          required
          options
        }
        textList
      }
      ... on CustomFieldBooleanWithValue {
        customField {
          id
          name
          required
          type
        }
        boolean
      }
      ... on CustomFieldNumberWithValue {
        customField {
          id
          name
          required
          type
        }
        number
      }
      ... on CustomFieldDateWithValue {
        customField {
          id
          name
          required
          type
        }
        date
      }
      ... on CustomFieldTextWithValue {
        customField {
          id
          name
          required
          type
        }
        text
      }
    }
    updated {
      ...UserSignature
    }
    created {
      ...UserSignature
    }
  }
  ${BasicPatient}
  ${FullEmergencyContact}
  ${UserSignature}
`;

// vital signs - triage
export const FullMeasurement = `
  fragment FullMeasurement on Measurement {
    ... on BloodPressure {
      id
      type
      systolic
      diastolic
      created {
        ...UserSignature
      }
    }
    ... on Weight {
      id
      type
      kg
      created {
        ...UserSignature
      }
    }
    ... on Height {
      type
      cm
      created {
        ...UserSignature
      }
  }
    ... on Bmi {
      type
      bmi
      created {
        ...UserSignature
      }
    }
    ... on HeadCircumference {
      type
      cm
      created {
        ...UserSignature
      }
    }
    ... on MidUpperArmCircumference {
      type
      cm
      created {
        ...UserSignature
      }
    }
    ... on Temperature {
      type
      degrees_c
      location
      method
      created {
        ...UserSignature
      }
    }
    ... on HeartRate {
      type
      bpm
      created {
        ...UserSignature
      }
    }
    ... on RespiratoryRate {
      type
      breaths_per_minute
      created {
        ...UserSignature
      }
    }
    ... on O2Saturation {
      type
      percent
      created {
        ...UserSignature
      }
    }
  }
  ${UserSignature}
`;

export const FullVitals = `
  fragment FullVitals on Vitals {
    measurements {
      ... on BloodPressure {
        id
        type
        systolic
        diastolic
        updated {
          ...UserSignature
        }
      }
      ... on Weight {
        id
        type
        kg
        updated {
          ...UserSignature
        }
      }
      ... on Height {
        id
        type
        cm
        updated {
          ...UserSignature
        }
    }
      ... on Bmi {
        id
        type
        bmi
        updated {
          ...UserSignature
        }
      }
      ... on HeadCircumference {
        id
        type
        cm
        updated {
          ...UserSignature
        }
      }
      ... on MidUpperArmCircumference {
        id
        type
        cm
        updated {
          ...UserSignature
        }
      }
      ... on Temperature {
        id
        type
        degrees_c
        location
        method
        updated {
          ...UserSignature
        }
      }
      ... on HeartRate {
        id
        type
        bpm
        updated {
          ...UserSignature
        }
      }
      ... on RespiratoryRate {
        id
        type
        breaths_per_minute
        updated {
          ...UserSignature
        }
      }
      ... on O2Saturation {
        id
        type
        percent
        updated {
          ...UserSignature
        }
      }
    }
  }
  ${UserSignature}
`;

export const FullExamWithoutId = `
  ${UserSignature}
  fragment FullExamWithoutId on Exam {
    name {
      text
    }
    sections {
      type
      key
      name {
        text
      }
      note
      groups {
        key
        type
        physical_exam_codes
        defaults
        choices {
          key
          type
          selected
          name{
            text
          }
          details {
            list_id
            key
            selected_items {
              text
            }
            type
            name {
              text
            }
            value {
              text
            }
          }
        }
      }
    }
    updated {
      ...UserSignature
    }
  }
`;

export const FullExam = `
  fragment FullExam on Exam {
    id
    ...FullExamWithoutId
  }
  ${FullExamWithoutId}
`;

export const FullExamWithCount = `
  fragment FullExamWithCount on ExamsWithCount {
    docs {
      ...FullExam
    }
    count
  }
  ${FullExam}
`;

export const FullQualityChecksEquationItems = `
  fragment FullQualityChecksEquationItems on QualityChecksEquationItems{
    type
    itemId
    codes {
      text
    }
  }
`;
/**
 * Quality eq items are a kind of recursive type
 * in Graphql it's not possible to write a recursive query of unlimited depth
 * This GraphQL design pattern allows us to specify a fixed limit on recursion
 * depth by nesting each level of depth in the fragment definition
 * See: https://github.com/graphql/graphql-spec/issues/91 for explanation
 */
export const RecursiveQualityChecksEquationItems = `
  fragment RecursiveQualityChecksEquationItems on QualityChecksEquationItems{
    ...FullQualityChecksEquationItems
    items {
      ...FullQualityChecksEquationItems
      items {
        ...FullQualityChecksEquationItems
        items {
          ...FullQualityChecksEquationItems
          items {
            ...FullQualityChecksEquationItems
            items {
              ...FullQualityChecksEquationItems
              items {
                ...FullQualityChecksEquationItems
                items {
                  ...FullQualityChecksEquationItems
                }
              }
            }
          }
        }
      }
    }
  }
  ${FullQualityChecksEquationItems}
`;

export const FullQualityCheck = `
  fragment FullQualityCheck on QualityChecks{
    name {
      text
    }
    qualityCheckId
    message {
      text
    }
    show_alert
    actions {
      clinical_status
    }
    colors {
      error
      true
    }
    equation{
      type
      itemId
      items {
        ...RecursiveQualityChecksEquationItems
      }
    }
  }
  ${RecursiveQualityChecksEquationItems}
  `;

export const FullSystemDrugGuideline = `
  ${FullReference}
  fragment FullSystemDrugGuideline on SystemDrugGuideline {
    type
    entryId
    pre_text{
      type
      text
    }
    name{
      type
      text
    }
    dose_units
    dose_range_min
    dose_range_max
    doses_per_day
    max_dose
    max_doses
    max_dose_units
    route {
      text
    }
    post_text {
      text
    }
    frequency_show
    as_needed
    history_codes
    physical_exam_codes
    key
    checked
    days
    billing_tags
    quality_codes
    reference {
      ...FullReference
    }
  }
`;

export const FullChecklistEntry = `
  fragment FullChecklistEntry on ChecklistEntry {
    type
    entryId
    name {
      type
      text
    }
    reference {
      ...FullReference
    }
    general_tags {
      text
    }
    billing_tags
    history_codes
    physical_exam_codes
    quality_codes
    checked
    key
  }
  ${FullReference}
`;

export const BasicChecklist = `
  fragment BasicChecklist on Checklist {
    id
    name {
      text
    }
    background {
      text
    }
    category {
      text
    }
    general_tags { 
      text
    }
  }
`;

export const FullChecklist = `
  fragment FullChecklist on Checklist {
    id
    checklistId
    name {
      text
    }
    background {
      text
    }
    differential_diagnosis {
      ...FullChecklistEntry
    }
    history {
      ...FullChecklistEntry
    }
    physical_exam {
      ...FullChecklistEntry
    }
    studies {
      ...FullChecklistEntry
    }
    treatment {
      ... on SystemDrugGuideline {
        ...FullSystemDrugGuideline
      }
      ... on ChecklistEntry {
        ...FullChecklistEntry
      }
    }
    education {
      ...FullChecklistEntry
    }
    category {
      text
    }
    authors
    general_tags {
      text
    }
    quality_checks {
      ...FullQualityCheck
    }
  }
  ${FullChecklistEntry}
  ${FullSystemDrugGuideline}
  ${FullQualityCheck}
`;

export const FullComment = `
  fragment FullComment on Comment {
    id
    type
    text
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const BasicBill = `
  fragment BasicBill on Bill {
    id
    status
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullBillingItem = `
  fragment FullBillingItem on BillingItem {
    id
    name
    amount
    quantity
    unitPrice
    note
    billingCode
    pricelistId
    category
    userAdded
  }
`;

export const FullBill = `
  fragment FullBill on Bill {
    ...BasicBill
    xendit {
      id
      externalId
      url
      status
      paidAt
      updated {
        ...UserSignature
      }
    }
    visitId
    total
    currencyString
    location
    items {
      ...FullBillingItem
    }
  }
  ${BasicBill}
  ${UserSignature}
  ${FullBillingItem}
`;

// drugs
export const FullLotNearExpiringInfo = `
  fragment FullLotNearExpiringInfo on LotNearExpiringInfo {
    date
    amount
  }
`;

export const FullStockByLocationAnalytics = `
  fragment FullStockByLocationAnalytics on StockByLocationAnalytics {
    regressionData
    slope
    intercept
    daysUntilStockOut
    lotNearExpiringInfo {
      ...FullLotNearExpiringInfo
    }
  }
  ${FullLotNearExpiringInfo}
`;

export const BasicDrug = `
  fragment BasicDrug on Drug {
    id
    drugId
    name
    unit
    class
    category
    tags {
      text
    }
    stockInfo {
      stock
      analytics {
        ...FullStockByLocationAnalytics
      }
    }
  }
  ${FullStockByLocationAnalytics}
`;

export const FullUserDrugGuideline = `
  fragment FullUserDrugGuideline on UserDrugGuideline {
    type
    userGuidelineName: name
    doseUnits: dose_units
    doseRangeMin: dose_range_min
    doseRangeMax: dose_range_max
    doses_per_day
    max_dose
    max_dose_units
    route {
      text
    }
    frequency_show
    as_needed
    days
  }
`;

export const FullDrugBpjsSettings = `
  fragment FullDrugBpjsSettings on DrugBpjsSettings {
    bpjsId
  }
`;

export const FullDrugIntegrationSettings = `
  fragment FullDrugIntegrationSettings on DrugIntegrationsSettings {
    bpjs {
      ...FullDrugBpjsSettings
    }
  }
  ${FullDrugBpjsSettings}
`;

export const FullDrug = `
  fragment FullDrug on Drug {
    ...BasicDrug
    retail
    dosing
    interactions
    comments
    recommended_dosing
    wholesale
    stock_unit
    minimum_units_to_dispense
    guideline {
      ...FullUserDrugGuideline
    }
    integrations {
      ...FullDrugIntegrationSettings
    }
  }
  ${BasicDrug}
  ${FullUserDrugGuideline}
  ${FullDrugIntegrationSettings}
`;

export const FullRXDetails = `
  fragment FullRXDetails on RXDetails {
    dose_number
    dose_units
    drug_dose_number
    drug_dose_units
    rounded_dose
    rounded_dose_percent_difference
    units_per_dose
    units_per_dose_raw
    route
    frequency_show
    doses_per_day
    as_needed
    days
    dispense
    refills_allowed
    reviewed_medications
    reviewed_side_effects
    addressed_patient_questions
    notes
  }
`;

export const FullDrugRx = `
  fragment FullDrugRx on Drug {
    drugId
    name
    unit
    class
    category
    retail
    dosing
    interactions
    comments
    recommended_dosing
    wholesale
    stock_unit
    minimum_units_to_dispense    
  }
`;

export const FullRX = `
  fragment FullRX on RX {
    guideline {
      ... on SystemDrugGuideline {
        ...FullSystemDrugGuideline
      }
      ... on UserDrugGuideline {
        ...FullUserDrugGuideline
      }
    }
    drug {
      ...FullDrugRx
    }
    details {
      ...FullRXDetails
    }
    created {
      ...UserSignature
    }
    mixId
    mixNotes
  }
  ${FullSystemDrugGuideline}
  ${FullUserDrugGuideline}
  ${FullDrugRx}
  ${FullRXDetails}
  ${UserSignature}
`;

export const FullQuickpickGuideline = `
  fragment FullQuickpickGuideline on QuickpickGuideline {
    type
    pre_text{
      type
      text
    }
    name{
      type
      text
    }
    dose_units
    dose_range_min
    dose_range_max
    doses_per_day
    max_dose
    max_doses
    max_dose_units
    route {
      text
    }
    post_text {
      text
    }
    frequency_show
    as_needed
    history_codes
    physical_exam_codes
  }
`;
export const FullQuickpick = `
  fragment FullQuickpick on Quickpick {
    id
    name
    drugId
    guideline {
      ...FullQuickpickGuideline
    }
    location
    notes
    updated {
      ...UserSignature
    }
  }
  ${FullQuickpickGuideline}
  ${UserSignature}
`;

export const FullQuickpickWithDrug = `
  fragment FullQuickpickWithDrug on QuickpickWithDrug {
    id
    name
    drug {
      ...FullDrug
    }
    guideline {
      ...FullQuickpickGuideline
    }
    location
    notes
    updated {
      ...UserSignature
    }
  }
  ${FullDrug}
  ${FullQuickpickGuideline}
  ${UserSignature}
`;

// this one doesn't contain the patient
export const FullStoredRx = `
  fragment FullStoredRx on StoredRx {
    id
    patient {
      ...BasicPatientWithoutId
    }
    visitId
    location
    origin
    canceled
    filled
    fill {
      ...FullRX
    }
    rx {
      ...FullRX
    }
    updated {
      ...UserSignature
    }
  }
  ${FullRX}
  ${BasicPatientWithoutId}
  ${UserSignature}
`;

export const FullPastVisitReportRx = `
  fragment FullPastVisitReportRx on PastVisitReportRx {
    fill {
      ...FullRX
    }
    lotNumbers
  }
  ${FullRX}
  ${UserSignature}
`;

export const ListStoredRx = `
  fragment ListStoredRx on StoredRx {
    id
    patient {
      patientId
      first_name
      last_name
      birthdate
    }
    filled
    fill {
      created {
        ...UserSignature
      }
    }
    rx {
      created {
        ...UserSignature
      }
    }
  }
  ${UserSignature}
`;

// visit

export const FullHistoryItem = `
  fragment FullHistoryItem on HistoryItem {
    name
    signature {
      ...UserSignature
    }
    start
    end
    historyId
    deleted
  }
  ${UserSignature}
`;

export const FullMaternalHistory = `
  fragment FullMaternalHistory on MaternalHistory {
    gestation
    preterm
    onterm
    abortions
    livingChildren
    lastPeriod
    edd
    outcome
    signature {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullComplaints = `
  fragment FullComplaints on Complaints {
    id
    key
    type
    name {
      text
      type
    }
    note
    degree {
      ...FullListItem
    }
    characteristics {
      id
      list {
        ...FullListItem
      }
      values {
        ...FullListItem
      }
    }
    timings {
      ...FullListItem
    }
    bodyParts {
      ...FullListItem
    }
    positions {
      ...FullListItem
    }
  }
  ${FullListItem}
`;

export const FullStudyOrder = `
  fragment FullStudyOrder on StudyOrder {
    id
    type
    comments {
      ...FullComment
    }
    guideline {
      ...FullChecklistEntry
    }
    order {
      text
      created {
        ...UserSignature
      }
    }
    updated {
      ...UserSignature
    }
    canceled
    acknowledged
    received
    filled
    studyId
  }
  ${FullComment}
  ${FullChecklistEntry}
  ${UserSignature}
`;

export const FullComplaintLists = `
  fragment FullComplaintLists on ComplaintLists {
    siteSpecificComplaintList {
      ...FullList
    }
    globalComplaintList {
      ...FullList
    }
    positionList {
      ...FullList
    }
    bodyPartList {
      ...FullList
    }
    degreeList {
      ...FullList
    }
    timingList {
      ...FullList
    }
    characteristicList {
      ...FullList
    }
  }
  ${FullList}
`;

export const FullNoteTemplate = `
  fragment FullNoteTemplate on NoteTemplate {
    id
    type
    name {
      text
    }
    description {
      text
    }
    items {
      ... on NoteTemplateHeaderItem {
        entryId
        type
        text {
          text
        }
        level
        groupId
      }
      ... on NoteTemplateParagraphItem {
        entryId
        type
        text {
          text
        }
        groupId
      }
      ... on NoteTemplateInputItem {
        entryId
        type
        inputType
        value
        label {
          text
        }
        groupId
      }
      ... on NoteTemplateRangeItem {
        entryId
        type
        label {
          text
        }
        value
        min
        max
        step
        groupId
      }
      ... on NoteTemplateCheckboxItem {
        entryId
        type
        label {
          text
        }
        checked
        groupId
      }
      ... on NoteTemplateSelectorItem {
        entryId
        type
        label {
          text
        }
        value
        options {
          text
        }
        groupId
      }
  
      ... on NoteTemplateSpacerItem {
        entryId
        type
        level
        groupId
      }
      ... on NoteTemplateExamItem {
        entryId
        type
        exam {
          ...FullExamWithoutId
        }
        groupId
      }
      ... on NoteTemplateVitalItem {
        entryId
        type
        vitalType
        kg
        cm
        bpm
        breaths_per_minute
        systolic
        diastolic
        degrees_c
        percent
        groupId
      }
      ... on NoteTemplateGroupItem {
        entryId
        type
        label {
          text
        }
        layout
      }
    }
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
  ${FullExamWithoutId}
`;

export const FullNote = `
  fragment FullNote on Note {
    id
    type
    templateId
    comments {
      text
      created {
        ...UserSignature
      }
    }
    name {
      text
    }
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
    filled {
      ...UserSignature
    }
    items {
      ... on NoteTemplateHeaderItem {
        entryId
        type
        text {
          text
        }
        level
        groupId
      }
      ... on NoteTemplateParagraphItem {
        entryId
        type
        text {
          text
        }
        groupId
      }
      ... on NoteTemplateInputItem {
        entryId
        type
        inputType
        value
        label {
          text
        }
        groupId
      }
      ... on NoteTemplateRangeItem {
        entryId
        type
        label {
          text
        }
        value
        min
        max
        step
        groupId
      }
      ... on NoteTemplateCheckboxItem {
        entryId
        type
        label {
          text
        }
        checked
        groupId
      }
      ... on NoteTemplateSelectorItem {
        entryId
        type
        label {
          text
        }
        value
        options {
          text
        }
        groupId
      }
  
      ... on NoteTemplateSpacerItem {
        entryId
        type
        level
        groupId
      }
      ... on NoteTemplateExamItem {
        entryId
        type
        exam {
          ...FullExamWithoutId
        }
        groupId
      }
      ... on NoteTemplateVitalItem {
        entryId
        type
        vitalType
        kg
        cm
        bpm
        breaths_per_minute
        systolic
        diastolic
        degrees_c
        percent
        groupId
      }
      ... on NoteTemplateGroupItem {
        entryId
        type
        label {
          text
        }
        layout
      }
    }
  }
  ${UserSignature}
  ${FullExamWithoutId}
`;

export const FullStandingOrder = `
  fragment FullStandingOrder on StandingOrder {
    diet
    iv
    vitalSigns
    o2
    visitors
    mobility
    hygiene
    toileting
    eating
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullAdmission = `
  fragment FullAdmission on Admission {
    fromVisit
    diagnosis
    started {
      ...UserSignature
    }
    ended {
      ...UserSignature
    }
    screenshot
  }
  ${UserSignature}
`;

export const FullPastVisits = `
  fragment FullPastVisits on PastVisits {
    id
    start
    end
    type
    chief_complaint {
      complaint
      how_long
      followUp
    }
    assessments {
      type
      description
    }
    treatments {
      type
      treatment
    }
    diagnoses {
      ...FullChecklist
    }
    admission {
      ...FullAdmission
    }
  }
  ${FullAdmission}
  ${FullChecklist}
`;

export const FullDentalHistory = `
  fragment FullDentalHistory on DentalHistory {
    end
    start
    exam {
      ...FullExamWithoutId
    }
  }
  ${FullExamWithoutId}
`;

export const FullVitalsHistory = `
  fragment FullVitalsHistory on VitalsHistory {
    end
    start
    vitals {
      ...FullVitals
    }
  }
  ${FullVitals}
`;

export const FullMedicationsHistory = `
  fragment FullMedicationsHistory on MedicationsHistory {
    start
    end
    medications {
      ...FullStoredRx
    }
  }
  ${FullStoredRx}
`;

export const FullExpenseItem = `
  fragment FullExpenseItem on ExpenseItem {
    id
    name
    category
    unitPrice
    quantity
    total
    location
    note
    backdated
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullBillableItem = `
  fragment FullBillableItem on BillableItem {
    billingCode
    category
    description {
      text
    }
    wholesale
  }
`;

export const FullPrice = `
  fragment FullPrice on Price {
    id
    pricelistId
    billingCode
    category
    description {
      text
    }
    amount
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullSignedRequestFields = `
  fragment FullSignedRequestFields on SignedRequestFields {
    bucket
    XAmzAlgorithm
    XAmzCredential
    XAmzDate
    XAmzSignature
    XAmzSecurityToken
    key
    Policy
    ACL
  }
`;

export const FullSignedRequest = `
  fragment FullSignedRequest on SignedRequest {
    url
    fields {
      ...FullSignedRequestFields
    }
  }
  ${FullSignedRequestFields}
`;

/**
 * Bulk patient registration
 */
export const FullBulkRegistrationHistory = `
 fragment FullBulkRegistrationHistory on BulkRegistrationHistory {
   id
   count
   signature {
     ...UserSignature
   }
 }
 ${UserSignature}
`;

/**
 * Translations
 */

export const FullTranslatedMessage = `
 fragment FullTranslatedMessage on TranslatedMessage {
    locale
    message
    defaultMessage
    translatorUsername
    lastUpdated
 }
`;

export const FullTranslation = `
 fragment FullTranslation on Translation {
    id
    defaultMessage
    created
    lastUpdated
    translations {
      ...FullTranslatedMessage
    }
  }
  ${FullTranslatedMessage}
`;

/**
 * Surveys
 */
export const FullSurveyBody = `
  fragment FullSurveyBody on SurveyBody {
    version
    blocks {
      ... on SurveyHeaderBlock {
        entryId
        type
        text {
          text
        }
        level
      }
      ... on SurveyParagraphBlock {
        entryId
        type
        text {
          text
        }
      }
      ... on SurveyInputBlock {
        entryId
        type
        inputType
          value
          label {
            text
          }
      }

      ... on SurveyRangeBlock {
        entryId
        type
        label {
          text
        }
        value
        min
        max
        step
      }

      ... on SurveyCheckboxBlock {
        entryId
        type
        label {
          text
        }
        checked
      }

      ... on SurveySelectorBlock {
        entryId
        type
        label {
          text
        }
        value
        options {
          text
        }
      }
    }
  }
  ${UserSignature}
`;

export const FullSurvey = `
  fragment FullSurvey on Survey {
    id
    name {
      text
    }
    description {
      text
    }
    body {
      ...FullSurveyBody
    }
    created{
      ...UserSignature
    }
    updated{
      ...UserSignature
    }
  }
  ${FullSurveyBody}
  ${UserSignature}
`;

export const FullSurveyHistoryItem = `
  fragment FullSurveyHistoryItem on SurveyHistoryItem {
    historyId
    surveyId
    name {
      text
    }
    description {
      text
    }
    signature {
      ...UserSignature
    }
    items {
      ... on NoteTemplateHeaderItem {
        entryId
        type
        text {
          text
        }
        level
        groupId
      }
      ... on NoteTemplateParagraphItem {
        entryId
        type
        text {
          text
        }
        groupId
      }
      ... on NoteTemplateInputItem {
        entryId
        type
        inputType
        value
        label {
          text
        }
        groupId
      }
      ... on NoteTemplateRangeItem {
        entryId
        type
        label {
          text
        }
        value
        min
        max
        step
        groupId
      }
      ... on NoteTemplateCheckboxItem {
        entryId
        type
        label {
          text
        }
        checked
        groupId
      }
      ... on NoteTemplateSelectorItem {
        entryId
        type
        label {
          text
        }
        value
        options {
          text
        }
        groupId
      }
  
      ... on NoteTemplateSpacerItem {
        entryId
        type
        level
        groupId
      }
      ... on NoteTemplateExamItem {
        entryId
        type
        exam {
          ...FullExamWithoutId
        }
        groupId
      }
      ... on NoteTemplateVitalItem {
        entryId
        type
        vitalType
        kg
        cm
        bpm
        breaths_per_minute
        systolic
        diastolic
        degrees_c
        percent
        groupId
      }
      ... on NoteTemplateGroupItem {
        entryId
        type
        label {
          text
        }
        layout
      }
    }
  }
  ${UserSignature}
  ${FullExamWithoutId}
`;

export const FullVisitHistory = `
  fragment FullVisitHistory on VisitHistory {
    medications {
      ...FullMedicationsHistory
    }
    vitals {
      ...FullVitalsHistory
    }
    dental {
      ...FullDentalHistory
    }
    pastVisits {
      ...FullPastVisits
    }
    manualMedications {
      ...FullHistoryItem
    }
    manualProblems {
      ...FullHistoryItem
    }
    allergies {
      ...FullHistoryItem
    }
    familyHistory {
      ...FullHistoryItem
    }
    dietHistory {
      ...FullHistoryItem
    }
    developmentHistory {
      ...FullHistoryItem
    }
    socialHistory {
      ...FullHistoryItem
    }
    surveys {
      ...FullSurveyHistoryItem
    }
    maternal {
      ...FullMaternalHistory
    }
  }
  ${FullMedicationsHistory}
  ${FullVitalsHistory}
  ${FullDentalHistory}
  ${FullPastVisits}
  ${FullHistoryItem}
  ${FullSurveyHistoryItem}
  ${FullMaternalHistory}
`;

export const FullHistory = `
  fragment FullHistory on History {
    manualMedications {
      ...FullHistoryItem
    }
    manualProblems {
      ...FullHistoryItem
    }
    allergies {
      ...FullHistoryItem
    }
    family {
      ...FullHistoryItem
    }
    diet {
      ...FullHistoryItem
    }
    development {
      ...FullHistoryItem
    }
    social {
      ...FullHistoryItem
    }
    generalReviewOfSystems {
      ...FullExamWithoutId
    }
    complaints {
      ...FullComplaints
    }
    comments {
      ...FullComment
    }
    surveys {
      ...FullSurveyHistoryItem
    }
    maternal {
      ...FullMaternalHistory
    }
  }
  ${FullComplaints}
  ${FullHistoryItem}
  ${FullExamWithoutId}
  ${FullComment}
  ${FullSurveyHistoryItem}
  ${FullMaternalHistory}
`;

export const BasicVisit = `
  fragment BasicVisit on Visit {
    id
    location
    start
    backdated
    type
    history {
      ...FullHistory
    }
    clinical_status {
      priority
    }
    chief_complaint {
      how_long
      complaint
      followUp
    }
    status_assesment
    disposition
    new_visit_type
    external_referral_location
    external_referral_reason
    followup
    icd10s {
      name
      code
    }
    admission {
      ...FullAdmission
    }
  }
  ${FullAdmission}
  ${UserSignature}
  ${FullVitals}
  ${FullHistory}
`;

export const WaitingPatientVisit = gql`
  fragment WaitingPatientVisit on WaitingPatientVisit {
    exam_started_by {
      ...UserSignature
    }
    study_orders {
      ...FullStudyOrder
    }
    room
    telehealth
    waitingNumber
    id
    location
    start
    backdated
    type
    clinical_status {
      priority
    }
    admission {
      ...FullAdmission
    }
  }
  ${FullAdmission}
  ${UserSignature}
  ${FullStudyOrder}
`;

export const WaitingPatient = gql`
  fragment WaitingPatient on WaitingPatient {
    id
    first_name
    last_name
    birthdate
    sex
    active_visit {
      ...WaitingPatientVisit
    }
  }
  ${WaitingPatientVisit}
`;

export const FullProcedureOrder = gql`
  ${FullComment}
  ${FullChecklistEntry}
  ${UserSignature}
  fragment FullProcedureOrder on ProcedureOrder {
    id
    type
    comments {
      ...FullComment
    }
    guideline {
      ...FullChecklistEntry
    }
    order {
      text
      created {
        ...UserSignature
      }
    }
    updated {
      ...UserSignature
    }
    canceled
    acknowledged
    procedureId
  }
`;

export const ActiveVisit = `
  fragment ActiveVisit on Visit {
    ...BasicVisit
    status_assesment
    disposition
    exam_started_by {
      ...UserSignature
    }
    completedBy {
      ...UserSignature
    }
    exam {
      ...FullExamWithoutId
    }
    checklists {
      ...FullChecklist
    }
    rx {
      prescriptions
      orders
    }
    medicine_orders {
      id
      type
      recurringAdministrationId
      administered {
        ...UserSignature
      }
      comments {
        ...FullComment
      }
      order {
        text
        created {
          ...UserSignature
        }
        updated {
          ...UserSignature
        }
      }
      updated {
        ...UserSignature
      }
      canceled
      acknowledged
      rxId
    }
    study_orders {
      ...FullStudyOrder
    }
    procedure_orders {
      id
      type
      comments {
        ...FullComment
      }
      guideline {
        ...FullChecklistEntry
      }
      order {
        text
        created {
          ...UserSignature
        }
      }
      canceled
      acknowledged
      procedureId
    }
    dr_nurse_orders {
      id
      type
      comments {
        ...FullComment
      }
      order {
        text
        created {
          ...UserSignature
        }
      }
      canceled
      acknowledged
    }
    vitals {
      ...FullVitals
    }
    assessments {
      type
      description
      created {
        ...UserSignature
      }
      id
    }
    treatments {
      type
      treatment
      created {
        ...UserSignature
      }
      id
    }
    progress_notes {
      ...FullComment
    }
    notes {
      ...FullNote
    }
    room
    telehealth
    waitingNumber
    standingOrder{
      ...FullStandingOrder
    }
  }
  ${FullStandingOrder}
  ${FullNote}
  ${FullStudyOrder}
  ${FullComment}
  ${BasicVisit}
  ${FullExamWithoutId}
  ${FullChecklist}
  ${FullChecklistEntry}
  ${FullVitals}
  ${UserSignature}
`;

export const FullPatientWithActiveVisit = `
  fragment FullPatientWithActiveVisit on Patient {
    ...FullPatient
    active_visit {
      ...ActiveVisit
    }
  }
  ${ActiveVisit}
  ${FullPatient}
`;

export const FullVisit = `
  fragment FullVisit on Visit {
    ...ActiveVisit
    patient {
      ...BasicPatient
    }
  }
  ${BasicPatient}
  ${ActiveVisit}
`;

export const FullBillVisit = gql`
  ${FullPatientWithoutId}
  ${FullChecklist}
  ${FullProcedureOrder}
  ${FullStoredRx}
  ${FullStudyOrder}
  fragment FullBillVisit on BillVisit {
    id
    location
    type
    start
    checklists {
      ...FullChecklist
    }
    assessments {
      description
    }
    studies {
      templateId
      order {
        ...FullStudyOrder
      }
    }
    procedure_orders {
      ...FullProcedureOrder
    }
    rx {
      ...FullStoredRx
    }
    patient {
      ...FullPatientWithoutId
    }
  }
`;

export const FullPharmacyItemStock = `
  fragment FullPharmacyItemStock on DrugStockEntry {
    id
    amount
    balance
    container
    containerCapacity
    containerCount
    notes
    stock_unit
    item
    location
    rxId
    lotNumber
    lotExpirationDate
    audit
    created 
    createdBy {
      ...UserSignature
    }
    lastModified
    lastModifiedBy {
      ...UserSignature
    }
  }
  ${UserSignature}
`;

export const FullWeight = `
  fragment FullWeight on Weight {
    id
      type
      kg
      updated {
        ...UserSignature
      }
  }
  ${UserSignature}
`;

export const FullPrescription = `
  fragment FullPrescription on RxsByVisit {
    visitId
    location
    date {
      ...UserSignature
    }
    rxs {
      ...ListStoredRx
    }
  }
  ${ListStoredRx}
  ${UserSignature}
`;

export const FullScheduledVisit = `
  fragment FullScheduledVisit on ScheduledVisit {
    id
    updated {
      ...UserSignature
    }
    registrationData {
      location
      room
      start
      end
      type
      telehealth
      chiefComplaint {
        complaint
        howLong
        followUp
      }
      patient {
        ...BasicPatient
      }
    }
  }
  ${UserSignature}
  ${BasicPatient}
`;

export const FullPastVisit = gql`
  fragment FullPastVisit on PastVisit {
    id
    location
    start
    backdated
    type
    patient {
      ...FullPatientWithoutId
    }
    history {
      ...FullHistory
    }
    clinical_status {
      priority
    }
    chief_complaint {
      how_long
      complaint
      followUp
    }
    status_assesment
    disposition
    new_visit_type
    external_referral_location
    external_referral_reason
    followup
    status_assesment
    disposition
    exam_started_by {
      ...UserSignature
    }
    completedBy {
      ...UserSignature
    }
    exam {
      ...FullExamWithoutId
    }
    checklists {
      ...FullChecklist
    }
    medicine_orders {
      id
      type
      recurringAdministrationId
      administered {
        ...UserSignature
      }
      comments {
        ...FullComment
      }
      order {
        text
        created {
          ...UserSignature
        }
      }
      canceled
      acknowledged
      rxId
    }
    study_orders {
      ...FullStudyOrder
    }
    procedure_orders {
      id
      type
      comments {
        ...FullComment
      }
      guideline {
        ...FullChecklistEntry
      }
      order {
        text
        created {
          ...UserSignature
        }
      }
      canceled
      acknowledged
      procedureId
    }
    dr_nurse_orders {
      id
      type
      comments {
        ...FullComment
      }
      order {
        text
        created {
          ...UserSignature
        }
      }
      canceled
      acknowledged
    }
    vitals {
      ...FullVitals
    }
    assessments {
      type
      description
      created {
        ...UserSignature
      }
      id
    }
    treatments {
      type
      treatment
      created {
        ...UserSignature
      }
      id
    }
    progress_notes {
      ...FullComment
    }
    room
    telehealth
    rx {
      orders {
        ...FullStoredRx
      }
      prescriptions {
        ...FullStoredRx
      }
    }
    icd10s {
      name
      code
    }
  }
  ${FullHistory}
  ${FullStudyOrder}
  ${FullComment}
  ${FullExamWithoutId}
  ${FullChecklist}
  ${FullChecklistEntry}
  ${FullVitals}
  ${UserSignature}
  ${FullPatientWithoutId}
  ${FullStoredRx}
`;

export const FullPastVisitWithHistory = gql`
  fragment FullPastVisitWithHistory on PastVisitWithHistory {
    visit {
      ...FullPastVisit
    }
    patientHistory {
      ...FullVisitHistory
    }
  }
  ${FullPastVisit}
  ${FullVisitHistory}
`;

export const ListPastVisit = gql`
  fragment ListPastVisit on Visit {
    id
    start
    end
    type
    followup
    assessments {
      description
    }
    treatments {
      treatment
    }
    checklists {
      checklistId
      name {
        text
      }
    }
    patient {
      sex
      id
      first_name
      last_name
      birthdate
    }
    exam_started_by {
      ...UserSignature
    }
  }
  ${UserSignature}
`;
