import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';
import { FullPatientWithActiveVisit } from '../../api/interfaces';
import { useSchedulerContext } from './useSchedulerContext';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { LoadingInline } from 'components-ts/Loading';
import { Button } from 'reactstrap';
import { START_SCHEDULED_VISIT } from 'scenes/ScheduledVisits/requests';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { sitemap } from 'components-ts/Routing';
import { calcDateDiffInMinutes } from 'components-ts/DateAndTime';
import { TranslationWrapper as T } from 'components-ts/Translations';

const messages = defineMessages({
  startVisit: {
    id: 'EventViewer.start_visit',
    defaultMessage: 'Start visit',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

export interface StartVisitVariables {
  id: string;
}

interface StartVisitData {
  startScheduledVisit: FullPatientWithActiveVisit;
}

export type UseStartVisitParams = MutationHookOptions<StartVisitData, StartVisitVariables>;

export const useStartVisit = (params?: UseStartVisitParams) => {
  const intl = useIntl();
  const { selectedEvent, events } = useSchedulerContext();

  const history = useHistory();
  const [error, setError] = React.useState<Nullable<string>>(null);

  const onError = (error) => {
    if (typeof params?.onError === 'function') {
      params.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError));
    }
  };

  const onCompleted = (data: StartVisitData) => {
    const patient = data.startScheduledVisit;
    const url = sitemap.patients.routes.exam.buildPath(patient.id);

    history.push(url);
  };

  const [startVisit, { loading: isLoading }] = useMutation<StartVisitData, StartVisitVariables>(START_SCHEDULED_VISIT, {
    ...params,
    onCompleted,
    onError,
  });

  const onSubmit = () => {
    setError(null);
    if (!selectedEvent) {
      return null;
    }

    const variables = {
      id: selectedEvent,
    };

    startVisit({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  const fullEvent = events.find((event) => event.id === selectedEvent);
  const isStartable = fullEvent?.start && calcDateDiffInMinutes(fullEvent.start.toString()) < 10;

  return {
    isStartable,
    onSubmit,
    error,
    isLoading,
    onErrorClose,
  };
};

export const StartVisitButton: React.VFC = () => {
  const intl = useIntl();

  const { onSubmit, isLoading, isStartable } = useStartVisit();
  if (!isStartable) {
    return null;
  }

  return (
    <Button color="success" className={'ml-1'} onClick={onSubmit}>
      <T id={messages.startVisit.id} className={isLoading ? 'mr-2' : ''}>
        {intl.formatMessage(messages.startVisit)}
      </T>
      {isLoading && <LoadingInline />}
    </Button>
  );
};
