import { MutationHookOptions, useMutation } from '@apollo/client';
import { HealthSystem } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import {
  SUBSCRIBE_TO_TRIAGE_CHECKLIST,
  SUBSCRIBE_TO_TRIAGE_CHECKLIST_BATCH,
  UNSUBSCRIBE_FROM_TRIAGE_CHECKLIST,
} from 'api/request/systems';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  checklistNotFound: {
    id: 'TriageTab.checklist_not_found',
    defaultMessage: 'Checklist not found. Please, reload.',
  },
  errorAddingChecklist: {
    id: 'TriageTab.could_not_add_the_checklist',
    defaultMessage: 'Could not add this checklist. Please, retry.',
  },
  errorRemovingChecklist: {
    id: 'TriageTab.could_not_remove_the_checklist',
    defaultMessage: 'Could not remove this checklist. Please, retry.',
  },
});

interface SubscribeToTriageChecklistData {
  subscribeToTriageChecklist: HealthSystem;
}

interface SubscribeToTriageChecklistVariables {
  systemId: string;
  locationId: string;
  checklistId: string;
}

type UseSubscribeToTriageChecklistParams = MutationHookOptions<
  SubscribeToTriageChecklistData,
  SubscribeToTriageChecklistVariables
> & {
  systemId: string;
  locationId: string;
};

export const useSubscribeToTriageChecklist = (params: UseSubscribeToTriageChecklistParams) => {
  const { systemId, locationId, ...restParams } = params;
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'checklist_not_found':
        return setError(messages.checklistNotFound);
      case 'could_not_add_the_checklist':
        return setError(messages.errorAddingChecklist);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToTriageChecklist, { loading }] = useMutation<
    SubscribeToTriageChecklistData,
    SubscribeToTriageChecklistVariables
  >(SUBSCRIBE_TO_TRIAGE_CHECKLIST, { ...restParams, onError, onCompleted });

  const onSubmit = (checklistId: string) => {
    subscribeToTriageChecklist({
      variables: {
        systemId,
        locationId,
        checklistId,
      },
    });
  };

  return {
    onSubmit,
    loading,
    error,
  };
};

interface UseUnsubscribeFromTriageChecklistData {
  unsubscribeFromTriageChecklist: HealthSystem;
}

interface UseUnsubscribeFromTriageChecklistVariables {
  systemId: string;
  locationId: string;
  checklistId: string;
}

type UseUnsubscribeFromTriageChecklistParams = MutationHookOptions<
  UseUnsubscribeFromTriageChecklistData,
  UseUnsubscribeFromTriageChecklistVariables
> & { systemId: string; locationId: string };

export const useUnsubscribeFromTriageChecklist = (params: UseUnsubscribeFromTriageChecklistParams) => {
  const { systemId, locationId, ...restParams } = params;
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'checklist_not_found':
        return setError(messages.checklistNotFound);
      case 'could_not_remove_the_checklist':
        return setError(messages.errorRemovingChecklist);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [unsubscribeFromTriageChecklist, { loading }] = useMutation(UNSUBSCRIBE_FROM_TRIAGE_CHECKLIST, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onDelete = (checklistId: string) => {
    unsubscribeFromTriageChecklist({
      variables: {
        systemId,
        locationId,
        checklistId,
      },
    });
  };

  return {
    onDelete,
    loading,
    error,
  };
};

interface SubscribeToTriageChecklistBatchData {
  subscribeToTriageChecklistsBatch: HealthSystem;
}

interface SubscribeToTriageChecklistBatchVariables {
  systemId: string;
  locationId: string;
  ids: Array<string>;
}

type UseSubscribeToTriageChecklistBatchParams = MutationHookOptions<
  SubscribeToTriageChecklistBatchData,
  SubscribeToTriageChecklistBatchVariables
> & {
  systemId: string;
  locationId: string;
};
export const useSubscribeToTriageChecklistsBatch = (params: UseSubscribeToTriageChecklistBatchParams) => {
  const { systemId, locationId, ...restParams } = params;
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'checklist_not_found':
        return setError(messages.checklistNotFound);
      case 'could_not_remove_the_checklist':
        return setError(messages.errorRemovingChecklist);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToTriageChecklistsBatch, { loading: isLoading }] = useMutation<
    SubscribeToTriageChecklistBatchData,
    SubscribeToTriageChecklistBatchVariables
  >(SUBSCRIBE_TO_TRIAGE_CHECKLIST_BATCH, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onSubsbribeBatch = (ids: Array<string>) => {
    subscribeToTriageChecklistsBatch({
      variables: {
        systemId,
        locationId,
        ids,
      },
    });
  };

  return {
    onSubsbribeBatch,
    error,
    isLoading,
  };
};
