import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Signature } from 'api/interfaces';
import { getNameInitials } from './utils';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { FaHistory as HistoryIcon } from 'react-icons/fa';
const messages = defineMessages({
  updated: {
    id: 'UI.label_last_update',
    defaultMessage: 'Last updated {date} by {user}',
  },
  created: {
    id: 'UI.label_created',
    defaultMessage: 'Created {date} by {user}',
  },
});

interface LastUpdatedProps {
  signature: Signature;
  className?: string;
  placement?: string;
}

export const LastUpdated: React.FC<LastUpdatedProps> = (props) => {
  const { signature, placement = 'bottom', className = '' } = props;
  const intl = useIntl();

  const id = 'last-updated' + new Date(signature.timestamp).getTime().toString();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <small
        id={id}
        className={`p-1 d-flex align-items-center animated-border-on-hover rounded pointer text-gray ${className}`}
      >
        <HistoryIcon className="mr-1" />
        <em>
          <b>{getNameInitials(`${signature.given_name} ${signature.family_name}`)}</b>
          {`: `}
          <b> {intl.formatDate(signature.timestamp, { dateStyle: 'short', timeStyle: 'short' })}</b>
        </em>
      </small>
      <UncontrolledPopover placement={placement} target={id} trigger="legacy">
        <PopoverBody>
          <T id={messages.updated.id}>
            {intl.formatMessage(messages.updated, {
              date: <b> {intl.formatDate(signature.timestamp, { dateStyle: 'long', timeStyle: 'short' })}</b>,
              user: <b>{`${signature.given_name} ${signature.family_name}`}</b>,
            })}
          </T>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

interface LastUpdatedTraslationProps {
  username: string;
  date: string;
  className?: string;
  placement?: string;
}
/**
 * These are not saved as signatures -_-
 * So, new component
 */
export const LastUpdatedTraslation: React.FC<LastUpdatedTraslationProps> = (props) => {
  const { username, date, placement = 'bottom', className = '' } = props;
  const intl = useIntl();

  const id = 'last-updated' + new Date(date).getTime().toString();

  return (
    <>
      <small
        id={id}
        className={`p-1 d-flex align-items-center animated-border-on-hover rounded pointer text-gray ${className}`}
      >
        <HistoryIcon className="mr-1" />
        <em>
          <b>{username}</b>
          {`: `}
          <b> {intl.formatDate(date, { dateStyle: 'short', timeStyle: 'short' })}</b>
        </em>
      </small>
      <UncontrolledPopover placement={placement} target={id} trigger="legacy">
        <PopoverBody>
          <T id={messages.updated.id}>
            {intl.formatMessage(messages.updated, {
              date: <b> {intl.formatDate(username, { dateStyle: 'long', timeStyle: 'short' })}</b>,
              user: <b>{`${username}`}</b>,
            })}
          </T>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export const Created: React.FC<LastUpdatedProps> = (props) => {
  const { signature, placement = 'bottom', className = '' } = props;
  const intl = useIntl();

  const id = 'created-' + new Date(signature.timestamp).getTime().toString();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <small
        id={id}
        className={`p-1 d-flex align-items-center animated-border-on-hover rounded pointer text-gray ${className}`}
      >
        <HistoryIcon className="mr-1" />
        <em>
          <b>{getNameInitials(`${signature.given_name} ${signature.family_name}`)}</b>
          {`: `}
          <b> {intl.formatDate(signature.timestamp, { dateStyle: 'short', timeStyle: 'short' })}</b>
        </em>
      </small>
      <UncontrolledPopover placement={placement} target={id} trigger="legacy">
        <PopoverBody>
          <T id={messages.updated.id}>
            {intl.formatMessage(messages.created, {
              date: <b> {intl.formatDate(signature.timestamp, { dateStyle: 'long', timeStyle: 'short' })}</b>,
              user: <b>{`${signature.given_name} ${signature.family_name}`}</b>,
            })}
          </T>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};
