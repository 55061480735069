import React, { useState } from 'react'
import { Alert, Button, ButtonGroup, Modal, ModalBody, ModalHeader, FormGroup, Row, Col, Label, Input } from 'reactstrap'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Field, Form } from 'react-final-form'
import { LoadingInline } from 'components'
import { useMutation } from '@apollo/client'
import { required } from 'utils/formValidators'
import { ObjectId } from 'bson'
import { GET_PRICELISTS, CREATE_PRICELIST, UPDATE_PRICELIST } from 'api/request/pricing'
import { useMySystemSettings } from 'components-ts/HealthSystems'
import { countries } from 'countries-list'

const debug = require('debug')('wd:CreatePricelistModal')

// Static translations
const messages = defineMessages({
  createPaymentType: {
    id: 'CreatePricelistModal.create_paymentType',
    defaultMessage: 'Create Payment Type'
  },
  updatePaymentType: {
    id: 'CreatePricelistModal.update_paymentType',
    defaultMessage: 'Update Payment Type'
  },
  name: {
    id: 'CreatePricelistModal.label_name',
    defaultMessage: 'Name'
  },
  description: {
    id: 'CreatePricelistModal.label_description',
    defaultMessage: 'Description'
  },
  currency: {
    id: 'CreatePricelistModal.label_currency',
    defaultMessage: 'Currency'
  },
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Please reload'
  },
  requiredError: {
    id: 'UI.field_required',
    defaultMessage: 'This field is required'
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel'
  },
  confirmButton: {
    id: 'UI.button_confirm',
    defaultMessage: 'Confirm'
  }
})

const CreatePricelistModal = (props) => {
  const { intl, onCreated, onUpdated, showModal, toggleModal, pricelistToSave } = props
  const { formatMessage } = intl
  const { id, name, description, currency } = pricelistToSave || {}

  const { payment, system } = useMySystemSettings()

  const [defaultCurrency] = countries[system.country] ? countries[system.country].currency.split(',') : []

  const creating = !id

  const initialValues = creating
    ? {
      id: new ObjectId(),
      name: '',
      description: '',
      currency: (payment || {}).currency || defaultCurrency
    }
    : {
      id,
      name,
      description,
      currency
    }

  // error handler
  const [error, setError] = useState()
  const onError = (e) => {
    setError(formatMessage(messages.unexpectedError))
  }

  const [createPricelist, { loading: isCreating }] = useMutation(CREATE_PRICELIST, { onCompleted: onCreated, onError })
  const [updatePricelist, { loading: isUpdating }] = useMutation(UPDATE_PRICELIST, { onCompleted: onUpdated, onError })

  const onSubmit = (values) => {
    const { id, name, description, currency } = values

    const pricelist = {
      name,
      description: description || '',
      currency: currency || payment.currency
    }

    try {
      // Creating a new pricelist
      if (creating) {
        createPricelist({
          variables: { pricelist },
          update: (cache, result) => {
            try {
              const { data } = result
              const { pricelists } = cache.readQuery({ query: GET_PRICELISTS })
              // adding the new pricelist to the cache
              const newPricelists = {
                docs: [data.createPricelist, ...pricelists.docs],
                count: pricelists.count + 1,
                __typename: 'PricelistsWithCount'
              }
              // updating cache
              cache.writeQuery({
                query: GET_PRICELISTS,
                data: {
                  pricelists: newPricelists
                }
              })
            } catch (e) {
              debug(e)
            }
          }
        })
      } else {
      // Updating an existing pricelist
        updatePricelist({
          variables: { id, pricelist }
        })
      }
    } catch (e) {
      debug(JSON.stringify(e))
      // show the error
      setError(formatMessage(messages.unexpectedError))
    }
  }

  return (
    <Modal isOpen={showModal} toggle={toggleModal} backdrop>
      <ModalHeader toggle={toggleModal}>
        { creating
          ? <T id={messages.createPaymentType.id}>{formatMessage(messages.createPaymentType)}</T>
          : <T id={messages.updatePaymentType.id}>{formatMessage(messages.updatePaymentType)}</T>
        }
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          className='p-4'
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            return <form
              onSubmit={async event => {
                await handleSubmit(event)
              }}
            >
              <Row className='align-items-center'>
                <Field
                  name='name'
                  validate={required(formatMessage(messages.requiredError))}
                >
                  {({ input, meta }) => (
                    <Col>
                      <FormGroup>
                        <Label><T id={messages.name.id}>{formatMessage(messages.name)}</T></Label>
                        <Input
                          {...input}
                          className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''}`}
                          placeholder={formatMessage(messages.name)}
                        />
                      </FormGroup>
                      {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                    </Col>
                  )}
                </Field>
              </Row>
              <Row className='align-items-center'>
                <Field
                  name='description'
                >
                  {({ input, meta }) => (
                    <Col>
                      <FormGroup>
                        <Label><T id={messages.description.id}>{formatMessage(messages.description)}</T></Label>
                        <Input
                          {...input}
                          className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''}`}
                          placeholder={formatMessage(messages.description)}
                        />
                      </FormGroup>
                      {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                    </Col>
                  )}
                </Field>
              </Row>
              <Row className='align-items-center'>
                <Field
                  name='currency'
                >
                  {({ input, meta }) => (
                    <Col>
                      <FormGroup>
                        <Label><T id={messages.currency.id}>{formatMessage(messages.currency)}</T></Label>
                        <Input
                          {...input}
                          className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''}`}
                          placeholder={formatMessage(messages.currency)}
                          disabled
                        />
                      </FormGroup>
                      {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                    </Col>
                  )}
                </Field>
              </Row>
              {error && <Alert className='alert-danger'>{error}</Alert>}
              <Row className='m-1 d-flex justify-content-end'>
                <ButtonGroup>
                  <Button color='secondary' className='mr-1 ' onClick={toggleModal}>
                    <T id={messages.cancelButton.id}>{formatMessage(messages.cancelButton)}</T>
                  </Button>
                  <Button type='submit' color='primary' className='' disabled={isCreating || isUpdating}>
                    <T id={messages.confirmButton.id}>{formatMessage(messages.confirmButton)}</T>
                    {(isCreating || isUpdating) && <LoadingInline />}
                  </Button>
                </ButtonGroup>
              </Row>
            </form>
          }}
        />
      </ModalBody>
    </Modal>
  )
}

export default injectIntl(CreatePricelistModal)

debug('loaded')
