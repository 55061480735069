import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams, useSession } from 'hooks';
import { DefaultSystemOrLocationAttr } from 'components-ts/Auth/utils';
import { DateRange, dateRangeFromPeriod, Period } from 'components-ts/DateAndTime/utils';
import { Nullable } from 'components-ts/utils';
import { VisitType } from 'api/interfaces';
import { CommoditiesReportFilter } from '.';
import { BillableCategory } from 'components-ts/Billing/utils';

enum Filter {
  FROM = 'from',
  TO = 'to',
  LOCATION = 'location',
  DOCTOR = 'doctor',
  CATEGORY = 'category',
  VISIT_TYPE = 'visitType',
}

interface UseCommoditiesReportFilter {
  filter: CommoditiesReportFilter;
  onLocationChange: (location: Array<string>) => void;
  onVisitTypeChange: (visitType: Array<VisitType>) => void;
  onDoctorChange: (doctor: Array<string>) => void;
  onCategoryChange: (category: Array<BillableCategory>) => void;
  onPeriodChange: (period: DateRange) => void;
}

function queryParamToArray<T>(value: Nullable<string>): Array<T> {
  if (value === null) {
    return [];
  }

  try {
    const array = JSON.parse(value);

    if (Array.isArray(array)) {
      return array as Array<T>;
    }
  } catch (error) {}

  return [];
}

const DEFAULT_PERIOD = dateRangeFromPeriod(Period.PAST_MONTH);

export const useCommoditiesReportFilter = (): UseCommoditiesReportFilter => {
  // replate it after migrating the session content
  const { getUserLocation } = useSession() as any;
  const { id: defaultLocationId } = getUserLocation() as DefaultSystemOrLocationAttr;

  // url synchronized filter
  const queryParams = useQueryParams();
  const history = useHistory();

  const initialFrom = queryParams.get(Filter.FROM) ?? DEFAULT_PERIOD.from.toISOString();
  const initialTo = queryParams.get(Filter.TO) ?? DEFAULT_PERIOD.to.toISOString();

  const [filter, setFilter] = React.useState<CommoditiesReportFilter>({
    from: initialFrom,
    to: initialTo,
    location: queryParamToArray<string>(queryParams.get(Filter.LOCATION)) ?? [defaultLocationId],
    doctor: queryParamToArray<string>(queryParams.get(Filter.DOCTOR)),
    category: queryParamToArray<BillableCategory>(queryParams.get(Filter.CATEGORY)),
    visitType: queryParamToArray<VisitType>(queryParams.get(Filter.VISIT_TYPE)),
  });

  const onLocationChange = (location: Array<string>) => {
    setFilter((prev) => ({
      ...prev,
      location,
      doctor: [],
    }));

    if (location.length > 0) {
      queryParams.set(Filter.LOCATION, JSON.stringify(location));
    } else {
      queryParams.delete(Filter.LOCATION);
    }

    /**
     * These ones depend on the selected location
     */
    queryParams.delete(Filter.DOCTOR);

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onVisitTypeChange = (visitType: Array<VisitType>) => {
    setFilter((prev) => ({ ...prev, visitType }));

    if (visitType.length > 0) {
      queryParams.set(Filter.VISIT_TYPE, JSON.stringify(visitType));
    } else {
      queryParams.delete(Filter.VISIT_TYPE);
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onDoctorChange = (doctor: Array<string>) => {
    setFilter((prev) => ({ ...prev, doctor }));

    if (doctor.length > 0) {
      queryParams.set(Filter.DOCTOR, JSON.stringify(doctor));
    } else {
      queryParams.delete(Filter.DOCTOR);
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onCategoryChange = (category: Array<BillableCategory>) => {
    setFilter((prev) => ({ ...prev, category }));

    if (category.length > 0) {
      queryParams.set(Filter.CATEGORY, JSON.stringify(category));
    } else {
      queryParams.delete(Filter.CATEGORY);
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onPeriodChange = (period: DateRange) => {
    const from = period.from.toISOString();
    const to = period.to.toISOString();

    setFilter((prev) => ({ ...prev, from, to }));

    queryParams.set(Filter.FROM, from);
    queryParams.set(Filter.TO, to);

    history.replace({
      search: queryParams.toString(),
    });
  };

  return {
    filter,
    onLocationChange,
    onVisitTypeChange,
    onDoctorChange,
    onCategoryChange,
    onPeriodChange,
  };
};
