import { ReactSelectInternalSingleValue } from 'components-ts/Selectors';

export enum BillableCategory {
  ADMINISTRATION = 'administration',
  DOCTOR_FEE = 'doctorFee',
  LAB = 'lab',
  MEDICINES = 'medicines',
  OTHERS = 'others',
  PROCEDURES = 'procedures',
}

export const isValidBillableCategory = (value: string): boolean => {
  return (Object.values(BillableCategory) as Array<string>).includes(value);
};

export const calculatePrice = (quantity: number, unitPrice: number) => {
  return Math.round(quantity * (unitPrice * 100)) / 100;
};

export const billingCodeValidator = (billingCodes: Array<ReactSelectInternalSingleValue>, value?: string) => {
  if (!value) return undefined;

  const found = billingCodes.find((billingCode) => billingCode.value === value);

  return !found;
};
