import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import FullCalendar, {
  CalendarApi,
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import {
  MdCalendarViewMonth as MonthlyViewIcon,
  MdCalendarViewWeek as WeeklyViewIcon,
  MdCalendarViewDay as DailyViewIcon,
  MdFormatListBulleted as ListViewIcon,
  MdKeyboardArrowRight as NextIcon,
  MdKeyboardArrowLeft as PrevIcon,
  MdOutlineSettings as SettingIcon,
} from 'react-icons/md';
import { useSystems } from 'components-ts/HealthSystems';
import { Alert, Button, ButtonGroup } from 'reactstrap';
import { useDisclosure } from 'components-ts/useDisclosure';
import { CalendarViewType, getDurationInMinutes } from '../utils';
import { UpdateSchedulerSettingsModal } from '../SchedulerSettings';
import { useSchedulerContext } from '../useSchedulerContext';
import { Nullable } from 'components-ts/utils';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { EventFilter } from './EventFilter';
import { EventColor } from './utils';
import { useMediaQuery } from 'hooks';
import './styles.scss';
import { AddRoomModal } from 'components-ts/HealthSystems/AddRoomModal';

const messages = defineMessages({
  newEvent: {
    id: 'Calendar.click_to_create',
    defaultMessage: 'Click to create',
  },
  settingNeeded: {
    id: 'SettingsNotFound.you_need_to_configure_telehealth',
    defaultMessage: 'You can configure the duration of the slots to improve the calendar visualization',
  },
  listView: {
    id: 'UI.list_view_button',
    defaultMessage: 'List',
  },
  dailyView: {
    id: 'UI.daily_view_button',
    defaultMessage: 'Daily',
  },
  weeklyView: {
    id: 'UI.weekly_view_button',
    defaultMessage: 'Weekly',
  },
  monthlyView: {
    id: 'UI.monthly_view_button',
    defaultMessage: 'Monthly',
  },
  today: {
    id: 'UI.today_button',
    defaultMessage: 'Today',
  },
  next: {
    id: 'UI.next_button',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'UI.previous_button',
    defaultMessage: 'Previous',
  },
  settings: {
    id: 'UI.settings_button',
    defaultMessage: 'Settings',
  },
  createRoom: {
    id: 'TelehealthTab.button_create_a_room',
    defaultMessage: 'Create a Room',
  },
  chooseRoom: {
    id: 'Calendar.choose_a_room',
    defaultMessage: 'You have to choose a Room first',
  },
});

interface CalendarProps {
  isDisabled?: boolean;
  className?: string;
}

/**
 * THIS NEED TO BE INSIDE OF THE CALENDAR CONTEXT PROVIDER
 */
export const CustomCalendar: React.FC<CalendarProps> = props => {
  const { isDisabled, className = '' } = props;

  const ref = React.useRef<Nullable<FullCalendar>>(null);

  const isSmallScreen = useMediaQuery('(max-width: 640px)');
  const { roomId, events, locale, slotDuration, onEventSelect, onPeriodChange, onCurrentDateChange } =
    useSchedulerContext();

  const { getMyLocation } = useSystems();
  const { settings } = getMyLocation();
  const { scheduler: schedulerSettings } = settings;

  const styledEvents = useStyledEvents(events);
  const onSelect = (info: DateSelectArg) => {
    onPeriodChange(info.start, info.end, info.allDay);
  };

  const onEventClick = (arg: EventClickArg) => {
    onEventSelect(arg.event.id);
  };

  const onEventChange = (arg: EventChangeArg) => {
    onEventSelect(arg.event.id);
    if (arg.event.start && arg.event.end) {
      onPeriodChange(arg.event.start, arg.event.end, arg.event.allDay);
    }
  };

  const onNavLinkDayClick = (date: Date, event: FullCalendarVDom.VUIEvent) => {
    event.stopPropagation();

    const api = ref.current?.getApi();
    if (!api) {
      return;
    }

    api.changeView(CalendarViewType.DAY);
    api.gotoDate(date);
  };

  const workMin = schedulerSettings.businessHours
    ?.map(item => item.startTime)
    .sort()
    .shift();
  const workMax = schedulerSettings.businessHours
    ?.map(item => item.endTime)
    .sort()
    .pop();
  const workDays = Array.from(new Set(schedulerSettings.businessHours?.flatMap(item => item.daysOfWeek)));
  const hideDays = schedulerSettings.businessHours
    ? Array.from(Array(7).keys()).filter(day => !workDays.includes(day))
    : null;

  const calendarProps = {
    height: 'auto',
    themeSystem: 'bootstrap',
    ref: ref,
    locale: locale,
    slotDuration: { minutes: getDurationInMinutes(slotDuration) },
    initialView: isSmallScreen ? CalendarViewType.DAY : CalendarViewType.WEEK,
    select: onSelect,
    eventClick: onEventClick,
    eventChange: onEventChange,
    editable: !isDisabled,
    headerToolbar: {
      start: '',
      end: 'title',
    },
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    events: styledEvents,
    navLinkDayClick: onNavLinkDayClick,
    selectable: true,
    selectOverlap: true,
    selectMirror: true,
    navLinks: true,
    stickyHeaderDates: true,
    nowIndicator: true,
  };

  if (schedulerSettings.businessHours) {
    calendarProps['slotMinTime'] = workMin;
    calendarProps['slotMaxTime'] = workMax;
    calendarProps['hiddenDays'] = hideDays;
  }

  if (!roomId) {
    return <CreateARoomLink />;
  }

  return (
    <div className={className}>
      {ref?.current && (
        <ToolBar api={ref.current?.getApi()} onCurrentDateChange={onCurrentDateChange} isSmallScreen={isSmallScreen} />
      )}
      <EventFilter />
      <FullCalendar {...calendarProps} />
    </div>
  );
};

const CreateARoomLink: React.VFC = () => {
  const intl = useIntl();
  const { getMySystem, getMyLocation } = useSystems();
  const { id: systemId } = getMySystem();
  const { id: locationId } = getMyLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Alert color='info' className='d-flex justify-content-between align-items-center'>
        <T id={messages.chooseRoom.id}>{intl.formatMessage(messages.chooseRoom)}</T>
        <Button className='btn-link' style={{ color: 'inherit' }} onClick={onOpen}>
          <T id={messages.createRoom.id}>{intl.formatMessage(messages.createRoom)}</T>
        </Button>
      </Alert>
      {isOpen && <AddRoomModal systemId={systemId} locationId={locationId} onClose={onClose} />}
    </>
  );
};

export enum EventType {
  IN_PERSON = 'inPerson',
  TELEHEALTH = 'telehealth',
  PERSONAL_APPOINTMENT = 'personalAppointment',
}

const useStyledEvents = (events: Array<EventInput>): Array<EventInput> => {
  const telehealthScheduledVisitStyles = {
    backgroundColor: EventColor.telehealth,
    borderColor: EventColor.telehealth,
  };

  const roomEventStyles = {
    backgroundColor: EventColor.personalAppointment,
    borderColor: EventColor.personalAppointment,
  };

  const inPersonScheduledVisitStyles = {
    backgroundColor: EventColor.inPerson,
    borderColor: EventColor.inPerson,
  };

  const styledEvents = events.map(event => {
    if (event.extendedProps?.isScheduledVisit) {
      if (event?.extendedProps?.scheduledVisit?.registrationData?.telehealth) {
        return {
          ...event,
          ...telehealthScheduledVisitStyles,
        };
      }

      return {
        ...event,
        ...inPersonScheduledVisitStyles,
      };
    }

    return {
      ...event,
      ...roomEventStyles,
    };
  });

  return styledEvents;
};

interface ToolBarProps {
  api: CalendarApi;
  isDisabled?: boolean;
  isSmallScreen?: boolean;
  onCurrentDateChange: (current: Date) => void;
}

const ToolBar: React.FC<ToolBarProps> = props => {
  const { api, isDisabled, onCurrentDateChange, isSmallScreen } = props;
  const intl = useIntl();

  const [view, setView] = React.useState<string>(api.view.type);

  const onPrev = () => {
    api.prev();

    const currentDate = api.getDate();
    onCurrentDateChange(currentDate);
  };

  const onNext = () => {
    api.next();

    const currentDate = api.getDate();
    onCurrentDateChange(currentDate);
  };

  const onToday = () => {
    api.today();

    const currentDate = api.getDate();
    onCurrentDateChange(currentDate);
  };

  const onDailyView = () => {
    setView(CalendarViewType.DAY);
    api.changeView(CalendarViewType.DAY);
  };

  const onWeeklyView = () => {
    setView(CalendarViewType.WEEK);
    api.changeView(CalendarViewType.WEEK);
  };

  const onMonthlyView = () => {
    setView(CalendarViewType.MONTH);
    api.changeView(CalendarViewType.MONTH);
  };

  const onListView = () => {
    setView(CalendarViewType.LIST);
    api.changeView(CalendarViewType.LIST);
  };

  const size = isSmallScreen ? 'sm' : 'md';
  return (
    <div className='d-flex justify-content-between'>
      <div className='text-nowrap'>
        <ButtonGroup className='mr-2'>
          <Button
            color='primary'
            onClick={onMonthlyView}
            active={view === CalendarViewType.MONTH}
            disabled={isDisabled}
            size={size}
          >
            <MonthlyViewIcon />
          </Button>
          <Button
            color='primary'
            onClick={onWeeklyView}
            active={view === CalendarViewType.WEEK}
            disabled={isDisabled}
            size={size}
          >
            <WeeklyViewIcon />
          </Button>
          <Button
            color='primary'
            onClick={onDailyView}
            active={view === CalendarViewType.DAY}
            disabled={isDisabled}
            size={size}
          >
            <DailyViewIcon />
          </Button>

          <Button
            color='primary'
            onClick={onListView}
            active={view === CalendarViewType.LIST}
            disabled={isDisabled}
            size={size}
          >
            <ListViewIcon />
          </Button>
        </ButtonGroup>

        <ButtonGroup>
          <Button color='primary' onClick={onPrev} disabled={isDisabled} size={size}>
            <PrevIcon />
          </Button>
          <Button color='primary' onClick={onToday} disabled={isDisabled} size={size}>
            <T id={messages.today.id}>{intl.formatMessage(messages.today)}</T>
          </Button>
          <Button color='primary' onClick={onNext} disabled={isDisabled} size={size}>
            <NextIcon />
          </Button>
        </ButtonGroup>
      </div>
      <SchedulerSettings isDisabled={isDisabled} isSmallScreen={isSmallScreen} />
    </div>
  );
};

interface SchedulerSettingsProps {
  isDisabled?: boolean;
  isSmallScreen?: boolean;
}
const SchedulerSettings: React.FC<SchedulerSettingsProps> = props => {
  const { isDisabled, isSmallScreen } = props;

  const intl = useIntl();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        size={isSmallScreen ? 'sm' : 'md'}
        onClick={onOpen}
        disabled={isDisabled}
        className='btn-ghost-secondary d-flex align-items-center'
      >
        <SettingIcon className='mr-1 h5 m-0' />
        <T id={messages.settings.id} className='h6 m-0'>
          {intl.formatMessage(messages.settings)}
        </T>
      </Button>

      {isOpen && <UpdateSchedulerSettingsModal onClose={onClose} onCompleted={onClose} />}
    </>
  );
};
