import { gql } from '@apollo/client';
import { FullPatient, FullStudyOrder } from 'utils/GQLFragments';
import { FullTranslatableText, UserSignature } from './common';

export const BasicStudyTemplate = gql`
  fragment BasicStudyTemplate on StudyTemplate {
    id
    name {
      ...FullTranslatableText
    }
    description {
      ...FullTranslatableText
    }
    method {
      ...FullTranslatableText
    }
    tags {
      ...FullTranslatableText
    }
    created {
      ...UserSignature
    }
    updated {
      ...UserSignature
    }
  }
  ${FullTranslatableText}
  ${UserSignature}
`;

export const FullStudyTemplate = gql`
  fragment FullStudyTemplate on StudyTemplate {
    ...BasicStudyTemplate
    items {
      id
      name {
        text
      }
      type
      unit {
        text
      }
      result {
        reference {
          ... on StudyNumericalReference {
            maximum
            minimum
          }
          ... on StudyCategoricalReference {
            possibleOutcomes {
              text
            }
          }
          ... on StudyCategoricalBasedOnNumberReference {
            possibleOutcomesWithRange {
              outcome {
                text
              }
              range {
                maximum
                minimum
              }
            }
          }
        }
      }
    }
  }
  ${BasicStudyTemplate}
`;

export const BasicStudy = gql`
  fragment BasicStudy on Study {
    id
    patient {
      ...FullPatient
    }
    name {
      text
    }
    description {
      text
    }
    visitId
    templateId
    method {
      text
    }
    location
    completed {
      ...UserSignature
    }
    note
    updated {
      ...UserSignature
    }
  }
  ${UserSignature}
  ${FullPatient}
`;

export const FullStudyItem = gql`
  fragment FullStudyItem on StudyItem {
    id
    templateItemId
    name {
      text
    }
    type
    unit {
      text
    }
    result {
      reference {
        ... on StudyNumericalReference {
          maximum
          minimum
        }
        ... on StudyCategoricalReference {
          possibleOutcomes {
            text
          }
        }
        ... on StudyCategoricalBasedOnNumberReference {
          possibleOutcomesWithRange {
            outcome {
              text
            }
            range {
              maximum
              minimum
            }
          }
        }
      }
      value {
        ... on StudyNumericalResult {
          number
        }
        ... on StudyCategoricalResult {
          outcome {
            text
          }
        }
        ... on StudyCategoricalBasedOnNumberResult {
          outcome {
            text
          }
          number
        }
      }
    }
  }
`;

export const FullStudy = gql`
  fragment FullStudy on Study {
    ...BasicStudy
    files
    created {
      ...UserSignature
    }
    items {
      ...FullStudyItem
    }
  }
  ${BasicStudy}
  ${FullStudyItem}
`;

export const FullStudyWithOrder = gql`
  fragment FullStudyWithOrder on Study {
    ...FullStudy
    isActive
    order {
      ...FullStudyOrder
    }
  }
  ${FullStudy}
  ${FullStudyOrder}
`;

export const BasicStudyWithOrder = gql`
  fragment BasicStudyWithOrder on Study {
    ...BasicStudy
    isActive
    order {
      ...FullStudyOrder
    }
  }
  ${BasicStudy}
  ${FullStudyOrder}
`;
