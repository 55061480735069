import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { eventId: null }
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render () {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <div className='alert alert-danger'>
          <p>We're sorry — something's gone wrong.</p>
          <p>Our team has been notified, but click here fill out a report.</p>
          <button className='btn btn-ghost-primary' onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
        </div>
      )
    }

    // when there's not an error, render children untouched
    return this.props.children
  }
}

export default ErrorBoundary
