
import { useState } from 'react'
import { useQuickpicksWithDrug } from 'hooks'
import { graphQLErrorFormatter } from 'utils/errors'

const ITEMS_PER_PAGE = 14
export const useQuickpickWithDrugSearchActions = () => {
  const [error, setError] = useState()

  const errorFormatter = errorCode => {
    switch (errorCode) {
      default:
        return { id: '' }
    }
  }

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter)
    setError(translatedErrors)
  }

  const onCompleted = () => {
    setError()
  }

  const initialFilter = {
    q: '',
    page: 0
  }

  const [filter, setFilter] = useState(initialFilter)

  const onSearch = _q => {
    if (_q !== filter.q) {
      setFilter(prev => ({ ...prev, q: _q, page: 0 }))
    }
  }

  const onPageChange = page => {
    setFilter(prev => ({ ...prev, page }))
  }

  const { quickpicks, count, loading, refetch } = useQuickpicksWithDrug({
    q: filter.q,
    offset: filter.page * ITEMS_PER_PAGE,
    limit: ITEMS_PER_PAGE
  }, {
    onError,
    onCompleted,
    fetchPolicy: 'cache-and-network'
  })

  return {
    quickpicks,
    count,
    loading,
    onSearch,
    onPageChange,
    filter,
    error,
    refetch,
    ITEMS_PER_PAGE
  }
}
