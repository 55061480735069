import React from 'react';
import { Nullable } from 'components-ts/utils';
import { useBPJSApiToken } from './useBPJSApiToken';

type BPJSTokenContextValue = {
  getToken: () => Promise<string>;
};

const BPJSTokenContext = React.createContext<Nullable<BPJSTokenContextValue>>(null);
export const BPJSTokenContextProvider: React.FC = (props) => {
  const [token, setToken] = React.useState<Nullable<string>>(null);
  const [tokenDate, setTokenDate] = React.useState<Date>(new Date());

  const { getToken: _getToken } = useBPJSApiToken();

  const getToken = async () => {
    const now = new Date();

    const nowJakartaDate = new Intl.DateTimeFormat('en-US', {
      timeZone: 'Asia/Jakarta',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(now);

    const tokenJakartaDate = new Intl.DateTimeFormat('en-US', {
      timeZone: 'Asia/Jakarta',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(tokenDate);

    // Check if the current Jakarta time is greater than or equal to 23:59
    const isTokenExpired = !token || (Boolean(token) && nowJakartaDate !== tokenJakartaDate);

    if (isTokenExpired) {
      const newToken = await _getToken();

      setToken(newToken);
      setTokenDate(now);
      return newToken;
    }

    return token;
  };

  const value: BPJSTokenContextValue = {
    getToken,
  };

  return <BPJSTokenContext.Provider value={value}>{props.children}</BPJSTokenContext.Provider>;
};

export const useBPJSTokenContext = () => {
  const value = React.useContext(BPJSTokenContext);

  validateContextValue(value);

  return value;
};

function validateContextValue(value: Nullable<BPJSTokenContextValue>): asserts value is BPJSTokenContextValue {
  if (value === null) {
    throw TypeError('Using bpjs token context out of the provider');
  }
}
