import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_COMMODITIES_REPORTS } from 'scenes/Reports/requests';
import { graphQLErrorFormatter } from 'utils/errors';
import { BillableCategory } from 'components-ts/Billing/utils';
import { VisitType } from 'api/interfaces';

export interface CommoditiesReportFilter {
  from: string;
  to: string;
  location: Array<string>;
  visitType: Array<VisitType>;
  doctor: Array<string>;
  category: Array<BillableCategory>;
}

interface CommoditiesReportParams {
  filter: CommoditiesReportFilter;
}

export interface Commodity {
  id: string;
  name: string;
  category: BillableCategory;
  price: number;
  units: number;
  total: number;
  visitType: string;
  doctor: string;
  date: string;
  location: string;
  patientName: string;
}

interface CommoditiesReport {
  isLoading: boolean;
  error: any;
  items: Array<Commodity>;
}

export const useCommoditiesReport = (params: CommoditiesReportParams): CommoditiesReport => {
  const [error, setError] = React.useState(null);
  const [items, setItems] = React.useState<Array<Commodity>>([]);

  const errorFormatter = (errorCode) => {
    switch (errorCode) {
      case 'invalid_date_range':
      case 'invalid_doctor':
      case 'invalid_location':
      case 'invalid_category':
      case 'invalid_visit_type':
      default:
        return {};
    }
  };

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter);
    setError(translatedErrors);
  };

  const onCompleted = (data) => {
    setError(null);

    const { commoditiesReport } = data;

    if (Array.isArray(commoditiesReport)) {
      setItems(commoditiesReport as Array<Commodity>);
    } else {
      setItems([]);
    }
  };

  const { loading } = useQuery(GET_COMMODITIES_REPORTS, {
    variables: {
      ...params.filter,
    },
    onCompleted,
    onError,
  });

  return {
    items,
    error,
    isLoading: loading,
  };
};
