import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDisclosure } from 'components-ts/useDisclosure';
import {
  FullStudyWithOrder,
  StudyCategoricalBasedOnNumberResult,
  StudyCategoricalResult,
  StudyNumericalResult,
  StudyResultType,
} from 'api/interfaces';
import { FiBarChart2 as HistoryIcon } from 'react-icons/fi';
import { useDynamicTranslation } from 'components-ts/i18n';
import Select from 'react-select';
import { ReactSelectInternalSingleValue } from 'components-ts/Selectors';
import { Nullable } from 'components-ts/utils';
import { FormattedValue, useMutatorsAPI } from 'components-ts/Mutators';
import { ExportButton } from 'components-ts/Reports';
import DefaultTable from 'components/DefaultTable';
import { usePatientStudyHistory } from './usePatientStudyHistory';

const messages = defineMessages({
  selector: {
    id: 'StudyHistory.study_selector',
    defaultMessage: 'Select a study',
  },
  emptySelection: {
    id: 'StudyHistory.empty_selection_message',
    defaultMessage: 'You have to select a study type',
  },
  title: {
    id: 'StudyHistory.lab_trend',
    defaultMessage: 'Study trends',
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close',
  },
  date: {
    id: 'UI.label_date',
    defaultMessage: 'Date',
  },
});

interface StudyHistoryProps {
  patientId: string;
}

export const StudyHistory: React.FC<StudyHistoryProps> = (props) => {
  const { patientId } = props;

  const intl = useIntl();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { docs, count } = usePatientStudyHistory({
    id: patientId,
  });

  if (count === 0) {
    return null;
  }

  return (
    <>
      <Button
        onClick={onOpen}
        color="primary"
        className="d-flex align-items-center"
        style={{ fontWeight: 'bold', backgroundColor: 'MediumSlateBlue', borderColor: 'MediumSlateBlue' }}
      >
        <HistoryIcon className="mr-1" style={{ fontWeight: 'bold' }} />
        <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
      </Button>
      <StudyHistoryModal isOpen={isOpen} onClose={onClose} count={count} studies={docs} />
    </>
  );
};

interface StudyHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  count: number;
  studies: Array<FullStudyWithOrder>;
}

const StudyHistoryModal: React.FC<StudyHistoryModalProps> = (props) => {
  const { isOpen, onClose, count, studies } = props;

  const intl = useIntl();

  const [selectedStudy, setSelectedStudy] = React.useState<Nullable<string>>(null);
  const onChange = (value: Nullable<string>) => {
    setSelectedStudy(value);
  };

  const filteredItems = studies.filter((study) => study.templateId === selectedStudy);
  return (
    <Modal className="modal-lg" isOpen={isOpen} toggle={onClose} backdrop>
      <ModalHeader toggle={onClose}>
        <T id={messages.title.id}>{intl.formatMessage(messages.title, { count })}</T>
      </ModalHeader>
      <ModalBody>
        <div className="col-md-6 mx-0">
          <StudyTypeSelector studies={studies} onChange={onChange} />
        </div>
        {filteredItems.length > 0 ? (
          <StudyHistoryTable studies={filteredItems} />
        ) : (
          <div className="my-4 d-flex justify-content-center">
            <T id={messages.emptySelection.id}>{intl.formatMessage(messages.emptySelection)}</T>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" className="mr-1" onClick={onClose}>
          <T id={messages.closeButton.id}>{intl.formatMessage(messages.closeButton)}</T>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

interface StudyTypeSelectorProps {
  studies: Array<FullStudyWithOrder>;
  defaultValue?: string;
  onChange: (selected: Nullable<string>) => void;
}

const StudyTypeSelector: React.FC<StudyTypeSelectorProps> = (props) => {
  const { studies } = props;

  const intl = useIntl();
  const { translateDynamic } = useDynamicTranslation();

  const optionsMap: Map<string, ReactSelectInternalSingleValue> = studies.reduce((options, study) => {
    if (!options.has(study.templateId)) {
      options.set(study.templateId, {
        value: study.templateId,
        label: translateDynamic(study.name.text ?? '-'),
      });
    }

    return options;
  }, new Map());

  const options = Array.from(optionsMap.values());

  const onChange = (v: ReactSelectInternalSingleValue): void => {
    props.onChange(v?.value ?? null);
  };

  return (
    <FormGroup>
      <Label>
        <T id={messages.selector.id}>{intl.formatMessage(messages.selector)}</T>
      </Label>
      <Select onChange={onChange} options={options} isClearable={true} isSearchable blurInputOnSelect />
    </FormGroup>
  );
};

/**
 * Csv headers
 */
type Headers = Map<string, string>;
const useHeaders = (study: FullStudyWithOrder): Headers => {
  const intl = useIntl();
  const { translateDynamic } = useDynamicTranslation();

  const { items } = study;

  const initialValue = new Map();
  initialValue.set('date', intl.formatMessage(messages.date));

  const headers = items.reduce((headers, item) => {
    const { templateItemId, name } = item;

    if (!name.text) {
      return headers;
    }

    headers.set(templateItemId, translateDynamic(name.text));

    return headers;
  }, initialValue);

  return headers;
};

/**
 * Columns are generated using the names of the items
 */
const useMemorizedColumnFromItems = (study: FullStudyWithOrder) => {
  const intl = useIntl();
  const { translateDynamic } = useDynamicTranslation();

  const { items } = study;
  return React.useMemo(() => {
    // react-table columns
    const rtColumns = items
      .filter((item) => item.name?.text)
      .map((item) => {
        const { templateItemId, name, type } = item;

        /**
         * Cell formatter
         */
        const Cell = (rtCell) => {
          const {
            cell: { value },
          } = rtCell;

          switch (type) {
            case StudyResultType.NUMERICAL: {
              if (typeof value === 'number') {
                return intl.formatNumber(value);
              }

              return value ?? '';
            }

            case StudyResultType.CATEGORICAL:
            case StudyResultType.CATEGORICAL_BASED_ON_NUMBER:
            default:
              return value ?? '';
          }
        };

        return {
          Header: translateDynamic(name.text ?? ''),
          accessor: templateItemId,
          Cell,
        };
      });

    return [
      {
        Header: intl.formatMessage(messages.date),
        accessor: 'date',
      },
      ...rtColumns,
    ];
  }, [intl, translateDynamic, items]);
};

interface StudyHistoryTableProps {
  studies: Array<FullStudyWithOrder>;
}

const StudyHistoryTable: React.FC<StudyHistoryTableProps> = (props) => {
  const { studies } = props;

  const { data, headers, columns } = useStudyMutators(studies);

  const { translateDynamic } = useDynamicTranslation();

  const { name } = studies[0];
  const csvDataRef = React.useRef(null);
  const csvFilename = `${translateDynamic(name.text ?? '')}.csv`;

  const header = Object.fromEntries(headers);
  return (
    <>
      <section className={'scrollable position-relative'}>
        <DefaultTable columns={columns} data={data} rowsRef={csvDataRef} />
      </section>
      <div className="d-flex justify-content-start">
        {ExportButton<typeof header>({
          filename: csvFilename,
          header,
          csvDataRef: csvDataRef,
        })}
      </div>
    </>
  );
};

const useStudyMutators = (studies: Array<FullStudyWithOrder>) => {
  const intl = useIntl();
  const { translateDynamic } = useDynamicTranslation();

  const { applyMutators } = useMutatorsAPI();
  const columns = useMemorizedColumnFromItems(studies[0]);
  const headers = useHeaders(studies[0]);

  const mutators = React.useMemo(() => {
    const initialValue = {};

    const mutators = Array.from(headers.entries()).reduce((mutators, [itemId]) => {
      const mutatorFn = (study: FullStudyWithOrder): FormattedValue => {
        if (itemId === 'date' && study.completed?.timestamp) {
          return intl.formatDate(new Date(study.completed.timestamp));
        }

        const item = study.items.find((item) => item.templateItemId === itemId);
        if (!item) {
          return ' ';
        }

        const { type, result } = item;
        if (result.value === null) {
          return ' ';
        }

        switch (type) {
          case StudyResultType.NUMERICAL: {
            const value = result.value as StudyNumericalResult;
            return intl.formatNumber(value.number);
          }

          case StudyResultType.CATEGORICAL: {
            const value = result.value as StudyCategoricalResult;
            return translateDynamic(value.outcome.text ?? '') ?? ' ';
          }

          case StudyResultType.CATEGORICAL_BASED_ON_NUMBER: {
            const value = result.value as StudyCategoricalBasedOnNumberResult;
            const translated = translateDynamic(value.outcome.text ?? '') ?? ' ';
            return `${value.number} (${translated})`;
          }

          default:
            return ' ';
        }
      };

      return {
        ...mutators,
        [itemId]: mutatorFn,
      };
    }, initialValue);

    return mutators;
  }, [intl, translateDynamic, headers]);

  const data = React.useMemo(() => applyMutators({ items: studies, mutators }), [studies, applyMutators, mutators]);
  return {
    data,
    columns,
    headers,
  };
};
