import React from 'react';
import { Footer, FooterProps } from './Footer';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useSubscribeToChecklistBatch } from 'components-ts/HealthSystems/useChecklistTagsSubscriptions';
import { useSystems } from 'components-ts/HealthSystems';
import { defineMessages, useIntl } from 'react-intl';
import { IdTagTranslatedSubscriptionForm } from 'components-ts/HealthSystems/IdTagTranslatedSubscriptionForm';
import { useTranslatedChecklists } from 'components-ts/HealthSystems/useTranslatedChecklists';

const messages = defineMessages({
  title: { id: 'ChecklistsFirstStep.title', defaultMessage: 'Checklists' },
  tags: { id: 'ChecklistsFirstStep.tags_title', defaultMessage: 'Tags' },
  description: {
    id: 'ChecklistsFirstStep.description',
    defaultMessage:
      'Let’s choose the checklists that your staff will use to chart, order studies, prescribe and administer medicines, etc...This will depend on your health facility type. For outpatient clinics, we suggest you select the "Outpatient" tag. For dental clinics we suggest you choose the "Dental" tag. For hospital wards, we suggest you choose the "Hospital" tag.',
  },
  onlySelectedLabel: {
    id: 'UI.show_only_selected',
    defaultMessage: 'Only selected',
  },
});

type ChecklistsStepProps = {
  footerProps: FooterProps;
};

interface UseStepActionParams {
  footerProps: FooterProps;
}

const useStepAction = (params: UseStepActionParams) => {
  const { footerProps } = params;

  const { getMySystem, getMyLocation } = useSystems();
  const system = getMySystem();
  const location = getMyLocation();

  const { onSubmit, isLoading, error } = useSubscribeToChecklistBatch({
    systemId: system.id,
    locationId: location.id,
    onCompleted: footerProps.next,
  });

  const onNext = (selectedIds: Set<string>) => {
    if (selectedIds.size > 0) {
      onSubmit(Array.from(selectedIds));
    } else {
      footerProps.next();
    }
  };

  return {
    onNext,
    isSubmitting: isLoading,
    submittingError: error,
  };
};

export const ChecklistsStep: React.FC<ChecklistsStepProps> = (props) => {
  const { footerProps } = props;

  const intl = useIntl();

  const { getMyLocation } = useSystems();

  const location = getMyLocation();

  const { translatedChecklists, error: fetchError, isLoading } = useTranslatedChecklists();

  const { onNext, isSubmitting, submittingError } = useStepAction({ footerProps });

  const error = fetchError ?? submittingError;
  return (
    <>
      <div>
        <div className="mb-4">
          <h3>
            <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
          </h3>
          <p className="mt-2">
            <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
          </p>
        </div>
        <IdTagTranslatedSubscriptionForm
          onSubmit={(selectedIds) => onNext(new Set(selectedIds))}
          items={translatedChecklists}
          isLoading={isLoading}
          error={error}
          initialValues={location.subscriptions.checklists.items}
        >
          <Footer {...footerProps} next={() => false} isNextLoading={isSubmitting} isStepLoading={isLoading} />
        </IdTagTranslatedSubscriptionForm>
      </div>
    </>
  );
};
