import React from 'react';
import { FaInstagram as InstagramIcon, FaRegEnvelope as MailIcon } from 'react-icons/fa';
import { ReferenceLink } from './utils';

/**
 * should I move this to envs?
 */
const CONTACT = {
  instagram: 'walking_doctors',
  mail: 'hello@walkingdocs.com',
};

export const ContactUs: React.VFC = () => {
  return (
    <div>
      <ReferenceLink className="mb-1 h6 text-muted" url={`https://www.instagram.com/${CONTACT.instagram}`}>
        <InstagramIcon className="mr-2" />
        <span>{CONTACT.instagram}</span>
      </ReferenceLink>
      <ReferenceLink className="mb-1 h6 text-muted" url={`mailto:${CONTACT.mail}`}>
        <MailIcon className="mr-2" />
        <span>{CONTACT.mail}</span>
      </ReferenceLink>
    </div>
  );
};
