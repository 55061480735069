import store from 'store';

const IOSPlatforms = ['ipad simulator', 'iphone simulator', 'ipod simulator', 'ipad', 'iphone', 'ipod'];

// mobile detection
export const userAgent = navigator.userAgent.toLowerCase();
export const isAndroid = userAgent.indexOf('android') > -1;
export const isIOS =
  IOSPlatforms.includes(navigator.platform.toLowerCase()) || (userAgent.includes('mac') && 'ontouchend' in document); // iPad on iOS 13 detection

/**
 *
 * This is an abstraction to get multiple values from localStorage
 * It receives an array of keys and returns an object with
 * each key and it corresponding value saved on the stora
 *
 * @param {Array<string> | string} keys
 *
 */
type GetLocalStorageParams = string | Array<string>;
export const getLocalStorage = (keys: GetLocalStorageParams) => {
  if (Array.isArray(keys)) {
    return keys.map((key) => store.get(key));
  }
  return store.get(keys);
};

type SetLocalStorageParams = {
  [key: string]: string;
};
export const setLocalStorage = (dataObj: SetLocalStorageParams) => {
  Object.entries(dataObj).forEach(([key, value]) => {
    store.set(key, value);
  });
};

type RemoveLocalStorageParams = string | Array<string>;
export const removeLocalStorage = (keys: RemoveLocalStorageParams) => {
  if (Array.isArray(keys)) {
    keys.forEach((key) => store.remove(key));
  } else {
    store.remove(keys);
  }
};
