import { MutationHookOptions, useMutation } from '@apollo/client';
import { FullPatientWithActiveVisit, NewPatientPaymentInfo } from 'api/interfaces';
import { Nullable, extractFirstErrorCode } from 'components-ts/utils';
import { useState } from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { UPDATE_PAYMENT_INFO } from 'api/request/patients';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  errorUpdatingPaymentInfo: {
    id: 'PersonalInfoCard.error_saving_payment_info',
    defaultMessage: 'Could not save the payment info. Please, try again',
  },
  errorDefaultPaymentMethod: {
    id: 'PersonalInfoCard.invalid_default_payment_method',
    defaultMessage: 'Invalid default payment method',
  },
  nikValidationError: {
    id: 'Validations.indonesia_nik_16_length',
    defaultMessage: 'NIK must have 16 digits',
  },
});

interface UsePatientUpdatePaymentSettingsData {
  updatePatientPaymentInfo: FullPatientWithActiveVisit;
}

interface UsePatientUpdatePaymentSettingsVariables {
  id: string;
  newPatientPaymentInfo: NewPatientPaymentInfo;
}

type UsePatientUpdatePaymentSettingsParams = MutationHookOptions<
  UsePatientUpdatePaymentSettingsData,
  UsePatientUpdatePaymentSettingsVariables
> & { patientId: string; country?: string };

export const usePatientUpdatePaymentSettings = (params: UsePatientUpdatePaymentSettingsParams) => {
  const { patientId, country, ...rest } = params;

  const intl = useIntl();

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (errors) => {
    const errorCode = extractFirstErrorCode(errors);

    switch (errorCode) {
      case 'error_saving_payment_info':
        return setError(messages.errorUpdatingPaymentInfo);
      case 'invalid_default_payment_method':
        return setError(messages.errorDefaultPaymentMethod);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = () => {
    setError(null);
  };

  const [updatePaymentInfo, { loading }] = useMutation<
    UsePatientUpdatePaymentSettingsData,
    UsePatientUpdatePaymentSettingsVariables
  >(UPDATE_PAYMENT_INFO, { ...rest, onError, onCompleted });

  const onSubmit = (values: NewPatientPaymentInfo) => {
    updatePaymentInfo({
      variables: {
        id: patientId,
        newPatientPaymentInfo: values,
      },
    });
  };

  const validate: ValidateFn<NewPatientPaymentInfo> = (values) => {
    const errors: ValidationError<NewPatientPaymentInfo> = {};

    if (values.national_id) {
      if (country === 'ID') {
        if (!values.national_id.match(/^\d{16}$/g)) {
          errors.national_id = intl.formatMessage(messages.nikValidationError);
        }
      }
    }

    return errors;
  };

  return {
    onSubmit,
    validate,
    error,
    isLoading: loading,
  };
};
