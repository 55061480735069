import { useSession } from 'components-ts/Auth';
import { AppScenes, getRolesByScene, getRolesByRoute } from './sitemap';

/**
 * This hook provide us with utilities to manage the
 * router mounting based on the user permissions
 */
export const useSitemapAccess = () => {
  const { userHasRole } = useSession();

  const canUserAccessToScene = (scene: AppScenes) => {
    const roles = getRolesByScene(scene);
    const hasAnyRole = roles.some((role) => userHasRole(role));

    return hasAnyRole;
  };

  const canUserAccessToRoute = (scene: AppScenes, route, systemId?: string, locationId?: string) => {
    const roles = getRolesByRoute(scene, route);

    if (!Array.isArray(roles)) return false;

    const hasAnyRole = roles.some((role) => userHasRole(role, systemId, locationId));
    return hasAnyRole;
  };

  return {
    canUserAccessToScene,
    canUserAccessToRoute,
  };
};
