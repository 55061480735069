import { EventInput } from '@fullcalendar/common';
import { CalendarSlotUnit, EventRecurrence, RoomEvent, ScheduledVisit, SchedulerSettings } from 'api/interfaces';
import { getPatientName } from '../Patients';
import { useIntl } from 'react-intl';
import { addMinutes, calcDateDiffInMinutes, isValidDate, substractMinutes } from 'components-ts/DateAndTime';

export const getTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export enum CalendarViewType {
  LIST = 'listWeek',
  DAY = 'timeGridDay',
  WEEK = 'timeGridWeek',
  MONTH = 'dayGridMonth',
}

export enum CalendarNavigationOptions {
  TODAY = 'TODAY',
  PREV = 'PREV',
  NEXT = 'NEXT',
}

export const isAValidView = (viewType: string) =>
  Object.values(CalendarViewType).includes(viewType as CalendarViewType);

export const isValidSlotUnit = (unit: string) => Object.values(CalendarSlotUnit).includes(unit as CalendarSlotUnit);

export const isValidEventRecurrence = (recurrence: string) =>
  Object.values(EventRecurrence).includes(recurrence as EventRecurrence);

export const getSlotDurationFromSettings = (settings: SchedulerSettings) => {
  if (!settings) {
    return { minutes: 30 };
  }

  const { slot } = settings;
  switch (slot.unit) {
    case CalendarSlotUnit.DAYS:
      return { days: slot.length };

    case CalendarSlotUnit.HOURS:
      return { hours: slot.length };

    case CalendarSlotUnit.MINUTES:
      return { minutes: slot.length };

    default:
      return { minutes: 30 };
  }
};

/**
 * Validate that both contained strings are valid dates
 */
const isValidDateTimeSlot = (current: InternalNormalizedAvailableSlot): boolean => {
  return isValidDate(current.start) && isValidDate(current.end);
};

const parseSlotToDateTimeSlot = (slot: AvailableSlot): InternalNormalizedAvailableSlot => {
  return {
    start: new Date(slot.start),
    end: new Date(slot.end),
  };
};

/**
 * Source start & end are valid moment dates
 */
const divideIntoNormalizedSlots = (
  source: InternalNormalizedAvailableSlot,
  slotDurationInMinutes: number,
  useEndValue?: boolean
): Array<Date> => {
  // calculate how many available slots are between these dates
  const diff = calcDateDiffInMinutes(new Date(source.end), new Date(source.start));
  const count = Math.ceil(diff / slotDurationInMinutes);

  if (count === 0) {
    return [];
  }

  return Array.from({ length: count }).map((_, index) => {
    // in case I need the end value of the slot for end time selector
    if (useEndValue) {
      // fix 00:00 time to show 23:59
      const time = addMinutes(new Date(source.start), (index + 1) * slotDurationInMinutes);

      if (time.getHours() === 0) {
        return substractMinutes(time, 1);
      }

      return time;
    }

    return addMinutes(new Date(source.start), index * slotDurationInMinutes);
  });
};

/**
 * Take an array of AvailableSlot [start, end] and return
 * a normalized one in which all the items have the same duration
 */
export interface AvailableSlot {
  start: string;
  end: string;
}

interface InternalNormalizedAvailableSlot {
  start: Date;
  end: Date;
}

export interface NormalizedAvailableSlot {
  start: Date;
  end: Date;
}

export const normalizeSlots = (slots: Array<AvailableSlot>, slotDurationInMinutes: number): Array<Date> => {
  const normalizedSlots = slots.reduce((normalized, current) => {
    const datetimeSlot = parseSlotToDateTimeSlot(current);

    if (!isValidDateTimeSlot(datetimeSlot)) {
      return normalized as Array<Date>;
    }

    const subSlots = divideIntoNormalizedSlots(datetimeSlot, slotDurationInMinutes);

    return [...normalized, ...subSlots] as Array<Date>;
  }, [] as Array<Date>);

  return normalizedSlots;
};

/**
 * End time selector needs a filtered and formatted version of the available slots.
 * This should be after the start time selection and before the next occupied slot
 */
export const formatNormalizedSlotsForEndTimeSelector = (
  slots: Array<AvailableSlot>,
  slotDurationInMinutes: number,
  selectedStart?: Date
): Array<Date> => {
  if (!selectedStart) {
    return normalizeSlots(slots, slotDurationInMinutes);
  }

  // find the container
  const container = slots.find((interval) => {
    if (!selectedStart) {
      return false;
    }

    const selected = new Date(selectedStart);

    return (
      selected.getTime() >= new Date(interval.start).getTime() && selected.getTime() <= new Date(interval.end).getTime()
    );
  });

  if (!container) {
    return [];
  }

  const source = {
    start: selectedStart,
    end: new Date(container.end),
  };

  // true as 3rd params means that I want the end value of the normalized slots
  return divideIntoNormalizedSlots(source, slotDurationInMinutes, true);
};

/**
 * Event parsers
 */
export const scheduledVisitToCalendarEvent = (event: ScheduledVisit): EventInput => {
  const { id, registrationData } = event;

  const { patient, type, end, start } = registrationData;
  const patientName = getPatientName(patient);

  return {
    id,
    title: patientName,
    description: type,
    allDay: false,
    resourceEditable: false,
    start: new Date(start),
    end: new Date(end),
    extendedProps: {
      isScheduledVisit: true,
      scheduledVisit: {
        id,
        registrationData,
      },
    },
  };
};

export const getDurationInMinutes = (duration: { days?: number; hours?: number; minutes?: number }) => {
  const { days, hours, minutes } = duration;

  const res = (days ?? 0) * 24 * 60 + (hours ?? 0) * 60 + (minutes ?? 0);

  return res;
};

export const useSchedulerUtils = () => {
  const intl = useIntl();

  const roomEventToCalendarEvent = (event: RoomEvent): EventInput => {
    const { id, name, description, recurrence, start, end, allDay } = event;

    const baseEvent = {
      id,
      title: name,
      description,
      allDay: allDay ?? undefined,
      resourceEditable: false,
      extendedProps: {
        isRoomEvent: true,
        originalEvent: event,
      },
    };

    switch (recurrence) {
      case EventRecurrence.UNIQUE:
        return {
          ...baseEvent,
          start: new Date(start),
          end: new Date(end),
        };

      case EventRecurrence.DAILY:
        return {
          ...baseEvent,
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          groupId: `daily-${id}`,
          startTime: intl.formatDate(start, { timeStyle: 'medium' }),
          endTime: intl.formatDate(end, { timeStyle: 'medium' }),
        };

      case EventRecurrence.WEEKLY:
        return {
          ...baseEvent,
          daysOfWeek: [new Date(start).getDay()],
          groupId: `weekly-${id}`,
          startTime: intl.formatDate(start, { timeStyle: 'medium' }),
          endTime: intl.formatDate(end, { timeStyle: 'medium' }),
        };

      default:
        return {
          ...baseEvent,
          start: new Date(start),
          end: new Date(end),
        };
    }
  };

  return {
    roomEventToCalendarEvent,
  };
};

export const calendarEventToRoomEvent = (event: EventInput): RoomEvent => {
  return event?.extendedProps?.originalEvent;
};

export const calendarEventToScheduledVisit = (event: EventInput): ScheduledVisit => {
  return event?.extendedProps?.scheduledVisit;
};
