import { useMySystemSettings } from 'components-ts/HealthSystems';
import { useStudyTemplates, UseStudyTemplatesParams } from './useStudyTemplates';

export const useLabSubscriptions = (params?: UseStudyTemplatesParams) => {
  const { subscriptions } = useMySystemSettings();

  const ids = subscriptions.lab.items;
  const updated = subscriptions.lab.updated;

  const { isLoading, count, studies, error } = useStudyTemplates({ ...params, ids });

  return {
    isLoading,
    count,
    studies,
    updated,
    error,
  };
};
