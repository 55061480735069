import React from 'react';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { FullStudy } from 'api/interfaces';
import { ADD_FILE_TO_STUDY, ADD_NOTE_TO_STUDY, COMPLETE_STUDY } from 'api/request/lab';

const messages = defineMessages({
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Please reload',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  errorAddingNote: {
    id: 'StudyEditor.error_adding_note',
    defaultMessage: 'We could not add the note. Try again',
  },
  errorAddingFile: {
    id: 'StudyEditor.error_adding_file',
    defaultMessage:
      'We could not upload your file. Please, check the status of your store in system settings and try again.',
  },
});

export interface CompleteStudyData {
  completeStudy: FullStudy;
}

interface CompleteStudyVariables {
  id: string;
}

type UseCompleteStudyProps = MutationHookOptions<CompleteStudyData, CompleteStudyVariables> & CompleteStudyVariables;

export const useCompleteStudy = (params: UseCompleteStudyProps) => {
  const { id, ...rest } = params;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof params?.onError === 'function') {
      params.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_completing_study':
      default:
        return setError(messages.unexpectedError);
    }
  };

  const onCompleted = (data: CompleteStudyData) => {
    onErrorClose();

    if (typeof params?.onCompleted === 'function') {
      params.onCompleted(data);
    }
  };

  const [completeStudy, { loading: isLoading }] = useMutation<CompleteStudyData, CompleteStudyVariables>(
    COMPLETE_STUDY,
    {
      ...rest,
      onError,
      onCompleted,
    }
  );

  const onSubmit = () => {
    setError(null);

    const variables = {
      id,
    };

    completeStudy({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    onSubmit,
    error,
    isLoading,
    onErrorClose,
  };
};

export interface AddNoteToStudyFormValues {
  note: string;
}

interface AddNoteToStudyData {
  addNoteToStudy: FullStudy;
}

interface AddNoteToStudyVariables {
  id: string;
  note: string;
}

type UseAddNoteToStudyProps = MutationHookOptions<AddNoteToStudyData, AddNoteToStudyVariables> & {
  id: string;
};

export const useAddNoteToStudy = (params: UseAddNoteToStudyProps) => {
  const { id, ...rest } = params;

  const intl = useIntl();

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_adding_note':
        return setError(messages.errorAddingNote);

      default:
        return setError(messages.unexpectedError);
    }
  };

  const validate: ValidateFn<AddNoteToStudyFormValues> = async (values) => {
    const errors: ValidationError<AddNoteToStudyFormValues> = {};

    if (!values.note) {
      errors.note = intl.formatMessage(messages.required);
    }

    return errors;
  };

  const [addNoteToStudy, { loading: isLoading }] = useMutation<AddNoteToStudyData, AddNoteToStudyVariables>(
    ADD_NOTE_TO_STUDY,
    {
      ...rest,
      onError,
    }
  );

  const onSubmit = (values: AddNoteToStudyFormValues) => {
    setError(null);

    const variables = {
      id,
      note: values.note,
    };

    addNoteToStudy({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  };
};

export interface AddFileToStudyFormValues {
  fileId: string;
}

interface AddFileToStudyData {
  addFileToStudy: FullStudy;
}

interface AddFileToStudyVariables {
  id: string;
  fileId: string;
}

type UseAddFileToStudyProps = MutationHookOptions<AddFileToStudyData, AddFileToStudyVariables> & {
  id: string;
};

export const useAddFileToStudy = (params: UseAddFileToStudyProps) => {
  const { id, ...rest } = params;

  const intl = useIntl();

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_adding_file':
        return setError(messages.errorAddingFile);

      default:
        return setError(messages.unexpectedError);
    }
  };

  const validate: ValidateFn<AddFileToStudyFormValues> = async (values) => {
    const errors: ValidationError<AddFileToStudyFormValues> = {};

    if (!values.fileId) {
      errors.fileId = intl.formatMessage(messages.required);
    }

    return errors;
  };

  const [addFileToStudy, { loading: isLoading }] = useMutation<AddFileToStudyData, AddFileToStudyVariables>(
    ADD_FILE_TO_STUDY,
    {
      ...rest,
      onError,
    }
  );

  const onSubmit = (values: AddFileToStudyFormValues) => {
    setError(null);

    const variables = {
      id,
      fileId: values.fileId,
    };

    addFileToStudy({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    onSubmit,
    validate,
    error,
    isLoading,
    onErrorClose,
  };
};
