import React from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { BusinessHours, HealthSystem, Slot } from '../../api/interfaces';
import { useMySystemSettings } from '.';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { UPDATE_SCHEDULER_SETTINGS } from 'api/request/systems';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  required: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
});

export interface NewSchedulerSettingsFormValues extends Slot {
  notifications: boolean;
  businessHours: boolean;
}

interface NewSchedulerSettings {
  slot: Slot;
  notifications: boolean;
  businessHours?: Array<BusinessHours>;
}

interface UpdateSchedulerSettingsVariables {
  systemId: string;
  locationId: string;
  settings: NewSchedulerSettings;
}

interface UpdateSchedulerSettingsData {
  updateTelehealthSettings: HealthSystem;
}

export type UpdateTelehealthSettingsParams = MutationHookOptions<
  UpdateSchedulerSettingsData,
  UpdateSchedulerSettingsVariables
>;

export const useUpdateSchedulerSettings = (params?: UpdateTelehealthSettingsParams) => {
  const intl = useIntl();

  const { scheduler, system, location } = useMySystemSettings();

  const initialValues = {
    unit: scheduler.slot.unit,
    length: scheduler.slot.length,
    notifications: !!scheduler.notifications,
    businessHours: !!scheduler.businessHours,
  };

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);
  const validate: ValidateFn<NewSchedulerSettingsFormValues> = async (values) => {
    const errors: ValidationError<NewSchedulerSettingsFormValues> = {};

    if (!values.unit) {
      errors.unit = intl.formatMessage(messages.required);
    }

    if (!values.length || values.length <= 0) {
      errors.length = intl.formatMessage(messages.required);
    }

    return errors;
  };

  const onError = (error) => {
    if (params?.onError) {
      params.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);
    if (params?.onCompleted) {
      params.onCompleted(data);
    }
  };

  const [updateSettings, { loading: isLoading }] = useMutation<
    UpdateSchedulerSettingsData,
    UpdateSchedulerSettingsVariables
  >(UPDATE_SCHEDULER_SETTINGS, { ...params, onError, onCompleted });

  const onSubmit = (values: NewSchedulerSettingsFormValues) => {
    const businessHours = values.businessHours
      ? [{ daysOfWeek: [1, 2, 3, 4, 5], startTime: '07:00', endTime: '20:00' }]
      : undefined;

    const variables = {
      systemId: system.id,
      locationId: location.id,
      settings: {
        slot: {
          unit: values.unit,
          length: Number(values.length),
        },
        notifications: !!values.notifications,
        businessHours,
      },
    };

    updateSettings({ variables });
  };

  const onErrorClose = () => {
    setError(null);
  };

  return {
    initialValues,
    validate,
    onSubmit,
    isLoading,
    error,
    onErrorClose,
  };
};
