import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LoadingInline } from 'components-ts';
import { TranslationWrapper as T } from 'components-ts/Translations';
import Highlighter from 'react-highlight-words';
import { FaCheckCircle as CheckIcon } from 'react-icons/fa';
import { useDynamicTranslation } from 'components-ts/i18n';
import { useProcedureSubscriptions } from './useProcedureSubscriptions';
import { useAddProcedureOrder } from './useProcedureOrder';
import { Nullable } from 'components-ts/utils';
import { FullChecklistEntry, FullProcedure } from 'api/interfaces';
import { ClickableItemWrapper } from 'components-ts/SearchableList';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { LastUpdated } from 'components-ts/LastUpdated';
import { usePatientContext } from 'components-ts/Patients/usePatientContext';
import { ScrollableList } from 'components-ts/ScrollableList';

const messages = defineMessages({
  orderNewProcedure: {
    id: 'ProcedureOrderModal.order_new_procedure',
    defaultMessage: 'Order new procedure',
  },
  searchStudies: {
    id: 'ProcedureOrderModal.search_procedures',
    defaultMessage: 'Search procedures',
  },
  orderButton: {
    id: 'ProcedureOrderModal.order',
    defaultMessage: 'Order',
  },
  comment: {
    id: 'ProcedureOrderModal.comment',
    defaultMessage: 'Comment',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
});

const ITEMS_PER_PAGE = 10;
type TranslatedProcedure = FullProcedure & { translatedName: string; translatedDescription: Nullable<string> };

interface ProcedureOrderModalProps {
  patientId: string;
  guideline?: FullChecklistEntry;
  isOpen?: boolean;
  onClose?: () => void;
  onCompleted?: () => void;
}

export const ProcedureOrderModal: React.FC<ProcedureOrderModalProps> = (props) => {
  const { patientId, guideline, isOpen, onClose, onCompleted } = props;

  const intl = useIntl();

  const [procedureId, setProcedureId] = React.useState<Nullable<string>>(null);
  const [orderTitle, setOrderTitle] = React.useState<Nullable<string>>(null);
  const [comment, setComment] = React.useState<string>('');

  const { activeVisit } = usePatientContext();
  const visitId = activeVisit?.id ?? '';

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setComment(value);
  };

  const onSelect = (template: Nullable<TranslatedProcedure>) => {
    setProcedureId(template?.id ?? null);
    setOrderTitle(template?.translatedName ?? null);
  };

  const {
    onSubmit,
    isLoading: adding,
    error: orderingError,
  } = useAddProcedureOrder({ patientId, visitId, onCompleted });

  const onOrdered = () => {
    if (!procedureId || !orderTitle) {
      return;
    }

    const procedureOrder = {
      guideline,
      comment,
      procedureId,
      orderTitle,
    };

    onSubmit(procedureOrder);
  };

  const { translateDynamic } = useDynamicTranslation();

  const initialFilter = translateDynamic(guideline?.name?.text ?? '') ?? '';
  const {
    procedures,
    updated,
    isLoading,
    error: searchingError,
  } = useProcedureSubscriptions({
    skip: !isOpen,
    nextFetchPolicy: 'cache-and-network',
  });

  const translatedProcedures: Array<TranslatedProcedure> = React.useMemo(() => {
    const translated = procedures.map((procedure) => {
      const { name, description } = procedure;

      const translatedName = translateDynamic(name?.text ?? '');
      const translatedDescription = translateDynamic(description?.text ?? '');

      return {
        ...procedure,
        translatedName: translatedName ?? name.text ?? '',
        translatedDescription: translatedDescription ?? description?.text ?? null,
      };
    });

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      if (b.translatedName > a.translatedName) {
        return -1;
      }

      return 0;
    });

    return sorted;
  }, [procedures, translateDynamic]);

  const error = searchingError ?? orderingError;

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader>
        <T id={messages.orderNewProcedure.id}>{intl.formatMessage(messages.orderNewProcedure)}</T>
      </ModalHeader>
      <ModalBody>
        <ScrollableList
          searcherPlaceholder={intl.formatMessage(messages.searchStudies)}
          items={translatedProcedures}
          itemsToShow={ITEMS_PER_PAGE}
          searchableProperties={['translatedName', 'translatedDescription']}
          initialSearch={initialFilter}
          isLoading={isLoading}
          isURLSync={false}
          renderItem={(item, _index, searchTerms) => (
            <CustomItem
              key={item.id}
              searchTerms={searchTerms}
              procedure={item}
              isSeledted={procedureId === item.id}
              onSelect={onSelect}
            />
          )}
        />
        <Input
          value={comment}
          onChange={onCommentChange}
          placeholder={intl.formatMessage(messages.comment)}
          maxLength={120}
          autoComplete="off"
          autoFocus
          disabled={isLoading}
        />
        {error && <ErrorViewer error={error} />}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="primary" disabled={!procedureId || isLoading} onClick={onOrdered}>
          <T id={messages.orderButton.id}>{intl.formatMessage(messages.orderButton)}</T>
          {adding && <LoadingInline className="ml-1" />}
        </Button>
        {updated && <LastUpdated signature={updated} />}
      </ModalFooter>
    </Modal>
  );
};

interface CustomItemProps {
  procedure: TranslatedProcedure;
  isSeledted: boolean;
  onSelect: (id: Nullable<TranslatedProcedure>) => void;
  searchTerms: any;
}
const CustomItem: React.FC<CustomItemProps> = (props) => {
  const { procedure, isSeledted, onSelect, searchTerms } = props;

  const { translatedName, translatedDescription } = procedure;

  const onClick = () => {
    onSelect(isSeledted ? null : procedure);
  };
  const className = `d-flex align-items-center justify-content-between ${isSeledted ? 'bg-light' : ''}`;
  return (
    <ClickableItemWrapper onClick={onClick} className={className}>
      <div className="d-flex flex-column  flex-wrap">
        <span className="d-flex align-items-center">
          <Highlighter
            className="text-capitalize font-weight-bold"
            searchWords={searchTerms}
            autoEscape
            textToHighlight={translatedName}
          />
        </span>
        <span className="small text-muted">{translatedDescription}</span>
      </div>
      {isSeledted && <CheckIcon className="text-success h4 m-0" />}
    </ClickableItemWrapper>
  );
};
