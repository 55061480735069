import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Field, Form } from 'react-final-form';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Input,
  CardFooter,
  Button,
} from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { usePatientContext } from 'components-ts/Patients/usePatientContext';
import { ErrorViewer, LoadingInline } from 'components';
import { FaHashtag as IdIcon, FaClinicMedical as ClinicIcon } from 'react-icons/fa';
import { usePatientUpdatePaymentSettings } from './usePatientUpdatePaymentSettings';
import { PricelistSelector } from 'components-ts/Selectors/PricelistSelector';

const messages = defineMessages({
  paymentTitle: {
    id: 'PaymentCard.payment_title',
    defaultMessage: 'Payment',
  },
  clinicId: {
    id: 'PaymentCard.clinic_id',
    defaultMessage: 'Clinic ID',
  },
  nationalId: {
    id: 'PaymentCard.national_id',
    defaultMessage: 'National ID',
  },
  jkn: {
    id: 'PaymentCard.jkn',
    defaultMessage: 'JKN',
  },
  privateInsuranceNumber: {
    id: 'PaymentCard.private_insurance_number',
    defaultMessage: 'Private insurance number',
  },
  defaultPaymentMethod: {
    id: 'PaymentCard.default_payment_method',
    defaultMessage: 'Default payment method',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
});

type PaymentCardProps = {
  className?: string;
};

export const PaymentCard: React.FC<PaymentCardProps> = (props) => {
  const { className = '' } = props;

  const intl = useIntl();

  /**
   * we need info related to the active visit too
   * that's why we don't use the usePatient hook
   */
  const { patient } = usePatientContext();

  const initialValues = {
    clinic_id: patient.clinic_id ?? undefined,
    national_id: patient.national_id ?? undefined,
    private_insurance_number: patient.private_insurance_number ?? undefined,
    pricelist: patient.pricelist ?? undefined,
  };

  const { onSubmit, validate, error, isLoading } = usePatientUpdatePaymentSettings({
    patientId: patient.id,
    country: patient.address?.country ?? undefined,
  });

  return (
    <Card className={className}>
      <CardHeader>
        <h6 className="m-0 font-weight-bold text-muted">
          <T id={messages.paymentTitle.id}>{intl.formatMessage(messages.paymentTitle)}</T>
        </h6>
      </CardHeader>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({ handleSubmit, dirty }) => {
          return (
            <form onSubmit={handleSubmit}>
              <CardBody>
                {/* clinic id */}
                <Row>
                  <Field name="clinic_id">
                    {({ input, meta }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.clinicId.id}>{intl.formatMessage(messages.clinicId)}</T>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <ClinicIcon />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              {...input}
                              title={intl.formatMessage(messages.clinicId)}
                              placeholder={intl.formatMessage(messages.clinicId)}
                              aria-label={intl.formatMessage(messages.clinicId)}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              maxLength={50}
                              className={`${
                                meta.error && meta.touched ? 'border border-danger rounded' : ''
                              } text-muted`}
                            />
                          </InputGroup>
                        </FormGroup>
                        {meta.error && meta.touched && (
                          <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                            {meta.error}
                          </div>
                        )}
                      </Col>
                    )}
                  </Field>
                </Row>

                {/* national_id */}
                <Row>
                  <Field name="national_id">
                    {({ input, meta }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.nationalId.id}>{intl.formatMessage(messages.nationalId)}</T>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <IdIcon />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              title={intl.formatMessage(messages.nationalId)}
                              placeholder={intl.formatMessage(messages.nationalId)}
                              aria-label={intl.formatMessage(messages.nationalId)}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              maxLength={50}
                              {...input}
                              className={`${
                                meta.error && meta.touched ? 'border border-danger rounded' : ''
                              } text-muted`}
                            />
                          </InputGroup>
                        </FormGroup>
                        {meta.error && meta.touched && (
                          <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                            {meta.error}
                          </div>
                        )}
                      </Col>
                    )}
                  </Field>
                </Row>

                {/* private insurance number  */}
                <Row>
                  <Field name="private_insurance_number">
                    {({ input }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.privateInsuranceNumber.id}>
                              {intl.formatMessage(messages.privateInsuranceNumber)}
                            </T>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <IdIcon />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              title={intl.formatMessage(messages.privateInsuranceNumber)}
                              placeholder={intl.formatMessage(messages.privateInsuranceNumber)}
                              aria-label={intl.formatMessage(messages.privateInsuranceNumber)}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              maxLength={50}
                              className={`form-control`}
                              {...input}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )}
                  </Field>
                </Row>

                {/* private insurance number  */}
                <Row>
                  <Field name="pricelist">
                    {({ input, meta }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.defaultPaymentMethod.id}>
                              {intl.formatMessage(messages.defaultPaymentMethod)}
                            </T>
                          </Label>
                          <PricelistSelector {...input} />
                          {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                        </FormGroup>
                      </Col>
                    )}
                  </Field>
                </Row>

                {error && <ErrorViewer error={error} />}
              </CardBody>
              {dirty && (
                <CardFooter className="animated fadeIn">
                  <div className="d-flex justify-content-start">
                    <Button type="submit" color="primary">
                      <T id={messages.saveButton.id} className={isLoading ? 'mr-2' : ''}>
                        {intl.formatMessage(messages.saveButton)}
                      </T>
                      {isLoading && <LoadingInline />}
                    </Button>
                  </div>
                </CardFooter>
              )}
            </form>
          );
        }}
      />
    </Card>
  );
};
