import { ReactElement } from 'react';

/**
 * Select components
 * https://react-select.com/home
 */
export type ReactSelectInternalSingleValue = {
  value: string;
  label: ReactElement | string;
  id?: string;
};

export type ReactSelectInternalMultiValue = Array<ReactSelectInternalSingleValue>;
export type ReactSelectInternalValue = ReactSelectInternalSingleValue | ReactSelectInternalMultiValue;

interface BaseSelectorProps {
  id?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  blurInputOnSelect?: boolean;
  autoFocus?: boolean;
}

export interface SingleSelectorProps<T> extends BaseSelectorProps {
  value: T;
  onChange: (newValue: T) => void;
  isMulti?: false;
}

export interface MultiSelectorProps<T> extends BaseSelectorProps {
  value: Array<T>;
  onChange: (newValue: Array<T>) => void;
  isMulti: true;
}

export type SelectorProps<T> = SingleSelectorProps<T> | MultiSelectorProps<T>;

export function isMultiSelector<T> (props: SelectorProps<T>): props is MultiSelectorProps<T> {
  return 'isMulti' in props;
}
