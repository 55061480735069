import { NewRoom } from 'api/interfaces';
import { ValidateFn } from 'components-ts/Forms/utils';
import React from 'react';
import { Form, Field } from 'react-final-form';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Nullable } from 'components-ts/utils';
import { ErrorViewer } from 'components-ts/ErrorViewer';

const messages = defineMessages({
  name: {
    id: 'RoomEditor.label_name',
    defaultMessage: 'Room name',
  },
  description: {
    id: 'RoomEditor.label_description',
    defaultMessage: 'Description',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
  deleteButton: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  available: {
    id: 'RoomViewer.label_available',
    defaultMessage: 'Available',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
});

type RoomFormProps = {
  onSubmit: (room: NewRoom) => void;
  validate?: ValidateFn<NewRoom>;
  error: Nullable<MessageDescriptor>;
  initialValues?: NewRoom;
  loading?: boolean;
};

export const RoomForm: React.FC<RoomFormProps> = (props) => {
  const { onSubmit, validate, initialValues, loading, error } = props;

  const intl = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, form, invalid, pristine }) => {
        return (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event);
              // clear the form after saving
              const { valid } = form.getState();
              if (valid) {
                form.resetFieldState('name');
                form.reset();
              }
            }}
          >
            <Row className="row align-items-center">
              <Field name="name">
                {({ input, meta }) => (
                  <Col>
                    <FormGroup>
                      <Label>
                        <T id={messages.name.id}>{intl.formatMessage(messages.name)}</T>
                      </Label>
                      <Input
                        {...input}
                        autoComplete="off"
                        className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''}`}
                        placeholder={intl.formatMessage(messages.name)}
                      />
                    </FormGroup>
                    {meta.error && meta.touched && (
                      <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                        {meta.error}
                      </div>
                    )}
                  </Col>
                )}
              </Field>
            </Row>
            <Row>
              <Field name="description">
                {({ input, meta }) => (
                  <Col>
                    <FormGroup className="mb-3">
                      <Label>
                        <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
                      </Label>
                      <Input
                        {...input}
                        autoComplete="off"
                        type="textarea"
                        placeholder={intl.formatMessage(messages.description)}
                        className="form-control"
                        maxLength={200}
                      />
                    </FormGroup>
                    {meta.error && meta.touched && (
                      <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                        {meta.error}
                      </div>
                    )}
                  </Col>
                )}
              </Field>
            </Row>
            <Row>
              <Col>
                <FormGroup className="d-flex align-items-center  m-0">
                  <Label className="switch switch-pill switch-primary m-0 mr-2">
                    <Field className="switch-input" name="available" component="input" type="checkbox" />
                    <span className="switch-slider m-0" />
                  </Label>
                  <p className="m-0 text-muted">
                    <T id={messages.available.id}>{intl.formatMessage(messages.available)}</T>
                  </p>
                </FormGroup>
              </Col>
            </Row>
            {error && <ErrorViewer error={error} />}
            {/* Buttons */}
            <div className="justify-content-start mt-3">
              <Button className="" color="primary" disabled={loading || invalid || pristine} onClick={handleSubmit}>
                <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
};
