import { gql } from '@apollo/client'
import { FullQuickpick, FullQuickpickWithDrug } from 'utils/GQLFragments'

export const GET_QUICKPICK_BY_ID = gql`
  ${FullQuickpick}
  query quickpick($id: ID!){
    quickpick(id: $id) {
      ...FullQuickpick
    }
  }
`

export const GET_QUICKPICKS = gql`
  ${FullQuickpick}
  query quickpicks($q: String, $offset: Int, $limit: Int){
    quickpicks(q: $q, offset: $offset, limit: $limit) {
      docs{
        ...FullQuickpick
      },
      count
    }
  }
`

export const GET_QUICKPICKS_WITH_DRUG = gql`
  ${FullQuickpickWithDrug}
  query quickpicksWithDrug($q: String, $offset: Int, $limit: Int){
    quickpicksWithDrug(q: $q, offset: $offset, limit: $limit) {
      docs{
        ...FullQuickpickWithDrug
      },
      count
    }
  }
`

export const CREATE_QUICKPICK = gql`
  ${FullQuickpick}
  mutation createQuickpick($newQuickpick: NewQuickpick!) {
    createQuickpick(newQuickpick: $newQuickpick) {
      ...FullQuickpick
    }
  }
`

export const UPDATE_QUICKPICKS = gql`
  ${FullQuickpick}
  mutation updateQuickpick($id: ID!, $updateQuickpick: NewQuickpick!) {
    updateQuickpick(id: $id, updateQuickpick: $updateQuickpick) {
      ...FullQuickpick
    }
  }
`

export const DELETE_QUICKPICK = gql`
  mutation ($id: ID!) {
    deleteQuickpick(id: $id){
      done
    }
  }
`
