import React from 'react'
import Select from 'react-select'
import { FormGroup, Label } from 'reactstrap'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { injectIntl, defineMessages } from 'react-intl'
import { usePharmacyItems } from 'components-ts/Pharmacy'

const debug = require('debug')('wd:DrugSelector')

/**
 * Component for allow to select a drug/s
 *
 * @component
 * @example

 * // if isMulti === true, drugs is an Array<string>
 * const onChange = ([...drugs]) => debug(drugs[0])

 * // else, drugs is a string
 * const onChange = (drug) => debug(drug)
 * return (
 *   <DrugSelector
 *      defaultValue={drug}
 *      onChange={onChange}
 *   />
 * )
 */

// static translations
const messages = defineMessages({
  drug: {
    id: 'DrugSelector.label_drug_selector',
    defaultMessage: 'Drug'
  }
})

const DrugSelector = (props) => {
  // props
  const {
    defaultValue: _defaultValue,
    onChange,
    isMulti,
    isClearable,
    className,
    withCount,
    withLabel = true,
    withClass = true,
    intl
  } = props

  const { formatMessage } = intl

  if (typeof onChange !== 'function') debug('Missing prop: onChange')

  const { pharmacyItems, isLoading, getPharmacyItem } = usePharmacyItems()

  const formatOption = drug => {
    const { id, name, class: drugClass } = drug //eslint-disable-line

    return {
      id: id,
      value: name,
      label: <div style={{ display: 'flex', flexDirection: 'column' }}>
        <b style={{ lineHeight: '.8em', margin: '3px 0' }}>
          {name}
        </b>
        { withClass && drugClass
          ? <p className='text-muted small p-0 m-0' title={drugClass} >
            { drugClass }
          </p>
          : null }
      </div>
    }
  }

  // shown options
  const options = pharmacyItems.map(formatOption)
  const defaultValue = getPharmacyItem(_defaultValue)

  const _onChange = (val) => {
    if (typeof onChange === 'function') {
      const newValue = isMulti && Array.isArray(val)
        ? val.map(v => v.id)
        : (val || {}).id

      const newName = isMulti && Array.isArray(val)
        ? val.map(v => (getPharmacyItem(v.id) || {}).name)
        : (getPharmacyItem(val.id) || {}).name

      onChange(newValue, newName)
    }
  }

  debug(pharmacyItems)

  /**
   * Force a component remount after fetching the
   * items to show the default value
   */
  const updateKey = `drug-selector-${isLoading}`
  return <FormGroup>
    { withLabel && <Label>
      <T id={messages.drug.id}>{formatMessage(messages.drug)}</T>
      { withCount && ` (${options.length})`}
    </Label>
    }
    <Select
      className={`dropdownZIndex ${className}`}
      key={updateKey}
      defaultValue={defaultValue ? formatOption(defaultValue) : ''}
      onChange={_onChange}
      options={options}
      isMulti={isMulti}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable
    />
  </FormGroup>
}

DrugSelector.default = {
  defaultValue: '',
  onChange: () => {},
  isMulti: false
}
export default injectIntl(DrugSelector)
