import React from 'react';
import { Field, Form } from 'react-final-form';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import { ErrorViewer } from '../ErrorViewer';
import {
  RegistrationDataFormValues,
  useDeleteScheduledVisit,
  ValidatedRegistrationDataFormValues,
} from './useScheduledVisitActions';
import { useSchedulerContext } from './useSchedulerContext';
import { Button, Col, FormGroup, Label, Input } from 'reactstrap';
import { AvailableSlot, formatNormalizedSlotsForEndTimeSelector, normalizeSlots } from './utils';
import { ValidateFn } from 'components-ts/Forms/utils';
import { Nullable } from 'components-ts/utils';
import { HowLongSelector, PatientSelector, VisitTypeSelector } from 'components-ts/Selectors';
import { TimeSelector } from './TimeSelector';
import { LoadingInline } from 'components-ts/Loading';
import { StartVisitButton } from './StartVisitButton';
import { ConfirmationModal } from 'components-ts/ConfirmationModal';
import { useDisclosure } from 'components-ts/useDisclosure';

const messages = defineMessages({
  patient: {
    id: 'UI.button_patient_profile',
    defaultMessage: 'Patient',
  },
  fromLabel: {
    id: 'RoomEventEditor.from_label',
    defaultMessage: 'From',
  },
  toLabel: {
    id: 'RoomEventEditor.to_label',
    defaultMessage: 'To',
  },
  visitType: {
    id: 'ScheduledVisitsRegistrationTab.visit_type_label',
    defaultMessage: 'Visit type',
  },
  chiefComplaint: {
    id: 'ScheduledVisitsRegistrationTab.chief_complaint',
    defaultMessage: 'Chief Complaint',
  },
  howLong: {
    id: 'ScheduledVisitsRegistrationTab.label_how_long',
    defaultMessage: 'How long?',
  },
  telehealthLabel: {
    id: 'ScheduledVisitsRegistrationTab.telehealth_label',
    defaultMessage: 'Telehealth',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  deleteButton: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
});

interface ScheduleVisitFormProps {
  slotDurationInMinutes: number;
  availableSlots: Array<AvailableSlot>;
  initialValues?: ValidatedRegistrationDataFormValues;
  onSubmit: (values: ValidatedRegistrationDataFormValues) => void;
  isLoading?: boolean;
  validate: ValidateFn<RegistrationDataFormValues>;
  error: Nullable<MessageDescriptor>;
  onErrorClose: () => void;
  isDeletable?: boolean;
}

export const ScheduleVisitForm: React.FC<ScheduleVisitFormProps> = (props) => {
  const { slotDurationInMinutes, initialValues, validate, error, onSubmit, isLoading, availableSlots, isDeletable } =
    props;

  const intl = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, values, initialValues, form }) => {
        const selectedDate = values.start ?? initialValues.start;
        const allowedEndTimes = formatNormalizedSlotsForEndTimeSelector(
          availableSlots,
          slotDurationInMinutes,
          selectedDate ? new Date(selectedDate) : undefined
        );

        return (
          <form onSubmit={handleSubmit}>
            <Field name="patientId">
              {({ input, meta }) => (
                <Col className="px-0">
                  <PatientSelector {...input} />
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="type">
              {({ input, meta }) => (
                <Col className="px-0">
                  <VisitTypeSelector {...input} />
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="start">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.fromLabel.id}>{intl.formatMessage(messages.fromLabel)}</T>
                    </Label>
                    <TimeSelector
                      {...input}
                      onChange={(e) => {
                        form.change('end', undefined);
                        input.onChange(e);
                      }}
                      availableSlots={normalizeSlots(availableSlots, slotDurationInMinutes)}
                    />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="end">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.toLabel.id}>{intl.formatMessage(messages.toLabel)}</T>
                    </Label>
                    <TimeSelector {...input} availableSlots={allowedEndTimes} />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="complaint">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.chiefComplaint.id}>{intl.formatMessage(messages.chiefComplaint)}</T>
                    </Label>
                    <Input
                      {...input}
                      autoComplete="off"
                      className="form-control"
                      placeholder={intl.formatMessage(messages.chiefComplaint)}
                      maxLength={100}
                    />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="howLong">
              {({ input, meta }) => (
                <Col className="px-0">
                  <HowLongSelector {...input} />
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>

            <Col className="d-flex align-items-center justify-content-start px-0">
              <Label className="switch switch-pill switch-primary m-0 mr-2">
                <Field className="switch-input" name="telehealth" component="input" type="checkbox" />
                <span className="switch-slider m-0" />
              </Label>
              <p className="m-0 text-muted">
                <T id={messages.telehealthLabel.id}>{intl.formatMessage(messages.telehealthLabel)}</T>
              </p>
            </Col>

            {error && <ErrorViewer error={error} />}

            <div className="d-flex mt-4">
              <Button color={'primary'} type="submit">
                <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
                {isLoading && <LoadingInline className="ml-1" />}
              </Button>

              {
                // it means that can be started
                isDeletable && <StartVisitButton />
              }

              {isDeletable && <DeleteScheduledVisitButton />}
            </div>
          </form>
        );
      }}
    />
  );
};

const DeleteScheduledVisitButton: React.VFC = () => {
  const intl = useIntl();

  const { selectedEvent, onReset, onRefresh } = useSchedulerContext();

  const onCompleted = () => {
    onRefresh();
    onReset();
  };

  const { onDelete, error, isLoading } = useDeleteScheduledVisit({
    id: selectedEvent ?? '',
    onCompleted: onCompleted,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();
  if (!selectedEvent) {
    return null;
  }
  return (
    <>
      <Button className={'ml-1'} color={'danger'} onClick={onOpen}>
        <T id={messages.deleteButton.id} className={isLoading ? 'mr-1' : ''}>
          {intl.formatMessage(messages.deleteButton)}
        </T>
        {isLoading && <LoadingInline />}
      </Button>
      {isOpen && (
        <ConfirmationModal
          error={error}
          loading={isLoading}
          message={messages.confirmationMessage}
          className="btn-danger"
          onConfirm={onDelete}
          onClose={onClose}
        />
      )}
    </>
  );
};
