import React from 'react';
import { FullChecklist, ItemSubscriptions } from 'api/interfaces';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useDynamicTranslation } from 'components-ts/i18n';
import { useSubscribeToTriageChecklist, useUnsubscribeFromTriageChecklist } from './useTriageActions';
import { Nullable } from 'components-ts/utils';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { ClickableItemWrapper, SearchableList } from 'components-ts/SearchableList';
import Highlighter from 'react-highlight-words';
import { FaCheckCircle as CheckIcon } from 'react-icons/fa';
import { useChecklists } from 'components-ts/Checklists/useChecklists';
import { useDisclosure } from 'components-ts/useDisclosure';
import { Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';

const messages = defineMessages({
  title: {
    id: 'TriageTab.label_triage',
    defaultMessage: 'Triage',
  },
  description: {
    id: 'TriageTab.description',
    defaultMessage: 'Checklist used in triage',
  },
  noSubscriptions: {
    id: 'UI.label_no_subscriptions',
    defaultMessage: 'No Subscriptions',
  },
  subscribedLabel: {
    id: 'UI.label_subscribed',
    defaultMessage: 'Subscribed ({count})',
  },
});

type TriageTabProps = {
  systemId: string;
  locationId: string;
  subscriptions: ItemSubscriptions;
  isDisabled?: boolean;
};

type TranslatedChecklist = FullChecklist & { translatedName: string; translatedDescription: Nullable<string> };

const TriageChecklistSubscriptions: React.FC<TriageTabProps> = (props) => {
  const { systemId, locationId, subscriptions, isDisabled } = props;

  const { translateDynamic } = useDynamicTranslation();
  const { docs: checklists, isLoading, error: searchingError } = useChecklists({ tags: ['Triage'] });

  const { onSubmit: onSubscribe, error: subscribingError } = useSubscribeToTriageChecklist({ systemId, locationId });
  const { onDelete: onUnsubscribe, error: unsubscribingError } = useUnsubscribeFromTriageChecklist({
    systemId,
    locationId,
  });

  const isSelected = (checklistId) =>
    !!subscriptions.items?.find((subscribedId) => subscribedId === checklistId) ?? false;

  const translatedAndSortedChecklists: Array<TranslatedChecklist> = React.useMemo(() => {
    const translated = checklists.map((study) => {
      const { name, background } = study;

      const translatedName = translateDynamic(name?.text ?? '');
      const translatedDescription = translateDynamic(background?.text ?? '');

      return {
        ...study,
        translatedName: translatedName ?? name.text ?? '',
        translatedDescription: translatedDescription ?? background?.text ?? null,
      };
    });

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      if (b.translatedName > a.translatedName) {
        return -1;
      }

      return 0;
    });

    return sorted;
  }, [checklists, translateDynamic]);

  const error = searchingError ?? subscribingError ?? unsubscribingError;
  return (
    <div className="my-2">
      <SearchableList
        items={translatedAndSortedChecklists ?? []}
        itemsToShow={8}
        searchableProperties={['translatedName', 'translatedDescription']}
        isLoading={isLoading}
        isURLSync={false}
        renderItem={(item, index, searchTems) => (
          <CustomItem
            key={index}
            item={item}
            searchTerms={searchTems}
            isSelected={isSelected(item.id)}
            onSubscribe={onSubscribe}
            onUnsubscribe={onUnsubscribe}
            isDisabled={isDisabled}
          />
        )}
      />
      {error && <ErrorViewer error={error} />}
    </div>
  );
};

type CustomItemProps = {
  item: TranslatedChecklist;
  searchTerms: Array<string>;
  onSubscribe: (id: string) => void;
  onUnsubscribe: (id: string) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
};

const CustomItem: React.FC<CustomItemProps> = (props) => {
  const { item, isSelected, searchTerms, onSubscribe, onUnsubscribe, isDisabled } = props;
  const { id, name, translatedDescription, translatedName } = item;

  const onClick = () => {
    if (isSelected) {
      onUnsubscribe(id);
    } else {
      onSubscribe(id);
    }
  };

  const translationId = `Dynamic.${name.text}`;
  const [shortText] = translatedName.split('(');
  return (
    <ClickableItemWrapper
      isDisabled={isDisabled}
      key={id}
      onClick={onClick}
      className="d-flex align-items-center justify-content-between"
    >
      <div className={'d-flex flex-column'} style={{ maxWidth: 'calc(100% - 30px)' }}>
        <T id={translationId}>
          <Highlighter
            className="text-capitalize font-weight-bold"
            searchWords={searchTerms}
            autoEscape
            textToHighlight={shortText}
          />
        </T>
        {translatedDescription && (
          <span
            className="font-size-sm font-italic"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {translatedDescription}
          </span>
        )}
      </div>
      {isSelected && <CheckIcon className="text-success h4 m-0" />}
    </ClickableItemWrapper>
  );
};

interface TriageSubscriptionSectionProps {
  systemId: string;
  locationId: string;
  subscriptions: ItemSubscriptions;
  className?: string;
  isDisabled?: boolean;
}

export const TriageSubscriptionSection: React.FC<TriageSubscriptionSectionProps> = (props) => {
  const { systemId, locationId, subscriptions, className, isDisabled } = props;
  const intl = useIntl();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const count = subscriptions.items?.length ?? 0;
  return (
    <div
      onClick={onOpen}
      className={'d-flex align-items-center justify-content-between pointer animated-border-on-hover rounded p-3'}
    >
      <div className="flex-wrap">
        <h6 className="mb-1">
          <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
        </h6>
        <p className="text-muted mb-2">
          <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
        </p>
      </div>

      {count > 0 ? (
        <Badge color="success" className={`no-wrap ${className}`}>
          <T id={messages.subscribedLabel.id}>{intl.formatMessage(messages.subscribedLabel, { count })}</T>
        </Badge>
      ) : (
        <Badge className={`no-wrap ${className}`}>
          <T id={messages.noSubscriptions.id}>{intl.formatMessage(messages.noSubscriptions)}</T>
        </Badge>
      )}
      {isOpen && (
        <Modal isOpen={true} toggle={onClose}>
          <ModalHeader toggle={onClose}>
            <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
          </ModalHeader>
          <ModalBody>
            <TriageChecklistSubscriptions
              isDisabled={isDisabled}
              systemId={systemId}
              locationId={locationId}
              subscriptions={subscriptions}
            />
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
