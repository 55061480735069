import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from './Translations';
import { ResourcePaginator } from './ResourcePaginator';
import { useQueryParams } from 'hooks';
import { useHistory } from 'react-router-dom';

const messages = defineMessages({
  loading: {
    id: 'UI.loading_text',
    defaultMessage: 'Loading...',
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No results',
  },
  resultCount: {
    id: 'ResourceList.showing_result_count',
    defaultMessage: 'Showing {start}-{end} out of {total} results.',
  },
});

type PaginatedListProps<T> = {
  items: Array<T>;
  renderItem: (item: T, index: number) => JSX.Element;
  itemsToShow: number;
  onPageChange?: (index: number) => void;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isURLSync?: boolean;
};

export function PaginatedList<T>(props: PaginatedListProps<T>) {
  const {
    items,
    renderItem,
    onPageChange: _onPageChange,
    itemsToShow,
    isDisabled,
    isLoading,
    isURLSync = true,
    className = '',
  } = props;

  const intl = useIntl();

  const queryParams = useQueryParams();
  const history = useHistory();

  const fromUrlIndex = isURLSync ? Number(queryParams.get('page')) : 0;
  const initialIndex = !isURLSync || isNaN(fromUrlIndex) ? 0 : fromUrlIndex;

  const [paginatorIndex, setPaginatorIndex] = React.useState<number>(initialIndex);

  const onPageChange = React.useCallback(
    (index: number) => {
      // update state
      setPaginatorIndex(index);

      if (isURLSync) {
        // update url
        queryParams.set('page', index.toString());
        history.replace({
          search: queryParams.toString(),
        });
      }
      // run callback
      if (typeof _onPageChange === 'function') {
        _onPageChange(index);
      }
    },
    [_onPageChange, queryParams, history, isURLSync]
  );

  const isPaginatorVisible = itemsToShow < items.length;
  const offset = paginatorIndex * itemsToShow;

  return (
    <div className={className}>
      <ul className={'list-group position-relative my-1'}>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center mt-2">
            <T id={messages.loading.id}>{intl.formatMessage(messages.loading)}</T>
          </div>
        ) : items.length === 0 ? (
          <div className="bg-light text-center my-2 py-3 rounded text-muted">
            <T id={messages.noItemsFound.id}>{intl.formatMessage(messages.noItemsFound)}</T>
          </div>
        ) : (
          <>
            <div className="rounded">
              {items.slice(offset, offset + itemsToShow).map((item, index) => renderItem(item, index))}
            </div>
            {isPaginatorVisible && (
              <ResourcePaginator
                onPageChange={onPageChange}
                selectedPage={paginatorIndex}
                itemsCount={items.length}
                itemsToShow={itemsToShow}
                pagesToShow={5}
                isDisabled={isDisabled}
                className="mt-2"
              />
            )}
          </>
        )}
      </ul>
    </div>
  );
}

type AsyncPaginatedListProps<T> = {
  items: Array<T>;
  renderItem: (item: T, index: number) => JSX.Element;
  itemsToShow: number;
  itemsCount: number;
  onPageChange: (index: number) => void;
  isLoading: boolean;
  initialPage?: number;
  className?: string;
  isDisabled?: boolean;
};

export function AsyncPaginatedList<T>(props: AsyncPaginatedListProps<T>) {
  const {
    items,
    renderItem,
    itemsCount,
    onPageChange: _onPageChange,
    initialPage = 1,
    itemsToShow,
    isDisabled,
    isLoading,
    className = '',
  } = props;

  const intl = useIntl();

  const [paginatorIndex, setPaginatorIndex] = React.useState<number>(initialPage);

  const onPageChange = React.useCallback(
    (index: number) => {
      // update state
      setPaginatorIndex(index);

      // run callback
      if (typeof _onPageChange === 'function') {
        _onPageChange(index);
      }
    },
    [_onPageChange]
  );

  const isPaginatorVisible = itemsToShow < itemsCount;

  return (
    <div className={className}>
      <ul className={'list-group position-relative my-1 border-0'}>
        {items.length === 0 ? (
          <div
            className="animated d-flex align-items-center justify-content-center bg-light my-2 py-3 rounded text-muted"
            style={{ minHeight: 64 }}
          >
            {isLoading ? (
              <T id={messages.loading.id}>{intl.formatMessage(messages.loading)}</T>
            ) : (
              <T id={messages.noItemsFound.id}>{intl.formatMessage(messages.noItemsFound)}</T>
            )}
          </div>
        ) : (
          <>
            <div className="rounded">{items.map((item, index) => renderItem(item, index))}</div>
            {isPaginatorVisible && (
              <ResourcePaginator
                onPageChange={onPageChange}
                selectedPage={paginatorIndex}
                itemsCount={itemsCount}
                itemsToShow={itemsToShow}
                pagesToShow={5}
                isDisabled={isDisabled}
                className="mt-2"
              />
            )}
          </>
        )}
      </ul>
    </div>
  );
}
