import { gql } from '@apollo/client';
import { FullPricelist } from 'api/fragments/pricing';

export const GET_PRICELISTS = gql`
  ${FullPricelist}
  query pricelists($q: String, $offset: Int, $limit: Int) {
    pricelists(q: $q, offset: $offset, limit: $limit) {
      docs {
        ...FullPricelist
      }
      count
    }
  }
`;

// create a new pricelist
export const CREATE_PRICELIST = gql`
  mutation createPricelist($pricelist: NewPricelist!) {
    createPricelist(pricelist: $pricelist) {
      ...FullPricelist
    }
  }
  ${FullPricelist}
`;
// update the whole pricelist
export const UPDATE_PRICELIST = gql`
  mutation ($id: ID!, $pricelist: NewPricelist!) {
    updatePricelist(id: $id, pricelist: $pricelist) {
      ...FullPricelist
    }
  }
  ${FullPricelist}
`;

// delete a pricelist
export const DELETE_PRICELIST = gql`
  mutation ($documentId: ID!) {
    deletePricelist(id: $documentId) {
      done
    }
  }
`;
