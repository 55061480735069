import { useSystems } from 'components-ts/HealthSystems';
import { Footer, FooterProps } from './Footer';
import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useSubscribeFromStudiesBatch } from 'components-ts/HealthSystems/useLabSubscriptionActions';
import { defineMessages, useIntl } from 'react-intl';
import { IdTagTranslatedSubscriptionForm } from 'components-ts/HealthSystems/IdTagTranslatedSubscriptionForm';
import { useTranslatedStudies } from 'components-ts/HealthSystems/useTranslatedStudies';

const messages = defineMessages({
  title: { id: 'StudiesFirstStep.title', defaultMessage: 'Studies' },
  description: {
    id: 'StudiesFirstStep.description',
    defaultMessage:
      "Let's set up the studies that can be ordered at your health facility. You can manually select studies one-by-one from a comprehensive library. Or, you can import a WD pre-prepared list of studies and add or deselect items at your convenience. This will save you time, so we recommend this.",
  },
  tagsTitle: {
    id: 'StudiesFirstStep.tags_title',
    defaultMessage: 'You can choose from our predefined selection groups',
  },
  onlySelectedLabel: {
    id: 'UI.show_only_selected',
    defaultMessage: 'Only selected',
  },
});

type LabsStepProps = {
  footerProps: FooterProps;
};

interface UseStepActionParams {
  footerProps: FooterProps;
}

const useStepAction = (params: UseStepActionParams) => {
  const { footerProps } = params;

  const { getMySystem, getMyLocation } = useSystems();
  const system = getMySystem();
  const location = getMyLocation();

  const { onSubmit, isLoading, error } = useSubscribeFromStudiesBatch({
    systemId: system.id,
    locationId: location.id,
    onCompleted: footerProps.next,
  });

  const onNext = (selectedIds: Set<string>) => {
    if (selectedIds.size > 0) {
      onSubmit(Array.from(selectedIds));
    } else {
      footerProps.next();
    }
  };

  return {
    onNext,
    isSubmitting: isLoading,
    submittingError: error,
  };
};

export const LabsStep: React.FC<LabsStepProps> = (props) => {
  const { footerProps } = props;

  const intl = useIntl();

  const { getMyLocation } = useSystems();

  const location = getMyLocation();

  const { translatedStudies, isLoading: isLoadingStudies, error: errorLoadingStudies } = useTranslatedStudies();

  const { onNext, isSubmitting, submittingError } = useStepAction({ footerProps });

  const error = errorLoadingStudies ?? submittingError;

  return (
    <>
      <div className="mb-4">
        <h3>
          <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
        </h3>
        <p className="mt-2">
          <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
        </p>
      </div>
      <IdTagTranslatedSubscriptionForm
        onSubmit={(selectedIds) => onNext(new Set(selectedIds))}
        items={translatedStudies}
        isLoading={isLoadingStudies}
        error={error}
        initialValues={location.subscriptions.lab.items}
      >
        <Footer {...footerProps} next={() => false} isNextLoading={isSubmitting} isStepLoading={isLoadingStudies} />
      </IdTagTranslatedSubscriptionForm>
    </>
  );
};
