import { Nullable } from 'components-ts/utils';
import React from 'react';
import { getLocalStorage, setLocalStorage } from 'utils/browserUtilities';
export enum THEME {
  DARK = 'dark',
  LIGHT = 'light',
}
const isValidTheme = (value: string): boolean => {
  return (Object.values(THEME) as Array<string>).includes(value);
};

const isDarkModePreferred = window.matchMedia('(prefers-color-scheme: dark)');
const defaultTheme = isDarkModePreferred ? THEME.DARK : THEME.LIGHT;

export interface ThemeContextValue {
  theme: THEME;
  toggleTheme: () => void;
  updateTheme: (theme: THEME) => void;
}

const THEME_STORAGE_KEY = 'theme';
export const ThemeContext = React.createContext<Nullable<ThemeContextValue>>(null);
export const ThemeProvider = ({ children }) => {
  const fromLS = getLocalStorage(THEME_STORAGE_KEY);
  const initialTheme = isValidTheme(fromLS) ? fromLS : defaultTheme;
  const [theme, setTheme] = React.useState(initialTheme);

  const toggleTheme = () => {
    setTheme((prev) => (prev === THEME.DARK ? THEME.LIGHT : THEME.DARK));
  };

  const updateTheme = (newTheme: THEME) => {
    setTheme(newTheme);
  };
  const value = {
    theme,
    updateTheme,
    toggleTheme,
  };

  React.useEffect(() => {
    const app = document.body;

    setLocalStorage({ [THEME_STORAGE_KEY]: theme });
    if (app) {
      app.setAttribute('data-theme', theme);
    }
  }, [theme]);
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
