import React from 'react'
import { Button, ButtonGroup, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { COMMON_ROUTE_TYPES } from 'wd-common/src/logic/pharmacy/definitions'
import { DrugRoute } from 'components/Pharmacy'

// static translations
const messages = defineMessages({
  route: {
    id: 'RouteSelector.route_label',
    defaultMessage: 'Route'
  },
  route_po: {
    id: 'RouteSelector.route_po_label',
    defaultMessage: 'PO'
  },
  route_im: {
    id: 'RouteSelector.route_im_label',
    defaultMessage: 'IM'
  },
  route_iv: {
    id: 'RouteSelector.route_iv_label',
    defaultMessage: 'IV'
  }
})
/**
 * It allows the user to select a clinical status for visits
 * @component
 * @example
 *
 * const onChange = (route) => debug(route)
 * return (
 *   <RouteSelector
 *      value={route}
 *      onChange={onChange}
 *   />
 * )
 */
const RouteSelector = (props) => {
  const {
    value,
    onChange,
    isDisabled = false,
    className,
    intl
  } = props
  const { formatMessage } = intl

  const onRouteSelectorChange = route => {
    if (typeof onChange === 'function') {
      onChange(route)
    }
  }

  const onInputChange = event => {
    const route = event.target.value
    if (typeof onChange === 'function') {
      onChange(route)
    }
  }

  return <FormGroup >
    <Label className='m-0 mb-2'> <T id={messages.route.id}>{formatMessage(messages.route)}</T></Label>
    <InputGroup >
      <Input className={`${className || ''}`} value={value} onChange={onInputChange} disabled={isDisabled} autoComplete='off' />

      <InputGroupAddon addonType='append'>
        <ButtonGroup>
          {
            Object.keys(COMMON_ROUTE_TYPES).map((route, index) => {
              const uppercaseValue = (value || '').toUpperCase()
              return <Button
                disabled={isDisabled}
                key={route}
                color={`${uppercaseValue === route ? 'primary' : ''}`}
                className={`btn-small} ${uppercaseValue !== route && 'btn-outline-secondary'} `}
                onClick={e => {
                  if (uppercaseValue !== route) onRouteSelectorChange(route)
                }}
              >
                <DrugRoute route={route} />
              </Button>
            })
          }
        </ButtonGroup>
      </InputGroupAddon>
    </InputGroup>
  </FormGroup>
}

RouteSelector.defaultProps = {
  value: '',
  isDisabled: false,
  onChange: () => {}
}

export default injectIntl(RouteSelector)
