import flagUSA from '../../img/flags/USA.png';
import flagID from '../../img/flags/Indonezia.png';
import flagMX from '../../img/flags/Mexico.png';
import flagFR from '../../img/flags/France.png';
import flagSO from '../../img/flags/Somalia.png';
import { Nullable } from 'components-ts/utils';
import { defineMessages } from 'react-intl';
import browserDefaultLocale from 'locale2';
import { continents, countries, getEmojiFlag } from 'countries-list';

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';

  function supportedValuesOf(input: Key): string[];
}

export enum Locales {
  EN_US = 'en-us',
  ID_ID = 'id-id',
  ES_MX = 'es-mx',
  FR_FR = 'fr-fr',
  SO_SO = 'so-so',
}

export interface LocaleInfo {
  country: string;
  language: string;
  lang: string;
  flag: string;
  countryCode: string;
}

export const SUPPORTED_LOCALES: Record<Locales, LocaleInfo> = {
  [Locales.EN_US]: {
    countryCode: 'US',
    country: 'United States',
    flag: flagUSA,
    language: 'English',
    lang: 'en',
  },
  [Locales.ID_ID]: {
    countryCode: 'ID',
    country: 'Indonesia',
    flag: flagID,
    language: 'Bahasa Indonesia',
    lang: 'id',
  },
  [Locales.ES_MX]: {
    countryCode: 'MX',
    country: 'México',
    flag: flagMX,
    language: 'Español',
    lang: 'es',
  },
  [Locales.FR_FR]: {
    countryCode: 'FR',
    country: 'La France',
    flag: flagFR,
    language: 'French',
    lang: 'fr',
  },
  [Locales.SO_SO]: {
    countryCode: 'SO',
    country: 'Somalia',
    flag: flagSO,
    language: 'Somali ',
    lang: 'so',
  },
};

export enum UnitSystems {
  metric = 'metric',
  imperial = 'imperial',
}

export const isValidUnitSystem = (value: string): value is Locales => {
  return Object.values(UnitSystems).includes(value as UnitSystems);
};

export const DEFAUL_LOCALE = Locales.EN_US;

export const guessLocale = (): Locales => {
  if (isSupportedLocale(browserDefaultLocale)) {
    return browserDefaultLocale.toLocaleLowerCase() as Locales;
  }

  const similarLocal = guessLocaleByLang(browserDefaultLocale);
  if (similarLocal) {
    return similarLocal;
  }

  return DEFAUL_LOCALE;
};

export const getTimezoneOptions = (country?: string) => {
  const allTz = Intl.supportedValuesOf('timeZone');
  if (country && countries[country]) {
    const _country = countries[country];

    if (['SA', 'NA'].includes(_country.continent)) {
      return allTz.filter((tz) => tz.includes('America'));
    }

    const continent = continents[_country.continent];
    return allTz.filter((tz) => tz.includes(continent));
  }

  return allTz;
};

export const guessCountry = (): Nullable<string> => {
  const [, countryCode] = browserDefaultLocale.split('-');

  return countryCode;
};

export const guessCountryTimezones = (country: string) => {
  const zones = getTimezoneOptions(country);
  if (Array.isArray(zones)) {
    return zones[0];
  }

  return null;
};

export const guessLocaleByLang = (lang: string): Nullable<Locales> => {
  const [language] = lang.split('-');
  const found = Object.entries(SUPPORTED_LOCALES).find(([, localeInfo]) => {
    return localeInfo.lang === (language ?? '').toLocaleLowerCase();
  });

  if (Array.isArray(found)) {
    return found[0] as Locales;
  }

  return null;
};

export const isSupportedLocale = (value: string): value is Locales => {
  return Object.values(Locales).includes(value.toLowerCase() as Locales);
};

/**
 * This is temporary. We should upgrade react-intl
 * Latest versions use typescript and contain a hook api
 */
export interface DefinedMessage {
  id: string;
  defaultMessage: string;
}

/**
 * The defineMessage method returns what's passed-in
 * because it's meant to be a hook for babel-plugin-react-intl.
 */
export function typedDefineMessage<T extends Record<string, DefinedMessage>>(messages: T): T {
  return defineMessages(messages);
}

export type FormatMessage = (message: DefinedMessage, templateValues?: any) => string;
export type FormatNumber = (number: number, templateValues?: any) => string;

export const formatLocale = (locale: Nullable<string>) => {
  if (!locale) {
    return null;
  }

  if (!isSupportedLocale(locale)) {
    return null;
  }

  const _locale = SUPPORTED_LOCALES[locale];

  if (_locale.countryCode) {
    return `${getEmojiFlag(_locale.countryCode)} ${_locale.language} - ${_locale.country}`;
  }

  return null;
};
