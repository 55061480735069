import { Nullable } from 'components-ts/utils';
import { Signature } from './common';
import { BasicPatient } from './patients';
import { NewChiefComplaint } from './visits';

export enum EventRecurrence {
  UNIQUE = 'unique',
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

export enum CalendarSlotUnit {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
}

export interface NewRoomEvent {
  name: string;
  description?: string;
  recurrence: EventRecurrence;
  start: string;
  end: string;
  allDay?: boolean;
}
export interface RoomEvent {
  id: string;
  recurrence: EventRecurrence;
  name: string;
  description: Nullable<string>;
  start: string;
  end: string;
  allDay: Nullable<boolean>;
  updated: Signature;
}
export interface NewRegistrationData {
  type: string;
  location: string;
  room: string;
  start: string;
  end: string;
  patient: BasicPatient;
  telehealth?: boolean;
  chiefComplaint?: NewChiefComplaint;
}

export interface RegistrationData {
  type: string;
  location: string;
  room: string;
  start: string;
  end: string;
  patient: BasicPatient;
  telehealth: Nullable<boolean>;
  chiefComplaint: Nullable<NewChiefComplaint>;
}
export interface ScheduledVisit {
  id: string;
  registrationData: RegistrationData;
  created: Nullable<Signature>;
  updated: Nullable<Signature>;
}
