import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SystemsProvider } from 'components-ts/HealthSystems';
import {
  AsyncAuth,
  AsyncBackoffice,
  AsyncBilling,
  AsyncChecklists,
  AsyncContentLibraries,
  AsyncExams,
  AsyncHealthSystem,
  AsyncLab,
  AsyncMetrics,
  AsyncPatients,
  AsyncPharmacy,
  AsyncPreferences,
  AsyncPricelists,
  AsyncProcedures,
  AsyncQuickpicks,
  AsyncReports,
  AsyncScheduledVisits,
  AsyncSetup,
  AsyncTelehealth,
  AsyncTranslations,
  AsyncUnauth,
  AsyncVisits,
  AsyncLists,
  AsyncNotes,
} from './LazyImports';
import { Loading } from 'components-ts';
import { PharmacyItemsProvider } from 'components-ts/Pharmacy';
import { AppScenes, Header, Footer, sitemap, useSitemapAccess } from 'components-ts/Routing';
import { AccountStatus, useSession } from 'components-ts/Auth';
import { Sidebar } from './Sidebar';
import { assertUnreachable } from 'components-ts/utils';
import { ChartProvider, ChartProviderErrorHandler } from './WDContentProvider';
import { LazyExternalRedirectBanner } from './LazyRedirectBanner';
import { FirstSteps } from '../FirstSteps/FirstSteps';
import { IntegrationsHoC } from 'components-ts/HealthSystems/IntegrationsHoC';

export const RouterContainer: React.VFC = () => {
  const { isLoggedIn } = useSession();
  const isLogged = isLoggedIn();

  return (
    <div className="app">
      <Header />
      <div className={'app-body'}>
        <Suspense fallback={<Loading className="text-primary" />}>
          {isLogged ? <AuthRouter /> : <UnAuthRouter />}
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

/**
 * The setup progress contains 2 basic steps:
 * Payment checkout and Clinic customization
 *
 * This may seem confusing but using different routes allow us to restrict the navigation
 * No 403 or 404 screens
 */
const AuthRouter: React.VFC = () => {
  const { getSubscriptionActionUrl, getAccountStatus } = useSession();

  const actionURL = getSubscriptionActionUrl();
  if (actionURL) {
    return <LazyExternalRedirectBanner url={actionURL} />;
  }

  const accountStatus = getAccountStatus();

  switch (accountStatus) {
    case AccountStatus.PAYMENT_REQUIRED:
    case AccountStatus.REACTIVATION_REQUIRED:
    case AccountStatus.SETUP_PENDING:
    case AccountStatus.DEFAULT_MISSING:
      return (
        <>
          <main className="w-100 d-flex flex-column align-items-center">
            <Switch>
              <Route name={sitemap.setup.name} path={sitemap.setup.path} component={AsyncSetup} />
              <Route name={sitemap.preferences.name} path={sitemap.preferences.path} component={AsyncPreferences} />
              <Route name={sitemap.auth.name} path={sitemap.auth.path} component={AsyncAuth} />

              {/* default redirection to avoid white screens */}
              <Redirect name="Default to Checkout page" to={sitemap.setup.routes.checkout.path} />
            </Switch>
          </main>
          <Sidebar />
        </>
      );

    case AccountStatus.READ_ONLY_ACCESS:
    case AccountStatus.FULL_ACCESS:
      return <AuthRouterWithSystemInfo />;

    default:
      assertUnreachable('Invalida account status', accountStatus);
  }
};

const UnAuthRouter = () => {
  return (
    <main className="w-100 d-flex flex-column align-items-center my-auto">
      <AsyncUnauth />
    </main>
  );
};

const AuthRouterWithSystemInfo = () => {
  return (
    <SystemsProvider>
      <Sidebar />
      <Main />
    </SystemsProvider>
  );
};

/**
 * Everything set. This contain medical content contexts
 */
const Main: React.VFC = () => {
  const { canUserAccessToScene } = useSitemapAccess();

  return (
    <main className="w-100 d-flex flex-column align-items-center">
      <PharmacyItemsProvider>
        <ChartProvider>
          <ChartProviderErrorHandler>
            <FirstSteps>
              <IntegrationsHoC>
                <Switch>
                  <Route name={sitemap.auth.name} path={sitemap.auth.path} component={AsyncAuth} />

                  {canUserAccessToScene(AppScenes.BACKOFFICE) && (
                    <Route
                      path={sitemap[AppScenes.BACKOFFICE].path}
                      name={sitemap[AppScenes.BACKOFFICE].name}
                      component={AsyncBackoffice}
                    />
                  )}
                  {canUserAccessToScene(AppScenes.BILLING) && (
                    <Route
                      path={sitemap[AppScenes.BILLING].path}
                      name={sitemap[AppScenes.BILLING].name}
                      component={AsyncBilling}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.HEALTH_SYSTEMS) && (
                    <Route
                      path={sitemap[AppScenes.HEALTH_SYSTEMS].path}
                      name={sitemap[AppScenes.HEALTH_SYSTEMS].name}
                      component={AsyncHealthSystem}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.LAB) && (
                    <Route path={sitemap[AppScenes.LAB].path} name={sitemap[AppScenes.LAB].name} component={AsyncLab} />
                  )}

                  {canUserAccessToScene(AppScenes.METRICS) && (
                    <Route
                      path={sitemap[AppScenes.METRICS].path}
                      name={sitemap[AppScenes.METRICS].name}
                      component={AsyncMetrics}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.PATIENTS) && (
                    <Route
                      path={sitemap[AppScenes.PATIENTS].path}
                      name={sitemap[AppScenes.PATIENTS].name}
                      component={AsyncPatients}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.PRICING) && (
                    <Route
                      path={sitemap[AppScenes.PRICING].path}
                      name={sitemap[AppScenes.PRICING].name}
                      component={AsyncPricelists}
                    />
                  )}

                  <Route
                    path={sitemap[AppScenes.PREFERENCES].path}
                    name={sitemap[AppScenes.PREFERENCES].name}
                    render={() => <AsyncPreferences />}
                  />

                  {canUserAccessToScene(AppScenes.PROCEDURES) && (
                    <Route
                      path={sitemap[AppScenes.PROCEDURES].path}
                      name={sitemap[AppScenes.PROCEDURES].name}
                      component={AsyncProcedures}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.QUICKPICKS) && (
                    <Route
                      path={sitemap[AppScenes.QUICKPICKS].path}
                      name={sitemap[AppScenes.QUICKPICKS].name}
                      component={AsyncQuickpicks}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.REPORTS) && (
                    <Route
                      path={sitemap[AppScenes.REPORTS].path}
                      name={sitemap[AppScenes.REPORTS].name}
                      component={AsyncReports}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.SCHEDULER) && (
                    <Route
                      path={sitemap[AppScenes.SCHEDULER].path}
                      name={sitemap[AppScenes.SCHEDULER].name}
                      component={AsyncScheduledVisits}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.NOTES) && (
                    <Route
                      path={sitemap[AppScenes.NOTES].path}
                      name={sitemap[AppScenes.NOTES].name}
                      component={AsyncNotes}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.TELEHEALTH) && (
                    <Route
                      path={sitemap[AppScenes.TELEHEALTH].path}
                      name={sitemap[AppScenes.TELEHEALTH].name}
                      component={AsyncTelehealth}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.VISITS) && (
                    <Route
                      path={sitemap[AppScenes.VISITS].path}
                      name={sitemap[AppScenes.VISITS].name}
                      component={AsyncVisits}
                    />
                  )}

                  {canUserAccessToScene(AppScenes.PHARMACY) && (
                    <Route
                      path={sitemap[AppScenes.PHARMACY].path}
                      name={sitemap[AppScenes.PHARMACY].name}
                      component={AsyncPharmacy}
                    />
                  )}
                  {canUserAccessToScene(AppScenes.TRANSLATION) && (
                    <Route
                      path={sitemap[AppScenes.TRANSLATION].path}
                      name={sitemap[AppScenes.TRANSLATION].name}
                      component={AsyncTranslations}
                    />
                  )}
                  {canUserAccessToScene(AppScenes.CONTENT_LIBRARIES) && (
                    <Route
                      path={sitemap[AppScenes.CONTENT_LIBRARIES].path}
                      name={sitemap[AppScenes.CONTENT_LIBRARIES].name}
                      component={AsyncContentLibraries}
                    />
                  )}

                  <Route
                    path={sitemap[AppScenes.MEDICAL_REFERENCE].routes.checklists.path}
                    name={sitemap[AppScenes.MEDICAL_REFERENCE].routes.checklists.name}
                    component={AsyncChecklists}
                  />

                  {canUserAccessToScene(AppScenes.MEDICAL_REFERENCE) && (
                    <Route
                      path={sitemap[AppScenes.MEDICAL_REFERENCE].routes.exams.path}
                      name={sitemap[AppScenes.MEDICAL_REFERENCE].routes.exams.name}
                      component={AsyncExams}
                    />
                  )}
                  {canUserAccessToScene(AppScenes.MEDICAL_REFERENCE) && (
                    <Route
                      path={sitemap.medicalReference.routes.lists.path}
                      name={sitemap.medicalReference.routes.lists.name}
                      component={AsyncLists}
                    />
                  )}
                  <RedirectBasedOnPermissions />
                </Switch>
              </IntegrationsHoC>
            </FirstSteps>
          </ChartProviderErrorHandler>
        </ChartProvider>
      </PharmacyItemsProvider>
    </main>
  );
};

/**
 * We have a few groups of roles.
 * Most of them will work with patients but other won't have access to them
 * So, to avoid 404 pages when the user only have Lab or Pharmacy access, we've to implement this
 * custom redirection
 */
const RedirectBasedOnPermissions: React.VFC = () => {
  const { canUserAccessToScene } = useSitemapAccess();

  if (canUserAccessToScene(AppScenes.PATIENTS)) {
    return <Redirect name="Default to Patients" to={sitemap.patients.routes.waitingPatients.path} />;
  }

  if (canUserAccessToScene(AppScenes.PHARMACY)) {
    return <Redirect name="Default to Prescriptions" to={sitemap.pharmacy.routes.prescriptions.path} />;
  }

  if (canUserAccessToScene(AppScenes.LAB)) {
    return <Redirect name="Default to Studies" to={sitemap.lab.routes.studies.path} />;
  }

  if (canUserAccessToScene(AppScenes.BILLING)) {
    return <Redirect name="Default to Billing" to={sitemap.billing.routes.list.path} />;
  }

  return <Redirect name="Default to Health systems" to={sitemap.healthSystems.routes.systems.path} />;
};
