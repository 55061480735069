import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { CSVLink } from 'react-csv';
import { FaFileCsv as ExportCSVIcon } from 'react-icons/fa';
import { TranslationWrapper as T } from 'components-ts/Translations';
const messages = defineMessages({
  export: {
    id: 'UI.button_export',
    defaultMessage: 'Export',
  },
});

type CSVHeaderDescriptor<T> = {
  key: keyof T;
  label: string;
};

type Props<T> = {
  header: T;
  filename: string;
  csvDataRef: any;
};

export function ExportButton<T>(props: React.PropsWithChildren<Props<T>>) {
  const { header, filename, csvDataRef } = props;

  const intl = useIntl();

  const [data, setData] = React.useState<Array<T>>([]);

  const headers = getCsvHeaders(header);

  const onClick = (_event, done) => {
    if (csvDataRef && Array.isArray(csvDataRef.current)) {
      setData(csvDataRef.current);
      done();
    }
  };

  return (
    <CSVLink
      className=" btn btn-success"
      data={data}
      headers={headers}
      filename={filename}
      target="_blank"
      asyncOnClick={true}
      onClick={onClick}
    >
      <ExportCSVIcon />
      <T id={messages.export.id}>{intl.formatMessage(messages.export)}</T>
    </CSVLink>
  );
}

function getCsvHeaders<T>(header: T) {
  return Object.entries(header).reduce((acc, [key, label]) => {
    const d: CSVHeaderDescriptor<T> = {
      label: label as string,
      key: key as keyof T,
    };

    return [...acc, d];
  }, [] as Array<CSVHeaderDescriptor<T>>);
}
