import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryParams, useSession } from 'hooks';
import { DefaultSystemOrLocationAttr } from 'components-ts/Auth/utils';
import { DateRange, dateRangeFromPeriod, Period } from 'components-ts/DateAndTime/utils';
import { Nullable } from 'components-ts/utils';
import { BillableCategory } from 'components-ts/Billing/utils';
import { VisitType } from 'api/interfaces';

export interface DiseasesReportFilter {
  from: string;
  to: string;
  location: Array<string>;
  visitType: Array<VisitType>;
  doctor: Array<string>;
  diagnosis: Array<string>;
}

enum Filter {
  FROM = 'from',
  TO = 'to',
  LOCATION = 'location',
  DOCTOR = 'doctor',
  DIAGNOSIS = 'diagnosis',
  VISIT_TYPE = 'visitType',
}

interface UseDiseasesReportFilter {
  filter: DiseasesReportFilter;
  onLocationChange: (location: Array<string>) => void;
  onVisitTypeChange: (visitType: Array<VisitType>) => void;
  onDoctorChange: (doctor: Array<string>) => void;
  onDiagnosisChange: (category: Array<string>) => void;
  onPeriodChange: (period: DateRange) => void;
}

function queryParamToArray<T>(value: Nullable<string>): Array<T> {
  if (value === null) {
    return [];
  }

  try {
    const array = JSON.parse(value);

    if (Array.isArray(array)) {
      return array as Array<T>;
    }
  } catch (error) {}

  return [];
}

const DEFAULT_PERIOD = dateRangeFromPeriod(Period.PAST_MONTH);

export const useDiseasesReportFilter = (): UseDiseasesReportFilter => {
  // replate it after migrating the session content
  const { getUserLocation } = useSession() as any;
  const { id: defaultLocationId } = getUserLocation() as DefaultSystemOrLocationAttr;

  // url synchronized filter
  const queryParams = useQueryParams();
  const history = useHistory();

  const initialFrom = queryParams.get(Filter.FROM) ?? DEFAULT_PERIOD.from.toISOString();
  const initialTo = queryParams.get(Filter.TO) ?? DEFAULT_PERIOD.to.toISOString();

  const [filter, setFilter] = React.useState<DiseasesReportFilter>({
    from: initialFrom,
    to: initialTo,
    location: queryParamToArray<string>(queryParams.get(Filter.LOCATION)) ?? [defaultLocationId],
    doctor: queryParamToArray<string>(queryParams.get(Filter.DOCTOR)),
    diagnosis: queryParamToArray<BillableCategory>(queryParams.get(Filter.DIAGNOSIS)),
    visitType: queryParamToArray<VisitType>(queryParams.get(Filter.VISIT_TYPE)),
  });

  const onLocationChange = (location: Array<string>) => {
    setFilter((prev) => ({
      ...prev,
      location,
      doctor: [],
    }));

    if (location.length > 0) {
      queryParams.set(Filter.LOCATION, JSON.stringify(location));
    } else {
      queryParams.delete(Filter.LOCATION);
    }

    /**
     * These ones depend on the selected location
     */
    queryParams.delete(Filter.DOCTOR);

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onVisitTypeChange = (visitType: Array<VisitType>) => {
    setFilter((prev) => ({ ...prev, visitType }));

    if (visitType.length > 0) {
      queryParams.set(Filter.VISIT_TYPE, JSON.stringify(visitType));
    } else {
      queryParams.delete(Filter.VISIT_TYPE);
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onDoctorChange = (doctor: Array<string>) => {
    setFilter((prev) => ({ ...prev, doctor }));

    if (doctor.length > 0) {
      queryParams.set(Filter.DOCTOR, JSON.stringify(doctor));
    } else {
      queryParams.delete(Filter.DOCTOR);
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onDiagnosisChange = (diagnosis: Array<string>) => {
    setFilter((prev) => ({ ...prev, diagnosis: diagnosis ?? [] }));

    // I cannot refactor the checklist selecter right now.
    // we should define types first
    if (Array.isArray(diagnosis) && diagnosis.length > 0) {
      queryParams.set(Filter.DIAGNOSIS, JSON.stringify(diagnosis));
    } else {
      queryParams.delete(Filter.DIAGNOSIS);
    }

    history.replace({
      search: queryParams.toString(),
    });
  };

  const onPeriodChange = (period: DateRange) => {
    if (!period.from || !period.to) {
      return;
    }

    const from = period.from.toISOString();
    const to = period.to.toISOString();

    setFilter((prev) => ({ ...prev, from, to }));

    queryParams.set(Filter.FROM, from);
    queryParams.set(Filter.TO, to);

    history.replace({
      search: queryParams.toString(),
    });
  };

  return {
    filter,
    onLocationChange,
    onVisitTypeChange,
    onDoctorChange,
    onDiagnosisChange,
    onPeriodChange,
  };
};
