import React from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { Nullable } from 'components-ts/utils';
import { BillableCategory, isValidBillableCategory } from 'components-ts/Billing/utils';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from '.';

const messages = defineMessages({
  category: {
    id: 'UI.label_category',
    defaultMessage: 'Category',
  },
});

/**
 * Record will force us to add the translation
 * every time we updated the visit type list
 * The backend js definition is on common/src/logic/billing/definitions
 */
const categoriesTranslations: Record<BillableCategory, MessageDescriptor> = defineMessages({
  [BillableCategory.ADMINISTRATION]: {
    id: 'BillableCategory.administration',
    defaultMessage: 'Administration',
  },
  [BillableCategory.DOCTOR_FEE]: {
    id: 'BillableCategory.doctor_fee',
    defaultMessage: 'Doctor fee',
  },
  [BillableCategory.LAB]: {
    id: `BillableCategory.lab`,
    defaultMessage: 'Lab',
  },
  [BillableCategory.MEDICINES]: {
    id: `BillableCategory.medicines`,
    defaultMessage: 'Medicines',
  },
  [BillableCategory.OTHERS]: {
    id: 'BillableCategory.category_name_other',
    defaultMessage: 'Other',
  },
  [BillableCategory.PROCEDURES]: {
    id: `BillableCategory.procedures`,
    defaultMessage: 'Procedures',
  },
});

/**
 * See the IntlDefineMessage definition
 */
const translationMessages = defineMessages(categoriesTranslations);

type BillableCategorySelectorProps = SelectorProps<BillableCategory> & {
  onlyCommodities?: boolean;
  className?: string;
};

export const BillableCategorySelector: React.FC<BillableCategorySelectorProps> = (props) => {
  const { value, isClearable, isDisabled, onlyCommodities, className } = props;

  const intl = useIntl();

  const formatOption = (visitType: BillableCategory): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(translationMessages[visitType]),
      value: visitType,
    };
  };

  const options = onlyCommodities
    ? Object.values(BillableCategory)
        .filter((item) =>
          [BillableCategory.LAB, BillableCategory.MEDICINES, BillableCategory.PROCEDURES].includes(item)
        )
        .map(formatOption)
    : Object.values(BillableCategory).map(formatOption);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return null;
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
        if (isValidBillableCategory(value)) {
          return [...prev, formatOption(value as BillableCategory)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      if (isValidBillableCategory(value as string)) {
        return formatOption(value as BillableCategory);
      }

      return null;
    }
  };

  const _onChange = (v: ReactSelectInternalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(v)) {
        return props.onChange([]);
      }

      const selectedValues = v as Array<ReactSelectInternalSingleValue>;
      const newValues = selectedValues.map((item) => item.value);

      props.onChange(newValues as Array<BillableCategory>);
    } else {
      const selectedValue = v as ReactSelectInternalSingleValue;

      props.onChange(selectedValue.value as BillableCategory);
    }
  };

  return (
    <FormGroup>
      <Label>
        <T id={messages.category.id}>{intl.formatMessage(messages.category)}</T>
      </Label>
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    </FormGroup>
  );
};
