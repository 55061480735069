import { Nullable } from 'components-ts/utils';
import { SubscriptionProvider, SubscriptionStatus, SubscriptionType } from '../../api/interfaces';
import { addDays } from 'components-ts/DateAndTime';

/**
 * We  need to define q kind of account status based on the stripe subscription
 * status
 *
 * Sign up:
 * User will get their account thru cognito.
 * The initial status is PAYMENT_REQUIRED. User only will see the initial
 * checkout page, and the preferences page with basic features
 * like settigs for 2FA, locale, and theme
 *
 * Payment:
 * The status will change to SETUP_PENDING (Subscription account can be Active or Trailing in this step)
 * In this moment the user will only be able to create and set up
 * their own clinic
 *
 * Clinic setup:
 * After creating and configuring the clinic, the status will change
 * to FULL_ACCESS, and that means the user will see all the features that not
 * require admin access.
 *
 * Expiration / Payment rejected
 * If the subcription expires, subscription will change to past_due and account status to
 * READ_ONLY_ACCESS. In this situation, the existing user data will be accesible for ONE WEEK but all
 * the mutation will be blocked
 * User should update the payment method in the preferences page to recover their subscription
 *
 * Cancelation
 * After the past due period, the subscription will be canceled, the account status will change to
 * REACTIVATION_REQUIRED.
 * This status will behave like PAYMENT_REQUIRED, but users with a canceled subscription will have a stripe
 * customer linked to their account.
 *
 */
export enum AccountStatus {
  PAYMENT_REQUIRED = 'paymentRequired',
  SETUP_PENDING = 'setupPending',
  FULL_ACCESS = 'fullAccess',
  READ_ONLY_ACCESS = 'readOnlyAccess',
  REACTIVATION_REQUIRED = 'reactivationRequired',
  DEFAULT_MISSING = 'defaultMissing',
}

// optional fields because comes from a json parse, not from apollo
export interface Subscription {
  customerId: string;
  provider: SubscriptionProvider;
  type: SubscriptionType;
  expirationDate?: string;
  status?: SubscriptionStatus;
  subscriptionId?: string;
  url?: string;
}

export const isValidAccountStatus = (value: string): boolean => {
  return (Object.values(AccountStatus) as Array<string>).includes(value);
};

export const isValidSubscriptionStatus = (value: string): boolean => {
  return (Object.values(SubscriptionStatus) as Array<string>).includes(value);
};

export const isValidSubscriptionProvider = (value: string): boolean => {
  return (Object.values(SubscriptionProvider) as Array<string>).includes(value);
};

export const isValidSubscriptionType = (value: string): boolean => {
  return (Object.values(SubscriptionType) as Array<string>).includes(value);
};

/**
 * Check if the subscription status allows the user to use the app
 */
export const isFullAccessAllowed = (subscription: Subscription): boolean => {
  switch (subscription.type) {
    case SubscriptionType.RECURRING:
      return subscription.status === SubscriptionStatus.ACTIVE || subscription.status === SubscriptionStatus.TRIALING;

    case SubscriptionType.ONE_TIME_PAYMENTS: {
      if (!subscription?.expirationDate) {
        return false;
      }

      const expirationDate = new Date(subscription.expirationDate);

      const now = new Date();

      return expirationDate > now;
    }

    default:
      return false;
  }
};

/**
 * Check if the subscription status allows the user to use the app
 */
export const isReadOnlyAccessAllowed = (subscription: Subscription): boolean => {
  switch (subscription.type) {
    case SubscriptionType.RECURRING:
      return subscription.status === SubscriptionStatus.PAST_DUE;

    case SubscriptionType.ONE_TIME_PAYMENTS: {
      if (!subscription?.expirationDate) {
        return false;
      }

      const expirationDate = new Date(subscription.expirationDate);
      const pastDueAllowedDate = addDays(expirationDate, 7);
      const now = new Date();

      return pastDueAllowedDate > now;
    }

    default:
      return false;
  }
};

/**
 * Check if the subscription status has any kind of access to the app
 */
export const isAccessAllowed = (subscription: Subscription): boolean => {
  switch (subscription.type) {
    case SubscriptionType.RECURRING:
      return (
        subscription.status === SubscriptionStatus.ACTIVE ||
        subscription.status === SubscriptionStatus.TRIALING ||
        subscription.status === SubscriptionStatus.PAST_DUE
      );

    case SubscriptionType.ONE_TIME_PAYMENTS: {
      if (!subscription?.expirationDate) {
        return false;
      }

      const expirationDate = new Date(subscription.expirationDate);
      const pastDueAllowedDate = addDays(expirationDate, 7);
      const now = new Date();

      return pastDueAllowedDate > now;
    }

    default:
      return false;
  }
};

/**
 * Validate subscription
 * @param value Nullable<string>
 */
export const isValidSubscriptionAttr = (subcription: Nullable<string>): boolean => {
  if (subcription === null) {
    return false;
  }

  try {
    const attr = JSON.parse(subcription);

    if (!isValidSubscriptionProvider(attr.provider)) {
      return false;
    }

    if (!isValidSubscriptionType(attr?.type)) {
      return false;
    }

    if (typeof attr?.customerId !== 'string') {
      return false;
    }

    switch (attr?.type) {
      case SubscriptionType.RECURRING:
        return typeof attr?.subscriptionId === 'string' && isValidSubscriptionStatus(attr?.status);

      case SubscriptionType.ONE_TIME_PAYMENTS:
        return typeof attr?.expirationDate === 'string';

      default:
        return false;
    }
  } catch (e) {
    return false;
  }
};
