import { FrequencyShow } from 'api/interfaces';
import { isAValidFrequencyShow } from './utils';

export enum RX_VALIDATION_ERROR {
  INVALID_DOSE = 'invalid_dose_value',
  INVALID_DOSE_UNIT = 'invalid_dose_unit',
  INVALID_UNITS_PER_DOSE = 'invalid_units_per_dose',
  INVALID_ROUTE = 'invalid_route',
  INVALID_FREUQUECY_SHOW = 'invalid_frequency_show',
  INVALID_DOSES_PER_DAY = 'invalid_doses_per_day',
  INVALID_DAYS = 'invalid_days',
  INVALID_DISPENSE = 'invalid_dispense',
}

export const validateRXForm = (values) => {
  const { days, doseNumber, doseUnits, unitsPerDose, dispense, dosesPerDay, route, frequencyShow } = values || {};

  if (!(doseNumber > 0)) return RX_VALIDATION_ERROR.INVALID_DOSE;

  if (!doseUnits) return RX_VALIDATION_ERROR.INVALID_DOSE_UNIT;

  if (!(unitsPerDose > 0)) return RX_VALIDATION_ERROR.INVALID_UNITS_PER_DOSE;

  if (!route) return RX_VALIDATION_ERROR.INVALID_ROUTE;

  if (!isAValidFrequencyShow(frequencyShow)) return RX_VALIDATION_ERROR.INVALID_FREUQUECY_SHOW;

  if (!(dosesPerDay > 0)) return RX_VALIDATION_ERROR.INVALID_DOSES_PER_DAY;

  if (frequencyShow !== FrequencyShow.DOSES && !(days > 0)) return RX_VALIDATION_ERROR.INVALID_DAYS;

  if (!(dispense > 0)) return RX_VALIDATION_ERROR.INVALID_DISPENSE;

  return null;
};
