import React, { useState } from 'react'
import { Button, ButtonGroup, FormGroup, Label } from 'reactstrap'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { SEX } from 'wd-common/src/logic/patient/definitions'
import { Sex } from 'components'
import { IoMdFemale as FemaleIcon, IoMdMale as MaleIcon } from 'react-icons/io'
const debug = require('debug')('wd:SexSelector')

// static translations
export const messages = defineMessages({
  label: {
    id: 'SexSelector.label',
    defaultMessage: 'Sex'
  },
  female: {
    id: 'Sex.Female',
    defaultMessage: 'Female'
  },
  male: {
    id: 'Sex.Male',
    defaultMessage: 'Male'
  }
})

/**
 * It allows the user to select the patient sex
 * @component
 * @example
 *
 * const onChange = (sex) => debug(sex)
 * return (
 *   <SexSelector
 *      value={customFieldType}
 *      onChange={onChange}
 *   />
 * )
 */
const SexSelector = (props) => {
  // props
  const {
    value: _sex,
    onChange,
    disabled,
    className,
    intl
  } = props

  const { formatMessage } = intl

  if (typeof onChange !== 'function') debug('Missing prop: onChange')

  const formatOption = sex => {
    switch (sex) {
      case SEX.FEMALE:
        return {
          value: sex,
          label: <p className='m-0 d-flex align-items-center justify-content-center'>
            <FemaleIcon className={'mr-1'} />
            <Sex sex={sex} />
          </p>
        }

      case SEX.MALE:
        return {
          value: sex,
          label: <p className='m-0 d-flex align-items-center justify-content-center'>
            <MaleIcon className={'mr-1'} />
            <Sex sex={sex} />
          </p>
        }

      default:
        return ''
    }
  }

  const options = Object.values(SEX).map(formatOption)

  const initialValue = _sex ? formatOption(_sex) : ''

  const [sex, setSex] = useState(initialValue)

  const _onChange = (val) => {
    setSex(val)
    if (typeof onChange === 'function') onChange((val || {}).value)
  }
  debug(sex)
  return <FormGroup className=''>
    <Label>
      <T id={messages.label.id}>{formatMessage(messages.label)}</T>
    </Label>
    <ButtonGroup className={className || 'form-control border-0 p-0'}>
      {
        options.map(option => {
          return <Button
            key={`sex-${option.value}`}
            className={`btn-sm`}
            type='button'
            color={sex.value === option.value ? 'primary' : 'light'}
            onClick={() => _onChange(option)}
            disabled={disabled}>
            <span className='h6 m-0'>
              {option.label}
            </span>
          </Button>
        })
      }

    </ButtonGroup>
  </FormGroup>
}

SexSelector.defaultProps = {
  value: '',
  onChange: () => {}
}
export default injectIntl(SexSelector)
