import { Nullable } from 'components-ts/utils';
import { Signature, TranslatableText } from './common';
import { CustomField } from './customFields';
import { BPJSSettings, Integrations, XenditSettings } from './integrations';
import { CalendarSlotUnit, RoomEvent } from './scheduler';
import { BPJSCredentials } from './bpjs';
import { DefaultSystemOrLocationAttr } from 'components-ts/Auth/utils';

export interface Slot {
  length: number;
  unit: CalendarSlotUnit;
}

export interface BusinessHours {
  daysOfWeek: Array<number>;
  startTime: string;
  endTime: string;
}
export interface SchedulerSettings {
  slot: Slot;
  notifications: Nullable<boolean>;
  businessHours: Nullable<Array<BusinessHours>>;
}

export interface Room {
  id: string;
  name: string;
  description: string;
  events: Array<RoomEvent>;
  available: boolean;
  updated: Signature;
}

export interface NewRoom {
  name: string;
  description: Nullable<string>;
  available: boolean;
}

export interface ItemSubscriptions {
  items: Array<string>;
  updated: Nullable<Signature>;
}

export interface TagSubscriptions {
  tags: Array<TranslatableText>;
  updated: Nullable<Signature>;
}

export interface PaymentSettings {
  defaultPricelist: Nullable<string>;
  currency: Nullable<string>;
  xenditSubaccount: Nullable<string>;
}

export interface NewPaymentSettings {
  defaultPricelist?: string;
  currency?: string;
  xenditSubaccount?: string;
}

export interface LocationSettings {
  payment: Nullable<PaymentSettings>;
  scheduler: SchedulerSettings;
  integrations: Nullable<LocationIntegrationsSettings>;
}

export interface SubscriptionSettings {
  triage: ItemSubscriptions;
  lab: ItemSubscriptions;
  procedures: ItemSubscriptions;
  checklists: ItemSubscriptions;
  visitTypes: Nullable<ItemSubscriptions>;
}
export interface LocationBPJSSettings {
  polyclinicId: string;
  credentials: Nullable<BPJSCredentials>;
}

export interface LocationIntegrationsSettings {
  [Integrations.BPJS]: LocationBPJSSettings;
}

export interface Location {
  id: string;
  name: string;
  address: string;
  phoneNumber: string;
  mainContact: Nullable<string>;
  subscriptions: SubscriptionSettings;
  settings: LocationSettings;
  rooms: Array<Room>;
  created: Signature;
  updated: Signature;
}

export interface RegistrationGroup {
  id: string;
  name: string;
  customFields: Array<string>;
}

export interface RegistrationSettings {
  fields: Array<CustomField>;
  groups: Array<RegistrationGroup>;
}

export interface HealthSystemPharmacySettings {
  format: string;
  pharmacyEducation: Nullable<ItemSubscriptions>;
  defaultPharmacy: Nullable<DefaultSystemOrLocationAttr>;
}
export interface HealthSystemIntegrationsSettings {
  [Integrations.BPJS]: Nullable<BPJSSettings>;
  [Integrations.XENDIT]: Nullable<XenditSettings>;
}

export interface DailyReportWebhookSettings {
  isEnabled: boolean;
  url: string;
  parser: string;
  authorizationKey?: string;
  emailToNotify?: string;
}
export interface HealthSystemWebhooksSettings {
  dailyReport: Nullable<DailyReportWebhookSettings>;
}
export interface HealthSystemSettings {
  registration: Nullable<RegistrationSettings>;
  pharmacy: HealthSystemPharmacySettings;
  integrations: Nullable<HealthSystemIntegrationsSettings>;
  webhooks: Nullable<HealthSystemWebhooksSettings>;
}

export interface HealthSystem {
  id: string;
  name: string;
  country: string;
  timezone: string;
  locale: string;
  locations: Array<Location>;
  settings: HealthSystemSettings;
  logoFileId: Nullable<string>;
  firstStepsDone: Nullable<boolean>;
  updated: Signature;
  created: Signature;
  deleted: Signature;
}

export interface NewTextListCustomField {
  name: string;
  required: boolean;
  options: Array<string>;
}

export interface NewPrimitiveCustomField {
  type: string;
  name: string;
  required: boolean;
}

export interface NewRegistrationGroup {
  name: string;
  customFields: Array<string>;
}

export interface StoreStatus {
  id: string;
  isReady: boolean;
}
