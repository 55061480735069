import { gql } from '@apollo/client';
import { FullTranslatableText } from 'api/fragments/common';
import { FullProcedure } from 'api/fragments/procedures';

/**
 *
 * All these request are related to procedures
 * Right know procedures are small. All the fields are basic fields
 * So, it doesn't care if we use FullProcedures in the paginated query
 * In case it increases it size, create a BasicProcedure type and change this fragment
 */
export const GET_PROCEDURES = gql`
  ${FullProcedure}
  query procedures($q: String, $offset: Int, $limit: Int, $tags: [String], $ids: [String]) {
    procedures(q: $q, offset: $offset, limit: $limit, tags: $tags, ids: $ids) {
      docs {
        ...FullProcedure
      }
      count
    }
  }
`;

export const GET_PROCEDURE_BY_ID = gql`
  ${FullProcedure}
  query procedure($id: ID!) {
    procedure(id: $id) {
      ...FullProcedure
    }
  }
`;

export const CREATE_PROCEDURE = gql`
  ${FullProcedure}
  mutation createProcedure($newProcedure: NewProcedure!) {
    createProcedure(newProcedure: $newProcedure) {
      ...FullProcedure
    }
  }
`;

export const UPDATE_PROCEDURE = gql`
  ${FullProcedure}
  mutation updateProcedure($id: ID!, $newProcedure: NewProcedure!) {
    updateProcedure(id: $id, newProcedure: $newProcedure) {
      ...FullProcedure
    }
  }
`;

export const DELETE_PROCEDURE = gql`
  mutation deleteProcedure($id: ID!) {
    deleteProcedure(id: $id) {
      done
    }
  }
`;

export const GET_PROCEDURE_TAGS = gql`
  ${FullTranslatableText}
  query procedureTags {
    procedureTags {
      ...FullTranslatableText
    }
  }
`;
