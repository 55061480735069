import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'

// static translations
const messages = defineMessages({
  forbidden: {
    id: 'Forbidden.dont_have_permissions',
    defaultMessage: "You don't have permissions to access this page."
  }
})

const Forbidden = (props) => {
  const { intl } = props
  const { formatMessage } = intl

  return <div className='animated fadeIn container vh-100 d-flex flex-column align-items-center justify-content-center text-center'>
    <h1 className='text-dark'>403</h1>
    <p className='text-muted'>
      <T id={messages.forbidden.id}>{formatMessage(messages.forbidden)}</T>
    </p>
  </div>
}
export default injectIntl(Forbidden)
