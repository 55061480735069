import { ActiveVisit, FullPatient } from 'api/interfaces';
import { Nullable } from 'components-ts/utils';
import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { PatientContext, PatientWithActiveVisitAndHistoryContextValue } from './PatientContext';

export interface PatientContextValue {
  patient: FullPatient;
  activeVisit: Nullable<ActiveVisit>;
  isLoading: boolean;
  error: Nullable<MessageDescriptor>;
}

export const usePatientContext = (): PatientContextValue => {
  const ctx = React.useContext(PatientContext);
  validateContextValue(ctx);

  const { data, isLoading, error } = ctx;
  const { active_visit: activeVisit, ...patient } = data.patient;

  const value = {
    patient,
    activeVisit,
    isLoading,
    error,
  };

  return value;
};

function validateContextValue(
  value: Nullable<PatientWithActiveVisitAndHistoryContextValue>
): asserts value is PatientWithActiveVisitAndHistoryContextValue {
  if (value === null) {
    throw TypeError('Using patient out of the provider');
  }
}
