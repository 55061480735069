import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import { Badge } from 'reactstrap';
import { FaRegCheckCircle as AckIcon, FaBan as CancelIcon, FaSyringe as AdministerIcon } from 'react-icons/fa';
import { AiOutlineClockCircle } from 'react-icons/ai';

const messages = defineMessages({
  cancelLabel: {
    id: 'UI.cancelled_label',
    defaultMessage: 'Cancelled',
  },
  acknowledgedLabel: {
    id: 'UI.acknowledged_label',
    defaultMessage: 'Acknowledged',
  },
  administeredLabel: {
    id: 'UI.administered_label',
    defaultMessage: 'Administered',
  },
  filledLabel: {
    id: 'UI.filled_label',
    defaultMessage: 'Filled',
  },
  receivedLabel: {
    id: 'UI.received_label',
    defaultMessage: 'Received',
  },
});

interface OrderStatusProps {
  acknowledged?: boolean;
  administered?: boolean;
  canceled?: boolean;
  filled?: boolean;
  received?: boolean;
  className?: string;
}

export const OrderStatus: React.FC<OrderStatusProps> = (props) => {
  const { acknowledged, administered, canceled, filled, received, className } = props;

  const intl = useIntl();

  return (
    <div className={`d-flex align-items-center ${className || ''}`}>
      {filled && !canceled && (
        <Badge pill color="success" className="mr-1 d-flex align-items-center justify-content-center">
          <AckIcon className="mr-1" />
          <T id={messages.filledLabel.id}>{intl.formatMessage(messages.filledLabel)}</T>
        </Badge>
      )}
      {canceled && (
        <Badge pill color="danger" className="mr-1 d-flex align-items-center justify-content-center">
          <CancelIcon className="mr-1" />
          <T id={messages.cancelLabel.id}>{intl.formatMessage(messages.cancelLabel)}</T>
        </Badge>
      )}
      {received && !filled && !canceled && (
        <Badge pill color="warning" className="mr-1 d-flex align-items-center justify-content-center">
          <AiOutlineClockCircle className="mr-1" />
          <T id={messages.receivedLabel.id}>{intl.formatMessage(messages.receivedLabel)}</T>
        </Badge>
      )}
      {administered && !canceled && (
        <Badge pill color="success" className="mr-1 d-flex align-items-center justify-content-center">
          <AdministerIcon className="mr-1" />
          <T id={messages.filledLabel.id}>{intl.formatMessage(messages.administeredLabel)}</T>
        </Badge>
      )}
      {acknowledged && !received && !canceled && !filled && (
        <Badge pill className="mr-1 d-flex align-items-center justify-content-center">
          <T id={messages.acknowledgedLabel.id}>{intl.formatMessage(messages.acknowledgedLabel)}</T>
        </Badge>
      )}
    </div>
  );
};
