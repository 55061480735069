import { isValidObjectId, validateArray, validateNonEmptyObject, validateString } from '../utils';

/**
 * Walking Doctors Role / Permission definitions
 *
 * Introduction: this system allows you to store role-based permissions as a
 * JSON blob and run various checks on them.
 *
 * Three types of roles are supported:
 *
 * 1) Global roles: These are in effect for the whole app, not just an
 * individual health system or location For example, you can have a role that
 * makes the user an Admin for the whole WD system, or a role that marks a
 * user as inactive for all health systems.
 *
 * 2) System roles: These roles apply to an entire health system and all the
 * locations inside it. For example, if a user has the Doctor role for a
 * health system, they are a doctor for every location in that system. The
 * special role SystemAdmin controls administrative access to an entire
 * system. Any system role also counts as a local role in all the locations in
 * a system.
 *
 * 3) Local roles: These roles apply only to a specific location inside a
 * health system. For example a Doctor at Locaiton X can only access patients
 * at Location X, unless they also have permission at Location Y, or if they
 * have a system permission. The special role LocalAdmin provides
 * administrative access to a location.
 *
 * Format:
 *
 * The internal permissions format is designed to be compact, rather than
 * readable. The Permissions class provides full API access to this format.
 *
 * Example
 * const permissions: PermissionsObject = {
 *  roles: [
 *    GlobalRole.ADMIN, // This is the list of global roles.
 *    GlobalRole.TRANSLATOR, // Values in here apply across all locations.
 *  ]
 *  '59c477a3e7eec54535164c29': {
 *    // Entry for a health system
 *    roles: [CustomerRole.SYSTEM_ADMIN],
 *    '59c477a3e7eec54535164c26': [
 *      // Entry for a specific location
 *      CustomerRole.DOCTOR,
 *      CustomerRole.LOCAL_ADMIN
 *    ]
 *  },
 *  '59cedfba9ae80d05757f54e9': {
 *    // Another system example, users can
 *    roles: [
 *      // be members of more than one system
 *      CustomerRole.DOCTOR,
 *      CustomerRole.NURSE,
 *      CustomerRole.PHARMACIST,
 *   ],
 *   '59cedfba9ae80d05757f54e7': [
 *     // Another location
 *     CustomerRole.PHARMACIST, // Specific local role
 *   ]
 *  }
 * }
 */

export enum GlobalRole {
  ADMIN = 'Admin',
  TRANSLATOR = 'Translator',
}

export enum CustomerRole {
  SYSTEM_ADMIN = 'SystemAdmin',
  LOCAL_ADMIN = 'LocalAdmin',
  DOCTOR = 'Doctor',
  NURSE = 'Nurse',
  PHARMACIST = 'Pharmacist',
  PHARMACIST_ADMIN = 'PharmacyAdmin',
  REGISTRAR = 'Registrar',
  BILLING = 'Billing',
  BILLING_ADMIN = 'BillingAdmin',
  LAB = 'Lab',
  REPORT_USER = 'ReportUser',
  EXTERNAL_REPORTING_ADMIN = 'ExternalReportingAdmin',
  EXTERNAL_REPORTING = 'ExternalReporting',
}

// export const permissionsExample: PermissionsObject = {
//   roles: [
//     GlobalRole.ADMIN, // This is the list of global roles.
//     GlobalRole.TRANSLATOR, // Values in here apply across all locations.
//   ],
//   '59c477a3e7eec54535164c29': {
//     // Entry for a health system
//     roles: [CustomerRole.SYSTEM_ADMIN],
//     '59c477a3e7eec54535164c26': [
//       // Entry for a specific location
//       CustomerRole.DOCTOR,
//       CustomerRole.LOCAL_ADMIN,
//     ],
//   },
//   '59cedfba9ae80d05757f54e9': {
//     // Another system example, users can
//     roles: [
//       // be members of more than one system
//       CustomerRole.DOCTOR,
//       CustomerRole.NURSE,
//       CustomerRole.PHARMACIST,
//     ],
//     '59cedfba9ae80d05757f54e7': [
//       // Another location
//       CustomerRole.PHARMACIST, // Specific local role
//     ],
//   },
// };

export const AllRoles: Array<Roles> = [...Object.values(GlobalRole), ...Object.values(CustomerRole)];

export type SystemRole = Exclude<CustomerRole, CustomerRole.LOCAL_ADMIN>;
export type LocalRole = Exclude<CustomerRole, CustomerRole.SYSTEM_ADMIN>;
export type Roles = GlobalRole | CustomerRole;

export const localRoles = Object.values(CustomerRole).filter((role) => role !== CustomerRole.SYSTEM_ADMIN);
export const systemRoles = Object.values(CustomerRole).filter((role) => role !== CustomerRole.LOCAL_ADMIN);
export const globalRoles = Object.values(GlobalRole) as Array<string>;

/**
 * These must be sets instead of arrays
 */
type SpecificLocationPermissions = Record<string, Array<LocalRole>> & Partial<Record<'roles', never>>;
export type SystemPermissions =
  | SpecificLocationPermissions
  | {
      roles: Array<SystemRole>;
    };

type SpecificSystemPermissions = Record<string, SystemPermissions> & Partial<Record<'roles', never>>;

export type PermissionsObject = SpecificSystemPermissions | { roles: Array<GlobalRole> };

/**
 * Validates a permissions object and provides functions to check and modify permissions.
 */
export class Permissions {
  private permissions: PermissionsObject;

  constructor(rawPermissions: PermissionsObject | string) {
    if (typeof rawPermissions === 'string') {
      const obj = JSON.parse(rawPermissions);
      Permissions.validatePermissions(obj);

      this.permissions = obj;
    } else if (typeof rawPermissions === 'object') {
      Permissions.validatePermissions(rawPermissions);
      this.permissions = rawPermissions;
    } else {
      throw new Error('Invalid permissions constructor value');
    }
  }

  /**
   * Returns a list of system ids.
   */
  getSystemIDs() {
    return Object.keys(this.permissions).filter((system) => system !== 'roles');
  }

  /**
   * Returns a list of location ids.
   */
  getLocationIDs() {
    const locationIDs: Array<string> = [];

    this.getSystemIDs().forEach((id) =>
      locationIDs.push(...Object.keys(this.permissions[id]).filter((location) => location !== 'roles'))
    );

    return locationIDs;
  }

  /**
   * Return full object
   * @returns Valid permissions object
   */
  getPermissionsObject(): PermissionsObject {
    return this.permissions;
  }

  listRoles(): Array<Roles> {
    const list = new Set<Roles>();

    const { roles: globalRoles, ...systems } = this.permissions;

    if (Array.isArray(globalRoles)) {
      globalRoles.forEach((role) => list.add(role));
    }

    Object.keys(systems).forEach((systemId) => {
      const { roles: systemRoles, ...locations } = this.permissions[systemId];

      if (Array.isArray(systemRoles)) {
        systemRoles.forEach((role) => list.add(role));
      }

      Object.keys(locations).forEach((locationId) => {
        const localeRoles = locations[locationId];

        if (Array.isArray(localeRoles)) {
          localeRoles.forEach((role) => list.add(role));
        }
      });
    });

    return Array.from(list.values());
  }
  /**
   * Serializes the permissions object to a string. Throws an Error if the permissions
   * object is invalid.
   */
  toString() {
    Permissions.validatePermissions(this.permissions);
    return JSON.stringify(this.permissions);
  }

  /**
   * Adds a global role, mutating this permissions object.
   */
  addGlobalRole(role: GlobalRole) {
    if (Array.isArray(this.permissions.roles)) {
      if (this.permissions.roles.indexOf(role) === -1) {
        this.permissions.roles.push(role);
      }
    }

    this.permissions.roles = [role];
    Permissions.validatePermissions(this.permissions);
  }

  /**
   * Removes a global role, mutating this permissions object.
   */
  removeGlobalRole(role: GlobalRole) {
    if (Array.isArray(this.permissions.roles)) {
      const ix = this.permissions.roles.indexOf(role);
      if (ix !== -1) {
        this.permissions.roles.splice(ix, 1);
      }

      if (this.permissions.roles.length === 0) {
        delete this.permissions.roles;
      }
    }

    Permissions.validatePermissions(this.permissions);
  }

  /**
   * Adds a system role, mutating this permissions object.
   */
  addSystemRole(role: SystemRole, system: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);

    if (!this.permissions[system]) this.permissions[system] = { roles: [role] };
    else if (!this.permissions[system].roles) this.permissions[system].roles = [role];
    else if ((this.permissions[system].roles as Array<SystemRole>).indexOf(role) === -1)
      (this.permissions[system].roles as Array<SystemRole>).push(role);

    Permissions.validatePermissions(this.permissions);
  }

  /**
   * Removes a system role, mutating this permissions object.
   */
  removeSystemRole(role: SystemRole, system: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);

    if (this.permissions[system] && this.permissions[system].roles) {
      const ix = (this.permissions[system].roles as Array<SystemRole>).indexOf(role);
      if (ix !== -1) this.permissions[system].roles?.splice(ix, 1);
      if (this.permissions[system].roles?.length === 0) delete this.permissions[system].roles;
      if (Object.keys(this.permissions[system]).length === 0) delete this.permissions[system];
    }

    Permissions.validatePermissions(this.permissions);
  }

  /**
   * Adds a local role, mutating this permissions object.
   */
  addLocalRole(role: LocalRole, system: string, location: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);
    if (!isValidObjectId(location)) throw new Error(`Invalid location ${location}`);

    if (!this.permissions[system]) this.permissions[system] = { [location]: [role] };
    else if (!this.permissions[system][location]) this.permissions[system][location] = [role];
    else if (this.permissions[system][location].indexOf(role) === -1) this.permissions[system][location].push(role);

    Permissions.validatePermissions(this.permissions);
  }

  /**
   * Removes a local role, mutating this permissions object. The system and location parameters
   * defaults to the system and local context from the constructor.
   */
  removeLocalRole(role: LocalRole, system: string, location: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);
    if (!isValidObjectId(location)) throw new Error(`Invalid location ${location}`);

    if (this.permissions[system] && this.permissions[system][location]) {
      const ix = this.permissions[system][location].indexOf(role);
      if (ix !== -1) this.permissions[system][location].splice(ix, 1);
      if (this.permissions[system][location].length === 0) delete this.permissions[system][location];
      if (Object.keys(this.permissions[system]).length === 0) delete this.permissions[system];
    }

    Permissions.validatePermissions(this.permissions);
  }

  clearLocationRoles(system: string, location: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);
    if (!isValidObjectId(location)) throw new Error(`Invalid location ${location}`);

    if (this.permissions[system] && this.permissions[system][location]) {
      delete this.permissions[system][location];
      if (Object.keys(this.permissions[system]).length === 0) delete this.permissions[system];
    }

    Permissions.validatePermissions(this.permissions);
  }

  /**
   * Accepts any role (global, system or location) and returns true if user has the role
   * in any context. @system and @location are optional.
   */
  hasRole(role, system?: string, location?: string) {
    return (
      this.hasGlobalRole(role) ||
      (system && this.hasSystemRole(role, system)) ||
      (system && location && this.hasLocalRole(role, system, location))
    );
  }

  /**
   * Returns true if the user has the given global role.
   */
  hasGlobalRole(role: GlobalRole) {
    return Array.isArray(this.permissions.roles) && this.permissions.roles.includes(role);
  }

  /**
   * Returns true if the user has any role in the given system.
   */
  hasAnySystemRole(system: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);
    return this.permissions[system] && this.permissions[system].roles && this.permissions[system].roles?.length > 0;
  }

  /**
   * Returns true if the user has the given role in the system.
   */
  hasSystemRole(role: string, system: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);

    return (
      isValidSystemRole(role) &&
      this.permissions[system] &&
      this.permissions[system].roles &&
      this.permissions[system].roles.indexOf(role) !== -1
    );
  }

  /**
   * Returns true if the user has the given role in any system.
   */
  hasRoleInAnySystem(role: SystemRole) {
    return this.getSystemIDs().some((system) => this.hasSystemRole(role, system));
  }

  /**
   * Returns true if the user has any role in the system -> location context.
   */
  hasAnyLocalRole(system: string, location: string) {
    if (!system || !location) return false;
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);
    if (!isValidObjectId(location)) throw new Error(`Invalid location ${location}`);

    return (
      this.permissions[system] && this.permissions[system][location] && this.permissions[system][location].length > 0
    );
  }

  /**
   * Returns true if the user has the given role in the system -> location context.
   */
  hasLocalRole(value: string, system: string, location: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);
    if (!isValidObjectId(location)) throw new Error(`Invalid location ${location}`);

    return (
      isValidLocalRole(value) &&
      this.permissions[system] &&
      this.permissions[system][location] &&
      this.permissions[system][location].indexOf(value) !== -1
    );
  }

  /**
   * Returns true if the user has the given role in any location.
   */
  hasRoleInAnyLocation(role: LocalRole) {
    return this.getSystemIDs().some((system) =>
      this.getLocationIDs().some((location) => this.hasLocalRole(role, system, location))
    );
  }

  getRolesInLocation(system: string, location: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);
    if (!isValidObjectId(location)) throw new Error(`Invalid location ${location}`);

    return this.permissions[system][location];
  }

  getRolesInSystem(system: string) {
    if (!isValidObjectId(system)) throw new Error(`Invalid system ${system}`);

    return this.permissions[system].roles ?? [];
  }

  /**
   * Validates a permissions object. Returns true on success and throws an Error
   * if permissions fails check.
   */
  static validatePermissions(value: unknown): asserts value is PermissionsObject {
    validateNonEmptyObject(value);

    Object.keys(value).forEach((globalKey) => {
      if (globalKey !== 'roles' && !isValidObjectId(globalKey)) {
        throw new Error(`Invalid global key ${globalKey}`);
      }
    });

    // check global roles if exist
    if (value?.roles) {
      validateArray(value.roles);
      value.roles.forEach(validateGlobalRole);
    }

    // check system permissions
    Object.keys(value).forEach((globalLevelKey) => {
      if (globalLevelKey !== 'roles') {
        Permissions.validateSystemPermissions(value[globalLevelKey]);
      }
    });
  }

  static validateSystemPermissions(value: unknown): asserts value is SystemPermissions {
    validateNonEmptyObject(value);

    Object.keys(value).forEach((systemLevelKey) => {
      if (systemLevelKey !== 'roles' && !isValidObjectId(systemLevelKey)) {
        throw new Error(`Invalid system key ${systemLevelKey}`);
      }
    });

    // Check system roles
    if (value?.roles) {
      validateArray(value?.roles);
      value.roles.forEach(validateSystemRole);
    }

    // Check local permissions
    Object.keys(value).forEach((systemLevelKey) => {
      if (systemLevelKey !== 'roles') {
        Permissions.validateLocalPermissions(value[systemLevelKey]);
      }
    });
  }

  static validateLocalPermissions(value: unknown): asserts value is SpecificLocationPermissions {
    validateArray(value);

    value.forEach(validateLocalRole);
  }
}

/**
 * Helpers
 */

const validateGlobalRole = (value: unknown): asserts value is GlobalRole => {
  validateString(value);

  if (!globalRoles.includes(value)) {
    throw new Error(`${value} is not a global role`);
  }
};

const validateSystemRole = (value: unknown): asserts value is SystemRole => {
  validateString(value);

  if (!(systemRoles as Array<string>).includes(value)) {
    throw new Error(`${value} is not a system role`);
  }
};

const validateLocalRole = (value: unknown): asserts value is LocalRole => {
  validateString(value);

  if (!(localRoles as Array<string>).includes(value)) {
    throw new Error(`${value} is not a local role`);
  }
};

export const isValidGlobalRole = (value: string) => {
  return Object.values(GlobalRole).includes(value as GlobalRole);
};

export const isValidSystemRole = (value: string) => {
  return (systemRoles as Array<string>).includes(value);
};

export const isValidLocalRole = (value: string) => {
  return (localRoles as Array<string>).includes(value);
};
