import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { COMMON_ROUTE_TYPES } from 'wd-common/src/logic/pharmacy/definitions'
import { isCommonRouteType } from 'components-ts/RXs/utils'

const messages = defineMessages({
  po: {
    id: 'DrugRoute.route_po_label',
    defaultMessage: 'PO'
  },
  im: {
    id: 'DrugRoute.route_im_label',
    defaultMessage: 'IM'
  },
  iv: {
    id: 'DrugRoute.route_iv_label',
    defaultMessage: 'IV'
  }
})

const DrugRoute = props => {
  const { route } = props

  const intl = useIntl()

  const getTranslation = _route => {
    const route = _route.toLowerCase()

    switch (route) {
      case COMMON_ROUTE_TYPES.PO:
        return <T id={messages.po.id}>{intl.formatMessage(messages.po)}</T>
      case COMMON_ROUTE_TYPES.IM:
        return <T id={messages.im.id}>{intl.formatMessage(messages.im)}</T>
      case COMMON_ROUTE_TYPES.IV:
        return <T id={messages.iv.id}>{intl.formatMessage(messages.iv)}</T>

      default:
        return ''
    }
  }
  return isCommonRouteType(route)
    ? getTranslation(route)
    : route
}

export default DrugRoute
