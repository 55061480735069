import { QueryHookOptions, useQuery } from '@apollo/client';
import { PaginatedResult, PharmacyItemStockEntry } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { GET_PHARMACY_ITEM_STOCK_ENTRIES } from 'scenes/Pharmacy/requests';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

type PharmacyStockData = {
  pharmacyStock: Result;
};

type UsePharmacyItemStockVariables = {
  q?: string;
  added?: boolean;
  removed?: boolean;
  rx?: boolean;
  itemId: string;
  offset?: number;
  limit?: number;
};

type Result = PaginatedResult<PharmacyItemStockEntry>;

type UsePharmacyItemStockParams = QueryHookOptions<PharmacyStockData, UsePharmacyItemStockVariables>;

export const usePharmacyItemStock = (params: UsePharmacyItemStockParams) => {
  const intl = useIntl();
  const [error, setError] = useState<Nullable<string>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(intl.formatMessage(messages.internalServerError));
    }
  };

  const onCompleted = (data) => {
    if (typeof params.onCompleted === 'function') {
      params.onCompleted(data);
    }

    setError(null);
  };
  const { data, loading, refetch } = useQuery<PharmacyStockData, UsePharmacyItemStockVariables>(
    GET_PHARMACY_ITEM_STOCK_ENTRIES,
    { ...params, onError, onCompleted }
  );

  return {
    docs: data?.pharmacyStock?.docs ?? [],
    count: data?.pharmacyStock?.count ?? 0,
    loading,
    error,
    refetch,
  };
};
