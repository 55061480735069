import { MutationHookOptions, useMutation } from '@apollo/client';
import { FullPatientWithActiveVisit, StoredRx } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import {
  CANCEL_ORDER,
  ACKNOWLEDGE_ORDER,
  ADD_COMMENT_TO_ORDER,
  RECEIVE_ORDER,
  ADMINISTER_ORDER,
} from 'api/request/orders';
import { CANCEL_MIX, CANCEL_RX } from 'api/request/rxs';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';

const messages = defineMessages({
  patientNotFound: {
    id: 'OrderActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  invalidOrderId: {
    id: 'OrderActions.invalid_order_id',
    defaultMessage: 'We could not found the order, try again.',
  },
  genericErrorMessage: {
    id: 'OrderActions.generic_error_message',
    defaultMessage: 'Something went wrong, try again.',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
});

type CommonVariables = {
  patientId: string;
  visitId: string;
  orderId: string;
};

type UseCancelOrderData = {
  cancelOrder: FullPatientWithActiveVisit;
};

type UseCancelOrderParams = MutationHookOptions<UseCancelOrderData, CommonVariables> & CommonVariables;

export const useCancelOrder = (params: UseCancelOrderParams) => {
  const { visitId, patientId, orderId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'order_not_found':
      case 'invalid_order_id':
        return setError(messages.invalidOrderId);

      case 'patient_not_found':
        return setError(messages.patientNotFound);

      case 'error_cancelling_procedure_order':
      case 'error_cancelling_study_order':
      case 'error_cancelling_mix':
      case 'error_cancelling_rx':
      case 'invalid_signature':
      default:
        return setError(messages.genericErrorMessage);
    }
  };

  const onCompleted = (e: any) => {
    setError(null);

    if (restParams && restParams.onCompleted) {
      restParams.onCompleted(e);
    }
  };

  const [cancelOrder, { loading: isLoading }] = useMutation(CANCEL_ORDER, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onCancel = () => {
    const variables = {
      patientId,
      visitId,
      orderId,
    };
    cancelOrder({ variables });
  };

  return {
    onCancel,
    isLoading,
    error,
  };
};

type UseAcknowledgeOrderData = {
  acknowledgeOrder: FullPatientWithActiveVisit;
};

type UseAcknowledgeOrderParams = MutationHookOptions<UseAcknowledgeOrderData, CommonVariables> & CommonVariables;

export const useAcknowledgeOrder = (params: UseAcknowledgeOrderParams) => {
  const { visitId, patientId, orderId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'order_not_found':
      case 'invalid_order_id':
        return setError(messages.invalidOrderId);

      case 'patient_not_found':
        return setError(messages.patientNotFound);

      case 'error_acknowledging_study_order':
      case 'error_acknowledging_procedure_order':
      case 'invalid_signature':
      default:
        return setError(messages.genericErrorMessage);
    }
  };

  const onCompleted = (e: any) => {
    setError(null);

    if (restParams && restParams.onCompleted) {
      restParams.onCompleted(e);
    }
  };

  const [acknowledgeOrder, { loading: isLoading }] = useMutation(ACKNOWLEDGE_ORDER, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onAcknowledge = () => {
    const variables = {
      patientId,
      visitId,
      orderId,
    };

    acknowledgeOrder({ variables });
  };

  return {
    onAcknowledge,
    isLoading,
    error,
  };
};

type UseAdministerOrderData = {
  AdministerOrder: FullPatientWithActiveVisit;
};

type UseAdministerOrderParams = MutationHookOptions<UseAdministerOrderData, CommonVariables> & CommonVariables;

export const useAdministerOrder = (params: UseAdministerOrderParams) => {
  const { visitId, patientId, orderId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'order_not_found':
      case 'invalid_order_id':
        return setError(messages.invalidOrderId);

      case 'patient_not_found':
        return setError(messages.patientNotFound);

      case 'error_acknowledging_study_order':
      case 'error_acknowledging_procedure_order':
      case 'invalid_signature':
      default:
        return setError(messages.genericErrorMessage);
    }
  };

  const onCompleted = (e: any) => {
    setError(null);

    if (restParams && restParams.onCompleted) {
      restParams.onCompleted(e);
    }
  };

  const [administerOrder, { loading: isLoading }] = useMutation(ADMINISTER_ORDER, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onAdminister = () => {
    const variables = {
      patientId,
      visitId,
      orderId,
    };

    administerOrder({ variables });
  };

  return {
    onAdminister,
    isLoading,
    error,
  };
};

type UseReceiveOrderData = {
  receiveOrder: FullPatientWithActiveVisit;
};

type UseReceiveOrderParams = MutationHookOptions<UseReceiveOrderData, CommonVariables> & CommonVariables;

export const useReceiveOrder = (params: UseReceiveOrderParams) => {
  const { visitId, patientId, orderId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'order_not_found':
      case 'invalid_order_id':
        return setError(messages.invalidOrderId);

      case 'patient_not_found':
        return setError(messages.patientNotFound);

      case 'error_receiving_order':
      case 'invalid_signature':
      default:
        return setError(messages.genericErrorMessage);
    }
  };

  const onCompleted = (e: any) => {
    setError(null);

    if (restParams && restParams.onCompleted) {
      restParams.onCompleted(e);
    }
  };

  const [receiveOrder, { loading: isLoading }] = useMutation(RECEIVE_ORDER, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onReceive = () => {
    const variables = {
      patientId,
      visitId,
      orderId,
    };

    receiveOrder({ variables });
  };

  return {
    onReceive,
    isLoading,
    error,
  };
};

type UseAddCommentVariables = CommonVariables & {
  newComment: string;
};

type UseAddCommentData = {
  receiveOrder: FullPatientWithActiveVisit;
};

type UseAddCommentParams = MutationHookOptions<UseAddCommentData, UseAddCommentVariables> & CommonVariables;

interface AddCommentFormValue {
  newComment: string;
}
export const useAddCommentToOrder = (params: UseAddCommentParams) => {
  const { visitId, patientId, orderId, ...restParams } = params;

  const intl = useIntl();
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const validate: ValidateFn<AddCommentFormValue> = (values) => {
    const errors: ValidationError<AddCommentFormValue> = {};

    if (!values.newComment) {
      errors.newComment = intl.formatMessage(messages.requiredError);
    }

    return errors;
  };

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'order_not_found':
      case 'invalid_order_id':
        return setError(messages.invalidOrderId);

      case 'patient_not_found':
        return setError(messages.patientNotFound);

      case 'error_adding_comment_to_order':
      case 'invalid_comment':
      case 'invalid_signature':
      default:
        return setError(messages.genericErrorMessage);
    }
  };

  const onCompleted = (e: any) => {
    setError(null);

    if (restParams && restParams.onCompleted) {
      restParams.onCompleted(e);
    }
  };

  const [addComment, { loading: isLoading }] = useMutation(ADD_COMMENT_TO_ORDER, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onSubmit = (values: AddCommentFormValue) => {
    const variables = {
      patientId,
      visitId,
      orderId,
      newComment: values.newComment,
    };

    addComment({ variables });
  };

  return {
    onSubmit,
    validate,
    isLoading,
    error,
  };
};

type CancelRXVariables = {
  id: string;
};

type UseCancelRXData = {
  cancelRx: StoredRx;
};

type UseCancelRXParams = MutationHookOptions<UseCancelRXData, CancelRXVariables> & CancelRXVariables;

export const useCancelRX = (params: UseCancelRXParams) => {
  const { id, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_cancelling_rx':
      case 'invalid_signature':
      default:
        return setError(messages.genericErrorMessage);
    }
  };

  const onCompleted = (e: any) => {
    setError(null);

    if (restParams && restParams.onCompleted) {
      restParams.onCompleted(e);
    }
  };

  const [cancelRX, { loading: isLoading }] = useMutation(CANCEL_RX, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onCancelRX = () => {
    const variables = {
      id,
    };

    cancelRX({ variables });
  };

  return {
    onCancelRX,
    isLoading,
    error,
  };
};

type CancelMixVariables = {
  mixId: string;
};

type UseCancelMixData = {
  cancelMix: StoredRx;
};

type UseCancelMixParams = MutationHookOptions<UseCancelMixData, CancelMixVariables> & CancelMixVariables;

export const useCancelMix = (params: UseCancelMixParams) => {
  const { mixId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'error_cancelling_mix':
      case 'invalid_signature':
      default:
        return setError(messages.genericErrorMessage);
    }
  };

  const onCompleted = (e: any) => {
    setError(null);

    if (restParams && restParams.onCompleted) {
      restParams.onCompleted(e);
    }
  };

  const [cancelMix, { loading: isLoading }] = useMutation(CANCEL_MIX, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onCancelMix = () => {
    const variables = {
      mixId,
    };

    cancelMix({ variables });
  };

  return {
    onCancelMix,
    isLoading,
    error,
  };
};
