import React from 'react';
import { FormGroup, InputGroupText, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import { FieldRenderProps } from 'react-final-form';
import { DatePicker, DatePickerProps } from './DatePicker/DatePicker';

type DateFieldProps = Omit<DatePickerProps, 'onChange' | 'selected'> &
  FieldRenderProps<Date> & {
    label?: string;
    before?: React.ReactNode;
    className?: string;
  };

export const DateField: React.FC<DateFieldProps> = (props) => {
  const { input, meta, ...rest } = props;
  const { name, value, ...restInput } = input;
  const { label, className = '', before, ...pickerProps } = rest;

  return (
    <FormGroup name={name}>
      {label ? <Label for={name}>{label}</Label> : null}
      <InputGroup>
        {before ? (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{before}</InputGroupText>
          </InputGroupAddon>
        ) : null}
        <DatePicker
          {...restInput}
          isInvalid={meta.error && meta.touched}
          id={name}
          placeholderText={label}
          showYearDropdown
          dropdownMode="select"
          className={`form-control ${className}`}
          {...pickerProps}
          selected={input.value}
        />
      </InputGroup>
      {props.meta.error && props.meta.touched && <div className="invalid-feedback d-flex">{props.meta.error}</div>}
    </FormGroup>
  );
};
