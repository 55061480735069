import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Field, Form } from 'react-final-form';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Input,
  CardFooter,
  Button,
} from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { usePatientContext } from 'components-ts/Patients/usePatientContext';
import { LoadingInline } from 'components';
import { required } from 'utils/formValidators';
import { FaUserAlt as UserIcon, FaHeart as RelationshipIcon } from 'react-icons/fa';
import { PhoneNumberField } from 'components-ts/Forms/PhoneNumberField';
import { usePatientUpdateEmergencyContact } from './usePatientUpdateEmergencyContact';
import { ErrorViewer } from 'components-ts/ErrorViewer';

const messages = defineMessages({
  emergencyContactTitle: {
    id: 'EmergencyContactCard.emergency_contact',
    defaultMessage: 'Emergency contact',
  },
  firstName: {
    id: 'EmergencyContactCard.first_name',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'EmergencyContactCard.last_name',
    defaultMessage: 'Last name',
  },
  phone: {
    id: 'EmergencyContactCard.phone',
    defaultMessage: 'Phone',
  },
  relationship: {
    id: 'EmergencyContactCard.relationship_to_patient',
    defaultMessage: 'Relationship to patient',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
});

type EmergencyContactCardProps = {
  className?: string;
};

export const EmergencyContactCard: React.FC<EmergencyContactCardProps> = (props) => {
  const { className = '' } = props;

  const intl = useIntl();
  /**
   * we need info related to the active visit too
   * that's why we don't use the usePatient hook
   */
  const { patient } = usePatientContext();

  const initialValues = {
    first_name: patient.emergency_contact?.first_name ?? undefined,
    last_name: patient.emergency_contact?.last_name ?? undefined,
    phone_number: patient.emergency_contact?.phone_number ?? undefined,
    relationship_to_patient: patient.emergency_contact?.relationship_to_patient ?? undefined,
  };

  const { error, isLoading, onSubmit } = usePatientUpdateEmergencyContact({ patientId: patient.id });

  return (
    <Card className={className}>
      <CardHeader>
        <h6 className="m-0 font-weight-bold text-muted">
          <T id={messages.emergencyContactTitle.id}>{intl.formatMessage(messages.emergencyContactTitle)}</T>
        </h6>
      </CardHeader>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        render={({ handleSubmit, form, dirty, values }) => {
          return (
            <form
              onSubmit={async (event) => {
                await handleSubmit(event);
                form.initialize(values);
              }}
            >
              <CardBody>
                {/* firstname */}
                <Row>
                  <Field name="first_name" validate={required(intl.formatMessage(messages.requiredError))}>
                    {({ input, meta }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.firstName.id}>{intl.formatMessage(messages.firstName)}</T>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <UserIcon />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              title={intl.formatMessage(messages.firstName)}
                              placeholder={intl.formatMessage(messages.firstName)}
                              aria-label={intl.formatMessage(messages.firstName)}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              maxLength={50}
                              {...input}
                              className={`${
                                meta.error && meta.touched ? 'border border-danger rounded' : ''
                              } text-muted`}
                            />
                          </InputGroup>
                        </FormGroup>
                        {meta.error && meta.touched && (
                          <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                            {meta.error}
                          </div>
                        )}
                      </Col>
                    )}
                  </Field>
                </Row>
                {/* lastname */}
                <Row>
                  <Field name="last_name" validate={required(intl.formatMessage(messages.requiredError))}>
                    {({ input, meta }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.lastName.id}>{intl.formatMessage(messages.lastName)}</T>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <UserIcon />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              title={intl.formatMessage(messages.lastName)}
                              placeholder={intl.formatMessage(messages.lastName)}
                              aria-label={intl.formatMessage(messages.lastName)}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              maxLength={50}
                              {...input}
                              className={`${
                                meta.error && meta.touched ? 'border border-danger rounded' : ''
                              } text-muted`}
                            />
                          </InputGroup>
                        </FormGroup>
                        {meta.error && meta.touched && (
                          <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                            {meta.error}
                          </div>
                        )}
                      </Col>
                    )}
                  </Field>
                </Row>

                {/* phone */}
                <Row>
                  <Field name="phone_number" validate={required(intl.formatMessage(messages.requiredError))}>
                    {({ input, meta }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.phone.id}>{intl.formatMessage(messages.phone)}</T>
                          </Label>
                          <PhoneNumberField
                            input={input}
                            meta={meta}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            withIcon
                          />
                        </FormGroup>
                        {meta.error && meta.touched && (
                          <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                            {meta.error}
                          </div>
                        )}
                      </Col>
                    )}
                  </Field>
                </Row>

                {/* relationship  */}
                <Row>
                  <Field name="relationship_to_patient">
                    {({ input }) => (
                      <Col>
                        <FormGroup>
                          <Label>
                            <T id={messages.relationship.id}>{intl.formatMessage(messages.relationship)}</T>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <RelationshipIcon />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              title={intl.formatMessage(messages.relationship)}
                              placeholder={intl.formatMessage(messages.relationship)}
                              aria-label={intl.formatMessage(messages.relationship)}
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              maxLength={50}
                              className={`form-control`}
                              {...input}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )}
                  </Field>
                </Row>

                {error && <ErrorViewer error={error} />}
              </CardBody>
              {dirty && (
                <CardFooter className="animated fadeIn">
                  <div className="d-flex justify-content-start">
                    <Button type="submit" color="primary">
                      <T id={messages.saveButton.id} className={isLoading ? 'mr-2' : ''}>
                        {intl.formatMessage(messages.saveButton)}
                      </T>
                      {isLoading && <LoadingInline />}
                    </Button>
                  </div>
                </CardFooter>
              )}
            </form>
          );
        }}
      />
    </Card>
  );
};
