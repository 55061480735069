import { Nullable } from 'components-ts/utils';
import { FullComment, Signature, TranslatableText } from './common';
import { FullExamWithoutId } from './exams';
import { FullVitals } from './triage';
import { Complaints, PastVisit } from './visits';
import { StoredRx } from './rxs';
import { NoteTemplateItem } from './notes';

export interface FullMedicationsHistory {
  start: Nullable<string>;
  end: Nullable<string>;
  medications: Nullable<Array<StoredRx>>;
}
export interface VitalsHistory {
  end: string;
  start: string;
  vitals: FullVitals;
}

export interface FullHistoryItem {
  name: string;
  historyId: Nullable<string>;
  signature: Nullable<Signature>;
  start: Nullable<string>;
  end: Nullable<string>;
  deleted: Nullable<boolean>;
}

export interface FullSurveyHistoryItem {
  historyId: string;
  surveyId: string;
  name: TranslatableText;
  signature: Signature;
  description?: TranslatableText;
  items: Nullable<Array<NoteTemplateItem>>;
}

export enum MATERNAL_OUTCOME {
  vaginalDelivery = 'vaginalDelivery',
  cSection = 'cSection',
  stillBorn = 'stillBorn',
  deceased = 'deceased',
}

export interface FullMaternalHistory {
  gestation: Nullable<number>;
  preterm: Nullable<number>;
  onterm: Nullable<number>;
  abortions: Nullable<number>;
  livingChildren: Nullable<number>;
  lastPeriod: Nullable<string>;
  edd: Nullable<string>;
  outcome: Nullable<MATERNAL_OUTCOME>;
  signature: Nullable<Signature>;
}
export interface ActiveVisitHistory {
  complaints: Nullable<Array<Complaints>>;
  generalReviewOfSystems: Nullable<FullExamWithoutId>;
  manualMedications: Nullable<Array<FullHistoryItem>>;
  manualProblems: Nullable<Array<FullHistoryItem>>;
  allergies: Nullable<Array<FullHistoryItem>>;
  family: Nullable<Array<FullHistoryItem>>;
  diet: Nullable<Array<FullHistoryItem>>;
  development: Nullable<Array<FullHistoryItem>>;
  social: Nullable<Array<FullHistoryItem>>;
  comments: Nullable<Array<FullComment>>;
  surveys: Nullable<Array<FullSurveyHistoryItem>>;
  maternal: Nullable<FullMaternalHistory>;
}

export interface FullHistory {
  vitals: Nullable<Array<VitalsHistory>>;
  pastVisits: Nullable<Array<PastVisit>>;
  manualMedications: Nullable<Array<FullHistoryItem>>;
  manualProblems: Nullable<Array<FullHistoryItem>>;
  allergies: Nullable<Array<FullHistoryItem>>;
  familyHistory: Nullable<Array<FullHistoryItem>>;
  dietHistory: Nullable<Array<FullHistoryItem>>;
  developmentHistory: Nullable<Array<FullHistoryItem>>;
  socialHistory: Nullable<Array<FullHistoryItem>>;
  surveys: Nullable<Array<FullSurveyHistoryItem>>;
  medications: Nullable<Array<FullMedicationsHistory>>;
  maternal: Nullable<Array<FullMaternalHistory>>;
}
