import {
  ChecklistSections,
  DeprecatedVisitType,
  FullChecklist,
  FullChecklistEntry,
  HowLong,
  SignedRequestFields,
  VisitType,
} from '../api/interfaces';

export type Nullable<T> = T | null;

/**
 * Visit types
 *
 * Move this types to wd-common after implementing ts
 */
export const isValidVisitType = (value: string): boolean => {
  return (Object.values(VisitType) as Array<string>).includes(value);
};

export const isDeprecatedVisitType = (value: string): boolean => {
  return (Object.values(DeprecatedVisitType) as Array<string>).includes(value);
};

export const isValidHowLong = (value: string): boolean => {
  return (Object.values(HowLong) as Array<string>).includes(value);
};

/**
 * Use this in your `switch` default cases so typescript yells
 * when you miss a possible case. Example:
 *
 * enum Things {
 *     first,
 *     second,
 * }
 *
 * function doSomething(case: Things): void {
 *     switch(case) {
 *         case Things.first:
 *             //
 *
 *         case Things.second:
 *             //
 *
 *         default:
 *             assertUnreachable('Invalida case', case)
 *     }
 * }
 *
 * If you add a `third` value to the `Things` enum, and forget to add the
 * case to the switch, typescript will show a compile error at the
 * `assertUnreachable` line.
 *
 */
export function assertUnreachable(message: string, value: never): never {
  throw new Error(`${message} ${value}`);
}

/**
 * extract graphql errors
 */
export const extractFirstErrorCode = (error: any): string => {
  const [firstError] = error?.graphQLErrors ?? [];

  return firstError?.extensions?.code as string;
};

export const isAValidSection = (section: ChecklistSections) => Object.values(ChecklistSections).includes(section);

export const getClearingCodesByChecklist = (checklist: FullChecklist) => {
  const clearingCodes = ['physical_exam', 'history']
    .map((section) => {
      const entries = checklist[section] as FullChecklistEntry[];

      if (!entries) {
        return null;
      }

      return entries
        .filter((entry) => entry.checked)
        .map((entry) => {
          const { history_codes, physical_exam_codes, name, key } = entry;

          if (history_codes || physical_exam_codes) {
            return {
              historyCodes: history_codes,
              physicalExamCodes: physical_exam_codes,
              name,
              key,
            };
          }

          return null;
        })
        .filter((i) => i)
        .flat();
    })
    .flat();

  return clearingCodes;
};

/**
 * assertions
 */
export function validateNotNil<T>(value: T | null | undefined): asserts value is T {
  if (value === null || value === undefined) {
    throw new TypeError('Value is nil');
  }
}

export function validateString(value: unknown): asserts value is string {
  validateNotNil(value);
  if (typeof value !== 'string') {
    throw new TypeError('Value is not a string');
  }
}

export function validateNumber(value: unknown): asserts value is number {
  validateNotNil(value);
  if (typeof value !== 'number') {
    throw new TypeError('Value is not a number');
  }
}

export function validateArray(value: unknown): asserts value is Array<any> {
  validateNotNil<unknown>(value);

  if (!Array.isArray(value)) {
    throw new TypeError('Value is not an array');
  }
}

export function validateSet(value: unknown): asserts value is Set<any> {
  validateNotNil<unknown>(value);

  if (typeof value !== 'object' || typeof (value as Set<any>)?.has !== 'function') {
    throw new TypeError('Value is not a list');
  }
}

export function validateNonEmptyObject(value: unknown): asserts value is { [k: string]: any } {
  validateNotNil(value);

  if (typeof value !== 'object') {
    throw new TypeError('Value is not an object');
  }

  if (Object.keys(value as object).length === 0) {
    throw new TypeError('Value is an empty object');
  }
}

export function validateObjectKey<T extends object>(obj: T, key: string): asserts obj is T {
  validateNonEmptyObject(obj);

  if (!(key in obj)) {
    throw new TypeError(`Missing key ${String(key)}`);
  }
}

export const isValidObjectId = (value: string) => {
  const validObjectId = /^[0-9a-fA-F]{24}$/;
  return validObjectId.test(value);
};

export const getNameInitials = (name: string): string => {
  const words = name.split(' ');

  const initials = words.reduce((initials, word) => {
    if (!word) {
      return initials;
    }

    return initials.concat(word[0]);
  }, '');

  return initials.toUpperCase();
};

/**
 * Send request to s3 using the signed post fields
 *
 * @param {File} file file to upload
 * @param {String} url url returned by the createPresignedPost fn
 * @param {Object} fields fields returned by the createPresignedPost fn
 * @returns
 */
export const putFileInS3 = async (file: File, url: string, fields: SignedRequestFields) => {
  const formData = new FormData();

  // the order it's supposed to matter
  if (fields.key) {
    formData.append('key', fields.key);
  }
  if (fields.bucket) {
    formData.append('bucket', fields.bucket);
  }
  if (fields.XAmzAlgorithm) {
    formData.append('X-Amz-Algorithm', fields.XAmzAlgorithm);
  }
  if (fields.XAmzCredential) {
    formData.append('X-Amz-Credential', fields.XAmzCredential);
  }
  if (fields.XAmzDate) {
    formData.append('X-Amz-Date', fields.XAmzDate);
  }
  if (fields.XAmzSignature) {
    formData.append('X-Amz-Signature', fields.XAmzSignature);
  }
  if (fields.ACL) {
    formData.append('ACL', fields.ACL);
  }

  /**
   * This will depends on the way in which we use the aws credentials
   * It only exists if you use STS to get temporary credentials
   * like as in the Role system
   */
  if (fields.XAmzSecurityToken) {
    formData.append('X-Amz-Security-Token', fields.XAmzSecurityToken);
  }

  if (fields.Policy) {
    formData.append('Policy', fields.Policy);
  }
  formData.append('Content-type', file.type);
  formData.append('file', file);

  const options: RequestInit = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, options);
};

export const createMessageDescriptorFromText = (text: string) => {
  return { id: 'dummy_id', defaultMessage: text };
};
