import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { RECURRENCE_TYPES } from 'wd-common/src/logic/scheduledVisits/definitions'

const messages = defineMessages({
  [RECURRENCE_TYPES.UNIQUE]: {
    id: 'EventRecurrence.unique',
    defaultMessage: 'Unique'
  },
  [RECURRENCE_TYPES.DAILY]: {
    id: 'EventRecurrence.daily',
    defaultMessage: 'Daily'
  },
  [RECURRENCE_TYPES.WEEKLY]: {
    id: 'EventRecurrence.weekly',
    defaultMessage: 'Weekly'
  }
})

const EventRecurrence = props => {
  const { type, intl } = props
  const { formatMessage } = intl
  if (!type) throw new Error('Missing type')
  const message = messages[type]
  return <T id={message.id}>
    {formatMessage(message)}
  </T>
}

export default injectIntl(EventRecurrence)
