import { useState } from 'react'
import { defineMessages } from 'react-intl'
import { useMutation } from '@apollo/client'
import { graphQLErrorFormatter } from 'utils/errors'
import { CREATE_QUICKPICK, DELETE_QUICKPICK, UPDATE_QUICKPICKS } from 'scenes/Quickpicks/requests'
// static translations
const messages = defineMessages({
  deletingError: {
    id: 'Quickpick.deleting_error',
    defaultMessage: 'Could not delete this quickpick. Try again'
  },
  drugNotFoung: {
    id: 'Quickpick.drug_not_foung',
    defaultMessage: 'This drug was not found.'
  },
  updatingError: {
    id: 'Quickpick.updating_error',
    defaultMessage: 'Could not update this quickpick. Try again'
  },
  createError: {
    id: 'Quickpick.create_error',
    defaultMessage: 'Could not create this quickpick. Try again'
  },
  signatureError: {
    id: 'Quickpick.signature_error',
    defaultMessage: 'No signature provided.'
  }
})

export const useQuickpickActions = ({ formatMessage, callback }) => {
  const [error, setError] = useState([])
  const {
    onCreate,
    onUpdate,
    onDelete
  } = callback || {}

  const errorFormatter = errorCode => {
    switch (errorCode) {
      case 'error_deleting_quickpick':
        return { id: errorCode, message: formatMessage(messages.deletingError) }
      case 'error_creating_quickpick':
        return { id: errorCode, message: formatMessage(messages.createError) }
      case 'drug_not_found':
        return { id: errorCode, message: formatMessage(messages.drugNotFoung) }
      case 'error_updating_quickpick':
        return { id: errorCode, message: formatMessage(messages.updatingError) }
      case 'invalid_signature':
        return { id: errorCode, message: formatMessage(messages.signatureError) }
      default:
        return { id: '' }
    }
  }

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter)
    setError(translatedErrors)
  }

  const onCompleted = (callbackFun) => (data) => {
    setError()
    if (typeof callbackFun === 'function') {
      callbackFun(data)
    }
  }

  const [updateQuickpick, { loading: updating }] = useMutation(UPDATE_QUICKPICKS, { onError, onCompleted: onCompleted(onUpdate) })
  const [createQuickpick, { loading: creating }] = useMutation(CREATE_QUICKPICK, { onError, onCompleted: onCompleted(onCreate) })
  const [deleteQuickpick, { loading: deleting }] = useMutation(DELETE_QUICKPICK, { onError, onCompleted: onCompleted(onDelete) })

  return {
    createQuickpick,
    deleteQuickpick,
    updateQuickpick,
    loading: {
      creating,
      deleting,
      updating
    },
    error
  }
}
