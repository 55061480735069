import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Col, Row } from 'reactstrap'

const messages = defineMessages({
  name: {
    id: 'DrugDescription.label_name',
    defaultMessage: 'Name'
  },
  guidelineDoesNotMatch: {
    id: 'DrugDescription.does_not_match_guideline',
    defaultMessage: 'Does not match guideline.'
  },
  guidelineDoesNotMatchWarning: {
    id: 'DrugDescription.does_not_match_guideline_warning',
    defaultMessage: 'Warning: drug does not exactly match guideline. Please verify that the formulation is correct before using the dosing below.'
  },
  doseUnit: {
    id: 'DrugDescription.label_dose_unit',
    defaultMessage: 'Dosing Unit'
  },
  minimumToDispense: {
    id: 'DrugDescription.label_minimum_units_to_dispense',
    defaultMessage: 'Minimum Units to Dispense'
  },
  class: {
    id: 'DrugDescription.label_class',
    defaultMessage: 'Class'
  },
  stockUnit: {
    id: 'DrugDescription.label_stock_unit',
    defaultMessage: 'Stock Unit'
  },
  category: {
    id: 'DrugDescription.label_category',
    defaultMessage: 'Category'
  },
  retailPrice: {
    id: 'DrugDescription.label_retail_price',
    defaultMessage: 'Retail Price'
  }
})

const DrugDescription = props => {
  const { drug, intl } = props

  return <div>
    <Row className='p-2'>
      <Col xs={12}>
        <T id={messages.name.id}>{intl.formatMessage(messages.name)}</T>
        <b>
          {`: ${drug.name}`}
        </b>
      </Col>
      <Col sm={4} xs={12}>
        <T id={messages.doseUnit.id}>{intl.formatMessage(messages.doseUnit)}</T>
        <b>
          {`: ${drug.unit}`}
        </b>
      </Col>

      {
        !!drug.minimum_units_to_dispense && <Col sm={4} xs={12}>
          <span>
            <T id={messages.minimumToDispense.id}>{intl.formatMessage(messages.minimumToDispense)}</T>
            <b>
              {`: ${drug.minimum_units_to_dispense}`}
            </b>
          </span>
        </Col>
      }

      {
        !!drug.class && <Col sm={4} xs={12}>
          <T id={messages.class.id}>{intl.formatMessage(messages.class)}</T>
          <b>
            {`: ${drug.class}`}
          </b>
        </Col>
      }

      <Col sm={4} xs={12}>
        <T id={messages.stockUnit.id}>{intl.formatMessage(messages.stockUnit)}</T>
        <b>
          {`: ${drug.stock_unit ? drug.stock_unit : '-'}`}
        </b>
      </Col>

      {
        !!drug.category && <Col sm={4} xs={12}>
          <T id={messages.category.id}>{intl.formatMessage(messages.category)}</T>
          <b>
            {`: ${drug.category}`}
          </b>
        </Col>
      }

      <Col sm={4} xs={12}>
        <T id={messages.retailPrice.id}>{intl.formatMessage(messages.retailPrice)}</T>
        <b>
          {`: ${drug.retail || '-'} / ${drug.stock_unit ? drug.stock_unit : drug.unit}`}
        </b>
      </Col>
    </Row>
  </div>
}

export default injectIntl(DrugDescription)
