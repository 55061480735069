import { useSystems } from 'components-ts/HealthSystems';

export const useMySystemSettings = () => {
  const { getMyLocation, getMySystem } = useSystems();

  const system = getMySystem();
  const location = getMyLocation();

  const systemInfo = {
    id: system.id,
    name: system.name,
    country: system.country,
    timezone: system.timezone,
    locale: system.locale,
  };

  const locationInfo = {
    id: location.id,
    name: location.name,
    address: location.address,
    phoneNumber: location.phoneNumber,
    mainContact: location.mainContact,
  };

  return {
    system: systemInfo,
    location: locationInfo,
    rooms: location.rooms,
    subscriptions: location.subscriptions,
    pharmacy: system.settings.pharmacy,
    payment: location.settings.payment,
    scheduler: location.settings.scheduler,
    registration: system.settings.registration,
  };
};
