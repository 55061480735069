import { Nullable } from 'components-ts/utils';

export interface DefaultSystemOrLocation {
  id: string;
  name: string;
}

/**
 * Subscription status from stripe
 */
export enum SubscriptionStatus {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
}

export enum SubscriptionType {
  RECURRING = 'recurring',
  ONE_TIME_PAYMENTS = 'oneTimePayment',
}

export enum SubscriptionProvider {
  XENDIT = 'xendit',
  STRIPE = 'stripe',
  CUSTOM = 'custom',
}
/**
 * This is what we get from apollo
 */
export interface BasicRecurringSubscriptionData {
  customerId: string;
  provider: SubscriptionProvider;
  type: SubscriptionType;
  subscriptionId: Nullable<string>;
  status: Nullable<string>;
  url: Nullable<string>;
}

export interface UserPaymentMethod {
  type: string;
  last4: Nullable<string>;
  brand: Nullable<string>;
  expYear: Nullable<string>;
  expMonth: Nullable<string>;
}

export interface FullRecurringSubscriptionData {
  id: string;
  provider: string;
  type: SubscriptionType;
  customerId: string;
  created: string;
  nickname: Nullable<string>;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  status: SubscriptionStatus;
  price: Nullable<number>;
  canceledAt: Nullable<string>;
  trialStart: Nullable<string>;
  trialEnd: Nullable<string>;
  currency: Nullable<string>;
  interval: Nullable<string>;
  intervalCount: Nullable<number>;
  nextPaymentDate: Nullable<string>;
  paymentMethod: UserPaymentMethod;
}
export interface OneTimePaymentSubscriptionData {
  customerId: string;
  provider: SubscriptionProvider;
  type: SubscriptionType;
  expirationDate: Nullable<string>;
  url: Nullable<string>;
}

export interface B2BSubscriptionData {
  customerId: string;
  provider: SubscriptionProvider;
  type: SubscriptionType;
  expirationDate: string;
  maxLocations: Nullable<number>;
  maxUsers: Nullable<number>;
}

export type BasicSubscriptionData =
  | BasicRecurringSubscriptionData
  | OneTimePaymentSubscriptionData
  | B2BSubscriptionData;
export type FullSubscriptionData = FullRecurringSubscriptionData | OneTimePaymentSubscriptionData | B2BSubscriptionData;

export interface User {
  id: string;
  username: string;
  family_name: string;
  given_name: string;
  email: string;
  created: string;
  locale: Nullable<string>;
  birthdate: Nullable<string>;
  email_verified: Nullable<boolean>;
  enabled: Nullable<boolean>;
  lastModified: Nullable<string>;
  phone_number: string;
  phone_number_verified: Nullable<boolean>;
  status: Nullable<string>;
  permissions: Nullable<string>;
  location: Nullable<DefaultSystemOrLocation>;
  system: Nullable<DefaultSystemOrLocation>;
  subscription: Nullable<BasicSubscriptionData>;
  bpjsDoctorId: Nullable<string>;
}

export interface Plan {
  id: string;
  provider: SubscriptionProvider;
  type: SubscriptionType;
  description: string;
  price: number;
  currency: Nullable<string>;
  isDefault: Nullable<boolean>;
}

export const isRecurringSubscription = (e: BasicSubscriptionData): e is BasicRecurringSubscriptionData => {
  return e.type === SubscriptionType.RECURRING;
};

export const getOneTimePaymentSubscriptionStatus = (
  subscription: OneTimePaymentSubscriptionData | B2BSubscriptionData
) => {
  if (!subscription.expirationDate) {
    return null;
  }

  // Had to duplicate, because importing it was causing circular dependencies issues
  const addDays = (date: Date, days: number) => {
    const aux = new Date(date);
    aux.setDate(aux.getDate() + days);

    return aux;
  };

  const expirationDate = new Date(subscription.expirationDate);
  const pastDueDate = addDays(expirationDate, 7);
  const now = new Date();

  if (pastDueDate > now && now > expirationDate) {
    return SubscriptionStatus.PAST_DUE;
  }

  if (expirationDate > now) {
    return SubscriptionStatus.ACTIVE;
  }

  return SubscriptionStatus.CANCELED;
};

export const getSubscriptionStatusFromSubscription = (sub: Nullable<BasicSubscriptionData>) => {
  if (!sub) {
    return null;
  }

  if (isRecurringSubscription(sub)) {
    return sub.status as Nullable<SubscriptionStatus>;
  }

  return getOneTimePaymentSubscriptionStatus(sub);
};
