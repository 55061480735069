import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Form, Field } from 'react-final-form';
import {
  Button,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Col,
  InputGroupText,
  FormGroup,
  Input,
} from 'reactstrap';
import Select from 'react-select';
import { DOSE_UNITS } from 'wd-common/src/logic/pharmacy/definitions';
import { required, composeValidators, minValue, minLength } from '../utils/formValidators';

// debug
const debug = require('debug')('wd:GuidelineCreator');

// Static translations
const messages = defineMessages({
  doseUnit: {
    id: 'guideline_creator.label_choose_dose_unit',
    defaultMessage: 'Choose a unit',
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required',
  },
  minValueError: {
    id: 'form_validation.min_value',
    defaultMessage: 'This field must be greater than: ',
  },
  maxValueError: {
    id: 'form_validation.max_value',
    defaultMessage: 'This field must be smaller than: ',
  },
  minLengthError: {
    id: 'form_validation.min_length',
    defaultMessage: 'This field must be larger than: ',
  },
  noUnitsFoundError: {
    id: 'quickpick_manager.label_no_units_found',
    defaultMessage: 'No units found',
  },
  preText: {
    id: 'GuidelineCreator.pre_text_label',
    defaultMessage: 'Pre text',
  },
  drugNameFormulation: {
    id: 'GuidelineCreator.drug_name_formulation_label',
    defaultMessage: 'Drug name and formulation',
  },
  doseRangeMin: {
    id: 'GuidelineCreator.dose_range_min_label',
    defaultMessage: 'Dose Range Min',
  },
  doseRangeMax: {
    id: 'GuidelineCreator.dose_range_max_label',
    defaultMessage: 'Dose Range Max',
  },
  doseUnits: {
    id: 'GuidelineCreator.dose_units_label',
    defaultMessage: 'Dose Units',
  },
  maxDose: {
    id: 'GuidelineCreator.max_dose_label',
    defaultMessage: 'Max Dose',
  },
  maxDoseUnits: {
    id: 'GuidelineCreator.max_dose_units_label',
    defaultMessage: 'Max Dose Units',
  },
  route: {
    id: 'GuidelineCreator.route_label',
    defaultMessage: 'Route',
  },
  route_po: {
    id: 'GuidelineCreator.route_po_label',
    defaultMessage: 'PO',
  },
  route_im: {
    id: 'GuidelineCreator.route_im_label',
    defaultMessage: 'IM',
  },
  route_iv: {
    id: 'GuidelineCreator.route_iv_label',
    defaultMessage: 'IV',
  },
  asNeeded: {
    id: 'GuidelineCreator.as_needed_label',
    defaultMessage: 'As Needed',
  },
  frequency: {
    id: 'GuidelineCreator.frequency_label',
    defaultMessage: 'Frequency',
  },
  doses: {
    id: 'GuidelineCreator.doses_label',
    defaultMessage: 'Doses',
  },
  dosesPerDay: {
    id: 'GuidelineCreator.doses_per_day_label',
    defaultMessage: 'Doses per day',
  },
  perDay: {
    id: 'GuidelineCreator.per_day_label',
    defaultMessage: 'Per day',
  },
  every: {
    id: 'GuidelineCreator.every_label',
    defaultMessage: 'Every',
  },
  hours: {
    id: 'TimeUnits.hours',
    defaultMessage: 'Hours',
  },
  days: {
    id: 'TimeUnits.days',
    defaultMessage: 'Days',
  },
  clearButton: {
    id: 'UI.button_clear',
    defaultMessage: 'Clear',
  },
  updateButton: {
    id: 'UI.button_update',
    defaultMessage: 'Update',
  },
  addDrugButton: {
    id: 'GuidelineCreator.add_drug',
    defaultMessage: 'Add Drug',
  },
});

const GuidelineCreator = props => {
  // props
  const {
    onSubmit: _onSubmit,
    disabled,
    guideline = {
      type: 'drug',
      pre_text: { type: 'translatable_text', text: '' },
      name: { type: 'translatable_text', text: '' },
      route: { type: 'translatable_text', text: '' },
      post_text: { type: 'translatable_text', text: '' },
      frequency_show: 'frequency',
      as_needed: false,
    },
    intl,
  } = props;
  const { formatMessage } = intl;

  // form initial values
  const initialValues = {
    pre_text: (guideline.pre_text || {}).text,
    name: guideline.name.text,
    dose_range_max: guideline.dose_range_max || 0,
    dose_range_min: guideline.dose_range_min || 0,
    dose_units: guideline.dose_units,
    max_dose: guideline.max_dose,
    max_dose_units: guideline.max_dose_units,
    route: guideline.route.text,
    post_text: (guideline || {}).post_text.text,
    frequency_show: guideline.frequency_show,
    doses_per_day: guideline.doses_per_day || 1,
    as_needed: guideline.as_needed,
    days: guideline.days,
  };
  debug({ initialValues });

  // validate the onSubmit
  if (typeof _onSubmit !== 'function') throw new Error('onChange must be a function');

  // form mutator
  const changeRoute = ([route], state, { changeValue }) => {
    changeValue(state, 'route', () => route);
  };

  // local onSubmit
  const onSubmit = (values, form) => {
    // generate the guideline
    const newGuideline = { ...guideline };
    for (const key in values) {
      if (newGuideline[key] && newGuideline[key].type === 'translatable_text') {
        newGuideline[key].text = values[key];
      } else {
        newGuideline[key] = values[key];
      }
    }
    // update the parent
    _onSubmit(newGuideline);
  };

  return (
    <div className='form-horizontal jumbotron p-3'>
      <Form
        onSubmit={onSubmit}
        mutators={{ changeRoute }}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            onSubmit={async event => {
              await handleSubmit(event);
              // clear the form after saving
              const { valid } = form.getState();
              if (valid) form.reset();
            }}
          >
            {/* Pre text */}
            <Field name='pre_text'>
              {({ input, meta }) => (
                <FormGroup>
                  <Label>
                    <T id={messages.preText.id}>{formatMessage(messages.preText)}</T>
                  </Label>
                  <Input className='form-control' name='pre_text' placeholder='Pre text' maxLength={120} {...input} />
                </FormGroup>
              )}
            </Field>

            {/* Drug name */}
            <Field name='name' validate={required(formatMessage(messages.requiredError))}>
              {({ input, meta }) => (
                <FormGroup>
                  <Label>
                    <T id={messages.drugNameFormulation.id}>{formatMessage(messages.drugNameFormulation)}</T>
                  </Label>
                  <Input
                    className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                    name='name'
                    placeholder='Drug name and formulation'
                    {...input}
                    maxLength={50}
                  />
                  {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                </FormGroup>
              )}
            </Field>

            {/* Dose Range - Dose units */}
            <Row>
              <Field
                name='dose_range_min'
                format={value => {
                  const f = parseFloat(value);
                  return isNaN(f) ? 0 : f;
                }}
                parse={value => {
                  const f = parseFloat(value);
                  return isNaN(f) ? 0 : f;
                }}
                validate={composeValidators(
                  required(formatMessage(messages.requiredError)),
                  minValue(0)(formatMessage(messages.minValueError))
                )}
              >
                {({ input, meta }) => (
                  <FormGroup className='col-md-4 col-sm-6'>
                    <Label>
                      <T id={messages.doseRangeMin.id}>{formatMessage(messages.doseRangeMin)}</T>
                    </Label>
                    <Input
                      className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                      type='number'
                      name='dose_range_min'
                      placeholder='Dose Range Min'
                      autoComplete='off'
                      {...input}
                    />
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
              <Field
                name='dose_range_max'
                format={value => {
                  const f = parseFloat(value);
                  return isNaN(f) ? 0 : f;
                }}
                parse={value => {
                  const f = parseFloat(value);
                  return isNaN(f) ? 0 : f;
                }}
                validate={composeValidators(
                  required(formatMessage(messages.requiredError)),
                  minValue(values.dose_range_min)(formatMessage(messages.minValueError))
                )}
              >
                {({ input, meta }) => (
                  <FormGroup className='col-md-4 col-sm-6'>
                    <Label>
                      <T id={messages.doseRangeMax.id}>{formatMessage(messages.doseRangeMax)}</T>
                    </Label>
                    <Input
                      className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                      type='number'
                      name='dose_range_max'
                      placeholder='Dose Range Max'
                      autoComplete='off'
                      {...input}
                    />
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
              <Field
                name='dose_units'
                validate={required(formatMessage(messages.requiredError))}
                // react-select needs both fields
                format={unit => (unit ? { value: unit, label: unit } : unit)}
                // but we only need to store the id
                parse={item => item.value}
              >
                {({ input, meta }) => (
                  <FormGroup className='col-md-4 col-sm-6'>
                    <Label>
                      <T id={messages.doseUnits.id}>{formatMessage(messages.doseUnits)}</T>
                    </Label>
                    <Select
                      className={`${meta.error && meta.touched ? 'border border-danger' : ''}`}
                      {...input}
                      placeholder={formatMessage(messages.doseUnit)}
                      // format the options
                      options={Object.values(DOSE_UNITS)
                        .filter(i => i)
                        .map(val => ({ value: val, label: val }))}
                      components={{
                        NoOptionsMessage: () => (
                          <p className='text-center pt-3'>
                            {
                              // formatmessage allows us to reuse the same id
                              formatMessage(messages.noUnitsFoundError)
                            }
                          </p>
                        ),
                      }}
                      blurInputOnSelect
                      isSearchable
                      isClearable
                    />
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
            </Row>

            {/* Max dose - Max Dose units */}
            <Row>
              <Field
                name='max_dose'
                format={value => {
                  const f = parseFloat(value);
                  return isNaN(f) ? 0 : f;
                }}
                parse={value => {
                  const f = parseFloat(value);
                  return isNaN(f) ? 0 : f;
                }}
                validate={composeValidators(
                  required(formatMessage(messages.requiredError)),
                  minValue(values.dose_range_max)(formatMessage(messages.minValueError))
                )}
              >
                {({ input, meta }) => (
                  <FormGroup className='col-md-4 col-sm-6'>
                    <Label>
                      <T id={messages.maxDose.id}>{formatMessage(messages.maxDose)}</T>
                    </Label>
                    <Input
                      className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                      type='number'
                      name='max_dose'
                      placeholder='Max Dose '
                      {...input}
                    />
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
              <Field
                name='max_dose_units'
                validate={required(formatMessage(messages.requiredError))}
                // react-select needs both fields
                format={unit => (unit ? { value: unit, label: unit } : unit)}
                // but we only need to store the id
                parse={item => item.value}
              >
                {({ input, meta }) => (
                  <FormGroup className='col-md-4 col-sm-6'>
                    <Label>
                      <T id={messages.maxDoseUnits.id}>{formatMessage(messages.maxDoseUnits)}</T>
                    </Label>
                    <Select
                      className={`${meta.error && meta.touched ? 'border border-danger' : ''}`}
                      placeholder={formatMessage(messages.doseUnit)}
                      // format the options
                      options={Object.values(DOSE_UNITS)
                        .filter(i => i)
                        .map(val => ({ value: val, label: val }))}
                      components={{
                        NoOptionsMessage: () => (
                          <p className='text-center pt-3'>
                            {
                              // formatmessage allows us to reuse the same id
                              formatMessage(messages.noUnitsFoundError)
                            }
                          </p>
                        ),
                      }}
                      blurInputOnSelect
                      isSearchable
                      isClearable
                      {...input}
                    />
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
            </Row>

            {/* Route */}
            <Row>
              <FormGroup className='col-md-6'>
                <Label>
                  <T id={messages.route.id}>{formatMessage(messages.route)}</T>
                </Label>
                <Field
                  name='route'
                  validate={composeValidators(
                    required(formatMessage(messages.requiredError)),
                    minLength(1)(formatMessage(messages.minLengthError))
                  )}
                >
                  {({ input, meta }) => {
                    return (
                      <InputGroup>
                        <input
                          className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                          placeholder='Route'
                          aria-label='Route'
                          maxLength={30}
                          {...input}
                        />
                        <InputGroupAddon addonType='append'>
                          <ButtonGroup id='route_addon'>
                            <Button type='button' color='primary' onClick={e => form.mutators.changeRoute('PO')}>
                              <Label>
                                <T id={messages.route_po.id}>{formatMessage(messages.route_po)}</T>
                              </Label>
                            </Button>
                            <Button type='button' color='primary' onClick={e => form.mutators.changeRoute('IM')}>
                              <Label>
                                <T id={messages.route_im.id}>{formatMessage(messages.route_im)}</T>
                              </Label>
                            </Button>
                            <Button color='primary' onClick={e => form.mutators.changeRoute('IV')}>
                              <Label>
                                <T id={messages.route_iv.id}>{formatMessage(messages.route_iv)}</T>
                              </Label>
                            </Button>
                          </ButtonGroup>
                        </InputGroupAddon>
                        {meta.error && meta.touched ? (
                          <div className='invalid-feedback d-flex'>{meta.error}</div>
                        ) : null}
                      </InputGroup>
                    );
                  }}
                </Field>
              </FormGroup>

              <FormGroup className='d-flex align-items-end col-md-6 mb-3'>
                <Label check>
                  <Field className='mr-2' name='as_needed' component='input' type='checkbox' />
                  <T id={messages.asNeeded.id}>{formatMessage(messages.asNeeded)}</T>
                </Label>
              </FormGroup>
            </Row>

            {/* Frequency */}
            <Row>
              <Col className='col-md-8 '>
                <div className='d-flex align-items-center mb-2'>
                  <InputGroup className='d-flex justify-content-center'>
                    <Label check className='mr-3 d-flex align-items-center'>
                      <Field name='frequency_show' component='input' type='radio' value='frequency' />

                      <T id={messages.frequency.id}>{formatMessage(messages.frequency)}</T>
                    </Label>
                    <Label check className='mr-3 d-flex align-items-center'>
                      <Field name='frequency_show' component='input' type='radio' value='doses_per_day' />
                      <T id={messages.dosesPerDay.id}>{formatMessage(messages.dosesPerDay)}</T>
                    </Label>
                    <Label check className='d-flex align-items-center'>
                      <Field name='frequency_show' component='input' type='radio' value='doses' />

                      <T id={messages.doses.id}>{formatMessage(messages.doses)}</T>
                    </Label>
                  </InputGroup>
                </div>
                {values.frequency_show === 'doses_per_day' || values.frequency_show === 'doses' ? (
                  <Field
                    name='doses_per_day'
                    validate={composeValidators(
                      required(formatMessage(messages.requiredError)),
                      minValue(0)(formatMessage(messages.minValueError))
                    )}
                    format={value => {
                      const f = parseInt(value);
                      return isNaN(f) ? 0 : f;
                    }}
                    parse={value => {
                      const f = parseInt(value);
                      return isNaN(f) ? 0 : f;
                    }}
                  >
                    {({ input, meta }) => (
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type='number'
                            className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                            name='doses_per_day'
                            placeholder='Doses per day'
                            aria-label='Doses per day'
                            {...input}
                          />
                          <InputGroupAddon addonType='append'>
                            {values.frequency_show === 'doses_per_day' ? (
                              <InputGroupText>
                                <T id={messages.perDay.id}>{formatMessage(messages.perDay)}</T>
                              </InputGroupText>
                            ) : values.frequency_show === 'doses' ? (
                              <InputGroupText>
                                <T id={messages.doses.id}>{formatMessage(messages.doses)}</T>
                              </InputGroupText>
                            ) : null}
                          </InputGroupAddon>
                        </InputGroup>
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                      </FormGroup>
                    )}
                  </Field>
                ) : (
                  <Field
                    name='doses_per_day'
                    validate={composeValidators(
                      required(formatMessage(messages.requiredError)),
                      minValue(0)(formatMessage(messages.minValueError))
                    )}
                    format={dosesPerDay => (isNaN(dosesPerDay) ? 0 : parseInt(24 / dosesPerDay))}
                    parse={frequency => {
                      const everyXHours = parseInt(frequency);
                      const dosesPerDay = Math.ceil(24 / everyXHours);
                      return dosesPerDay;
                    }}
                  >
                    {({ input, meta }) => (
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType='prepend' key='pre-addon-doses-pre-day'>
                            <InputGroupText>
                              <T id={messages.every.id}>{formatMessage(messages.every)}</T>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type='text'
                            className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                            name='doses_per_day'
                            placeholder='Doses per day'
                            aria-label='Doses per day'
                            maxLength={10}
                            {...input}
                          />
                          <InputGroupAddon addonType='append' key='ap-addon-doses-pre-day'>
                            <InputGroupText>
                              <T id={messages.hours.id}>{formatMessage(messages.hours)}</T>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                      </FormGroup>
                    )}
                  </Field>
                )}
              </Col>
              <Field
                name='days'
                parse={value => {
                  if (values.frequency_show === 'doses') return undefined;
                  const i = parseInt(value);
                  return isNaN(i) ? 0 : i;
                }}
                format={value => {
                  if (values.frequency_show === 'doses') return undefined;
                  const i = parseInt(value);
                  return isNaN(i) ? 0 : i;
                }}
                validate={
                  values.frequency_show !== 'doses'
                    ? minValue(0)(formatMessage(messages.minValueError))
                    : value => undefined
                }
              >
                {({ input, meta }) => (
                  <FormGroup className='col-md-4'>
                    <Label>
                      <T id={messages.days.id}>{formatMessage(messages.days)}</T>
                    </Label>
                    <InputGroup>
                      <Input
                        type='number'
                        className={`form-control ${meta.error && meta.touched ? 'is-invalid' : ''}`}
                        name='days'
                        placeholder='Days'
                        {...input}
                      />
                      <InputGroupAddon addonType='append'>
                        <span className='input-group-text' id='max_doses_addon'>
                          <T id={messages.days.id}>{formatMessage(messages.days)}</T>
                        </span>
                      </InputGroupAddon>
                    </InputGroup>
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
            </Row>

            {/* Buttons */}
            <Row className='m-2 justify-content-end'>
              <ButtonGroup>
                <Button className='mr-1 ' type='button' onClick={form.reset} disabled={submitting || pristine}>
                  <T id={messages.clearButton.id}>{formatMessage(messages.clearButton)}</T>
                </Button>
                <Button className='' color='primary' type='submit' disabled={disabled || submitting || form.error}>
                  {guideline.name && guideline.name.text ? (
                    <T id={messages.updateButton.id}>{formatMessage(messages.updateButton)}</T>
                  ) : (
                    <T id={messages.addDrugButton.id}>{formatMessage(messages.addDrugButton)}</T>
                  )}
                </Button>
              </ButtonGroup>
            </Row>
          </form>
        )}
      />
    </div>
  );
};

export default injectIntl(GuidelineCreator);
