import React from 'react'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'
import { getAgeCategory } from 'wd-common/src/logic/triage/utils'
import { AgeCategories, isValidAgeCategory } from './utils'


const categoryTranslations: Record<AgeCategories, MessageDescriptor> = {
  [AgeCategories.NEW_BORN]: {
    id: 'Vitals.age_range_newborn',
    defaultMessage: 'Newborn'
  },
  [AgeCategories.NEONATE]: {
    id: 'Vitals.age_neonate',
    defaultMessage: 'Neonate'
  },
  [AgeCategories.INFANT]: {
    id: 'Vitals.age_range_infant',
    defaultMessage: 'Infant (1-12 mo)'
  },
  [AgeCategories.TODDLER]: {
    id: 'Vitals.age_range_toddler',
    defaultMessage: 'Toddler (1-2 y)'
  },
  [AgeCategories.PRESCHOOLER]: {
    id: 'Vitals.age_range_preschooler',
    defaultMessage: 'Preschooler (3-5 y)'
  },
  [AgeCategories.SCHOOL_AGED_CHILD]: {
    id: 'Vitals.age_range_schooler_aged',
    defaultMessage: 'School-aged child (6-9 y)'
  },
  [AgeCategories.PREADOLESCENT]: {
    id: 'Vitals.age_range_preadolescent',
    defaultMessage: 'Preadolescent (10-12 y)'
  },
  [AgeCategories.ADOLESCENT_13_15]: {
    id: 'Vitals.age_range_adolescent_13_15',
    defaultMessage: 'Adolescent (13-15 y)'
  },
  [AgeCategories.ADOLESCENT_16_19]: {
    id: 'Vitals.age_range_adolescent_16_19',
    defaultMessage: 'Adolescent (16-19 y)'
  },
  [AgeCategories.ADULT]: {
    id: 'Vitals.age_range_adult',
    defaultMessage: 'Adult'
  }
}

const categoryMessages = defineMessages(categoryTranslations)

type PatientAgeCategoryProps = {
  birthdate: string
  className?: string
}

export const PatientAgeCategory: React.FC<PatientAgeCategoryProps> = props => {
  const { birthdate, className } = props
  const intl = useIntl()

  const category = getAgeCategory({ birthdate })

  const message = isValidAgeCategory(category)
    ? intl.formatMessage(categoryMessages[category])
    : '--'

  return <span className={className ?? ''}>
    {message}
  </span>
}
