import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Modal, ModalBody, Nav, NavLink, NavItem, ModalHeader, TabContent, TabPane } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useQueryParams } from 'hooks';
import { useHistory } from 'react-router-dom';
import { SystemDrugGuideline } from 'api/interfaces';
import { ManualTab } from './ManualTab';
import { QuickpickTab } from './QuickpickTab';
import { useMySystemSettings } from 'components-ts/HealthSystems';

const messages = defineMessages({
  rxTitle: {
    id: 'RxModal.Rx',
    defaultMessage: 'Rx',
  },
  manualMode: {
    id: 'RxModal.manual_mode_tab',
    defaultMessage: 'Manual',
  },
  quickpickMode: {
    id: 'RxModal.quickpick_mode',
    defaultMessage: 'Quick Pick',
  },
  pharmacyLabel: {
    id: 'UI.pharmacy_label',
    defaultMessage: 'Pharmacy',
  },
});

enum TABS {
  MANUAL = 'manual',
  QUICKPICK = 'quickpick',
}

interface RxModalProps {
  isOpen?: boolean;
  onToggle: () => void;
  guideline?: SystemDrugGuideline;
  onCompleted?: () => void;
}

export const RxModal: React.FC<RxModalProps> = (props) => {
  const { guideline, isOpen, onToggle, onCompleted } = props;

  const intl = useIntl();
  const history = useHistory();
  const queryParams = useQueryParams();

  const { pharmacy, location } = useMySystemSettings();

  const [activeTab, setActiveTab] = useState(TABS.MANUAL);

  const rxLocation = pharmacy.defaultPharmacy ?? { id: location.id, name: location.name };

  const changeTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      queryParams.set('tab', tab);
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  /**
   * This is the easiest way to uncheck the checklist item
   * if the order is canceled that doesn't require rewriting
   * all the involved components. It is probably worth doing
   * so at a later time in order to have more maintainable code.
   * For now:
   * Callback should trigger the item update in case the order is added.
   * If callback doesn't exist, just toggle the modal.
   */
  // const callback = () => {
  //   if (typeof props.callback === 'function') {
  //     props.callback();
  //   } else if (typeof toggle === 'function') {
  //     toggle();
  //   }
  // };

  const guidelineName = guideline ? (guideline.name || {}).text : '';

  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="lg">
      <ModalHeader toggle={onToggle}>
        <T id={messages.rxTitle.id}>{intl.formatMessage(messages.rxTitle)}</T>
        {`: ${guidelineName}`}
      </ModalHeader>

      <ModalBody>
        <div className="mb-2">
          <T id={messages.pharmacyLabel.id} className="font-weight-bold">
            {intl.formatMessage(messages.pharmacyLabel)}
          </T>
          <span className="ml-1">{rxLocation.name}</span>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === TABS.MANUAL ? 'active' : ''}
              onClick={() => {
                changeTab(TABS.MANUAL);
              }}
            >
              <T id={messages.manualMode.id}>{intl.formatMessage(messages.manualMode)}</T>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === TABS.QUICKPICK ? 'active' : ''}
              onClick={() => {
                changeTab(TABS.QUICKPICK);
              }}
            >
              <T id={messages.quickpickMode.id}>{intl.formatMessage(messages.quickpickMode)}</T>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.MANUAL}>
            {activeTab === TABS.MANUAL && (
              <ManualTab guideline={guideline} locationId={rxLocation.id} onCompleted={onCompleted} />
            )}
          </TabPane>
          <TabPane tabId={TABS.QUICKPICK}>
            {activeTab === TABS.QUICKPICK && <QuickpickTab locationId={rxLocation.id} onCompleted={onCompleted} />}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};
