import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useMySystemSettings } from 'components-ts/HealthSystems';
import { useRxUtils } from 'components-ts/RXs/utils';
import { FullRX } from 'api/interfaces';

const messages = defineMessages({
  dispense: {
    id: 'CustomRxDrug.text_dispense',
    defaultMessage: 'dispense',
  },
  // this messages are used in common/src/logic/rx/index
  // this is just for building them
  once: { id: 'Prescription.once_a_day', defaultMessage: 'once a day' },
  every12: { id: 'Prescription.every_12_hours', defaultMessage: 'every 12 hours' },
  every8: { id: 'Prescription.every_8_hours', defaultMessage: 'every 8 hours' },
  every6: { id: 'Prescription.every_6_hours', defaultMessage: 'every 6 hours' },
  every4: { id: 'Prescription.every_4_hours', defaultMessage: 'every 4 hours' },
  every3: { id: 'Prescription.every_3_hours', defaultMessage: 'every 3 hours' },
  every2: { id: 'Prescription.every_2_hours', defaultMessage: 'every 2 hours' },
  every1: { id: 'Prescription.every_hour', defaultMessage: 'every hour' },
  every10m: { id: 'Prescription.every_10_minutes', defaultMessage: 'every 10 minutes' },
  every5m: { id: 'Prescription.every_5_minutes', defaultMessage: 'every 5 minutes' },
  every3m: { id: 'Prescription.every_3_minutes', defaultMessage: 'every 3 minutes' },
  timesPerDay: { id: 'Prescription.times_per_day', defaultMessage: 'times per day' },
  dosesADay: { id: 'Prescription.doses_a_day', defaultMessage: 'doses a day' },
  dose: { id: 'Prescription.dose', defaultMessage: 'dose' },
  doses: { id: 'Prescription.doses', defaultMessage: 'doses' },
  days: { id: 'Prescription.days', defaultMessage: 'Days' },
  day: { id: 'Prescription.day', defaultMessage: 'Day' },
  asNeeded: { id: 'label_prn_as_needed', defaultMessage: 'PRN' },
});

export const useCustomDrugValue = () => {
  const intl = useIntl();

  const { customizedRXView } = useRxUtils();

  const defaultRxFormat =
    '{legacy-drug-text}{drug-name} - ' +
    '{legacy-dose-text}{dose-number} {dose-unit} ' +
    '{units-per-dose} {drug-unit} ' +
    '{legacy-route-text}{route} ' +
    '{legacy-frequency}{frequency} ' +
    '{legacy-duration}{as-needed} ' +
    `${intl.formatMessage(messages.dispense)} {legacy-dispense}{dispense} {drug-stock-unit}`;

  const { pharmacy } = useMySystemSettings();
  const userFormat = pharmacy.format || defaultRxFormat;

  return {
    getRxValue: (rx: FullRX, format?: string) => customizedRXView(rx, format ?? userFormat),
  };
};

interface CustomRxDrugProps {
  rx: FullRX;
  format?: string;
  cancelled?: boolean;
}

export const CustomRxDrug: React.FC<CustomRxDrugProps> = (props) => {
  const { rx, format, cancelled } = props;

  const { getRxValue } = useCustomDrugValue();
  const { value } = getRxValue(rx, format);

  const style: React.CSSProperties = {
    whiteSpace: 'pre-wrap',
    marginBottom: 0,
    width: '100%',
  };

  if (cancelled) style.textDecorationLine = 'line-through';

  return (
    <p style={style} className="text-left">
      {value}
    </p>
  );
};
