import React, { useEffect, useState, useRef } from 'react'

import { LoadingInline } from 'components'
import { MdBrokenImage as ErrorIcon } from 'react-icons/md'

// 30s of timeout
const TIMEOUT_MS = 30000
const AsyncImage = props => {
  const { alt, src, className, onLoadCompleted } = props

  const ref = useRef()
  const [isLoading, setIsLoading] = useState(!!src)
  const [error, setError] = useState(!src)

  const onLoad = () => {
    setIsLoading(false)

    if (ref.current) {
      clearTimeout(ref.current)
    }

    if (typeof onLoadCompleted === 'function') {
      onLoadCompleted()
    }
  }

  const onError = () => {
    setError(true)
    setIsLoading(false)

    if (ref.current) {
      clearTimeout(ref.current)
    }
  }

  useEffect(
    () => {
      ref.current = setTimeout(() => {
        onError()
      }, TIMEOUT_MS)

      return () => {
        if (ref.current) {
          clearTimeout(ref.current)
        }
      }
    }, [])

  return <div className='d-flex align-items-center justify-content-center'>
    <img
      src={src}
      alt={alt}
      onLoad={onLoad}
      onError={onError}
      className={`${(isLoading || error) ? 'd-none' : 'd-block'} ` + className}
    />
    {isLoading && <LoadingInline />}
    {error && <ErrorIcon className='h1 m-1 text-muted' />}
  </div>
}

export default AsyncImage
