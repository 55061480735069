import { defineMessages, useIntl } from 'react-intl';
import { isValidDate } from './utils';

const messages = defineMessages({
  years: {
    id: 'DiseasesReport.age_unit_years',
    defaultMessage: 'y',
  },
  months: {
    id: 'DiseasesReport.age_unit_months',
    defaultMessage: 'mo',
  },
  days: {
    id: 'DiseasesReport.age_unit_days',
    defaultMessage: 'd',
  },
});

export const useAgeTranslator = () => {
  const intl = useIntl();

  const translateAge = (birthdate: string): string => {
    const yearLabel = intl.formatMessage(messages.years);
    const monthLabel = intl.formatMessage(messages.months);
    const dayLabel = intl.formatMessage(messages.days);

    const date = new Date(birthdate);

    if (!isValidDate(birthdate)) {
      return '--';
    }

    const ageAsDuration = new Date().getTime() - date.getTime();

    // google formula -> years = ms / 3.154e10
    const years = ageAsDuration / 3.154e10;

    if (years > 4) {
      return `${Math.trunc(years)}${yearLabel}`;
    }

    if (years >= 1) {
      // google formula -> months = days / 30.417
      const restMonths = ((years % 1) * 365.25) / 30.417;

      if (restMonths > 0) {
        return `${Math.trunc(years)}${yearLabel} ${Math.trunc(restMonths)}${monthLabel}`;
      }

      return `${Math.trunc(years)}${yearLabel}`;
    }

    const months = (years * 365.25) / 30.417;

    if (months > 0) {
      const restInDays = (months % 1) * 30;

      if (restInDays > 0) {
        return `${Math.trunc(months)}${monthLabel} ${Math.trunc(restInDays)}${dayLabel}`;
      }

      return `${Math.trunc(months)}${monthLabel}`;
    }

    const days = months * 30.417;

    return `${Math.trunc(days)}${dayLabel}`;
  };

  return {
    translateAge,
  };
};
