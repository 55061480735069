import React from 'react';
import { HowLong, ScheduledVisit, VisitType } from '../../api/interfaces';
import { ScheduleVisitForm } from './ScheduleVisitForm';
import { useCreateScheduledVisit, useUpdateScheduledVisit } from './useScheduledVisitActions';
import { useAvailableSlots } from './useAvailableSlots';
import { Nullable } from 'components-ts/utils';
import { useQueryParams } from 'hooks';
import { useHistory } from 'react-router-dom';
interface CreateEventModalProps {
  roomId: string;
  start: Date;
  end: Date;
  onClose: () => void;
  onCompleted?: () => void;
}

export const CreateScheduleVisitTab: React.FC<CreateEventModalProps> = (props) => {
  const { roomId, onClose, start, end } = props;

  const history = useHistory();
  const queryparams = useQueryParams();
  const patientId = queryparams.get('patient');

  const onCompleted = () => {
    queryparams.delete('patient');
    history.replace({ search: queryparams.toString() });

    if (typeof props.onCompleted === 'function') {
      props.onCompleted();
    }
    onClose();
  };

  const { isLoading, validate, onErrorClose, onSubmit, error } = useCreateScheduledVisit({
    onCompleted,
    roomId,
  });

  const { availableSlots, slotDurationInMinutes } = useAvailableSlots({
    roomId,
    date: start.toISOString(),
  });

  const initialValues = {
    type: '' as VisitType,
    patientId: patientId ?? '',
    start: start.toISOString(),
    end: end.toISOString(),
  };

  return (
    <ScheduleVisitForm
      slotDurationInMinutes={slotDurationInMinutes}
      availableSlots={availableSlots}
      isLoading={isLoading}
      initialValues={initialValues}
      validate={validate}
      onErrorClose={onErrorClose}
      onSubmit={onSubmit}
      error={error}
    />
  );
};

interface UpdateEventModalProps {
  event: ScheduledVisit;
  start: Nullable<Date>;
  end: Nullable<Date>;
  onClose: () => void;
}

export const UpdateScheduleVisitTab: React.FC<UpdateEventModalProps> = (props) => {
  const { event, onClose, start, end } = props;

  const onCompleted = () => {
    onClose();
  };

  const { isLoading, validate, onErrorClose, onSubmit, error } = useUpdateScheduledVisit({
    onCompleted,
    roomId: event.registrationData.room,
    scheduledVisitId: event.id,
  });

  const { availableSlots, slotDurationInMinutes } = useAvailableSlots({
    roomId: event.registrationData.room,
    date: event.registrationData.start,
    ignoreVisit: event.id,
  });

  const initialValues = {
    type: event.registrationData.type as VisitType,
    start: start ? start.toISOString() : event.registrationData.start,
    end: end ? end.toISOString() : event.registrationData.end,
    patientId: event.registrationData.patient.id,
    telehealth: event.registrationData.telehealth ?? false,
    complaint: event.registrationData.chiefComplaint?.complaint ?? '',
    howLong: (event.registrationData.chiefComplaint?.howLong ?? '') as HowLong,
  };

  return (
    <ScheduleVisitForm
      slotDurationInMinutes={slotDurationInMinutes}
      availableSlots={availableSlots}
      isLoading={isLoading}
      initialValues={initialValues}
      validate={validate}
      onErrorClose={onErrorClose}
      onSubmit={onSubmit}
      error={error}
      isDeletable
    />
  );
};
