import { gql } from '@apollo/client';
import { BasicChecklist, FullComplaintLists, FullExamWithCount, FullList } from 'utils/GQLFragments';

export const WD_CONTENT = gql`
  ${BasicChecklist}
  ${FullComplaintLists}
  ${FullList}
  ${FullExamWithCount}
  query wdContent($ids: [String]) {
    exams {
      ...FullExamWithCount
    }
    checklists(ids: $ids) {
      docs {
        ...BasicChecklist
      }
      count
    }
    complaintLists {
      ...FullComplaintLists
    }
    lists {
      count
      docs {
        ...FullList
      }
    }
  }
`;

export const GET_ICD10s = gql`
  query getICD10s($q: String) {
    getICD10s(q: $q) {
      docs {
        id
        name
        code
      }
      count
    }
  }
`;
