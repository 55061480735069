import { BasicPatientWithoutId, Signature } from '.';
import { Nullable } from 'components-ts/utils';
import { Drug, DrugGuideline, NewDrug, NewSystemDrugGuideline, NewUserDrugGuideline } from './pharmacy';

export enum CommonRouteType {
  IM = 'im',
  IV = 'iv',
  PO = 'po',
}

export enum DoseUnit {
  MG_KG = 'mg/kg',
  G_KG = 'g/kg',
  ML_KG = 'ml/kg',
  MCG_KG = 'mcg/kg',
  UNITS_KG = 'units/kg',
  UNITS_KG_HR = 'units/kg/hr',
  MCG_KG_MIN = 'mcg/kg/min',
  MCG_MIN = 'mcg/min',
  MG_MIN = 'mg/min',
  MG_HR = 'mg/hr',
  MG_KG_HR = 'mg/kg/hr',
  MG = 'mg',
  ML = 'ml',
  L = 'L',
  MEQ = 'meq',
  MEQ_KG = 'meq/kg',
  JOULES_KG = 'joules/kg',
  JOULES = 'joules',
  MCG = 'mcg',
  G = 'g',
  GRAMS = 'gram',
  DROPS = 'drops',
  PERCENT = '%',
  TABLETS = 'tablets',
  SPRAYS = 'sprays',
  DAB = 'dab',
  APPLICATION = 'application',
  OTHER = 'other',
  UNITS = 'units',
}

export enum FrequencyShow {
  FREQUENCY = 'frequency',
  DOSES = 'doses',
  DOSES_PER_DAY = 'doses_per_day',
}

export interface RXDetails {
  dispense: number;
  dose_number: number;
  dose_units: string;
  drug_dose_number: Nullable<number>;
  drug_dose_units: Nullable<string>;
  rounded_dose: Nullable<number>;
  rounded_dose_percent_difference: Nullable<number>;
  units_per_dose: number;
  units_per_dose_raw: Nullable<number>;
  route: string;
  frequency_show: Nullable<string>;
  doses_per_day: Nullable<number>;
  as_needed: Nullable<boolean>;
  days: number;
  refills_allowed: Nullable<number>;
  reviewed_medications: Nullable<boolean>;
  reviewed_side_effects: Nullable<boolean>;
  addressed_patient_questions: Nullable<boolean>;
  notes: Nullable<string>;
}

// Prescription, fill, order or administration of a medicine
export interface FullRX {
  guideline: Nullable<DrugGuideline>;
  drug: Drug;
  details: RXDetails;
  created: Nullable<Signature>;
  mixId: Nullable<string>;
  mixNotes: Nullable<string>;
}

interface QuickpickInfo {
  id: string;
  name: string;
}

export interface StoredRx {
  id: string;
  visitId: string;
  location: string;
  patient: BasicPatientWithoutId;
  rx: FullRX;
  origin: Nullable<string>;
  canceled: Nullable<boolean>;
  fill: Nullable<FullRX>;
  filled: Nullable<boolean>;
  quickpick: Nullable<QuickpickInfo>;
  updated: Nullable<Signature>;
}

export interface PastVisitsReportRx {
  fill: FullRX;
  lotNumbers: Nullable<Array<string>>;
}

export interface PrescriptionListRx {
  id: string;
  patient: Nullable<BasicPatientWithoutId>;
  rx: Nullable<Pick<FullRX, 'created'>>;
  filled: Nullable<boolean>;
  fill: Nullable<Pick<FullRX, 'created'>>;
}

export interface NewRXDetails {
  doseNumber: number;
  doseUnits: string;
  unitsPerDose: number;
  route: string;
  days: number;
  dispense: number;
  drugDoseNumber: Nullable<number>;
  drugDoseUnits: Nullable<string>;
  unitsPerDoseRaw: Nullable<number>;
  frequencyShow: Nullable<FrequencyShow>;
  dosesPerDay: Nullable<number>;
  asNeeded: Nullable<boolean>;
  refillsAllowed: Nullable<number>;
  notes: Nullable<string>;
}

export interface NewRX {
  guideline: Nullable<NewSystemDrugGuideline>;
  drug: NewDrug;
  details: NewRXDetails;
  mixId: Nullable<string>;
  mixNotes: Nullable<string>;
}

export interface NewUserGuidelineRX {
  guideline: Nullable<NewUserDrugGuideline>;
  drug: NewDrug;
  details: NewRXDetails;
  mixId: Nullable<string>;
  mixNotes: Nullable<string>;
}
