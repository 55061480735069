import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { GET_STUDY_BY_ID } from 'api/request/lab';
import { FullStudyWithOrder } from 'api/interfaces';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  studyNotFound: {
    id: 'useStudy.study_not_found',
    defaultMessage: 'Study not found',
  },
});

export interface StudyVariables {
  id: string;
}

interface StudyData {
  study: FullStudyWithOrder;
}

export type UseStudyParams = QueryHookOptions<StudyData, StudyVariables> & {
  id: string;
};

export const useStudy = (params: UseStudyParams) => {
  const { id, ...rest } = params;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const translateError = (code) => {
    switch (code) {
      case 'study_not_found':
        return setError(messages.studyNotFound);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors);
    translateError(firstError);

    if (typeof rest?.onError === 'function') {
      rest.onError(errors);
    }
  };

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<StudyData, StudyVariables>(GET_STUDY_BY_ID, {
    ...rest,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError,
  });

  const onErrorClose = () => {
    setError(null);
  };

  return {
    isLoading,
    study: data?.study ?? null,
    error,
    refetch,
    onErrorClose,
  };
};
