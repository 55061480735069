import React from 'react'
import { Progress } from 'reactstrap'
import { LoadingInline } from '../../components'

interface TranslationStatsProps {
  translated: number
  total: number
  isLoading?: boolean
}

export const TranslationStats: React.FC<TranslationStatsProps> = (props) => {
  const { isLoading, translated, total } = props

  const value = translated > 0 ? Math.floor((100 * translated) / total) : 0

  if (isNaN(value)) {
    return null
  }

  return (
    <div className='w-100 my-2'>
      {isLoading ? (
        <LoadingInline />
      ) : (
        <Progress className='progress' color='success' value={value}>
          {value} %
        </Progress>
      )}
    </div>
  )
}

