import { QueryHookOptions, useQuery } from '@apollo/client';
import { StoredRx } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { GET_RXS_BY_IDS } from 'api/request/rxs';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

interface UseRXsByIdsData {
  rxsByIDs: Array<StoredRx>;
}

interface UseRXsByIdsVariables {
  ids: Array<string>;
}

type UseRXsByIdsParams = QueryHookOptions<UseRXsByIdsData, UseRXsByIdsVariables> & { ids: Array<string> };

export const useRXsByIds = (params: UseRXsByIdsParams) => {
  const { ids, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (errors) => {
    const errorCode = extractFirstErrorCode(errors);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (rest.onCompleted) rest.onCompleted(data);
  };

  const { data, loading } = useQuery<UseRXsByIdsData, UseRXsByIdsVariables>(GET_RXS_BY_IDS, {
    ...rest,
    variables: {
      ids: ids,
    },
    skip: rest.skip || ids.length === 0,
    onError,
    onCompleted,
  });

  return {
    rxs: data?.rxsByIDs ?? [],
    isLoading: loading,
    error,
  };
};
