import React from 'react';
import { useSystems } from 'components-ts/HealthSystems';
import { FaPhone as PhoneIcon, FaHospital as LocationIcon } from 'react-icons/fa';
import { useIntl, defineMessages } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Address } from 'components-ts/Address';
import { FullAddress, HealthSystem, Location } from 'api/interfaces';
import { Nullable } from 'components-ts/utils';

// static translations
const messages = defineMessages({
  clinicName: {
    id: 'ClinicInfo.clinic_name_label',
    defaultMessage: 'Clinic: ',
  },
  phoneNumber: {
    id: 'ClinicInfo.phone_number_label',
    defaultMessage: 'Phone: ',
  },
});

type ClinicInfoProps = {
  current?: boolean;
  systemId?: string;
  locationId?: string;
  withIcon?: boolean;
  className?: string;
};

export const ClinicInfo: React.FC<ClinicInfoProps> = (props) => {
  // props
  const { current, systemId, locationId, withIcon, className } = props;

  const intl = useIntl();

  const { formatMessage } = intl;

  // hooks
  const { getSystem, getLocation, getMySystem, getMyLocation } = useSystems();
  let system: Nullable<HealthSystem>;
  let location: Nullable<Location>;

  if (!current) {
    if (!systemId || !locationId) {
      throw new TypeError('Clinic info cannot render without systemId and locationId');
    }

    system = getSystem(systemId);

    if (!system) {
      return null;
    }

    location = getLocation(system.id, locationId);
  } else {
    system = getMySystem();
    location = getMyLocation();
  }

  if (system && location) {
    const { country } = system;
    const { address, phoneNumber, name } = location;

    const splitAddress = (address || '').split('\n');
    const addressInfo: FullAddress = {
      country,
      line_1: splitAddress.length > 0 ? splitAddress[0] : null,
      line_2: splitAddress.length > 1 ? splitAddress[1] : null,
      line_3: splitAddress.length > 2 ? splitAddress[2] : null,
      locality: null,
      postal_code: null,
      province: null,
    };

    return (
      <div className={className || ''}>
        <div className="d-flex">
          {withIcon ? (
            <LocationIcon style={{ margin: '.2em 0' }} className="text-muted" />
          ) : (
            <T id={messages.clinicName.id}>{formatMessage(messages.clinicName)}</T>
          )}
          <b className="ml-2">{name}</b>
        </div>
        <Address address={addressInfo} withIcon={withIcon} withLabel={!withIcon} />
        {phoneNumber && (
          <div className="d-flex">
            {withIcon ? (
              <PhoneIcon style={{ margin: '.2em 0' }} className="text-muted" />
            ) : (
              <T id={messages.phoneNumber.id}>{formatMessage(messages.phoneNumber)}</T>
            )}
            <span className="ml-2">{phoneNumber}</span>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};
