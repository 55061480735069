import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_DISEASES_REPORTS } from 'scenes/Reports/requests';
import { graphQLErrorFormatter } from 'utils/errors';
import { Disease } from 'components-ts/interfaces';
import { DiseasesReportFilter } from './useDiseasesReportFilter';

interface DiseasesReportParams {
  filter: DiseasesReportFilter;
}

interface DiseasesReportData {
  diseases: Array<Disease>;
}

export const useDiseasesReport = (params: DiseasesReportParams) => {
  const [error, setError] = React.useState(null);

  const errorFormatter = (errorCode) => {
    switch (errorCode) {
      case 'invalid_date_range':
      case 'invalid_doctor':
      case 'invalid_location':
      case 'invalid_diagnosis':
      case 'invalid_visit_type':
      default:
        return {};
    }
  };

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter);
    setError(translatedErrors);
  };

  const onCompleted = () => {
    setError(null);
  };

  const { data, loading } = useQuery<DiseasesReportData, DiseasesReportFilter>(GET_DISEASES_REPORTS, {
    variables: {
      ...params.filter,
    },
    onCompleted,
    onError,
  });

  return {
    items: data?.diseases ?? [],
    error,
    isLoading: loading,
  };
};
