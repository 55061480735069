import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import Select from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Nullable } from 'components-ts/utils';
import { ReactSelectInternalSingleValue } from 'components-ts/Selectors';
import { DateRange, dateRangeFromPeriod, isValidDate, Period, startOfDay } from './utils';
import { DatePicker } from 'components-ts/Forms/DatePicker/DatePicker';

// const debug = require('debug')('wd:PeriodSelect');

const messages = defineMessages({
  lastDay: {
    id: 'periodSelection.last_day',
    defaultMessage: 'Last Day',
  },
  pastWeek: {
    id: 'periodSelection.past_week',
    defaultMessage: 'Past Week',
  },
  pastMonth: {
    id: 'periodSelection.past_month',
    defaultMessage: 'Past Month',
  },
  pastYear: {
    id: 'periodSelection.past_year',
    defaultMessage: 'Past Year',
  },
  monthToDate: {
    id: 'periodSelection.month_to_date',
    defaultMessage: 'Month to Date',
  },
  yearToDate: {
    id: 'periodSelection.year_to_date',
    defaultMessage: 'Year to Date',
  },
  from: {
    id: 'periodSelection.datepicker_from_label',
    defaultMessage: 'From',
  },
  to: {
    id: 'PeriodSelect.datepicker_to_label',
    defaultMessage: 'To',
  },
  period: {
    id: 'PeriodSelect.period_label',
    defaultMessage: 'Period',
  },
  customPeriod: {
    id: 'PeriodSelect.custom_period_label',
    defaultMessage: 'Custom period',
  },
});

type PeriodSelectProps = {
  onChange: (range: DateRange) => void;
  from: Nullable<Date>;
  to: Nullable<Date>;
  isClearable?: boolean;
  allowCustom?: boolean;
  defaultPeriod?: Period;
};

type PeriodSelectorProps = {
  onChange: (value: Nullable<ReactSelectInternalSingleValue>) => void;
  value: Nullable<Period>;
  isClearable?: boolean;
};

const PeriodSelector: React.FC<PeriodSelectorProps> = (props) => {
  const { onChange, value, isClearable } = props;

  const intl = useIntl();

  const periods = Object.values(Period);

  const formatOption = React.useCallback(
    (period: Period) => ({ value: period, label: intl.formatMessage(messages[period]) }),
    [intl]
  );

  const periodsOptions = React.useMemo(() => (periods as Array<any>).map(formatOption), [periods, formatOption]);

  // handle period select changes
  return (
    <FormGroup>
      <Label>
        <T id={messages.period.id}>{intl.formatMessage(messages.period)}</T>
      </Label>
      <Select
        options={periodsOptions}
        onChange={onChange}
        value={value ? formatOption(value) : null}
        isClearable={isClearable}
      />
    </FormGroup>
  );
};

export const PeriodSelect: React.FC<PeriodSelectProps> = (props) => {
  // props
  const { onChange, from, to, isClearable, allowCustom, defaultPeriod } = props;

  const intl = useIntl();

  const [period, setPeriod] = useState<Nullable<Period>>(defaultPeriod ?? null);
  const [startDate, setStartDate] = useState<Nullable<Date>>(from);
  const [endDate, setEndDate] = useState<Nullable<Date>>(to);

  // datepicker ranges
  const minDate = new Date();
  minDate.setFullYear(new Date().getFullYear() - 10);

  const maxDate = new Date();

  // period options values with translations
  const onPeriodChange = (selectedPeriod: Nullable<ReactSelectInternalSingleValue>) => {
    setPeriod((selectedPeriod?.value as Period) ?? null);

    // null range
    if (!selectedPeriod) return onChange({ from: new Date(), to: new Date() });

    const { from, to } = dateRangeFromPeriod(selectedPeriod.value as Period);

    // calculate range
    onChange({ from, to });
    setStartDate(from);
    setEndDate(to);
  };

  // handle date range select changes
  const onRangeChange = (range) => {
    // extract values
    const [startDate, endDate] = range as Array<Nullable<Date>>;

    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      const from = isValidDate(startDate) ? startOfDay(startDate) : startDate;
      const to = isValidDate(endDate) ? startOfDay(endDate) : endDate;

      // set range
      onChange({ from, to });
      setPeriod(null);
    }
  };

  return (
    <Row>
      <Col xs="12" sm={allowCustom ? '4' : '12'}>
        <PeriodSelector onChange={onPeriodChange} value={period} isClearable={isClearable} />
      </Col>
      {allowCustom && (
        <Col xs="12" sm="8">
          <FormGroup className="d-flex flex-column w-100">
            <Label>
              <T id={messages.customPeriod.id}>{intl.formatMessage(messages.customPeriod)}</T>
            </Label>
            <DatePicker
              selectsRange
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={onRangeChange}
              minDate={minDate}
              maxDate={maxDate}
              className="form-control"
              dropdownMode="select"
            />
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};
