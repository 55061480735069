import { QueryHookOptions, useQuery } from '@apollo/client';
import { FullProcedure, PaginatedResult } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { GET_PROCEDURES } from 'api/request/procedures';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

interface UseProceduresData {
  procedures: PaginatedResult<FullProcedure>;
}

interface UseProceduresVariables {
  q?: string;
  offset?: number;
  limit?: number;
  tags?: Array<string>;
  ids?: Array<string>;
}

export type UseProceduresParams = QueryHookOptions<UseProceduresData, UseProceduresVariables> & UseProceduresVariables;

export const useProcedures = (params?: UseProceduresParams) => {
  const { q, offset, limit, tags, ids, ...rest } = params ?? {};

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    if (rest.onError) rest.onError(graphqlError);

    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (rest.onCompleted) rest.onCompleted(data);
  };

  const { loading, data, refetch } = useQuery<UseProceduresData, UseProceduresVariables>(GET_PROCEDURES, {
    ...rest,
    onError,
    onCompleted,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      q,
      offset,
      limit,
      tags,
      ids,
    },
  });

  return {
    isLoading: loading,
    count: data?.procedures?.count ?? 0,
    procedures: data?.procedures?.docs ?? [],
    error,
    refetch,
  };
};
