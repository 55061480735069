import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { VISIT_TYPES } from 'wd-common/src/logic/visits/definitions'
import { isAValidType } from 'wd-common/src/logic/visits/validations'
const messages = defineMessages({
  sickVisit: {
    id: 'VisitType.Sick Visit',
    defaultMessage: 'Sick Visit'
  },
  followUp: {
    id: 'VisitType.Follow-up',
    defaultMessage: 'Follow-up'
  },
  childHealth: {
    id: 'VisitType.Child Health',
    defaultMessage: 'Child Health'
  },
  antenatalCare: {
    id: 'VisitType.Antenatal Care',
    defaultMessage: 'Antenatal Care'
  },
  delivery: {
    id: 'VisitType.Delivery',
    defaultMessage: 'Delivery'
  },
  familyPlanning: {
    id: 'VisitType.Family Planning',
    defaultMessage: 'Family Planning'
  },
  physicalExam: {
    id: 'VisitType.Physical Exam',
    defaultMessage: 'Physical Exam'
  },
  immunization: {
    id: 'VisitType.Immunization',
    defaultMessage: 'Immunization'
  },
  dental: {
    id: 'VisitType.Dental',
    defaultMessage: 'Dental'
  },
  transfer: {
    id: 'VisitType.Transfer',
    defaultMessage: 'Transfer'
  },
  other: {
    id: 'VisitType.Other',
    defaultMessage: 'Other'
  },
  generalPractitioner: {
    id: 'VisitType.General Practitioner',
    defaultMessage: 'General Practitioner'
  },
  medicalClearance: {
    id: 'VisitType.Medical Clearance',
    defaultMessage: 'Medical Clearance'
  },
  obgyn: {
    id: 'VisitType.OBGYN',
    defaultMessage: 'OB/GYN'
  },
  pediatrician: {
    id: 'VisitType.Pediatrician',
    defaultMessage: 'Pediatrician'
  },
  internist: {
    id: 'VisitType.Internist',
    defaultMessage: 'Internist'
  },
  testing: {
    id: 'VisitType.Testing',
    defaultMessage: 'Testing'
  },
  lactationCounseling: {
    id: 'VisitType.Lactation Counseling',
    defaultMessage: 'Lactation Counseling'
  },
  midwife: {
    id: 'VisitType.Midwife',
    defaultMessage: 'Midwife'
  }

})

const VisitType = props => {
  const { type, className } = props

  const intl = useIntl()

  if (!isAValidType(type)) return null

  const getTranslation = type => {
    switch (type) {
      case VISIT_TYPES.SICK_VISIT:
        return messages.sickVisit

      case VISIT_TYPES.FOLLOW_UP:
        return messages.followUp

      case VISIT_TYPES.CHIILD_HEALTH:
        return messages.childHealth

      case VISIT_TYPES.ANTENATAL_CARE:
        return messages.antenatalCare

      case VISIT_TYPES.DELIVERY:
        return messages.delivery

      case VISIT_TYPES.FAMILY_PLANNING:
        return messages.familyPlanning

      case VISIT_TYPES.PHYSICAL_EXAM:
        return messages.physicalExam

      case VISIT_TYPES.INMUNIZATION:
        return messages.immunization

      case VISIT_TYPES.DENTAL:
        return messages.dental

      case VISIT_TYPES.TRANSFER:
        return messages.transfer

      case VISIT_TYPES.OTHER:
        return messages.other

      case VISIT_TYPES.GENERAL_PRACTITIONER:
        return messages.generalPractitioner

      case VISIT_TYPES.MEDICAL_CLEARANCE:
        return messages.medicalClearance

      case VISIT_TYPES.OBGYN:
        return messages.obgyn

      case VISIT_TYPES.PEDIATRICIAN:
        return messages.pediatrician

      case VISIT_TYPES.INTERNIST:
        return messages.internist

      case VISIT_TYPES.TESTING:
        return messages.testing

      case VISIT_TYPES.LACTATION_COUNSELING:
        return messages.lactationCounseling

      case VISIT_TYPES.MIDWIFE:
        return messages.midwife

      default:
        return ''
    }
  }

  const message = getTranslation(type)

  return <T id={message.id} className={className || ''}>
    {intl.formatMessage(message)}
  </T>
}

export default VisitType

export const useVisitTypes = () => {
  const intl = useIntl()

  return {
    translateVisitType: (visitType) => {
      if (!isAValidType(visitType)) return ''

      const getTranslation = type => {
        switch (type) {
          case VISIT_TYPES.SICK_VISIT:
            return messages.sickVisit

          case VISIT_TYPES.FOLLOW_UP:
            return messages.followUp

          case VISIT_TYPES.CHIILD_HEALTH:
            return messages.childHealth

          case VISIT_TYPES.ANTENATAL_CARE:
            return messages.antenatalCare

          case VISIT_TYPES.DELIVERY:
            return messages.delivery

          case VISIT_TYPES.FAMILY_PLANNING:
            return messages.familyPlanning

          case VISIT_TYPES.PHYSICAL_EXAM:
            return messages.physicalExam

          case VISIT_TYPES.INMUNIZATION:
            return messages.immunization

          case VISIT_TYPES.DENTAL:
            return messages.dental

          case VISIT_TYPES.TRANSFER:
            return messages.transfer

          case VISIT_TYPES.OTHER:
            return messages.other

          case VISIT_TYPES.GENERAL_PRACTITIONER:
            return messages.generalPractitioner

          case VISIT_TYPES.MEDICAL_CLEARANCE:
            return messages.medicalClearance

          case VISIT_TYPES.OBGYN:
            return messages.obgyn

          case VISIT_TYPES.PEDIATRICIAN:
            return messages.pediatrician

          case VISIT_TYPES.INTERNIST:
            return messages.internist

          case VISIT_TYPES.TESTING:
            return messages.testing

          case VISIT_TYPES.LACTATION_COUNSELING:
            return messages.lactationCounseling

          case VISIT_TYPES.MIDWIFE:
            return messages.midwife

          default:
            return ''
        }
      }

      return intl.formatMessage(getTranslation(visitType))
    }
  }
}
