import React from 'react';
import { FieldInputProps } from 'react-final-form';
import { FormGroup, Label, FormText } from 'reactstrap';
import Creatable from 'react-select/creatable';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  containerLabel: {
    id: 'PharmacyStock.label_container',
    defaultMessage: 'Container',
  },
  helpText: {
    id: 'PharmacyStock.help_container',
    defaultMessage: 'What is the name of the container that this item comes in? Example: bottle, bag, alone.',
  },
});

type ContainerSelectorProps = FieldInputProps<any, HTMLElement> & {
  containers: Set<string>;
  isDisabled?: boolean;
};

export const ContainerSelector: React.FC<ContainerSelectorProps> = (props) => {
  const { containers, isDisabled, ...input } = props;

  const intl = useIntl();

  const options: Array<{ value: string; label: string }> = React.useMemo(
    () =>
      Array.from(containers.values()).map((c) => ({
        value: c,
        label: c,
      })),
    [containers]
  );

  return (
    <FormGroup>
      <Label for={input.name}>{intl.formatMessage(messages.containerLabel)}</Label>
      <Creatable
        {...input}
        blurInputOnSelect
        isSearchable
        isClearable
        options={options}
        onChange={(option) => input.onChange(option ? option.value : '')}
        value={options ? options.find((option) => option.value === input.value) : ''}
        isDisabled={isDisabled}
      />
      <FormText color="muted">{intl.formatMessage(messages.helpText)}</FormText>
    </FormGroup>
  );
};
