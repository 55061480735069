import { useQuery, QueryHookOptions } from '@apollo/client';
import { FullChecklist, PaginatedResult } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { GET_CHECKLISTS } from 'scenes/Checklists/requests';

const messages = defineMessages({
  errorGetting: {
    id: 'UseChecklistsTags.error_getting_checklists',
    defaultMessage: 'An error ocurred while fetching checklists',
  },
});

export interface ChecklistsVariables {
  q?: string;
  offset?: number;
  limit?: number;
  tags?: Array<string>;
}

type Result = PaginatedResult<FullChecklist>;

interface ChecklistsData {
  checklists: Result;
}

export type UseChecklistsParams = QueryHookOptions<ChecklistsData, ChecklistsVariables> & ChecklistsVariables;

export const useChecklists = (params?: UseChecklistsParams) => {
  const { q, offset, limit, tags, ...rest } = params ?? {};
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);
    switch (errorCode) {
      case 'error_getting_checklists':
      default:
        return setError(messages.errorGetting);
    }
  };

  const onCompleted = (data) => {
    if (typeof rest.onCompleted === 'function') {
      rest.onCompleted(data);
    }
  };

  const { loading, data, refetch } = useQuery<ChecklistsData, ChecklistsVariables>(GET_CHECKLISTS, {
    ...rest,
    variables: {
      q,
      offset,
      limit,
      tags,
    },
    onError,
    onCompleted,
  });

  return {
    error,
    isLoading: loading,
    docs: data?.checklists?.docs ?? [],
    count: data?.checklists?.count ?? 0,
    refetch,
  };
};
