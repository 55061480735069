/**
 * This component displays a list of similar objects. The main purpose of a
 * resource list is to help a user find one of these objects and either take
 * quick actions on it or navigate to a full page representation of it.
 *
 * Props documentation:
 *
 * <ResourceList
 *   resourceName: string
 *     The name name for the items, for example "patients".
 *   items: Array<any>
 *     A list of items to display
 *   renderItem?: (item, index): React.Node
 *     A function that takes an item and its index and returns a React component
 *   className
 *     The class name for the <ResourceList> component itself (for styling).
 *
 * Searcher
 *   searcherPlaceholder?: string
 *     Placeholder text to appear in the search box.
 *     Defaults to "Search ${resourceName}".
 *   isSearching?:boolean
 *     In case you use a custom onSearch method, this value will show a loading indicator on the ResourceSearcher
 *   onSearch?: (search:string): void
 *     Custom method called after the debounce time (if it's not disable)
 *     This method MUST NOT be re-defined on each re-render because it's inside of an useEffect dependency array
 *     So, probably you've to wrap it in a useCallback
 *   searchableProperties?: Array<string>
 *     Array of keys of the item object thah should be included when searching the list.
 *   hideSearcher?:boolean
 *     Hide the searcher
 *
 * Paginator
 *   onPageChange?: (index:number): void
 *     Custom method called after the paginator index changes (onSelect, onNext, onBack)
 *     If you're using a custom onSearch, you would be manage the paginator index to build the requests
 *   selectedPage?: number
 *     Selected page of the paginator. It is reset after each search
 *   itemsCount?: number
 *     Number of items
 *   pagesToShow?: number
 *     Number of visible pages indicator
 *   itemsToShow?: number
 *     Number of rows per page
 *   hidePaginator?:boolean
 *     Hide the paginator
 * />
 *
 * Static data
 * <ResourceList
 *    resourceName={'Mocked data'}
 *    items={[{name: "user", address: "Street 123" }, {...} ]}
 *    pagesToShow={5}
 *    itemsToShow={10}
 *    searchableProperties={['name', 'address']}
 *  />
 *
 * Fetching each page from the API: .../api/v1.0/patients?q=[search]&limit=[itemsToShow]&offset=[page * itemsToShow]
 * <ResourceList
 *    resourceName={resourceName}
 *    items={patients}
 *    onSearch={onPatientSearch}
 *    isSearching={isSearching}
 *    onPageChange={(index) => setPage(index)}
 *    selectedPage={page}
 *    itemsCount={patients.length}
 *    pagesToShow={5}
 *    hidePaginator={!withPaginator}
 *    hideSearcher={!withSearch}
 *    renderItem={(patient, index) => {
 *    const { id, first_name = '', last_name = '', birthdate = '', score } = patient
 *      return <ResourceItem
 *        key={id || `patient-item-${index}`}
 *        title={`${last_name}, ${first_name}`}
 *        subtitle={birthdate}
 *      />
 *    }}
 *  />
 */

import React, { useState, useCallback, useEffect } from 'react'
import ResourceSearcher from './ResourceSearcher'
import ResourcePaginator from './ResourcePaginator'
import { scoreString, stringMatchesTokens, tokenizeQuery } from 'wd-common/src/natural'
import ResourceItem from './ResourceItem'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { LoadingInline } from '../components-ts/Loading'
const debug = require('debug')('wd:ResourceList')

// static translations
const messages = defineMessages({
  loading: {
    id: 'UI.loading_text',
    defaultMessage: 'Loading...'
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No items found'
  },
  resultCount: {
    id: 'ResourceList.showing_result_count',
    defaultMessage: 'Showing {start}-{end} out of {total} results.'
  }
})

const ResourceList = (props) => {
  const {
    resourceName,
    header,
    items,
    renderItem,
    searcherPlaceholder,
    isSearching,
    onSearch,
    searchableProperties,
    q,
    onPageChange,
    selectedPage,
    pagesToShow,
    itemsCount: _itemsCount,
    itemsToShow,
    className,
    listClassName,
    hidePaginator,
    hideSearcher,
    intl
  } = props

  const { formatMessage } = intl

  const itemsCount = (!_itemsCount && typeof onSearch !== 'function') ? items.length : _itemsCount
  const pagesCount = Math.ceil((itemsCount || 0) / itemsToShow)

  const DYNAMIC_DATA_SEARCH = typeof onSearch === 'function'

  // for now, filter is just an string, but it could be an object with diferent filters
  const [filter, setFilter] = useState(q || '')

  // paginator index on static search mode
  const [localPaginatorIndex, setLocalPaginatorIndex] = useState(0)
  const searchTerms = tokenizeQuery(DYNAMIC_DATA_SEARCH ? q : filter)

  const recursiveSearch = (item, key, searchTerms) => {
    debug('received', item, key)
    if (key.toString().search(/\./) >= 0) {
      const [first, ...others] = key.split('.')
      debug('first', first)
      if (item[first]) return recursiveSearch(item[first], others, searchTerms)
    } else {
      debug('simple', item, key)
      // check for each searchable word
      if (item[key] && typeof item[key] === 'string') {
        const result = stringMatchesTokens(item[key], searchTerms)
        if (result) debug('found', item, key, searchTerms)
        return result
      } else {
        debug('not-string', item, key)
      }
    }
    return false
  }

  const isBeingFiltered = DYNAMIC_DATA_SEARCH || !filter
  // DYNAMIC_DATA_SEARCH means that the parent handle the state
  // and no filter means no filter
  const filteredItems = isBeingFiltered
    ? items
    : items.filter(item => {
      // search on the searchableProperties
      return (searchableProperties || []).some(word => recursiveSearch(item, word, searchTerms))
    })
      // Then do more expensive scoring
      .map(item => {
        const score = (searchableProperties || [])
          .map(word => {
            // check for each searchable word
            if (item[word] && typeof item[word] === 'string') {
              // scoring
              return scoreString(item[word], searchTerms)
            }
            return 0
          })
          .reduce((a, b) => a + b, 0)
        return { ...item, ResourceList_search: { score, searchableProperties: searchableProperties, searchTerms } }
      })
      // And sort on the result
      .sort((a, b) => {
        return b.ResourceList_search.score - a.ResourceList_search.score
      })

  // static data searcher method
  const defaultOnSearch = useCallback((value) => {
    setFilter(value)
    setLocalPaginatorIndex(0)
  }, [])

  // searcher props depends of the onSeach method received
  const searcherProps = typeof onSearch === 'function'
    ? {
      onSearch,
      placeHolder: searcherPlaceholder || `Search ${resourceName}`,
      isSearching,
      q
    }
    : {
      onSearch: defaultOnSearch,
      placeHolder: searcherPlaceholder || `Search ${resourceName}`,
      debounceTime: 0,
      q
    }

  // paginator props depends of the onPageChange method received
  const paginatorProps = DYNAMIC_DATA_SEARCH && typeof onPageChange === 'function'
    ? {
      onPageChange,
      selectedPage,
      pagesCount,
      pagesToShow,
      className: 'm-2',
      disableNext: isSearching, // disable the paginator when is fetching
      disableBack: isSearching // disable the paginator when is fetching
    }
    : {
      onPageChange: (index) => setLocalPaginatorIndex(index),
      selectedPage: localPaginatorIndex,
      pagesCount: filteredItems.length / itemsToShow,
      pagesToShow,
      className: 'm-2'
    }

  debug(filteredItems, searchTerms)

  // paginator
  const offset = DYNAMIC_DATA_SEARCH ? 0 : localPaginatorIndex * itemsToShow

  // start and end label for paginator
  const showingStart = filteredItems.length > 0 ? (selectedPage || localPaginatorIndex) * itemsToShow + 1 : 0
  const showingEnd = showingStart + itemsToShow < itemsCount ? showingStart + itemsToShow - 1 : itemsCount

  useEffect(() => {
    setFilter(q)
  }, [q])

  useEffect(() => {
    if (!DYNAMIC_DATA_SEARCH) setLocalPaginatorIndex(0)
  }, [itemsCount, DYNAMIC_DATA_SEARCH])
  return <div className={className}>
    {!hideSearcher && <ResourceSearcher {...searcherProps} />}

    <span className='d-flex text-capitalize'>{`${resourceName}`}</span>

    {header}

    <ul className={`list-group position-relative ${listClassName}`} >
      {
        filteredItems.length
          ? filteredItems.slice(offset, offset + itemsToShow)
            .map((item, index) => {
              return typeof renderItem === 'function'
                ? renderItem(item, index, searchTerms)
                : defaultRenderItem(item, index, searchTerms, searchableProperties)
            })
          : <div className='bg-light text-center p-2'>
            <T id={messages.noItemsFound.id}>{formatMessage(messages.noItemsFound)}</T>
          </div>
      }
      {isSearching &&
        <div className='position-absolute w-100 h-100 bg-white d-flex justify-content-center align-items-center' style={{
          opacity: 0.8
        }}>
          <LoadingInline />
          <T id={messages.loading.id}>{formatMessage(messages.loading)}</T>
        </div>
      }
    </ul>
    {
      !hidePaginator &&
      <div>
        <ResourcePaginator {...paginatorProps} />
        {isBeingFiltered && <div className='row d-flex justify-content-center'>
          <T id={messages.resultCount.id}>
            {formatMessage(messages.resultCount, {
              start: showingStart,
              end: showingEnd,
              total: itemsCount
            })}
          </T>
        </div>}
      </div>
    }

  </div>
}
const defaultRenderItem = (item, index, searchTerms, searchableProperties) => {
  const id = item.id ? item.id : `default-item-${index}`
  const title = searchableProperties[0] ? item[searchableProperties[0]] : item.title
  const subtitle = searchableProperties[1] ? item[searchableProperties[1]] : item.subtitle
  return <ResourceItem
    key={id || `resource-item-${index}`}
    title={title} // eslint-disable-line
    subtitle={subtitle}
    searchTerms={searchTerms}
    className='list-group-item  p-2'
  />
}

ResourceList.defaultProps = {
  resourceName: '',
  items: [],
  searchableProperties: ['title', 'subtitle'],
  searcherPlaceholder: '',
  isSearching: false,
  forcedQ: null,
  selectedPage: 0,
  pagesCount: 1,
  pagesToShow: 5,
  itemsToShow: 10,
  className: '',
  hidePaginator: false,
  hideSearcher: false,
  renderItem: null,
  onSearch: null,
  onPageChange: null
}
export default injectIntl(ResourceList)
