import React from 'react';
import Select from 'react-select';
import { ReactSelectInternalSingleValue, ReactSelectInternalValue, SingleSelectorProps } from 'components-ts/Selectors';
import { Nullable } from 'components-ts/utils';
import { useIntl } from 'react-intl';
import { isValidDate } from 'components-ts/DateAndTime';

/**
 * Quick styles customization to make it work with the
 * brand color set in the chakra context in light & dark mode.
 */
// import './styles.css'

export type TimeSelectorProps = SingleSelectorProps<string> & {
  availableSlots: Array<Date>;
  isDisabled?: boolean;
  isClearable?: boolean;
};

export const TimeSelector: React.FC<TimeSelectorProps> = (props) => {
  const { value, availableSlots, isDisabled, isClearable = false } = props;

  const intl = useIntl();

  const formatOption = (datetime: Date): ReactSelectInternalSingleValue => {
    const date = intl.formatDate(datetime);
    const time = intl.formatTime(datetime);

    return {
      value: datetime.toISOString(),
      label: `${date} ${time}`,
    };
  };

  const options = availableSlots.map(formatOption);
  const parseValue = (value: string): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    const date = new Date(value);

    if (isValidDate(date)) {
      return formatOption(date);
    }

    return null;
  };

  const _onChange = (value) => {
    if (!value) {
      return;
    }

    props.onChange(value.value);
  };
  return (
    <Select
      value={parseValue(value)}
      onChange={_onChange}
      options={options}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isSearchable
      blurInputOnSelect
    />
  );
};
