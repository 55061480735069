import React from 'react';
import Creatable from 'react-select/creatable';
import { FieldInputProps } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';

const messages = defineMessages({
  no_units_found: {
    id: 'StockUnitSelector.no_units_found',
    defaultMessage: 'No stock units found',
  },
  placeholder: {
    id: 'StockUnitSelector.placeholder',
    defaultMessage: 'Stock Unit',
  },
});

type StockUnitSelectorProps = FieldInputProps<any, HTMLElement> & {
  stockUnits: Set<string>;
  isDisabled?: boolean;
};

export const StockUnitSelector: React.FC<StockUnitSelectorProps> = (props) => {
  const { stockUnits, isDisabled, ...input } = props;

  const intl = useIntl();

  const options = React.useMemo(
    () => Array.from(stockUnits.values()).map((v) => ({ value: v, label: v })),
    [stockUnits]
  );

  return (
    <Creatable
      {...input}
      placeholder={intl.formatMessage(messages.placeholder)}
      // format the options
      options={options}
      components={{
        NoOptionsMessage: () => (
          <p className="text-center pt-3">
            <T id={messages.no_units_found.id}>{intl.formatMessage(messages.no_units_found)}</T>
          </p>
        ),
      }}
      blurInputOnSelect
      isSearchable
      isClearable
      // react-select uses {value: string, label: string} as value
      // react-final-form uses traditional string only
      // so we need to handle this with some hacky moves
      onChange={(option) => input.onChange(option ? option.value : '')}
      value={options ? options.find((option) => option.value === input.value) : ''}
      isDisabled={isDisabled}
    />
  );
};
