import React, { useState, useEffect, useRef } from 'react'
import { Label, InputGroup, Input, FormGroup } from 'reactstrap'

const debug = require('debug')('wd:EditableInput')

/**
 * EditableInput
 * A text input that notifies its parent on blur.
 */

const EditableInput = (props) => {
  const {
    value: v,
    name,
    label,
    error,
    placeholder,
    autoFocus,
    forceDigits,
    ensureParseFloat,
    className,
    style,
    onChange,
    onKeyboard,
    addonAfter: AddonAfter,
    addonBefore: AddonBefore,
    ...rest
  } = props
  const [value, setValue] = useState(v)
  const ref = useRef(null)

  useEffect(() => {
    if (autoFocus && ref && ref.current) ref.current.focus()
  }, [autoFocus])

  const inputProps = {
    value,
    name,
    placeholder,
    className: `'form-control' ${error ? 'is-invalid' : ''}`,
    style,
    ref,
    ...rest
  }

  return <FormGroup className={className}>

    {label && <Label>{label}</Label>}
    <InputGroup>
      {AddonBefore}
      <Input
        type='text'
        {...inputProps}
        onKeyUp={e => {
          switch (e.keyCode) {
            case 27: setValue(value, _ => { if (typeof ref.current.blur === 'function') ref.current.blur(); if (typeof onKeyboard === 'function') onKeyboard() }); break
            case 13: if (typeof ref.current.blur === 'function') ref.current.blur(); if (typeof onKeyboard === 'function')onKeyboard(); break
            default: break
          }
        }}
        onChange={e => {
          let val = e.target.value
          if (forceDigits) {
            val = parseInt(val, 10)
            val = Number.isNaN(val) ? '' : val
          }
          if (ensureParseFloat) {
            while (val.length && (Number.isNaN(parseFloat(val)) || !val.match(/^[0-9.]*$/))) {
              val = val.slice(0, -1)
            }
          }
          setValue(val)
        }}
        onBlur={e => {
          onChange(value)
        }}
      />
      {AddonAfter}
    </InputGroup>
    {error && <div className='invalid-feedback d-flex'>{error}</div> }
  </FormGroup>
}

EditableInput.defaultProps = {
  placeholder: '',
  value: '',
  className: '',
  style: {},
  onChange: _ => {},
  onKeyboard: _ => {},
  forceDigits: false,
  ensureParseFloat: false,
  autoFocus: false
}

export default EditableInput
debug('loaded')
