import { MutationHookOptions, useMutation } from '@apollo/client';
import { FullPatientWithActiveVisit, NewEmergencyContact } from 'api/interfaces';
import { Nullable, extractFirstErrorCode } from 'components-ts/utils';
import { useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { UPDATE_EMERGENCY_CONTACT } from 'api/request/patients';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  errorUpdatingContact: {
    id: 'PersonalInfoCard.error_saving_emergency_contact',
    defaultMessage: 'Could not save the emergency contact. Please, try again',
  },
});

interface UsePatientUpdateEmergencyContactData {
  updatePatientEmergencyContact: FullPatientWithActiveVisit;
}

interface UsePatientUpdateEmergencyContactVariables {
  id: string;
  newEmergencyContact: NewEmergencyContact;
}

type UsePatientUpdateEmergencyContactParams = MutationHookOptions<
  UsePatientUpdateEmergencyContactData,
  UsePatientUpdateEmergencyContactVariables
> & { patientId: string };

export const usePatientUpdateEmergencyContact = (params: UsePatientUpdateEmergencyContactParams) => {
  const { patientId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (errors) => {
    const errorCode = extractFirstErrorCode(errors);

    switch (errorCode) {
      case 'error_saving_emergency_contact':
        return setError(messages.errorUpdatingContact);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = () => {
    setError(null);
  };

  const [updateEmergencyContact, { loading }] = useMutation<
    UsePatientUpdateEmergencyContactData,
    UsePatientUpdateEmergencyContactVariables
  >(UPDATE_EMERGENCY_CONTACT, { ...rest, onError, onCompleted });

  const onSubmit = (values: NewEmergencyContact) => {
    updateEmergencyContact({
      variables: {
        id: patientId,
        newEmergencyContact: values,
      },
    });
  };

  return {
    onSubmit,
    error,
    isLoading: loading,
  };
};
