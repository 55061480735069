import React from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from '.';
import { DefaultSystemOrLocationAttr } from 'components-ts/Auth/utils';
import { useIntl, defineMessages } from 'react-intl';
import { useSystems } from 'components-ts/HealthSystems';

const messages = defineMessages({
  room: {
    id: 'RoomSelector.room_label',
    defaultMessage: 'Room',
  },
  noRoom: {
    id: 'RoomSelector.no_room_label',
    defaultMessage: 'There is no existing room for this location',
  },
});

export type RoomSelectorProps = SelectorProps<string> & {
  locationId?: string;
  onlyAvailable?: boolean;
  hideLabel?: boolean;
  autoSelect?: boolean;
  className?: string;
};

const formatOption = (room: DefaultSystemOrLocationAttr): ReactSelectInternalSingleValue => {
  const { id, name } = room;

  return {
    value: id,
    label: name,
  };
};

export const RoomSelector: React.FC<RoomSelectorProps> = (props) => {
  const {
    locationId,
    autoSelect,
    value,
    isClearable,
    isDisabled,
    className,
    onlyAvailable = false,
    hideLabel = false,
  } = props;

  const intl = useIntl();

  const { isLoading, getLocation, getMyLocation, getMySystem } = useSystems();

  const { id: systemId } = getMySystem();
  const { id: currentLocationId } = getMyLocation();

  const { rooms = [] } = getLocation(systemId, locationId ?? currentLocationId) ?? {};

  const options = React.useMemo(() => {
    if (Array.isArray(rooms)) {
      if (onlyAvailable) {
        return rooms.filter((room) => !!room.available).map(formatOption);
      }

      return rooms.map(formatOption);
    }

    return [];
  }, [rooms, onlyAvailable]);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): any => {
    if (options === null) {
      return {};
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return {};
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, roomId: string) => {
        const room = rooms.find((room) => room.id === roomId);

        if (room) {
          return [...prev, formatOption(room)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      const room = rooms.find((room) => room.id === value);

      if (room) {
        return formatOption(room);
      }

      return {};
    }
  };

  const _onChange = React.useCallback(
    (v: ReactSelectInternalValue): void => {
      if (isMultiSelector(props)) {
        if (!Array.isArray(v)) {
          return props.onChange([]);
        }

        const selectedValues = v as Array<ReactSelectInternalSingleValue>;
        const newValues = selectedValues.map((item) => item.value);

        props.onChange(newValues);
      } else {
        const selectedValue = v as ReactSelectInternalSingleValue;

        props.onChange(selectedValue.value);
      }
    },
    [props]
  );

  React.useEffect(() => {
    if (!value && autoSelect && options.length > 0) {
      _onChange(options[0]);
    }
  }, [options, autoSelect, _onChange, value]);

  if (!(rooms.length > 0)) {
    return (
      <Label>
        <T id={messages.noRoom.id}>{intl.formatMessage(messages.noRoom)}</T>
      </Label>
    );
  }

  if (hideLabel) {
    return (
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    );
  }

  return (
    <FormGroup>
      <Label>
        <T id={messages.room.id}>{intl.formatMessage(messages.room)}</T>
      </Label>
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    </FormGroup>
  );
};
