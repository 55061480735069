import React from 'react';
import { FullComment } from 'api/interfaces';
import { useIntl } from 'react-intl';
import { getNameInitials } from 'components-ts/utils';

interface OrderCommentsProps {
  comments: Array<FullComment>;
  className?: string;
}
export const OrderComments: React.FC<OrderCommentsProps> = (props) => {
  const { comments, className } = props;

  const intl = useIntl();
  if (comments.length <= 0) {
    return null;
  }

  return (
    <ul className={`pl-0 mb-0 d-flex flex-column align-items-end ${className || ''}`}>
      {comments.map((comment, cid) => {
        const date = intl.formatDate(new Date(comment.created.timestamp), { dateStyle: 'short', timeStyle: 'short' });
        const author = getNameInitials(`${comment.created.given_name} ${comment.created.family_name}`);

        return (
          <li className="d-table py-1 px-2 mb-1 rounded border border-gray" key={cid} style={{ maxWidth: 420 }}>
            <span className="mb-1">{comment.text}</span>

            <div className="border-top border-light pt-1 d-flex justify-content-end">
              <small>
                <b className="mr-2">{author}</b>
                <span>{date}</span>
              </small>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
