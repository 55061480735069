import { AccountStatus, useSession } from 'components-ts/Auth';
import React from 'react';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/browserUtilities';

const STORAGE_KEY = 'wd:visibleTranslations';

export const TranslationModeSwitch: React.VFC = () => {
  const onChange = () => {
    if (isTranslationMode) {
      removeLocalStorage(STORAGE_KEY);
    } else {
      setLocalStorage({ [STORAGE_KEY]: 'true' });
    }

    window.location = window.location; // eslint-disable-line no-self-assign
  };

  const isTranslationMode = getLocalStorage(STORAGE_KEY) === 'true';

  return (
    <div className="d-flex align-items-start">
      Translation mode
      <label className="switch switch-pill switch-primary ml-2">
        <input type="checkbox" className="switch-input" checked={isTranslationMode} onChange={onChange} />
        <span className="switch-slider"></span>
      </label>
    </div>
  );
};

export const useDevVisibility = () => {
  const { isLoggedIn, userHasRole, getAccountStatus } = useSession();

  if (isLoggedIn()) {
    const accountStatus = getAccountStatus();
    const userReady = AccountStatus.READ_ONLY_ACCESS === accountStatus || AccountStatus.FULL_ACCESS === accountStatus;

    if (userReady) {
      const isTranslationMode = getLocalStorage(STORAGE_KEY) === 'true';

      return (userHasRole('Translator') || userHasRole('Admin')) && isTranslationMode;
    }
  }

  return false;
};
