/**
 * final form validators
 */

import { isValidDate } from 'components-ts/DateAndTime'
import { isValidPhoneNumber } from 'components-ts/Forms/utils'
import { SEX } from 'wd-common/src/logic/patient/definitions'

export const composeValidators = (...validators) => (value, allValues) => {
  return validators.reduce((error, validator) => error || validator(value, allValues), undefined)
}

export const required = message => value => (value ? undefined : message)

export const mustBeNumber = message => value => (isNaN(value) ? message : undefined)

export const minValue = (min, strict) => message => value => {
  if (strict) return isNaN(value) || value <= min ? `${message} ${min}` : undefined
  else return isNaN(value) || value < min ? `${message} ${min}` : undefined
}
export const maxValue = (max, strict) => message => value => {
  if (strict) return isNaN(value) || value >= max ? `${message} ${max}` : undefined
  else return isNaN(value) || value > max ? `${message} ${max}` : undefined
}
export const minLength = (min, strict) => message => value => {
  if (strict) return value && value.toString().length >= min ? `${message} ${min}` : undefined
  else return value && value.toString().length < min ? `${message} ${min}` : undefined
}

export const mustBeEqual = (ref) => message => value => {
  return ref === value ? undefined : message
}

export const mustBeEmailAddress = (message, noMandatory) => value => {
  if (noMandatory && (!value || value === '')) {
    return undefined
  }
  const EXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return EXP.test(value) ? undefined : message
}

/**
 *
 * @param {string} message error message
 * @param {string} contryCode (Optional) country code
 */
export const mustBePhoneNumber = (message, contryCode = '') => value => {
  return isValidPhoneNumber(value, contryCode) ? undefined : message
}

export const mustStartWith = ref => (message) => value => {
  return value.indexOf(ref) === 0 ? undefined : message
}

export const nonEmptyArray = (message) => value => {
  return Array.isArray(value) && value.length > 0 ? undefined : message
}

export const mustBeADate = message => value => {
  return isValidDate(value) ? undefined : message
}

export const mustBeAValidSex = message => value => {
  if (SEX.FEMALE === value || SEX.MALE === value) {
    return undefined
  }

  return message
}
