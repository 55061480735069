import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ResourcePaginator } from './ResourcePaginator';
import { scoreString, stringMatchesTokens, tokenizeQuery } from 'wd-common/src/natural';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { LoadingInline } from 'components-ts/Loading';
import { useDebounceExececute, useQueryParams } from 'hooks';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FaSearch as SearchIcon } from 'react-icons/fa';
const messages = defineMessages({
  search: {
    id: 'UI.label_search',
    defaultMessage: 'Search...',
  },
  loading: {
    id: 'UI.loading_text',
    defaultMessage: 'Loading...',
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No results',
  },
  resultCount: {
    id: 'ResourceList.showing_result_count',
    defaultMessage: 'Showing {start}-{end} out of {total} results.',
  },
});

/**
 * This component displays a list of similar objects. The main purpose of a
 * resource list is to help a user find one of these objects and either take
 * quick actions on it or navigate to a full page representation of it.
 *
 * This version is synchronized with the url
 */

type SearchableListProps<T> = {
  items: Array<T>;
  renderItem: (item: T, index: number, searchTerms: Array<string>) => JSX.Element;
  itemsToShow: number;
  searchableProperties?: Array<string>;
  initialSearch?: string;
  onSearch?: (q: string) => void;
  onPageChange?: (index: number) => void;
  searcherPlaceholder?: string;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isURLSync?: boolean;
};

export function SearchableList<T>(props: SearchableListProps<T>) {
  const {
    items,
    renderItem,
    searchableProperties,
    onPageChange: _onPageChange,
    initialSearch = '',
    itemsToShow,
    isDisabled,
    isLoading,
    isURLSync = true,
    className = '',
  } = props;

  const intl = useIntl();

  const queryParams = useQueryParams();
  const history = useHistory();

  const fromUrlIndex = isURLSync ? Number(queryParams.get('page')) : 0;
  const initialIndex = !isURLSync || isNaN(fromUrlIndex) ? 0 : fromUrlIndex;
  const initialFilter = isURLSync ? queryParams.get('q') ?? '' : initialSearch;

  const [paginatorIndex, setPaginatorIndex] = useState<number>(initialIndex);
  const [filter, setFilter] = useState<string>(initialFilter);
  const ref = React.useRef<HTMLInputElement>(null);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value;

    // update states
    setFilter(q);
    setPaginatorIndex(0);

    if (isURLSync) {
      // update url
      queryParams.set('page', '0');
      if (q.length > 0) {
        queryParams.set('q', q);
      } else {
        queryParams.delete('q');
      }

      history.replace({
        search: queryParams.toString(),
      });
    }
    // run callback
    if (typeof props.onSearch === 'function') {
      props.onSearch(q);
    }
  };

  const onPageChange = React.useCallback(
    (index: number) => {
      // update state
      setPaginatorIndex(index);

      if (isURLSync) {
        // update url
        queryParams.set('page', index.toString());
        history.replace({
          search: queryParams.toString(),
        });
      }
      // run callback
      if (typeof _onPageChange === 'function') {
        _onPageChange(index);
      }
    },
    [_onPageChange, queryParams, history, isURLSync]
  );

  const searchTerms = tokenizeQuery(filter);
  const filteredItems = searchableProperties
    ? items
        .filter((item) =>
          // search on the searchableProperties
          searchableProperties.some((word) => recursiveSearch(item, word, searchTerms))
        )
        .map((item) => {
          const score = searchableProperties
            ?.map((word) => {
              // check for each searchable word
              if (item[word] && typeof item[word] === 'string') {
                // scoring
                return scoreString(item[word], searchTerms);
              }
              return 0;
            })
            .reduce((a, b) => a + b, 0);

          // append score for sorting
          return {
            ...item,
            ResourceList_search: {
              score,
              searchableProperties: searchableProperties,
              searchTerms,
            },
          };
        })
        // And sort on the result
        .sort((a, b) => b.ResourceList_search.score - a.ResourceList_search.score)
    : items;

  const isSearcherVisible = itemsToShow < items.length;
  const isPaginatorVisible = itemsToShow < filteredItems.length;
  const offset = paginatorIndex * itemsToShow;

  React.useEffect(() => {
    if (ref.current && initialSearch) {
      ref.current.focus();
      ref.current.select();
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    setPaginatorIndex(0);
  }, [items]); // eslint-disable-line

  return (
    <div className={className}>
      {isSearcherVisible && (
        <InputGroup>
          <Input
            value={filter}
            onChange={onSearch}
            type="text"
            className="form-control"
            placeholder={intl.formatMessage(messages.search)}
            aria-label={intl.formatMessage(messages.search)}
            innerRef={ref}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <SearchIcon />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      )}
      <ul className={'list-group position-relative my-1'}>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center  bg-light py-4 my-1 rounded animated fadeIn">
            <LoadingInline />
            <T id={messages.loading.id} className="ml-2">
              {intl.formatMessage(messages.loading)}
            </T>
          </div>
        ) : filteredItems.length === 0 ? (
          <div className="bg-light text-center bg-light py-4 my-1 rounded text-muted  animated fadeIn">
            <T id={messages.noItemsFound.id}>{intl.formatMessage(messages.noItemsFound)}</T>
          </div>
        ) : (
          <>
            <div className="rounded">
              {filteredItems
                .slice(offset, offset + itemsToShow)
                .map((item, index) => renderItem(item, index, searchTerms))}
            </div>
            {isPaginatorVisible && (
              <ResourcePaginator
                onPageChange={onPageChange}
                selectedPage={paginatorIndex}
                itemsCount={filteredItems.length}
                itemsToShow={itemsToShow}
                pagesToShow={5}
                isDisabled={isDisabled}
                className="mt-2"
              />
            )}
          </>
        )}
      </ul>
    </div>
  );
}

type AsyncSearchableListProps<T> = {
  items: Array<T>;
  renderItem: (item: T, index: number, searchTerms: Array<string>) => JSX.Element;
  itemsToShow: number;
  itemsCount: number;
  onSearch: (q: string) => void;
  onPageChange: (index: number) => void;
  isLoading: boolean;
  initialSearch?: string;
  initialPage?: number;
  searcherPlaceholder?: string;
  className?: string;
  isDisabled?: boolean;
};

export function AsyncSearchableList<T>(props: AsyncSearchableListProps<T>) {
  const {
    items,
    renderItem,
    itemsCount,
    onPageChange: _onPageChange,
    initialSearch = '',
    initialPage = 1,
    itemsToShow,
    isDisabled,
    isLoading,
    className = '',
  } = props;

  const intl = useIntl();

  const { execute } = useDebounceExececute(300);

  const [paginatorIndex, setPaginatorIndex] = useState<number>(initialPage);
  const [filter, setFilter] = useState<string>(initialSearch);
  const ref = React.useRef<HTMLInputElement>(null);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value;

    execute(() => {
      // update states
      setFilter(q);
      setPaginatorIndex(0);

      // run callback
      if (typeof props.onSearch === 'function') {
        props.onSearch(q);
      }
    });
  };

  const onPageChange = React.useCallback(
    (index: number) => {
      // update state
      setPaginatorIndex(index);

      // run callback
      if (typeof _onPageChange === 'function') {
        _onPageChange(index);
      }
    },
    [_onPageChange]
  );

  const searchTerms = tokenizeQuery(filter);
  const isPaginatorVisible = itemsToShow < itemsCount;

  React.useEffect(() => {
    if (ref.current && initialSearch) {
      ref.current.focus();
      ref.current.select();
    }
  }, []); // eslint-disable-line

  return (
    <div className={className}>
      {
        <InputGroup>
          <Input
            defaultValue={filter}
            onChange={onSearch}
            type="text"
            className="form-control"
            placeholder={intl.formatMessage(messages.search)}
            aria-label={intl.formatMessage(messages.search)}
            innerRef={ref}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <SearchIcon />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      }
      <ul className={'list-group position-relative my-1 border-0'}>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center bg-light py-4 my-1 rounded animated fadeIn">
            <LoadingInline />
            <T id={messages.loading.id} className="ml-2">
              {intl.formatMessage(messages.loading)}
            </T>
          </div>
        ) : items.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center bg-light py-4 my-1 rounded animated fadeIn">
            <T id={messages.noItemsFound.id}>{intl.formatMessage(messages.noItemsFound)}</T>
          </div>
        ) : (
          <>
            <div className="rounded">{items.map((item, index) => renderItem(item, index, searchTerms))}</div>
            {isPaginatorVisible && (
              <ResourcePaginator
                onPageChange={onPageChange}
                selectedPage={paginatorIndex}
                itemsCount={itemsCount}
                itemsToShow={itemsToShow}
                pagesToShow={5}
                isDisabled={isDisabled}
                className="mt-2"
              />
            )}
          </>
        )}
      </ul>
    </div>
  );
}

/**
 * Recursive search that
 * Look for string matches between the value of items[key]
 * and the array of search terms
 */
export function recursiveSearch<T>(item: T, key: string, searchTerms: Array<string>) {
  if (key.toString().search(/\./) >= 0) {
    const [first, ...others] = key.split('.');

    if (item[first]) {
      return recursiveSearch(item[first], others.join('.'), searchTerms);
    }
  } else {
    if (item[key] && typeof item[key] === 'string') {
      const result = stringMatchesTokens(item[key], searchTerms);

      return Boolean(result);
    }
  }

  return false;
}

interface ClickableItemWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick: () => void;
  isDisabled?: boolean;
}

export const ClickableItemWrapper: React.FC<ClickableItemWrapperProps> = (props) => {
  const { onClick, children, isDisabled } = props;

  const className = `animated-hover ${
    isDisabled ? 'not-allowed' : 'pointer'
  } text-dark px-3 py-2 mb-1 rounded border border-light ${props.className}`;
  return (
    <div
      className={className}
      onClick={isDisabled ? undefined : onClick}
      style={{ minHeight: 54, ...(props.style ?? {}) }}
    >
      {children}
    </div>
  );
};
