import { MutationHookOptions, useMutation } from '@apollo/client';
import { HealthSystem } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { SUBSCRIBE_TO_STUDIES_BATCH, SUBSCRIBE_TO_STUDY, UNSUBSCRIBE_FROM_STUDY } from 'api/request/systems';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  errorSubscribingToStudy: {
    id: 'LabTab.could_not_subscribe_to_study',
    defaultMessage: 'Could not subscribe to study. Please, retry.',
  },
  errorUnsubscribingFromStudy: {
    id: 'LabTab.could_not_unsubscribe_from_study',
    defaultMessage: 'Could not unsubscribe from study. Please, retry.',
  },
});

interface UseSubscribeToStudyData {
  subscribeSystemToStudyTemplate: HealthSystem;
}

interface UseSubscribeToStudyVariables {
  systemId: string;
  locationId: string;
  item: string;
}

type UseSubscribeToStudyParams = MutationHookOptions<UseSubscribeToStudyData, UseSubscribeToStudyVariables> & {
  systemId: string;
  locationId: string;
};

export const useSubscribeToStudy = (params: UseSubscribeToStudyParams) => {
  const { systemId, locationId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_subscribe_to_study':
        return setError(messages.errorSubscribingToStudy);
      case 'invalid_item_id':
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToStudy, { loading: isLoading }] = useMutation<UseSubscribeToStudyData, UseSubscribeToStudyVariables>(
    SUBSCRIBE_TO_STUDY,
    { ...restParams, onError, onCompleted }
  );

  const onSubmit = (item: string) => {
    subscribeToStudy({
      variables: {
        systemId,
        locationId,
        item,
      },
    });
  };

  return {
    onSubmit,
    subscribeToStudy,
    isLoading,
    error,
  };
};

interface UseUnsubscribeFromStudyData {
  unsubscribeSystemFromStudyTemplate: HealthSystem;
}

interface UseUnsubscribeFromStudyVariables {
  systemId: string;
  locationId: string;
  item: string;
}

type UseUnsubscribeFromStudyParams = MutationHookOptions<
  UseUnsubscribeFromStudyData,
  UseUnsubscribeFromStudyVariables
> & { systemId: string; locationId: string };

export const useUnsubscribeFromStudy = (params: UseUnsubscribeFromStudyParams) => {
  const { systemId, locationId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_unsubscribe_to_study':
        return setError(messages.errorUnsubscribingFromStudy);
      case 'invalid_item_id':
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [unsubscribeFromStudy, { loading: isLoading }] = useMutation<
    UseUnsubscribeFromStudyData,
    UseUnsubscribeFromStudyVariables
  >(UNSUBSCRIBE_FROM_STUDY, { ...restParams, onError, onCompleted });

  const onSubmit = (item: string) => {
    unsubscribeFromStudy({
      variables: {
        systemId,
        locationId,
        item,
      },
    });
  };

  return {
    onSubmit,
    unsubscribeFromStudy,
    isLoading,
    error,
  };
};

interface UseSubscribeFromStudiesBatchData {
  subscribeFromStudiesBatch: HealthSystem;
}

interface UseSubscribeFromStudiesBatchVariables {
  systemId: string;
  locationId: string;
  ids: Array<string>;
}

type UseSubscribeFromStudiesBatchParams = MutationHookOptions<
  UseSubscribeFromStudiesBatchData,
  UseSubscribeFromStudiesBatchVariables
> & { systemId: string; locationId: string };

export const useSubscribeFromStudiesBatch = (params: UseSubscribeFromStudiesBatchParams) => {
  const { systemId, locationId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_subscribe_to_studies':
        return setError(messages.errorSubscribingToStudy);
      case 'invalid_item_id':
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToStudies, { loading: isLoading }] = useMutation<
    UseSubscribeFromStudiesBatchData,
    UseSubscribeFromStudiesBatchVariables
  >(SUBSCRIBE_TO_STUDIES_BATCH, { ...restParams, onError, onCompleted });

  const onSubmit = (ids: Array<string>) => {
    subscribeToStudies({
      variables: {
        systemId,
        locationId,
        ids,
      },
    });
  };

  return {
    onSubmit,
    subscribeToStudies,
    isLoading,
    error,
  };
};
