import { Signature } from 'api/interfaces';
import { areSignaturesFromSameDay } from 'components-ts/DateAndTime';
import { useIntl } from 'react-intl';

/**
 * This receive two signatures.
 * The current to be formatted and the previous one.
 * If both are from the same day, the output string
 * will only contain the time. If not, date & time
 */

export const useSimplifiedDateFromSignatures = (signature: Signature, prevSignature?: Signature) => {
  const intl = useIntl();

  const date = intl.formatDate(new Date(signature.timestamp), { dateStyle: 'medium' });
  const time = intl.formatTime(new Date(signature.timestamp), { timeStyle: 'short' });

  if (prevSignature) {
    const isSameDay = areSignaturesFromSameDay(signature, prevSignature);

    if (isSameDay) {
      return { time };
    }
  }

  return { date, time };
};
