export const BILLABLE_TYPES = Object.freeze({
  ADMINISTRATION: 'administration',
  DOCTOR_FEE: 'doctorFee',
  MEDICINES: 'medicines',
  LAB: 'lab',
  PROCEDURES: 'procedures',
  OTHERS: 'others'
})

export const BILL_STATUS = Object.freeze({
  PAID: 'PAID',
  UNPAID: 'UNPAID'
})

export const XENDIT_STATUS = Object.freeze({
  PENDING: 'PENDING',
  PAID: 'PAID',
  EXPIRED: 'EXPIRED'
})
