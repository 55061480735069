import React from 'react'
import { SessionContext } from 'components-ts/Auth'

const useSession = () => {
  const session = React.useContext(SessionContext)
  // validateNotNil<Session>(session);

  if (session === null) {
    throw new Error('Using session out of the conntext')
  }
  return session
}

export default useSession
