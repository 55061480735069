import { Nullable } from 'components-ts/utils';
import { TranslatableText, FullReference, Signature } from './common';
import { Integrations } from './integrations';
import { PrescriptionListRx } from './rxs';
import { ChecklistEntryType } from './checklists';
export interface SystemDrugGuideline {
  type: ChecklistEntryType;
  entryId: string;
  name: TranslatableText;
  pre_text: Nullable<TranslatableText>;
  dose_units: Nullable<string>;
  dose_range_min: Nullable<number>;
  dose_range_max: Nullable<number>;
  dose_max: Nullable<number>;
  max_dose: Nullable<number>;
  doses_per_day: Nullable<number>;
  max_dose_units: Nullable<string>;
  route: Nullable<TranslatableText>;
  post_text: Nullable<TranslatableText>;
  reference: Nullable<FullReference>;
  billing_tags: Nullable<Array<string>>;
  history_codes: Nullable<Array<string>>;
  physical_exam_codes: Nullable<Array<string>>;
  quality_codes: Nullable<Array<string>>;

  frequency_show: Nullable<string>;
  as_needed: Nullable<boolean>;
  days: Nullable<number>;
  max_doses: Nullable<number>;
  key?: Nullable<number>;
  checked?: Nullable<boolean>;
}

export interface NewSystemDrugGuideline {
  type: string;
  entryId: Nullable<string>;
  pre_text: Nullable<TranslatableText>; // no need to create a NewTr... in ts
  name: Nullable<TranslatableText>;
  dose_units: string;
  dose_range_min: number;
  dose_range_max: number;
  dose_max: Nullable<TranslatableText>;
  doses_per_day: Nullable<number>;
  max_dose: Nullable<number>;
  max_doses: Nullable<number>;
  max_dose_units: Nullable<string>;
  route: Nullable<TranslatableText>;
  post_text: Nullable<TranslatableText>;
  frequency_show: Nullable<string>;
  as_needed: Nullable<boolean>;
  days: Nullable<number>;
  key: Nullable<number>;
  billing_tags: Nullable<Array<string>>;
  history_codes: Nullable<Array<string>>;
  physical_exam_codes: Nullable<Array<string>>;
  quality_codes: Nullable<Array<string>>;
  reference: FullReference; // here neither
}

export interface UserDrugGuideline {
  type: string;
  userGuidelineName: string;
  doseUnits: string;
  doseRangeMin: number;
  doseRangeMax: number;
  doses_per_day: Nullable<number>;
  max_dose: Nullable<number>;
  max_dose_units: Nullable<string>;
  route: Nullable<TranslatableText>;
  frequency_show: Nullable<string>;
  as_needed: Nullable<boolean>;
  days: Nullable<number>;
}

export interface NewUserDrugGuideline {
  type: string;
  name: string;
  dose_units: string;
  dose_range_min: number;
  dose_range_max: number;
  doses_per_day: number;
  max_dose: number;
  max_dose_units: string;
  route: string;
  frequency_show: string;
  as_needed: Nullable<boolean>;
  days: Nullable<number>;
}

export type DrugGuideline = SystemDrugGuideline | UserDrugGuideline;

export interface LotNearExpiringInfo {
  date: string;
  amount: number;
}
export interface StockByLocationAnalytics {
  regressionData: Nullable<Array<Array<number>>>;
  slope: Nullable<number>;
  intercept: Nullable<number>;
  daysUntilStockOut: Nullable<number>;
  lotNearExpiringInfo: Nullable<LotNearExpiringInfo>;
}
export interface StockByLocation {
  stock: number;
  analytics: Nullable<StockByLocationAnalytics>;
}

export interface DrugBPJSSettings {
  bpjsId: string;
}

export interface DrugIntegrationsSettings {
  [Integrations.BPJS]: DrugBPJSSettings;
}

export interface Drug {
  id: string;
  drugId: string;
  stockInfo: Nullable<StockByLocation>;
  name: string;
  unit: string;
  retail: Nullable<number>;
  class: string;
  category: Nullable<string>;
  dosing: Nullable<string>;
  interactions: Nullable<string>;
  comments: Nullable<string>;
  currentStock: Nullable<number>;
  recommended_dosing: Nullable<string>;
  wholesale: Nullable<number>;
  stock_unit: Nullable<string>;
  minimum_units_to_dispense: Nullable<number>;
  guideline: Nullable<UserDrugGuideline>;
  tags: Nullable<Array<TranslatableText>>;
  integrations: Nullable<DrugIntegrationsSettings>;
  created: Nullable<Signature>;
  updated: Nullable<Signature>;
}

export interface NewDrug {
  id: string;
  name: string;
  unit: string;
  tags?: Nullable<Array<string>>;
  retail: Nullable<number>;
  class: Nullable<string>;
  category: Nullable<string>;
  dosing: Nullable<string>;
  interactions: Nullable<string>;
  comments: Nullable<string>;
  recommended_dosing: Nullable<string>;
  currentStock?: Nullable<number>;
  wholesale?: Nullable<number>;
  stock_unit: Nullable<string>;
  minimum_units_to_dispense: Nullable<number>;
}

export interface PharmacyItemStockEntry {
  id: string;
  amount: number;
  balance: Nullable<number>;
  container: Nullable<string>;
  containerCapacity: Nullable<number>;
  containerCount: Nullable<number>;
  created: Nullable<string>;
  createdBy: Nullable<Signature>;
  item: string;
  lastModified: Nullable<string>;
  lastModifiedBy: Nullable<Signature>;
  location: string;
  notes: Nullable<string>;
  rxId: Nullable<string>;
  stock_unit: Nullable<string>;
  lotNumber: Nullable<string>;
  lotExpirationDate: Nullable<string>;
  audit: Nullable<boolean>;
}

export enum PrescriptionType {
  ORDER = 'order',
  PRESCRIPTION = 'prescription',
}

export interface Prescriptions {
  visitId: string;
  location: string;
  date: Signature;
  rxs: Array<PrescriptionListRx>;
}

export enum PharmacyEducation {
  REVIEWED_MEDICATIONS = 'reviewedMedications',
  REVIEWED_SIDE_EFFECTS = 'reviewedSideEfects',
  ADDRESSED_PATIENT_QUESTIONS = 'addressedPatientQuestions',
  DRUG_AND_INDICATION = 'drugAndIndication',
  DOSE_AND_ROUTE = 'doseAndRoute',
  DRUG_FREQUENCY_AND_DURATION = 'drugFrequencyAndDuration',
  INTERACTIONS = 'interactions',
  ALLERGIES = 'allergies',
  CONTRAINDICATIONS = 'contraindications',
  REFILL_PROCESS = 'refillProcess',
  DUPLICATE_MEDICINES = 'duplicateMedicines',
}
