import React from 'react';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { FullStudyTemplate, PaginatedResult } from 'api/interfaces';
import { GET_STUDY_TEMPLATES } from 'api/request/lab';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';

const messages = defineMessages({
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Please, try again.',
  },
});

interface StudyTemplatesVariables {
  q?: string;
  offset?: number;
  limit?: number;
  tags?: Array<string>;
  ids?: Array<string>;
}

interface StudyTemplatesData {
  studyTemplates: PaginatedResult<FullStudyTemplate>;
}

export type UseStudyTemplatesParams = QueryHookOptions<StudyTemplatesData, StudyTemplatesVariables> &
  StudyTemplatesVariables;

export const useStudyTemplates = (params?: UseStudyTemplatesParams) => {
  const { q, offset, limit, tags, ids, ...rest } = params ?? {};

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(messages.unexpectedError);
    }
  };

  const onCompleted = (data) => {
    if (typeof rest.onCompleted === 'function') {
      rest.onCompleted(data);
    }
  };

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<StudyTemplatesData, StudyTemplatesVariables>(GET_STUDY_TEMPLATES, {
    variables: {
      q,
      offset,
      limit,
      tags,
      ids,
    },
    ...rest,
    onError,
    onCompleted,
  });

  return {
    refetch,
    isLoading,
    count: data?.studyTemplates?.count ?? 0,
    studies: data?.studyTemplates?.docs ?? [],
    error,
  };
};
