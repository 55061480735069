import React from 'react'
import Select from 'react-select'
import { FormGroup, Label } from 'reactstrap'
import { DOSE_UNITS } from 'wd-common/src/logic/pharmacy/definitions'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { injectIntl, defineMessages } from 'react-intl'
import { DoseUnit } from 'components-ts/Pharmacy/DoseUnit'

const debug = require('debug')('wd:DoseUnitSelector')

/**
 * Component for allow to select a drugUnit/s
 *
 * @component
 * @example
 *
 * const onChange = (drugUnit) => debug(drugUnit)
 * return (
 *   <DoseUnitSelector
 *      defaultValue={drugUnit}
 *      onChange={onChange}
 *   />
 * )
 */

const messages = defineMessages({

  doseUnits: {
    id: 'DoseUnitSelector.dose_units_label',
    defaultMessage: 'Dose Units'
  },
  noUnitsFoundError: {
    id: 'DoseUnitSelector.label_no_units_found',
    defaultMessage: 'No units found'
  }
})

const DoseUnitSelector = (props) => {
  // props
  const {
    defaultValue,
    onChange,
    isClearable,
    withCount,
    withLabel = true,
    customLabel,
    onlyTopUnit = false,
    className,
    isDisabled = false,
    intl
  } = props

  const { formatMessage } = intl

  if (typeof onChange !== 'function') debug('Missing prop: onChange')

  const formatOptions = unit => {
    return {
      value: unit,
      label: <DoseUnit doseUnit={unit} />
    }
  }

  const _onChange = (val) => {
    if (typeof onChange === 'function') onChange((val || {}).value)
  }

  const array = Object.values(DOSE_UNITS)

  // strip out units of the format unit/other unit
  const unitsFilter = value => value.indexOf('/') === -1

  const options = onlyTopUnit
    ? array.filter(unitsFilter).map(formatOptions)
    : array.map(formatOptions)

  return <FormGroup >
    {withLabel && <Label>
      {customLabel || <T id={messages.doseUnits.id}>{formatMessage(messages.doseUnits)}</T>}
      <span>{withCount && ` (${options.length})`}</span>
    </Label>
    }
    <Select
      className={`dropdownZIndex ${className}`}
      defaultValue={formatOptions(defaultValue)}
      onChange={_onChange}
      options={options}
      isClearable={isClearable}
      isSearchable
      isDisabled={isDisabled}
      components={{
        NoOptionsMessage: () => <p className='text-center pt-3' >{
          // formatmessage allows us to reuse the same id
          formatMessage(messages.noUnitsFoundError)
        }</p>
      }}
    />
  </FormGroup>
}

DoseUnitSelector.default = {
  defaultValue: '',
  onChange: () => { }
}
export default injectIntl(DoseUnitSelector)
