import React from 'react';
import { useUpdateSchedulerSettings } from '../HealthSystems/useSchedulerActions';
import { defineMessages, useIntl } from 'react-intl';
import { SchedulerSettingsForm } from './SchedulerSettingsForm';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const messages = defineMessages({
  settings: {
    id: 'Telehealth.label_Settings',
    defaultMessage: 'Calendar settings',
  },
});

interface UpdateSchedulerSettingsModalProps {
  onClose: () => void;
  onCompleted?: () => void;
}

export const UpdateSchedulerSettingsModal: React.FC<UpdateSchedulerSettingsModalProps> = (props) => {
  const { onClose, onCompleted } = props;

  const intl = useIntl();

  const { isLoading, validate, onErrorClose, onSubmit, error, initialValues } = useUpdateSchedulerSettings({
    onCompleted,
  });

  return (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader toggle={onClose}>{intl.formatMessage(messages.settings)}</ModalHeader>
      <ModalBody>
        <SchedulerSettingsForm
          isLoading={isLoading}
          validate={validate}
          onErrorClose={onErrorClose}
          onSubmit={onSubmit}
          error={error}
          initialValues={initialValues}
        />
      </ModalBody>
    </Modal>
  );
};
