import React from 'react';
import { useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Nullable } from 'components-ts/utils';

type DynamicTranslationProps = {
  text: string;
  className?: string;
};

/**
 * Use this component if you need to translate dynamic text (i.e: Medicines)
 * @param {string} text Text to be translated dynamically
 */
export const DynamicTranslation = (props: DynamicTranslationProps) => {
  const { text, className } = props;

  const intl = useIntl();

  if (typeof text !== 'string' || text.trim().length === 0) return null;

  const prefix = 'Dynamic';
  const defaultMessage = text.trim();
  const id = `${prefix}.${defaultMessage}`;

  return (
    <T id={id} className={className}>
      {intl.formatMessage({ id, defaultMessage })}
    </T>
  );
};

export const useDynamicTranslation = () => {
  const { formatMessage } = useIntl();

  const translateDynamic = React.useCallback(
    (text: string): Nullable<string> => {
      if (text.trim().length === 0) return null;

      const prefix = 'Dynamic';
      const defaultMessage = text.trim();
      const id = `${prefix}.${defaultMessage}`;

      return formatMessage({ id, defaultMessage });
    },
    [formatMessage]
  );

  return {
    translateDynamic,
  };
};
