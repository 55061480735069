import { Signature } from 'api/interfaces';
import { assertUnreachable } from '../utils';
import { defineMessages, useIntl } from 'react-intl';

/**
 * Period selector presets
 */
export enum Period {
  LAST_DAY = 'lastDay',
  PAST_WEEK = 'pastWeek',
  PAST_MONTH = 'pastMonth',
  PAST_YEAR = 'pastYear',
  MONTH_TO_DATE = 'monthToDate',
  YEAR_TO_DATE = 'yearToDate',
}

export interface DateRange {
  from: Date;
  to: Date;
}

export const startOfDay = (date: Date) => {
  const aux = new Date(date);
  aux.setHours(0, 0, 0, 0);
  return aux;
};

export const endOfDay = (date: Date) => {
  const aux = new Date(date);
  aux.setHours(23, 59, 59);
  return aux;
};

export const startOfMonth = (date: Date) => {
  const aux = new Date(date);
  aux.setDate(-1);

  return aux;
};

export const endOfMonth = (date: Date) => {
  const aux = new Date(date);
  aux.setDate(1); // Avoids edge cases on the 31st day of some months
  aux.setMonth(aux.getMonth() + 1);
  aux.setDate(0);
  aux.setHours(23);
  aux.setMinutes(59);
  aux.setSeconds(59);

  return aux;
};

export const substractMs = (date: Date, ms: number) => {
  const aux = new Date(date);
  aux.setTime(aux.getTime() - ms);

  return aux;
};

export const substractDays = (date: Date, days: number) => {
  const aux = new Date(date);
  aux.setDate(aux.getDate() - days);

  return aux;
};

export const addDays = (date: Date, days: number) => {
  const aux = new Date(date);
  aux.setDate(aux.getDate() + days);

  return aux;
};

export const addWeeks = (date: Date, weeks: number) => {
  return addDays(date, 7 * weeks);
};

export const substractYears = (date: Date, days: number) => {
  const aux = new Date(date);

  aux.setFullYear(aux.getFullYear() - days);

  return aux;
};

export const addMinutes = (date: Date, mins: number) => {
  const aux = new Date(date);

  const ms = mins * 60 * 1000;
  aux.setTime(aux.getTime() + ms);

  return aux;
};

export const substractMinutes = (date: Date, mins: number) => {
  const aux = new Date(date);

  const ms = mins * 60 * 1000;
  aux.setTime(aux.getTime() - ms);

  return aux;
};

export const startOfYear = (date: Date) => {
  const aux = new Date(date);

  aux.setMonth(0, 1);
  return aux;
};

type Inclusivity = '[]';

export const isBetween = (needle: Date, floor: Date, ceil: Date, inclusivity: Inclusivity = '[]') => {
  switch (inclusivity) {
    case '[]':
      return floor.getTime() <= needle.getTime() && ceil.getTime() >= needle.getTime();
    default:
      return assertUnreachable('Invalid inclusivity', inclusivity);
  }
};

/**
 *
 * @param a The first date to diff
 * @param b The second date to diff, defaults to new Date() if not provided
 * @returns Difference in days signed
 */
export const calcDateDiffInDays = (a: Date | string, b?: Date | string) => {
  const aux = b ? new Date(b) : new Date();

  const diffInMs = new Date(a).getTime() - aux.getTime();
  return diffInMs / (1000 * 3600 * 24);
};

/**
 *
 * @param a The first date to diff
 * @param b The second date to diff, defaults to new Date() if not provided
 * @returns Difference in hours signed
 */
export const calcDateDiffInMinutes = (a: Date | string, b?: Date | string) => {
  const aux = b ? new Date(b) : new Date();

  const diffInMs = new Date(a).getTime() - aux.getTime();
  return diffInMs / (1000 * 60);
};

/**
 *
 * @param a The first date to diff
 * @param b The second date to diff, defaults to new Date() if not provided
 * @returns Difference in hours signed
 */
export const calcDateDiffInHours = (a: Date | string, b?: Date | string) => {
  const aux = b ? new Date(b) : new Date();

  const diffInMs = new Date(a).getTime() - aux.getTime();
  return diffInMs / (1000 * 3600);
};

/**
 *
 * @param a The first date to diff
 * @param b The second date to diff, defaults to new Date() if not provided
 * @returns Difference in months signed
 */
export const calcDateDiffInMonths = (a: Date | string, b?: Date | string) => {
  const aux = b ? new Date(b) : new Date();

  const diffInMs = new Date(a).getTime() - aux.getTime();
  return diffInMs / (1000 * 3600 * 24 * 30.417);
};

/**
 *
 * @param a The first date to diff
 * @param b The second date to diff, defaults to new Date() if not provided
 * @returns Difference in years signed
 */
export const calcDateDiffInYears = (a: Date | string, b?: Date | string) => {
  const aux = b ? new Date(b) : new Date();

  const diffInMs = new Date(a).getTime() - aux.getTime();
  return diffInMs / (1000 * 3600 * 24 * 365.25);
};

const fromDateMessages = defineMessages({
  minutes: {
    id: 'UI.minutes_template',
    defaultMessage: '{minutes} Minutes',
  },
  hours: {
    id: 'UI.hours_template',
    defaultMessage: '{hours} Hours',
  },
});

export const useFromDate = () => {
  const intl = useIntl();

  return {
    fromDate: (start: Date, end: Date) => {
      if (!isValidDate(start) && !isValidDate(end)) {
        return null;
      }

      const minutes = calcDateDiffInMinutes(end, start);

      if (minutes < 60) {
        return intl.formatMessage(fromDateMessages.minutes, { minutes: Math.round(minutes) });
      }

      if (minutes < 120) {
        return intl.formatMessage(fromDateMessages.minutes, { minutes: Math.round(minutes) });
      }

      const hours = Math.round(calcDateDiffInHours(end, start));
      return intl.formatMessage(fromDateMessages.hours, { hours });
    },
  };
};

export const dateRangeFromPeriod = (period: Period): DateRange => {
  switch (period) {
    case Period.LAST_DAY: {
      return {
        from: startOfDay(new Date()),
        to: new Date(),
      };
    }
    case Period.MONTH_TO_DATE: {
      return {
        from: startOfMonth(new Date()),
        to: new Date(),
      };
    }
    case Period.PAST_MONTH: {
      return {
        from: substractDays(new Date(), 30),
        to: new Date(),
      };
    }
    case Period.PAST_WEEK: {
      return {
        from: substractDays(new Date(), 7),
        to: new Date(),
      };
    }
    case Period.PAST_YEAR: {
      return {
        from: substractYears(new Date(), 1),
        to: new Date(),
      };
    }
    case Period.YEAR_TO_DATE: {
      return {
        from: startOfYear(new Date()),
        to: new Date(),
      };
    }
    default:
      assertUnreachable('Invalida period', period);
  }
};

export const isValidDate = (value: string | number | Date): boolean => {
  return !isNaN(Date.parse(value.toString()));
};

export const areSignaturesFromSameDay = (signature: Signature, prevSignature: Signature): boolean => {
  const currDate = new Date(signature.timestamp);
  const prevDate = new Date(prevSignature.timestamp);

  return (
    currDate.getFullYear() === prevDate.getFullYear() &&
    currDate.getMonth() === prevDate.getMonth() &&
    currDate.getDate() === prevDate.getDate()
  );
};
