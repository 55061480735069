import { MutationHookOptions, useMutation } from '@apollo/client';
import { HealthSystem, NewRoom } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { CREATE_ROOM, DELETE_ROOM, UPDATE_ROOM } from 'api/request/systems';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  roomHasScheduledEvents: {
    id: 'RoomEditor.cannot_delete_room_scheduled_events',
    defaultMessage: 'Cannot delete this room, there may be scheduled events on it.',
  },
});

interface UseCreateRoomData {
  createRoom: HealthSystem;
}

interface UseCreateRoomVariables {
  systemId: string;
  locationId: string;
  room: NewRoom;
}

type UseCreateRoomParams = MutationHookOptions<UseCreateRoomData, UseCreateRoomVariables> & {
  systemId: string;
  locationId: string;
};

export const useCreateRoom = (params: UseCreateRoomParams) => {
  const { systemId, locationId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [createRoom, { loading }] = useMutation<UseCreateRoomData, UseCreateRoomVariables>(CREATE_ROOM, {
    ...rest,
    onError,
    onCompleted,
  });

  const onSubmit = (newRoom: NewRoom) => {
    const { name, available, description } = newRoom;

    createRoom({
      variables: {
        systemId,
        locationId,
        room: {
          name,
          available,
          description,
        },
      },
    });
  };

  const validate: ValidateFn<NewRoom> = (values) => {
    const errors: ValidationError<NewRoom> = {};

    if (!values.name) {
      errors.name = 'required';
    }

    return errors;
  };

  return {
    onSubmit,
    validate,
    createRoom,
    loading,
    error,
  };
};

interface UseDeleteRoomData {
  deleteRoom: HealthSystem;
}

interface UseDeleteRoomVariables {
  systemId: string;
  locationId: string;
  roomId: string;
}

type UseDeleteRoomParams = MutationHookOptions<UseDeleteRoomData, UseDeleteRoomVariables> & {
  systemId: string;
  locationId: string;
};

export const useDeleteRoom = (params: UseDeleteRoomParams) => {
  const { systemId, locationId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'cannot_delete_room_scheduled_events':
        return setError(messages.roomHasScheduledEvents);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [deleteRoom, { loading }] = useMutation<UseDeleteRoomData, UseDeleteRoomVariables>(DELETE_ROOM, {
    ...rest,
    onError,
    onCompleted,
  });

  const onDelete = (roomId: string) => {
    deleteRoom({
      variables: {
        systemId,
        locationId,
        roomId,
      },
    });
  };

  return {
    onDelete,
    deleteRoom,
    loading,
    error,
  };
};

interface UseEditRoomData {
  updateRoom: HealthSystem;
}

interface UseEditRoomVariables {
  systemId: string;
  locationId: string;
  roomId: string;
  room: NewRoom;
}

type UseEditRoomParams = MutationHookOptions<UseEditRoomData, UseEditRoomVariables> & {
  systemId: string;
  locationId: string;
  roomId: string;
};

export const useEditRoom = (params: UseEditRoomParams) => {
  const { systemId, locationId, roomId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [updateRoom, { loading }] = useMutation<UseEditRoomData, UseEditRoomVariables>(UPDATE_ROOM, {
    ...rest,
    onError,
    onCompleted,
  });

  const onEdit = (newRoom: NewRoom) => {
    const { name, available, description } = newRoom;

    updateRoom({
      variables: {
        systemId,
        locationId,
        roomId,
        room: {
          name,
          available,
          description,
        },
      },
    });
  };

  const validate: ValidateFn<NewRoom> = (values) => {
    const errors: ValidationError<NewRoom> = {};

    if (!values.name) {
      errors.name = 'required';
    }

    return errors;
  };

  return {
    onEdit,
    validate,
    updateRoom,
    loading,
    error,
  };
};
