import { MutationHookOptions, useMutation } from '@apollo/client';
import { Drug } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { DELETE_PHARMACY_ITEM } from 'scenes/Pharmacy/requests';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

interface DeletePharmacyItemVariables {
  itemId: string;
}

interface DeletePharmacyItemData {
  deletePharmacyItem: Drug;
}

type DeletePharmacyItemMutationParams = MutationHookOptions<DeletePharmacyItemData, DeletePharmacyItemVariables> & {
  itemId: string;
};

export const useDeletePharmacyItem = (params: DeletePharmacyItemMutationParams) => {
  const { itemId } = params;
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const [deletePharmacyItem, { loading }] = useMutation<DeletePharmacyItemData, DeletePharmacyItemVariables>(
    DELETE_PHARMACY_ITEM,
    {
      ...params,
      onError,
    }
  );

  const onDelete = () =>
    deletePharmacyItem({
      variables: {
        itemId,
      },
    });

  return {
    deletePharmacyItem,
    onDelete,
    loading,
    error,
  };
};
