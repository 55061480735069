import { gql } from '@apollo/client';
import { FullStoredRx } from 'utils/GQLFragments';

export const GET_RX_BY_ID = gql`
  ${FullStoredRx}
  query rx($id: ID!) {
    rx(id: $id) {
      ...FullStoredRx
    }
  }
`;
export const GET_RXS_BY_IDS = gql`
  ${FullStoredRx}
  query rxsByIDs($ids: [ID]!) {
    rxsByIDs(ids: $ids) {
      ...FullStoredRx
    }
  }
`;

export const CANCEL_RX = gql`
  ${FullStoredRx}
  mutation cancelRX($id: ID!) {
    cancelRX(id: $id) {
      ...FullStoredRx
    }
  }
`;

export const CANCEL_MIX = gql`
  ${FullStoredRx}
  mutation cancelMix($mixId: ID!) {
    cancelMix(mixId: $mixId) {
      ...FullStoredRx
    }
  }
`;
