import { MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { TranslatableText, VoidResponse } from 'api/interfaces';
import { Nullable, extractFirstErrorCode } from 'components-ts/utils';
import { useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';
import {
  DELETE_IMPORTED_PHARMACY_LIBRARY_ITEMS,
  GET_PHARMACY_LIBRARY_TAGS,
  IMPORT_DRUGS_BY_TAGS,
} from 'scenes/Pharmacy/requests';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  deletePharmacyLibraryItemsError: {
    id: 'PharmacyItems.error_deleting_pharmacy_items',
    defaultMessage: 'Error deleting pharmacy items',
  },
  invalidIds: {
    id: 'PharmacyLibrary.invalid_ids',
    defaultMessage: 'Some drugs ids are invalid',
  },
});

interface UsePharmacyLibraryTagsData {
  getDrugsTags: Array<TranslatableText>;
}

type UsePharmacyLibraryTagsParams = QueryHookOptions<UsePharmacyLibraryTagsData, void>;

export const usePharmacyLibraryTags = (params?: UsePharmacyLibraryTagsParams) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlErrors) => {
    const errorCode = extractFirstErrorCode(graphqlErrors);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params?.onCompleted) params.onCompleted(data);
  };

  const { data, loading, refetch } = useQuery<UsePharmacyLibraryTagsData, void>(GET_PHARMACY_LIBRARY_TAGS, {
    ...params,
    onError,
    onCompleted,
  });

  return {
    tags: data?.getDrugsTags ?? [],
    isLoading: loading,
    error,
    refetch,
  };
};

type UseImportDrugsByTagsData = VoidResponse;

interface UseImportDrugsByTagsVariables {
  tag: Array<string>;
}

type UseImportDrugsByTagsParams = MutationHookOptions<UseImportDrugsByTagsData, UseImportDrugsByTagsVariables>;

export const useImportDrugsByTags = (params?: UseImportDrugsByTagsParams) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlErrors) => {
    const errorCode = extractFirstErrorCode(graphqlErrors);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params?.onCompleted) params.onCompleted(data);
  };

  const [importDrugs, { loading }] = useMutation<UseImportDrugsByTagsData, UseImportDrugsByTagsVariables>(
    IMPORT_DRUGS_BY_TAGS,
    { ...params, onError, onCompleted }
  );

  const onSubmit = (tags: Array<string>) => {
    importDrugs({
      variables: {
        tag: tags,
      },
    });
  };

  return {
    onSubmit,
    isLoading: loading,
    error,
  };
};

interface UseDeleteImportedPharmacyLibraryItemsData {
  deletePharmacyLibraryItems: VoidResponse;
}

interface UseDeleteImportedPharmacyLibraryItemsVariables {
  ids: Array<string>;
}

type UseDeleteImportedPharmacyLibraryItemsParams = MutationHookOptions<
  UseDeleteImportedPharmacyLibraryItemsData,
  UseDeleteImportedPharmacyLibraryItemsVariables
>;

export const useDeleteImportedPharmacyLibraryItems = (params?: UseDeleteImportedPharmacyLibraryItemsParams) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlErrors) => {
    const errorCode = extractFirstErrorCode(graphqlErrors);

    switch (errorCode) {
      case 'error_drug_ids_invalid':
        return setError(messages.invalidIds);
      case 'error_deleting_pharmacy_items':
        return setError(messages.deletePharmacyLibraryItemsError);
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params?.onCompleted) params.onCompleted(data);
  };

  const [deleteItems, { loading: isLoading }] = useMutation<
    UseDeleteImportedPharmacyLibraryItemsData,
    UseDeleteImportedPharmacyLibraryItemsVariables
  >(DELETE_IMPORTED_PHARMACY_LIBRARY_ITEMS, { ...params, onError, onCompleted });

  const onDelete = (ids: Array<string>) => {
    deleteItems({
      variables: {
        ids,
      },
    });
  };

  return {
    onDelete,
    isLoading,
    error,
  };
};
