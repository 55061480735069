import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import { Signature, StoredRx } from 'api/interfaces';
import { useSimplifiedDateFromSignatures } from './utils';
import { Nullable } from 'components-ts/utils';
import { OrderStatus } from './OrderStatus';
import { PrescriptionActions } from './OrderActions';
import { CustomRxDrug } from 'components-ts/RXs';
import { LastUpdated } from 'components-ts/LastUpdated';

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Note',
  },

  prescriptionsTitle: {
    id: 'OrderList.prescriptions_title',
    defaultMessage: 'Prescriptions',
  },
  ordersTitle: {
    id: 'OrderList.medicines_to_administer_title',
    defaultMessage: 'Medicines to Administer',
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No items found',
  },
  mixLabel: {
    id: 'ResourceList.mix_label',
    defaultMessage: 'Mix',
  },
  mixNotesLabel: {
    id: 'ResourceList.mix_notes_label',
    defaultMessage: 'Mix notes',
  },
});

interface PrescriptionListProps {
  orders: Array<StoredRx>;
  isLoading: boolean;
  isActionable?: boolean;
  className?: string;
  hideCanceled?: boolean;
}

export const PrescriptionList: React.FC<PrescriptionListProps> = (props) => {
  const { orders, isActionable, isLoading, className, hideCanceled } = props;

  const intl = useIntl();

  const groups = React.useMemo(
    () =>
      orders.reduce((groupedRXs, prescription) => {
        if (!prescription.rx.created) {
          return new Map(groupedRXs);
        }

        if (!prescription.rx?.mixId) {
          const singleGroup: RXGroup = {
            id: prescription.id,
            isMix: false,
            created: prescription.rx.created,
            updated: prescription.updated ?? prescription.fill?.created ?? prescription.rx.created,
            notes: prescription.rx.details?.notes,
            items: [prescription],
          };

          return new Map(groupedRXs).set(prescription.id, singleGroup);
        }

        if (groupedRXs.has(prescription.rx.mixId)) {
          const group = groupedRXs.get(prescription.rx.mixId) as RXGroup;

          group.items.push(prescription);

          return new Map(groupedRXs).set(prescription.rx.mixId, group);
        }

        const newGroup = {
          id: prescription.rx.mixId,
          isMix: true,
          created: prescription.rx.created,
          notes: prescription.rx.mixNotes,
          updated: prescription.updated ?? prescription.fill?.created ?? prescription.rx.created,
          items: [prescription],
        };

        return new Map(groupedRXs).set(prescription.rx.mixId, newGroup);
      }, new Map<string, RXGroup>()),
    [orders]
  );

  return (
    <div className={`w-100 ${className || ''}`}>
      <h4>
        <T id={messages.prescriptionsTitle.id}>{intl.formatMessage(messages.prescriptionsTitle)}</T>
      </h4>
      <div className="border-top py-1">
        {Array.from(groups.values()).map((group, index, allOrders) => {
          const prevItem = index > 0 ? allOrders[index - 1] : null;
          const key = `${group.id}-${index}`;

          return (
            <Group
              key={key}
              group={group}
              prevGroup={prevItem}
              isLoading={isLoading}
              isActionable={isActionable}
              hideCanceled={hideCanceled}
            />
          );
        })}
      </div>
    </div>
  );
};

type RXGroup = {
  id: string;
  isMix: boolean;
  created: Signature;
  updated: Signature;
  items: Array<StoredRx>;
  notes: Nullable<string>;
};

interface GroupProps {
  group: RXGroup;
  prevGroup: Nullable<RXGroup>;
  isLoading: boolean;
  isActionable?: boolean;
  className?: string;
  hideCanceled?: boolean;
}

const Group: React.FC<GroupProps> = (props) => {
  const { group, prevGroup, isLoading, isActionable, hideCanceled } = props;

  // if signature does not exists
  const { date, time } = useSimplifiedDateFromSignatures(group.created, prevGroup?.created ?? undefined);

  const canceled = group.items.every((item) => item.canceled);
  const filled = group.items.every((item) => item.filled);

  if (hideCanceled && canceled) {
    return null;
  }

  return (
    <div className="border-bottom border-light py-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex mb-2">
          <div style={{ minWidth: 96 }}>
            {date && <p className="m-0">{date}</p>}
            <p className="m-0">{time}</p>
          </div>
          <div className="px-2">
            {group.isMix ? (
              <Mix group={group} cancelled={canceled} isLoading={isLoading} />
            ) : (
              <Simple rx={group.items[0]} cancelled={canceled} isLoading={isLoading} notes={group.notes} />
            )}
          </div>
        </div>
        {isActionable && !filled && (
          <PrescriptionActions
            className="section-not-printable"
            orderId={group.id}
            isMix={group.isMix}
            isCancellable
            isCanceled={canceled}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        <OrderStatus className="section-not-printable my-2" filled={filled} canceled={canceled} />
        <LastUpdated signature={group.updated} />
      </div>
    </div>
  );
};

interface MixProps {
  group: RXGroup;
  cancelled?: boolean;
  isLoading?: boolean;
}

const Mix: React.FC<MixProps> = (props) => {
  const { group, cancelled } = props;
  const intl = useIntl();

  return (
    <>
      <b style={{ textDecoration: cancelled ? 'line-through' : 'none' }}>
        <T id={messages.mixLabel.id}>{intl.formatMessage(messages.mixLabel)}</T>
      </b>
      <ul>
        {group.items.map((rx) => (
          <li key={rx.id} style={{ textDecoration: cancelled ? 'line-through' : 'none' }} className="my-1">
            <CustomRxDrug cancelled={cancelled} rx={rx.rx} />
          </li>
        ))}
      </ul>
      {group.notes && (
        <p className="small m-0">
          <T id={messages.noteLabel.id} className="mr-1">
            {intl.formatMessage(messages.noteLabel)}:{' '}
          </T>
          <em>{group.notes}</em>
        </p>
      )}
    </>
  );
};

interface SimpleProps {
  rx: StoredRx;
  cancelled?: boolean;
  isLoading?: boolean;
  notes: Nullable<string>;
}
const Simple: React.FC<SimpleProps> = (props) => {
  const { rx, cancelled, notes } = props;
  const intl = useIntl();

  return (
    <>
      <b style={{ textDecoration: cancelled ? 'line-through' : 'none' }}>
        <CustomRxDrug cancelled={cancelled} rx={rx.rx} />
      </b>

      {notes && (
        <p className="small m-0">
          <T id={messages.noteLabel.id} className="mr-1">
            {intl.formatMessage(messages.noteLabel)}:{' '}
          </T>
          <em>{notes}</em>
        </p>
      )}
    </>
  );
};
