
import { useState } from 'react'
import { useQuickpicksWithDrug } from 'hooks'
import { graphQLErrorFormatter } from 'utils/errors'

export const useQuickpicks = (params = {}) => {
  const [error, setError] = useState()

  const errorFormatter = errorCode => {
    switch (errorCode) {
      default:
        return { id: '' }
    }
  }

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter)
    setError(translatedErrors)
  }

  const onCompleted = () => {
    setError()
  }

  const { quickpicks, count, loading, refetch } = useQuickpicksWithDrug({
    q: params.q,
    offset: params.offset,
    limit: params.limit
  }, {
    onError,
    onCompleted,
    fetchPolicy: 'cache-and-network'
  })

  return {
    quickpicks,
    count,
    loading,
    error,
    refetch
  }
}
