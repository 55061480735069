/**
 * Graphql fragments as ts interfaces.
 * These must be the graphql equivalents to avoid runtime issues
 */
export * from './admissions';
export * from './checklists';
export * from './common';
export * from './customFields';
export * from './exams';
export * from './history';
export * from './lists';
export * from './metrics';
export * from './notes';
export * from './patients';
export * from './pharmacy';
export * from './pricing';
export * from './procedures';
export * from './rxs';
export * from './scheduler';
export * from './staff';
export * from './studies';
export * from './systems';
export * from './translations';
export * from './triage';
export * from './visits';
export * from './integrations';
