import { gql } from '@apollo/client';
import { FullStoreStatus, FullHealthSystem, FullLocation } from 'api/fragments/systems';
import { FullSignedRequest } from 'utils/GQLFragments';

export const SET_INTEGRATION_FLAG_STATE = gql`
  mutation setIntegrationFlagState($systemId: String!, $name: String!, $isEnabled: Boolean!) {
    setIntegrationFlagState(systemId: $systemId, name: $name, isEnabled: $isEnabled) {
      ...FullSystem
    }
  }
  ${FullHealthSystem}
`;

export const SET_SYSTEM_BPJS_SETTINGS = gql`
  mutation updateSystemBPJSSettings($bpjsSettings: NewSystemBpjsSettings!) {
    updateSystemBPJSSettings(bpjsSettings: $bpjsSettings) {
      ...FullSystem
    }
  }
  ${FullHealthSystem}
`;

export const DELETE_SYSTEM_BPJS_SETTINGS = gql`
  mutation deleteSystemBPJSSettings {
    deleteSystemBPJSSettings {
      ...FullSystem
    }
  }
  ${FullHealthSystem}
`;

export const UPDATE_DAILY_REPORT_WEBHOOK_SETTINGS = gql`
  mutation updateDailyReportWebhookSettings($systemId: String!, $settings: NewDailyReportWebhookSettings!) {
    updateDailyReportWebhookSettings(systemId: $systemId, settings: $settings) {
      ...FullSystem
    }
  }
  ${FullHealthSystem}
`;

export const TEST_DAILY_REPORT_WEBHOOK = gql`
  mutation testDailyReportWebhook($systemId: String!) {
    testDailyReportWebhook(systemId: $systemId) {
      done
    }
  }
`;

export const SET_LOCATION_BPJS_SETTINGS = gql`
  mutation setLocationBPJSSettings($systemId: String!, $locationId: String!, $bpjsSettings: NewLocationBPJSSettings!) {
    setLocationBPJSSettings(systemId: $systemId, locationId: $locationId, bpjsSettings: $bpjsSettings) {
      ...FullLocation
    }
  }
  ${FullLocation}
`;

export const SET_LOCATION_BPJS_CREDENTIALS = gql`
  mutation setLocationBPJSCredentials($systemId: String!, $locationId: String!, $bpjsCredentials: NewBpjsCredentials!) {
    setLocationBPJSCredentials(systemId: $systemId, locationId: $locationId, bpjsCredentials: $bpjsCredentials) {
      ...FullLocation
    }
  }
  ${FullLocation}
`;

export const GET_SYSTEMS = gql`
  ${FullHealthSystem}
  query systems {
    systems {
      docs {
        ...FullSystem
      }
      count
    }
  }
`;
export const GET_LOGO_UPLOADING_URL = gql`
  ${FullSignedRequest}
  query logoUploadUrl($systemId: String!, $mymeType: String!) {
    logoUploadUrl(systemId: $systemId, mymeType: $mymeType) {
      ...FullSignedRequest
    }
  }
`;

export const GET_LOGO_DOWNLOADING_URL = gql`
  ${FullSignedRequest}
  query logoDownloadUrl($systemId: String!) {
    logoDownloadUrl(systemId: $systemId) {
      ...FullSignedRequest
    }
  }
`;

export const SAVE_LOGO = gql`
  ${FullHealthSystem}
  mutation saveLogo($systemId: String!, $fileId: String!) {
    saveLogo(systemId: $systemId, fileId: $fileId) {
      ...FullSystem
    }
  }
`;

export const REMOVE_LOGO = gql`
  ${FullHealthSystem}
  mutation removeLogo($systemId: String!) {
    removeLogo(systemId: $systemId) {
      ...FullSystem
    }
  }
`;

export const CREATE_SYSTEM = gql`
  ${FullHealthSystem}
  mutation createHealthSystem($basicInfo: NewSystem!) {
    createHealthSystem(basicInfo: $basicInfo) {
      ...FullSystem
    }
  }
`;
export const UPDATE_RXFORMAT = gql`
  ${FullHealthSystem}
  mutation updateRXFormat($systemId: String!, $newRXFormat: String!) {
    updateRXFormat(systemId: $systemId, newRXFormat: $newRXFormat) {
      ...FullSystem
    }
  }
`;

export const SET_DEFAULT_PHARMACY = gql`
  ${FullHealthSystem}
  mutation setDefaultPharmacy($systemId: String!, $defaultPharmacy: NewDefaultSystemOrLocation) {
    setDefaultPharmacy(systemId: $systemId, defaultPharmacy: $defaultPharmacy) {
      ...FullSystem
    }
  }
`;

export const UPDATE_SYSTEM = gql`
  ${FullHealthSystem}
  mutation updateHealthSystem($systemId: String!, $basicInfo: NewSystem!) {
    updateHealthSystem(systemId: $systemId, basicInfo: $basicInfo) {
      ...FullSystem
    }
  }
`;

export const DELETE_SYSTEM = gql`
  mutation deleteHealthSystem($systemId: String!) {
    deleteHealthSystem(systemId: $systemId) {
      done
    }
  }
`;

export const CREATE_LOCATION = gql`
  ${FullHealthSystem}
  mutation createLocation($systemId: String!, $location: NewLocation!) {
    createLocation(systemId: $systemId, location: $location) {
      ...FullSystem
    }
  }
`;

export const UPDATE_LOCATION = gql`
  ${FullLocation}
  mutation updateLocation($systemId: String!, $locationId: String!, $basicInfo: NewLocation!) {
    updateLocation(systemId: $systemId, locationId: $locationId, basicInfo: $basicInfo) {
      ...FullLocation
    }
  }
`;

export const DELETE_LOCATION = gql`
  ${FullHealthSystem}
  mutation deleteLocation($systemId: String!, $locationId: String!) {
    deleteLocation(systemId: $systemId, locationId: $locationId) {
      ...FullSystem
    }
  }
`;

export const UPDATE_PAYMENT_SETTINGS = gql`
  ${FullLocation}
  mutation updatePaymentSettings($systemId: String!, $locationId: String, $paymentSettings: NewPaymentSettings) {
    updatePaymentSettings(systemId: $systemId, locationId: $locationId, paymentSettings: $paymentSettings) {
      ...FullLocation
    }
  }
`;

export const UPDATE_SCHEDULER_SETTINGS = gql`
  ${FullLocation}
  mutation updateSchedulerSettings($systemId: String!, $locationId: String, $settings: NewSchedulerSettings) {
    updateSchedulerSettings(systemId: $systemId, locationId: $locationId, settings: $settings) {
      ...FullLocation
    }
  }
`;

export const SUBSCRIBE_TO_TRIAGE_CHECKLIST = gql`
  ${FullHealthSystem}
  mutation subscribeToTriageChecklist($systemId: String!, $locationId: String!, $checklistId: String!) {
    subscribeToTriageChecklist(systemId: $systemId, locationId: $locationId, checklistId: $checklistId) {
      ...FullSystem
    }
  }
`;

export const UNSUBSCRIBE_FROM_TRIAGE_CHECKLIST = gql`
  ${FullHealthSystem}
  mutation unsubscribeFromTriageChecklist($systemId: String!, $locationId: String!, $checklistId: String!) {
    unsubscribeFromTriageChecklist(systemId: $systemId, locationId: $locationId, checklistId: $checklistId) {
      ...FullSystem
    }
  }
`;

export const SUBSCRIBE_TO_TRIAGE_CHECKLIST_BATCH = gql`
  ${FullHealthSystem}
  mutation subscribeToTriageChecklistsBatch($systemId: String!, $locationId: String!, $ids: [String]!) {
    subscribeToTriageChecklistsBatch(systemId: $systemId, locationId: $locationId, ids: $ids) {
      ...FullSystem
    }
  }
`;

export const CREATE_ROOM = gql`
  ${FullHealthSystem}
  mutation createRoom($systemId: String!, $locationId: String!, $room: NewRoom!) {
    createRoom(systemId: $systemId, locationId: $locationId, room: $room) {
      ...FullSystem
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation updateRoom($systemId: String!, $locationId: String!, $roomId: String!, $room: NewRoom!) {
    updateRoom(systemId: $systemId, locationId: $locationId, roomId: $roomId, room: $room) {
      ...FullSystem
    }
  }
  ${FullHealthSystem}
`;

export const CREATE_ROOM_EVENT = gql`
  ${FullHealthSystem}
  mutation createRoomEvent($roomId: String!, $event: NewRoomEvent!) {
    createRoomEvent(roomId: $roomId, event: $event) {
      ...FullSystem
    }
  }
`;
export const UPDATE_ROOM_EVENT = gql`
  ${FullHealthSystem}
  mutation updateRoomEvent($roomId: String!, $eventId: String!, $event: NewRoomEvent!) {
    updateRoomEvent(roomId: $roomId, eventId: $eventId, event: $event) {
      ...FullSystem
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation deleteRoom($systemId: String!, $locationId: String!, $roomId: String!) {
    deleteRoom(systemId: $systemId, locationId: $locationId, roomId: $roomId) {
      ...FullSystem
    }
  }
  ${FullHealthSystem}
`;

export const DELETE_ROOM_EVENT = gql`
  ${FullHealthSystem}
  mutation deleteRoomEvent($roomId: String!, $eventId: String!) {
    deleteRoomEvent(roomId: $roomId, eventId: $eventId) {
      ...FullSystem
    }
  }
`;

/**
 * Custom fields
 */
export const ADD_PRIMITIVE_CUSTOM_FIELD = gql`
  ${FullHealthSystem}
  mutation addPrimitiveCustomField($systemId: String!, $newPrimitiveCustomField: NewPrimitiveCustomField!) {
    addPrimitiveCustomField(systemId: $systemId, newPrimitiveCustomField: $newPrimitiveCustomField) {
      ...FullSystem
    }
  }
`;

export const ADD_TEXT_LIST_CUSTOM_FIELD = gql`
  ${FullHealthSystem}
  mutation addTextListCustomField($systemId: String!, $newTextListCustomField: NewTextListCustomField!) {
    addTextListCustomField(systemId: $systemId, newTextListCustomField: $newTextListCustomField) {
      ...FullSystem
    }
  }
`;

export const DELETE_CUSTOM_FIELD = gql`
  ${FullHealthSystem}
  mutation deleteCustomField($systemId: String!, $id: ID!) {
    deleteCustomField(systemId: $systemId, id: $id) {
      ...FullSystem
    }
  }
`;
/**
 * Registration groups
 */
export const ADD_REGISTRATION_GROUP = gql`
  ${FullHealthSystem}
  mutation addRegistrationGroup($systemId: String!, $newRegistrationGroup: NewRegistrationGroup!) {
    addRegistrationGroup(systemId: $systemId, newRegistrationGroup: $newRegistrationGroup) {
      ...FullSystem
    }
  }
`;
export const UPDATE_REGISTRATION_GROUP = gql`
  ${FullHealthSystem}
  mutation updateRegistrationGroup($systemId: String!, $id: ID!, $newRegistrationGroup: NewRegistrationGroup!) {
    updateRegistrationGroup(systemId: $systemId, id: $id, newRegistrationGroup: $newRegistrationGroup) {
      ...FullSystem
    }
  }
`;
export const DELETE_REGISTRATION_GROUP = gql`
  ${FullHealthSystem}
  mutation deleteRegistrationGroup($systemId: String!, $id: ID!) {
    deleteRegistrationGroup(systemId: $systemId, id: $id) {
      ...FullSystem
    }
  }
`;

/**
 * Medical content subscriptions
 */
export const SUBSCRIBE_TO_VISIT_TYPE_BATCH = gql`
  ${FullLocation}
  mutation subscribeLocationToVisitTypesBatch($systemId: String!, $locationId: String!, $visitTypes: [String]!) {
    subscribeLocationToVisitTypesBatch(systemId: $systemId, locationId: $locationId, visitTypes: $visitTypes) {
      ...FullLocation
    }
  }
`;

export const SUBSCRIBE_SYSTEM_TO_PHARMACY_EDUCATION = gql`
  ${FullHealthSystem}
  mutation subscribeSystemToPharmacyEducationBatch($systemId: String!, $pharmacyEducation: [String]!) {
    subscribeSystemToPharmacyEducationBatch(systemId: $systemId, pharmacyEducation: $pharmacyEducation) {
      ...FullSystem
    }
  }
`;

export const SUBSCRIBE_TO_STUDY = gql`
  ${FullHealthSystem}
  mutation subscribeSystemToStudyTemplate($systemId: String!, $locationId: String!, $item: String!) {
    subscribeSystemToStudyTemplate(systemId: $systemId, locationId: $locationId, item: $item) {
      ...FullSystem
    }
  }
`;

export const UNSUBSCRIBE_FROM_STUDY = gql`
  ${FullHealthSystem}
  mutation unsubscribeSystemFromStudyTemplate($systemId: String!, $locationId: String!, $item: String!) {
    unsubscribeSystemFromStudyTemplate(systemId: $systemId, locationId: $locationId, item: $item) {
      ...FullSystem
    }
  }
`;

export const SUBSCRIBE_TO_STUDIES_BATCH = gql`
  ${FullHealthSystem}
  mutation subscribeSystemToStudyTemplatesBatch($systemId: String!, $locationId: String!, $ids: [String]!) {
    subscribeSystemToStudyTemplatesBatch(systemId: $systemId, locationId: $locationId, ids: $ids) {
      ...FullSystem
    }
  }
`;

export const SUBSCRIBE_TO_PROCEDURE = gql`
  ${FullHealthSystem}
  mutation subscribeSystemToProcedure($systemId: String!, $locationId: String!, $item: String!) {
    subscribeSystemToProcedure(systemId: $systemId, locationId: $locationId, item: $item) {
      ...FullSystem
    }
  }
`;

export const SUBSCRIBE_TO_PROCEDURES_BATCH = gql`
  ${FullHealthSystem}
  mutation subscribeSystemToProceduresBatch($systemId: String!, $locationId: String!, $ids: [String]!) {
    subscribeSystemToProceduresBatch(systemId: $systemId, locationId: $locationId, ids: $ids) {
      ...FullSystem
    }
  }
`;

export const UNSUBSCRIBE_FROM_PROCEDURE = gql`
  ${FullHealthSystem}
  mutation unsubscribeSystemFromProcedure($systemId: String!, $locationId: String!, $item: String!) {
    unsubscribeSystemFromProcedure(systemId: $systemId, locationId: $locationId, item: $item) {
      ...FullSystem
    }
  }
`;

export const SUBSCRIBE_TO_CHECKLISTS_TAG = gql`
  ${FullHealthSystem}
  mutation subscribeSystemToChecklistsTag($systemId: String!, $locationId: String!, $tag: String!) {
    subscribeSystemToChecklistsTag(systemId: $systemId, locationId: $locationId, tag: $tag) {
      ...FullSystem
    }
  }
`;

export const UNSUBSCRIBE_FROM_CHECKLISTS_TAG = gql`
  ${FullHealthSystem}
  mutation unsubscribeSystemFromChecklistsTags($systemId: String!, $locationId: String!, $tag: String!) {
    unsubscribeSystemFromChecklistsTags(systemId: $systemId, locationId: $locationId, tag: $tag) {
      ...FullSystem
    }
  }
`;

export const SUBSCRIBE_TO_CHECKLISTS_TAG_BATCH = gql`
  ${FullHealthSystem}
  mutation subscribeSystemToChecklistsBatch($systemId: String!, $locationId: String!, $ids: [String]!) {
    subscribeSystemToChecklistsBatch(systemId: $systemId, locationId: $locationId, ids: $ids) {
      ...FullSystem
    }
  }
`;

export const GET_STORE_STATUS = gql`
  ${FullStoreStatus}
  query storeStatus($systemId: ID!) {
    storeStatus(systemId: $systemId) {
      ...FullStoreStatus
    }
  }
`;

export const SET_STORE_UP = gql`
  ${FullStoreStatus}
  mutation setStoreUp($systemId: ID!) {
    setStoreUp(systemId: $systemId) {
      ...FullStoreStatus
    }
  }
`;

export const MAKE_STORE_ACCESSIBLE = gql`
  ${FullStoreStatus}
  mutation makeStoreAccessible($systemId: ID!) {
    makeStoreAccessible(systemId: $systemId) {
      ...FullStoreStatus
    }
  }
`;

export const SET_FIRST_STEPS_DONE = gql`
  ${FullHealthSystem}
  mutation setFirstStepsDone($systemId: String!) {
    setFirstStepsDone(systemId: $systemId) {
      ...FullSystem
    }
  }
`;
