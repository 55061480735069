import { gql } from '@apollo/client';
import { FullPatientWithActiveVisit, FullScheduledVisit } from '../../utils/GQLFragments';

// get the list
export const GET_SCHEDULED_VISITS = gql`
  ${FullScheduledVisit}
  query scheduledVisits($offset: Int, $limit: Int, $from: String, $to: String, $location: String, $room: String) {
    scheduledVisits(offset: $offset, limit: $limit, from: $from, to: $to, location: $location, room: $room) {
      docs {
        ...FullScheduledVisit
      }
      count
    }
  }
`;

// get one by id
export const GET_SCHEDULED_VISIT_BY_ID = gql`
  ${FullScheduledVisit}
  query scheduledVisit($id: String!) {
    scheduledVisit(id: $id) {
      ...FullScheduledVisit
    }
  }
`;
export const CREATE_SCHEDULED_VISIT = gql`
  ${FullScheduledVisit}
  mutation createScheduledVisit($newScheduledVisit: NewRegistrationData!) {
    createScheduledVisit(newScheduledVisit: $newScheduledVisit) {
      visit {
        ...FullScheduledVisit
      }
      warning
    }
  }
`;

export const DELETE_SCHEDULED_VISIT = gql`
  mutation deleteScheduledVisit($id: String!) {
    deleteScheduledVisit(id: $id) {
      done
    }
  }
`;

export const UPDATE_SCHEDULED_VISIT = gql`
  ${FullScheduledVisit}
  mutation updateScheduledVisit($id: ID!, $registrationData: NewRegistrationData!) {
    updateScheduledVisit(id: $id, registrationData: $registrationData) {
      visit {
        ...FullScheduledVisit
      }
      warning
    }
  }
`;

export const START_SCHEDULED_VISIT = gql`
  ${FullPatientWithActiveVisit}
  mutation startScheduledVisit($id: String!) {
    startScheduledVisit(id: $id) {
      ...FullPatientWithActiveVisit
    }
  }
`;

export const AVAILABLE_SLOTS = gql`
  query availableSlots($room: String!, $from: String!, $to: String!, $ignoreVisit: String) {
    availableSlots(room: $room, from: $from, to: $to, ignoreVisit: $ignoreVisit) {
      start
      end
    }
  }
`;
