import React from 'react';
import { Form, Field } from 'react-final-form';
import { useIntl, MessageDescriptor, defineMessages } from 'react-intl';
import { NewRoomEventFormValues, useDeleteRoomEvent } from './useRoomEventActions';
import { useSchedulerContext } from './useSchedulerContext';
import { ValidateFn } from 'components-ts/Forms/utils';
import { Nullable } from 'components-ts/utils';
import ConfirmationModal from 'components/ConfirmationModal';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Button, Col, FormGroup, Label, Input } from 'reactstrap';
import { EventRecurrenceSelector } from './EventRecurrenceSelector';
import { TimeSelector } from './TimeSelector';
import { AvailableSlot, formatNormalizedSlotsForEndTimeSelector, normalizeSlots } from './utils';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { LoadingInline } from 'components-ts/Loading';

const messages = defineMessages({
  roomEvent: {
    id: 'RoomEventEditor.room_event',
    defaultMessage: 'Room event',
  },
  name: {
    id: 'RoomEventEditor.label_name',
    defaultMessage: 'Event name',
  },
  description: {
    id: 'RoomEventEditor.label_description',
    defaultMessage: 'Event description',
  },
  fromLabel: {
    id: 'RoomEventEditor.from_label',
    defaultMessage: 'From',
  },
  toLabel: {
    id: 'RoomEventEditor.to_label',
    defaultMessage: 'To',
  },
  recurrenceLabel: {
    id: 'RoomEventEditor.recurrence_label',
    defaultMessage: 'Recurrence',
  },
  allDayLabel: {
    id: 'RoomEventEditor.allDay_label',
    defaultMessage: 'All day',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  deleteButton: {
    id: 'UI.button_delete',
    defaultMessage: 'Delete',
  },
  confirmationMessage: {
    id: 'message_deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?',
  },
});

interface RoomEventFormProps {
  slotDurationInMinutes: number;
  availableSlots: Array<AvailableSlot>;
  initialValues?: NewRoomEventFormValues;
  onSubmit: (values: NewRoomEventFormValues) => void;
  isLoading?: boolean;
  validate: ValidateFn<NewRoomEventFormValues>;
  error: Nullable<MessageDescriptor>;
  onErrorClose: () => void;
  isDeletable?: boolean;
}

export const RoomEventForm: React.FC<RoomEventFormProps> = (props) => {
  const { slotDurationInMinutes, availableSlots, initialValues, validate, error, onSubmit, isLoading, isDeletable } =
    props;

  const intl = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine, values, initialValues }) => {
        const selectedDate = values.start ?? initialValues.start;
        const allowedEndTimes = formatNormalizedSlotsForEndTimeSelector(
          availableSlots,
          slotDurationInMinutes,
          selectedDate ? new Date(selectedDate) : undefined
        );

        return (
          <form onSubmit={handleSubmit}>
            <Field name="name">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.name.id}>{intl.formatMessage(messages.name)}</T>
                    </Label>
                    <Input
                      {...input}
                      autoComplete="off"
                      className="form-control"
                      placeholder={intl.formatMessage(messages.name)}
                      maxLength={100}
                    />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="description">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
                    </Label>
                    <Input
                      {...input}
                      autoComplete="off"
                      className="form-control"
                      placeholder={intl.formatMessage(messages.description)}
                      maxLength={100}
                    />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            <Field name="recurrence">
              {({ input, meta }) => (
                <Col className="px-0">
                  <FormGroup className="mb-3">
                    <Label>
                      <T id={messages.recurrenceLabel.id}>{intl.formatMessage(messages.recurrenceLabel)}</T>
                    </Label>
                    <EventRecurrenceSelector {...input} />
                  </FormGroup>
                  {meta.error && meta.touched && (
                    <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                      {meta.error}
                    </div>
                  )}
                </Col>
              )}
            </Field>
            {!values.allDay && (
              <>
                <Field name="start">
                  {({ input, meta }) => (
                    <Col className="px-0">
                      <FormGroup className="mb-3">
                        <Label>
                          <T id={messages.fromLabel.id}>{intl.formatMessage(messages.fromLabel)}</T>
                        </Label>
                        <TimeSelector
                          {...input}
                          availableSlots={normalizeSlots(availableSlots, slotDurationInMinutes)}
                        />
                      </FormGroup>
                      {meta.error && meta.touched && (
                        <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                          {meta.error}
                        </div>
                      )}
                    </Col>
                  )}
                </Field>
                <Field name="end">
                  {({ input, meta }) => (
                    <Col className="px-0">
                      <FormGroup className="mb-3">
                        <Label>
                          <T id={messages.toLabel.id}>{intl.formatMessage(messages.toLabel)}</T>
                        </Label>
                        <TimeSelector {...input} availableSlots={allowedEndTimes} />
                      </FormGroup>
                      {meta.error && meta.touched && (
                        <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
                          {meta.error}
                        </div>
                      )}
                    </Col>
                  )}
                </Field>
              </>
            )}
            <Col className="d-flex align-items-center justify-content-start px-0">
              <Label className="switch switch-pill switch-primary m-0 mr-2">
                <Field className="switch-input" name="allDay" component="input" type="checkbox" />
                <span className="switch-slider m-0" />
              </Label>
              <p className="m-0">
                <T id={messages.allDayLabel.id}>{intl.formatMessage(messages.allDayLabel)}</T>
                {values.allDay && <> ({intl.formatDate(values.start)})</>}
              </p>
            </Col>
            {error && <ErrorViewer error={error} />}
            <div className="d-flex mt-3">
              <Button
                className={isLoading ? 'mr-1' : ''}
                color={'primary'}
                type="submit"
                disabled={pristine && !isDeletable}
              >
                <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
                {isLoading && <LoadingInline className="ml-1" />}
              </Button>
              {isDeletable && <DeleteRoomEventButton />}
            </div>
          </form>
        );
      }}
    />
  );
};

const DeleteRoomEventButton: React.VFC = () => {
  const intl = useIntl();

  const { selectedEvent, roomId, onReset } = useSchedulerContext();

  const { onDelete, error, isLoading } = useDeleteRoomEvent({
    eventId: selectedEvent ?? '',
    roomId: roomId ?? '',
    onCompleted: onReset,
  });

  if (!roomId || !selectedEvent) {
    return null;
  }

  return (
    <ConfirmationModal
      error={error}
      loading={isLoading}
      action={intl.formatMessage(messages.deleteButton)}
      message={intl.formatMessage(messages.confirmationMessage)}
      className="ml-2 btn-danger"
      onConfirm={onDelete}
    />
  );
};
