import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Alert, Button, InputGroup, InputGroupAddon, Label, Row, Col, FormGroup, Input } from 'reactstrap';
import { RouteSelector } from 'components/Pharmacy';
import { FrequencySelector } from './';
import { adaptivePrecision, calculateDispense, getDrugInfo } from 'wd-common/src/logic/pharmacy/utils';
import { Signature } from 'components';
import { FaRedo as RecalculateIcon } from 'react-icons/fa';
import { RX_VALIDATION_ERROR, validateRXForm } from './rxFormValidation';
import { FrequencyShow } from 'api/interfaces';
import { DoseUnitSelector } from '../../components/Pharmacy';
import { DEFAULT_RX_DETAILS } from './utils';

const debug = require('debug')('wd:EditableDrugRx');

const messages = defineMessages({
  doseNumber: {
    id: 'EditableDrugRx.label_dose_number',
    defaultMessage: 'Dose',
  },
  patientWeight: {
    id: 'EditableDrugRx.patient_weight',
    defaultMessage: 'Patient Weight',
  },
  whenMeasured: {
    id: 'EditableDrugRx.when_measured',
    defaultMessage: 'Measured',
  },
  noPatientWeight: {
    id: 'EditableDrugRx.no_patient_weight',
    defaultMessage: 'Patient has no recorded weight',
  },
  noDoseUnits: {
    id: 'EditableDrugRx.no_dose_units',
    defaultMessage: 'Guideline has no dose units',
  },
  perDose: {
    id: 'EditableDrugRx.label_per_dose',
    defaultMessage: 'per dose',
  },
  days: {
    id: 'EditableDrugRx.label_days',
    defaultMessage: 'Days',
  },
  dispense: {
    id: 'EditableDrugRx.label_dispense',
    defaultMessage: 'Dispense',
  },
  refillsAllowed: {
    id: 'EditableDrugRx.label_refills_allowed',
    defaultMessage: 'Refills Allowed',
  },
  refills: {
    id: 'EditableDrugRx.label_refills',
    defaultMessage: 'Refills',
  },
  dangerousDose: {
    id: 'EditableDrugRx.dangerous_dose',
    defaultMessage: 'Danger: {dose_number} {dose_units} is higher than the max dose of {max_dose} {dose_units}',
  },
  notes: {
    id: 'EditableDrugRx.label_notes',
    defaultMessage: 'Notes',
  },
  recalculate: {
    id: 'UI.recalculate_button',
    defaultMessage: 'Re-calculate',
  },
  mustBeGreaterThanZero: {
    id: 'EditableDrugRx.must_be_greater_than_zero_error',
    defaultMessage: 'Must be greater than 0',
  },
  mustNotBeEmpty: {
    id: 'EditableDrugRx.must_not_be_empty_error',
    defaultMessage: 'Must not be empty',
  },
});

const useRXForm = (initialDetails, drug, onChange) => {
  const [doseNumber, setDoseNumber] = useState(initialDetails.doseNumber);
  const [doseUnits, setDoseUnits] = useState(initialDetails.doseUnits);
  const [unitsPerDose, setUnitsPerDose] = useState(initialDetails.unitsPerDose);
  const [unitsPerDoseRaw, setUnitsPerDoseRaw] = useState(initialDetails.unitsPerDoseRaw);
  const [route, setRoute] = useState(initialDetails.route);

  const [frequencyShow, setFrequencyShow] = useState(initialDetails.frequencyShow);
  const [dosesPerDay, setDosesPerDay] = useState(initialDetails.dosesPerDay);
  const [asNeeded, setAsNeeded] = useState(!!initialDetails.asNeeded);

  const [days, setDays] = useState(initialDetails.days ?? 0);
  const [dispense, setDispense] = useState(initialDetails.dispense);
  const [refillsAllowed, setRefillsAllowed] = useState(initialDetails.refillsAllowed);
  const [notes, setNotes] = useState(initialDetails.notes);

  /**
   * We'll re-calculate the dispense value
   * but we won't overwrite it after each change
   * because the doctor should be able to maunally set the dispense value
   */
  const [autocalculated, setAutocalculated] = useState(initialDetails.dispense);
  const { drugUnit, drugStockUnit } = getDrugInfo(drug);

  const reCalculate = (unitsPerDose, dosesPerDay, days, drug) => {
    const newDispense =
      drugStockUnit.toLowerCase() === drugUnit.toLowerCase()
        ? calculateDispense(unitsPerDose, dosesPerDay, days, drug)
        : 1;

    setAutocalculated(newDispense);
  };

  const onDoseNumberChange = (event) => {
    const value = parseFloat(event.target.value);
    setDoseNumber(value);

    // update units per dose
    let newUnitsPerDose = 1;

    // it makes sense that when the drug unit is the same as the dose unit, the value is the same
    // eg: if the dose is 250ml there are 250ml in the dose
    if (!drugUnit || drugUnit.toLowerCase() === doseUnits.toLowerCase()) {
      newUnitsPerDose = value;
    } else {
      newUnitsPerDose = initialDetails.drugDoseNumber
        ? adaptivePrecision(value / initialDetails.drugDoseNumber)
        : unitsPerDose;
    }
    setUnitsPerDose(newUnitsPerDose);

    // update units per dose raw
    let newUnitsPerDoseRaw = 1;

    // it makes sense that when the drug unit is the same as the dose unit, the value is the same
    // eg: if the dose is 250ml there are 250ml in the dose
    if (!drugUnit || drugUnit.toLowerCase() === doseUnits.toLowerCase()) {
      newUnitsPerDoseRaw = value;
    } else {
      newUnitsPerDoseRaw = initialDetails.drugDoseNumber
        ? adaptivePrecision(value / initialDetails.drugDoseNumber)
        : unitsPerDose;
    }
    setUnitsPerDoseRaw(newUnitsPerDoseRaw);

    // update dispense value
    reCalculate(newUnitsPerDose, dosesPerDay, days, drug);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), doseNumber: value, unitsPerDose: newUnitsPerDose });
  };

  const onDoseUnitsChange = (unit) => {
    setDoseUnits(unit);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), doseUnits: unit });
  };

  const onUnitsPerDoseChange = (event) => {
    const value = parseFloat(event.target.value);
    setUnitsPerDose(value);
    setUnitsPerDoseRaw(value);

    // this means the initial calculation found a valid units per dose
    let newDoseNumber = 1;

    // it makes sense that when the drug unit is the same as the dose unit, the value is the same
    // eg: if each dose has 250ml, the dose is of 250ml
    if (!drugUnit || drugUnit.toLowerCase() === doseUnits.toLowerCase()) {
      newDoseNumber = value;
    } else {
      newDoseNumber =
        initialDetails.doseNumber && initialDetails.unitsPerDose // && !doseNumber
          ? adaptivePrecision(value * initialDetails.drugDoseNumber)
          : doseNumber;
    }

    setDoseNumber(newDoseNumber);

    // update dispense value
    reCalculate(value, dosesPerDay, days, drug);

    // update general state
    if (typeof onChange === 'function') {
      onChange({ ...getDetails(), unitsPerDose: value, doseNumber: newDoseNumber });
    }
  };

  const onRouteChange = (route) => {
    setRoute(route);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), route });
  };

  const onFrequencyShowChange = (newFrequencyShow) => {
    setFrequencyShow(newFrequencyShow);

    // update days value
    const newDays =
      newFrequencyShow === FrequencyShow.DOSES
        ? 0
        : frequencyShow === FrequencyShow.DOSES
        ? DEFAULT_RX_DETAILS.days
        : days;

    setDays(newDays);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), days: newDays, frequencyShow: newFrequencyShow });
  };

  const onDosesPerDayChange = (value) => {
    const newDosesPerDay = parseFloat(value);
    setDosesPerDay(newDosesPerDay);

    // update dispense value
    reCalculate(unitsPerDose, newDosesPerDay, days, drug);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), dosesPerDay: newDosesPerDay });
  };

  const onAsNeededChange = (asNeeded) => {
    setAsNeeded(!!asNeeded);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), asNeeded: !!asNeeded });
  };

  const onDaysChange = (event) => {
    const value = parseInt(event.target.value);
    setDays(value);

    // update dispense value
    reCalculate(unitsPerDose, dosesPerDay, value, drug);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), days: value });
  };

  const onDispenseChange = (event) => {
    const value = parseFloat(event.target.value);
    setDispense(value);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), dispense: value });
  };

  const onRefillsAllowedChange = (event) => {
    const value = parseInt(event.target.value);
    setRefillsAllowed(value);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), refillsAllowed: value });
  };

  const onNotesChange = (event) => {
    const { value } = event.target;
    setNotes(value);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), notes: value });
  };

  const recalculate = () => {
    setDispense(autocalculated);

    // update general state
    if (typeof onChange === 'function') onChange({ ...getDetails(), dispense: autocalculated });
  };

  const getDetails = () => {
    return {
      drugDoseNumber: initialDetails.drugDoseNumber,
      drugDoseUnits: initialDetails.drugDoseUnits,
      doseNumber,
      doseUnits,
      unitsPerDose,
      unitsPerDoseRaw,
      route,
      frequencyShow,
      dosesPerDay,
      asNeeded,
      days,
      dispense,
      refillsAllowed,
      notes,
    };
  };

  return {
    doseNumber,
    onDoseNumberChange,
    doseUnits,
    onDoseUnitsChange,
    unitsPerDose,
    onUnitsPerDoseChange,
    route,
    onRouteChange,
    frequencyShow,
    onFrequencyShowChange,
    dosesPerDay,
    onDosesPerDayChange,
    asNeeded,
    onAsNeededChange,
    days,
    onDaysChange,
    dispense,
    onDispenseChange,
    refillsAllowed,
    onRefillsAllowedChange,
    notes,
    onNotesChange,
    autocalculated,
    recalculate,
    getDetails,
    drugStockUnit,
  };
};

export const EditableDrugRx = (props) => {
  const { guideline, initialDetails, onDetailsChange, drug, patientWeight, isDisabled = false } = props;

  const intl = useIntl();

  const {
    doseNumber,
    onDoseNumberChange,
    doseUnits,
    onDoseUnitsChange,
    unitsPerDose,
    onUnitsPerDoseChange,
    route,
    onRouteChange,
    frequencyShow,
    onFrequencyShowChange,
    dosesPerDay,
    onDosesPerDayChange,
    days,
    onDaysChange,
    asNeeded,
    onAsNeededChange,
    dispense,
    onDispenseChange,
    refillsAllowed,
    onRefillsAllowedChange,
    notes,
    onNotesChange,
    autocalculated,
    recalculate,
    getDetails,
    drugStockUnit,
  } = useRXForm(initialDetails, drug, onDetailsChange);

  const maxDose = guideline
    ? String(guideline.max_dose_units).toLowerCase().includes('/kg') && patientWeight
      ? guideline.dose_range_max * patientWeight.kg
      : guideline.max_dose
    : null;

  debug({ drug, dispense, unitsPerDose, initial: initialDetails.unitsPerDose });

  const validationError = validateRXForm(getDetails());

  return (
    <div>
      <Row>
        <Col md={3} sm={12}>
          <FormGroup>
            <Label>
              <T id={messages.doseNumber.id}>{intl.formatMessage(messages.doseNumber)}</T>
            </Label>
            <InputGroup>
              <Input
                type="number"
                className={`${validationError === RX_VALIDATION_ERROR.INVALID_DOSE ? 'is-invalid' : ''}`}
                min={0}
                value={isNaN(doseNumber) ? undefined : doseNumber}
                onChange={onDoseNumberChange}
                disabled={isDisabled}
              />
            </InputGroup>
            {validationError === RX_VALIDATION_ERROR.INVALID_DOSE && (
              <div className="invalid-feedback d-flex">
                <T id={messages.mustBeGreaterThanZero.id}>{intl.formatMessage(messages.mustBeGreaterThanZero)}</T>
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md={3} sm={12}>
          <FormGroup>
            <DoseUnitSelector
              defaultValue={doseUnits}
              onChange={onDoseUnitsChange}
              className={`${validationError === RX_VALIDATION_ERROR.INVALID_DOSE_UNIT ? 'is-invalid' : ''}`}
              isDisabled={isDisabled}
              onlyTopUnit
            />
            {validationError === RX_VALIDATION_ERROR.INVALID_DOSE_UNIT && (
              <div className="invalid-feedback d-flex">
                <T id={messages.mustNotBeEmpty.id}>{intl.formatMessage(messages.mustNotBeEmpty)}</T>
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md={6} sm={12}>
          <PatientWeight patientWeight={patientWeight} />
        </Col>
      </Row>
      {!!doseNumber && guideline && maxDose && doseNumber > maxDose && (
        <Row>
          <Col>
            <Alert color="danger">
              <T id={messages.dangerousDose.id}>
                <FormattedMessage
                  id="EditableDrugRx.this_bill_was_generated_using_this_payment_method"
                  defaultMessage="Danger: {doseNumber} {doseUnits} is higher than the max dose of {maxDose} {doseUnits}"
                  values={{
                    doseNumber,
                    doseUnits,
                    maxDose,
                  }}
                />
              </T>
            </Alert>
          </Col>
        </Row>
      )}

      <Row>
        <Col lg={3} sm={12}>
          <FormGroup>
            <Label className="d-flex">
              {drug.unit}
              <T id={messages.perDose.id} className="ml-1">
                {intl.formatMessage(messages.perDose)}
              </T>
            </Label>
            <InputGroup>
              <Input
                type="number"
                min={0}
                value={unitsPerDose}
                onChange={onUnitsPerDoseChange}
                className={`${validationError === RX_VALIDATION_ERROR.INVALID_UNITS_PER_DOSE ? 'is-invalid' : ''}`}
                disabled={isDisabled}
              />

              <InputGroupAddon addonType="append">
                <Button
                  style={{
                    zIndex: 0,
                  }}
                  disabled
                >
                  {drug.unit[0] + drug.unit.slice(1)}
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {validationError === RX_VALIDATION_ERROR.INVALID_UNITS_PER_DOSE && (
              <div className="invalid-feedback d-flex">
                <T id={messages.mustBeGreaterThanZero.id}>{intl.formatMessage(messages.mustBeGreaterThanZero)}</T>
              </div>
            )}
          </FormGroup>
        </Col>
        <Col lg={4} sm={12} style={{ zIndex: 0 }}>
          <RouteSelector
            value={route}
            onChange={onRouteChange}
            className={`${validationError === RX_VALIDATION_ERROR.INVALID_ROUTE ? 'is-invalid' : ''}`}
            isDisabled={isDisabled}
          />
          {validationError === RX_VALIDATION_ERROR.INVALID_ROUTE && (
            <div className="invalid-feedback d-flex" style={{ marginTop: '-1.2em' }}>
              <T id={messages.mustNotBeEmpty.id}>{intl.formatMessage(messages.mustNotBeEmpty)}</T>
            </div>
          )}
        </Col>
        <Col lg={5} sm={12} className="form-group">
          <FrequencySelector
            frequencyShow={frequencyShow}
            dosesPerDay={dosesPerDay}
            asNeeded={asNeeded}
            onFrequencyShowChange={onFrequencyShowChange}
            onDosesPerDayChange={onDosesPerDayChange}
            onAsNeededChange={onAsNeededChange}
            className={`${validationError === RX_VALIDATION_ERROR.INVALID_DOSES_PER_DAY ? 'is-invalid' : ''}`}
            isDisabled={isDisabled}
          />
          {validationError === RX_VALIDATION_ERROR.INVALID_DOSES_PER_DAY && (
            <div className="invalid-feedback d-flex " style={{ marginTop: '-1.2em' }}>
              <T id={messages.mustBeGreaterThanZero.id}>{intl.formatMessage(messages.mustBeGreaterThanZero)}</T>
            </div>
          )}
          {validationError === RX_VALIDATION_ERROR.INVALID_FREUQUECY_SHOW && (
            <div className="invalid-feedback d-flex " style={{ marginTop: '-1.2em' }}>
              <T id={messages.mustNotBeEmpty.id}>{intl.formatMessage(messages.mustNotBeEmpty)}</T>
            </div>
          )}
        </Col>
      </Row>
      <Row className="align-items-end">
        <Col>
          <FormGroup>
            <Label for="days">
              <T id={messages.days.id}>{intl.formatMessage(messages.days)}</T>
            </Label>
            <Input
              type="number"
              min={0}
              value={days}
              onChange={onDaysChange}
              className={`${validationError === RX_VALIDATION_ERROR.INVALID_DAYS ? 'is-invalid' : ''}`}
              disabled={isDisabled || frequencyShow === FrequencyShow.DOSES}
            />
            {validationError === RX_VALIDATION_ERROR.INVALID_DAYS && (
              <div className="invalid-feedback ">
                <T id={messages.mustBeGreaterThanZero.id}>{intl.formatMessage(messages.mustBeGreaterThanZero)}</T>
              </div>
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label
              for="dispense"
              className="d-flex aling-items-center justify-content-between flex-lg-row flex-column-reverse"
            >
              <T id={messages.dispense.id}>{intl.formatMessage(messages.dispense)}</T>
              {autocalculated !== dispense && (
                <Button color="link" className={'d-flex align-items-center m-0 p-0'} onClick={() => recalculate()}>
                  <RecalculateIcon className="mr-1" />
                  <T id={messages.recalculate.id}>{intl.formatMessage(messages.recalculate)}</T>
                </Button>
              )}
            </Label>
            <InputGroup>
              <Input
                type="number"
                value={dispense}
                step={0.1}
                onChange={onDispenseChange}
                min={0}
                className={`${validationError === RX_VALIDATION_ERROR.INVALID_DISPENSE ? 'is-invalid' : ''}`}
                disabled={isDisabled}
              />
              {drug && drug.unit ? (
                <InputGroupAddon addonType="append">
                  <Button
                    style={{
                      zIndex: 0,
                    }}
                    disabled
                  >
                    {drugStockUnit}
                  </Button>
                </InputGroupAddon>
              ) : null}
            </InputGroup>
            {validationError === RX_VALIDATION_ERROR.INVALID_DISPENSE && (
              <div className="invalid-feedback d-flex">
                <T id={messages.mustBeGreaterThanZero.id}>{intl.formatMessage(messages.mustBeGreaterThanZero)}</T>
              </div>
            )}
          </FormGroup>
        </Col>
        <Col md={4} sm={12}>
          <FormGroup>
            <Label for="refills_allowed">
              <T id={messages.refillsAllowed.id}>{intl.formatMessage(messages.refillsAllowed)}</T>
            </Label>
            <InputGroup>
              <Input
                type="number"
                min={0}
                value={refillsAllowed}
                onChange={onRefillsAllowedChange}
                disabled={isDisabled}
              />
              <InputGroupAddon addonType="append">
                <Button
                  style={{
                    zIndex: 0,
                  }}
                  disabled
                >
                  <T id={messages.refills.id}>{intl.formatMessage(messages.refills)}</T>
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="notes">
              <T id={messages.notes.id}>{intl.formatMessage(messages.notes)}</T>
            </Label>
            <Input type="text" value={notes} onChange={onNotesChange} disabled={isDisabled} />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

const PatientWeight = (props) => {
  const { patientWeight } = props;
  const intl = useIntl();

  const signature = (patientWeight || {}).updated || (patientWeight || {}).created;
  return (
    <FormGroup>
      <Label>
        <T id={messages.patientWeight.id}>{intl.formatMessage(messages.patientWeight)}</T>
      </Label>
      <InputGroup className="d-flex align-items-end ">
        <b className="mr-2">
          {patientWeight && patientWeight.kg ? (
            <span className="h5">{patientWeight.kg} kg</span>
          ) : (
            <T id={messages.noPatientWeight.id}>{intl.formatMessage(messages.noPatientWeight)}</T>
          )}
        </b>
        {signature && (
          <small>
            <Signature shortName dateFormat="LLL" signature={signature} />
          </small>
        )}
      </InputGroup>
    </FormGroup>
  );
};
