import { ErrorViewer } from 'components-ts/ErrorViewer';
import { useStudy } from 'components-ts/Lab/useStudy';
import { useDisclosure } from 'components-ts/useDisclosure';
import { LoadingInline } from 'components-ts/Loading';
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { FullStudyWithOrder } from 'api/interfaces';
import { StudyFile, StudyItemEditor } from 'components/Study';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { NotFound } from 'components-ts/NotFound';
import CollapseCard from 'components/CollapseCard';
import { OrderComments } from './Orders';

const messages = defineMessages({
  results: {
    id: 'StudyEditor.results',
    defaultMessage: 'Results',
  },
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Notes',
  },
  commentsTitle: {
    id: 'StudyEditor.comments_title',
    defaultMessage: 'Comments',
  },
});

type StudyResultModalButtonProps = {
  orderId: string;
  className?: string;
};

export const StudyResultModalButton: React.FC<StudyResultModalButtonProps> = (props) => {
  const { orderId, className = '' } = props;

  const intl = useIntl();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className={className}>
      <Button className="btn-ghost-primary" onClick={onOpen}>
        <T id={messages.results.id}>{intl.formatMessage(messages.results)}</T>
      </Button>
      {isOpen && <StudyResultModal orderId={orderId} onClose={onClose} />}
    </div>
  );
};

type StudyResultModalProps = {
  orderId: string;
  onClose: () => void;
};

const StudyResultModal: React.FC<StudyResultModalProps> = (props) => {
  const { onClose, orderId } = props;

  const intl = useIntl();

  const { study, isLoading, error } = useStudy({ id: orderId });

  return (
    <Modal size="xl" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <T id={messages.results.id}>{intl.formatMessage(messages.results)}</T>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center">
        {isLoading ? (
          <LoadingInline />
        ) : !study ? (
          <NotFound resourceId={orderId} />
        ) : (
          <div className="w-100">
            {study && <StudyResult study={study} />}
            {error && <ErrorViewer error={error} />}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

type StudyResultProps = {
  study: FullStudyWithOrder;
};

const StudyResult: React.FC<StudyResultProps> = (props) => {
  const { study } = props;

  const intl = useIntl();

  const { items, files } = study;

  return (
    <div className="d-flex flex-column">
      <div className="my-2">
        {items && (
          <div className="d-flex flex-wrap">
            {(items || []).length > 0 &&
              (items || []).map((item) => (
                <div className="col-6 p-1" key={item.id}>
                  <StudyItemEditor readOnly item={item} studyId={study.id} />
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="p-1">
        <span>
          <T id={messages.noteLabel.id}>{intl.formatMessage(messages.noteLabel)}</T>
        </span>
        <Input type="textarea" disabled rows={2} defaultValue={study.note} />
      </div>
      {Array.isArray(study.order.comments) && (
        <CollapseCard
          className="my-2"
          title={
            <h5 className="m-0">
              <T id={messages.commentsTitle.id}>
                {intl.formatMessage(messages.commentsTitle)} ({study.order.comments.length})
              </T>
            </h5>
          }
        >
          <OrderComments comments={study.order.comments} />
        </CollapseCard>
      )}
      {(files || []).map((f) => (
        <StudyFile key={f} fileId={f} studyId={study.id} isDisabled />
      ))}
    </div>
  );
};
