import React from 'react';
import { Form, Field } from 'react-final-form';
import { Input, FormGroup, Label, Button, FormText, InputGroup, InputGroupText, InputGroupAddon } from 'reactstrap';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { StockUnitSelector } from './StockUnitSelector';
import createDecorator from 'final-form-calculate';
import { usePharmacyItems } from './usePharmacyItems';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { ValidateFn } from 'components-ts/Forms/utils';
import {
  CreatePharmacyItemStockInitialValues,
  CreatePharmacyItemStockValidatedValues,
} from './usePharmacyItemCreateStock';
import { Decorator } from 'final-form';
import { Nullable } from 'components-ts/utils';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { LoadingInline } from 'components-ts/Loading';
import { DateField } from 'components-ts/Forms/DateField';

const messages = defineMessages({
  saveLabel: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  containerCountLabel: {
    id: 'label_container_count',
    defaultMessage: 'Container Count',
  },
  containerCountHelp: {
    id: 'PharmacyStock.help_container_count',
    defaultMessage: 'How many containers are there?',
  },
  containerCapacityLabel: {
    id: 'label_stock_units_per_container',
    defaultMessage: 'Stock units per container',
  },
  stockUnitLabel: {
    id: 'PharmacyItem.label_stock_unit',
    defaultMessage: 'Stock Unit',
  },
  amountLabel: {
    id: 'label_amount',
    defaultMessage: 'Total Amount',
  },
  amountHelp: {
    id: 'PharmacyStock.help_amount',
    defaultMessage:
      'Total amount of item. Updated automatically when you change Container Count or Container Capacity.',
  },
  notesLabel: {
    id: 'label_notes',
    defaultMessage: 'Notes',
  },
  lotNumberLabel: {
    id: 'PharmacyStock.lotNumber',
    defaultMessage: 'Lot Number',
  },
  lotExpirationDateLabel: {
    id: 'PharmacyStock.lotExpirationDate',
    defaultMessage: 'Lot Expiration Date',
  },
});

type PharmacyAddStockEventFormProps = {
  onSubmit: (values: CreatePharmacyItemStockValidatedValues) => void;
  validate: ValidateFn<CreatePharmacyItemStockInitialValues>;
  error: Nullable<MessageDescriptor>;
  initialValues?: CreatePharmacyItemStockInitialValues;
  loading?: boolean;
};

export const PharmacyAddStockEventForm: React.FC<PharmacyAddStockEventFormProps> = (props) => {
  const { onSubmit, validate, initialValues, error, loading } = props;

  const intl = useIntl();

  const { stockUnits } = usePharmacyItems();

  const amountDecorator = React.useMemo(
    () =>
      createDecorator(
        {
          field: 'containerCount',
          updates: {
            amount: (containerCount, allFields) =>
              Number(containerCount) * Number((allFields as any)?.containerCapacity) || '',
          },
        },
        {
          field: 'containerCapacity',
          updates: {
            amount: (containerCapacity, allFields) =>
              Number(containerCapacity) * Number((allFields as any)?.containerCount) || '',
          },
        }
      ) as Decorator<CreatePharmacyItemStockInitialValues>,
    []
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values as CreatePharmacyItemStockValidatedValues)}
      validate={validate}
      decorators={[amountDecorator]}
      keepDirtyOnReinitialize
      render={({ handleSubmit, pristine, submitting, form }) => {
        const stockUnit = form.getFieldState('stock_unit');

        return (
          <form
            onSubmit={async (e) => {
              await handleSubmit(e);

              const { valid } = form.getState();
              if (valid) {
                form.reset(initialValues);
              }
            }}
          >
            <Field name="containerCount" type="number" parse={Number}>
              {({ input, meta }) => {
                return (
                  <FormGroup>
                    <Label for={input.name}>{intl.formatMessage(messages.containerCountLabel)}</Label>
                    <Input {...input} disabled={submitting} autoFocus={true} min={0} autoComplete="off" />
                    {meta.error && meta.touched && <small className="d-flex invalid-feedback">{meta.error}</small>}
                    <FormText color="muted">{intl.formatMessage(messages.containerCountHelp)}</FormText>
                  </FormGroup>
                );
              }}
            </Field>
            <Field name="stock_unit">
              {({ input }) => (
                <FormGroup>
                  <Label for={input.name}>{intl.formatMessage(messages.stockUnitLabel)}</Label>
                  <StockUnitSelector {...input} stockUnits={stockUnits} isDisabled />
                </FormGroup>
              )}
            </Field>
            <Field name="containerCapacity" type="number" parse={Number}>
              {({ input, meta }) => {
                return (
                  <FormGroup>
                    <Label for={input.name}>{intl.formatMessage(messages.containerCapacityLabel)}</Label>
                    <InputGroup>
                      <Input {...input} disabled={submitting} min={0} autoComplete="off" />

                      {stockUnit?.value && (
                        <InputGroupAddon addonType="append">
                          <InputGroupText>{stockUnit?.value}</InputGroupText>
                        </InputGroupAddon>
                      )}
                    </InputGroup>
                    {meta.error && meta.touched && <small className="d-flex invalid-feedback">{meta.error}</small>}
                  </FormGroup>
                );
              }}
            </Field>
            <Field name="amount" type="number">
              {({ input, meta }) => {
                return (
                  <FormGroup>
                    <Label for={input.name}>{intl.formatMessage(messages.amountLabel)}</Label>
                    <InputGroup>
                      <Input {...input} disabled />
                      {stockUnit?.value && (
                        <InputGroupAddon addonType="append">
                          <InputGroupText>{stockUnit.value}</InputGroupText>
                        </InputGroupAddon>
                      )}
                    </InputGroup>
                    <FormText color="muted">{intl.formatMessage(messages.amountHelp)}</FormText>
                    {meta.error && !pristine && <small className="d-flex invalid-feedback">{meta.error}</small>}
                  </FormGroup>
                );
              }}
            </Field>
            <Field name="lotNumber">
              {({ input }) => {
                return (
                  <FormGroup>
                    <Label>
                      <T id={messages.lotNumberLabel.id}>{intl.formatMessage(messages.lotNumberLabel)}</T>
                    </Label>
                    <Input {...input} disabled={submitting} autoComplete="off" />
                  </FormGroup>
                );
              }}
            </Field>
            <Field name="lotExpirationDate" parse={(value?: string) => (value ? new Date(value) : new Date())}>
              {(props) => (
                <DateField {...props} autoComplete="off" label={intl.formatMessage(messages.lotExpirationDateLabel)} />
              )}
            </Field>
            <Field name="notes" type="textarea">
              {({ input }) => {
                return (
                  <FormGroup>
                    <Label for={input.name}>{intl.formatMessage(messages.notesLabel)}</Label>
                    <Input {...input} disabled={submitting} autoComplete="off" />
                  </FormGroup>
                );
              }}
            </Field>
            {error && <ErrorViewer error={error} />}
            <Button color="primary" type="submit" disabled={pristine || submitting || loading}>
              <T id={messages.saveLabel.id}>{intl.formatMessage(messages.saveLabel)}</T>
              {(submitting || loading) && <LoadingInline className="ml-1" />}
            </Button>
          </form>
        );
      }}
    />
  );
};
