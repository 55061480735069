import { BasicChecklist, TranslatableText } from 'api/interfaces';
import { UseChecklistsParams, useChecklists } from 'components-ts/Checklists/useChecklists';
import { useDynamicTranslation } from 'components-ts/i18n';
import { Nullable } from 'components-ts/utils';
import { useMemo } from 'react';

export type TranslatedChecklist = BasicChecklist & {
  translatedName: string;
  translatedDescription: Nullable<string>;
  tags: Nullable<Array<TranslatableText>>;
};

export const useTranslatedChecklists = (params?: UseChecklistsParams) => {
  const { translateDynamic } = useDynamicTranslation();

  const { docs, count, isLoading, error, refetch } = useChecklists(params);

  const translatedChecklists: Array<TranslatedChecklist> = useMemo(() => {
    const translated = docs.map((checklist) => {
      const { name, background } = checklist;

      const translatedName = translateDynamic(name?.text ?? '');
      const translatedBackground = translateDynamic(background?.text ?? '');

      return {
        ...checklist,
        tags: checklist.general_tags ?? [],
        translatedName: translatedName ?? name.text ?? '',
        translatedDescription: translatedBackground ?? background?.text ?? null,
      };
    });

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      if (b.translatedName > a.translatedName) {
        return -1;
      }

      return 0;
    });

    return sorted;
  }, [docs, translateDynamic]);

  return {
    checklists: docs,
    translatedChecklists,
    count,
    isLoading,
    error,
    refetch,
  };
};
