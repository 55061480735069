import { gql } from '@apollo/client'
import { BILLABLE_TYPES } from 'wd-common/src/logic/billing/definitions'
import { FullExpenseItem } from 'utils/GQLFragments'

export const GET_COMMODITIES_REPORTS = gql`
  query commoditiesReport(
    $from: String
    $to: String
    $category: [String]
    $doctor: [String]
    $visitType: [String]
    $location: [String]
  ) {
    commoditiesReport(
      from: $from
      to: $to
      category: $category
      doctor: $doctor
      visitType: $visitType
      location: $location
    ) {
      id
      category
      name
      price
      units
      total
      visitType
      doctor
      date
      location
      patientName
    }
  }
`

export const GET_OUTPATIENT_REPORTS = gql`
  query outpatientReports($from: String, $to: String, $patient: [String], $doctor: [String], $visitType: [String], $location: [String]) {
    outpatientReports(from: $from, to: $to, patient: $patient, doctor: $doctor, visitType: $visitType, location: $location) {
      docs{
        visitId
        visitType
        doctor
        date
        patientName
        currencyString
        total
        paymentMethod
        ${Object.values(BILLABLE_TYPES)
    .map(category => `${category}`)
    .join('\n')}
      },
      count
    }
  }
`
export const GET_DOCTORS = gql`
  query doctors($location: [String]) {
    doctors(location: $location) {
      docs {
        id
        family_name
        given_name
      }
      count
    }
  }
`

export const GET_DISEASES_REPORTS = gql`
  query (
    $from: String
    $to: String
    $diagnosis: [String]
    $doctor: [String]
    $visitType: [String]
    $location: [String]
  ) {
    diseases(from: $from, to: $to, diagnosis: $diagnosis, doctor: $doctor, visitType: $visitType, location: $location) {
      visitType
      diagnosis
      doctor
      location
      start
      end
      patient {
        first_name
        last_name
        birthdate
        sex
      }
      quality_equation_results {
        name {
          text
        }
        value {
          val
          missing
        }
      }
    }
  }
`

export const GET_EXPENSE_ITEMS = gql`
  ${FullExpenseItem}
  query expenseItems($from: String, $to: String, $location: [String], $limit: Int, $offset: Int) {
    expenseItems(from: $from, to: $to, location: $location, limit: $limit, offset: $offset) {
      docs {
        ...FullExpenseItem
      }
      count
    }
  }
`

export const CREATE_EXPENSE_ITEM = gql`
  ${FullExpenseItem}
  mutation createExpenseItem($newItem: NewExpenseItem!) {
    createExpenseItem(newItem: $newItem) {
      ...FullExpenseItem
    }
  }
`

export const UPDATE_EXPENSE_ITEM = gql`
  ${FullExpenseItem}
  mutation updateExpenseItem($item: NewExpenseItem!) {
    updateExpenseItem(item: $item) {
      ...FullExpenseItem
    }
  }
`

export const DELETE_EXPENSE_ITEM = gql`
  mutation deleteExpenseItem($id: ID!) {
    deleteExpenseItem(id: $id) {
      done
    }
  }
`
