import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';
import {
  ADMINISTER_RX,
  ADMINISTER_USER_GUIDELINE_RX,
  PRESCRIBE_MIX,
  PRESCRIBE_RX,
  PRESCRIBE_USER_GUIDELINE_RX,
} from 'scenes/Patients/ActiveVisit/requests';
import { FullPatientWithActiveVisit, NewRX, NewUserGuidelineRX } from 'api/interfaces';
import { Nullable, extractFirstErrorCode } from 'components-ts/utils';
import { isUserGeneratedGuideline } from './utils';

const messages = defineMessages({
  patientNotFound: {
    id: 'RXActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  visitNotFound: {
    id: 'Checklist.visit_not_found',
    defaultMessage: 'Could not find the current visit. Please, reload the page',
  },
  errorCreatingRX: {
    id: 'RXActions.error_creating_rx',
    defaultMessage: 'We could not create the RX, try again later.',
  },
  invalidRXId: {
    id: 'RXActions.invalid_rx_id',
    defaultMessage: 'You must use a valid id.',
  },
  genericErrorMessage: {
    id: 'RXActions.generic_error_message',
    defaultMessage: 'Something went wrong, try again later.',
  },
});

const commonErrorFormatter = (errorCode: string): MessageDescriptor => {
  switch (errorCode) {
    case 'invalid_rx_id':
      return messages.invalidRXId;

    case 'visit_not_found':
      return messages.visitNotFound;

    case 'patient_not_found':
      return messages.patientNotFound;

    case 'error_creating_rx':
      return messages.errorCreatingRX;

    // all of these are internal errors
    case 'error_adding_rx_order':
    case 'invalid_signature':
    case 'invalid_location_id':
    default:
      return messages.genericErrorMessage;
  }
};

/**
 * Regular prescription
 */
interface PrescribeRXData {
  prescribeRX: FullPatientWithActiveVisit;
}

interface PrescribeRXVariables {
  patientId: string;
  visitId: string;
  locationId: string;
  rx: NewRX;
}

interface PrescribeUserGuidelineRXData {
  prescribeUserGuidelineRX: FullPatientWithActiveVisit;
}

interface PrescribeUserGuidelineRXVariables {
  patientId: string;
  visitId: string;
  rx: NewUserGuidelineRX;
  locationId: String;
}

type Args = {
  onCompleted?: () => void;
};

export const usePrescribeRx = (args?: Args) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);
    const message = commonErrorFormatter(errorCode);

    setError(message);
  };

  const onCompleted = () => {
    setError(null);

    if (typeof args?.onCompleted === 'function') {
      args.onCompleted();
    }
  };

  const [prescribeUserGuidelineRX, { loading: prescribingUserGuidelineRX }] = useMutation<
    PrescribeUserGuidelineRXData,
    PrescribeUserGuidelineRXVariables
  >(PRESCRIBE_USER_GUIDELINE_RX, {
    onError,
    onCompleted,
  });

  const [prescribeSystemGuidelineRX, { loading: prescribingSystemGuidelineRX }] = useMutation<
    PrescribeRXData,
    PrescribeRXVariables
  >(PRESCRIBE_RX, {
    onError,
    onCompleted,
  });

  const prescribeRX = (patientId: string, visitId: string, rx: NewRX | NewUserGuidelineRX, locationId: string) => {
    setError(null);

    const { guideline, drug, details, mixId, mixNotes } = rx;

    if (guideline && isUserGeneratedGuideline(guideline)) {
      const variables = {
        patientId,
        visitId,
        locationId,
        rx: { guideline, drug, details, mixId, mixNotes },
      };
      return prescribeUserGuidelineRX({ variables });
    }

    const variables = {
      patientId,
      visitId,
      locationId,
      rx: {
        guideline,
        drug,
        details,
        mixId,
        mixNotes,
      },
    };
    return prescribeSystemGuidelineRX({ variables });
  };

  return {
    error,
    isLoading: prescribingUserGuidelineRX || prescribingSystemGuidelineRX,
    prescribeRX,
  };
};

/**
 * This allow to prescribe multiple rx at once
 */
interface PrescribeMixData {
  prescribeMix: FullPatientWithActiveVisit;
}

interface PrescribeMixVariables {
  patientId: string;
  visitId: string;
  locationId: string;
  rxs: Array<NewRX>;
}

export const usePrescribeMix = (args: Args) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);
    const message = commonErrorFormatter(errorCode);

    setError(message);
  };

  const onCompleted = () => {
    setError(null);

    if (typeof args.onCompleted === 'function') {
      args.onCompleted();
    }
  };

  const [prescribe, { loading: isLoading }] = useMutation<PrescribeMixData, PrescribeMixVariables>(PRESCRIBE_MIX, {
    onError,
    onCompleted,
  });

  const prescribeMix = (patientId: string, visitId: string, rxs: Array<NewRX>, locationId: string) => {
    setError(null);

    const variables = {
      patientId,
      visitId,
      locationId,
      rxs,
    };

    prescribe({ variables });
  };
  return {
    error,
    isLoading,
    prescribeMix,
  };
};

/**
 * Administer drug should only allow recurring dosing when there
 * is an admission in process, it does not makes sense for regular visits
 */
interface AdministerRXData {
  administerRX: FullPatientWithActiveVisit;
}

interface AdministerRXVariables {
  patientId: string;
  visitId: string;
  locationId: string;
  rx: NewRX;
}

interface AdministerUserGuidelineRXData {
  administerUserGuidelineRX: FullPatientWithActiveVisit;
}

interface AdministerUserGuidelineRXVariables {
  patientId: string;
  visitId: string;
  locationId: string;
  rx: NewUserGuidelineRX;
}

export const useAdministerRx = (args: Args) => {
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);
    const message = commonErrorFormatter(errorCode);

    setError(message);
  };

  const onCompleted = () => {
    setError(null);

    if (typeof args.onCompleted === 'function') {
      args.onCompleted();
    }
  };

  const [administerUserGuidelineRX, { loading: administeringUserGuidelineRX }] = useMutation<
    AdministerUserGuidelineRXData,
    AdministerUserGuidelineRXVariables
  >(ADMINISTER_USER_GUIDELINE_RX, {
    onError,
    onCompleted,
  });

  const [administerSystemGuidelineRX, { loading: administeringSystemGuidelineRX }] = useMutation<
    AdministerRXData,
    AdministerRXVariables
  >(ADMINISTER_RX, {
    onError,
    onCompleted,
  });

  const administerRX = (patientId: string, visitId: string, rx: NewRX | NewUserGuidelineRX, locationId: string) => {
    setError(null);

    const { guideline, drug, details, mixId, mixNotes } = rx;

    if (guideline && isUserGeneratedGuideline(guideline)) {
      const variables = {
        patientId,
        visitId,
        locationId,
        rx: { guideline, drug, details, mixId, mixNotes },
      };

      return administerUserGuidelineRX({ variables });
    }

    const variables = {
      patientId,
      visitId,
      locationId,
      rx: {
        guideline,
        drug,
        details,
        mixId,
        mixNotes,
      },
    };
    return administerSystemGuidelineRX({ variables });
  };

  return {
    error,
    isLoading: administeringUserGuidelineRX || administeringSystemGuidelineRX,
    administerRX,
  };
};
