import React from 'react';
import { TagsSelectorOrCreator } from 'components-ts/TagsSelectorOrCreator';
import { isMultiSelector, SelectorProps } from 'components-ts/Selectors';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { usePharmacyLibraryTags } from 'components-ts/Pharmacy/usePharmacyLibrary';

type DrugTagSelectorProps = SelectorProps<string>;

export const DrugTagSelector: React.FC<DrugTagSelectorProps> = (props) => {
  const { tags, isLoading, error } = usePharmacyLibraryTags({ nextFetchPolicy: 'network-only' });

  const filteredTags = tags.filter((tag) => {
    if (isMultiSelector(props)) {
      return !props.value?.includes(tag?.text ?? '');
    }

    return tag?.text !== props.value;
  });

  return (
    <div>
      <TagsSelectorOrCreator {...props} tags={filteredTags} isLoading={isLoading} />
      {error && <ErrorViewer error={error} />}
    </div>
  );
};
