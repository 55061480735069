import React from 'react';
import { Address } from '.';
import {
  FaAddressCard as NationalIdIcon,
  FaPhone as PhoneIcon,
  FaUser as UserIcon,
  FaUserTimes as NotFoundIcon,
  FaEnvelope as EnvelopeIcon,
  FaCalendar,
} from 'react-icons/fa';
import { injectIntl, defineMessages } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useAgeTranslator } from 'components-ts/DateAndTime';

// static translations
const messages = defineMessages({
  patient: {
    id: 'PatientInfo.patient_label',
    defaultMessage: 'Patient: ',
  },
  phone: {
    id: 'PatientInfo.phone_label',
    defaultMessage: 'Phone: ',
  },
  email: {
    id: 'PatientInfo.email_label',
    defaultMessage: 'Email: ',
  },
  nationalIdNumber: {
    id: 'PatientInfo.national_id_number_label',
    defaultMessage: 'National ID Number: ',
  },
  patientNotFound: {
    id: 'PatientInfo.patient_not_found',
    defaultMessage: 'Patient not found.',
  },
});

const PatientInfo = props => {
  // props
  const { patient, withAge, withIcon, inlineAddress, className, intl } = props;
  const { formatMessage } = intl;

  const { translateAge } = useAgeTranslator({ intl });

  if (!patient) {
    return null;
  }

  const age = translateAge(patient.birthdate);
  return (patient || {}).first_name && (patient || {}).last_name ? (
    <div className={`${className || ''}`}>
      <div className='d-flex text-capitalize'>
        {withIcon ? (
          <UserIcon style={{ margin: '.2em 0' }} className='text-muted' />
        ) : (
          <T id={messages.patient.id}>{formatMessage(messages.patient)}</T>
        )}
        <b className='ml-2'>
          {`${patient.first_name} ${patient.last_name}`}
          {withAge && <span>{`,  ${age}`}</span>}
        </b>
      </div>

      {patient.birthdate && (
        <div className='d-flex'>
          {withIcon ? (
            <FaCalendar style={{ margin: '.2em 0' }} className='text-muted' />
          ) : (
            <T id={messages.phone.id}>{formatMessage(messages.phone)}</T>
          )}
          <span className='ml-2'>{intl.formatDate(patient.birthdate)}</span>
        </div>
      )}

      <Address address={patient.address} withIcon={withIcon} inline={!!inlineAddress} />

      {patient.phone_number && (
        <div className='d-flex'>
          {withIcon ? (
            <PhoneIcon style={{ margin: '.2em 0' }} className='text-muted' />
          ) : (
            <T id={messages.phone.id}>{formatMessage(messages.phone)}</T>
          )}
          <span className='ml-2'>{patient.phone_number}</span>
        </div>
      )}

      {patient.email && (
        <div className='d-flex'>
          {withIcon ? (
            <EnvelopeIcon style={{ margin: '.2em 0' }} className='text-muted' />
          ) : (
            <T id={messages.email.id}>{formatMessage(messages.email)}</T>
          )}
          <span className='ml-2'>{patient.email}</span>
        </div>
      )}

      {patient.national_id && (
        <div className='d-flex'>
          {withIcon ? (
            <NationalIdIcon style={{ margin: '.2em 0' }} className='text-muted' />
          ) : (
            <T id={messages.nationalIdNumber.id}>{formatMessage(messages.nationalIdNumber)}</T>
          )}
          <span className='ml-2'>{patient.national_id}</span>
        </div>
      )}
    </div>
  ) : (
    <p className={`d-flex align-items-center text-muted m-0 ${className}`}>
      <NotFoundIcon style={{ marginRight: '.5em' }} />
      <T id={messages.patientNotFound.id}>{formatMessage(messages.patientNotFound)}</T>
    </p>
  );
};

export default injectIntl(PatientInfo);
