import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { scoreString, tokenizeQuery } from 'wd-common/src/natural';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { LoadingInline } from 'components-ts/Loading';
import { useQueryParams } from 'hooks';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FaSearch as SearchIcon } from 'react-icons/fa';
import { recursiveSearch } from './SearchableList';

const messages = defineMessages({
  search: {
    id: 'UI.label_search',
    defaultMessage: 'Search...',
  },
  loading: {
    id: 'UI.loading_text',
    defaultMessage: 'Loading...',
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No results',
  },
});

/**
 * This component displays a list of similar objects. The main purpose of a
 * resource list is to help a user find one of these objects and either take
 * quick actions on it or navigate to a full page representation of it.
 *
 * This version is synchronized with the url
 */

type ScrollableListProps<T> = {
  items: Array<T>;
  renderItem: (item: T, index: number, searchTerms: Array<string>) => JSX.Element;
  itemsToShow: number;
  searchableProperties?: Array<string>;
  initialSearch?: string;
  onSearch?: (q: string) => void;
  onPageChange?: (index: number) => void;
  searcherPlaceholder?: string;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isURLSync?: boolean;
};

export function ScrollableList<T>(props: ScrollableListProps<T>) {
  const {
    items,
    renderItem,
    searchableProperties,
    initialSearch = '',
    isDisabled,
    isLoading,
    isURLSync = true,
    className = '',
    itemsToShow = 5,
  } = props;

  const intl = useIntl();

  const queryParams = useQueryParams();
  const history = useHistory();

  const initialFilter = isURLSync ? queryParams.get('q') ?? '' : initialSearch;
  const [filter, setFilter] = useState<string>(initialFilter);
  const [itemHeight, setItemHeight] = useState(54);

  const ref = React.useRef<HTMLInputElement>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value;

    // update states
    setFilter(q);

    if (isURLSync) {
      // update url
      queryParams.set('page', '0');
      if (q.length > 0) {
        queryParams.set('q', q);
      } else {
        queryParams.delete('q');
      }

      history.replace({
        search: queryParams.toString(),
      });
    }
    // run callback
    if (typeof props.onSearch === 'function') {
      props.onSearch(q);
    }
  };

  const searchTerms = tokenizeQuery(filter);
  const filteredItems = searchableProperties
    ? items
        .filter((item) =>
          // search on the searchableProperties
          searchableProperties.some((word) => recursiveSearch(item, word, searchTerms))
        )
        .map((item) => {
          const score = searchableProperties
            ?.map((word) => {
              // check for each searchable word
              if (item[word] && typeof item[word] === 'string') {
                // scoring
                return scoreString(item[word], searchTerms);
              }
              return 0;
            })
            .reduce((a, b) => a + b, 0);

          // append score for sorting
          return {
            ...item,
            ResourceList_search: {
              score,
              searchableProperties: searchableProperties,
              searchTerms,
            },
          };
        })
        // And sort on the result
        .sort((a, b) => b.ResourceList_search.score - a.ResourceList_search.score)
    : items;

  const isSearcherVisible = items.length > 0;

  React.useEffect(() => {
    if (ref.current && initialSearch) {
      ref.current.focus();
      ref.current.select();
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (items.length > 0 && scrollRef.current) {
      const item = scrollRef.current?.firstElementChild;
      if (item) {
        const boundingRect = item.getBoundingClientRect();
        const itemHeight = boundingRect.height;
        const computedStyles = getComputedStyle(item);
        const yMargin = parseInt(computedStyles.marginTop) + parseInt(computedStyles.marginBottom);

        const realItemHeight = itemHeight + yMargin;

        setItemHeight(realItemHeight);
      }
    }
  }, [scrollRef, items]);

  const scrollableSectionStyles = {
    maxHeight: `${itemsToShow * itemHeight}px`,
    overflow: 'auto',
  };
  return (
    <div className={className}>
      {isSearcherVisible && (
        <InputGroup>
          <Input
            value={filter}
            onChange={onSearch}
            type="text"
            className="form-control"
            placeholder={intl.formatMessage(messages.search)}
            aria-label={intl.formatMessage(messages.search)}
            innerRef={ref}
            disabled={isDisabled}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <SearchIcon />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      )}
      <ul className={'list-group position-relative my-1'}>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center  bg-light py-4 my-1 rounded animated fadeIn">
            <LoadingInline />
            <T id={messages.loading.id} className="ml-2">
              {intl.formatMessage(messages.loading)}
            </T>
          </div>
        ) : filteredItems.length === 0 ? (
          <div className="bg-light text-center bg-light py-4 my-1 rounded text-muted  animated fadeIn">
            <T id={messages.noItemsFound.id}>{intl.formatMessage(messages.noItemsFound)}</T>
          </div>
        ) : (
          <div className="rounded my-3" style={scrollableSectionStyles} ref={scrollRef}>
            {filteredItems.map((item, index) => renderItem(item, index, searchTerms))}
          </div>
        )}
      </ul>
    </div>
  );
}
