import React from 'react';
import Creatable from 'react-select/creatable';
import { useDynamicTranslation } from 'components-ts/i18n';
import { defineMessages, useIntl } from 'react-intl';
import { isMultiSelector, ReactSelectInternalSingleValue, ReactSelectInternalValue, SelectorProps } from './Selectors';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { TranslatableText } from 'api/interfaces';
import chroma from 'chroma-js';

const messages = defineMessages({
  createOne: {
    id: 'UI.tag_selector_empty',
    defaultMessage: 'Create one by typing...',
  },
  placeholder: {
    id: 'UI.tag_selector_placeholder',
    defaultMessage: 'Tags',
  },
});

type TagSelectorProps = SelectorProps<string> & {
  tags: Array<TranslatableText>;
  className?: string;
};

export const TagsSelectorOrCreator: React.FC<TagSelectorProps> = (props) => {
  const { value, tags, isClearable, isDisabled, isLoading, className } = props;

  const intl = useIntl();
  const { translateDynamic } = useDynamicTranslation();

  const formatOption = (tag: string): ReactSelectInternalSingleValue => {
    return {
      value: tag ?? '',
      label: translateDynamic(tag ?? '') ?? '',
    };
  };

  const options = tags.map((tt) => formatOption(tt?.text ?? ''));

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>) => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return [];
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, tag: string) => {
        return [...prev, formatOption(tag)];
      }, []);

      return formattedValues;
    } else {
      formatOption(value as string);
    }
  };

  const _onChange = (v: ReactSelectInternalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(v)) {
        return props.onChange([]);
      }

      const selectedValues = v as Array<ReactSelectInternalSingleValue>;
      const newValues = selectedValues.map((item) => item.value);

      props.onChange(newValues);
    } else {
      const selectedValue = v as ReactSelectInternalSingleValue;

      props.onChange(selectedValue.value);
    }
  };

  const styles = {
    multiValue: (styles, { data }) => {
      if (!data?.value) {
        return styles;
      }

      const color = chroma(intToRGB(hashTag(data.value)));

      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => {
      if (!data?.value) {
        return styles;
      }

      const color = chroma(intToRGB(hashTag(data.value)));

      return { ...styles, color: color.css() };
    },
    multiValueRemove: (styles, { data }) => {
      if (!data?.value) {
        return styles;
      }

      const color = chroma(intToRGB(hashTag(data.value)));

      return {
        ...styles,
        color: color.css(),
        ':hover': {
          backgroundColor: color.css(),
          color: 'white',
        },
      };
    },
  };

  return (
    <Creatable
      placeholder={intl.formatMessage(messages.placeholder)}
      className={className}
      value={parseValue(value)}
      onChange={_onChange}
      options={options}
      isMulti={isMultiSelector(props)}
      isLoading={isLoading}
      isClearable={isClearable}
      isDisabled={isDisabled}
      components={{
        NoOptionsMessage: () => (
          <p className="text-center pt-3">
            <T id={messages.createOne.id}>{intl.formatMessage(messages.createOne)}</T>
          </p>
        ),
      }}
      styles={styles}
      isSearchable
      blurInputOnSelect
    />
  );
};

const hashTag = (tag: string): number => {
  let hash = 0;
  for (var i = 0; i < tag.length; i++) {
    hash = tag.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i: number): string => {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
};
