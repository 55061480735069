import { FullProcedure } from 'api/interfaces';
import { useProcedures } from 'components-ts/Procedures';
import { useDynamicTranslation } from 'components-ts/i18n';
import { Nullable } from 'components-ts/utils';
import { useMemo } from 'react';

type TranslatedProcedure = FullProcedure & { translatedName: string; translatedDescription: Nullable<string> };

export const useTranslatedProcedures = () => {
  const { translateDynamic } = useDynamicTranslation();

  const { procedures, isLoading, error } = useProcedures();

  const translatedProcedures: Array<TranslatedProcedure> = useMemo(() => {
    const translated = procedures.map((study) => {
      const { name, description } = study;

      const translatedName = translateDynamic(name?.text ?? '');
      const translatedDescription = translateDynamic(description?.text ?? '');

      return {
        ...study,
        translatedName: translatedName ?? name.text ?? '',
        translatedDescription: translatedDescription ?? description?.text ?? null,
      };
    });

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      if (b.translatedName > a.translatedName) {
        return -1;
      }

      return 0;
    });

    return sorted;
  }, [procedures, translateDynamic]);

  return {
    procedures,
    translatedProcedures,
    isLoading,
    error,
  };
};
