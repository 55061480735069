import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';

const messages = defineMessages({
  dispense: {
    id: 'CustomRxDrug.text_dispense',
    defaultMessage: 'dispense',
  },
  // this messages are used in common/src/logic/rx/index
  // this is just for building them
  hoursBetween: { id: 'Prescription.hours_between', defaultMessage: 'hours between' },
  once: { id: 'Prescription.once_a_day', defaultMessage: 'once a day' },
  every72: { id: 'Prescription.every_72_hours', defaultMessage: 'every 72 hours' },
  every48: { id: 'Prescription.every_48_hours', defaultMessage: 'every 48 hours' },
  every36: { id: 'Prescription.every_36_hours', defaultMessage: 'every 36 hours' },
  every12: { id: 'Prescription.every_12_hours', defaultMessage: 'every 12 hours' },
  every8: { id: 'Prescription.every_8_hours', defaultMessage: 'every 8 hours' },
  every6: { id: 'Prescription.every_6_hours', defaultMessage: 'every 6 hours' },
  every4: { id: 'Prescription.every_4_hours', defaultMessage: 'every 4 hours' },
  every3: { id: 'Prescription.every_3_hours', defaultMessage: 'every 3 hours' },
  every2: { id: 'Prescription.every_2_hours', defaultMessage: 'every 2 hours' },
  every1: { id: 'Prescription.every_hour', defaultMessage: 'every hour' },
  every10m: { id: 'Prescription.every_10_minutes', defaultMessage: 'every 10 minutes' },
  every5m: { id: 'Prescription.every_5_minutes', defaultMessage: 'every 5 minutes' },
  every3m: { id: 'Prescription.every_3_minutes', defaultMessage: 'every 3 minutes' },
  timesPerDay: { id: 'Prescription.times_per_day', defaultMessage: 'times per day' },
  dosesADay: { id: 'Prescription.doses_a_day', defaultMessage: 'doses a day' },
  dose: { id: 'Prescription.dose', defaultMessage: 'dose' },
  doses: { id: 'Prescription.doses', defaultMessage: 'doses' },
  days: { id: 'Prescription.days', defaultMessage: 'Days' },
  day: { id: 'Prescription.day', defaultMessage: 'Day' },
  asNeeded: { id: 'label_prn_as_needed', defaultMessage: 'PRN' },
});

type DaysMessageProps = {
  days: number;
};

export const DaysMessage: React.FC<DaysMessageProps> = (props) => {
  const { days } = props;

  const intl = useIntl();

  const daysMessage = days > 1 ? messages.days : messages.day;

  return (
    <>
      x {intl.formatNumber(days)} <T id={daysMessage.id}>{intl.formatMessage(daysMessage)}</T>
    </>
  );
};

type DosesMessageProps = {
  doses: number;
};

const DosesMessage: React.FC<DosesMessageProps> = (props) => {
  const { doses } = props;

  const intl = useIntl();

  const message = doses === 1 ? messages.dose : messages.doses;

  return (
    <>
      {intl.formatNumber(doses)} <T id={message.id}>{intl.formatMessage(message)}</T>
    </>
  );
};

type DosesPerDayMessageProps = {
  dosesPerDay: number;
};

const DosesPerDayMessage: React.FC<DosesPerDayMessageProps> = (props) => {
  const { dosesPerDay } = props;

  const intl = useIntl();

  if (dosesPerDay === 1) {
    return <T id={messages.once.id}>{intl.formatMessage(messages.once)}</T>;
  }

  return (
    <>
      {intl.formatNumber(dosesPerDay)} <T id={messages.timesPerDay.id}>{intl.formatMessage(messages.timesPerDay)}</T>
    </>
  );
};

type FrequencyProps = {
  dosesPerDay: number;
};

const Frequency: React.FC<FrequencyProps> = (props) => {
  const { dosesPerDay } = props;

  const intl = useIntl();

  switch (dosesPerDay) {
    case 0.3333333333333333:
      return <T id={messages.every72.id}>{intl.formatMessage(messages.every72)}</T>;
    case 0.5:
      return <T id={messages.every48.id}>{intl.formatMessage(messages.every48)}</T>;
    case 0.6666666666666666:
      return <T id={messages.every36.id}>{intl.formatMessage(messages.every36)}</T>;
    case 1:
      return <T id={messages.once.id}>{intl.formatMessage(messages.once)}</T>;
    case 2:
      return <T id={messages.every12.id}>{intl.formatMessage(messages.every12)}</T>;
    case 3:
      return <T id={messages.every8.id}>{intl.formatMessage(messages.every8)}</T>;
    case 4:
      return <T id={messages.every6.id}>{intl.formatMessage(messages.every6)}</T>;
    case 6:
      return <T id={messages.every4.id}>{intl.formatMessage(messages.every4)}</T>;
    case 8:
      return <T id={messages.every3.id}>{intl.formatMessage(messages.every3)}</T>;
    case 12:
      return <T id={messages.every2.id}>{intl.formatMessage(messages.every2)}</T>;
    case 24:
      return <T id={messages.every1.id}>{intl.formatMessage(messages.every1)}</T>;
    case 144:
      return <T id={messages.every10m.id}>{intl.formatMessage(messages.every10m)}</T>;
    case 288:
      return <T id={messages.every5m.id}>{intl.formatMessage(messages.every5m)}</T>;
    case 480:
      return <T id={messages.every3m.id}>{intl.formatMessage(messages.every3m)}</T>;
    default: {
      if (dosesPerDay % 1) {
        const hours = Math.round(Math.abs(24 / dosesPerDay));
        return (
          <>
            {intl.formatNumber(hours)} <T id={messages.hoursBetween.id}>{intl.formatMessage(messages.hoursBetween)}</T>
          </>
        );
      }
      return (
        <>
          {intl.formatNumber(dosesPerDay)}{' '}
          <T id={messages.timesPerDay.id}>{intl.formatMessage(messages.timesPerDay)}</T>
        </>
      );
    }
  }
};

type FrequencyMessageProps = {
  frequencyShow: string;
  dosesPerDay: number;
};

export const FrequencyMessage: React.FC<FrequencyMessageProps> = (props) => {
  const { frequencyShow, dosesPerDay } = props;

  switch (frequencyShow) {
    case 'doses_per_day':
      return <DosesPerDayMessage dosesPerDay={dosesPerDay} />;
    case 'doses':
      return <DosesMessage doses={dosesPerDay} />;
    default:
      return <Frequency dosesPerDay={dosesPerDay} />;
  }
};
