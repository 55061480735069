import React, { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Badge, Button, FormGroup, Input, Label } from 'reactstrap';
import { LoadingInline, ResourceList } from 'components';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { usePatientVitals } from 'components-ts/Patients/usePatientVitals';
import { usePatientContext } from 'components-ts/Patients/usePatientContext';
import Highlighter from 'react-highlight-words';
import { initialCalculation } from 'wd-common/src/logic/pharmacy/utils';
import { DaysSelector, useQuickpickWithDrugSearchActions } from 'components/Quickpicks';
import { FaCheckCircle as CheckIcon } from 'react-icons/fa';
import { usePrescribeMix } from './useRXActions';
import { QUICKPICK_DAYS } from './utils';
import { Drug, NewRX, NewRXDetails, VitalType } from 'api/interfaces';
import { validateRXForm } from './rxFormValidation';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { ObjectId } from 'bson';
import { Quickpick } from 'api/interfaces/quickpicks';

const messages = defineMessages({
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  inStock: {
    id: 'RxModal.in_stock',
    defaultMessage: 'Stock',
  },
  outOfStock: {
    id: 'RxModal.out_of_stock',
    defaultMessage: 'Out of Stock',
  },
  prescribeButton: {
    id: 'UI.button_prescribe',
    defaultMessage: 'Prescribe',
  },
  mixMode: {
    id: 'QuickpickTab.label_mix_mode',
    defaultMessage: 'Mix Mode',
  },
  mixNotes: {
    id: 'QuickpickTab.label_mix_notes',
    defaultMessage: 'Mix Notes',
  },
});

const DEFAULT_DAYS_SELECTED = QUICKPICK_DAYS.ONE_DAY;

interface QuickpickTabProps {
  locationId: string;
  onCompleted?: () => void;
}
export const QuickpickTab: React.FC<QuickpickTabProps> = (props) => {
  const { locationId } = props;
  const intl = useIntl();

  const [isMix, setIsMix] = useState(false);
  const [selectedDays, setSelectedDays] = useState(DEFAULT_DAYS_SELECTED);
  const [selectedQuickpicks, setSelectedQuickpicks] = useState(new Map());
  const [mixNotes, setMixNotes] = useState('');

  // get the patient from cache
  const { activeVisit, patient } = usePatientContext();
  const visitId = activeVisit?.id;

  const { getVital } = usePatientVitals();
  const patientWeight = getVital(VitalType.WEIGHT);

  const args = {
    onCompleted: props.onCompleted,
  };

  const { prescribeMix, isLoading, error: prescribingErrors } = usePrescribeMix(args);

  /**
   * Every time the user adds or removes a quick pick,
   * we've to calculate the dosing for each option in the map
   * and show the corresponding calculation errors if exist
   */
  const autoCalculatedRX = useMemo(() => {
    return Array.from(selectedQuickpicks).map(([_, quickpick]) => {
      const guideline = quickpick.guideline
        ? {
            ...quickpick.guideline,
            days: selectedDays,
          }
        : {
            days: selectedDays,
          };

      // clean drug
      const {
        drugId, // We don't rely on id because it was overwriting cache
        class: drugClass,
        name,
        unit,
        retail,
        category,
        dosing,
        interactions,
        comments,
        recommended_dosing, //eslint-disable-line
        stock_unit, //eslint-disable-line
        minimum_units_to_dispense, //eslint-disable-line
      } = quickpick.drug;

      const drug = {
        id: drugId,
        class: drugClass,
        name,
        unit,
        retail,
        category,
        dosing,
        interactions,
        comments,
        recommended_dosing, //eslint-disable-line
        stock_unit, //eslint-disable-line
        minimum_units_to_dispense, //eslint-disable-line
      };

      const details = initialCalculation(guideline, drug, patientWeight);

      return {
        details,
        drug,
        guideline,
        error: validateRXForm(details),
      };
    });
  }, [selectedQuickpicks, selectedDays, patientWeight]);

  const onSelect = (quickpick) => {
    const id = quickpick.id;

    if (selectedQuickpicks.has(id)) {
      // deleting entry
      setSelectedQuickpicks((prev) => {
        const newState = new Map(prev);
        newState.delete(id);
        return newState;
      });
    } else {
      // adding entry
      setSelectedQuickpicks((prev) => new Map(prev).set(id, quickpick));
    }
  };

  const onDaysChange = (days) => {
    setSelectedDays(days);
  };

  const onMixNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setMixNotes(value);
  };

  const onPrescribeRX = () => {
    if (!visitId) {
      return null;
    }

    // create a new ID in case we need to group them
    const mixId = new ObjectId();
    const rxs = autoCalculatedRX.map((rx) => {
      const newRx: NewRX = {
        details: rx.details as NewRXDetails,
        drug: rx.drug,
        guideline: rx.guideline,
        mixId: null,
        mixNotes: null,
      };

      if (isMix) {
        newRx.mixId = mixId;
        newRx.mixNotes = mixNotes;
      }

      return newRx;
    });

    prescribeMix(patient.id, visitId, rxs, locationId);
  };

  const {
    quickpicks,
    count,
    loading: searching,
    onSearch,
    onPageChange,
    filter,
    error: searchingError,
    ITEMS_PER_PAGE,
  } = useQuickpickWithDrugSearchActions();

  const autoCalculationErrors = autoCalculatedRX
    .filter((rx) => rx.error)
    .map((rx) => ({ id: rx.error, message: rx.error }));

  const error = [...(searchingError || []), ...(autoCalculationErrors || [])];
  // TODO: format and show this message
  console.log({ error });
  const isSubmitDisabled = selectedQuickpicks.size === 0 || autoCalculationErrors.length > 0;
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center my-3">
        <FormGroup className="d-flex align-items-center m-0 my-1">
          <Label className="switch switch-pill switch-primary  m-0">
            <Input
              type="checkbox"
              className="switch-input"
              onChange={(e) => setIsMix(e.target.checked)}
              checked={isMix}
            />
            <span className="switch-slider m-0" />
          </Label>

          <p className="m-0 ml-2">
            <T id={messages.mixMode.id}>{intl.formatMessage(messages.mixMode)}</T>
          </p>
        </FormGroup>
        <DaysSelector value={selectedDays} onChange={onDaysChange} />
      </div>
      <ResourceList
        items={quickpicks}
        itemsToShow={ITEMS_PER_PAGE}
        itemsCount={count}
        q={filter.q}
        selectedPage={filter.page}
        onSearch={onSearch}
        onPageChange={onPageChange}
        isSearching={searching}
        listClassName={'flex-row flex-wrap'}
        renderItem={(item, _, searchTerms) => (
          <CustomItem
            key={item.id}
            quickpick={item}
            onSelect={onSelect}
            isSelected={selectedQuickpicks.has(item.id)}
            searchTerms={searchTerms}
          />
        )}
      />
      {isMix && (
        <FormGroup className="animated">
          <Label>
            <T id={messages.mixNotes.id}>{intl.formatMessage(messages.mixNotes)}</T>
          </Label>
          <Input type="text" value={mixNotes} onChange={onMixNotesChange} />
        </FormGroup>
      )}
      {/* {error && <ErrorViewer error={error} />} */}
      {prescribingErrors && <ErrorViewer error={prescribingErrors} />}
      <div className="d-flex mt-3">
        <Button color="primary" type="button" disabled={isSubmitDisabled} onClick={onPrescribeRX}>
          <T id={messages.prescribeButton.id} className={isLoading ? 'mr-1' : ''}>
            {intl.formatMessage(messages.prescribeButton)}
          </T>
          {isLoading && <LoadingInline />}
        </Button>
      </div>
    </div>
  );
};

type QuickpickWithDrug = Quickpick & { drug: Drug };
interface CustomItemProps {
  quickpick: QuickpickWithDrug;
  isSelected?: boolean;
  searchTerms?: Array<string>;
  onSelect: (newQp: QuickpickWithDrug) => void;
}

const CustomItem: React.FC<CustomItemProps> = (props) => {
  const { quickpick, isSelected, searchTerms } = props;

  const { id, name: quickpickName, drug } = quickpick;
  const { name: drugName, stockInfo } = drug;

  const intl = useIntl();

  /**
   * We'll only receive the current location stock info.
   */
  const { stock } = stockInfo || {};
  const currentStock = stock ? Math.round(stock * 100) / 100 : null;

  const outOfStock = !currentStock || currentStock <= 0;

  const onSelect = () => {
    props.onSelect(quickpick);
  };

  const dynamicStyles = `${outOfStock ? 'not-allowed' : 'pointer'} ${isSelected && 'bg-light'}`;

  return (
    <div className="col-lg-6 p-1">
      <div
        className={`d-flex justify-content-between align-items-center list-group-item-action border rounded p-2 ${dynamicStyles}`}
        key={id}
        onClick={onSelect}
      >
        <div className="d-flex flex-column">
          <span className="d-flex align-items-center h6 mb-0">
            <Highlighter
              className="font-weight-bold"
              searchWords={searchTerms}
              autoEscape
              textToHighlight={quickpickName}
            />
          </span>
          <small className="text-muted">{drugName}</small>
        </div>
        <div>
          {isSelected && <CheckIcon className="text-success h4 m-0 mr-2" />}

          {outOfStock ? (
            <Badge pill color={'danger'}>
              <T id={messages.outOfStock.id}>{intl.formatMessage(messages.outOfStock)}</T>
            </Badge>
          ) : (
            <Badge pill color={'primary'}>
              <span className="ml-1">{currentStock}</span>
              {drug.stock_unit && <span className="ml-1">{drug.stock_unit}</span>}
            </Badge>
          )}
        </div>
      </div>
    </div>
  );
};
