import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import { DrNurseOrder } from 'api/interfaces';
import { useSimplifiedDateFromSignatures } from './utils';
import { Nullable } from 'components-ts/utils';
import { OrderStatus } from './OrderStatus';
import { OrderComments } from './OrderComments';
import { OrderActionMenu } from './OrderActions';

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Note',
  },
  resultButton: {
    id: 'UI.result_button',
    defaultMessage: 'Result',
  },
  cancelLabel: {
    id: 'UI.cancelled_label',
    defaultMessage: 'Cancelled',
  },
  acknowledgedLabel: {
    id: 'UI.acknowledged_label',
    defaultMessage: 'Acknowledged',
  },
  filledLabel: {
    id: 'UI.filled_label',
    defaultMessage: 'Filled',
  },
  pendingLabel: {
    id: 'UI.pending_label',
    defaultMessage: 'Pending',
  },
  commentsLabel: {
    id: 'UI.comments_label',
    defaultMessage: 'Comments',
  },
  communicationOrder: {
    id: 'OrderList.communication_title',
    defaultMessage: 'Doctor / Nurse Communication',
  },
  studiesTitle: {
    id: 'OrderList.studies_title',
    defaultMessage: 'Studies',
  },
  proceduresTitle: {
    id: 'OrderList.procedures_title',
    defaultMessage: 'Procedures',
  },
  prescriptionsTitle: {
    id: 'OrderList.prescriptions_title',
    defaultMessage: 'Prescriptions',
  },
  ordersTitle: {
    id: 'OrderList.medicines_to_administer_title',
    defaultMessage: 'Medicines to Administer',
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No items found',
  },
  mixLabel: {
    id: 'ResourceList.mix_label',
    defaultMessage: 'Mix',
  },
  mixNotesLabel: {
    id: 'ResourceList.mix_notes_label',
    defaultMessage: 'Mix notes',
  },
});

type ActionableProps = {
  patientId: string;
  visitId: string;
  orders: Array<DrNurseOrder>;
  isActionable: true;
  className?: string;
};

type ReadOnlyProps = {
  orders: Array<DrNurseOrder>;
  isActionable?: false;
  className?: string;
};

type NurseOrderListProps = ActionableProps | ReadOnlyProps;

export const NurseOrderList: React.FC<NurseOrderListProps> = (props) => {
  const { orders, className, ...rest } = props;

  const intl = useIntl();

  return (
    <div className={`w-100 ${className || ''}`}>
      <h4>
        <T id={messages.communicationOrder.id}>{intl.formatMessage(messages.communicationOrder)}</T>
      </h4>
      <div className="border-top py-1">
        {orders.map((order, index, allOrders) => {
          const prevItem = index > 0 ? allOrders[index - 1] : null;
          const key = `${order.id}-${index}`;

          return <Item key={key} item={order} prevItem={prevItem} {...rest} />;
        })}
      </div>
    </div>
  );
};

type ActionableItemProps = {
  patientId: string;
  visitId: string;
  item: DrNurseOrder;
  prevItem: Nullable<DrNurseOrder>;
  isActionable: true;
  className?: string;
};

type ReadOnlyItemProps = {
  item: DrNurseOrder;
  prevItem: Nullable<DrNurseOrder>;
  isActionable?: false;
  className?: string;
};

type ItemProps = ReadOnlyItemProps | ActionableItemProps;

const Item: React.FC<ItemProps> = (props) => {
  const { item, prevItem, isActionable } = props;

  const { date, time } = useSimplifiedDateFromSignatures(item.order.created, prevItem?.order.created);

  return (
    <div className="border-bottom border-light py-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex mb-2">
          <div style={{ minWidth: 96 }}>
            {date && <p className="m-0">{date}</p>}
            <p className="m-0">{time}</p>
          </div>
          <div className="px-2">
            <b style={{ textDecoration: item.canceled ? 'line-through' : 'none' }}>{item.order.text}</b>
          </div>
        </div>
        {isActionable && (
          <OrderActionMenu
            className="section-not-printable"
            patientId={props.patientId}
            visitId={props.visitId}
            order={item}
            isCancellable
            isAcknowledgeable
            isCommentable
          />
        )}
      </div>
      <OrderStatus
        className="section-not-printable my-2"
        acknowledged={!!item.acknowledged}
        canceled={!!item.canceled}
      />
      {Array.isArray(item.comments) && <OrderComments comments={item.comments} className="my-2" />}
    </div>
  );
};
