import React from 'react';
import { FieldInputProps } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import Creatable from 'react-select/creatable';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { FormText } from 'reactstrap';

const messages = defineMessages({
  no_classes_found: {
    id: 'PharmacyItemClassSelector.no_classes_found',
    defaultMessage: 'No classes found',
  },
  help_class: {
    id: 'PharmacyItem.help_class',
    defaultMessage:
      'The medical purpose of the item e.g. Antihistimine, Bronchodialator, Anti-hypertensive, Assistive Device, etc.',
  },
  placeholder: {
    id: 'PharmacyItemClassSelector.placeholder',
    defaultMessage: 'Class',
  },
});

type PharmacyItemClassSelectorProps = FieldInputProps<any, HTMLElement> & {
  classes: Set<string>;
  isDisabled?: boolean;
};

export const PharmacyItemClassSelector: React.FC<PharmacyItemClassSelectorProps> = (props) => {
  const { classes, isDisabled, ...input } = props;

  const intl = useIntl();

  const options = React.useMemo(() => Array.from(classes.values()).map((v) => ({ value: v, label: v })), [classes]);

  return (
    <>
      <Creatable
        {...input}
        placeholder={intl.formatMessage(messages.placeholder)}
        // format the options
        options={options}
        components={{
          NoOptionsMessage: () => (
            <p className="text-center pt-3">
              <T id={messages.no_classes_found.id}>{intl.formatMessage(messages.no_classes_found)}</T>
            </p>
          ),
        }}
        blurInputOnSelect
        isSearchable
        isClearable
        // react-select uses {value: string, label: string} as value
        // react-final-form uses traditional string only
        // so we need to handle this with some hacky moves
        onChange={(option) => input.onChange(option ? option.value : '')}
        value={options ? options.find((option) => option.value === input.value) : ''}
        isDisabled={isDisabled}
      />
      <FormText className="text-muted">{intl.formatMessage(messages.help_class)}</FormText>
    </>
  );
};
