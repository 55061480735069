// calendar event types
export const EVENT_TYPES = Object.freeze({
  BLOCKED_TIME: 'Blocked Time',
  SCHEDULED_VISIT: 'Scheduled Visit',
  EVENT_CREATOR: 'Event Creator'
})

// room event can be recurring
export const RECURRENCE_TYPES = Object.freeze({
  UNIQUE: 'unique',
  DAILY: 'daily',
  WEEKLY: 'weekly'
})

// telehealth
export const SLOT_UNITS = Object.freeze({
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes'
})
