import { gql } from '@apollo/client'
import { FullTranslation } from 'utils/GQLFragments'

export const GET_TRANSLATION_BY_ID = gql`
  query translation($id: ID!) {
    translation(id: $id) {
      ...FullTranslation
    }
  }
  ${FullTranslation}
`

export const GET_TRANSLATION_STATS = gql`
  query stats {
    stats {
      total
      translated {
        count
        locale
      }
    }
  }
`

export const UPDATE_TRANSLATION = gql`
  mutation updateTranslation($id: ID!, $locale: String!, $message: String!, $defaultMessage: String!) {
    updateTranslation(id: $id, locale: $locale, message: $message, defaultMessage: $defaultMessage) {
      ...FullTranslation
    }
  }
  ${FullTranslation}
`

export const GET_TRANSLATIONS = gql`
  query getTranslations($q: String, $offset: Int, $limit: Int) {
    getTranslations(q: $q, offset: $offset, limit: $limit) {
      docs {
        ...FullTranslation
      }
      count
    }
  }
  ${FullTranslation}
`

export const REFRESH_STATIC_TRANSLATIONS = gql`
  mutation refreshStaticTranslations {
    refreshStaticTranslations {
      done
    }
  }
`

export const REFRESH_CHECKLISTS_TRANSLATIONS = gql`
  mutation refreshChecklistsTranslations {
    refreshChecklistsTranslations {
      done
    }
  }
`

export const REFRESH_EXAMS_TRANSLATIONS = gql`
  mutation refreshExamsTranslations {
    refreshExamsTranslations {
      done
    }
  }
`

export const REFRESH_LISTS_TRANSLATIONS = gql`
  mutation refreshListsTranslations {
    refreshListsTranslations {
      done
    }
  }
`

export const REFRESH_STUDIES_TRANSLATIONS = gql`
  mutation refreshStudiesTranslations {
    refreshStudiesTranslations {
      done
    }
  }
`

export const REFRESH_PROCEDURES_TRANSLATIONS = gql`
  mutation refreshProceduresTranslations {
    refreshProceduresTranslations {
      done
    }
  }
`

export const REFRESH_SURVEYS_TRANSLATIONS = gql`
  mutation refreshSurveysTranslations {
    refreshSurveysTranslations {
      done
    }
  }
`

export const REFRESH_NOTES_TRANSLATIONS = gql`
  mutation refreshNotesTranslations {
    refreshNotesTranslations {
      done
    }
  }
`
