import React from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Alert, Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { useSession } from './Auth';
import { SubscriptionStatus } from 'api/interfaces';

const messages = defineMessages({
  requestCantCompleted: {
    id: 'ErrorViewer.request_cant_be_completed',
    defaultMessage: 'The request cannot be completed',
  },
  pastDue: {
    id: 'ErrorViewer.past_due_error',
    defaultMessage: 'Your account subscription is past due',
  },
  error: {
    id: 'UI.title_error',
    defaultMessage: 'Error',
  },
  closeButton: {
    id: 'UI.close_button',
    defaultMessage: 'Close',
  },
});

interface Props {
  error: MessageDescriptor | Array<MessageDescriptor>;
  className?: string;
}
export const ErrorViewer: React.FC<Props> = (props) => {
  const { error, className } = props;

  const { getSubscriptionStatus } = useSession();

  const isPastDue = getSubscriptionStatus() === SubscriptionStatus.PAST_DUE;

  const intl = useIntl();

  const errors = isPastDue ? [messages.pastDue] : Array.isArray(error) ? error : [error];

  if (errors.length === 0) {
    return null;
  }

  return (
    <Alert className={`mt-1 alert-danger d-flex align-items-start justify-content-start flex-column ${className}`}>
      <T id={messages.requestCantCompleted.id}>{intl.formatMessage(messages.requestCantCompleted)}</T>
      <ul className="text-left">
        {errors.map((error, index) => {
          const key = `${error.id}-${index}`;

          return (
            <li key={key}>
              <T id={error.id}>{intl.formatMessage(error)}</T>
            </li>
          );
        })}
      </ul>
    </Alert>
  );
};

interface ErrorViewerPopupProps {
  targetId: string;
  error: MessageDescriptor;
  onErrorClose: () => void;
  className?: string;
}
export const ErrorViewerPopup: React.FC<ErrorViewerPopupProps> = (props) => {
  const { targetId, onErrorClose, error } = props;

  const intl = useIntl();
  return (
    <Popover placement={'bottom'} isOpen={!!error} target={`#${targetId}`} toggle={onErrorClose}>
      <PopoverHeader className="bg-danger d-flex justify-content-between">
        <T id={messages.error.id}>{intl.formatMessage(messages.error)}</T>
      </PopoverHeader>
      <PopoverBody>
        <p>
          <T id={error.id}>{intl.formatMessage(error)}</T>
        </p>
        <Button size="sm" onClick={onErrorClose}>
          <T id={messages.closeButton.id}>{intl.formatMessage(messages.closeButton)}</T>
        </Button>
      </PopoverBody>
    </Popover>
  );
};
