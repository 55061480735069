import { BasicChecklist, Signature } from 'api/interfaces';
import { useMySystemSettings } from 'components-ts/HealthSystems';
import { ChartContextValue, ChartContext } from 'components-ts/Routing/WDContentProvider';
import { Nullable } from 'components-ts/utils';
import { useContext } from 'react';
import { MessageDescriptor } from 'react-intl';

export interface ChecklistSubscriptionsContextValue {
  checklists: Array<BasicChecklist>;
  getChecklist: (id: string) => Nullable<BasicChecklist>;
  isLoading: boolean;
  error: Nullable<MessageDescriptor>;
  updated: Nullable<Signature>;
}

export const useChecklistSubscriptionsContext = () => {
  const ctx = useContext(ChartContext);
  validateContextValue(ctx);

  const { data, isLoading, error } = ctx;

  const { subscriptions } = useMySystemSettings();
  const updated = subscriptions.checklists.updated;

  const getChecklist = (id: string): Nullable<BasicChecklist> => {
    if (!data || !Array.isArray(data?.checklists?.docs)) {
      return null;
    }

    const found = data.checklists.docs.find((checklist) => checklist.id === id);

    return found ?? null;
  };

  const value: ChecklistSubscriptionsContextValue = {
    checklists: data?.checklists?.docs ?? [],
    updated,
    getChecklist,
    error,
    isLoading,
  };

  return value;
};

function validateContextValue(value: Nullable<ChartContextValue>): asserts value is ChartContextValue {
  if (value === null) {
    throw TypeError('Using checklists out of the provider');
  }
}
