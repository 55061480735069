import React from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useSchedulerContext } from '../useSchedulerContext';
import { EventColor, EventType } from './utils';
import { Button } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
const eventTypeTranslations: Record<EventType, MessageDescriptor> = {
  [EventType.IN_PERSON]: {
    id: 'VisitCalendar.label_in_person',
    defaultMessage: 'In Person',
  },
  [EventType.TELEHEALTH]: {
    id: 'VisitCalendar.label_telehealth',
    defaultMessage: 'Telehealth',
  },
  [EventType.PERSONAL_APPOINTMENT]: {
    id: 'VisitCalendar.label_personal_events',
    defaultMessage: 'Personal events',
  },
};

const messages = defineMessages(eventTypeTranslations);
const otherMessages = defineMessages({
  filter: {
    id: 'UI.label_filter',
    defaultMessage: 'Filter: ',
  },
});

export const EventFilter: React.VFC = () => {
  const intl = useIntl();

  const { eventFilter, onEventFilterChange } = useSchedulerContext();
  return (
    <div className={'d-flex mt-3 justify-content-start align-items-center'}>
      <span className="mr-2">
        <T id={otherMessages.filter.id}>{intl.formatMessage(otherMessages.filter)}</T>
      </span>
      {Object.values(EventType).map((type) => {
        const onChange = (isSelected) => {
          const newFilter = {
            ...eventFilter,
            [type]: !isSelected,
          };

          onEventFilterChange(newFilter);
        };

        const isSelected = eventFilter[type];
        const style = isSelected
          ? {
              borderColor: EventColor[type],
              background: EventColor[type],
              color: 'white',
              fontWeight: 'bold',
            }
          : {
              borderColor: EventColor[type],
              borderWidth: '1px',
              color: EventColor[type],
            };

        return (
          <Button
            key={type}
            className="mr-1 btn-ghost-primary  btn-sm"
            onClick={(e) => {
              onChange(isSelected);
              e.currentTarget?.blur();
            }}
            style={style}
          >
            <T id={messages[type].id}>{intl.formatMessage(messages[type])}</T>
          </Button>
        );
      })}
    </div>
  );
};
