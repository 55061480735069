import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import Highlighter from 'react-highlight-words';
import { FaCheckCircle as CheckIcon } from 'react-icons/fa';
import { useLabSubscriptions } from 'components-ts/Lab/useLabSubscriptions';
import { ErrorViewer, Nullable, ClickableItemWrapper, LastUpdated } from 'components-ts';
import { BasicStudyTemplate, FullChecklistEntry } from 'api/interfaces';
import { useDynamicTranslation } from 'components-ts/i18n';
import { useAddStudyOrder } from './useStudyOrder';
import { LoadingInline } from 'components-ts/Loading';
import { usePatientContext } from 'components-ts/Patients/usePatientContext';
import { ScrollableList } from 'components-ts/ScrollableList';

const messages = defineMessages({
  orderNewStudy: {
    id: 'StudyOrderModal.order_new_study',
    defaultMessage: 'Order new study',
  },
  searchStudies: {
    id: 'StudyOrderModal.search_studies',
    defaultMessage: 'Search studies',
  },
  orderButton: {
    id: 'StudyOrderModal.order',
    defaultMessage: 'Order',
  },
  comment: {
    id: 'StudyOrderModal.comment',
    defaultMessage: 'Comment',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
});

const ITEMS_PER_PAGE = 10;
type TranslatedStudy = BasicStudyTemplate & { translatedName: string; translatedDescription: Nullable<string> };

interface StudyOrderModalProps {
  patientId: string;
  guideline?: FullChecklistEntry;
  isOpen?: boolean;
  onClose?: () => void;
  onCompleted?: () => void;
}

export const StudyOrderModal: React.FC<StudyOrderModalProps> = (props) => {
  const { patientId, guideline, isOpen, onClose, onCompleted } = props;

  const intl = useIntl();

  const [templateId, setTemplateId] = React.useState<Nullable<string>>(null);
  const [orderTitle, setOrderTitle] = React.useState<Nullable<string>>(null);
  const [comment, setComment] = React.useState<string>('');

  const { activeVisit } = usePatientContext();
  const visitId = activeVisit?.id ?? '';

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setComment(value);
  };

  const onSelect = (template: Nullable<TranslatedStudy>) => {
    setTemplateId(template?.id ?? null);
    setOrderTitle(template?.translatedName ?? null);
  };

  const { onSubmit, isLoading: adding, error: orderingError } = useAddStudyOrder({ patientId, visitId, onCompleted });

  const onOrdered = () => {
    if (!templateId || !orderTitle) {
      return;
    }

    const studyOrder = {
      guideline,
      comment,
      templateId,
      orderTitle,
    };

    onSubmit(studyOrder);
  };

  const { translateDynamic } = useDynamicTranslation();

  const initialFilter = translateDynamic(guideline?.name?.text ?? '') ?? '';
  const { studies, isLoading, updated, error: searchingError } = useLabSubscriptions({ skip: !isOpen });

  const translatedAndSortedStudies: Array<TranslatedStudy> = React.useMemo(() => {
    const translated = studies.map((study) => {
      const { name, description } = study;

      const translatedName = translateDynamic(name?.text ?? '');
      const translatedDescription = translateDynamic(description?.text ?? '');

      return {
        ...study,
        translatedName: translatedName ?? name.text ?? '',
        translatedDescription: translatedDescription ?? description?.text ?? null,
      };
    });

    const sorted = translated.sort((a, b) => {
      if (a.translatedName > b.translatedName) {
        return 1;
      }
      if (b.translatedName > a.translatedName) {
        return -1;
      }

      return 0;
    });

    return sorted;
  }, [studies, translateDynamic]);

  const error = searchingError ?? orderingError;
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <T id={messages.orderNewStudy.id}>{intl.formatMessage(messages.orderNewStudy)}</T>
      </ModalHeader>
      <ModalBody>
        <ScrollableList
          searcherPlaceholder={intl.formatMessage(messages.searchStudies)}
          items={translatedAndSortedStudies}
          itemsToShow={ITEMS_PER_PAGE}
          searchableProperties={['translatedName', 'translatedDescription']}
          initialSearch={initialFilter}
          isLoading={isLoading}
          isURLSync={false}
          renderItem={(item, _index, searchTerms) => (
            <CustomItem
              key={item.id}
              searchTerms={searchTerms}
              study={item}
              isSeledted={templateId === item.id}
              onSelect={onSelect}
            />
          )}
        />
        <Input
          value={comment}
          onChange={onCommentChange}
          placeholder={intl.formatMessage(messages.comment)}
          maxLength={120}
          autoComplete="off"
          autoFocus
          disabled={isLoading}
        />

        {error && <ErrorViewer error={error} />}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="primary" disabled={!templateId || isLoading} onClick={onOrdered}>
          <T id={messages.orderButton.id}>{intl.formatMessage(messages.orderButton)}</T>
          {adding && <LoadingInline className="ml-1" />}
        </Button>
        {updated && <LastUpdated signature={updated} />}
      </ModalFooter>
    </Modal>
  );
};

interface CustomItemProps {
  study: TranslatedStudy;
  isSeledted: boolean;
  onSelect: (id: Nullable<TranslatedStudy>) => void;
  searchTerms: any;
}
const CustomItem: React.FC<CustomItemProps> = (props) => {
  const { study, isSeledted, onSelect, searchTerms } = props;
  const { translatedName, translatedDescription } = study;

  const onClick = () => {
    onSelect(isSeledted ? null : study);
  };
  const className = `d-flex align-items-center justify-content-between ${isSeledted ? 'bg-light' : ''}`;
  return (
    <ClickableItemWrapper onClick={onClick} className={className}>
      <div className="d-flex flex-column  flex-wrap">
        <span className="d-flex align-items-center">
          <Highlighter
            className="text-capitalize font-weight-bold"
            searchWords={searchTerms}
            autoEscape
            textToHighlight={translatedName}
          />
        </span>
        <span className="small text-muted">{translatedDescription}</span>
      </div>
      {isSeledted && <CheckIcon className="text-success h4 m-0" />}
    </ClickableItemWrapper>
  );
};
