import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { GET_DOCTORS } from '../scenes/Reports/requests'
/**
 * this hook will handle a single scheduled visit using the ID
 * @param {
  *   queryVariables: { location: [String] },
  *   queryOptions: { onCompleted, onError, ... }
  * }
  *
  */
function useDoctors(queryVariables, queryOptions) {
  // loading doctors
  const { location } = queryVariables
  const { loading, data, error } = useQuery(GET_DOCTORS, {
    variables: {
      location
    },
    ...queryOptions
  })

  // get by ID
  const getDoctor = useCallback((id) => {
    if (!data || !data.doctors) return null
    return data.doctors.docs.find(system => system.id === id)
  }, [data])

  return {
    loading,
    doctors: (data && data.doctors) ? data.doctors.docs : [],
    count: (data && data.doctors) ? data.doctors.count : 0,
    error,
    getDoctor
  }
}
export default useDoctors
