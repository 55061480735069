import React from 'react'
const debug = require('debug')('wd:Breadcrumb')

const Breadcrumb = () => {
  debug('loaded')
  return <ol className='breadcrumb'>
    <li className='breadcrumb-item'>A</li>
    <li className='breadcrumb-item'>B</li>
    <li className='breadcrumb-item active'>C</li>

    {/* <li className='breadcrumb-menu d-md-down-none'>
      <div className='btn-group' role='group' aria-label='Button group'>
      <a className='btn' href='#'><i className='icon-speech'></i></a>
      <a className='btn' href='./'><i className='icon-graph'></i> &nbsp;Dashboard</a>
      <a className='btn' href='#'><i className='icon-settings'></i> &nbsp;Settings</a>
      </div>
    </li> */}
  </ol>
}
Breadcrumb.default = {}
export default Breadcrumb
