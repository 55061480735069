import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LoadingInline } from 'components-ts';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { Nullable } from './utils';
import { ErrorViewer } from './ErrorViewer';

// static translations
const messages = defineMessages({
  confirmation: {
    id: 'ConfirmationModal.title',
    defaultMessage: 'Confirmation',
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel',
  },
  confirmButton: {
    id: 'UI.button_confirm',
    defaultMessage: 'Confirm',
  },
});

type ConfirmationModalProps = {
  message: MessageDescriptor;
  error: Nullable<MessageDescriptor>;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
  className?: string;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const { onClose, onConfirm, message, error, loading, className = 'btn-danger' } = props;

  const intl = useIntl();

  const onClick = () => {
    onConfirm();
  };
  return (
    <Modal isOpen backdrop toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <T id={messages.confirmation.id}>{intl.formatMessage(messages.confirmation)}</T>
      </ModalHeader>
      <ModalBody>
        <T id={message.id}>{intl.formatMessage(message)}</T>
        {error && <ErrorViewer error={error} />}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" className="mr-1" onClick={onClose}>
          <T id={messages.cancelButton.id}>{intl.formatMessage(messages.cancelButton)}</T>
        </Button>
        <Button type="button" onClick={onClick} className={className}>
          <T id={messages.confirmButton.id}>{intl.formatMessage(messages.confirmButton)}</T>
          {loading && <LoadingInline className="ml-1" />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
