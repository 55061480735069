import { MutationHookOptions, useMutation } from '@apollo/client';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import {
  SUBSCRIBE_TO_CHECKLISTS_TAG,
  SUBSCRIBE_TO_CHECKLISTS_TAG_BATCH,
  UNSUBSCRIBE_FROM_CHECKLISTS_TAG,
} from 'api/request/systems';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { HealthSystem } from 'api/interfaces';

const messages = defineMessages({
  errorSubscribingToChecklists: {
    id: 'UseChecklistsTags.could_not_subscribe_to_tags',
    defaultMessage: 'Could not subscribe to tags. Please, retry.',
  },
  errorUnsubscribingFromChecklists: {
    id: 'UseChecklistsTags.could_not_unsubscribe_from_tags',
    defaultMessage: 'Could not unsubscribe to tags. Please, retry.',
  },
  tagNotFound: {
    id: 'UseChecklistsTags.invalid_tag_found',
    defaultMessage: 'Tag not found',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

interface UseSubscribeToChecklistTagData {
  subscribeToChecklistTagTemplate: HealthSystem;
}

interface UseSubscribeToChecklistTagVariables {
  systemId: string;
  locationId: string;
  tag: string;
}

type UseSubscribeToChecklistTagParams = MutationHookOptions<
  UseSubscribeToChecklistTagData,
  UseSubscribeToChecklistTagVariables
> & {
  systemId: string;
  locationId: string;
};

export const useSubscribeToChecklistTag = (params: UseSubscribeToChecklistTagParams) => {
  const { systemId, locationId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_subscribe_from_checklist_tags':
        return setError(messages.errorSubscribingToChecklists);
      case 'invalid_tag_found':
        return setError(messages.tagNotFound);
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToChecklistTag, { loading: isLoading }] = useMutation<
    UseSubscribeToChecklistTagData,
    UseSubscribeToChecklistTagVariables
  >(SUBSCRIBE_TO_CHECKLISTS_TAG, { ...restParams, onError, onCompleted });

  const onSubmit = (tag: string) => {
    subscribeToChecklistTag({
      variables: {
        systemId,
        locationId,
        tag,
      },
    });
  };

  return {
    onSubmit,
    subscribeToChecklistTag,
    isLoading,
    error,
  };
};

interface UseUnsubscribeFromChecklistTagData {
  unsubscribeFromChecklistTag: HealthSystem;
}

interface UseUnsubscribeFromChecklistTagVariables {
  systemId: string;
  locationId: string;
  tag: string;
}

type UseUnsubscribeFromChecklistTagParams = MutationHookOptions<
  UseUnsubscribeFromChecklistTagData,
  UseUnsubscribeFromChecklistTagVariables
> & { systemId: string; locationId: string };

export const useUnsubscribeFromChecklistTag = (params: UseUnsubscribeFromChecklistTagParams) => {
  const { systemId, locationId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_unsubscribe_from_checklist_tags':
        return setError(messages.errorUnsubscribingFromChecklists);
      case 'invalid_tag_found':
        return setError(messages.tagNotFound);
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (restParams.onCompleted) {
      restParams.onCompleted(data);
    }
  };

  const [unsubscribeFromChecklistTag, { loading: isLoading }] = useMutation<
    UseUnsubscribeFromChecklistTagData,
    UseUnsubscribeFromChecklistTagVariables
  >(UNSUBSCRIBE_FROM_CHECKLISTS_TAG, {
    ...restParams,
    onError,
    onCompleted,
  });

  const onSubmit = (tag: string) => {
    unsubscribeFromChecklistTag({
      variables: {
        systemId,
        locationId,
        tag,
      },
    });
  };

  return {
    onSubmit,
    isLoading,
    error,
  };
};

interface UseSubscribeToChecklistBatchData {
  subscribeToChecklistBatch: HealthSystem;
}

interface UseSubscribeToChecklistBatchVariables {
  systemId: string;
  locationId: string;
  ids: Array<string>;
}

type UseSubscribeToChecklistBatchParams = MutationHookOptions<
  UseSubscribeToChecklistBatchData,
  UseSubscribeToChecklistBatchVariables
> & {
  systemId: string;
  locationId: string;
};

export const useSubscribeToChecklistBatch = (params: UseSubscribeToChecklistBatchParams) => {
  const { systemId, locationId, ...restParams } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_subscribe_from_checklist_tags':
        return setError(messages.errorSubscribingToChecklists);
      case 'invalid_tag_found':
        return setError(messages.tagNotFound);
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToChecklistBatch, { loading: isLoading }] = useMutation<
    UseSubscribeToChecklistBatchData,
    UseSubscribeToChecklistBatchVariables
  >(SUBSCRIBE_TO_CHECKLISTS_TAG_BATCH, { ...restParams, onError, onCompleted });

  const onSubmit = (ids: Array<string>) => {
    subscribeToChecklistBatch({
      variables: {
        systemId,
        locationId,
        ids,
      },
    });
  };

  return {
    onSubmit,
    isLoading,
    error,
  };
};
