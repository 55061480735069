import { MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { StoreStatus } from 'api/interfaces';
import { GET_STORE_STATUS, SET_STORE_UP } from 'api/request/systems';
import { Nullable, extractFirstErrorCode } from 'components-ts/utils';
import { useState } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';

const messages = defineMessages({
  checkingStatusError: {
    id: 'SystemStore.checking_store_status_error',
    defaultMessage: 'Could not check for the store status. Please, try again',
  },
  settingStatusError: {
    id: 'SystemStore.setting_up_store_error',
    defaultMessage: 'Could not configure your store. Please, try again',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

interface UseSetStoreUpData {
  setStoreUp: StoreStatus;
}

interface UseSetStoreUpVariables {
  systemId: string;
}

type UseSetStoreUpParams = MutationHookOptions<UseSetStoreUpData, UseSetStoreUpVariables> & { systemId: string };

export const useSetStoreUp = (params: UseSetStoreUpParams) => {
  const { systemId, ...rest } = params;
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onCompleted = (result) => {
    setError(null);

    if (rest.onCompleted) rest.onCompleted(result);
  };

  // error handler
  const onError = (graphQLErrors) => {
    const errorCode = extractFirstErrorCode(graphQLErrors);

    switch (errorCode) {
      case 'error_checking_for_store_status':
        return setError(messages.checkingStatusError);

      case 'could_not_set_up_system_store':
        return setError(messages.settingStatusError);

      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  // gql mutations
  const [onSetStore, { loading: isLoading }] = useMutation<UseSetStoreUpData, UseSetStoreUpVariables>(SET_STORE_UP, {
    onCompleted,
    onError,
  });

  const createStore = () => onSetStore({ variables: { systemId } });

  return {
    createStore,
    isLoading,
    error,
  };
};

interface UseStoreStatusData {
  storeStatus: StoreStatus;
}

interface UseStoreStatusVariables {
  systemId: string;
}

type UseStoreStatusParams = QueryHookOptions<UseStoreStatusData, UseSetStoreUpVariables> & { systemId: string };

export const useStoreStatus = (params: UseStoreStatusParams) => {
  const { systemId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlErrors) => {
    const errorCode = extractFirstErrorCode(graphqlErrors);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (rest.onCompleted) rest.onCompleted(data);
  };

  const { data, loading: isLoading } = useQuery<UseStoreStatusData, UseStoreStatusVariables>(GET_STORE_STATUS, {
    ...rest,
    variables: { systemId },
    onCompleted,
    onError,
  });

  return {
    status: data?.storeStatus ?? null,
    isLoading,
    error,
  };
};
