import { FullComment, Signature, TranslatableText } from './common';
import { Nullable } from 'components-ts/utils';
import { FullListItem } from './lists';
import { BasicPatient, FullPatientWithoutId } from './patients';
import { FullVitals } from './triage';
import { FullChecklist } from './checklists';
import { FullExamWithoutId } from './exams';
import { BasicStudyWithOrder, FullStudyOrder } from './studies';
import { FullProcedureOrder } from './procedures';
import { ActiveVisitHistory, FullHistory } from './history';
import { StoredRx } from './rxs';
import { Admission } from './admissions';
import { Note } from './notes';
import { ICD10 } from 'components-ts/ICD10/useICD10s';

export enum VisitType {
  GENERAL_PRACTITIONER = 'General Practitioner',
  OBGYN = 'OBGYN',
  ANTENATAL_CARE = 'Antenatal Care',
  PEDIATRICIAN = 'Pediatrician',
  LACTATION_COUNSELING = 'Lactation Counseling',
  FAMILY_PLANNING = 'Family Planning',
  INTERNIST = 'Internist',
  INMUNIZATION = 'Immunization',
  DENTAL = 'Dental',
  TESTING = 'Testing',
  OTHER = 'Other',
  MIDWIFE = 'Midwife',

  // deprecated
  SICK_VISIT = 'Sick Visit',
  FOLLOW_UP = 'Follow-up',
  CHIILD_HEALTH = 'Child Health',
  DELIVERY = 'Delivery',
  PHYSICAL_EXAM = 'Physical Exam',
  TRANSFER = 'Transfer',
  MEDICAL_CLEARANCE = 'Medical Clearance',
}

// These ones were depreecated
export enum DeprecatedVisitType {
  SICK_VISIT = 'Sick Visit',
  FOLLOW_UP = 'Follow-up',
  CHIILD_HEALTH = 'Child Health',
  DELIVERY = 'Delivery',
  PHYSICAL_EXAM = 'Physical Exam',
  TRANSFER = 'Transfer',
  MEDICAL_CLEARANCE = 'Medical Clearance',
}

export enum ClinicalStatusPriority {
  ROUTINE = 'Routine',
  GUARDED = 'Guarded',
  EMERGENCY = 'Emergency',
  DECEASED = 'Deceased',
}

export enum HowLong {
  JUST_NOW = 'Just now',
  AN_HOUR = 'An hour',
  FEW_HOURS = 'Few hours',
  SEVERAL_HOURS = 'Several hours',
  A_DAY = 'A day',
  FEW_DAYS = 'Few days',
  SEVERAL_DAYS = 'Several days',
  A_WEEK = 'A week',
  FEW_WEEKS = 'Few weeks',
  SEVERAL_WEEKS = 'Several weeks',
  A_MONTH = 'A month',
  FEW_MONTHS = 'Few months',
  SEVERAL_MONTHS = 'Several months',
  A_YEAR = 'A year',
  FEW_YEARS = 'Few years',
  SEVERAL_YEARS = 'Several years',
}

export enum FollowUp {
  TOMORROW = 'Tomorrow',
  TWO_DAYS = '2-3 Days',
  FOUR_DAYS = '4-6 Days',
  WEEK = 'One Week',
  TWO_WEEKS = 'Two Weeks',
  THREE_WEEKS = 'Three Weeks',
  MONTH = 'One Month',
  TWO_MONTHS = 'Two Months',
  THREE_MONTHS = 'Three Months',
  SIX_MONTHS = 'Six Months',
  YEAR = 'One Year',
}

export enum Disposition {
  DISCHARGE_HOME = 'Discharge home',
  OBSERVE = 'Observe',
  INTERNAL_REFERRAL = 'Internal Referral',
  EXTERNAL_REFERRAL = 'External Referral',
  TRANSFER = 'Transfer',
  ADMIT = 'Admit',
}

export enum StatusAssesment {
  STABLE = 'Stable',
  WELL = 'Well',
  GUARDED = 'Guarded',
  CRITICAL = 'Critical',
  DECEASED = 'Deceased',
}

/**
 * We have 5 types of orders
 * Nurse order
 * Communication between doctor and nurses
 * Able to Cancel, acknowledge & comment
 *
 * Study order
 * It implies a Study creation from a template. linked to study
 * Able to Cancel, acknowledge & comment
 *
 * Procedure order
 * It'slinked to a procedure (no extra entry is created)
 * Able to Cancel, acknowledge & comment
 *
 * Medicine order
 * To administer a medicine. rx linked with origin = 'order'
 * Able to Cancel, acknowledge & comment
 *
 * Prescription
 * To prescribe a medicine. rx linked with origin = 'prescription'
 * Only able to cancel
 */
export enum OrderType {
  PRESCRIPTION = 'prescription',
  NURSE_ORDER = 'dr_nurse_order',
  STUDY_ORDER = 'study_order',
  MEDICINE_ORDER = 'medicine_order',
  PROCEDURE_ORDER = 'procedure_order',
}

export interface Characteristic {
  id: string;
  list: Nullable<FullListItem>;
  values: Nullable<Array<FullListItem>>;
}

export interface Complaints {
  id: string;
  key: number;
  type: string;
  name: TranslatableText;
  note: Nullable<string>;
  degree: Nullable<FullListItem>;
  characteristics: Nullable<Array<Characteristic>>;
  timings: Nullable<Array<FullListItem>>;
  bodyParts: Nullable<Array<FullListItem>>;
  positions: Nullable<Array<FullListItem>>;
}

export interface ChiefComplaint {
  complaint: Nullable<string>;
  how_long: Nullable<HowLong>;
  followUp: Nullable<string>;
}

export interface NewChiefComplaint {
  complaint: Nullable<string>;
  howLong: Nullable<HowLong>;
}

export interface ClinicalStatus {
  priority: Nullable<ClinicalStatusPriority>;
}
export interface Assessment {
  id: Nullable<string>;
  type: string;
  description: string;
  created: Nullable<Signature>;
}

export interface Treatment {
  id: Nullable<string>;
  type: string;
  treatment: string;
  created: Nullable<Signature>;
}

export interface MedicineOrder {
  id: string;
  rxId: string;
  type: OrderType;
  order: FullComment;
  acknowledged: Nullable<boolean>;
  canceled: Nullable<boolean>;
  recurringAdministrationId: Nullable<string>;
  administered: Nullable<Signature>;
  filled: Nullable<boolean>;
  comments: Nullable<Array<FullComment>>;
  updated: Nullable<Signature>;
}

export interface DrNurseOrder {
  id: string;
  type: OrderType;
  order: FullComment;
  acknowledged: Nullable<boolean>;
  canceled: Nullable<boolean>;
  comments: Nullable<Array<FullComment>>;
}

export interface PastVisit {
  id: string;
  start: string;
  end: string;
  type: VisitType;
  chief_complaint: Nullable<ChiefComplaint>;
  assessments: Nullable<Array<Assessment>>;
  treatments: Nullable<Array<Treatment>>;
  diagnoses: Array<FullChecklist>;
  admission: Nullable<Admission>;
}

export enum StandingOrderDiet {
  CLEARS = 'clears',
  HILAL = 'hilal',
  KOSHER = 'kosher',
  LIQUID = 'liquid',
  NPO = 'npo',
  PUREED = 'pureed',
  REGULAR = 'regular',
  VEGAN = 'vegan',
  VEGETARIAN = 'vegetarian',
}

export enum StandingOrderIV {
  BOLUS = 'bolus',
  DC = 'dc',
  FLUSH = 'flush',
  TKO = 'tko',
  MAINTENANCE = 'maintenance',
}

export enum StandingOrderVitalSigns {
  DONOTDISTURB = 'doNotDisturb',
  Q1H = 'q1h',
  Q2H = 'q2h',
  Q4H = 'q4h',
}

export enum StandingOrderO2 {
  BIPAP = 'bipap',
  CPAP = 'cpap',
  HIGHFLOW = 'highFlow',
  MASK = 'mask',
  NC = 'nc',
  OVER91 = 'over91',
}

export enum StandingOrderVisitors {
  FAMILYONLY = 'familyOnly',
  NOVISITORS = 'noVisitors',
  UNRESTRICTED = 'unrestricted',
}

export enum StandingOrderMobility {
  ASSIST = 'assist',
  BED = 'bed',
  CANE = 'cane',
  CRUTCHES = 'crutches',
  WALK = 'walk',
  WALKER = 'walker',
  WATCH = 'watch',
  WHEELCHAIR = 'wheelChair',
}

export enum StandingOrderHygiene {
  FULL_ASSIST = 'fullAssist',
  PARTIAL_ASSIST = 'partialAssist',
  SUPERVISION = 'supervision',
  UNASSISTED = 'unassisted',
}

export enum StandingOrderToileting {
  BED_PAN = 'bedPan',
  DIAPERS = 'diapers',
  COMMODE = 'commode',
  FOLEY = 'foley',
  PORTABLE = 'portable',
  STRAIGHT_CATHETERIZATION = 'straightCatheterization',
}

export enum StandingOrderEating {
  FULL_ASSIST = 'fullAssist',
  PARTIAL_ASSIST = 'partialAssist',
  UNASSISTED = 'unassisted',
}

export interface StandingOrder {
  diet: Nullable<StandingOrderDiet>;
  iv: Nullable<StandingOrderIV>;
  vitalSigns: Nullable<StandingOrderVitalSigns>;
  o2: Nullable<StandingOrderO2>;
  visitors: Nullable<StandingOrderVisitors>;
  mobility: Nullable<StandingOrderMobility>;
  hygiene: Nullable<StandingOrderHygiene>;
  toileting: Nullable<StandingOrderToileting>;
  eating: Nullable<StandingOrderEating>;
  updated: Signature;
}

/**
 * Ids
 */
interface VisitRXs {
  prescriptions: Nullable<Array<string>>;
  orders: Nullable<Array<string>>;
}
export interface ActiveVisit {
  id: string;
  type: VisitType;
  location: string;
  start: string;
  end: string;
  room: Nullable<string>;
  backdated: Nullable<boolean>;
  chief_complaint: Nullable<ChiefComplaint>;
  clinical_status: Nullable<ClinicalStatus>;
  vitals: Nullable<FullVitals>;

  history: Nullable<ActiveVisitHistory>;
  exam: FullExamWithoutId;

  status_assesment: Nullable<StatusAssesment>;
  disposition: Nullable<Disposition>;
  followup: Nullable<FollowUp>;
  external_referral_location: Nullable<string>;
  external_referral_reason: Nullable<string>;

  procedure_orders: Nullable<Array<FullProcedureOrder>>;
  study_orders: Nullable<Array<FullStudyOrder>>;
  medicine_orders: Nullable<Array<MedicineOrder>>;
  dr_nurse_orders: Nullable<Array<DrNurseOrder>>;

  checklists: Array<FullChecklist>;
  rx: Nullable<VisitRXs>;
  assessments: Nullable<Array<Assessment>>;
  treatments: Nullable<Array<Treatment>>;
  progress_notes: Nullable<Array<FullComment>>;
  notes: Nullable<Array<Note>>;

  patient: Nullable<BasicPatient>;
  signature: Nullable<Signature>;
  updated: Nullable<Signature>;
  exam_started_by: Nullable<Signature>;
  completedBy: Nullable<Signature>;

  telehealth: Nullable<boolean>;
  waitingNumber: Nullable<number>;
  admission: Nullable<Admission>;
  standingOrder: Nullable<StandingOrder>;
}

export interface FullVisit extends ActiveVisit {
  patient: BasicPatient;
}

export interface PastVisitRx {
  orders: Nullable<Array<StoredRx>>;
  prescriptions: Nullable<Array<StoredRx>>;
}

export interface FullPastVisit {
  id: string;
  type: VisitType;
  location: string;
  start: string;
  end: string;
  room: Nullable<string>;
  backdated: Nullable<boolean>;
  chief_complaint: Nullable<ChiefComplaint>;
  clinical_status: Nullable<ClinicalStatus>;
  vitals: Nullable<FullVitals>;

  history: Nullable<ActiveVisitHistory>;
  exam: FullExamWithoutId;

  status_assesment: Nullable<StatusAssesment>;
  disposition: Nullable<Disposition>;
  followup: Nullable<FollowUp>;
  external_referral_location: Nullable<string>;
  external_referral_reason: Nullable<string>;

  procedure_orders: Nullable<Array<FullProcedureOrder>>;
  study_orders: Nullable<Array<FullStudyOrder>>;
  medicine_orders: Nullable<Array<MedicineOrder>>;
  dr_nurse_orders: Nullable<Array<DrNurseOrder>>;

  checklists: Array<FullChecklist>;
  assessments: Nullable<Array<Assessment>>;
  treatments: Nullable<Array<Treatment>>;
  progress_notes: Nullable<Array<FullComment>>;
  notes: Nullable<Array<Note>>;

  rx: Nullable<PastVisitRx>;

  patient: BasicPatient;
  signature: Nullable<Signature>;
  exam_started_by: Signature;
  completedBy: Signature;

  telehealth: Nullable<boolean>;
  admission: Nullable<Admission>;
  icd10s: Nullable<Array<ICD10>>;
}

export interface FullPastVisitWithHistory {
  visit: FullPastVisit;
  patientHistory: FullHistory;
}

export interface WaitingPatientVisit {
  exam_started_by: Nullable<Signature>;
  telehealth: Nullable<boolean>;
  waitingNumber: Nullable<number>;
  clinical_status: Nullable<ClinicalStatus>;
  id: string;
  type: VisitType;
  location: string;
  start: string;
  room: Nullable<string>;
  backdated: Nullable<boolean>;
  study_orders: Nullable<Array<FullStudyOrder>>;
  admission: Nullable<Admission>;
}

export interface BillVisit {
  id: string;
  type: VisitType;
  location: string;
  start: string;
  studies: Nullable<Array<BasicStudyWithOrder>>;
  procedure_orders: Nullable<Array<FullProcedureOrder>>;
  patient: FullPatientWithoutId;
  rx: Nullable<Array<StoredRx>>;
  checklists: Array<FullChecklist>;
  assessments: Nullable<Array<Assessment>>;
}
