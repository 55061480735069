import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Card, CardHeader, Collapse } from 'reactstrap'
import { FiChevronDown } from 'react-icons/fi'

const CollapseCard = (props) => {
  const {
    className,
    children,
    visible,
    toggle: controlledToggle,
    title,
    defaultOpen,
    readOnly
  } = props
  const [isOpen, setIsOpen] = useState(false || visible || defaultOpen)

  const titleContent =
    typeof title === 'object' ? (
      title
    ) : (
      <h5 className='m-0' onClick={() => setIsOpen(!isOpen)}>
        {title}
      </h5>
    )

  const toggle = controlledToggle || (() => setIsOpen(!isOpen))

  return (
    <div className={className}>
      {readOnly ? (
        titleContent
      ) : (
        <Card className='mb-0 pointer'>
          <CardHeader
            className='d-flex justify-content-between align-items-center px-3 py-2'
            onClick={toggle}
          >
            {titleContent}
            <FiChevronDown
              style={{
                transform: !isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: 'all 0.3 linear'
              }}
            />
          </CardHeader>
        </Card>
      )}
      <Collapse isOpen={isOpen || visible}><div className='mt-2'>{children}</div></Collapse>
    </div>
  )
}

export default injectIntl(CollapseCard)
