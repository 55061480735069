import React from 'react';
import { IntlProvider } from 'react-intl';
import { useAuthTranslations, useUnauthTranslations } from './useTranslations';
import { guessLocale, DEFAUL_LOCALE, isSupportedLocale, Locales } from './utils';
import { getAuthUri, getUnAuthUri } from '../../api/utils';
import { AccountStatus } from '../Auth/subscription';
import { useSession } from '../Auth';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import id from 'date-fns/locale/id';
import fr from 'date-fns/locale/fr';

registerLocale(Locales.ID_ID, id);
registerLocale(Locales.ES_MX, es);
registerLocale(Locales.FR_FR, fr);

/**
 *  How to use Static translations:
 *
 *  1. Define a messages object in your component
 *
 *  const messages = defineMessages({
 *    yourMessage: {
 *      id: '[PREFIX].message_Id',
 *      defaultMessage: 'The message written in English (en-US)'
 *   }, ...)
 *
 *  2. Import and use TranslationWrapper in your component
 *
 *  import { TranslationWrapper as T } from 'components-ts/Translations'
 *  <T id={messages.yourMessage.id}>{formatMessage(messages.yourMessage)}</T>
 *
 *  Known shared Prefixes:
 *  UI: for common UI objects (such as: buttons, dropdowns, etc.)
 *  NonStaffUI: for UI elements accessible without login
 *
 *  How to use Dynamic Translations:
 *
 *  1. Import <DynamicTranslation /> and use it in your component
 *
 *  <DynamicTranslation text={text} />
 */

export const TranslationProvider: React.FC = (props) => {
  const { isBooting, isLoggedIn, getAccountStatus } = useSession();

  if (isBooting()) {
    return null;
  }

  if (isLoggedIn()) {
    const accountStatus = getAccountStatus();
    if (accountStatus === AccountStatus.READ_ONLY_ACCESS || accountStatus === AccountStatus.FULL_ACCESS) {
      return <AuthTranslationsProvider {...props} />;
    }
  }

  return <UnauthTranslationsProvider {...props} />;
};

const FALLBACK_LOCALE = guessLocale();
const AuthTranslationsProvider: React.FC = (props) => {
  const { getUserLocale, getUserLocation, getToken, getUserSystem } = useSession();

  const userPreferredLocale = (getUserLocale() ?? '').toLowerCase();
  const userLocale = isSupportedLocale(userPreferredLocale) ? userPreferredLocale : FALLBACK_LOCALE;

  const { id: userLocationId } = getUserLocation();
  const { id: userSystemId } = getUserSystem();

  const params = {
    locale: userLocale,
    baseURL: getAuthUri(),
    token: getToken(),
    userSystemId,
    userLocationId,
  };

  const { translations } = useAuthTranslations(params);
  setDefaultLocale(userLocale);

  return (
    <IntlProvider locale={userLocale} defaultLocale={DEFAUL_LOCALE} messages={translations}>
      {props.children}
    </IntlProvider>
  );
};

const UnauthTranslationsProvider: React.FC = (props) => {
  const userLocale = FALLBACK_LOCALE;

  const params = {
    locale: userLocale,
    baseURL: getUnAuthUri(),
  };

  const { translations } = useUnauthTranslations(params);

  return (
    <IntlProvider locale={userLocale} defaultLocale={DEFAUL_LOCALE} messages={translations}>
      {props.children}
    </IntlProvider>
  );
};
