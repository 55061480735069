import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import { FullChecklist, FullStudyOrder } from 'api/interfaces';
import { useSimplifiedDateFromSignatures } from './utils';
import { Nullable } from 'components-ts/utils';
import { OrderStatus } from './OrderStatus';
import { OrderComments } from './OrderComments';
import { DynamicTranslation } from 'components-ts/i18n';
import { OrderActionMenu } from './OrderActions';
import { StudyResultModalButton } from '../StudyResultModal';
import { LastUpdated } from 'components-ts/LastUpdated';
import { ITEM_TYPE } from 'wd-common/src/logic/checklists/definitions';

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Note',
  },
  studiesTitle: {
    id: 'OrderList.studies_title',
    defaultMessage: 'Studies',
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No items found',
  },
  mixLabel: {
    id: 'ResourceList.mix_label',
    defaultMessage: 'Mix',
  },
  mixNotesLabel: {
    id: 'ResourceList.mix_notes_label',
    defaultMessage: 'Mix notes',
  },
  guideline: {
    id: 'RxModal.label_guideline',
    defaultMessage: 'Guideline',
  },
});

interface StudyOrderListProps {
  patientId: string;
  visitId: string;
  orders: Array<FullStudyOrder>;
  checklists?: Array<FullChecklist>;
  isActionable?: boolean;
  showGuideline?: boolean;
  hideCancelled?: boolean;
  className?: string;
}
export const StudyOrderList: React.FC<StudyOrderListProps> = (props) => {
  const { orders, patientId, visitId, isActionable, checklists, showGuideline, hideCancelled, className = '' } = props;

  const intl = useIntl();

  const textEntries = checklists?.map((c) => c.studies?.filter((t) => t.checked && t.type === ITEM_TYPE.TEXT)).flat();

  const hasTextEntries = Array.isArray(textEntries) && textEntries.length > 0;

  const hasActiveStudies = hasTextEntries || orders.some((o) => !o.canceled || o.filled);

  if (hideCancelled && !hasActiveStudies) {
    return null;
  }

  return (
    <div className={`w-100 ${className}`}>
      <h4>
        <T id={messages.studiesTitle.id}>{intl.formatMessage(messages.studiesTitle)}</T>
      </h4>
      <div className="border-top py-1">
        {hasTextEntries && (
          <ul className="m-0 px-4">
            {textEntries.map((entry) =>
              entry && entry.name?.text ? (
                <li className="py-1" key={entry.key}>
                  <DynamicTranslation text={entry.name.text} />
                </li>
              ) : null
            )}
          </ul>
        )}
        {orders?.map((order, index, allOrders) => {
          if (hideCancelled && order.canceled) {
            return null;
          }

          const prevItem = index > 0 ? allOrders[index - 1] : null;
          const key = `${order.id}-${index}`;
          return (
            <Item
              key={key}
              item={order}
              prevItem={prevItem}
              patientId={patientId}
              visitId={visitId}
              isActionable={isActionable}
              showGuideline={showGuideline}
            />
          );
        })}
      </div>
    </div>
  );
};

interface ItemProps {
  patientId: string;
  visitId: string;
  item: FullStudyOrder;
  prevItem: Nullable<FullStudyOrder>;
  isActionable?: boolean;
  className?: string;
  showGuideline?: boolean;
}

const Item: React.FC<ItemProps> = (props) => {
  const { item, prevItem, patientId, visitId, isActionable, showGuideline } = props;

  const intl = useIntl();
  const { date, time } = useSimplifiedDateFromSignatures(item.order.created, prevItem?.order.created);

  return (
    <div className="border-bottom border-light py-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between flex-grow-1 mb-2">
          <div className="d-flex">
            <div style={{ minWidth: 96 }}>
              {date && <p className="m-0">{date}</p>}
              <p className="m-0">{time}</p>
            </div>
            <div className="px-2">
              <b style={{ textDecoration: item.canceled ? 'line-through' : 'none' }}>{item.order.text}</b>
              {showGuideline && item.guideline && (
                <p className="small m-0">
                  <T id={messages.guideline.id} className="mr-1">
                    {intl.formatMessage(messages.guideline)}:{' '}
                  </T>
                  <em>
                    <DynamicTranslation text={item.guideline.name?.text ?? ''} />
                  </em>
                </p>
              )}
            </div>
          </div>
          {item.filled && <StudyResultModalButton className="ml-2" orderId={item.studyId} />}
        </div>
        {isActionable && !item.filled && (
          <OrderActionMenu
            className="section-not-printable"
            patientId={patientId}
            visitId={visitId}
            order={item}
            isCancellable={!item.received}
            isAcknowledgeable
            isCommentable
            isReceivable={!item.canceled}
          />
        )}
      </div>
      <OrderStatus
        className="section-not-printable my-2"
        acknowledged={!!item.acknowledged}
        canceled={!!item.canceled}
        received={!!item.received}
        filled={!!item.filled}
      />
      {Array.isArray(item.comments) && <OrderComments comments={item.comments} className="my-2" />}
      {item.updated && (
        <div className="d-flex justify-content-end">
          <LastUpdated signature={item.updated} />
        </div>
      )}
    </div>
  );
};
