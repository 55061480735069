import { FREQUENCY_SHOW, DEFAULT_RX_DETAILS } from './definitions'

export const DRUG_NAME_REGEX = /([^/]+)\s(\d*\.?,?\d+)\s?(\w+)?\s?\/?\s?(\d*\.?,?\d+)?\s?(\D+)?/

export const getDrugInfo = (drug) => {
  const result = drug.name.match(DRUG_NAME_REGEX)

  if (!result) {
    return {
      normalizedName: null,
      topConcentration: null,
      topUnit: null,
      bottomConcentration: null,
      bottomUnit: null,
      drugUnit: '',
      drugStockUnit: '',
      matchNumber: null,
      matchUnit: null
    }
  }

  const [
    _fullString, // eslint-disable-line
    _normalizedName,
    _topConcentration,
    _topUnit,
    _bottomConcentration,
    _bottomUnit] = result

  const normalizedName = typeof _normalizedName === 'string' ? _normalizedName.toLowerCase().trim() : null
  const topConcentration = typeof _topConcentration === 'string' ? parseFloat(_topConcentration.trim().replace(',', '.')) : null
  const topUnit = typeof _topUnit === 'string' ? _topUnit.toLowerCase().trim() : null
  const bottomConcentration = typeof _bottomConcentration === 'string' ? parseFloat(_bottomConcentration.trim().replace(',', '.')) : 1
  const bottomUnit = typeof _bottomUnit === 'string' ? _bottomUnit.toLowerCase().trim() : null
  const drugUnit = drug.unit || ''
  const drugStockUnit = drug.stock_unit || ''

  const matchNumber = topConcentration / (bottomConcentration || 1)
  const matchUnits = topUnit && bottomUnit ? `${topUnit}/${bottomUnit}` : topUnit

  const simpleName = `${normalizedName} ${matchNumber} ${matchUnits}`

  return {
    simpleName,
    normalizedName,
    topConcentration,
    topUnit,
    bottomConcentration,
    bottomUnit,
    drugUnit,
    drugStockUnit,
    matchNumber,
    matchUnits
  }
}

export function underDoseMixDrug (dose) {
  return (Math.floor(dose * 2) / 2).toFixed(1)
}

export function calculateSafeDose (dose, maxDose, underDose) {
  if (maxDose) {
    dose = Math.min(maxDose, dose)
  }

  // Underdose to nearest .5 number - Only for Quickpicks Mix
  const hasDecimalPlaces = dose % 1 !== 0
  if (underDose && hasDecimalPlaces) {
    dose = underDoseMixDrug(dose)
    dose = parseFloat(dose)
  }
  return dose
}

export function adaptivePrecision (number) {
  const num = parseFloat(number)
  if (num < 100) return parseFloat(num.toPrecision(2))
  else return Math.round(num)
}

/**
 * Calculates drug dose from guideline and the patient weight
 * @param {Object} guideline Drug guideline
 * @param {Number} weightKg Patient weight
 * @returns
 */
export function calculateDose (guideline, weightKg) {
  if (!guideline.dose_units) return null

  // General dosage
  const originalUnits = guideline.dose_units
  const warnings = []
  const rangeMin = Math.min(guideline.dose_range_min || 0, guideline.dose_range_max || 0)
  const rangeMax = Math.max(guideline.dose_range_min || 0, guideline.dose_range_max || 0)
  const rangeSpan = rangeMax - rangeMin

  // if ((guideline.dose_range_min || 0) > (guideline.dose_range_max || 0)) {
  //   warnings.push('minimum and maximum ranges were nonsensical. swapped them')
  // }

  // Weight based dosage
  if (guideline.dose_units.includes('/kg')) {
    const finalUnits = guideline.dose_units.replace('/kg', '')

    if (!(weightKg || {}).kg) return null

    const dosePerKg = rangeSpan * 0.5 + rangeMin
    const dose = dosePerKg * weightKg.kg
    const maxDose = String(guideline.max_dose_units).toLowerCase().includes('/kg') ? rangeMax * weightKg.kg : guideline.max_dose
    const underDose = guideline.under_dose
    const safeDose = calculateSafeDose(dose, maxDose, underDose)

    return {
      rangeMin,
      rangeMax,
      rangeSpan,
      dosePerKg,
      dose,
      safeDose,
      originalUnits,
      finalUnits,
      weightKg,
      formula_lines: [
        'dose per kg = 1/2 (range max - range min) + range min',
        'dose = dose per kg * weight in kg'
      ],
      calculation_lines: [
        `${dosePerKg} ${originalUnits} = 0.5 * (${rangeMax} ${originalUnits} - ${rangeMin} ${originalUnits}) + ${rangeMin} ${originalUnits}`,
        `${dose} ${finalUnits} = ${dosePerKg} ${originalUnits} * ${weightKg} kg`
      ],
      finalDose: `${dose} ${finalUnits}`,
      warnings
    }
  } else {
    // No weight
    const finalUnits = guideline.dose_units
    const dose = rangeSpan * 0.5 + rangeMin
    const safeDose = calculateSafeDose(dose, guideline.max_dose, guideline.under_dose)
    return {
      rangeMin,
      rangeMax,
      rangeSpan,
      dose,
      safeDose,
      originalUnits,
      finalUnits,
      weightKg,
      formula_lines: [
        'dose = 1/2 (range max - range min) + range min'
      ],
      calculation_lines: [
        `${dose} ${originalUnits} = 0.5 * (${rangeMax} ${originalUnits} - ${rangeMin} ${originalUnits}) + ${rangeMin} ${originalUnits}`
      ],
      finalDose: `${dose} ${finalUnits}`,
      warnings
    }
  }
}

export const roundDose = number => {
  if (number >= 10) {
    // Values greater or equal to 10 are rounded to integers
    return parseInt(number)
  }
  if (number >= 1 && number < 10) {
    // Values between 1 and 9 are rounded to nearest .5
    return Math.ceil(number * 2) / 2
  }
  // Values minor than 1 rounded to nearest hundredths
  return Math.ceil(number * 100) / 100
}

export const initialCalculation = (guideline, drug, patientWeight) => {
  const details = { ...DEFAULT_RX_DETAILS }

  if ((guideline || {}).route) details.route = guideline.route.text

  if ((guideline || {}).frequency_show) details.frequencyShow = guideline.frequency_show

  if ((guideline || {}).doses_per_day) details.dosesPerDay = guideline.doses_per_day

  if ((guideline || {}).as_needed) details.asNeeded = !!guideline.as_needed

  if ((guideline || {}).days) details.days = guideline.days

  // anyway if the frequency is by doses...
  if (details.frequencyShow === FREQUENCY_SHOW.DOSES) details.days = 0

  if ((guideline || {}).max_doses) details.maxDoses = guideline.max_doses

  const dose = guideline ? calculateDose(guideline, patientWeight) : undefined

  const { matchUnits, matchNumber, normalizedName, drugUnit, drugStockUnit, topUnit, bottomUnit } = getDrugInfo(drug)

  // For cases where we don't have a guideline, take the numerator for the dose
  if (dose && dose.finalUnits) {
    details.doseUnits = dose.finalUnits
  } else if (topUnit) {
    details.doseUnits = topUnit
  } else {
    details.doseUnits = drugUnit
  }

  // For cases where we don't have a guideline, take the matched drug guideline
  if (dose && dose.safeDose) {
    details.doseNumber = dose.safeDose
  } else if (matchNumber) {
    details.doseNumber = matchNumber
  } else {
    details.doseNumber = 0
  }

  // this means we found the drug name
  if (normalizedName) {
    if (drugUnit.toLowerCase() === details.doseUnits.toLowerCase()) {
      details.unitsPerDose = details.doseNumber
      details.unitsPerDoseRaw = details.doseNumber
      details.drugDoseNumber = details.doseNumber
      details.drugDoseUnits = details.doseUnits
    } else if (matchNumber && matchUnits !== '0' && topUnit === details.doseUnits && bottomUnit ? bottomUnit.toLowerCase() === drugUnit.toLowerCase() : true) {
      details.unitsPerDose = matchNumber > 0 ? adaptivePrecision(details.doseNumber / matchNumber) : 0
      details.unitsPerDoseRaw = matchNumber > 0 ? details.doseNumber / matchNumber : 0
      details.drugDoseNumber = matchNumber
      details.drugDoseUnits = matchUnits
    } else {
      details.unitsPerDose = 0
      details.unitsPerDoseRaw = 0
      details.drugDoseNumber = 0
      details.drugDoseUnits = ''
    }
  } else {
    details.unitsPerDose = 0
    details.unitsPerDoseRaw = 0
    details.drugDoseNumber = 0
    details.drugDoseUnits = ''
  }
  const roundedDose = roundDose(details.doseNumber)
  details.doseNumber = isNaN(roundedDose) ? 0 : roundedDose

  /**
    * almost in all cases where medicine is liquid, amount to be dispensed will be ampule, bottle, bag, etc...
    * So I would just default any ml or cc Rx to #1 dispense. In other cases, we've to calculate it
   */
  if (!isLiquidDrug(drugUnit) && drugStockUnit.toLowerCase() === drugUnit.toLowerCase()) {
    details.dispense = calculateDispense(details.unitsPerDose, details.dosesPerDay, details.days, drug)
  }

  return details
}

const isLiquidDrug = drugUnit => ['ml', 'cc'].includes(drugUnit.toLowerCase())

export const calculateDispense = (unitsPerDose, dosesPerDay, days, drug = {}) => {
  if (!unitsPerDose || !dosesPerDay) return 0

  const dispense = days > 0
    ? adaptivePrecision(unitsPerDose * dosesPerDay * days)
    : adaptivePrecision(unitsPerDose * dosesPerDay)

  const minimum = adaptivePrecision(drug.minimum_units_to_dispense)
  let dispenseMinimum = minimum ? minimum * adaptivePrecision(dispense / minimum) : dispense

  // If the calculus above is lower than 'minimum units to dispense', we'll use 'minimum unit to dispense' as dispense value
  dispenseMinimum = dispenseMinimum < minimum ? minimum : dispenseMinimum

  if (dispenseMinimum > 10) {
    // Round up to nearest integer
    const rounded = Math.ceil(dispenseMinimum)
    return isNaN(rounded) ? 0 : rounded
  } else {
    // Round number to 2 decimal places
    const rounded = parseFloat(dispenseMinimum.toPrecision(2))
    return isNaN(rounded) ? 0 : rounded
  }
}
