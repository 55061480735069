import { useEffect } from 'react'
const debug = require('debug')('wd:useKeyEvent')
const useKeyEvent = (event, keyMap, node = document) => {
  const keyHandler = event => keyMap[event.code] && keyMap[event.code](event)
  debug(keyMap)
  // validate event [keydown, keypress...]
  useEffect(() => {
    node.addEventListener(event, keyHandler)
    return () => node.removeEventListener(event, keyHandler)
  })
}

export default useKeyEvent
