import React from 'react';
import { Form, Field } from 'react-final-form';
import { Input, FormGroup, Label, InputGroup, InputGroupText, InputGroupAddon, FormText } from 'reactstrap';
import { defineMessages, useIntl } from 'react-intl';
import { Nullable } from 'components-ts/utils';
import { AuditDrugFormValues } from './useAuditDrugStock';
import { ValidateFn } from 'components-ts/Forms/utils';
import ConfirmationModal from 'components/ConfirmationModal';
import { TranslationWrapper as T } from 'components-ts/Translations';
import createDecorator from 'final-form-calculate';
import { Decorator } from 'final-form';

const messages = defineMessages({
  saveLabel: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
  notesLabel: {
    id: 'label_notes',
    defaultMessage: 'Notes',
  },
  auditMessage: {
    id: 'UI.audit_message',
    defaultMessage: 'This action will permanently change the stock value. Are you sure?',
  },
  newStockLabel: {
    id: 'PharmacyAuditStockForm.new_stock_label',
    defaultMessage: 'New total stock',
  },
  diffLabel: {
    id: 'PharmacyAuditStockForm.diff_label',
    defaultMessage: 'The amount of the new stock entry will be: {diff} {unit}',
  },
  currentStockLabel: {
    id: 'PharmacyAuditStockForm.current_stock_label',
    defaultMessage: 'Current stock: {stock} {unit}',
  },
  containerCountLabel: {
    id: 'label_container_count',
    defaultMessage: 'Container Count',
  },
  containerCountHelp: {
    id: 'PharmacyStock.help_container_count',
    defaultMessage: 'How many containers are there?',
  },
  containerCapacityLabel: {
    id: 'label_stock_units_per_container',
    defaultMessage: 'Stock units per container',
  },
});

type PharmacyAuditStockFormProps = {
  currentStock: number;
  stockUnit: Nullable<string>;
  onSubmit: (values: AuditDrugFormValues) => void;
  validate: ValidateFn<AuditDrugFormValues>;
  error?: Nullable<string>;
  loading?: boolean;
};

export const PharmacyAuditStockForm: React.FC<PharmacyAuditStockFormProps> = (props) => {
  const { onSubmit, validate, error, loading, currentStock, stockUnit } = props;

  const intl = useIntl();

  const disableSubmitOnEnterKey = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const amountDecorator = React.useMemo(
    () =>
      createDecorator(
        {
          field: 'containerCount',
          updates: {
            newTotal: (containerCount, allFields) =>
              Number(containerCount) * Number((allFields as any)?.containerCapacity) || '',
          },
        },
        {
          field: 'containerCapacity',
          updates: {
            newTotal: (containerCapacity, allFields) =>
              Number(containerCapacity) * Number((allFields as any)?.containerCount) || '',
          },
        }
      ) as Decorator<AuditDrugFormValues>,
    []
  );

  return (
    <div>
      <Form
        decorators={[amountDecorator]}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, invalid, pristine, submitting }) => {
          const newTotal = form.getFieldState('newTotal')?.value;
          return (
            <form
              className="d-flex flex-column"
              onSubmit={async (e) => {
                await handleSubmit(e);

                const { valid } = form.getState();

                if (valid) {
                  form.reset();
                }
              }}
            >
              <b>
                <T id={messages.currentStockLabel.id}>
                  {intl.formatMessage(messages.currentStockLabel, { stock: currentStock, unit: stockUnit })}
                </T>
              </b>
              <Field name="containerCount" type="number" parse={Number}>
                {({ input }) => {
                  return (
                    <FormGroup>
                      <Label for={input.name}>{intl.formatMessage(messages.containerCountLabel)}</Label>
                      <Input {...input} disabled={submitting} autoFocus={true} min={0} autoComplete="off" />
                      <FormText color="muted">{intl.formatMessage(messages.containerCountHelp)}</FormText>
                    </FormGroup>
                  );
                }}
              </Field>
              <Field name="containerCapacity" type="number" parse={Number}>
                {({ input }) => {
                  return (
                    <FormGroup>
                      <Label for={input.name}>{intl.formatMessage(messages.containerCapacityLabel)}</Label>
                      <InputGroup>
                        <Input {...input} disabled={submitting} min={0} autoComplete="off" />
                        {stockUnit ? (
                          <InputGroupAddon addonType="append">
                            <InputGroupText>{stockUnit}</InputGroupText>
                          </InputGroupAddon>
                        ) : null}
                      </InputGroup>
                    </FormGroup>
                  );
                }}
              </Field>
              <Field name="newTotal" type="number" parse={Number}>
                {({ input, meta }) => {
                  return (
                    <FormGroup>
                      <Label for={input.name}>
                        <T id={messages.newStockLabel.id}>{intl.formatMessage(messages.newStockLabel)}</T>
                      </Label>
                      <InputGroup>
                        <Input
                          {...input}
                          autoComplete="off"
                          autoFocus={true}
                          min={0}
                          onKeyDown={disableSubmitOnEnterKey}
                          className={meta.error && meta.touched ? 'border border-danger' : ''}
                        />
                        {stockUnit ? (
                          <InputGroupAddon addonType="append">
                            <InputGroupText>{stockUnit}</InputGroupText>
                          </InputGroupAddon>
                        ) : null}
                      </InputGroup>
                      {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                    </FormGroup>
                  );
                }}
              </Field>
              {newTotal && newTotal !== currentStock ? (
                <span className="text-muted">
                  <T id={messages.diffLabel.id}>
                    {intl.formatMessage(messages.diffLabel, {
                      diff: intl.formatNumber((form.getFieldState('newTotal')?.value ?? 0) - currentStock),
                      unit: stockUnit,
                    })}
                  </T>
                </span>
              ) : null}
              <Field name="notes" type="textarea">
                {({ input }) => {
                  return (
                    <FormGroup>
                      <Label for={input.name}>{intl.formatMessage(messages.notesLabel)}</Label>
                      <Input {...input} autoComplete="off" />
                    </FormGroup>
                  );
                }}
              </Field>
              <div>
                <ConfirmationModal
                  action={<T id={messages.saveLabel.id}>{intl.formatMessage(messages.saveLabel)}</T>}
                  className=" mt-2"
                  onConfirm={handleSubmit}
                  loading={loading}
                  message={intl.formatMessage(messages.auditMessage)}
                  error={error}
                  disabled={invalid || pristine || newTotal === currentStock}
                />
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
