import React from 'react';
import { Footer, FooterProps } from './Footer';
import { defineMessages, useIntl } from 'react-intl';
import { useSystems } from 'components-ts/HealthSystems';
import { useSetStoreUp, useStoreStatus } from 'components-ts/HealthSystems/useStoreActions';
import { LoadingInline } from 'components-ts/Loading';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { ErrorViewer } from 'components-ts/ErrorViewer';

const messages = defineMessages({
  title: {
    id: 'StorageFirstStep.title',
    defaultMessage: 'Cloud storage',
  },
  description: {
    id: 'StorageFirstStep.description',
    defaultMessage:
      "Let's establish the cloud storage for your system, providing your clinic with a dedicated and secure encrypted storage on Amazon Web Services. This exclusive storage area will guarantee that sensitive data, such as study or lab results, can only be accessed by authorized users within your system. Click the button to setup.",
  },
});

type StorageStepProps = {
  footerProps: FooterProps;
};

export const StorageStep: React.FC<StorageStepProps> = (props) => {
  const { footerProps } = props;

  const intl = useIntl();

  const { getMySystem } = useSystems();
  const system = getMySystem();

  const { status, isLoading: loadingStatus } = useStoreStatus({ systemId: system.id });
  const { createStore, isLoading, error } = useSetStoreUp({ systemId: system.id, onCompleted: footerProps.next });

  const onNext = async () => {
    if (!status?.isReady) {
      await createStore();
    } else {
      footerProps.next();
    }
  };

  return (
    <>
      <div>
        <h3>
          <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
        </h3>
        <p>
          <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
        </p>
        {loadingStatus && <LoadingInline />}
        {error && <ErrorViewer error={error} />}
      </div>
      <Footer {...footerProps} next={onNext} isNextLoading={isLoading} isStepLoading={loadingStatus} />
    </>
  );
};
