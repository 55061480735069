import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import { FullRX, MedicineOrder, StoredRx } from 'api/interfaces';
import { useSimplifiedDateFromSignatures } from './utils';
import { Nullable } from 'components-ts/utils';
import { OrderStatus } from './OrderStatus';
import { OrderComments } from './OrderComments';
import { OrderActionMenu } from './OrderActions';
import { LoadingInline } from 'components-ts/Loading';
import { CustomRxDrug } from 'components-ts/RXs';
import { LastUpdated } from 'components-ts/LastUpdated';

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Note',
  },
  ordersTitle: {
    id: 'OrderList.medicines_to_administer_title',
    defaultMessage: 'Medicines to Administer',
  },
  timeToAdministerLabel: {
    id: 'OrderList.time_to_administer_label',
    defaultMessage: 'Time to Administer',
  },
  timeAdministeredLabel: {
    id: 'OrderList.time_administered_label',
    defaultMessage: 'Time Administered',
  },
  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No items found',
  },
  mixLabel: {
    id: 'ResourceList.mix_label',
    defaultMessage: 'Mix',
  },
  mixNotesLabel: {
    id: 'ResourceList.mix_notes_label',
    defaultMessage: 'Mix notes',
  },
  guideline: {
    id: 'RxModal.label_guideline',
    defaultMessage: 'Guideline',
  },
  minutesShort: {
    id: 'UI.minutes_short_template',
    defaultMessage: '{minutes}m',
  },
  secondsShort: {
    id: 'UI.seconds_short_template',
    defaultMessage: '{seconds}s',
  },
});

// these orders need to show the rx. Which is async
type Order = MedicineOrder & {
  rx: Nullable<StoredRx>;
};

type ActionableProps = {
  patientId: string;
  visitId: string;
  orders: Array<Order>;
  isActionable: true;
  className?: string;
  isLoading: boolean;
  hideCanceled?: boolean;
  hideProgress?: boolean;
  hideRecurring?: boolean;
};

type ReadOnlyProps = {
  orders: Array<Order>;
  isActionable?: false;
  isLoading: boolean;
  className?: string;
  hideCanceled?: boolean;
  hideProgress?: boolean;
  hideRecurring?: boolean;
};

type MedicineOrderListProps = ActionableProps | ReadOnlyProps;
type GroupedMedicineOrders = Map<string, Array<Order>>;
/**
 * This is the only case in which we can have future orders
 * In that case they should be grouped by their
 */
export const MedicineOrderList: React.FC<MedicineOrderListProps> = (props) => {
  const { orders, className, ...rest } = props;

  const intl = useIntl();

  const orderMap: GroupedMedicineOrders = React.useMemo(() => {
    // just group them
    const grouped = orders.reduce((map, order) => {
      const key = order.recurringAdministrationId || 'single';

      if (!map.has(key)) {
        map.set(key, []);
      }

      map.get(key).push(order);

      return map;
    }, new Map());

    // then filter to only show past due and next administration
    const groupedAndFiltered = new Map();
    grouped.forEach((group, groupId) => {
      const nextAdministrationIndex = group.findIndex((rx) => {
        const now = new Date().getTime();
        const createdDate = new Date(rx.order.created?.timestamp || '').getTime();

        return createdDate > now && !rx.administered;
      });

      const pastDueOrdersAndNextAdministration =
        nextAdministrationIndex !== -1 ? group.slice(0, nextAdministrationIndex + 1) : group;

      groupedAndFiltered.set(groupId, pastDueOrdersAndNextAdministration);
    });

    return groupedAndFiltered;
  }, [orders]);

  return (
    <div className={`w-100 ${className || ''}`}>
      <h4>
        <T id={messages.ordersTitle.id}>{intl.formatMessage(messages.ordersTitle)}</T>
      </h4>
      <div className="border-top py-1">
        {Array.from(orderMap).map(([groupId, orders], groupIndex) => {
          //  just keep old behavior
          if (groupId === 'single') {
            return orders.map((order, index, allOrders) => {
              const prevItem = index > 0 ? allOrders[index - 1] : null;
              const key = `${groupId}-${order.id}-${index}`;

              return <Item key={key} item={order} prevItem={prevItem} {...rest} />;
            });
          }

          const groupKey = `group-${groupId}-${groupIndex}`;
          return <RecurringGroup orders={orders} key={groupKey} groupId={groupId} {...rest} />;
        })}
      </div>
    </div>
  );
};

type ActionableItemProps = {
  patientId: string;
  visitId: string;
  item: Order;
  prevItem: Nullable<Order>;
  isActionable: true;
  className?: string;
  isLoading: boolean;
  hideCanceled?: boolean;
};

type ReadOnlyItemProps = {
  item: Order;
  prevItem: Nullable<Order>;
  isActionable?: false;
  className?: string;
  isLoading: boolean;
  hideCanceled?: boolean;
};

type ItemProps = ReadOnlyItemProps | ActionableItemProps;

const Item: React.FC<ItemProps> = (props) => {
  const { item, prevItem, isLoading, isActionable, hideCanceled } = props;

  const intl = useIntl();

  const { date, time } = useSimplifiedDateFromSignatures(item.order.created, prevItem?.order.created);

  const lastUpdated = item.updated ?? item.rx?.fill?.created ?? item.rx?.rx.created;

  if (hideCanceled && item.canceled) {
    return null;
  }

  return (
    <div className="border-bottom border-light py-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex mb-2">
          <div style={{ minWidth: 100 }}>
            {date && <p className="m-0">{date}</p>}
            <p className="m-0">{time}</p>
          </div>
          <div className="px-2">
            {isLoading ? (
              <LoadingInline />
            ) : (
              <>
                <b style={{ textDecoration: item.canceled ? 'line-through' : 'none' }}>
                  {item.rx ? <CustomRxDrug cancelled={!!item.canceled} rx={item.rx.rx} /> : item?.order?.text}
                </b>
                {item.rx?.rx?.details?.notes && (
                  <p className="small m-0">
                    <T id={messages.noteLabel.id} className="mr-1">
                      {intl.formatMessage(messages.noteLabel)}:{' '}
                    </T>
                    <em>{item.rx?.rx?.details.notes}</em>
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        {isActionable && !item.rx?.filled && (
          <OrderActionMenu
            className="section-not-printable"
            patientId={props.patientId}
            visitId={props.visitId}
            order={item}
            isCancellable
            isAdministrable
            isCommentable
          />
        )}
      </div>
      <OrderStatus
        className="section-not-printable my-2"
        acknowledged={!!item.acknowledged}
        canceled={!!item.canceled}
        filled={!!item.filled}
      />
      {Array.isArray(item.comments) && <OrderComments comments={item.comments} className="my-2" />}
      {lastUpdated && (
        <div className="d-flex justify-content-end">
          <LastUpdated signature={lastUpdated} />
        </div>
      )}
    </div>
  );
};

type RecurringGroupProps = MedicineOrderListProps & {
  groupId: string;
};
const RecurringGroup: React.FC<RecurringGroupProps> = (props) => {
  const { orders, groupId, hideProgress, hideRecurring, ...itemProps } = props;

  const allCancelled = React.useMemo(() => {
    return orders.every((order) => !!order.canceled);
  }, [orders]);

  const aggregatedRx = React.useMemo(() => {
    const aggregated = orders.reduce((prev: null | FullRX, order: Order, index: number) => {
      if (order.rx) {
        if (prev === null) {
          return order.rx.rx;
        }

        /**
         * in case of liquid, all orders are linked to the
         * same rx, so we don't need to aggregate
         */
        const prevOrder = orders[index - 1];
        if (prevOrder && order.rxId === prevOrder.rxId) {
          return prev;
        }

        const currentRx = order.rx.rx;
        return {
          ...prev,
          details: {
            ...prev.details,
            dispense: prev.details.dispense + currentRx.details.dispense,
          },
        };
      }

      return prev;
    }, null);

    return aggregated;
  }, [orders]);

  const { date, time } = useSimplifiedDateFromSignatures(orders[0].order.created);

  return (
    <div className="border-bottom border-light py-2">
      <div className="d-flex mb-2">
        <div className="w-100">
          {itemProps.isLoading ? (
            <LoadingInline />
          ) : (
            <div className="d-flex">
              {hideRecurring && (
                <div style={{ minWidth: 100 }}>
                  {date && <p className="m-0">{date}</p>}
                  <p className="m-0">{time}</p>
                </div>
              )}
              <b className="px-2" style={{ textDecoration: allCancelled ? 'line-through' : 'none' }}>
                {aggregatedRx ? <CustomRxDrug cancelled={!!allCancelled} rx={aggregatedRx} /> : orders[0].order.text}
              </b>
            </div>
          )}
        </div>
      </div>
      {!hideRecurring && (
        <div className="d-flex">
          <div className="w-100">
            {orders.map((order, index, allOrders) => {
              const prevItem = index > 0 ? allOrders[index - 1] : null;
              const key = `${groupId}-${order.id}-${index}`;

              return <RecurringItem key={key} {...itemProps} item={order} prevItem={prevItem} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const RecurringItem: React.FC<ItemProps> = (props) => {
  const { item, prevItem, isActionable, hideCanceled } = props;

  const intl = useIntl();
  const { date, time } = useSimplifiedDateFromSignatures(item.order.created, prevItem?.order.created);

  const administeredDate = item.administered
    ? intl.formatDate(new Date(item.administered.timestamp), { dateStyle: 'medium' })
    : null;

  const administeredTime = item.administered
    ? intl.formatTime(new Date(item.administered.timestamp), { timeStyle: 'short' })
    : null;

  const prevDelayInMs =
    prevItem && prevItem.administered
      ? new Date(prevItem.administered.timestamp).getTime() - new Date(prevItem.order.created.timestamp).getTime()
      : null;
  const delayInMs = item.administered
    ? new Date(item.administered.timestamp).getTime() - new Date(item.order.created.timestamp).getTime()
    : null;

  let delay = delayInMs ? delayInMs / 1000 : null;
  let delayMsg = '';

  if (delay && delay < 60) {
    delayMsg = `${intl.formatMessage(messages.secondsShort, {
      seconds: intl.formatNumber(Math.trunc(delay), { signDisplay: 'exceptZero' }),
    })}`;
  }

  if (delay && delay >= 60) {
    delay = delay / 60;
    delayMsg = `${intl.formatMessage(messages.minutesShort, {
      minutes: intl.formatNumber(Math.trunc(delay), { signDisplay: 'exceptZero' }),
    })}`;
  }

  const administrationDate = prevDelayInMs
    ? intl.formatDate(new Date(new Date(item.order.created.timestamp).getTime() + prevDelayInMs), {
        dateStyle: 'medium',
      })
    : null;

  const administrationTime = prevDelayInMs
    ? intl.formatTime(new Date(new Date(item.order.created.timestamp).getTime() + prevDelayInMs), {
        timeStyle: 'short',
      })
    : null;

  const lastUpdated = item.updated ?? item.rx?.fill?.created ?? item.rx?.rx.created;

  const isLastUpdatedVisible = lastUpdated && new Date(lastUpdated.timestamp).getTime() < new Date().getTime();

  const isAdministrable = prevItem ? !!prevItem?.administered : true;

  if (hideCanceled && item.canceled) {
    return null;
  }

  return (
    <div className="border-top border-light py-1">
      <div className="d-flex justify-content-between">
        <div className="px-2">
          <div className="d-flex" style={{ textDecoration: !!item.canceled ? 'line-through' : 'none' }}>
            <T id={messages.timeToAdministerLabel.id} className="mr-1">
              {intl.formatMessage(messages.timeToAdministerLabel)}:{' '}
            </T>
            <div className="d-flex mb-2">
              {administrationDate && administrationTime ? (
                <div className="d-flex">
                  <div>
                    <p className="m-0">
                      <s>{date}</s>
                    </p>
                    <p className="m-0 ml-2">
                      <s>{time}</s>
                    </p>
                  </div>
                  <div className="ml-2">
                    <p className="m-0">{administrationDate}</p>
                    <p className="m-0 ml-2">{administrationTime}</p>
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <p className="m-0">{date}</p>
                  <p className="m-0 ml-2">{time}</p>
                </div>
              )}
            </div>
          </div>
          {administeredDate && administeredTime && (
            <div className="d-flex">
              <T id={messages.timeAdministeredLabel.id} className="mr-1">
                {intl.formatMessage(messages.timeAdministeredLabel)}:{' '}
              </T>

              <div className="d-flex mb-2">
                <p className="m-0">{administeredDate}</p>
                <p className="m-0 ml-2">{administeredTime}</p>
                {delayMsg && <p className="text-info m-0 ml-2">({delayMsg})</p>}
              </div>
            </div>
          )}
        </div>
        {isActionable && (
          <OrderActionMenu
            className="section-not-printable"
            patientId={props.patientId}
            visitId={props.visitId}
            order={item}
            isAdministrable={isAdministrable}
            isCancellable
            isCommentable
          />
        )}
      </div>
      <OrderStatus
        className="section-not-printable my-2"
        acknowledged={!!item.acknowledged}
        administered={!!item.administered}
        canceled={!!item.canceled}
        filled={!!item.filled}
      />
      {Array.isArray(item.comments) && <OrderComments comments={item.comments} className="my-2" />}
      {lastUpdated && isLastUpdatedVisible && (
        <div className="d-flex justify-content-end">
          <LastUpdated signature={lastUpdated} />
        </div>
      )}
    </div>
  );
};
