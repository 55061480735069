import React from 'react'
import Highlighter from 'react-highlight-words'
const debug = require('debug')('wd:ResourceItem')

const ResourceItem = (props) => {
  const {
    title = '',
    subtitle,
    searchTerms,
    onClick,
    className
  } = props

  const onClickProps = typeof onClick === 'function'
    ? { onClick, style: { cursor: 'pointer' } }
    : {}

  debug(onClickProps)
  return <li className={`d-flex flex-column text-left ${className}`} {...onClickProps}>
    {searchTerms
      ? <Highlighter
        className='text-dark title'
        searchWords={searchTerms}
        autoEscape
        textToHighlight={title}
      />
      : <span className='text-dark title'>{title}</span>
    }
    {subtitle &&
    (searchTerms
      ? <Highlighter
        className='text-muted small'
        searchWords={searchTerms}
        autoEscape
        textToHighlight={subtitle}
      />
      : <span className='text-muted small'>{subtitle}</span>
    )}
  </li>
}
ResourceItem.default = {
  title: '',
  subtitle: '',
  searchTerms: [''],
  onClick: null
}
export default ResourceItem
