import React from 'react';

interface Params {
  defaultIsOpen?: boolean;
}

export function useDisclosure(props?: Params) {
  const [isOpen, setIsOpen] = React.useState<boolean>(props?.defaultIsOpen ?? false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
  };
}
