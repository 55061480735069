import React from 'react';
import {
  Alert,
  Label,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabPane,
} from 'reactstrap';
import { ErrorViewer, LoadingInline } from 'components';
import { useTranslation, useUpdateTranslation } from '.';
import { FullTranslatedMessage, FullTranslation } from 'api/interfaces/translations';
import { FaHistory as HistoryIcon } from 'react-icons/fa';
import { useDebounceExececute } from 'hooks';
import { Locales, SUPPORTED_LOCALES } from 'components-ts/i18n';
import { Nullable } from 'components-ts/utils';
import { FaCheckCircle as CheckIcon } from 'react-icons/fa';
import { isValidDate, useFromDate } from 'components-ts/DateAndTime';
/**
 * This content doesn't need to be translated
 */

interface TranslationModalProps {
  id: string;
  locale?: Locales;
  onToggle: () => void;
  isOpen?: boolean;
}

export const TranslationModal: React.FC<TranslationModalProps> = (props) => {
  const { id, isOpen, onToggle } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={onToggle}
      backdrop
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ModalHeader toggle={onToggle}>Update translation</ModalHeader>
      <ModalBody>
        <TranslationModalBody id={id} onToggle={onToggle} locale={props?.locale} />
      </ModalBody>
    </Modal>
  );
};

const TranslationModalBody: React.FC<TranslationModalProps> = (props) => {
  const { id, onToggle } = props;

  const { translation, isLoading, error } = useTranslation({ id });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <LoadingInline />
      </div>
    );
  }

  if (error || translation === null) {
    return <Alert color="danger">{error ?? 'An internal error has ocurred.'}</Alert>;
  }

  return <TranslationEditor translation={translation} onToggle={onToggle} locale={props?.locale} />;
};

interface TranslationEditorProps {
  translation: FullTranslation;
  locale?: Locales;
  onToggle: () => void;
}

const TranslationEditor: React.FC<TranslationEditorProps> = (props) => {
  const { translation, locale } = props;

  const { fromDate } = useFromDate();

  const defaultLocale = locale ?? Locales.SO_SO;
  const [selectedLocale, setSelectedLocale] = React.useState<Locales>(defaultLocale);
  const translatedMessage = getTranslatedMessageByLocale(translation, selectedLocale);

  const onTabChange = (tab: Locales) => {
    if (selectedLocale !== tab) {
      setSelectedLocale(tab);
    }
  };

  const creationDate = translation?.created ? new Date(translation.created) : null;

  const creationMessage = creationDate !== null && isValidDate(creationDate) ? fromDate(new Date(), creationDate) : '-';

  return (
    <div>
      <p className="mb-1">
        <b>ID: </b>
        {translation.id}
      </p>
      {`Dynamic.${translation.defaultMessage}` !== translation.id && (
        <p className="mb-1">
          <b>{'Default message: '}</b>
          {translation.defaultMessage}
        </p>
      )}
      <p className="mb-1">
        <b>{'Created: '}</b>
        {creationMessage}
      </p>
      <Nav tabs className="my-2">
        {Object.entries(SUPPORTED_LOCALES)
          .filter(([id]) => id !== Locales.EN_US)
          .map(([id]) => {
            const isActive = id === selectedLocale;
            const doesContainTranslation =
              Array.isArray(translation.translations) &&
              translation.translations.some((item) => item.locale === id && !!item.message);

            return (
              <NavItem key={id}>
                <NavLink className={isActive ? 'active' : ''} onClick={() => onTabChange(id as Locales)}>
                  <span>{id.toUpperCase()}</span>
                  {doesContainTranslation && <CheckIcon className="text-success ml-1" />}
                </NavLink>
              </NavItem>
            );
          })}
      </Nav>
      <EditorTabContent
        key={selectedLocale}
        id={translation.id}
        translatedMessage={translatedMessage}
        locale={selectedLocale}
        defaultMessage={translation.defaultMessage}
      />
    </div>
  );
};

interface EditorTabContentProps {
  id: string;
  translatedMessage: Nullable<FullTranslatedMessage>;
  defaultMessage: string;
  locale: Locales;
}
const EditorTabContent: React.FC<EditorTabContentProps> = (props) => {
  const { id, locale, translatedMessage, defaultMessage } = props;

  const { execute } = useDebounceExececute(300);

  const { fromDate } = useFromDate();

  const [validationError, setValidationError] = React.useState<string>('');
  const [value, setValue] = React.useState<string>(translatedMessage?.message ?? '');

  const { onUpdate, isLoading, error } = useUpdateTranslation();
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError('');

    const value = event.target.value;
    setValue(value);
    execute(() => onSave(value));
  };

  const onSave = (value: string) => {
    if (!value) {
      setValidationError('Empty strings are not allowed');
      return;
    }

    const params = {
      id,
      defaultMessage,
      message: value,
      locale,
    };

    onUpdate(params);
  };

  const date = translatedMessage?.lastUpdated ? new Date(translatedMessage.lastUpdated) : null;
  const lastUpdateDescription =
    date && isValidDate(date)
      ? `${fromDate(new Date(), date)} by ${translatedMessage?.translatorUsername ?? '--'}`
      : '-';

  return (
    <TabPane className="mt-2">
      <FormGroup>
        <Label className="mb-1">
          Enter a translation
          {isLoading && <LoadingInline />}
        </Label>
        <Input
          value={value}
          onChange={onInputChange}
          type="textarea"
          maxLength={1000}
          rows={3}
          className={`mb-2 form-control ${validationError ? 'is-invalid' : ''}`}
        />

        {validationError && <div className="invalid-feedback d-flex">{validationError}</div>}
      </FormGroup>
      {error && <ErrorViewer error={error} />}
      <small className={'text-muted d-flex align-items-center'}>
        {date !== null && isValidDate(date) && (
          <span className="mr-1 d-flex align-items-center">
            <HistoryIcon className="m-1 ml-2" />
            {lastUpdateDescription}
          </span>
        )}
      </small>
    </TabPane>
  );
};

const getTranslatedMessageByLocale = (translation: FullTranslation, locale: Locales) => {
  if (Array.isArray(translation?.translations)) {
    const found = translation.translations.find((item) => item.locale === locale);

    return found ?? null;
  }

  return null;
};
