import { MutationHookOptions, useMutation } from '@apollo/client';
import { Drug } from 'api/interfaces';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { CREATE_PHARMACY_ITEM } from 'scenes/Pharmacy/requests';

const messages = defineMessages({
  createError: {
    id: 'CreatePharmacyItem.create_error',
    defaultMessage: 'An error ocurred while updating the item',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  noClassError: {
    id: 'CreatePharmacyItem.no_class_error',
    defaultMessage: 'Please provide a class',
  },
  noNameError: {
    id: 'CreatePharmacyItem.no_name_error',
    defaultMessage: 'Please provide a name',
  },
  noUnitError: {
    id: 'CreatePharmacyItem.no_unit_error',
    defaultMessage: 'Please provide a unit',
  },
});

type CreatePharmacyItemParams = {
  name: string;
  unit: string;
  stock_unit: Nullable<string>;
  minimum_units_to_dispense: Nullable<number>;
  class: string;
  category: Nullable<string>;
  recommended_dosing: Nullable<string>;
  interactions: Nullable<string>;
  comments: Nullable<string>;
};
interface CreatePharmacyItemVariables {
  pharmacyItem: CreatePharmacyItemParams;
}

type CreatePharmacyItemData = {
  createPharmacyItem: Drug;
};

type CreatePharmacyItemParam = MutationHookOptions<CreatePharmacyItemData, CreatePharmacyItemVariables>;

export const useCreatePharmacyItem = (params?: CreatePharmacyItemParam) => {
  const intl = useIntl();
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'create_error':
        return setError(messages.createError);
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params?.onCompleted) {
      params.onCompleted(data);
    }
  };

  const [createPharmacyItem, { loading: isLoading }] = useMutation<CreatePharmacyItemData, CreatePharmacyItemVariables>(
    CREATE_PHARMACY_ITEM,
    {
      ...params,
      onError,
      onCompleted,
    }
  );

  const onSubmit = (pharmacyItem: CreatePharmacyItemParams) => {
    createPharmacyItem({
      variables: {
        pharmacyItem,
      },
    });
  };

  const validate: ValidateFn<CreatePharmacyItemParams> = (values) => {
    const errors: ValidationError<CreatePharmacyItemParams> = {};

    if (!values.name) {
      errors.name = intl.formatMessage(messages.noNameError);
    }

    if (!values.class) {
      errors.class = intl.formatMessage(messages.noClassError);
    }

    if (!values.unit) {
      errors.unit = intl.formatMessage(messages.noUnitError);
    }

    return errors;
  };

  return {
    createPharmacyItem,
    onSubmit,
    validate,
    isLoading,
    error,
  };
};
