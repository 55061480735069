import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * Quick styles customization to make it work with the
 * brand color set in the chakra context in light & dark mode.
 */
import './styles.scss';
import { useTheme } from 'components-ts/Theme/useTheme';
import { THEME } from 'components-ts/Theme/context';

export type DatePickerProps = Omit<ReactDatePickerProps, 'selectsRange'> & {
  onChange: (date: Date) => void;
  value?: Date | undefined;
  isInvalid?: boolean;
  isDisabled?: boolean;
  selectsRange?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = props => {
  const { value, onChange, isClearable = false, showPopperArrow = false, isInvalid, isDisabled, ...otherProps } = props;

  const { theme } = useTheme();

  const className = isInvalid ? 'react-datapicker__input-text invalid' : 'react-datapicker__input-text';

  return (
    <div className={theme === THEME.LIGHT ? 'light-theme' : 'dark-theme'}>
      <ReactDatePicker
        selected={value}
        onChange={onChange}
        isClearable={isClearable}
        disabled={isDisabled}
        showPopperArrow={showPopperArrow}
        showYearDropdown
        className={className}
        disabledKeyboardNavigation
        autoComplete={'false'}
        dateFormat='P'
        timeFormat='p'
        {...otherProps}
      />
    </div>
  );
};
