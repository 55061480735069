import React from 'react';
import { getPatientName } from '.';
import {
  FaAddressCard as NationalIdIcon,
  FaPhone as PhoneIcon,
  FaUser as UserIcon,
  FaEnvelope as EnvelopeIcon,
  FaCalendar,
} from 'react-icons/fa';
import { useIntl, defineMessages } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useAgeTranslator } from 'components-ts/DateAndTime';
import { BasicPatient, BasicPatientWithoutId, FullPatient, FullPatientWithoutId } from 'api/interfaces';
import { Address } from 'components-ts/Address';

// static translations
const messages = defineMessages({
  patient: {
    id: 'PatientInfo.patient_label',
    defaultMessage: 'Name',
  },
  birthdate: {
    id: 'PatientInfo.birthdate_label',
    defaultMessage: 'Birthdate',
  },
  phone: {
    id: 'PatientInfo.phone_label',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'PatientInfo.email_label',
    defaultMessage: 'Email',
  },
  nationalIdNumber: {
    id: 'PatientInfo.national_id_number_label',
    defaultMessage: 'National ID Number',
  },
  clinicId: {
    id: 'PaymentCard.clinic_id',
    defaultMessage: 'Clinic ID',
  },
});

type PatientInfoProps = {
  patient: BasicPatient | BasicPatientWithoutId | FullPatient | FullPatientWithoutId;
  withAge?: boolean;
  withIcon?: boolean;
  inlineAddress?: boolean;
  className?: string;
};

export const PatientInfo: React.FC<PatientInfoProps> = (props) => {
  const { patient, withAge, withIcon, inlineAddress, className } = props;
  const intl = useIntl();

  const { translateAge } = useAgeTranslator();

  const age = translateAge(patient.birthdate);
  const patientName = getPatientName(patient);

  const hasClinicId = 'clinic_id' in patient && patient.clinic_id;

  return (
    <div className={className}>
      {patient.mr && <span className="d-flex">MR: {patient.mr.toString().padStart(5, '0')}</span>}
      {hasClinicId && (
        <span className="d-flex">
          <T id={messages.clinicId.id} className="mr-1">
            {intl.formatMessage(messages.clinicId)}:
          </T>
          {patient.clinic_id}
        </span>
      )}
      <div className="d-flex text-capitalize">
        {withIcon ? (
          <UserIcon style={{ margin: '.2em 0' }} className="text-muted" />
        ) : (
          <T id={messages.patient.id}>{intl.formatMessage(messages.patient)}:</T>
        )}
        <span className="ml-2">
          {patientName}
          {withAge && <span>{`,  ${age}`}</span>}
        </span>
      </div>

      <div className="d-flex">
        {withIcon ? (
          <FaCalendar style={{ margin: '.2em 0' }} className="text-muted" />
        ) : (
          <T id={messages.birthdate.id}>{intl.formatMessage(messages.birthdate)}:</T>
        )}
        <span className="ml-2">{intl.formatDate(patient.birthdate)}</span>
      </div>

      {patient.address ? (
        <Address address={patient.address} withIcon={withIcon} inline={inlineAddress} withLabel={!withIcon} />
      ) : null}

      {patient.phone_number && (
        <div className="d-flex">
          {withIcon ? (
            <PhoneIcon style={{ margin: '.2em 0' }} className="text-muted" />
          ) : (
            <T id={messages.phone.id}>{intl.formatMessage(messages.phone)}:</T>
          )}
          <span className="ml-2">{patient.phone_number}</span>
        </div>
      )}

      {patient.email && (
        <div className="d-flex">
          {withIcon ? (
            <EnvelopeIcon style={{ margin: '.2em 0' }} className="text-muted" />
          ) : (
            <T id={messages.email.id}>{intl.formatMessage(messages.email)}:</T>
          )}
          <span className="ml-2">{patient.email}</span>
        </div>
      )}

      {patient.national_id && (
        <div className="d-flex">
          {withIcon ? (
            <NationalIdIcon style={{ margin: '.2em 0' }} className="text-muted" />
          ) : (
            <T id={messages.nationalIdNumber.id}>{intl.formatMessage(messages.nationalIdNumber)}:</T>
          )}
          <span className="ml-2">{patient.national_id}</span>
        </div>
      )}
    </div>
  );
};
