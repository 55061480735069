import { MutationHookOptions, useMutation } from '@apollo/client';
import { Drug } from 'api/interfaces';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { UPDATE_PHARMACY_ITEM } from 'scenes/Pharmacy/requests';

const messages = defineMessages({
  updateError: {
    id: 'UpdatePharmacyItem.update_error',
    defaultMessage: 'An error ocurred while updating the item',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  noClassError: {
    id: 'CreatePharmacyItem.no_class_error',
    defaultMessage: 'Please provide a class',
  },
  noNameError: {
    id: 'CreatePharmacyItem.no_name_error',
    defaultMessage: 'Please provide a name',
  },
  noUnitError: {
    id: 'CreatePharmacyItem.no_unit_error',
    defaultMessage: 'Please provide a unit',
  },
});

type UpdatePharmacyItemParams = {
  name: string;
  unit: string;
  stock_unit: Nullable<string>;
  minimum_units_to_dispense: Nullable<number>;
  class: string;
  category: Nullable<string>;
  recommended_dosing: Nullable<string>;
  interactions: Nullable<string>;
  comments: Nullable<string>;
};
interface UpdatePharmacyItemVariables {
  itemId: string;
  pharmacyItem: UpdatePharmacyItemParams;
}

interface UpdatePharmacyItemData {
  updatePharmacyItem: Drug;
}

type UpdatePharmacyItemMutationParams = MutationHookOptions<UpdatePharmacyItemData, UpdatePharmacyItemVariables> & {
  itemId: string;
};

export const useUpdatePharmacyItem = (params: UpdatePharmacyItemMutationParams) => {
  const { itemId } = params;
  const intl = useIntl();
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'update_error':
        return setError(messages.updateError);
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const [updatePharmacyItem, { loading: isLoading }] = useMutation<UpdatePharmacyItemData, UpdatePharmacyItemVariables>(
    UPDATE_PHARMACY_ITEM,
    {
      ...params,
      onError,
    }
  );

  const onSubmit = (pharmacyItem: UpdatePharmacyItemParams) => {
    updatePharmacyItem({
      variables: {
        itemId,
        pharmacyItem,
      },
    });
  };

  const validate: ValidateFn<UpdatePharmacyItemParams> = (values) => {
    const errors: ValidationError<UpdatePharmacyItemParams> = {};

    if (!values.name) {
      errors.name = intl.formatMessage(messages.noNameError);
    }

    if (!values.class) {
      errors.class = intl.formatMessage(messages.noClassError);
    }

    if (!values.unit) {
      errors.unit = intl.formatMessage(messages.noUnitError);
    }

    return errors;
  };

  return {
    updatePharmacyItem,
    onSubmit,
    validate,
    isLoading,
    error,
  };
};
