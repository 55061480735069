import React from 'react';
import {
  CognitoUserSession,
  CognitoUserPool,
  CognitoUser,
  CognitoRefreshToken,
  CognitoIdToken,
  AuthenticationDetails,
  CognitoUserAttribute,
  ISignUpResult,
  UserData,
} from 'amazon-cognito-identity-js';
import {
  ChangePasswordParams,
  LogInParams,
  RequestAttributeVerificationParams,
  Session,
  SessionState,
  UpdateAttributeParams,
  VerifyAttributeParams,
} from './session';
import {
  ConfirmRegistrationParams,
  DecodedUserPayload,
  ForgotPasswordParams,
  ForgotPasswordResponse,
  LogInResponse,
  ResetPasswordParams,
  SendMFATokenParams,
  SetUserMfaPreferenceResponse,
  SignUpParams,
  UpdateLocaleParams,
  validateSystemOrLocationAttr,
  VerifyMFATokenParams,
  VerifyMFATokenResponse,
} from './session';
import { GlobalRole, Permissions } from './permissions';
import { guessLocale, Locales, UnitSystems } from '../i18n';
import { DefaultSystemOrLocation, SubscriptionStatus, SubscriptionType } from '../../api/interfaces';
import {
  Subscription,
  AccountStatus,
  isFullAccessAllowed,
  isReadOnlyAccessAllowed,
  isValidSubscriptionAttr,
  isAccessAllowed,
} from './subscription';
import { getEnvs } from 'components-ts/envs';
import { Nullable, validateNotNil } from 'components-ts/utils';
import { addDays } from 'components-ts/DateAndTime';
import { removeLocalStorage } from 'utils/browserUtilities';

const envs = getEnvs();
const { UserPoolId, ClientId } = envs.cognito;
const pool: CognitoUserPool = new CognitoUserPool({
  UserPoolId,
  ClientId,
});

export const SessionContext = React.createContext<Nullable<Session>>(null);
export const SessionProvider: React.FC = (props) => {
  const [sessionState, setSessionState] = React.useState<SessionState>(SessionState.BOOTING);
  const [user, setUser] = React.useState<Nullable<CognitoUser>>(pool.getCurrentUser());
  const [token, setToken] = React.useState<Nullable<CognitoIdToken>>(null);

  /**
   * Auto login after confirming signup
   */
  const [tempCredentials, setTempCredentials] = React.useState<Nullable<AuthenticationDetails>>(null);

  /**
   * Internal utility to pull the current session from cognito library.
   */
  const getCognitoSession = React.useCallback(async (): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      if (user !== null) {
        user.getSession((error: Nullable<Error>, session: Nullable<CognitoUserSession>) => {
          if (error !== null) {
            return reject(error);
          }

          if (session !== null) {
            return resolve(session);
          }
        });
      } else {
        return reject(new Error('Never authenticated'));
      }
    });
  }, [user]);

  /**
   * Internal utility to refresh the given cognito session. Returns a
   * promise for the new session
   */
  const refreshCognitoSession = React.useCallback(
    async (newSession: CognitoUserSession): Promise<CognitoUserSession> => {
      return new Promise((resolve, reject) => {
        if (user !== null) {
          const refreshToken: CognitoRefreshToken = newSession.getRefreshToken();

          user.refreshSession(refreshToken, (error: Nullable<Error>, session: Nullable<CognitoUserSession>) => {
            if (error !== null) {
              return reject(error);
            }

            if (session !== null) {
              return resolve(session);
            }
          });
        } else {
          return reject(new Error('Tried to refresh but never authenticated'));
        }
      });
    },
    [user]
  );

  /**
   * Internal utility to set the store state to a cognito session object
   */
  const setCurrentSession = React.useCallback((session: CognitoUserSession): void => {
    if (session.isValid()) {
      const newToken = session.getIdToken();
      setToken(newToken);
      setSessionState(SessionState.LOGGED_IN);
    }
  }, []);

  /**
   * Refreshes a user's token with cognito. Useful for updating the UI when
   * there are permissions or other token changes. Returns the current cognito
   * session.
   */
  type Callback = () => void;
  const refresh = React.useCallback(
    async (callback?: Callback): Promise<void> => {
      try {
        const initialSession = await getCognitoSession();
        const currentSession = await refreshCognitoSession(initialSession);

        setCurrentSession(currentSession);
        if (typeof callback === 'function') {
          callback();
        }
      } catch (e) {
        setSessionState(SessionState.LOGGED_OUT);
      }
    },
    [getCognitoSession, setCurrentSession, refreshCognitoSession]
  );

  /**
   * Log in user after verifying their account
   * @param params login params
   * @returns {Promise<void>}
   */
  const autoLogin = async (): Promise<void> => {
    if (user === null || typeof user.authenticateUser !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    if (!tempCredentials) {
      return;
    }

    return new Promise((resolve, reject) => {
      user.authenticateUser(tempCredentials, {
        onSuccess: (newSession: CognitoUserSession) => {
          setCurrentSession(newSession);
          resolve();
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });
  };

  /**
   * Exported methods
   */

  /**
   * Associate MFA token
   */
  const associateMFAToken = async (): Promise<string> => {
    if (user === null || typeof user.associateSoftwareToken !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.associateSoftwareToken({
        associateSecretCode: (secretCode) => resolve(secretCode),
        onFailure: (error) => {
          if (error.code === 'NotAuthorizedException') {
            refresh();
          }
          reject(error);
        },
      });
    });
  };

  /**
   * Associate MFA token
   */
  const changePassword = async (params: ChangePasswordParams): Promise<void> => {
    if (user === null || typeof user.changePassword !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.changePassword(params.oldPassword, params.newPassword, (error) => {
        if (error) {
          return reject(error);
        }

        resolve();
      });
    });
  };

  const changeUserUnitSystem = async (unitSystem: UnitSystems) => {
    if (user === null || typeof user.updateAttributes !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return updateAttribute({ attributeName: 'custom:unitSystem', value: unitSystem });
  };

  const updateUserBpjsDoctorId = async (newBpjsDoctorId: string) => {
    if (user === null || typeof user.updateAttributes !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return updateAttribute({
      attributeName: 'custom:bpjsDoctorId',
      value: newBpjsDoctorId,
    });
  };

  /**
   * Confirm signup
   * @param params Signup confirmation params
   * @returns {Promise<void>}
   */
  const confirmRegistration = (params: ConfirmRegistrationParams): Promise<void> => {
    if (user === null || typeof user.confirmRegistration !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    const { code } = params;
    return new Promise((resolve, reject) => {
      user.confirmRegistration(code, false, (error) => {
        if (error) {
          return reject(error);
        }

        autoLogin().then(resolve).catch(reject);
      });
    });
  };

  /**
   * Disable Multi Factor Authentication
   */
  const disableMFA = async (): Promise<SetUserMfaPreferenceResponse> => {
    if (user === null || typeof user.setUserMfaPreference !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    const SoftwareTokenMfaSettings = {
      Enabled: false,
      PreferredMfa: false,
    };

    return new Promise((resolve, reject) => {
      user.setUserMfaPreference(null, SoftwareTokenMfaSettings, function (error, data: any) {
        if (error) {
          reject(error);
        }
        if (data) {
          resolve(data as SetUserMfaPreferenceResponse);
        }

        reject(new Error('unknown'));
      });
    });
  };

  /**
   * * Start MFA activation
   */
  const enableMFA = async (): Promise<SetUserMfaPreferenceResponse> => {
    if (user === null || typeof user.setUserMfaPreference !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    const SoftwareTokenMfaSettings = {
      Enabled: true,
      PreferredMfa: true,
    };

    return new Promise((resolve, reject) => {
      user.setUserMfaPreference(null, SoftwareTokenMfaSettings, function (err, data: any) {
        if (err) {
          reject(err);
        }
        if (data) {
          resolve(data as SetUserMfaPreferenceResponse);
        }
        reject(new Error('unknown'));
      });
    });
  };

  /**
   * Trigger a password restor
   * @param params Forgot password params
   * @returns ForgotPasswordResponse with recovery code destination partially hidden
   */
  const forgotPassword = (params: ForgotPasswordParams): Promise<ForgotPasswordResponse> => {
    const cognitoUser = new CognitoUser({
      Username: params.username,
      Pool: pool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
          if (!isCognitoUserSet()) {
            setUser(cognitoUser);
          } else {
            const username = user?.getUsername();
            if (params.username !== username) {
              setUser(cognitoUser);
            }
          }
        },
        onFailure: reject,
      });
    });
  };

  /**
   * Get the status of the account
   * When the user creates a new account, this will
   */
  const getAccountStatus = React.useCallback((): AccountStatus => {
    try {
      validateNotNil<CognitoIdToken>(token);

      const userInfo = token.payload as DecodedUserPayload;

      // no valid subscription attr means that the user still didn't pay or has not an invite
      if (!userInfo['custom:subscription'] || !isValidSubscriptionAttr(userInfo['custom:subscription'])) {
        return AccountStatus.PAYMENT_REQUIRED;
      }

      const subscription = JSON.parse(userInfo['custom:subscription']) as Subscription;

      /**
       * Users can cancel the subscription before setting their clinic up
       */
      if (!isAccessAllowed(subscription)) {
        return AccountStatus.REACTIVATION_REQUIRED;
      }

      /**
       * If paid, we have to check permissions and default attributes
       */
      try {
        const permissions = JSON.parse(userInfo['custom:permissions']);
        Permissions.validatePermissions(permissions);

        try {
          validateSystemOrLocationAttr(userInfo['custom:location']);
          validateSystemOrLocationAttr(userInfo['custom:system']);
        } catch (error) {
          /**
           * Everything is ok. User only needs to set their default facility
           * This means the user still didn't create their first location.
           */
          return AccountStatus.DEFAULT_MISSING;
        }

        // valid permissions and subscription paid => user has full regular access
        if (isFullAccessAllowed(subscription)) {
          return AccountStatus.FULL_ACCESS;
        }

        if (isReadOnlyAccessAllowed(subscription)) {
          return AccountStatus.READ_ONLY_ACCESS;
        }
      } catch (error) {
        /**
         * if the permissions validation fails, the user have to set up their clinic
         */
        if (isFullAccessAllowed(subscription)) {
          return AccountStatus.SETUP_PENDING;
        }
      }
    } catch (error) {
      //
    }

    return AccountStatus.REACTIVATION_REQUIRED;
  }, [token]);

  const getInvite = (): Nullable<string> => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo['custom:invite'] ?? null;
  };

  const getUser = (): DecodedUserPayload => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo;
  };

  /**
   * This is implemented to quietly migrate users to new subscription system
   * Get the required subscription action url if exist
   */
  const getSubscriptionActionUrl = (): Nullable<string> => {
    try {
      validateNotNil<CognitoIdToken>(token);

      const userInfo = token.payload as DecodedUserPayload;

      // no valid subscription attr means that the user still didn't pay
      if (!userInfo['custom:subscription']) {
        return null;
      }

      /**
       * If paid, we have to check permissions and default attributes
       */
      const subscription = JSON.parse(userInfo['custom:subscription']) as Subscription;

      return subscription.url ?? null;
    } catch (errror) {
      // invalid token
    }

    return null;
  };

  /**
   * Get the status of the account
   * When the user creates a new account, this will
   */
  const getSubscriptionStatus = (): Nullable<SubscriptionStatus> => {
    try {
      validateNotNil<CognitoIdToken>(token);

      const userInfo = token.payload as DecodedUserPayload;

      // no valid subscription attr means that the user still didn't pay
      if (!userInfo['custom:subscription'] || !isValidSubscriptionAttr(userInfo['custom:subscription'])) {
        return null;
      }

      /**
       * If paid, we have to check permissions and default attributes
       */
      const subscription = JSON.parse(userInfo['custom:subscription']) as Subscription;
      if (subscription.type === SubscriptionType.RECURRING) {
        return subscription.status ?? null;
      }

      if (!subscription?.expirationDate) {
        return null;
      }

      const expirationDate = new Date(subscription.expirationDate);
      const pastDueDate = addDays(expirationDate, 7);
      const now = new Date();

      if (pastDueDate > now && now > expirationDate) {
        return SubscriptionStatus.PAST_DUE;
      }

      if (expirationDate > now) {
        return SubscriptionStatus.ACTIVE;
      }

      return SubscriptionStatus.CANCELED;
    } catch (errror) {
      // invalid token
    }

    return null;
  };

  const getUserSubscription = () => {
    try {
      validateNotNil<CognitoIdToken>(token);

      const userInfo = token.payload as DecodedUserPayload;

      // no valid subscription attr means that the user still didn't pay
      if (!userInfo['custom:subscription'] || !isValidSubscriptionAttr(userInfo['custom:subscription'])) {
        return null;
      }

      /**
       * If paid, we have to check permissions and default attributes
       */
      const subscription = JSON.parse(userInfo['custom:subscription']) as Subscription;
      return subscription;
    } catch (err) {
      //invalid token
    }

    return null;
  };

  const getSystemIDs = (): Array<string> => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    const userPermissions = new Permissions(userInfo['custom:permissions']);

    return userPermissions.getSystemIDs();
  };

  /**
   * Get jwt token for authentication
   * @returns {string} Cognito jwtToken
   */
  const getToken = (): string => {
    validateNotNil<CognitoIdToken>(token);

    return token.getJwtToken();
  };

  /**
   * This contains some extra information like MFA preferences
   */
  const getUserData = async (): Promise<UserData> => {
    if (user === null || typeof user.getUserData !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.getUserData((error, data) => {
        if (error) {
          return reject(error);
        }

        if (data) {
          resolve(data);
        }

        reject(new Error('Unknown'));
      });
    });
  };

  /**
   * Get user email
   * @returns {string} User full name
   */
  const getUserEmail = (): string => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.email;
  };

  /**
   * Get user family name
   */
  const getUserFamilyName = (): string => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.family_name;
  };

  /**
   * Get user full name
   */
  const getUserFullName = (): string => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return `${userInfo.given_name} ${userInfo.family_name}`;
  };

  /**
   * Get user given name
   */
  const getUserGivenName = (): string => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.given_name;
  };

  /**
   * Get the subject of the jwt token
   * @returns {string} subject
   */
  const getUserId = (): string => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.sub;
  };

  /**
   * Get user locale
   * @returns {string} locale
   */
  const getUserLocale = (): Locales => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.locale as Locales;
  };

  /**
   * Get custom attr: default location id
   * @returns {string} location id
   */
  const getUserLocation = (): DefaultSystemOrLocation => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;

    // custom attributes can be null for new users
    const locationAttr = userInfo['custom:location'];
    validateSystemOrLocationAttr(locationAttr);

    return JSON.parse(locationAttr) as DefaultSystemOrLocation;
  };

  /**
   * Get cognito username
   * @returns {string} Cognito username
   */
  const getUsername = (): string => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    const username = userInfo['cognito:username'];
    return username;
  };

  /**
   * Get cognito username
   * @returns {string} Cognito username
   */
  const getUsernameFromCognitoUser = (): string => {
    if (user === null || typeof user?.getUsername !== 'function') {
      throw new Error('Invalid user');
    }

    return user.getUsername();
  };

  /**
   * Get user phone
   * @returns {string} User full name
   */
  const getUserPhoneNumber = (): string => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.phone_number;
  };

  /**
   * Get custom attr: default system id
   * @returns {string} system
   */
  const getUserSystem = (): DefaultSystemOrLocation => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;

    const systemAttr = userInfo['custom:system'];
    validateSystemOrLocationAttr(systemAttr);

    return JSON.parse(systemAttr) as DefaultSystemOrLocation;
  };

  /**
   * Unit system: metric or imperial
   * @returns {string} unit system
   */
  const getUserUnitSystem = () => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo['custom:unitSystem'] as UnitSystems;
  };

  const getUserBpjsDoctorId = () => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;

    return userInfo['custom:bpjsDoctorId'] ?? null;
  };

  const getUserPermissions = () => {
    validateNotNil<CognitoIdToken>(token);

    const userInfo = token.payload as DecodedUserPayload;

    const permissions = new Permissions(userInfo['custom:permissions']);

    return permissions;
  };

  /**
   * Check if session is booting
   * @returns {boolean} User is logged in
   */
  const isBooting = (): boolean => {
    return sessionState === SessionState.BOOTING;
  };

  /**
   * Check if the cognito user is already created.
   * @returns {boolean} CognitoUser is created
   */
  const isCognitoUserSet = (): boolean => {
    if (user === null || typeof user.getUsername !== 'function') {
      return false;
    }

    return true;
  };
  /**
   * Check if user is logged in
   * @returns {boolean} User is logged in
   */
  const isLoggedIn = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token);
    } catch (error) {
      return false;
    }

    return sessionState === SessionState.LOGGED_IN;
  };

  /**
   * Check if user is an admin
   * @returns {boolean} User is admin
   */
  const isAdmin = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token);
    } catch (error) {
      return false;
    }

    const userInfo = token.payload as DecodedUserPayload;
    const userPermissions = new Permissions(userInfo['custom:permissions']);
    return userPermissions.hasGlobalRole(GlobalRole.ADMIN);
  };

  /**
   * Check if user email is verified
   */
  const isEmailVerified = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token);
    } catch (error) {
      return false;
    }

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.email_verified;
  };

  /**
   * Check if user phone number is verified
   */
  const isPhoneNumberVerified = (): boolean => {
    try {
      validateNotNil<CognitoIdToken>(token);
    } catch (error) {
      return false;
    }

    const userInfo = token.payload as DecodedUserPayload;
    return userInfo.phone_number_verified;
  };

  /**
   * Log in user
   * @param params login params
   * @returns {Promise<void>}
   */
  const logIn = async (params: LogInParams): Promise<LogInResponse> => {
    const { username, password } = params;

    const credentials = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: pool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(credentials, {
        onSuccess: (newSession: CognitoUserSession) => {
          setUser(cognitoUser);
          setCurrentSession(newSession);
          const result: LogInResponse = {
            totpRequired: false,
          };
          resolve(result);
        },
        onFailure: (error) => {
          if (error?.code === 'UserNotConfirmedException') {
            setTempCredentials(credentials);
            setUser(cognitoUser);
          }

          reject(error);
        },
        totpRequired: () => {
          setUser(cognitoUser);
          const result: LogInResponse = {
            totpRequired: true,
          };
          resolve(result);
        },
      });
    });
  };

  /**
   * Log out user
   */
  const logOut = () => {
    if (user) {
      const permissions = getUserPermissions();
      const systemIDs = permissions.getSystemIDs();

      removeLocalStorage(systemIDs.map((id) => `logo-${id}`));

      if (typeof user.signOut === 'function') {
        user.signOut();
      }
    }

    setUser(null);
    setSessionState(SessionState.LOGGED_OUT);
  };

  /**
   * Request an attr verification by sending a code
   */
  const requestAttributeVerification = async (params: RequestAttributeVerificationParams): Promise<void> => {
    if (user === null || typeof user.getAttributeVerificationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.getAttributeVerificationCode(params.attributeName, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (error) => reject(error),
      });
    });
  };

  /**
   * Request a new attr verification code
   */
  const resendAttributeVerificationCode = async (params: RequestAttributeVerificationParams): Promise<void> => {
    if (user === null || typeof user.getAttributeVerificationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.getAttributeVerificationCode(params.attributeName, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (error) => reject(error),
      });
    });
  };

  /**
   * Request confrimatino code re-sending
   * @returns {Promise<void>}
   */
  const resendConfirmationCode = (): Promise<void> => {
    if (user === null || typeof user.resendConfirmationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.resendConfirmationCode((error) => {
        if (error) {
          return reject(error);
        }
        resolve();
      });
    });
  };

  /**
   * Update password
   * @param params Reset password params
   */
  const resetPassword = (params: ResetPasswordParams): Promise<string> => {
    if (user === null || typeof user.confirmPassword !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    const { verificationCode, newPassword } = params;
    return new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => resolve(data),
        onFailure: reject,
      });
    });
  };

  /**
   * Send MFA code
   */
  const sendMFACode = async (params: SendMFATokenParams): Promise<void> => {
    if (user === null || typeof user.sendMFACode !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.sendMFACode(
        params.confirmationCode,
        {
          onSuccess: (session) => {
            setCurrentSession(session);
            resolve();
          },
          onFailure: (error) => {
            reject(error);
          },
        },
        'SOFTWARE_TOKEN_MFA'
      );
    });
  };

  /**
   * Register new user
   * @param params Sign up params
   * @returns {Promise<void>}
   */
  const signUp = (params: SignUpParams): Promise<void> => {
    const { username, password, email, firstName, lastName, phoneNumber, invite } = params;

    const locale = guessLocale();
    const attrs = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
      new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumber }),
      new CognitoUserAttribute({ Name: 'locale', Value: locale }),
    ];

    if (invite) {
      attrs.push(new CognitoUserAttribute({ Name: 'custom:invite', Value: invite }));
    }

    return new Promise((resolve, reject) => {
      pool.signUp(username, password, attrs, [], (err?: Error, result?: ISignUpResult) => {
        if (err) {
          reject(err);
          return;
        }

        if (result) {
          const credentials = new AuthenticationDetails({
            Username: username,
            Password: password,
          });
          setTempCredentials(credentials);

          setUser(result.user);
          resolve();
        }

        reject(new Error('Invalid result'));
      });
    });
  };

  /**
   * Update cognito attribute
   * Name, mail & phone are updated in the same ways in the
   * preferences page using this fn
   *
   * @param Name Cognit attr name
   * @param Value attr value
   * @returns
   */
  const updateAttribute = async (params: UpdateAttributeParams | Array<UpdateAttributeParams>): Promise<void> => {
    if (user === null || typeof user.updateAttributes !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    const toCognitoAttribute = (attr: UpdateAttributeParams) =>
      new CognitoUserAttribute({
        Name: attr.attributeName,
        Value: attr.value,
      });

    return new Promise((resolve, reject) => {
      const atts = Array.isArray(params) ? params.map(toCognitoAttribute) : [toCognitoAttribute(params)];

      user.updateAttributes(atts, (error) => {
        if (error) {
          return reject(error);
        }

        resolve();
      });
    });
  };

  /**
   * Update user locale [Replace by the above fn]
   * @param locale string
   */
  const updateUserLocale = async (params: UpdateLocaleParams): Promise<void> => {
    if (user === null || typeof user.updateAttributes !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      const atts = [new CognitoUserAttribute({ Name: 'locale', Value: params.locale })];

      user.updateAttributes(atts, (error) => {
        if (error) {
          return reject(error);
        }

        refresh();
        resolve();
      });
    });
  };

  /**
   * Verify attribute using the received code
   */
  const verifyAttribute = (params: VerifyAttributeParams): Promise<void> => {
    if (user === null || typeof user.getAttributeVerificationCode !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.verifyAttribute(params.attributeName, params.code, {
        onSuccess: () => {
          resolve();
          refresh();
        },
        onFailure: (error) => reject(error),
      });
    });
  };

  /**
   * MFA Token verification
   */
  const verifyMFAToken = async (params: VerifyMFATokenParams): Promise<VerifyMFATokenResponse> => {
    if (user === null || typeof user.verifySoftwareToken !== 'function') {
      return Promise.reject(new Error('Invalid user'));
    }

    return new Promise((resolve, reject) => {
      user.verifySoftwareToken(params.totpCode, params.friendlyDeviceName, {
        onSuccess: (value: any) => {
          // types file is wrong
          resolve(value as VerifyMFATokenResponse);
        },
        onFailure: (error) => reject(error),
      });
    });
  };

  /**
   * This function takes a list of systems and returns an array
   * of locations in which the current user has access - having any kind
   * of role inside of it
   * @param {Array<HealthSystem>} systems
   * @returns {Array<Location>} Locations
   */
  const getAvailableLocationsFromSystems = (systems) => {
    validateNotNil<CognitoIdToken>(token);
    const userInfo = token.payload as DecodedUserPayload;
    const userPermissions = new Permissions(userInfo['custom:permissions']);

    if (Array.isArray(systems)) {
      const availableLocations = systems.reduce((availableLocations, system) => {
        (system.locations || []).forEach((location) => {
          // check for any permission
          const hasAnyRole =
            userPermissions.hasGlobalRole(GlobalRole.ADMIN) ||
            userPermissions.hasAnySystemRole(system.id) ||
            userPermissions.hasAnyLocalRole(system.id, location.id);

          if (hasAnyRole) {
            const newItem = {
              systemName: system.name,
              systemId: system.id,
              name: location.name,
              id: location.id,
              address: location.address || system.address,
              timezone: system.timezone,
            };

            availableLocations.push(newItem);
          }
        });

        return availableLocations;
      }, []);

      return availableLocations;
    }

    return null;
  };

  const userHasRole = (role: string, systemId?: string, locationId?: string): boolean => {
    validateNotNil<CognitoIdToken>(token);
    const userInfo = token.payload as DecodedUserPayload;
    const userPermissions = new Permissions(userInfo['custom:permissions']);

    if (systemId) {
      if (locationId) {
        return userPermissions.hasRole(role, systemId, locationId);
      }

      return userPermissions.hasRole(role, systemId);
    }

    let hasRole = false;

    try {
      hasRole = userPermissions.hasRole(role, getUserSystem().id, getUserLocation().id);
    } catch (e) {
      hasRole = userPermissions.hasRole(role);
    }

    return hasRole;
  };

  /**
   * Refresh the existing section after mounting the context if exist
   */
  React.useEffect(() => {
    if (sessionState === SessionState.BOOTING) {
      refresh();
    }
  }, [refresh, sessionState]);

  const session = {
    associateMFAToken,
    changePassword,
    confirmRegistration,
    disableMFA,
    enableMFA,
    forgotPassword,
    getAccountStatus,
    getAvailableLocationsFromSystems,
    getInvite,
    getSubscriptionActionUrl,
    getSubscriptionStatus,
    getSystemIDs,
    getToken,
    getUser,
    getUserData,
    getUserEmail,
    getUserId,
    getUserFamilyName,
    getUserFullName,
    getUserGivenName,
    getUserLocale,
    getUserLocation,
    getUsername,
    getUsernameFromCognitoUser,
    getUserPhoneNumber,
    getUserSystem,
    getUserUnitSystem,
    getUserSubscription,
    getUserPermissions,
    isAdmin,
    isBooting,
    isCognitoUserSet,
    isEmailVerified,
    isLoggedIn,
    isPhoneNumberVerified,
    logIn,
    logOut,
    refresh,
    requestAttributeVerification,
    resendAttributeVerificationCode,
    resendConfirmationCode,
    resetPassword,
    sendMFACode,
    signUp,
    state: sessionState,
    updateAttribute,
    updateUserLocale,
    userHasRole,
    changeUserUnitSystem,
    verifyAttribute,
    verifyMFAToken,
    updateUserBpjsDoctorId,
    getUserBpjsDoctorId,
  };

  return <SessionContext.Provider value={session}>{props.children}</SessionContext.Provider>;
};
