import React, { useState } from 'react'
import { Alert, Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { injectIntl, defineMessages } from 'react-intl'
import { LoadingInline } from 'components'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { useMutation } from '@apollo/client'
import { DELETE_PRICELIST, GET_PRICELISTS } from 'api/request/pricing'

const debug = require('debug')('wd:DeletePricelistModal')

const messages = defineMessages({
  deletingItem: {
    id: 'DeletePricelistModal.deleting_item',
    defaultMessage: 'Deleting item'
  },
  deleteWarning: {
    id: 'DeletePricelistModal.deleting_item_warning',
    defaultMessage: 'This will permanently delete this item. Are you sure?'
  },
  notDeletedError: {
    id: 'DeletePricelistModal.not_deleted',
    defaultMessage: 'Could not delete this pricelist.'
  },
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Please reload'
  },
  cancelButton: {
    id: 'UI.button_cancel',
    defaultMessage: 'Cancel'
  },
  confirmButton: {
    id: 'UI.button_confirm',
    defaultMessage: 'Confirm'
  }
})

const DeletePricelistModal = (props) => {
  const { intl, onDeleted, deletingId, toggleDeletion } = props
  const { formatMessage } = intl

  // error handler
  const [error, setError] = useState()
  const onError = (e) => {
    setError(formatMessage(messages.unexpectedError))
  }

  // delete a pricelist
  const [deletePricelist, { loading: deleting }] = useMutation(DELETE_PRICELIST, { onCompleted: onDeleted, onError })

  // delete this pricelist
  const onDelete = async () => {
    if (!deletingId) return
    try {
      // delete
      deletePricelist({
        variables: { documentId: deletingId },
        update: (cache, result) => {
          try {
            const { pricelists } = cache.readQuery({ query: GET_PRICELISTS })
            debug(pricelists)
            // update the system
            pricelists.docs = pricelists.docs.filter((pricelist) => deletingId !== pricelist.id)
            debug(pricelists)
            cache.writeQuery({
              query: GET_PRICELISTS,
              data: {
                pricelists
              }
            })
          } catch (e) {
            debug(e)
          }
        }
      })
    } catch (e) {
      debug(JSON.stringify(e))
      // show the error
      setError(formatMessage(messages.notDeletedError))
    }
  }

  return (
    <Modal isOpen={!!deletingId} toggle={toggleDeletion} backdrop>
      <ModalHeader toggle={toggleDeletion}>
        <T id={messages.deletingItem.id}>{formatMessage(messages.deletingItem)}</T>
      </ModalHeader>
      <ModalBody>
        <T id={messages.deleteWarning.id}>{formatMessage(messages.deleteWarning)}</T>
      </ModalBody>
      <ModalFooter>
        {error && <Alert className='alert-danger w-100'>{error}</Alert>}
        <ButtonGroup>
          <Button color='secondary' className='mr-1 ' onClick={toggleDeletion}>
            <T id={messages.cancelButton.id}>{formatMessage(messages.cancelButton)}</T>
          </Button>
          <Button onClick={onDelete} color='danger' className='' disabled={deleting}>
            <T id={messages.confirmButton.id}>{formatMessage(messages.confirmButton)}</T>
            {deleting && <LoadingInline />}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

export default injectIntl(DeletePricelistModal)

debug('loaded')
