import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Card, CardBody, CardHeader, CardFooter, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import { Field, Form } from 'react-final-form'
import { BackButton, ErrorViewer, SubmitButton } from 'components'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { useQuickpick, useSession } from 'hooks'
import { composeValidators, minValue, required } from 'utils/formValidators'
import { SUBMIT_BUTTON_STATES, SUBMIT_BUTTON_ACTIONS } from 'utils/components/definitions'
import { DoseUnitSelector, DrugSelector, RouteSelector } from 'components/Pharmacy'
import { FREQUENCY_SHOW } from 'wd-common/src/logic/pharmacy/definitions'
import { useQuickpickActions } from './useQuickpickActions'
import { useHistory } from 'react-router-dom'
import { LocationSelector } from 'components-ts/Selectors'
import { LastUpdated } from 'components-ts'
import { sitemap } from 'components-ts/Routing'

const debug = require('debug')('wd:ProcedureCard')

const messages = defineMessages({
  title: {
    id: 'QuickpickCard.title',
    defaultMessage: 'Quick Pick'
  },
  guideline: {
    id: 'QuickpickCard.guideline_label',
    defaultMessage: 'Guideline'
  },
  name: {
    id: 'QuickpickCard.name_label',
    defaultMessage: 'Name'
  },
  preText: {
    id: 'QuickpickCard.pre_text_label',
    defaultMessage: 'Pre text'
  },
  postText: {
    id: 'QuickpickCard.post_text_label',
    defaultMessage: 'Post text'
  },
  drugNameFormulation: {
    id: 'QuickpickCard.drug_name_formulation_label',
    defaultMessage: 'Drug name and formulation'
  },
  doseRangeMin: {
    id: 'QuickpickCard.dose_range_min_label',
    defaultMessage: 'Dose Range Min'
  },
  doseRangeMax: {
    id: 'QuickpickCard.dose_range_max_label',
    defaultMessage: 'Dose Range Max'
  },
  maxDose: {
    id: 'QuickpickCard.max_dose_label',
    defaultMessage: 'Max Dose'
  },
  maxDoseUnits: {
    id: 'QuickpickCard.max_dose_units_label',
    defaultMessage: 'Max Dose Units'
  },
  route: {
    id: 'QuickpickCard.route_label',
    defaultMessage: 'Route'
  },
  notes: {
    id: 'QuickpickCard.label_notes',
    defaultMessage: 'Notes'
  },
  mustNotBeEmpty: {
    id: 'form_validation.must_not_be_empty_error',
    defaultMessage: 'Must not be empty'
  },
  requiredError: {
    id: 'form_validation.required',
    defaultMessage: 'This field is required'
  },
  minValueError: {
    id: 'form_validation.min_value',
    defaultMessage: 'This field must be greater than 0'
  },
  maxValueError: {
    id: 'form_validation.max_value',
    defaultMessage: 'This field must be greater than: '
  },
  minLengthError: {
    id: 'form_validation.min_length',
    defaultMessage: 'This field must be larger than: '
  },
  frequency: {
    id: 'FrequencySelector.label_frequency',
    defaultMessage: 'Frequency'
  },
  dosesPerDay: {
    id: 'FrequencySelector.label_doses_per_day',
    defaultMessage: 'Doses per day'
  },
  doses: {
    id: 'FrequencySelector.label_doses',
    defaultMessage: 'Doses'
  },
  perDayAddon: {
    id: 'FrequencySelector.label_per_day',
    defaultMessage: 'per day'
  },
  dosesAddon: {
    id: 'FrequencySelector.label_doses_addon',
    defaultMessage: 'doses'
  },
  everyAddon: {
    id: 'FrequencySelector.label_every',
    defaultMessage: 'Every'
  },
  hoursAddon: {
    id: 'FrequencySelector.label_hours',
    defaultMessage: 'hours'
  },
  asNeeded: {
    id: 'FrequencySelector.label_as_needed',
    defaultMessage: 'As needed'
  }
})

export const QuickpickCard = (props) => {
  const {
    isCreating,
    quickpickId
  } = props

  const intl = useIntl()
  const history = useHistory()
  const { getUserLocation } = useSession()
  const currentLocation = getUserLocation()
  const { quickpick } = useQuickpick({ id: quickpickId })

  const initialValues = isCreating
    ? {
      frequency_show: FREQUENCY_SHOW.DOSES_PER_DAY,
      location: currentLocation.id,
      doses_per_day: 1
    }
    : {
      location: quickpick.location,
      drugId: quickpick.drugId,
      quickpickName: quickpick.name,
      pre_text: (quickpick.guideline.pre_text || {}).text,
      name: quickpick.guideline.name.text,
      dose_range_max: quickpick.guideline.dose_range_max,
      dose_range_min: quickpick.guideline.dose_range_min,
      dose_units: quickpick.guideline.dose_units,
      max_dose: quickpick.guideline.max_dose,
      max_dose_units: quickpick.guideline.max_dose_units,
      route: quickpick.guideline.route.text,
      post_text: (quickpick.guideline.post_text || {}).text,
      frequency_show: quickpick.guideline.frequency_show,
      doses_per_day: quickpick.guideline.doses_per_day,
      as_needed: !!quickpick.guideline.as_needed,
      notes: quickpick.notes
    }

  const onCreate = response => {
    const id = (response.createQuickpick || {}).id
    history.replace(sitemap.quickpicks.routes.quickpickEditor.buildPath(id))
  }

  const {
    createQuickpick,
    updateQuickpick,
    loading,
    error
  } = useQuickpickActions({
    formatMessage: intl.formatMessage,
    callback: {
      onCreate
    }
  })

  const onSubmit = (values) => {
    const { quickpickName, drugId, location, notes, ...guideline } = values
    const newValues = {
      drugId,
      name: quickpickName,
      guideline: {
        ...guideline,
        dose_range_min: parseFloat(guideline.dose_range_min),
        dose_range_max: parseFloat(guideline.dose_range_max),
        max_dose: parseFloat(guideline.max_dose),
        doses_per_day: parseInt(guideline.doses_per_day)
      },
      location,
      notes
    }

    if (isCreating) {
      const variables = {
        newQuickpick: {
          ...newValues
        }
      }
      debug({ variables })

      createQuickpick({ variables })
    } else {
      const variables = {
        id: quickpickId,
        updateQuickpick: {
          ...newValues
        }
      }
      debug({ variables })

      updateQuickpick({ variables })
    }
  }

  return <Card className='w-100'>
    <CardHeader className='d-flex justify-content-between align-items-center'>
      <h4 className='m-0 text-muted'>
        <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
      </h4>
      <BackButton />
    </CardHeader>

    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={(props) => {
        const { handleSubmit, pristine, values, form } = props

        const validationRangeMax = composeValidators(
          required(intl.formatMessage(messages.mustNotBeEmpty)),
          (value, allValues) => {
            const doseMin = parseFloat(allValues.dose_range_min) > 0 ? parseFloat(allValues.dose_range_min) : 0

            return minValue(doseMin, false)(intl.formatMessage(messages.maxValueError))(parseFloat(value))
          })
        const validationRangeMin = composeValidators(
          required(intl.formatMessage(messages.mustNotBeEmpty)),
          (value) => {
            return minValue(0, false)(intl.formatMessage(messages.minValueError))(value)
          })

        return <form
          onSubmit={async event => {
            await handleSubmit(event)
          }}>

          <CardBody>
            <Row>
              <Field
                name='location'
                validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
              >
                {({ input, meta }) => (
                  <Col sm={6}>
                    <LocationSelector
                      {...input}
                    />
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                  </Col>
                )}
              </Field>
            </Row>
            <Row>
              <Field
                name='drugId'
                validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
              >
                {({ input, meta }) => (
                  <Col sm={6}>
                    <DrugSelector
                      defaultValue={meta.initial}
                      onChange={(drugId, name) => {
                        input.onChange(drugId)
                        form.change('name', name)
                      }}
                      className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                    />
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                  </Col>
                )}
              </Field>
              <Field
                name='quickpickName'
                validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
              >
                {({ input, meta }) => (
                  <Col sm={6}>
                    <FormGroup>
                      <Label><T id={messages.name.id}>{intl.formatMessage(messages.name)}</T></Label>
                      <Input
                        {...input}
                        className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                      />
                    </FormGroup>
                    {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                  </Col>
                )}
              </Field>
            </Row>
            <div className='rounded bg-light px-2 py-1'>
              <p className='font-weight-bold'>
                <T id={messages.guideline.id}>{intl.formatMessage(messages.guideline)}</T>
              </p>

              <Row >
                <Field
                  name='pre_text'
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <Label >
                          <T id={messages.preText.id}>{intl.formatMessage(messages.preText)}</T>
                        </Label>
                        <InputGroup>
                          <Input {...input} />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Field>
                <Field
                  name='name'
                  validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <Label >
                          <T id={messages.name.id}>{intl.formatMessage(messages.name)}</T>
                        </Label>
                        <InputGroup>
                          <Input
                            {...input}
                            className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                          />
                        </InputGroup>
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex'>{meta.error}</div>}
                      </FormGroup>
                    </Col>
                  )}
                </Field>
                <Field
                  name='post_text'
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <Label >
                          <T id={messages.postText.id}>{intl.formatMessage(messages.postText)}</T>
                        </Label>
                        <InputGroup>
                          <Input
                            {...input}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Field>
              </Row>
              <Row>
                <Field
                  name='dose_range_min'
                  validate={validationRangeMin}
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <Label >
                          <T id={messages.doseRangeMin.id}>{intl.formatMessage(messages.doseRangeMin)}</T>
                        </Label>
                        <InputGroup>
                          <Input
                            {...input}
                            type='number'
                            className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                            min={0}
                            step={0.01}
                          />
                        </InputGroup>
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex' >{meta.error}</div>}
                      </FormGroup>
                    </Col>
                  )}
                </Field>
                <Field
                  name='dose_range_max'
                  validate={validationRangeMax}
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <Label >
                          <T id={messages.doseRangeMax.id}>{intl.formatMessage(messages.doseRangeMax)}</T>
                        </Label>
                        <InputGroup>
                          <Input
                            {...input}
                            type='number'
                            className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                            min={0}
                            step={0.01}
                          />
                        </InputGroup>
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex' >{meta.error}</div>}
                      </FormGroup>
                    </Col>
                  )}
                </Field>

                <Field
                  name='dose_units'
                  validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <DoseUnitSelector
                          {...input}
                          defaultValue={meta.initial}
                          className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                        />
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                      </FormGroup>
                    </Col>
                  )}
                </Field>
              </Row>
              <Row>
                <Field
                  name='max_dose'
                  validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <Label >
                          <T id={messages.maxDose.id}>{intl.formatMessage(messages.maxDose)}</T>
                        </Label>
                        <InputGroup>
                          <Input
                            {...input}
                            type='number'
                            className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                            min={0}
                            step={0.01}
                          />
                        </InputGroup>
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex' >{meta.error}</div>}
                      </FormGroup>
                    </Col>
                  )}
                </Field>
                <Field
                  name='max_dose_units'
                  validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
                >
                  {({ input, meta }) => (
                    <Col sm={4} >
                      <FormGroup>
                        <DoseUnitSelector
                          {...input}
                          defaultValue={meta.initial}
                          className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''} `}
                          customLabel={<T id={messages.maxDoseUnits.id}>{intl.formatMessage(messages.maxDoseUnits)}</T>}
                        />
                        {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                      </FormGroup>
                    </Col>
                  )}
                </Field>

                <Field
                  name='route'
                  validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
                >
                  {({ input, meta }) => {
                    return <Col sm={4} style={{ zIndex: 0 }}>
                      <RouteSelector
                        {...input}
                        className={`${meta.error && meta.touched ? 'border border-danger rounded' : ''}`}
                      />
                      {meta.error && meta.touched && <div className='invalid-feedback d-flex' style={{ marginTop: '-1.2em' }}>{meta.error}</div>}
                    </Col>
                  }}
                </Field>
              </Row>
              <Row className='d-flex justify-content-end'>

                <Col sm={6}>
                  <FormGroup className='mb-0'>
                    <Field
                      name='frequency_show'
                      type='radio'
                      validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
                    >
                      {({ input, meta }) => (
                        <Label className='d-flex'>
                          <InputGroup className='d-flex align-items-center justify-content-around'>
                            <Label check className='mx-1'>
                              <Input
                                onChange={input.onChange}
                                type='radio'
                                name='frequency'
                                value={FREQUENCY_SHOW.FREQUENCY}
                                defaultChecked={FREQUENCY_SHOW.FREQUENCY === meta.initial}
                              />
                              <T id={messages.frequency.id}>{intl.formatMessage(messages.frequency)}</T>
                            </Label>
                            <Label check className='mx-1'>
                              <Input
                                onChange={input.onChange}
                                type='radio'
                                name='frequency'
                                value={FREQUENCY_SHOW.DOSES_PER_DAY}
                                defaultChecked={FREQUENCY_SHOW.DOSES_PER_DAY === meta.initial}
                              />
                              <T id={messages.dosesPerDay.id}>{intl.formatMessage(messages.dosesPerDay)}</T>
                            </Label>
                            <Label check className='mx-1'>
                              <Input
                                onChange={input.onChange}
                                type='radio'
                                name='frequency'
                                value={FREQUENCY_SHOW.DOSES}
                                defaultChecked={FREQUENCY_SHOW.DOSES === meta.initial}
                              />
                              <T id={messages.doses.id}>{intl.formatMessage(messages.doses)}</T>
                            </Label>
                          </InputGroup>
                        </Label>
                      )}
                    </Field>
                    <Field
                      name='doses_per_day'
                      validate={required(intl.formatMessage(messages.mustNotBeEmpty))}
                      parse={inputToForm(values.frequency_show)}
                      format={fromToInput(values.frequency_show)}
                    >
                      {({ input, meta }) => {
                        return values.frequency_show === FREQUENCY_SHOW.DOSES_PER_DAY || values.frequency_show === FREQUENCY_SHOW.DOSES
                          ? <InputGroup>
                            <Input
                              type='number'
                              min={0}
                              step={1}
                              {...input}
                            />
                            <InputGroupAddon addonType='append'>
                              <div style={{
                                zIndex: 0
                              }} className={'btn btn-secondary '} disabled>
                                {values.frequency_show === FREQUENCY_SHOW.DOSES_PER_DAY
                                  ? <T id={messages.perDayAddon.id}>{intl.formatMessage(messages.perDayAddon)}</T>
                                  : <T id={messages.dosesAddon.id}>{intl.formatMessage(messages.dosesAddon)}</T>}
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                          : <InputGroup>
                            <InputGroupAddon addonType='prepend'>
                              <div style={{
                                zIndex: 0
                              }} className={'btn btn-secondary '} disabled>
                                <T id={messages.everyAddon.id}>{intl.formatMessage(messages.everyAddon)}</T>
                              </div>
                            </InputGroupAddon>
                            <Input
                              type='number'
                              min={0}
                              step={1}
                              {...input}
                            />
                            <InputGroupAddon addonType='append'>
                              <div style={{
                                zIndex: 0
                              }} className={'btn btn-secondary  '} disabled>
                                <T id={messages.hoursAddon.id}>{intl.formatMessage(messages.hoursAddon)}</T>
                              </div>
                            </InputGroupAddon>
                          </InputGroup>
                      }}
                    </Field>
                    <Field
                      name='as_needed'
                      type='checkbox'
                    >
                      {({ input, meta }) => (
                        <Label check className='d-flex mt-1 ml-4 pointer'>
                          <Input
                            type='checkbox'
                            {...input}
                          />
                          <T id={messages.asNeeded.id}>{intl.formatMessage(messages.asNeeded)}</T>
                        </Label>
                      )}
                    </Field>

                  </FormGroup>

                </Col>

                <Col sm={6}>
                  <Field
                    name='notes'
                  >
                    {({ input, meta }) => (
                      <FormGroup>
                        <Label for='notes'>
                          <T id={messages.notes.id}>{intl.formatMessage(messages.notes)}</T>
                        </Label>
                        <Input {...input} type='text' />
                      </FormGroup>
                    )}
                  </Field>
                </Col>
              </Row>
              {error && <ErrorViewer error={error} className='mt-2' />}

            </div>
          </CardBody>

          <CardFooter className='d-flex justify-content-between align-items-center'>
            <SubmitButton
              state={loading.creating || loading.deleting || loading.updating
                ? SUBMIT_BUTTON_STATES.LOADING
                : SUBMIT_BUTTON_STATES.IDLE
              }
              action={isCreating ? SUBMIT_BUTTON_ACTIONS.CREATE : SUBMIT_BUTTON_ACTIONS.EDIT}
              type='submit'
              disabled={pristine || loading.creating || loading.deleting}
            />
            {quickpick && quickpick.updated && <LastUpdated signature={quickpick.updated} />}
          </CardFooter>

        </form>
      }} />
  </Card>
}

const fromToInput = frequencyShow => dosesPerDay => {
  if (!dosesPerDay || dosesPerDay === 0) return 0

  if (frequencyShow === FREQUENCY_SHOW.FREQUENCY) {
    const period = Math.abs(24 / dosesPerDay) < 1
      ? Math.abs(24 / dosesPerDay).toPrecision(3)
      : Math.abs(24 / dosesPerDay)

    return isNaN(period) ? 0 : period
  }

  return dosesPerDay
}

const inputToForm = frequencyShow => dosesPerDay => {
  if (!dosesPerDay || dosesPerDay === 0) return 0

  if (frequencyShow === FREQUENCY_SHOW.FREQUENCY) {
    const period = 24 / dosesPerDay

    return isNaN(period) ? 0 : period
  }

  return dosesPerDay
}
