export interface CognitoConfig {
  UserPoolId: string;
  ClientId: string;
}

export interface ServerConfig {
  cognito: CognitoConfig;
  region: string;
  releaseType: string;
  release: string;
  role: string;
  landing: string;
  sentryDsn: string;
  bridgingUrl: string;
  [x: string | number | symbol]: unknown;
}

export interface WindowWithEnvs extends Window {
  serverConfig?: ServerConfig;
}

export const getEnvs = (): ServerConfig => {
  const { cognito, region, releaseType, release, role, sentryDsn, bridgingUrl } =
    (window as WindowWithEnvs).serverConfig ?? {};

  return {
    bridgingUrl: bridgingUrl ?? process.env.REACT_APP_DEFAULT_BRIDGING_URL,
    cognito: {
      UserPoolId: cognito?.UserPoolId ?? process.env.REACT_APP_DEFAULT_USER_POOL_ID,
      ClientId: cognito?.ClientId ?? process.env.REACT_APP_DEFAULT_CLIENT_ID,
    },
    region: region ?? process.env.REGION,
    releaseType: releaseType ?? process.env.REACT_APP_DEFAULT_RELEASE_TYPE,
    release: release ?? process.env.REACT_APP_DEFAULT_RELEASE,
    role: role ?? process.env.ROLE,
    sentryDsn: sentryDsn ?? process.env.SENTRY_DSN,
    landing: 'https://walkingdocs.org',
  } as ServerConfig;
};
