import { Nullable } from 'components-ts';
import { Signature, TranslatableText } from './common';
import { FullExamWithoutId } from './exams';
import { VitalType } from './triage';

export enum NoteType {
  SURVEY = 'survey',
  NURSE = 'nursing',
  DAILY_PROGRESS = 'dailyProgress',
  INCIDENT = 'incident',
  SURGICAL = 'surgical',
  PROCEDURE = 'procedure',
  CONSULT = 'consult',
  DISCHARGE = 'discharge',
  PROGRESS_NOTE = 'progressNote',
}

export enum NoteItemType {
  HEADER = 'header',
  PARAGRAPH = 'paragraph',
  INPUT = 'input',
  RANGE = 'range',
  CHECKBOX = 'checkbox',
  SELECTOR = 'selector',
  SPACER = 'spacer',
  PHYSICAL_EXAM = 'physicalExam',
  VITAL = 'vital',
  GROUP = 'group',
}

export enum NoteItemInputType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMBER = 'number',
  DATE = 'date',
}

export enum Layout {
  SINGLE_COLUMN = 'single_column',
  TWO_COLUMNS = 'two_columns',
  THREE_COLUMNS = 'three_columns',
}

/**
 * Blocks
 */
export type HeaderItem = {
  entryId: string;
  type: NoteItemType.HEADER;
  text: TranslatableText;
  level: number;
  groupId: Nullable<string>;
};

export type ParagraphItem = {
  entryId: string;
  type: NoteItemType.PARAGRAPH;
  text: TranslatableText;
  groupId: Nullable<string>;
};

export type InputItem = {
  entryId: string;
  type: NoteItemType.INPUT;
  inputType: NoteItemInputType;
  value: string;
  label: Nullable<TranslatableText>;
  groupId: Nullable<string>;
};

export type RangeItem = {
  entryId: string;
  type: NoteItemType.RANGE;
  value: string;
  label: Nullable<TranslatableText>;
  min: string;
  max: string;
  step: string;
  groupId: Nullable<string>;
};

export type CheckboxItem = {
  entryId: string;
  type: NoteItemType.CHECKBOX;
  checked: boolean;
  label: Nullable<TranslatableText>;
  score: Nullable<number>;
  groupId: Nullable<string>;
};

export type SelectorItem = {
  entryId: string;
  type: NoteItemType.SELECTOR;
  value: Nullable<string>;
  label: Nullable<TranslatableText>;
  options: Array<TranslatableText>;
  groupId: Nullable<string>;
};

export type SpacerItem = {
  entryId: string;
  type: NoteItemType.SPACER;
  level: number;
  groupId: Nullable<string>;
};

export type ExamItem = {
  entryId: string;
  type: NoteItemType.PHYSICAL_EXAM;
  exam: FullExamWithoutId;
  groupId: Nullable<string>;
};

export type VitalItem = {
  entryId: string;
  type: NoteItemType.VITAL;
  vitalType: VitalType;
  kg: Nullable<number>;
  cm: Nullable<number>;
  percent: Nullable<number>;
  breaths_per_minute: Nullable<number>;
  bpm: Nullable<number>;
  systolic: Nullable<number>;
  diastolic: Nullable<number>;
  degrees_c: Nullable<number>;
  groupId: Nullable<string>;
};

export type GroupItem = {
  entryId: string;
  type: NoteItemType.GROUP;
  label: TranslatableText;
  layout: Nullable<Layout>;
};

export type NoteTemplateItem =
  | HeaderItem
  | ParagraphItem
  | InputItem
  | RangeItem
  | CheckboxItem
  | SelectorItem
  | SpacerItem
  | ExamItem
  | VitalItem
  | GroupItem;

export interface NoteTemplate {
  id: string;
  type: NoteType;
  name: TranslatableText;
  description: Nullable<TranslatableText>;
  items: Array<NoteTemplateItem>;
  created: Nullable<Signature>;
  updated: Nullable<Signature>;
}

export interface NoteComment {
  text: string;
  created: Signature;
}

export interface Note extends NoteTemplate {
  templateId: string;
  created: Signature;
  filled: Nullable<Signature>;
  comments: Nullable<Array<NoteComment>>;
}
