export enum Integrations {
  BPJS = 'bpjs',
  XENDIT = 'xendit',
  SATU_SEHAT = 'satu-sehat',
}

export interface BaseIntegrationSettings {
  isEnabled: boolean;
}

export interface BPJSSettings extends BaseIntegrationSettings {}

export interface XenditSettings extends BaseIntegrationSettings {}
