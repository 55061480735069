import React from 'react';
import { useSystems } from './useSystems';
import { Integrations } from 'api/interfaces';
import { BPJSTokenContextProvider } from 'components-ts/Bpjs/BPJSTokenContext';

export const IntegrationsHoC: React.FC = (props) => {
  const { systemHasIntegration } = useSystems();

  // Why this way? So we can nest more providers if necessary
  let children = <>{props.children}</>;

  if (systemHasIntegration(Integrations.BPJS)) {
    children = <BPJSTokenContextProvider>{children}</BPJSTokenContextProvider>;
  }

  return children;
};
