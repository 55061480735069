import { useMutation } from '@apollo/client'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
  UPDATE_NUMERICAL_RESULT,
  UPDATE_CATEGORICAL_RESULT,
  UPDATE_CATEGORICAL_BASED_ON_NUMBER_RESULT
} from 'api/request/lab'
import { graphQLErrorFormatter } from 'utils/errors'
import { STUDY_RESULT_TYPE } from 'wd-common/src/logic/lab/definitions'

const messages = defineMessages({
  errorAddingResult: {
    id: 'StudyItemEditor.invalid_error_adding_result',
    defaultMessage: 'Could not add the result. Try again'
  }
})

export const checkForRange = (range, value) => {
  const { maximum, minimum } = range
  return value >= minimum && value <= maximum
}

export const getOutcomeFromNumber = (item, value) => {
  const { result } = item
  const { reference } = result
  const { possibleOutcomesWithRange } = reference
  const wantedOutcomeWithRange = possibleOutcomesWithRange.find(outcomeWithRange => checkForRange(outcomeWithRange.range, value))
  const wantedOutcome = wantedOutcomeWithRange ? wantedOutcomeWithRange.outcome : { text: '' }

  return wantedOutcome.text
}

export const normalizeDataByType = (item, data) => {
  const defaultValues = {
    itemId: item.templateItemId
  }

  switch (item.type) {
    case STUDY_RESULT_TYPE.CATEGORICAL_BASED_ON_NUMBER:
      return { ...defaultValues, number: data.number, outcome: getOutcomeFromNumber(item, data.number) }

    case STUDY_RESULT_TYPE.NUMERICAL:
      return { ...defaultValues, number: data.number }

    case STUDY_RESULT_TYPE.CATEGORICAL:
      return { ...defaultValues, outcome: data.outcome }

    default:
      return {}
  }
}

export const useStudyItemsActions = (params) => {
  const { studyId } = params

  const intl = useIntl()
  const [error, setError] = React.useState()

  const errorFormatter = errorCode => {
    switch (errorCode) {
      case 'error_adding_study_result':
        return {
          id: errorCode,
          message: intl.formatMessage(messages.errorAddingResult)
        }
      default:
        return { id: '' }
    }
  }

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter)
    setError(translatedErrors)
  }

  const onCompleted = () => {
    setError()
  }

  const [updateNumericalResultMutation] = useMutation(UPDATE_NUMERICAL_RESULT, {
    onCompleted,
    onError
  })

  const [updateCategoricalResultMutation] = useMutation(UPDATE_CATEGORICAL_RESULT, {
    onCompleted,
    onError
  })

  const [updateCategoricalNumericalResultMutation] = useMutation(UPDATE_CATEGORICAL_BASED_ON_NUMBER_RESULT, {
    onCompleted,
    onError
  })

  const updateNumericalResult = (params) => {
    const { itemId, number } = params
    const newResult = {
      number: Number(number)
    }
    const variables = {
      id: studyId,
      itemId,
      newResult
    }
    updateNumericalResultMutation({ variables })
  }

  const updateCategoricalResult = (params) => {
    const { itemId, outcome } = params
    const newResult = {
      outcome
    }
    const variables = {
      id: studyId,
      itemId,
      newResult
    }
    updateCategoricalResultMutation({ variables })
  }

  const updateCategoricalNumericalResult = (params) => {
    const { itemId, outcome, number } = params

    const newResult = {
      outcome,
      number: Number(number)
    }
    const variables = {
      id: studyId,
      itemId,
      newResult
    }
    updateCategoricalNumericalResultMutation({ variables })
  }

  const getMutationByType = type => {
    switch (type) {
      case STUDY_RESULT_TYPE.NUMERICAL:
        return updateNumericalResult

      case STUDY_RESULT_TYPE.CATEGORICAL:
        return updateCategoricalResult

      case STUDY_RESULT_TYPE.CATEGORICAL_BASED_ON_NUMBER:
        return updateCategoricalNumericalResult

      default:
        return () => { }
    }
  }

  const getInitialValues = item => {
    const { type } = item
    const { value } = item.result

    if (value === null) {
      return {}
    }

    switch (type) {
      case STUDY_RESULT_TYPE.CATEGORICAL_BASED_ON_NUMBER:
      case STUDY_RESULT_TYPE.NUMERICAL:
        return { number: value.number }

      case STUDY_RESULT_TYPE.CATEGORICAL:
        return { outcome: value.outcome }

      default:
        return {}
    }
  }

  return {
    updateNumericalResult,
    updateCategoricalResult,
    updateCategoricalNumericalResult,
    getMutationByType,
    getInitialValues,
    error
  }
}
