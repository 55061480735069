import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import { LoadingInline } from 'components';
import {
  FaRegCheckCircle as AckIcon,
  FaBan as CancelIcon,
  FaRegComment as CommentIcon,
  FaEllipsisV as DotsIcon,
  FaRegEnvelope as ReceiveIcon,
  FaSyringe as AdministerIcon,
} from 'react-icons/fa';
import { Form, Field } from 'react-final-form';
import { useSession } from 'components-ts/Auth';
import {
  useAcknowledgeOrder,
  useAddCommentToOrder,
  useAdministerOrder,
  useCancelMix,
  useCancelOrder,
  useCancelRX,
  useReceiveOrder,
} from './useOrderActions';
import { useDisclosure } from 'components-ts/useDisclosure';
import { ConfirmationModal } from 'components-ts/ConfirmationModal';
import { FullProcedureOrder, FullStudyOrder, MedicineOrder, DrNurseOrder, OrderType } from 'api/interfaces';
import { ErrorViewer } from 'components-ts/ErrorViewer';

const useDisplayPermissions = () => {
  const { userHasRole } = useSession();

  return {
    canAcknowledgeOrder:
      userHasRole('Admin') || userHasRole('SystemAdmin') || userHasRole('LocalAdmin') || userHasRole('Nurse'),
    canAdministerOrder:
      userHasRole('Admin') || userHasRole('SystemAdmin') || userHasRole('LocalAdmin') || userHasRole('Nurse'),
    canReceiveOrder:
      userHasRole('Admin') || userHasRole('SystemAdmin') || userHasRole('LocalAdmin') || userHasRole('Lab'),
    canAddComment:
      userHasRole('Admin') ||
      userHasRole('SystemAdmin') ||
      userHasRole('LocalAdmin') ||
      userHasRole('Lab') ||
      userHasRole('Nurse'),
    canCancelOrder:
      userHasRole('Admin') || userHasRole('SystemAdmin') || userHasRole('LocalAdmin') || userHasRole('Doctor'),
  };
};

const messages = defineMessages({
  newCommenttitle: {
    id: 'OrderActions.new_comment_title',
    defaultMessage: 'New Comment',
  },
  commentLabel: {
    id: 'OrderActions.comment_label',
    defaultMessage: 'Comment',
  },
  cancelButton: {
    id: 'UI.cancel_button',
    defaultMessage: 'Cancel',
  },
  acknowledgedButton: {
    id: 'UI.acknowledged_button',
    defaultMessage: 'Acknowledge',
  },
  administerButton: {
    id: 'UI.administer_button',
    defaultMessage: 'Administer',
  },
  receiveButton: {
    id: 'UI.receive_button',
    defaultMessage: 'Receive',
  },
  commentButton: {
    id: 'UI.comment_button',
    defaultMessage: 'Comment',
  },
  ackConfirmationMessage: {
    id: 'OrderActions.acknowledge_order_warning',
    defaultMessage: 'This item will permanently set as acknowledged the order. Are you sure?',
  },
  administerConfirmationMessage: {
    id: 'OrderActions.administer_order_warning',
    defaultMessage: 'This item will permanently set as administered. Are you sure?',
  },
  receiveConfirmationMessage: {
    id: 'OrderActions.receive_order_warning',
    defaultMessage: 'This item will permanently set as received. Are you sure?',
  },
  cancelConfirmationMessage: {
    id: 'OrderActions.cancel_order_warning',
    defaultMessage: 'This will permanently cancel this order. Are you sure?',
  },
});

interface CommentModalProps {
  patientId: string;
  visitId: string;
  orderId: string;
  onToggle: () => void;
}

const CommentModal: React.FC<CommentModalProps> = (props) => {
  const { patientId, visitId, orderId, onToggle } = props;

  const intl = useIntl();

  const { isLoading, onSubmit, error } = useAddCommentToOrder({
    patientId,
    visitId,
    orderId,
    onCompleted: () => onToggle(),
  });

  return (
    <Modal isOpen={true} toggle={onToggle} autoFocus={true}>
      <ModalHeader toggle={onToggle}>
        <T id={messages.newCommenttitle.id}>{intl.formatMessage(messages.newCommenttitle)}</T>
      </ModalHeader>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, form, submitting, pristine }) => {
          return (
            <form
              onSubmit={async (event) => {
                await handleSubmit(event);

                const { valid } = form.getState();
                if (valid) {
                  form.resetFieldState('newComment');
                  form.reset();
                }
              }}
            >
              <Field name="newComment">
                {({ input }) => (
                  <Col>
                    <Input
                      {...input}
                      autoComplete="off"
                      placeholder={intl.formatMessage(messages.commentLabel)}
                      className="form-control mt-2"
                      maxLength={120}
                      autoFocus={true}
                    />
                  </Col>
                )}
              </Field>
              <ModalBody>{error && <ErrorViewer error={error} />}</ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  className={isLoading ? 'mr-1' : ''}
                  type="submit"
                  disabled={submitting || pristine}
                >
                  <T id={messages.commentButton.id}>{intl.formatMessage(messages.commentButton)}</T>
                  {isLoading && <LoadingInline />}
                </Button>
              </ModalFooter>
            </form>
          );
        }}
      />
    </Modal>
  );
};

type Order = FullProcedureOrder | FullStudyOrder | MedicineOrder | DrNurseOrder;

interface OrderActionsProps {
  patientId: string;
  visitId: string;
  order: Order;
  isDisabled?: boolean;
  isCancellable?: boolean;
  isAcknowledgeable?: boolean;
  isReceivable?: boolean;
  isCommentable?: boolean;
  isAdministrable?: boolean;
  className?: string;
}

export const OrderActionMenu: React.FC<OrderActionsProps> = (props) => {
  const {
    patientId,
    visitId,
    order,
    isDisabled,
    isCancellable,
    isAcknowledgeable,
    isReceivable,
    isCommentable,
    isAdministrable,
  } = props;

  const intl = useIntl();
  const permissions = useDisplayPermissions();

  /**
   * All disclousures and actions should live here because
   * dropdown items are unmounted when we click outside of it and
   * all these actions are confirmed from a dialog/modal
   */
  const { isOpen: isAcknowledgeOpen, onClose: onAcknowledgeClose, onOpen: onAcknowledgeOpen } = useDisclosure();
  const {
    onAcknowledge,
    isLoading: isAcknowledging,
    error: ackError,
  } = useAcknowledgeOrder({
    patientId,
    visitId,
    orderId: order.id,
    onCompleted: onAcknowledgeClose,
  });

  const ackConfirmationProps = {
    onConfirm: onAcknowledge,
    message: messages.ackConfirmationMessage,
    loading: isAcknowledging,
    error: ackError,
    onClose: onAcknowledgeClose,
    className: 'btn-success',
  };

  // administer
  const { isOpen: isAdministerOpen, onClose: onAdministerClose, onOpen: onAdministerOpen } = useDisclosure();
  const {
    onAdminister,
    isLoading: isAdministerging,
    error: administerError,
  } = useAdministerOrder({
    patientId,
    visitId,
    orderId: order.id,
    onCompleted: onAdministerClose,
  });

  const administerConfirmationProps = {
    onConfirm: onAdminister,
    message: messages.administerConfirmationMessage,
    loading: isAdministerging,
    error: administerError,
    onClose: onAdministerClose,
    className: 'btn-success',
  };

  // add comment to order
  const { isOpen: isCommentOpen, onClose: onCommentClose, onOpen: onCommentOpen } = useDisclosure();

  // receive order
  const { isOpen: isReceiveOpen, onClose: onReceiveClose, onOpen: onReceiveOpen } = useDisclosure();
  const {
    onReceive,
    isLoading: isReceiving,
    error: receivingError,
  } = useReceiveOrder({
    patientId,
    visitId,
    orderId: order.id,
    onCompleted: onReceiveClose,
  });

  const receiveConfirmationProps = {
    onConfirm: onReceive,
    message: messages.receiveConfirmationMessage,
    loading: isReceiving,
    error: receivingError,
    onClose: onReceiveClose,
    className: 'btn-success',
  };

  // cancel order
  const { isOpen: isCancelOpen, onClose: onCancelClose, onOpen: onCancelOpen } = useDisclosure();
  const {
    onCancel,
    isLoading: isCancelling,
    error: cancellingError,
  } = useCancelOrder({
    patientId,
    visitId,
    orderId: order.id,
    onCompleted: onCancelClose,
  });

  const cancelOrderConfirmationProps = {
    onConfirm: onCancel,
    message: messages.cancelConfirmationMessage,
    loading: isCancelling,
    error: cancellingError,
    onClose: onCancelClose,
  };

  const { acknowledged, canceled, type } = order;
  const administered = 'administered' in order ? order.administered : null;

  const canACK = permissions.canAcknowledgeOrder && isAcknowledgeable && !acknowledged && !canceled;
  const canAdminister = permissions.canAdministerOrder && isAdministrable && !administered && !canceled;
  const canReceive =
    type === OrderType.STUDY_ORDER &&
    permissions.canReceiveOrder &&
    isReceivable &&
    !(order as FullStudyOrder).received &&
    !canceled;

  const canComment = permissions.canAddComment && isCommentable;
  const canCancel = permissions.canCancelOrder && isCancellable && !canceled && !administered;
  return (
    <>
      <UncontrolledDropdown
        direction="left"
        setActiveFromChild
        onClick={(e) => e.stopPropagation()}
        onTouchEnd={(e) => e.stopPropagation()}
        className={props.className}
      >
        <DropdownToggle size="md" className="btn-ghost-secondary">
          <DotsIcon />
        </DropdownToggle>
        <DropdownMenu>
          {canACK && (
            <DropdownItem
              onClick={onAcknowledgeOpen}
              className={'d-flex align-items-center justify-content-start'}
              disabled={isDisabled}
            >
              <AckIcon className="mr-2" />
              <T id={messages.acknowledgedButton.id}>{intl.formatMessage(messages.acknowledgedButton)}</T>
            </DropdownItem>
          )}
          {canAdminister && (
            <DropdownItem
              onClick={onAdministerOpen}
              className={'d-flex align-items-center justify-content-start'}
              disabled={isDisabled}
            >
              <AdministerIcon className="mr-2" />
              <T id={messages.acknowledgedButton.id}>{intl.formatMessage(messages.administerButton)}</T>
            </DropdownItem>
          )}
          {canReceive && (
            <DropdownItem
              onClick={onReceiveOpen}
              className={'d-flex align-items-center justify-content-start'}
              disabled={isDisabled}
            >
              <ReceiveIcon className="mr-2" />
              <T id={messages.receiveButton.id}>{intl.formatMessage(messages.receiveButton)}</T>
            </DropdownItem>
          )}
          {canComment && (
            <DropdownItem
              onClick={onCommentOpen}
              className={'d-flex align-items-center justify-content-start'}
              disabled={isDisabled}
            >
              <CommentIcon className="mr-2" />
              <T id={messages.commentButton.id}>{intl.formatMessage(messages.commentButton)}</T>
            </DropdownItem>
          )}

          {canCancel && (
            <DropdownItem
              onClick={onCancelOpen}
              className={'d-flex align-items-center justify-content-start bg-danger'}
              disabled={isDisabled}
            >
              <CancelIcon className="mr-2" />
              <T id={messages.cancelButton.id}>{intl.formatMessage(messages.cancelButton)}</T>
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
      {isCommentOpen && (
        <CommentModal visitId={visitId} orderId={order.id} patientId={patientId} onToggle={onCommentClose} />
      )}
      {isAcknowledgeOpen && <ConfirmationModal {...ackConfirmationProps} />}
      {isAdministerOpen && <ConfirmationModal {...administerConfirmationProps} />}
      {isReceiveOpen && <ConfirmationModal {...receiveConfirmationProps} />}
      {isCancelOpen && <ConfirmationModal {...cancelOrderConfirmationProps} />}
    </>
  );
};

/**
 * Prescriptions
 */

interface PrescriptionActionsProps {
  orderId: string;
  isMix?: boolean;
  isDisabled?: boolean;
  isCanceled?: boolean;
  isCancellable?: boolean;
  className?: string;
}

export const PrescriptionActions: React.FC<PrescriptionActionsProps> = (props) => {
  const { orderId, isMix, isDisabled, isCancellable, isCanceled } = props;

  const intl = useIntl();
  const permissions = useDisplayPermissions();

  /**
   * All disclousures and actions should live here because
   * dropdown items are unmounted when we click outside of it and
   * all these actions are confirmed from a dialog/modal
   */
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    onCancelRX,
    isLoading: isCancelling,
    error: canellingError,
  } = useCancelRX({
    id: orderId,
    onCompleted: onClose,
  });

  const {
    onCancelMix,
    isLoading: isCancellingMix,
    error: mixError,
  } = useCancelMix({
    mixId: orderId,
    onCompleted: onClose,
  });

  const cancelOrderConfirmationProps = {
    onConfirm: isMix ? onCancelMix : onCancelRX,
    message: messages.cancelConfirmationMessage,
    loading: isCancelling || isCancellingMix,
    error: mixError || canellingError,
    onClose,
  };

  const canCancel = permissions.canCancelOrder && isCancellable && !isCanceled;
  return (
    <>
      <UncontrolledDropdown
        direction="left"
        setActiveFromChild
        onClick={(e) => e.stopPropagation()}
        onTouchEnd={(e) => e.stopPropagation()}
        className={props.className}
      >
        <DropdownToggle size="md" className="btn-ghost-secondary">
          <DotsIcon />
        </DropdownToggle>
        <DropdownMenu>
          {canCancel && (
            <DropdownItem
              onClick={onOpen}
              className={'d-flex align-items-center justify-content-start bg-danger'}
              disabled={isDisabled}
            >
              <CancelIcon className="mr-2" />
              <T id={messages.cancelButton.id}>{intl.formatMessage(messages.cancelButton)}</T>
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
      {isOpen && <ConfirmationModal {...cancelOrderConfirmationProps} />}
    </>
  );
};
