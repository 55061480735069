import React from 'react'
import { injectIntl } from 'react-intl'

const SeparatedList = (props) => {
  const { list, separator = ',', className, listStyle } = props

  return (
    <ul style={{ listStyle: listStyle || 'none' }} className={`my-0 ${listStyle ? 'px-3' : ''} ${className}`}>
      <li>
        {list.map((listItem, index) => {
          return (
            <span key={index}>
              {listItem}
              {index < list.length - 1 && `${separator} `}
            </span>
          )
        })}
      </li>
    </ul>
  )
}

export default injectIntl(SeparatedList)
