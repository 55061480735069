import React from 'react';
import { FullHistory, FullPatientWithActiveVisit } from '../../api/interfaces';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_ALL_PATIENT_DATA } from 'api/request/patients';
import { Loading } from 'components-ts/Loading';
import { NotFound } from 'components-ts/NotFound';

const messages = defineMessages({
  notFound: {
    id: 'Patient.patient_not_found',
    defaultMessage: 'Patient not found',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

export interface PatientVariables {
  id: string;
}

interface PatientQueryData {
  patient: FullPatientWithActiveVisit;
  patientHistory: FullHistory;
}

export type UsePatientParams = QueryHookOptions<PatientQueryData, PatientVariables> & {
  id: string;
};

export interface PatientWithActiveVisitAndHistoryContextValue {
  data: PatientQueryData;
  isLoading: boolean;
  error: Nullable<MessageDescriptor>;
  refetch: () => void;
}

export const PatientContext = React.createContext<Nullable<PatientWithActiveVisitAndHistoryContextValue>>(null);
export const PatientProvider: React.FC<UsePatientParams> = (props) => {
  const { id, children, ...rest } = props;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const translateError = (code) => {
    switch (code) {
      case 'patient_not_found':
        return setError(messages.notFound);

      default:
        return setError(messages.internalServerError);
    }
  };

  const onError = (errors) => {
    const firstError = extractFirstErrorCode(errors);
    translateError(firstError);

    if (typeof rest?.onError === 'function') {
      rest.onError(errors);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (typeof rest?.onCompleted === 'function') {
      rest.onCompleted(data);
    }
  };

  const {
    loading: isLoading,
    data,
    refetch,
  } = useQuery<PatientQueryData, PatientVariables>(GET_ALL_PATIENT_DATA, {
    variables: {
      id,
    },
    ...rest,
    skip: !id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError,
    onCompleted,
  });

  if (isLoading) {
    return <Loading className="text-primary" />;
  }

  if (!data?.patient) {
    return <NotFound resourceId={id} />;
  }

  const value = {
    isLoading,
    data,
    error,
    refetch,
  };

  return <PatientContext.Provider value={value}>{children}</PatientContext.Provider>;
};
