import { gql } from '@apollo/client';
import { FullDrug, FullPharmacyItemStock, FullPrescription, FullStoredRx } from 'utils/GQLFragments';

export const GET_PHARMACY_ITEMS = gql`
  ${FullDrug}
  query pharmacyItems($q: String, $offset: Int, $limit: Int) {
    pharmacyItems(q: $q, offset: $offset, limit: $limit) {
      docs {
        ...FullDrug
      }
      count
    }
  }
`;

export const GET_PHARMACY_ITEM_STOCK_ENTRIES = gql`
  ${FullPharmacyItemStock}
  query pharmacyStock(
    $q: String
    $itemId: String!
    $added: Boolean
    $removed: Boolean
    $rx: Boolean
    $limit: Int
    $offset: Int
  ) {
    pharmacyStock(q: $q, added: $added, removed: $removed, rx: $rx, itemId: $itemId, limit: $limit, offset: $offset) {
      docs {
        ...FullPharmacyItemStock
      }
      count
    }
  }
`;

export const UPDATE_PHARMACY_ITEM = gql`
  mutation updatePharmacyItem($itemId: String!, $pharmacyItem: UpdateDrug!) {
    updatePharmacyItem(itemId: $itemId, pharmacyItem: $pharmacyItem) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const DELETE_PHARMACY_ITEM = gql`
  mutation deletePharmacyItem($itemId: String!) {
    deletePharmacyItem(itemId: $itemId) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const CREATE_PHARMACY_ITEM_STOCK = gql`
  mutation createStock($pharmacyStock: NewDrugStockEntry) {
    createStock(pharmacyStock: $pharmacyStock) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const CREATE_PHARMACY_ITEM = gql`
  mutation createPharmacyItem($pharmacyItem: UpdateDrug!) {
    createPharmacyItem(pharmacyItem: $pharmacyItem) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const UPDATE_PHARMACY_STOCK = gql`
  mutation updatePharmacyStock($stockId: String!, $pharmacyStock: NewDrugStockEntry!) {
    updatePharmacyStock(stockId: $stockId, pharmacyStock: $pharmacyStock) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const DELETE_PHARMACY_STOCK_EVENT = gql`
  mutation deletePharmacyStockEvent($stockId: String!) {
    deletePharmacyStockEvent(stockId: $stockId) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const GET_PRESCRIPTIONS = gql`
  query getPrescriptions(
    $filled: Boolean!
    $origin: String!
    $q: String
    $offset: Int
    $limit: Int
    $location: String
  ) {
    getPrescriptions(filled: $filled, origin: $origin, q: $q, offset: $offset, limit: $limit, location: $location) {
      docs {
        ...FullPrescription
      }
      count
    }
  }
  ${FullPrescription}
`;

export const GET_PRESCRIPTIONS_BY_VISIT = gql`
  query getPrescriptionsByVisit($visitId: String!, $origin: String!, $filled: Boolean!) {
    getPrescriptionsByVisit(visitId: $visitId, origin: $origin, filled: $filled) {
      ...FullStoredRx
    }
  }
  ${FullStoredRx}
`;

export const FILL_PRESCRIPTIONS = gql`
  mutation fillPrescriptions($fillPrescriptions: [NewFillPrescription]!) {
    fillPrescriptions(fillPrescriptions: $fillPrescriptions) {
      ...FullStoredRx
    }
  }
  ${FullStoredRx}
`;

export const SET_DRUG_GUIDELINE = gql`
  mutation setDrugGuideline($itemId: String!, $guideline: NewUserDrugGuideline!) {
    setDrugGuideline(itemId: $itemId, guideline: $guideline) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const REMOVE_DRUG_GUIDELINE = gql`
  mutation removeDrugGuideline($itemId: String!) {
    removeDrugGuideline(itemId: $itemId) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const AUDIT_DRUG_STOCK = gql`
  mutation auditPharmacyStock(
    $containerCount: Int
    $containerCapacity: Int
    $itemId: String!
    $newTotal: Float!
    $notes: String
  ) {
    auditPharmacyStock(
      containerCount: $containerCount
      containerCapacity: $containerCapacity
      itemId: $itemId
      newTotal: $newTotal
      notes: $notes
    ) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const GET_PHARMACY_LIBRARY_TAGS = gql`
  query getPharmacyLibraryTags {
    getDrugsTags {
      text
    }
  }
`;

export const IMPORT_DRUGS_BY_TAGS = gql`
  mutation importDrugsByTags($tag: [String]!) {
    importDrugsByTags(tag: $tag) {
      done
    }
  }
`;

export const SET_DRUG_BPJS_SETTINGS = gql`
  mutation setDrugBpjsSettings($itemId: String!, $bpjsSettings: NewDrugBpjsSettings!) {
    setDrugBpjsSettings(itemId: $itemId, bpjsSettings: $bpjsSettings) {
      ...FullDrug
    }
  }
  ${FullDrug}
`;

export const DELETE_IMPORTED_PHARMACY_LIBRARY_ITEMS = gql`
  mutation deletePharmacyLibraryItems($ids: [String]!) {
    deletePharmacyLibraryItems(ids: $ids) {
      done
    }
  }
`;
