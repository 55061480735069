import React from 'react';
import { ItemSubscriptions } from 'api/interfaces';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { Badge, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { useDisclosure, LastUpdated } from 'components-ts';
import { LoadingInline } from 'components-ts/Loading';
import { useSubscribeFromStudiesBatch } from './useLabSubscriptionActions';
import { IdTagTranslatedSubscriptionForm } from './IdTagTranslatedSubscriptionForm';
import { useTranslatedStudies } from './useTranslatedStudies';
import { useSystems } from './useSystems';

const messages = defineMessages({
  title: {
    id: 'LabTab.label_lab_subscriptions',
    defaultMessage: 'Lab',
  },
  description: {
    id: 'LabTab.choose_the_studies_you_want_to_show_in_your_system',
    defaultMessage: 'Library of labs and studies available in your facility',
  },
  noSubscriptions: {
    id: 'UI.label_no_subscriptions',
    defaultMessage: 'No Subscriptions',
  },
  subscribedLabel: {
    id: 'UI.label_subscribed',
    defaultMessage: 'Subscribed ({count})',
  },
  tagsTitle: {
    id: 'StudiesFirstStep.tags_title',
    defaultMessage: 'You can choose from our predefined selection groups',
  },
  onlySelectedLabel: {
    id: 'UI.show_only_selected',
    defaultMessage: 'Only selected',
  },
  saveButton: {
    id: 'UI.button_save',
    defaultMessage: 'Save',
  },
});

type LabTabProps = {
  systemId: string;
  locationId: string;
  subscriptions: ItemSubscriptions;
  isDisabled?: boolean;
};

const LabSubscriptions: React.FC<LabTabProps> = (props) => {
  const { systemId, locationId, subscriptions, isDisabled } = props;

  const intl = useIntl();

  const { getLocation } = useSystems();

  const location = getLocation(systemId, locationId);

  const { translatedStudies, isLoading: isLoadingStudies, error: errorLoadingStudies } = useTranslatedStudies();

  const {
    onSubmit,
    isLoading,
    error: errorSubscribing,
  } = useSubscribeFromStudiesBatch({
    systemId: systemId,
    locationId: locationId,
  });

  const error = errorLoadingStudies ?? errorSubscribing;

  return (
    <ModalBody>
      <IdTagTranslatedSubscriptionForm
        error={error}
        onSubmit={onSubmit}
        isLoading={isLoadingStudies}
        items={translatedStudies}
        initialValues={location?.subscriptions.lab.items}
      >
        <div className="d-flex align-items-center justify-content-between">
          <Button type="submit" color="primary" disabled={isDisabled}>
            <T id={messages.saveButton.id}>{intl.formatMessage(messages.saveButton)}</T>
            {isLoading && <LoadingInline className="ml-1" />}
          </Button>
          {subscriptions.updated && <LastUpdated signature={subscriptions.updated} />}
        </div>
      </IdTagTranslatedSubscriptionForm>
    </ModalBody>
  );
};

interface LabSubscriptionSectionProps {
  systemId: string;
  locationId: string;
  subscriptions: ItemSubscriptions;
  className?: string;
  isDisabled?: boolean;
}
export const LabSubscriptionSection: React.FC<LabSubscriptionSectionProps> = (props) => {
  const { systemId, locationId, subscriptions, className = '', isDisabled } = props;

  const intl = useIntl();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const count = subscriptions.items?.length ?? 0;
  return (
    <div
      onClick={onOpen}
      className={'d-flex align-items-center justify-content-between pointer animated-border-on-hover rounded p-3'}
    >
      <div className="flex-wrap">
        <h6 className="mb-1">
          <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
        </h6>
        <p className="text-muted mb-2">
          <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
        </p>
      </div>

      {count > 0 ? (
        <Badge color="success" className={`no-wrap ${className}`}>
          <T id={messages.subscribedLabel.id}>{intl.formatMessage(messages.subscribedLabel, { count })}</T>
        </Badge>
      ) : (
        <Badge className={`no-wrap ${className}`}>
          <T id={messages.noSubscriptions.id}>{intl.formatMessage(messages.noSubscriptions)}</T>
        </Badge>
      )}
      {isOpen && (
        <Modal isOpen={true} toggle={onClose}>
          <ModalHeader toggle={onClose}>
            <T id={messages.title.id}>{intl.formatMessage(messages.title)}</T>
          </ModalHeader>
          <LabSubscriptions
            isDisabled={isDisabled}
            systemId={systemId}
            locationId={locationId}
            subscriptions={subscriptions}
          />
        </Modal>
      )}
    </div>
  );
};
