import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'

// static translations
const messages = defineMessages({
  pageNotFound: {
    id: 'PageNotFound.page_not_found',
    defaultMessage: 'Page not found.'
  }
})

const PageNotFound = (props) => {
  const { intl } = props
  const { formatMessage } = intl

  return <div className='animated fadeIn container vh-100 d-flex flex-column align-items-center justify-content-center text-center'>
    <h1 className='text-dark'>404</h1>
    <p className='text-muted'>
      <T id={messages.pageNotFound.id}>{formatMessage(messages.pageNotFound)}</T>
    </p>
  </div>
}

export default injectIntl(PageNotFound)
