import { CLINICAL_STATUS_PRIORITY, HOW_LONG, VISIT_TYPES, HISTORY_TYPES, DISPOSITION, FOLLOW_UP, STATUS_ASSESMENT } from './definitions'

export const isAValidType = type => Object.values(VISIT_TYPES).includes(type)

export const isAValidClinicalStatusPriority = priority => Object.values(CLINICAL_STATUS_PRIORITY).includes(priority)

export const isAValidClinicalStatus = clinicalStatus => {
  return typeof clinicalStatus === 'object' && isAValidClinicalStatusPriority(clinicalStatus.priority)
}

export const isAValidDisposition = disposition => Object.values(DISPOSITION).includes(disposition)

export const isAValidFollowUp = followUp => Object.values(FOLLOW_UP).includes(followUp)

export const isAValidStatusAssessment = status => Object.values(STATUS_ASSESMENT).includes(status)

export const isAValidHowLong = howLong => Object.values(HOW_LONG).includes(howLong)

export const isAValidChiefComplaint = chiefComplaint => {
  return typeof chiefComplaint === 'object' && (!chiefComplaint.how_long || isAValidHowLong(chiefComplaint.how_long))
}

export const validteHistoryKey = historyKey => Object.values(HISTORY_TYPES).includes(historyKey)
