import React from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { Alert } from 'reactstrap'

/**
 * Component to display the apollo client errors
 *
 * @component
 * @example
 * // else, patients is a string
 * const errors = [
 *  {
 *    id: String - error id used as html key
 *    message: String - translated message
 *   }
 * ]
 * return error &&
 *   <ErrorViewer
 *      error={errors}
 *   />
 *
 */
const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.'
  },
  requestCantCompleted: {
    id: 'ErrorViewer.request_cant_be_completed',
    defaultMessage: 'The request cannot be completed'
  }
})

const ErrorViewer = (props) => {
  const { error, className, intl } = props
  const { formatMessage } = intl

  if (!error) {
    return null
  }

  const errors = Array.isArray(error) ? error : [error]

  return errors.length > 0 &&
    <Alert className={`alert-danger d-flex align-items-start justify-content-start flex-column ${className}`}>
      <T id={messages.requestCantCompleted.id}>{formatMessage(messages.requestCantCompleted)}</T>
      <ul className='text-left'>
        {
          errors.map((error, index) => {
            return (error || {}).id
              ? <li key={error.id}>{error.message}</li>
              : <li key={`default_internal_server_error_${index}`}>{formatMessage(messages.internalServerError)}</li>
          })
        }
      </ul>
    </Alert>
}

export default injectIntl(ErrorViewer)
