import { MutationHookOptions, useMutation } from '@apollo/client';
import { HealthSystem } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { SUBSCRIBE_TO_PROCEDURE, SUBSCRIBE_TO_PROCEDURES_BATCH, UNSUBSCRIBE_FROM_PROCEDURE } from 'api/request/systems';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  errorSubscribingToProcedures: {
    id: 'ProceduresTab.could_not_subscribe_to_procedure',
    defaultMessage: 'Could not subscribe to procedure. Please, retry.',
  },
  errorUnsubscribingFromProcedures: {
    id: 'ProceduresTab.could_not_unsubscribe_from_procedure',
    defaultMessage: 'Could not unsubscribe to procedure. Please, retry.',
  },
});

interface UseSubscribeToProcedureData {
  subscribeSystemToProcedure: HealthSystem;
}

interface UseSubscribeToProcedureVariables {
  systemId: string;
  locationId: string;
  item: string;
}

type UseSubscribeToProcedureParams = MutationHookOptions<
  UseSubscribeToProcedureData,
  UseSubscribeToProcedureVariables
> & { systemId: string; locationId: string };

export const useSubscribeToProcedure = (params: UseSubscribeToProcedureParams) => {
  const { systemId, locationId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_subscribe_to_procedure':
        return setError(messages.errorSubscribingToProcedures);
      case 'invalid_item_id':
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToProcedure, { loading }] = useMutation<
    UseSubscribeToProcedureData,
    UseSubscribeToProcedureVariables
  >(SUBSCRIBE_TO_PROCEDURE, { ...rest, onError, onCompleted });

  const onSubmit = (item: string) => {
    subscribeToProcedure({
      variables: {
        systemId,
        locationId,
        item,
      },
    });
  };

  return {
    onSubmit,
    subscribeToProcedure,
    isLoading: loading,
    error,
  };
};

interface UseUnsubscribeFromProcedureData {
  unsubscribeSystemFromProcedure: HealthSystem;
}

interface UseUnsubscribeFromProcedureVariables {
  systemId: string;
  locationId: string;
  item: string;
}

type UseUnsubscribeFromProcedureParams = MutationHookOptions<
  UseUnsubscribeFromProcedureData,
  UseUnsubscribeFromProcedureVariables
> & { systemId: string; locationId: string };

export const useUnsubscribeFromProcedure = (params: UseUnsubscribeFromProcedureParams) => {
  const { systemId, locationId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_unsubscribe_from_procedure':
        return setError(messages.errorUnsubscribingFromProcedures);
      case 'invalid_item_id':
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [unsubscribeFromProcedure, { loading }] = useMutation<
    UseUnsubscribeFromProcedureData,
    UseUnsubscribeFromProcedureVariables
  >(UNSUBSCRIBE_FROM_PROCEDURE, { ...rest, onError, onCompleted });

  const onSubmit = (item: string) => {
    unsubscribeFromProcedure({
      variables: {
        systemId,
        locationId,
        item,
      },
    });
  };

  return {
    onSubmit,
    unsubscribeFromProcedure,
    isLoading: loading,
    error,
  };
};

interface UseSubscribeToProceduresBatchData {
  subscribeToProceduresBatch: HealthSystem;
}

interface UseSubscribeToProceduresBatchVariables {
  locationId: string;
  systemId: string;
  ids: Array<string>;
}

type UseSubscribeToProceduresBatchParams = MutationHookOptions<
  UseSubscribeToProceduresBatchData,
  UseSubscribeToProceduresBatchVariables
> & { systemId: string; locationId: string };

export const useSubscribeToProceduresBatch = (params: UseSubscribeToProceduresBatchParams) => {
  const { systemId, locationId, ...rest } = params;

  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (graphqlError) => {
    const errorCode = extractFirstErrorCode(graphqlError);

    switch (errorCode) {
      case 'could_not_subscribe_to_procedures_batch':
        return setError(messages.errorSubscribingToProcedures);
      case 'invalid_item_id':
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = (data) => {
    setError(null);

    if (params.onCompleted) params.onCompleted(data);
  };

  const [subscribeToProceduresBatch, { loading }] = useMutation<
    UseSubscribeToProceduresBatchData,
    UseSubscribeToProceduresBatchVariables
  >(SUBSCRIBE_TO_PROCEDURES_BATCH, { ...rest, onError, onCompleted });

  const onSubmit = (ids: Array<string>) => {
    subscribeToProceduresBatch({
      variables: {
        systemId,
        locationId,
        ids,
      },
    });
  };

  return {
    onSubmit,
    subscribeToProceduresBatch,
    isLoading: loading,
    error,
  };
};
