import React from 'react';
import Select from 'react-select';
import { ReactSelectInternalSingleValue, SingleSelectorProps } from './utils';
import { usePricelists } from 'components-ts/Pricelists/usePricelists';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { Pricelist } from 'api/interfaces';

type PricelistSelectorProps = SingleSelectorProps<string> & { defaultValue?: string };

const formatOption = (pricelist: Pricelist) => ({ label: pricelist.name, value: pricelist.id });

export const PricelistSelector: React.FC<PricelistSelectorProps> = (props) => {
  const { docs, error, getPricelist, isLoading } = usePricelists();

  const options = docs.map(formatOption);

  const _value = getPricelist(props.value);
  const _defaultValue = props.defaultValue ? getPricelist(props.defaultValue) : undefined;

  const _onChange = (value: ReactSelectInternalSingleValue) => {
    if (value) {
      props.onChange(value.value);
    } else {
      props.onChange('');
    }
  };

  return (
    <>
      <Select
        {...props}
        onChange={_onChange}
        isLoading={isLoading}
        options={options}
        defaultValue={_defaultValue ? formatOption(_defaultValue) : undefined}
        value={_value ? formatOption(_value) : null}
      />
      {error && <ErrorViewer error={error} />}
    </>
  );
};
