import {
  BasicStudyTemplate,
  BasicStudyWithOrder,
  FullStudy,
  FullStudyTemplate,
  FullStudyWithOrder,
} from 'api/fragments/lab';
import { gql } from '@apollo/client';
import { BasicPatientWithoutId, FullSignedRequest } from '../../utils/GQLFragments';

import { FullTranslatableText } from 'api/fragments/common';

export const GET_STUDY_TEMPLATE_TAGS = gql`
  ${FullTranslatableText}
  query studyTemplateTags {
    studyTemplateTags {
      ...FullTranslatableText
    }
  }
`;

/**
 *
 * All these request are related to a studies templates
 *
 */
export const DELETE_STUDY = gql`
  mutation deleteStudy($id: ID!) {
    deleteStudy(id: $id) {
      done
    }
  }
`;

export const GET_STUDY_TEMPLATES = gql`
  ${BasicStudyTemplate}
  query studyTemplates($q: String, $offset: Int, $limit: Int, $tags: [String], $ids: [String]) {
    studyTemplates(q: $q, offset: $offset, limit: $limit, tags: $tags, ids: $ids) {
      docs {
        ...BasicStudyTemplate
      }
      count
    }
  }
`;
export const GET_STUDY_TEMPLATE_BY_ID = gql`
  ${FullStudyTemplate}
  query studyTemplate($id: ID!) {
    studyTemplate(id: $id) {
      ...FullStudyTemplate
    }
  }
`;

export const CREATE_STUDY_TEMPLATE = gql`
  ${FullStudyTemplate}
  mutation createStudyTemplate($newStudyTemplate: NewStudyTemplate!) {
    createStudyTemplate(newStudyTemplate: $newStudyTemplate) {
      ...FullStudyTemplate
    }
  }
`;

export const UPDATE_STUDY_TEMPLATE = gql`
  ${FullStudyTemplate}
  mutation updateStudyTemplate($id: String!, $newStudyTemplate: NewStudyTemplate!) {
    updateStudyTemplate(id: $id, newStudyTemplate: $newStudyTemplate) {
      ...FullStudyTemplate
    }
  }
`;

export const ADD_NUMERICAL_ITEM = gql`
  ${FullStudyTemplate}
  mutation addStudyTemplateNumericalItem($id: ID!, $item: NewStudyTemplateNumericalItem!) {
    addStudyTemplateNumericalItem(id: $id, item: $item) {
      ...FullStudyTemplate
    }
  }
`;
export const ADD_CATEGORICAL_ITEM = gql`
  ${FullStudyTemplate}
  mutation addStudyTemplateCategoricalItem($id: ID!, $item: NewStudyTemplateCategoricalItem!) {
    addStudyTemplateCategoricalItem(id: $id, item: $item) {
      ...FullStudyTemplate
    }
  }
`;
export const ADD_CATEGORICAL_BASED_ON_NUMBER_ITEM = gql`
  ${FullStudyTemplate}
  mutation addStudyTemplateCategoricalBasedOnNumberItem(
    $id: ID!
    $item: NewStudyTemplateCategoricalBasedOnNumberItem!
  ) {
    addStudyTemplateCategoricalBasedOnNumberItem(id: $id, item: $item) {
      ...FullStudyTemplate
    }
  }
`;

export const UPDATE_NUMERICAL_ITEM = gql`
  ${FullStudyTemplate}
  mutation updateStudyTemplateNumericalItem($id: ID!, $item: NewStudyTemplateNumericalItem!) {
    updateStudyTemplateNumericalItem(id: $id, item: $item) {
      ...FullStudyTemplate
    }
  }
`;
export const UPDATE_CATEGORICAL_ITEM = gql`
  ${FullStudyTemplate}
  mutation updateStudyTemplateCategoricalItem($id: ID!, $item: NewStudyTemplateCategoricalItem!) {
    updateStudyTemplateCategoricalItem(id: $id, item: $item) {
      ...FullStudyTemplate
    }
  }
`;
export const UPDATE_CATEGORICAL_BASED_ON_NUMBER_ITEM = gql`
  ${FullStudyTemplate}
  mutation updateStudyTemplateCategoricalBasedOnNumberItem(
    $id: ID!
    $item: NewStudyTemplateCategoricalBasedOnNumberItem!
  ) {
    updateStudyTemplateCategoricalBasedOnNumberItem(id: $id, item: $item) {
      ...FullStudyTemplate
    }
  }
`;
export const DELETE_STUDY_TEMPLATE_ITEM = gql`
  ${FullStudyTemplate}
  mutation deleteStudyTemplateItem($id: ID!, $itemId: String!) {
    deleteStudyTemplateItem(id: $id, itemId: $itemId) {
      ...FullStudyTemplate
    }
  }
`;
export const DELETE_STUDY_TEMPLATE = gql`
  mutation deleteStudyTemplate($id: ID!) {
    deleteStudyTemplate(id: $id) {
      done
    }
  }
`;

/**
 *
 * And all of these are related to studies generated by templates
 *
 */

export const GET_UPLOADING_URL = gql`
  ${FullSignedRequest}
  query studyUploadURL($patientId: ID!, $mymeType: String!) {
    studyUploadURL(patientId: $patientId, mymeType: $mymeType) {
      ...FullSignedRequest
    }
  }
`;

export const GET_DOWNLOADING_URL = gql`
  ${FullSignedRequest}
  query studyDownloadURL($fileId: String!) {
    studyDownloadURL(fileId: $fileId) {
      ...FullSignedRequest
    }
  }
`;

export const ADD_FILE_TO_STUDY = gql`
  ${FullStudy}
  mutation addFileToStudy($id: ID!, $fileId: String!) {
    addFileToStudy(id: $id, fileId: $fileId) {
      ...FullStudy
    }
  }
`;

export const REMOVE_FILE_FROM_STUDY = gql`
  ${FullStudy}
  mutation removeFileFromStudy($id: ID!, $fileId: String!) {
    removeFileFromStudy(id: $id, fileId: $fileId) {
      ...FullStudy
    }
  }
`;

export const ADD_NOTE_TO_STUDY = gql`
  ${FullStudy}
  mutation addNoteToStudy($id: ID!, $note: String!) {
    addNoteToStudy(id: $id, note: $note) {
      ...FullStudy
    }
  }
`;

export const COMPLETE_STUDY = gql`
  ${FullStudy}
  mutation completeStudy($id: ID!) {
    completeStudy(id: $id) {
      ...FullStudy
    }
  }
`;

export const GET_STUDY_BY_ID = gql`
  ${FullStudyWithOrder}
  query study($id: ID!) {
    study(id: $id) {
      ...FullStudyWithOrder
    }
  }
`;

export const GET_STUDIES = gql`
  ${BasicStudyWithOrder}
  query studies($q: String, $locationId: String, $offset: Int, $limit: Int, $filled: Boolean, $ids: [String]) {
    studies(q: $q, locationId: $locationId, offset: $offset, limit: $limit, filled: $filled, ids: $ids) {
      docs {
        ...BasicStudyWithOrder
      }
      count
    }
  }
`;

export const GET_STUDIES_GROUPED_BY_VISIT = gql`
  ${BasicStudyWithOrder}
  ${BasicPatientWithoutId}
  query studiesWithOrderGroupedByVisit(
    $q: String
    $locationId: String
    $offset: Int
    $limit: Int
    $filled: Boolean
    $ids: [String]
    $visitId: String
  ) {
    studiesWithOrderGroupedByVisit(
      q: $q
      locationId: $locationId
      offset: $offset
      limit: $limit
      filled: $filled
      ids: $ids
      visitId: $visitId
    ) {
      docs {
        visitId
        patient {
          ...BasicPatientWithoutId
        }
        studies {
          ...BasicStudyWithOrder
        }
      }
      count
    }
  }
`;

export const GET_FULL_STUDIES_GROUPED_BY_VISIT = gql`
  ${FullStudyWithOrder}
  query studiesWithOrderGroupedByVisit(
    $q: String
    $locationId: String
    $offset: Int
    $limit: Int
    $filled: Boolean
    $ids: [String]
    $visitId: String
  ) {
    studiesWithOrderGroupedByVisit(
      q: $q
      locationId: $locationId
      offset: $offset
      limit: $limit
      filled: $filled
      ids: $ids
      visitId: $visitId
    ) {
      docs {
        studies {
          ...FullStudyWithOrder
        }
      }
      count
    }
  }
`;

export const GET_FULL_STUDIES = gql`
  ${FullStudyWithOrder}
  query studies($q: String, $locationId: String, $offset: Int, $limit: Int, $filled: Boolean, $ids: [String]) {
    studies(q: $q, locationId: $locationId, offset: $offset, limit: $limit, filled: $filled, ids: $ids) {
      docs {
        ...FullStudyWithOrder
      }
      count
    }
  }
`;

export const CREATE_STUDY = gql`
  ${FullStudy}
  mutation createStudy($newStudy: NewStudy!) {
    createStudy(newStudy: $newStudy) {
      ...FullStudy
    }
  }
`;
export const UPDATE_CATEGORICAL_BASED_ON_NUMBER_RESULT = gql`
  ${FullStudy}
  mutation updateStudyCategoricalBasedOnNumberResult(
    $id: ID!
    $itemId: ID!
    $newResult: NewStudyCategoricalBasedOnNumberResult!
  ) {
    updateStudyCategoricalBasedOnNumberResult(id: $id, itemId: $itemId, newResult: $newResult) {
      ...FullStudy
    }
  }
`;

export const UPDATE_NUMERICAL_RESULT = gql`
  ${FullStudy}
  mutation updateStudyNumericalResult($id: ID!, $itemId: ID!, $newResult: NewStudyNumericalResult!) {
    updateStudyNumericalResult(id: $id, itemId: $itemId, newResult: $newResult) {
      ...FullStudy
    }
  }
`;
export const UPDATE_CATEGORICAL_RESULT = gql`
  ${FullStudy}
  mutation updateStudyCategoricalResul($id: ID!, $itemId: ID!, $newResult: NewStudyCategoricalResult!) {
    updateStudyCategoricalResult(id: $id, itemId: $itemId, newResult: $newResult) {
      ...FullStudy
    }
  }
`;

export const UPDATE_STUDY_COMMENT = gql`
  ${FullStudy}
  mutation updateStudyComment($id: ID!, $newComment: String!) {
    updateStudyComment(id: $id, newComment: $newComment) {
      ...FullStudy
    }
  }
`;

/**
 * History
 *
 */
export const GET_PATIENT_STUDY_HISTORY = gql`
  query studyHistory($id: ID!, $offset: Int, $limit: Int) {
    studyHistory(id: $id, offset: $offset, limit: $limit) {
      docs {
        ...FullStudyWithOrder
      }
      count
    }
  }
  ${FullStudyWithOrder}
`;
