import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { Nullable, isValidVisitType, isDeprecatedVisitType } from 'components-ts/utils';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps } from '.';
import { VisitType } from 'api/interfaces';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
const messages = defineMessages({
  visitType: {
    id: 'UI.label_visit_type',
    defaultMessage: 'Visit type',
  },
});

/**
 * Record will force us to add the translation
 * every time we updated the visit type list
 */
export const visitTypeTranslations: Record<VisitType, MessageDescriptor> = {
  [VisitType.SICK_VISIT]: {
    id: 'VisitType.Sick Visit',
    defaultMessage: 'Sick Visit',
  },
  [VisitType.FOLLOW_UP]: {
    id: 'VisitType.Follow-up',
    defaultMessage: 'Follow-up',
  },
  [VisitType.CHIILD_HEALTH]: {
    id: 'VisitType.Child Health',
    defaultMessage: 'Child Health',
  },
  [VisitType.ANTENATAL_CARE]: {
    id: 'VisitType.Antenatal Care',
    defaultMessage: 'Antenatal Care',
  },
  [VisitType.DELIVERY]: {
    id: 'VisitType.Delivery',
    defaultMessage: 'Delivery',
  },
  [VisitType.FAMILY_PLANNING]: {
    id: 'VisitType.Family Planning',
    defaultMessage: 'Family Planning',
  },
  [VisitType.PHYSICAL_EXAM]: {
    id: 'VisitType.Physical Exam',
    defaultMessage: 'Physical Exam',
  },
  [VisitType.INMUNIZATION]: {
    id: 'VisitType.Immunization',
    defaultMessage: 'Immunization',
  },
  [VisitType.DENTAL]: {
    id: 'VisitType.Dental',
    defaultMessage: 'Dental',
  },
  [VisitType.TRANSFER]: {
    id: 'VisitType.Transfer',
    defaultMessage: 'Transfer',
  },
  [VisitType.OTHER]: {
    id: 'VisitType.Other',
    defaultMessage: 'Other',
  },
  [VisitType.MIDWIFE]: {
    id: 'VisitType.Midwife',
    defaultMessage: 'Midwife',
  },
  [VisitType.GENERAL_PRACTITIONER]: {
    id: 'VisitType.General Practitioner',
    defaultMessage: 'General Practitioner',
  },
  [VisitType.MEDICAL_CLEARANCE]: {
    id: 'VisitType.Medical Clearance',
    defaultMessage: 'Medical Clearance',
  },
  [VisitType.OBGYN]: {
    id: 'VisitType.OBGYN',
    defaultMessage: 'OB/GYN',
  },
  [VisitType.PEDIATRICIAN]: {
    id: 'VisitType.Pediatrician',
    defaultMessage: 'Pediatrician',
  },
  [VisitType.INTERNIST]: {
    id: 'VisitType.Internist',
    defaultMessage: 'Internist',
  },
  [VisitType.TESTING]: {
    id: 'VisitType.Testing',
    defaultMessage: 'Testing',
  },
  [VisitType.LACTATION_COUNSELING]: {
    id: 'VisitType.Lactation Counseling',
    defaultMessage: 'Lactation Counseling',
  },
};

/**
 * See the IntlDefineMessage definition
 */
const visitTranslations = defineMessages(visitTypeTranslations);

type VisitTypeSelectorProps = SelectorProps<VisitType> & {
  showAll?: boolean;
  className?: string;
};

export const VisitTypeSelector: React.FC<VisitTypeSelectorProps> = props => {
  const { value, isMulti, isClearable, isDisabled, className, showAll } = props;

  const intl = useIntl();

  const formatOption = (visitType: VisitType): ReactSelectInternalSingleValue => {
    return {
      label: intl.formatMessage(visitTranslations[visitType]),
      value: visitType,
    };
  };

  const filteredOptions = showAll
    ? Object.values(VisitType)
    : Object.values(VisitType).filter(type => !isDeprecatedVisitType(type));

  const options = filteredOptions.map(formatOption);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    if (isMulti) {
      if (!Array.isArray(value)) {
        return null;
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, value: string) => {
        if (isValidVisitType(value) && filteredOptions.includes(value as VisitType)) {
          return [...prev, formatOption(value as VisitType)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      if (isValidVisitType(value as string) && filteredOptions.includes(value as VisitType)) {
        return formatOption(value as VisitType);
      }

      return null;
    }
  };

  const _onChange = (v: ReactSelectInternalValue): void => {
    if (props.isMulti) {
      if (!Array.isArray(v)) {
        return props.onChange([]);
      }

      const selectedValues = v as Array<ReactSelectInternalSingleValue>;
      const newValues = selectedValues.map(item => item.value);

      props.onChange(newValues as Array<VisitType>);
    } else {
      const selectedValue = v as ReactSelectInternalSingleValue;

      props.onChange(selectedValue.value as VisitType);
    }
  };

  return (
    <FormGroup>
      <Label>
        <T id={messages.visitType.id}>{intl.formatMessage(messages.visitType)}</T>
      </Label>
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMulti}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    </FormGroup>
  );
};
