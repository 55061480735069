import { VitalType } from 'api/interfaces';
import { usePatientContext } from 'components-ts/Patients/usePatientContext';
import { usePatientHistoryContext } from 'components-ts/Patients/usePatientHistoryContext';

export const usePatientVitals = () => {
  const { activeVisit, isLoading: loadingVisit, error } = usePatientContext();
  const { getLastVital, isLoading: loadingHistory } = usePatientHistoryContext();

  const getVital = (type: VitalType) => {
    if (activeVisit) {
      const measurements = activeVisit.vitals?.measurements ?? [];
      const filtered = measurements.filter((measurement) => measurement.type === type);

      if (filtered.length === 1) {
        return filtered[0];
      }

      const sorted = filtered.reverse();

      return sorted[0] ?? null;
    }

    return getLastVital(type);
  };

  return {
    error, // is the same for both
    isLoading: loadingHistory ?? loadingVisit,
    getVital,
  };
};
