import { Nullable } from 'components-ts/utils';

export enum PrimitiveCustomFieldType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
}

export enum NonPrimitiveCustomFieldType {
  TEXT_LIST = 'textList',
}

export type CustomFieldType = PrimitiveCustomFieldType | NonPrimitiveCustomFieldType;

export type PrimitiveCustomField = {
  id: string;
  name: string;
  type: PrimitiveCustomFieldType;
  required: Nullable<boolean>;
};

export interface TextListCustomField {
  id: string;
  name: string;
  required: Nullable<boolean>;
  options: Array<string>;
}

export interface CustomFieldBooleanWithValue {
  customField: PrimitiveCustomField;
  boolean: Nullable<boolean>;
}

export interface CustomFieldNumberWithValue {
  customField: PrimitiveCustomField;
  number: Nullable<number>;
}

export interface CustomFieldTextWithValue {
  customField: PrimitiveCustomField;
  text: Nullable<string>;
}

export interface CustomFieldDateWithValue {
  customField: PrimitiveCustomField;
  date: Nullable<string>;
}

export interface CustomFieldTextListWithValue {
  customField: TextListCustomField;
  textList: Nullable<string>;
}

export type CustomField = PrimitiveCustomField | TextListCustomField;
export type CustomFieldWithValue =
  | CustomFieldBooleanWithValue
  | CustomFieldNumberWithValue
  | CustomFieldTextWithValue
  | CustomFieldDateWithValue
  | CustomFieldTextListWithValue;

export const isPrimitiveCustomField = (customField: any): customField is PrimitiveCustomField =>
  Boolean(customField.type);
