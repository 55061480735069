import React from 'react';
import { Spinner } from 'reactstrap';

interface Props {
  className?: string;
  height?: number;
  weight?: number;
  size?: 'sm' | 'md' | 'lg';
}

export const Loading: React.FC<Props> = (props) => {
  const { className, height, weight } = props;

  return (
    <Spinner
      className={`position-absolute ${className}`}
      style={{
        height,
        weight,
        top: 'calc(50% - 1rem)',
        left: 'calc(50% - 1rem)',
      }}
    />
  );
};

export const LoadingInline: React.FC<Props> = (props) => {
  const { className, size = 'sm' } = props;

  return <Spinner className={className} size={size} />;
};
