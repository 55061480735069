import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Nav, NavItem, NavLink } from 'reactstrap';
import { FaQuestionCircle as HelpIcon } from 'react-icons/fa';
import { useDisclosure } from 'components-ts/useDisclosure';
import { ContactUs } from './ContactUs';
import { ReferenceLink } from './utils';
import { useSession } from 'components-ts/Auth';
import { Locales } from 'components-ts/i18n';

const EnglishHelpReferences = (props) => {
  const { disable } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button color="link" onClick={onOpen} disable={disable}>
        <HelpIcon className="nav-icon text-info h3 m-0" />
      </Button>
      <Modal isOpen={isOpen} className="modal-lg" toggle={onClose}>
        <ModalHeader toggle={onClose}>Help Topics</ModalHeader>
        <ModalBody className="d-flex justify-conten-between align-items-center">
          <Nav vertical className="mr-3">
            <NavItem>
              <NavLink href="#section-a">A</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-b">B</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-c">C</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-d">D</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-e">E</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-h">
                ...
                <br />H
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-L">L</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-o">
                ...
                <br />O
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-p">P</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-q">Q</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-r">R</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-s">S</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-t">T</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-u">U</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-w">
                ...
                <br />W
              </NavLink>
            </NavItem>
          </Nav>
          <div style={{ maxHeight: '70vh', overflowY: 'auto', flex: 1 }} className="pt-1 ml-3 scrollable">
            <div id="section-a" className="pb-1">
              <h5 className="text-muted">B</h5>
              <ReferenceLink url="https://youtu.be/aps2f7PREZw">
                <p className="mb-0">
                  <b>Auditing</b> drug stock
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/8vepwFFzwOE">
                <p className="mb-0">
                  Mortality <b>Audit</b>
                </p>
              </ReferenceLink>
            </div>
            <div id="section-b" className="pb-1">
              <h5 className="text-muted">B</h5>
              <ReferenceLink url="https://youtu.be/g9uu7nQ_t0I">
                <p className="mb-0">
                  <b>Billing</b>
                </p>
              </ReferenceLink>
            </div>
            <div id="section-c" className="pb-1">
              <h5 className="text-muted">C</h5>
              <ReferenceLink url="https://youtu.be/d25AljfT4V0">
                <p className="mb-0">
                  <b>Checklist</b> overview
                </p>
              </ReferenceLink>
            </div>

            <div id="section-d" className="pb-1">
              <h5 className="text-muted">D</h5>
              <ReferenceLink url="https://youtu.be/GsHCiRmetn8">
                <p className="mb-0">
                  <b>Discharge</b> of patients
                </p>
              </ReferenceLink>
            </div>
            <div id="section-e" className="pb-1">
              <h5 className="text-muted">E</h5>
              <ReferenceLink url="https://www.youtube.com/watch?v=bickE1UXa2g">
                <p className="mb-0">
                  <b>Exam</b> page overview
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://www.youtube.com/watch?v=DQNHExZOf1g">
                <p className="mb-0">
                  <b>Emergency</b> management heart attack
                </p>
              </ReferenceLink>
            </div>
            <div id="section-h" className="pb-1">
              <h5 className="text-muted">H</h5>
              <ReferenceLink url="https://youtu.be/sfd-ZXiFG0A">
                <p className="mb-0">
                  Filling out <b>History</b> of present illness
                </p>
              </ReferenceLink>
            </div>
            <div id="section-l" className="pb-1">
              <h5 className="text-muted">L</h5>
              <ReferenceLink url="https://youtu.be/iExWCqYaAnA">
                <p className="mb-0">
                  <b>Language</b> selection
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/3bCY33wrZCw">
                <p className="mb-0">
                  <b>Lactation</b> support
                </p>
              </ReferenceLink>
            </div>
            <div id="section-o" className="pb-1">
              <h5 className="text-muted">O</h5>
              <ReferenceLink url="https://www.youtube.com/watch?v=yTgNY1dxP-s">
                <p className="mb-0">
                  <b>Ordering</b> medicines
                </p>
              </ReferenceLink>
            </div>
            <div id="section-p" className="pb-1">
              <h5 className="text-muted">P</h5>
              <ReferenceLink url="https://www.youtube.com/watch?v=PyhaP7bv5VI">
                <p className="mb-0">
                  <b>Performance</b> metrics
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/F8czfXh88w0">
                <p className="mb-0">
                  Filling out <b>Physical</b> Exam
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/_dX-QjeApxQ">
                <p className="mb-0">
                  Setting <b>Prices</b>
                </p>
              </ReferenceLink>
            </div>
            <div id="section-q" className="pb-1">
              <h5 className="text-muted">Q</h5>
              <ReferenceLink url="https://youtu.be/x0yEfaGjaWs">
                <p className="mb-0">
                  <b>Quality Checks</b>
                </p>
              </ReferenceLink>
            </div>
            <div id="section-r" className="pb-1">
              <h5 className="text-muted">R</h5>
              <ReferenceLink url="https://youtu.be/F-aiuOOxuOI">
                <p className="mb-0">
                  Patient <b>Registration</b>
                </p>
              </ReferenceLink>
            </div>
            <div id="section-s" className="pb-1">
              <h5 className="text-muted">S</h5>
              <ReferenceLink url="https://youtu.be/_ihB-mi6P-M">
                <p className="mb-0">
                  Managing <b>Subscriptions</b>
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/b4Io3VjjDCs">
                <p className="mb-0">
                  Patient <b>Search</b>
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/38R9aQsvnnY">
                <p className="mb-0">
                  <b>Scheduler</b>
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/tHBiu8R9_fs">
                <p className="mb-0">
                  Patient <b>Surveys</b>
                </p>
              </ReferenceLink>
            </div>
            <div id="section-t" className="pb-1">
              <h5 className="text-muted">T</h5>
              <ReferenceLink url="https://youtu.be/Zupz_F1KoYA">
                <p className="mb-0">
                  <b>Triage</b>
                </p>
              </ReferenceLink>
            </div>
            <div id="section-u" className="pb-1">
              <h5 className="text-muted">U</h5>
              <ReferenceLink url="https://youtu.be/DY-x0MCdqVA">
                <p className="mb-0">
                  Inviting new <b>Users</b>
                </p>
              </ReferenceLink>
            </div>

            <div id="section-w" className="pb-1">
              <h5 className="text-muted">W</h5>
              <ReferenceLink url="https://youtu.be/u8usSLruQIA">
                <p className="mb-0">
                  Patient <b>Weight</b> trending
                </p>
              </ReferenceLink>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <ContactUs />
          <Button color="secondary" className="" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const IndonesianHelpReferences = (props) => {
  const { disable } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button color="link" onClick={onOpen} disable={disable}>
        <HelpIcon className="nav-icon text-info h3 m-0" />
      </Button>
      <Modal isOpen={isOpen} className="modal-lg" toggle={onClose}>
        <ModalHeader toggle={onClose}>Topik Bantuan</ModalHeader>
        <ModalBody className="d-flex justify-conten-between align-items-center">
          <Nav vertical className="mr-3">
            <NavItem>
              <NavLink href="#section-a">A</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-b">B</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-d">
                ...
                <br />D
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-h">
                ...
                <br />H
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-i">I</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-l">
                ...
                <br />L
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-o">
                ...
                <br />O
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-p">P</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-r">
                ...
                <br />R
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-s">S</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-t">T</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#section-v">
                ...
                <br />V
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#">...</NavLink>
            </NavItem>
          </Nav>
          <div style={{ maxHeight: '70vh', overflowY: 'auto', flex: 1 }} className="pt-1 ml-3 scrollable">
            <div id="section-a" className="pb-1">
              <h5 className="text-muted">A</h5>
              <ReferenceLink url="https://youtu.be/XKh0C5Vg3_8">
                <p className="mb-0">
                  Kelola <b>Akses</b> Staff
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/CLz4d03zxVI">
                <p className="mb-0">
                  Daftar <b>Antrian</b> Pasien
                </p>
              </ReferenceLink>
            </div>

            <div id="section-b" className="pb-1">
              <h5 className="text-muted">B</h5>
              <ReferenceLink url="https://youtu.be/unN5t57WsKg">
                <p className="mb-0">
                  Ubah pilihan <b>Bahasa</b> di WD
                </p>
              </ReferenceLink>
            </div>

            <div id="section-d" className="pb-1">
              <h5 className="text-muted">D</h5>
              <ReferenceLink url="https://youtu.be/oDk9-w7rlh4">
                <p className="mb-0">
                  <b>Dashboard</b>
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/JlUdlw4o5vA">
                <p className="mb-0">
                  Daftar Tilik <b>Diagnosa</b>
                </p>
              </ReferenceLink>
            </div>

            <div id="section-h" className="pb-1">
              <h5 className="text-muted">H</h5>
              <ReferenceLink url="https://youtu.be/MOhD7YKC1as">
                <p className="mb-0">
                  Pengaturan <b>Harga</b>
                </p>
              </ReferenceLink>
            </div>

            <div id="section-i" className="pb-1">
              <h5 className="text-muted">I</h5>
              <ReferenceLink url="https://youtu.be/Abm1TW6CYUM">
                <p className="mb-0">
                  <b>Instruksi</b> Dokter
                </p>
              </ReferenceLink>
            </div>

            <div id="section-l" className="pb-1">
              <h5 className="text-muted">L</h5>
              <ReferenceLink url="https://youtu.be/Abm1TW6CYUM">
                <p className="mb-0">
                  <b>Laporan</b> Keuangan
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/eowEwWSzv-8">
                <p className="mb-0">
                  Pemeriksaan <b>Lab </b>
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/fuztDzfJOS0">
                <p className="mb-0">
                  Entri hasil pemeriksaan <b>Lab</b>
                </p>
              </ReferenceLink>
            </div>

            <div id="section-o" className="pb-1">
              <h5 className="text-muted">O</h5>
              <ReferenceLink url="https://youtu.be/wwhjjXoMYxk">
                <p className="mb-0">
                  Entri <b>Obat</b> Baru di Farmasi
                </p>
              </ReferenceLink>
            </div>

            <div id="section-p" className="pb-1">
              <h5 className="text-muted">P</h5>
              <ReferenceLink url="https://youtu.be/HKpRZvaeMUY">
                <p className="mb-0">
                  <b>Pendaftaran</b> Pasien Lama
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/oLFPb6kn7jY">
                <p className="mb-0">
                  <b>Pendaftaran</b> Pasien Baru
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/8LtQO6e8jmo">
                <p className="mb-0">
                  <b>Pemeriksaan</b> Fisik Pasien
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/2ntQrXCyYJ0">
                <p className="mb-0">
                  Lembar <b>Pemeriksaan</b> Dokter
                </p>
              </ReferenceLink>
            </div>

            <div id="section-r" className="pb-1">
              <h5 className="text-muted">R</h5>
              <ReferenceLink url="https://youtu.be/6_Wk-nJl57I">
                <p className="mb-0">
                  <b>Resep</b> obat di Apotik
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/6S5i7oqs4l8">
                <p className="mb-0">
                  <b>Resep</b> obat di Lembar Pemeriksaan Dokter
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/X1EtKR0YPyA">
                <p className="mb-0">
                  <b>Riwayat</b> Pasien
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/OzPd-xprasI">
                <p className="mb-0">
                  <b> Rekam</b> Medik Lampau
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/2p2csRY2ZW8">
                <p className="mb-0">
                  Setting <b>Resep</b> Cepat
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/bzY-LLB0sU4">
                <p className="mb-0">
                  <b>Resep</b> Obat puyer
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/yexFloJbWKE">
                <p className="mb-0">
                  Menutup <b>Rekam</b> Medis
                </p>
              </ReferenceLink>
            </div>

            <div id="section-s" className="pb-1">
              <h5 className="text-muted">S</h5>
              <ReferenceLink url="https://youtu.be/EsLGjdirybg">
                <p className="mb-0">
                  Audit <b>Stok</b> Obat
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/1Rsy-ZSTMN0">
                <p className="mb-0">
                  Tambah <b>Stok</b> obat
                </p>
              </ReferenceLink>
            </div>

            <div id="section-t" className="pb-1">
              <h5 className="text-muted">T</h5>
              <ReferenceLink url="https://youtu.be/V0yMgOVXYPw">
                <p className="mb-0">
                  <b>Tagihan</b> Pasien
                </p>
              </ReferenceLink>
              <ReferenceLink url="https://youtu.be/ZTlfNLDCxfs">
                <p className="mb-0">
                  <b>Tindakan</b> Medis
                </p>
              </ReferenceLink>
            </div>
            <div id="section-v" className="pb-1">
              <h5 className="text-muted">V</h5>
              <ReferenceLink url="https://youtu.be/OCoITvKtQ3I">
                <p className="mb-0">
                  Entri <b>Vital</b> Sign
                </p>
              </ReferenceLink>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <ContactUs />
          <Button color="secondary" className="" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export const HelpReferences: React.VFC = () => {
  const { getUserLocale } = useSession();

  const locale = getUserLocale();

  switch (locale) {
    case Locales.ID_ID:
      return <IndonesianHelpReferences />;

    case Locales.EN_US:
    default:
      return <EnglishHelpReferences />;
  }
};
