import React from 'react';
import { useCreateRoom } from './useRoomActions';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { RoomForm } from './RoomForm';
import { NewRoom } from 'api/interfaces';
import { useDisclosure } from 'components-ts/useDisclosure';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';

const messages = defineMessages({
  button: {
    id: 'AddRoomModal.button',
    defaultMessage: 'Add room',
  },
  modalTitle: {
    id: 'AddRoomModal.title',
    defaultMessage: 'Add room',
  },
});

type AddRoomModalButtonProps = {
  systemId: string;
  locationId: string;
};

export const AddRoomModalButton: React.FC<AddRoomModalButtonProps> = (props) => {
  const { systemId, locationId } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const intl = useIntl();

  return (
    <>
      <Button color="primary" onClick={onOpen}>
        <T id={messages.button.id}>{intl.formatMessage(messages.button)}</T>
      </Button>
      {isOpen && <AddRoomModal systemId={systemId} locationId={locationId} onClose={onClose} />}
    </>
  );
};

type AddRoomModalProps = {
  systemId: string;
  locationId: string;
  onClose: () => void;
};

export const AddRoomModal: React.FC<AddRoomModalProps> = (props) => {
  const { systemId, locationId, onClose } = props;

  const intl = useIntl();

  const { onSubmit, validate, loading, error } = useCreateRoom({ systemId, locationId, onCompleted: onClose });

  const initialValues: NewRoom = {
    name: '',
    description: null,
    available: false,
  };

  return (
    <Modal isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        <T id={messages.modalTitle.id}>{intl.formatMessage(messages.modalTitle)}</T>
      </ModalHeader>
      <ModalBody>
        <RoomForm
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          loading={loading}
          error={error}
        />
      </ModalBody>
    </Modal>
  );
};
