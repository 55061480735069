import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { AsyncImage, ConfirmationModal, ErrorViewer, LoadingInline } from 'components'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { useMutation, useQuery } from '@apollo/client'
import { GET_DOWNLOADING_URL, REMOVE_FILE_FROM_STUDY } from 'api/request/lab'
import { graphQLErrorFormatter } from 'utils/errors'
import { FaTrashAlt as DeleteIcon } from 'react-icons/fa'
import { useSession } from 'hooks'
const messages = defineMessages({
  defaultError: {
    id: 'StudyFilesViewer.default_error',
    defaultMessage: 'Something went wrong while trying to get the file. Try later'
  },
  removeButton: {
    id: 'UI.remove_button',
    defaultMessage: 'Remove'
  },
  filesLabel: {
    id: 'StudyFilesViewer.see_files_label',
    defaultMessage: 'See files ({files})'
  },
  closeButton: {
    id: 'UI.button_close',
    defaultMessage: 'Close'
  },
  deleteConfirmationMessage: {
    id: 'StudyFilesViewer.deleting_file_warning',
    defaultMessage: 'This will permanently delete this file. Are you sure?'
  }
})

const userCanDelete = (userHasRole) => {
  if (typeof userHasRole !== 'function') {
    return false
  }

  return userHasRole('Lab') || userHasRole('Admin')
}

export const StudyFilesViewer = (props) => {
  const { studyId, files, isLoading } = props

  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(prev => !prev)
  }

  const isDisabled = (files || []).length === 0 || isOpen
  return (
    <div >
      <Button className='btn-sm btn-ghost-primary  mb-1' disabled={isDisabled} onClick={toggle}>
        <T id={messages.filesLabel.id}>
          {intl.formatMessage(messages.filesLabel, { files: (files || []).length })}
        </T>
        {
          isLoading && <div className='mx-1'><LoadingInline /></div>
        }
      </Button>
      <StudyFilesModal files={files} studyId={studyId} toggle={toggle} isOpen={isOpen} />
    </div>
  )
}

const StudyFilesModal = props => {
  const { isOpen, toggle, studyId, files } = props

  const intl = useIntl()
  if (!Array.isArray(files) || files.length === 0) return null

  return <Modal isOpen={isOpen} className='modal-lg'>
    <ModalBody className='d-flex flex-column'>
      {
        files.map(file => {
          const alt = `study-image-${file}`
          return <StudyFile studyId={studyId} fileId={file} key={file} alt={alt} />
        })
      }
    </ModalBody>
    <ModalFooter>
      <Button className='btn ' onClick={e => toggle()}>
        <T id={messages.closeButton.id} >
          {intl.formatMessage(messages.closeButton)}
        </T>
      </Button>
    </ModalFooter>
  </Modal>
}

export const StudyFile = props => {
  const { fileId, studyId, isDisabled = false } = props
  const { userHasRole } = useSession()

  const intl = useIntl()
  const [error, setError] = useState()
  const errorFormatter = (errorCode) => {
    switch (errorCode) {
      default:
        return {
          id: errorCode,
          message: intl.formatMessage(messages.defaultError)
        }
    }
  }

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter)
    setError(translatedErrors)
  }

  const onCompleted = () => {
    setError()
  }

  const { loading, data } = useQuery(GET_DOWNLOADING_URL, {
    variables: {
      fileId
    },
    onError,
    onCompleted
  })

  const [deleteFile, { loading: deleting }] = useMutation(REMOVE_FILE_FROM_STUDY, {
    onError,
    onCompleted
  })

  const onDelete = () => {
    const variables = {
      id: studyId,
      fileId
    }
    deleteFile({ variables })
  }

  if (loading) {
    return <div className='d-flex justify-content-center'>
      <LoadingInline />
    </div>
  }

  const url = ((data || {}).studyDownloadURL || {}).url
  const isDeletionAllowed = userCanDelete(userHasRole)

  const className = 'position-relative my-2 ' + (props.className || '')
  return <div className={className}>
    <div className='w-100 d-flex justify-content-center'>
      <AsyncImage src={url} className='img-fluid' />

      {
        !isDisabled && isDeletionAllowed &&
        <div className='w-100 d-flex justify-content-end position-absolute p-1'>
          <ConfirmationModal
            action={
              <DeleteIcon className='mx-1' />
            }
            message={
              <T id={messages.deleteConfirmationMessage.id}>
                {intl.formatMessage(messages.deleteConfirmationMessage)}
              </T>
            }
            isLoading={deleting}
            className='btn-ghost-danger '
            error={error}
            onConfirm={onDelete}
          />
        </div>
      }
    </div>
    {error && <ErrorViewer error={error} />}
  </div>
}
