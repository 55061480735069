import { TranslatableText } from 'api/interfaces'
import React from 'react'
import { DynamicTranslation } from './i18n'

interface InlineSeparatedListProps {
  list: Array<string>
  separator?: string
  className?:string
}

export const InlineSeparatedList: React.VFC<InlineSeparatedListProps> = (props) => {
  const { list, separator = ',', className } = props

  return (
    <span className={className}>
      {list.map((listItem, index) => {
        return (
          <span key={index}>
            {listItem}
            {index < list.length - 1 && `${separator} `}
          </span>
        )
      })}
    </span>
  )
}

interface InlineSeparatedTranslatableListProps {
  list: Array<TranslatableText>
  separator?: string
  className?:string
}

export const InlineSeparatedTranslatableList: React.VFC<InlineSeparatedTranslatableListProps> = (props) => {
  const { list, separator = ',', className } = props

  return (
    <span className={className}>
      {list.map((listItem, index) => {
        const key = `${listItem.text}-${index}`

        return (
          <span key={key}>
            <DynamicTranslation text={listItem.text ?? ''}/>
            {index < list.length - 1 && `${separator} `}
          </span>
        )
      })}
    </span>
  )
}