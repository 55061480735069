import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';

const messages = defineMessages({
  redirection: {
    id: 'UI.redirection_label',
    defaultMessage: 'Redirection',
  },
  willBeRedirected: {
    id: 'UI.lazy_external_redirection_message',
    defaultMessage: 'You are going to be redirected out Walking doctors in a while...',
  },
});

interface Props {
  url: string;
  mSeconds?: number;
}
export const LazyExternalRedirectBanner: React.FC<Props> = (props) => {
  const { url, mSeconds = 4000 } = props;

  const intl = useIntl();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = url;
    }, mSeconds);

    return () => clearTimeout(timeout);
  }, [url, mSeconds]);

  return (
    <div className="card m-auto rounded p-4 ">
      <h5>
        <T id={messages.redirection.id}>{intl.formatMessage(messages.redirection)}</T>
      </h5>
      <div className="text-muted">
        <T id={messages.willBeRedirected.id}>{intl.formatMessage(messages.willBeRedirected)}</T>
      </div>
    </div>
  );
};
