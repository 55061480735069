import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { ADD_STUDY_ORDER_TO_ACTIVE_VISIT } from 'scenes/Patients/ActiveVisit/requests';
import { FullPatientWithActiveVisit, NewStudyOrder } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';

const messages = defineMessages({
  studyNotFound: {
    id: 'UseOrderActions.study_not_found',
    defaultMessage: 'The selected study was not found.',
  },
  patientNotFound: {
    id: 'UseOrderActions.patient_not_found',
    defaultMessage: 'The patient was not found.',
  },
  errorAddingStudyOrder: {
    id: 'UseOrderActions.error_adding_study_order',
    defaultMessage: 'We could not create the study, try again later.',
  },
  invalidStudyTemplateId: {
    id: 'UseOrderActions.invalid_study_template_id',
    defaultMessage: 'You must select a valid procedure.',
  },
  unexpectedError: {
    id: 'UI.unexpected_error',
    defaultMessage: 'Unexpected error. Try again',
  },
});
interface AddStudyOrderData {
  addStudyOrderToActiveVisit: FullPatientWithActiveVisit;
}

interface AddStudyOrderVariables {
  patientId: string;
  visitId: string;
  studyOrder: NewStudyOrder;
}

type UseAddStudyOrderProps = MutationHookOptions<AddStudyOrderData, AddStudyOrderVariables> & {
  patientId: string;
  visitId: string;
};

export const useAddStudyOrder = (props: UseAddStudyOrderProps) => {
  const { patientId, visitId, ...rest } = props;

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    if (typeof rest?.onError === 'function') {
      rest.onError(error);
    }

    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'invalid_study_id':
      case 'missing_system_id':
      case 'template_not_found':
        return setError(messages.studyNotFound);

      case 'patient_not_found':
        return setError(messages.patientNotFound);

      case 'error_adding_study_order':
      case 'error_creating_study':
        return setError(messages.errorAddingStudyOrder);

      case 'invalid_study_template_id':
        return setError(messages.invalidStudyTemplateId);
      default:
        return setError(messages.unexpectedError);
    }
  };

  const onCompleted = (data: AddStudyOrderData) => {
    setError(null);
    if (typeof rest?.onCompleted === 'function') {
      rest.onCompleted(data);
    }
  };

  const [addStudyOrder, { loading: isLoading }] = useMutation(ADD_STUDY_ORDER_TO_ACTIVE_VISIT, {
    ...rest,
    onError,
    onCompleted,
  });

  const onSubmit = (studyOrder: NewStudyOrder) => {
    setError(null);

    const variables = {
      patientId,
      visitId,
      studyOrder,
    };

    addStudyOrder({ variables });
  };

  return {
    error,
    onSubmit,
    isLoading,
  };
};
