import React from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { BasicChecklist } from '../../api/interfaces';
import { Nullable } from 'components-ts/utils';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from './utils';
import { useChecklistSubscriptionsContext } from 'components-ts/Exam/useChecklistSubscriptionsContext';

type ChecklistSelectorProps = SelectorProps<string>;

const SubscribedChecklistsSelector: React.FC<ChecklistSelectorProps> = (props) => {
  const { value } = props;

  const intl = useIntl();

  const { checklists, getChecklist, isLoading } = useChecklistSubscriptionsContext();

  const formatOption = React.useCallback(
    (checklist: BasicChecklist): ReactSelectInternalSingleValue => {
      const { id, name, background } = checklist;

      const translatedName = name?.text
        ? intl.formatMessage({ id: `Dynamic.${name.text}`, defaultMessage: name.text })
        : '';

      const translatedBackground = background?.text
        ? intl.formatMessage({ id: `Dynamic.${background.text}`, defaultMessage: background.text })
        : '';

      const [shortText] = translatedName.split('(');
      return {
        label: (
          <div>
            <b style={{ lineHeight: '.8em' }}>{shortText}</b>
            {translatedBackground && (
              <p
                className="font-size-sm font-italic"
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: '.9em',
                  lineHeight: '.9em',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '120ch',
                }}
              >
                {translatedBackground}
              </p>
            )}
          </div>
        ),
        id: id,
        value: shortText,
      };
    },
    [intl]
  );

  const options = React.useMemo(() => {
    if (Array.isArray(checklists)) {
      return checklists.map((value) => formatOption(value as BasicChecklist));
    }

    return [];
  }, [checklists, formatOption]);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): Nullable<ReactSelectInternalValue> => {
    if (options === null) {
      return null;
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return null;
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, checklistId: string) => {
        const checklist = getChecklist(checklistId);

        if (checklist) {
          return [...prev, formatOption(checklist)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      const checklist = getChecklist(value as string);

      if (checklist) {
        return formatOption(checklist);
      }

      return null;
    }
  };

  const _onChange = (internalValue): void => {
    if (isMultiSelector(props)) {
      if (!Array.isArray(internalValue)) {
        return props.onChange([]);
      }

      const selectedValues = internalValue;
      const newValues = selectedValues.map((item) => item.id);

      props.onChange(newValues);
    } else {
      if (!internalValue) {
        return props.onChange('');
      }

      const selectedValue = internalValue;

      props.onChange(selectedValue.id);
    }
  };

  return (
    <Select
      options={options}
      value={parseValue(value)}
      onChange={_onChange}
      isLoading={isLoading}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      aria-invalid={props.isInvalid}
      placeholder={props.placeholder}
      blurInputOnSelect={props.blurInputOnSelect}
    />
  );
};

export default SubscribedChecklistsSelector;
