import React from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { injectIntl, defineMessages } from 'react-intl'
import { QUICKPICK_DAYS } from 'wd-common/src/logic/rx/definitions'

const messages = defineMessages({
  daysLabel: {
    id: 'DaysSelector.days_label',
    defaultMessage: '{days} days'
  },
  dayLabel: {
    id: 'DaysSelector.day_label',
    defaultMessage: '{days} day'
  }
})
/**
 * It allows the user to select a amount of days for a quick prescription
 * @component
 * @example
 *
 * const onChange = (days) => debug(days)
 * return (
 *   <DaysSelector
 *      value={days}
 *      onChange={onChange}
 *   />
 * )
 */
const DaysSelector = (props) => {
  const {
    value,
    onChange,
    isDisabled,
    className,
    intl
  } = props

  const onDaysSelectorChange = status => {
    if (typeof onChange === 'function') {
      onChange(status)
    }
  }

  return <div className={`d-flex align-items-center ${className}`}>
    <ButtonGroup >
      {
        Object.values(QUICKPICK_DAYS)
          .map(days => {
            return <Button
              disabled={isDisabled}
              key={`days-selector-${days}`}
              color={`${value === days ? 'primary' : ''}`}
              className={` ${value !== days && 'btn-outline-secondary'} `}
              onClick={e => {
                if (value !== days) onDaysSelectorChange(days)
              }}
            >
              {
                days === 1
                  ? intl.formatMessage(messages.dayLabel, { days })
                  : intl.formatMessage(messages.daysLabel, { days })
              }
            </Button>
          })
      }
    </ButtonGroup>
  </div>
}

export default injectIntl(DaysSelector)
