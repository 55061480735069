import React from 'react';
import { Router } from 'react-router-dom';
import { SessionProvider } from 'components-ts/Auth';
import { createBrowserHistory } from 'history';
import { RouterContainer } from './components-ts/Routing';
import { TranslationProvider } from 'components-ts/i18n';
import ErrorBoundary from './ErrorBoundary';
import { ThemeProvider } from 'components-ts/Theme/context';
import { CustomApolloProvider } from 'api/client';

import './App.css';
import { TranslationModeContextProvider } from 'components-ts/Translations/TranslationModeContext';
const debug = require('debug')('wd:App');

// Make sure root appears in our history so back buttons work
// when visiting pages from a deep link (back needs to go somewhere
// in this case, and the root is probably the best choice)
const history = createBrowserHistory();
window.browserHistory = history;
if (history.location.pathname !== '/') {
  const oldLocation = Object.assign({}, history.location);
  history.replace('/');
  history.push(oldLocation);
}

const App = props => {
  return (
    <ErrorBoundary>
      <Router history={history}>
        <SessionProvider>
          <CustomApolloProvider>
            <TranslationProvider>
              <TranslationModeContextProvider>
                <ThemeProvider>
                  <RouterContainer />
                </ThemeProvider>
              </TranslationModeContextProvider>
            </TranslationProvider>
          </CustomApolloProvider>
        </SessionProvider>
      </Router>
    </ErrorBoundary>
  );
};
export default App;

debug('loaded');
