import React from 'react';
import { HealthSystem, PaginatedResult, Location, Room, Integrations } from 'api/interfaces';
import { useLazyQuery } from '@apollo/client';
import { GET_SYSTEMS } from 'api/request/systems';
import { Nullable, validateNotNil } from 'components-ts/utils';
import { Loading } from 'components-ts/Loading';
import { Button } from 'reactstrap';
import { useSession } from 'components-ts/Auth';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { useLazyGetLogoDownloadUrl } from './useSystemActions';
import { getLocalStorage, setLocalStorage } from 'utils/browserUtilities';
const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'Something went wrong while trying to get settings from our servers. Please, contact to our team',
  },
});
interface SystemsQueryData {
  systems: PaginatedResult<HealthSystem>;
}

export interface SystemContextData {
  isLoading: boolean;
  systems: Array<HealthSystem>;
  systemHasIntegration: (integration: Integrations, systemId?: string) => boolean;
  getSystem: (systemId: string) => Nullable<HealthSystem>;
  getLocation: (systemId: string, locationId: string) => Nullable<Location>;
  getRoom: (systemId: string, locationId: string, roomId: string) => Nullable<Room>;
  getMySystem: () => HealthSystem;
  getMySystemLogo: () => Nullable<string>;
  getMyLocation: () => Location;
  refetch: () => void;
}

export const SystemsContext = React.createContext<Nullable<SystemContextData>>(null);
export const SystemsProvider: React.FC = props => {
  const { getUserLocation, getUserSystem, logOut } = useSession();

  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);
  const [systems, setSystems] = React.useState<Array<HealthSystem>>([]);

  const onError = errorResponse => {
    const { networkError } = errorResponse;

    if (networkError !== null) {
      if ((networkError as any)?.statusCode === 401) {
        return;
      }
    }

    setError(messages.internalServerError);
  };

  const onCompleted = () => {
    setError(null);
  };

  const [fetchSystems, { loading: isLoadingSystems, data, refetch }] = useLazyQuery<SystemsQueryData>(GET_SYSTEMS, {
    onCompleted,
    onError,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-only', // Used for subsequent executions
  });

  const { getUrl, url, isLoading: isLoadingLogo } = useLazyGetLogoDownloadUrl({ onCompleted, onError });

  const isLoading = isLoadingSystems || isLoadingLogo;

  React.useEffect(() => {
    if (systems.length > 0) {
      const { id: mySystemId } = getUserSystem();
      const mySystem = systems.find(s => s.id === mySystemId);

      const existingImage = getLocalStorage(`logo-${mySystemId}`);
      const systemHasLogo = mySystem?.logoFileId;

      if (!existingImage && systemHasLogo && url) {
        fetch(url).then(r =>
          r.blob().then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const logoDataUrl = reader.result as string;
              setLocalStorage({ [`logo-${mySystemId}`]: logoDataUrl });
              return logoDataUrl;
            };
            reader.readAsDataURL(blob);
          })
        );
      }
    }
  }, [url, systems, getUserSystem]);

  React.useEffect(() => {
    if (systems.length > 0) {
      const { id: mySystemId } = getUserSystem();
      const mySystem = systems.find(s => s.id === mySystemId);

      const existingImage = getLocalStorage(`logo-${mySystemId}`);
      const systemHasLogo = mySystem?.logoFileId;

      if (!existingImage && systemHasLogo) {
        getUrl({ variables: { systemId: mySystemId } });
      }
    }
  }, [systems, getUserSystem, getUrl]);

  React.useEffect(() => {
    if (data && Array.isArray(data?.systems.docs)) {
      setSystems(data.systems.docs);
    }
  }, [data]);

  React.useEffect(() => {
    fetchSystems();
  }, []); // eslint-disable-line

  const getSystem = React.useCallback(
    (systemId: string): Nullable<HealthSystem> => {
      const found = systems.find(system => system.id === systemId);

      return found ?? null;
    },
    [systems]
  );

  const getLocation = React.useCallback(
    (systemId: string, locationId: string): Nullable<Location> => {
      const system = getSystem(systemId);

      if (system !== null && Array.isArray(system?.locations)) {
        const location = system.locations.find(location => location.id === locationId);

        return location ?? null;
      }

      return null;
    },
    [getSystem]
  );

  const getRoom = React.useCallback(
    (systemId: string, locationId: string, roomId: string): Nullable<Room> => {
      const location = getLocation(systemId, locationId);

      if (location !== null && Array.isArray(location?.rooms)) {
        const room = location.rooms.find(room => room.id === roomId);

        return room ?? null;
      }

      return null;
    },
    [getLocation]
  );

  const getMyLocation = React.useCallback((): Location => {
    const { id: systemId } = getUserSystem();
    const { id: locationId } = getUserLocation();

    const location = getLocation(systemId, locationId);

    // if error, all the children will unmounted
    return location as Location;
  }, [getLocation, getUserLocation, getUserSystem]);

  const getMySystem = React.useCallback((): HealthSystem => {
    const { id: systemId } = getUserSystem();

    const system = getSystem(systemId);

    return system as HealthSystem;
  }, [getSystem, getUserSystem]);

  const getMySystemLogo = React.useCallback(() => {
    const { id: mySystemId } = getMySystem();
    return getLocalStorage(`logo-${mySystemId}`);
  }, [getMySystem]);

  const systemHasIntegration = React.useCallback(
    (integration: Integrations, systemId?: string) => {
      let system: Nullable<HealthSystem>;

      if (systemId) {
        system = getSystem(systemId);
      } else {
        system = getMySystem();
      }

      if (system) {
        /**
         * TODO: this is temporarly and we should create shortcut to enable a new
         * integration called satu sehat that automatically configure the webhook
         */
        if (integration === Integrations.SATU_SEHAT) {
          return system.settings.webhooks?.dailyReport?.isEnabled ?? false;
        } else {
          return system.settings.integrations?.[integration]?.isEnabled ?? false;
        }
      }

      return false;
    },
    [getSystem, getMySystem]
  );

  const value = {
    isLoading,
    systems,
    refetch,
    getRoom,
    getLocation,
    getSystem,
    getMySystem,
    getMySystemLogo,
    getMyLocation,
    systemHasIntegration,
  };

  /**
   * Loading state will unmount all the children
   */
  if (systems.length === 0) {
    return <Loading className='text-primary' />;
  }

  return (
    <SystemsContext.Provider value={value}>
      {error ? (
        <div className='p-3 card m-auto' style={{ maxWidth: 540 }}>
          <ErrorViewer error={error} />
          <Button className='mt-3 mx-auto' onClick={() => logOut()}>
            Logout
          </Button>
        </div>
      ) : (
        props.children
      )}
    </SystemsContext.Provider>
  );
};

export const useSystems = (): SystemContextData => {
  const systems = React.useContext(SystemsContext);
  validateNotNil(systems);

  return systems;
};
