import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { DrugRoute } from 'components/Pharmacy'
import { DynamicTranslation } from '..'
import { DaysMessage, FrequencyMessage } from 'components-ts/RXs/FrequencyMessage'
import { DoseUnit } from 'components-ts/Pharmacy/DoseUnit'

const messages = defineMessages({
  max: {
    id: 'DrugGuideline.max',
    defaultMessage: 'max'
  },
  asNeeded: { id: 'label_prn_as_needed', defaultMessage: 'PRN' }
})

const getDrugName = drug => {
  if (typeof drug.name === 'string') {
    return drug.name
  }

  if (drug.name) {
    return drug.name.text
  }

  return null
}

const DrugGuideline = props => {
  const { drug, drugNameClass, className = '' } = props
  const intl = useIntl()

  if (typeof drug !== 'object') {
    return null
  }

  const name = getDrugName(drug)
  const maxDose = `${drug.max_dose}${drug.max_dose_units ? ' ' + drug.max_dose_units : ' '} `

  const range =
    Math.abs(drug.dose_range_min - drug.dose_range_max) < 0.00001
      ? drug.dose_range_min + ' '
      : [drug.dose_range_min, drug.dose_range_max].filter(i => i).join('-') + ' '

  return (
    <span className={`${className} w-100`}>
      {name && (
        <span className={drugNameClass || 'text-capitalize font-weight-bold'}>
          <DynamicTranslation text={name} />
        </span>
      )}

      <span className='ml-1'>{range}</span>

      {drug.dose_units && <DoseUnit doseUnit={drug.dose_units} />}

      {drug.max_dose ? (
        <span key='span_dose_max'>
          {` (${maxDose}`}
          <T id={messages.max.id}>{intl.formatMessage(messages.max)}</T>
          {')'}
        </span>
      ) : drug.dose_max && drug.dose_max.text ? (
        <span key='span_dose_max'>
          {'('}
          <DynamicTranslation text={drug.dose_max.text} />
          <T id={messages.max.id} className='ml-1'>{intl.formatMessage(messages.max)}</T>
          {')'}
        </span>
      ) : null}
      {drug.route && drug.route.text ? (
        <span className='uppercase ml-1'>
          <DrugRoute route={drug.route.text} />
        </span>
      ) : null}
      {drug.frequency_show && drug.doses_per_day && (
        <span className='ml-1'>
          <FrequencyMessage frequencyShow={drug.frequency_show} dosesPerDay={drug.doses_per_day} />
        </span>
      )}
      {drug && drug.days && (
        <span className='ml-1'>
          <DaysMessage days={drug.days} />
        </span>
      )}
      {drug.as_needed && (
        <span className='ml-1'>
          <T id={messages.asNeeded.id}>{intl.formatMessage(messages.asNeeded)}</T>
        </span>
      )}
    </span>
  )
}

export default DrugGuideline
