import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UPDATE_CHIEF_COMPLAINT, UPDATE_CLINICAL_STATUS } from 'scenes/Patients/ActiveVisit/requests'
import { graphQLErrorFormatter } from 'utils/errors'

const messages = defineMessages({
  invalidClinicalStatus: {
    id: 'PatientStatus.invalid_clinical_status',
    defaultMessage: 'Invalid clinical status'
  },
  invalidChiefComplaint: {
    id: 'PatientStatus.invalid_chief_complaint',
    defaultMessage: 'Invalid chief complaint'
  },
  patientNotFound: {
    id: 'PatientStatus.patient_not_found',
    defaultMessage: 'Patient not found'
  },
  errorUpdatingStatus: {
    id: 'PatientStatus.error_updating_status',
    defaultMessage: 'Could not update the status. Please, try again'
  }
})

const useStatusActions = () => {
  const intl = useIntl()
  const [error, setError] = useState()

  const errorFormatter = (errorCode) => {
    switch (errorCode) {
      case 'invalid_clinical_status':
        return {
          id: errorCode,
          message: intl.formatMessage(messages.invalidClinicalStatus)
        }

      case 'invalid_chief_complaint':
        return {
          id: errorCode,
          message: intl.formatMessage(messages.invalidChiefComplaint)
        }

      case 'patient_not_found':
        return {
          id: errorCode,
          message: intl.formatMessage(messages.patientNotFound)
        }

      case 'error_updating_clinical_status':
        return {
          id: errorCode,
          message: intl.formatMessage(messages.errorUpdatingStatus)
        }

      default:
        return { id: '' }
    }
  }

  const onError = (errors) => {
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter)
    setError(translatedErrors)
  }

  const onCompleted = () => {
    setError()
  }

  const [updateClinicalStatus, { loading: updatingStatus }] = useMutation(
    UPDATE_CLINICAL_STATUS,
    { onError, onCompleted }
  )
  const [updateChiefComplaint, { loading: updatingChiefComplaint }] =
    useMutation(UPDATE_CHIEF_COMPLAINT, { onError, onCompleted })

  return {
    updateClinicalStatus,
    updateChiefComplaint,
    updatingStatus,
    updatingChiefComplaint,
    error
  }
}

export default useStatusActions
