import React, { useState } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { useDropzone } from 'react-dropzone'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { ErrorViewer, LoadingInline } from 'components'
import { AiFillFileImage } from 'react-icons/ai'
import { GET_UPLOADING_URL } from 'api/request/lab'
import { useLazyQueryAsPromise } from 'hooks'
import { graphQLErrorFormatter } from 'utils/errors'
import { MAX_STUDY_FILE_LENGHT_2_MB } from 'wd-common/src/logic/lab/definitions'
import { putFileInS3 } from 'components-ts'

const messages = defineMessages({
  dragImage: {
    id: 'StudyFileUploader.drag_result_here',
    defaultMessage: 'Drag your result file or click here'
  },
  accessDenied: {
    id: 'StudyFileUploader.error_access_denied',
    defaultMessage: 'You have no permissions to upload files. Please, contact your system administrator.'
  },
  errorInvalidFormat: {
    id: 'StudyFileUploader.error_invalid_format',
    defaultMessage: 'Invalid file. Try with an image - JPG, PNG, GIF or BMP - Less than 2MB'
  },
  errorAddingFile: {
    id: 'StudyFileUploader.error_uploading_file',
    defaultMessage: 'We could not upload the file. Try again'
  },
  removeButton: {
    id: 'UI.remove_button',
    defaultMessage: 'Remove'
  }
})

const ACCEPTED_FILES = 'image/jpg, image/jpeg, image/png, image/gif, image/bmp'

const useStudyFileUploader = (patientId, onChange, formatMessage) => {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  const getUploadingURL = useLazyQueryAsPromise(GET_UPLOADING_URL)

  // translate errors
  const errorFormatter = errorCode => {
    switch (errorCode) {
      case 'AccessDenied':
        return {
          id: errorCode,
          message: formatMessage(messages.accessDenied)
        }
      case 'invalid_file_extension':
        return {
          id: errorCode,
          message: formatMessage(messages.errorInvalidFormat)
        }
      default:
        return {
          id: errorCode,
          message: formatMessage(messages.errorAddingFile)
        }
    }
  }

  const onError = (errors) => {
    setLoading(false)
    const translatedErrors = graphQLErrorFormatter(errors, errorFormatter)
    setError(translatedErrors)
  }

  /**
   * Listener for file input
   *
   * @param {Array<File>} acceptedFiles
   */
  /* eslint-env browser */
  const onUpload = (acceptedFiles) => {
    if (!Array.isArray(acceptedFiles) || acceptedFiles.length === 0) {
      setError({
        id: 'invalid_file_extension',
        message: formatMessage(messages.errorInvalidFormat)
      })

      return
    }

    setLoading(true)
    const file = acceptedFiles[0]

    const reader = new FileReader()
    reader.onload = async () => {
      const variables = {
        patientId,
        mymeType: file.type
      }

      try {
        const { studyUploadURL } = await getUploadingURL({ variables })
        const { url, fields } = studyUploadURL

        const response = await putFileInS3(file, url, fields)

        if (response.ok) {
          if (typeof onChange === 'function') {
            onChange(fields.key)
          }
        } else {
          /**
           * show the default error because
           * there is no useful data to show to the users
           */
          setError(response.statusText)
        }
      } catch (error) {
        onError(error)
      }

      setLoading(false)
    }

    reader.readAsText(file)
  }

  return {
    onUpload,
    loading,
    error
  }
}

const StudyFileUploader = (props) => {
  const { patientId, onChange, isDisable, intl } = props

  const { onUpload, loading, error } = useStudyFileUploader(patientId, onChange, intl.formatMessage)

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onUpload,
    multiple: false,
    maxSize: MAX_STUDY_FILE_LENGHT_2_MB,
    accept: ACCEPTED_FILES,
    disabled: isDisable
  })

  return (
    <div >
      <div
        {...getRootProps()}
        className='p-4 form-control rounded-lg d-flex align-items-center justify-content-center'
      >
        <input {...getInputProps()} />

        <div className='text-center text-muted'>
          {loading ? <LoadingInline /> : <AiFillFileImage />}{' '}
          <T id={messages.dragImage.id}>
            {intl.formatMessage(messages.dragImage)}
          </T>
        </div>
      </div>
      {error && <ErrorViewer error={error} className='mt-2' />}
    </div>
  )
}

export default injectIntl(StudyFileUploader)
