import { Nullable } from 'components-ts/utils';
import { useContext } from 'react';
import { SchedulerContext, SchedulerContextValue } from './SchedulerContext';

export const useSchedulerContext = () => {
  const value = useContext(SchedulerContext)
  validateContextValue(value)

  return value
};
 
function validateContextValue (value:Nullable<SchedulerContextValue>): asserts value is SchedulerContextValue {
  if(value === null){
    throw TypeError('Using scheduler context out of the provider')
  }
}