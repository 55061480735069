import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { FaSearch as SearchIcon } from 'react-icons/fa';

const messages = defineMessages({
  notFoundWithID: {
    id: 'UI.resource_with_id_not_found',
    defaultMessage: 'Resource not found. ID: {resourceId}',
  },
  notFound: {
    id: 'UI.resource_not_found',
    defaultMessage: 'Resource not found. ',
  },
});

interface NotFoundProps {
  resourceId?: string;
}

export const NotFound: React.FC<NotFoundProps> = (props) => {
  const { resourceId } = props;

  const intl = useIntl();

  return (
    <div className="d-flex flex-column align-items-center py-4">
      <SearchIcon className="h4 text-muted" />
      {resourceId ? (
        <T id={messages.notFoundWithID.id}>{intl.formatMessage(messages.notFoundWithID, { resourceId })}</T>
      ) : (
        <T id={messages.notFound.id}>{intl.formatMessage(messages.notFound)}</T>
      )}
    </div>
  );
};
