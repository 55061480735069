import React from 'react';
import { validateNotNil } from 'components-ts/utils';
import { ThemeContext, ThemeContextValue } from './context';

export const useTheme = (): ThemeContextValue => {
  const themeCtx = React.useContext(ThemeContext);
  validateNotNil<ThemeContextValue>(themeCtx);

  return themeCtx;
};
