import { useQuery } from '@apollo/client'
import { GET_QUICKPICK_BY_ID } from 'scenes/Quickpicks/requests'
/**
 * @param {
  *   queryVariables: { id },
  *   queryOptions: { onCompleted, onError, ... }
  * }
  *
  */
function useQuickpick (queryVariables, queryOptions) {
  const { id } = queryVariables
  // loading visits
  const { loading, data, error } = useQuery(GET_QUICKPICK_BY_ID, {
    variables: {
      id
    },
    skip: !id,
    ...queryOptions
  })

  return {
    loading,
    quickpick: data ? data.quickpick : null,
    error
  }
}
export default useQuickpick
