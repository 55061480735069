import { MutationHookOptions, useMutation } from '@apollo/client';
import { Drug } from 'api/interfaces';
import { ValidateFn, ValidationError } from 'components-ts/Forms/utils';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { UPDATE_PHARMACY_STOCK } from 'scenes/Pharmacy/requests';

const messages = defineMessages({
  updateError: {
    id: 'UpdatePharmacyItemStock.update_error',
    defaultMessage: 'An error ocurred while updating the item',
  },
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
  noAmountError: {
    id: 'CreatePharmacyItemStock.no_amount_error',
    defaultMessage: 'Please provide an amount',
  },
  requiredError: {
    id: 'UI.required_error',
    defaultMessage: 'You must enter a value for this field.',
  },
});

export type UpdatePharmacyItemStockParams = {
  container: string;
  containerCount: number;
  containerCapacity: number;
  stock_unit: string;
  amount: number;
  item: string;
  notes?: string;
  lotNumber?: string;
  lotExpirationDate?: string;
};

export type UpdatePharmacyItemStockValidatedValues = {
  container: string;
  containerCount: number;
  containerCapacity: number;
  stock_unit: string;
  amount: number;
  notes?: string;
  lotNumber?: string;
  lotExpirationDate?: Date;
};

export type UpdatePharmacyItemStockInitialValues = Partial<UpdatePharmacyItemStockValidatedValues>;
interface UpdatePharmacyItemStockVariables {
  stockId: string;
  pharmacyStock: UpdatePharmacyItemStockParams;
}

interface UpdatePharmacyItemStockData {
  updatePharmacyItemStock: Drug;
}

type UpdatePharmacyItemStockMutationParams = MutationHookOptions<
  UpdatePharmacyItemStockData,
  UpdatePharmacyItemStockVariables
> & {
  item: string;
  id: string;
};

export const useUpdatePharmacyItemStock = (params: UpdatePharmacyItemStockMutationParams) => {
  const { item, id } = params;
  const intl = useIntl();
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      case 'update_error':
        return setError(messages.updateError);
      case 'invalid_signature':
      default:
        return setError(messages.internalServerError);
    }
  };

  const [updatePharmacyItemStock, { loading }] = useMutation<
    UpdatePharmacyItemStockData,
    UpdatePharmacyItemStockVariables
  >(UPDATE_PHARMACY_STOCK, {
    ...params,
    onError,
  });

  const onAddStockSubmit = (values: UpdatePharmacyItemStockValidatedValues) => {
    const variables: UpdatePharmacyItemStockVariables = {
      pharmacyStock: {
        amount: values.amount,
        container: values.container,
        containerCapacity: values.containerCapacity,
        containerCount: values.containerCount,
        lotExpirationDate: values.lotExpirationDate ? values.lotExpirationDate.toISOString() : undefined,
        stock_unit: values.stock_unit,
        lotNumber: values.lotNumber,
        notes: values.notes,
        item,
      },
      stockId: id,
    };

    return updatePharmacyItemStock({
      variables,
    });
  };

  const onRemoveStockSubmit = (values: UpdatePharmacyItemStockValidatedValues) => {
    const variables: UpdatePharmacyItemStockVariables = {
      pharmacyStock: {
        amount: Math.abs(values.amount) * -1,
        container: values.container,
        containerCapacity: values.containerCapacity,
        containerCount: values.containerCount,
        lotExpirationDate: values.lotExpirationDate ? values.lotExpirationDate.toISOString() : undefined,
        stock_unit: values.stock_unit,
        lotNumber: values.lotNumber,
        notes: values.notes,
        item,
      },
      stockId: id,
    };

    return updatePharmacyItemStock({
      variables,
    });
  };

  const validate: ValidateFn<UpdatePharmacyItemStockInitialValues> = (values) => {
    const errors: ValidationError<UpdatePharmacyItemStockInitialValues> = {};

    if (!values.amount) {
      errors.amount = intl.formatMessage(messages.noAmountError);
    }

    if (!values.containerCapacity) {
      errors.containerCapacity = intl.formatMessage(messages.requiredError);
    }

    if (!values.containerCount) {
      errors.containerCount = intl.formatMessage(messages.requiredError);
    }

    return errors;
  };

  return {
    validate,
    updatePharmacyItemStock,
    onAddStockSubmit,
    onRemoveStockSubmit,
    loading,
    error,
  };
};
