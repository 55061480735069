import React from 'react';
import { Footer, FooterProps } from './Footer';
import { defineMessages, useIntl } from 'react-intl';
import { usePharmacyItems } from 'components-ts/Pharmacy';
import { useImportDrugsByTags, usePharmacyLibraryTags } from 'components-ts/Pharmacy/usePharmacyLibrary';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { PaginatedList } from 'components-ts/PaginatedList';
import { ClickableItemWrapper } from 'components-ts/SearchableList';
import { DynamicTranslation } from 'components-ts/i18n';
import { FaCheckCircle } from 'react-icons/fa';
import { ErrorViewer } from 'components-ts/ErrorViewer';

const messages = defineMessages({
  drugsTitle: {
    id: 'DrugsFirstStep.title',
    defaultMessage: 'Pharmacy',
  },
  description: {
    id: 'DrugsFirstStep.description',
    defaultMessage:
      "Now let's set up your pharmacy. You can choose to import medicines from one of WDs pre-prepared drug libraries or alternatively, manually enter your own medicines. We recommend a combination of both: Select a drug library and add and delete entries at your convenience. ",
  },
  listTitle: {
    id: 'DrugsFirstStep.list_label',
    defaultMessage: 'Libraries',
  },
});

type DrugImportStepProps = {
  footerProps: FooterProps;
};

export const DrugImportStep: React.FC<DrugImportStepProps> = (props) => {
  const { footerProps } = props;
  const { next } = footerProps;

  const intl = useIntl();

  const [selectedTags, setSelectedTags] = React.useState<Set<string>>(new Set());

  const { refetch, tags: existingTags } = usePharmacyItems();
  const onCompleted = () => {
    refetch();
    next();
  };

  const { tags, isLoading, error: errorTags } = usePharmacyLibraryTags();
  const { onSubmit, error: errorImporting, isLoading: importing } = useImportDrugsByTags({ onCompleted });

  const onClick = (tag) => {
    if (selectedTags.has(tag)) {
      setSelectedTags((prev) => {
        prev.delete(tag);
        return new Set(prev);
      });
    } else {
      setSelectedTags((prev) => {
        prev.add(tag);
        return new Set(prev);
      });
    }
  };

  const onNext = () => {
    if (selectedTags.size > 0) {
      onSubmit(Array.from(selectedTags));
    } else {
      next();
    }
  };

  const error = errorTags ?? errorImporting;
  return (
    <>
      <div>
        <div className="mb-4">
          <h3>
            <T id={messages.drugsTitle.id}>{intl.formatMessage(messages.drugsTitle)}</T>
          </h3>
          <p className="mt-2">
            <T id={messages.description.id}>{intl.formatMessage(messages.description)}</T>
          </p>
        </div>
        <div>
          <h6 className="font-weight-bolder">
            <T id={messages.listTitle.id}>{intl.formatMessage(messages.listTitle)}</T>
          </h6>
          <PaginatedList
            isLoading={isLoading}
            items={tags}
            itemsToShow={10}
            renderItem={(tag, idx) => {
              const isSelected = existingTags.has(tag.text ?? '') || selectedTags.has(tag.text ?? '');
              const isDisabled = existingTags.has(tag.text ?? '');

              return (
                <CustomItem
                  key={tag.text ?? idx}
                  tag={tag.text ?? ''}
                  onClick={onClick}
                  isDisabled={isDisabled}
                  isSelected={isSelected}
                />
              );
            }}
          />
          {error && <ErrorViewer error={error} />}
        </div>
      </div>
      <Footer {...footerProps} isNextLoading={importing} isStepLoading={isLoading} next={onNext} />
    </>
  );
};

interface CustomItemProps {
  tag: string;
  onClick: (tag: string) => void;
  isDisabled: boolean;
  isSelected: boolean;
}
const CustomItem: React.FC<CustomItemProps> = (props) => {
  const { tag, onClick, isSelected, isDisabled } = props;

  return (
    <ClickableItemWrapper
      onClick={() => onClick(tag)}
      className="d-flex justify-content-between align-items-center"
      isDisabled={isDisabled}
    >
      <span className="font-weight-bold">
        <DynamicTranslation text={tag} />
      </span>
      {isSelected && <FaCheckCircle className="text-success h4 m-0" />}
    </ClickableItemWrapper>
  );
};
