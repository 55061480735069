import React from 'react'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import Select from 'react-select'
import { FormGroup, Label } from 'reactstrap'

/**
 * Delimiter selector for CSV management
 * */
const DELIMITERS = [
  ',',
  ';',
  ':'
]

const messages = defineMessages({
  delimiterLabel: {
    id: 'UI.label_delimiter',
    defaultMessage: 'Delimiter'
  }
})

const CSVDelimiterSelector = (props) => {
  const {
    intl,
    isDisabled
  } = props

  const onChange = option => {
    if (typeof props.onChange === 'function') {
      props.onChange(option.value)
    }
  }

  const formatOption = option => ({
    label: option,
    value: option
  })
  const defaultValue = props.defaultValue
    ? formatOption(props.defaultValue)
    : formatOption(DELIMITERS[0])

  const options = DELIMITERS.map(formatOption)
  return <FormGroup className={props.className}>
    <Label>
      <T id={messages.delimiterLabel.id}>{intl.formatMessage(messages.delimiterLabel)}</T>
    </Label>
    <Select
      className='dropdownZIndex'
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      isDisabled={isDisabled}
      isSearchable
    />
  </FormGroup>
}

export default injectIntl(CSVDelimiterSelector)
