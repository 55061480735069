import { useSystems } from 'components-ts/HealthSystems';
import { useStoreStatus } from 'components-ts/HealthSystems/useStoreActions';
import { useSetFirstStepsDone } from 'components-ts/HealthSystems/useSystemActions';
import { usePharmacyItems } from 'components-ts/Pharmacy';
import { useEffect, useState } from 'react';

enum Steps {
  PHARMACY = 'pharmacy',
  CHECKLISTS = 'checklists',
  PROCEDURES = 'procedures',
  STUDIES = 'studies',
  CLOUD_STORAGE = 'cloudStorage',
}

const stepCount = Object.keys(Steps).length;

export const useSteps = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { getMySystem, getMyLocation } = useSystems();

  const system = getMySystem();
  const location = getMyLocation();

  const { count, isLoading: loadingDrugs } = usePharmacyItems();
  const { status, isLoading: loadingStatus } = useStoreStatus({ systemId: system.id });

  const {
    setFirstStepsDone,
    isLoading: finishing,
    error: errorFinishing,
  } = useSetFirstStepsDone({ systemId: system.id });

  const next = () => {
    if (currentStep + 1 === stepCount) {
      setFirstStepsDone();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  useEffect(() => {
    if (!loadingDrugs && !loadingStatus) {
      if (count === 0) {
        setCurrentStep(0);
      } else if (location.subscriptions.checklists.items.length === 0) {
        setCurrentStep(1);
      } else if (location.subscriptions.procedures.items.length === 0) {
        setCurrentStep(2);
      } else if (location.subscriptions.lab.items.length === 0) {
        setCurrentStep(3);
      } else if (!status?.isReady) {
        setCurrentStep(4);
      } else {
        setCurrentStep(stepCount - 1);
      }

      setIsLoading(false);
    }
  }, [
    loadingDrugs,
    loadingStatus,
    count,
    status,
    location.subscriptions.procedures.items.length,
    location.subscriptions.lab.items.length,
    location.subscriptions.checklists.items.length,
  ]);

  return {
    isLoading,
    currentStep,
    stepCount,
    finishing,
    errorFinishing,
    next,
    prev,
  };
};
