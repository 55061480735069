import { useIntl, defineMessages } from 'react-intl';
import { CommonRouteType, DoseUnit, FrequencyShow, FullRX } from 'api/interfaces/rxs';
import { NewSystemDrugGuideline, NewUserDrugGuideline } from 'api/interfaces';

const messages = defineMessages({
  dispense: {
    id: 'CustomRxDrug.text_dispense',
    defaultMessage: 'dispense',
  },
  // this messages are used in common/src/logic/rx/index
  // this is just for building them
  hoursBetween: { id: 'Prescription.hours_between', defaultMessage: 'hours between' },
  once: { id: 'Prescription.once_a_day', defaultMessage: 'once a day' },
  every72: { id: 'Prescription.every_72_hours', defaultMessage: 'every 72 hours' },
  every48: { id: 'Prescription.every_48_hours', defaultMessage: 'every 48 hours' },
  every36: { id: 'Prescription.every_36_hours', defaultMessage: 'every 36 hours' },
  every12: { id: 'Prescription.every_12_hours', defaultMessage: 'every 12 hours' },
  every8: { id: 'Prescription.every_8_hours', defaultMessage: 'every 8 hours' },
  every6: { id: 'Prescription.every_6_hours', defaultMessage: 'every 6 hours' },
  every4: { id: 'Prescription.every_4_hours', defaultMessage: 'every 4 hours' },
  every3: { id: 'Prescription.every_3_hours', defaultMessage: 'every 3 hours' },
  every2: { id: 'Prescription.every_2_hours', defaultMessage: 'every 2 hours' },
  every1: { id: 'Prescription.every_hour', defaultMessage: 'every hour' },
  every10m: { id: 'Prescription.every_10_minutes', defaultMessage: 'every 10 minutes' },
  every5m: { id: 'Prescription.every_5_minutes', defaultMessage: 'every 5 minutes' },
  every3m: { id: 'Prescription.every_3_minutes', defaultMessage: 'every 3 minutes' },
  timesPerDay: { id: 'Prescription.times_per_day', defaultMessage: 'times per day' },
  dosesADay: { id: 'Prescription.doses_a_day', defaultMessage: 'doses a day' },
  dose: { id: 'Prescription.dose', defaultMessage: 'dose' },
  doses: { id: 'Prescription.doses', defaultMessage: 'doses' },
  days: { id: 'Prescription.days', defaultMessage: 'Days' },
  day: { id: 'Prescription.day', defaultMessage: 'Day' },
  asNeeded: { id: 'label_prn_as_needed', defaultMessage: 'PRN' },
});

export const useRxUtils = () => {
  const intl = useIntl();

  const getFrequency = (dosesPerDay: number) => {
    switch (dosesPerDay) {
      case 0.3333333333333333:
        return intl.formatMessage(messages.every72);
      case 0.5:
        return intl.formatMessage(messages.every48);
      case 0.6666666666666666:
        return intl.formatMessage(messages.every36);
      case 1:
        return intl.formatMessage(messages.once);
      case 2:
        return intl.formatMessage(messages.every12);
      case 3:
        return intl.formatMessage(messages.every8);
      case 4:
        return intl.formatMessage(messages.every6);
      case 6:
        return intl.formatMessage(messages.every4);
      case 8:
        return intl.formatMessage(messages.every3);
      case 12:
        return intl.formatMessage(messages.every2);
      case 24:
        return intl.formatMessage(messages.every1);
      case 144:
        return intl.formatMessage(messages.every10m);
      case 288:
        return intl.formatMessage(messages.every5m);
      case 480:
        return intl.formatMessage(messages.every3m);
      default: {
        if (dosesPerDay % 1) {
          const hours = Math.round(Math.abs(24 / dosesPerDay));
          return `${hours} ${intl.formatMessage(messages.hoursBetween)}`;
        }
        return `${dosesPerDay} ${intl.formatMessage(messages.timesPerDay)}`;
      }
    }
  };

  const getDosesPerDay = (dosesPerDay: number) => {
    const message =
      dosesPerDay === 1
        ? intl.formatMessage(messages.once)
        : `${dosesPerDay} ${intl.formatMessage(messages.timesPerDay)}`;

    return message;
  };

  const getDoses = (doses: number) => {
    const message = doses === 1 ? intl.formatMessage(messages.dose) : intl.formatMessage(messages.doses);
    return `${doses} ${message}`;
  };

  const getDaysMessage = (days: number) => {
    // we need to translate this messages.
    // getTranslations should be a reference to formatMessage, extracted form react-intl

    const daysMessage = days > 1 ? intl.formatMessage(messages.days) : intl.formatMessage(messages.day);

    return `${days} ${daysMessage}`;
  };

  const getFrequencyMessage = (frequencyShow: string, dosesPerDay: number) => {
    // we need to translate this messages.
    // getTranslations should be a reference to formatMessage, extracted form react-intl

    switch (frequencyShow) {
      case 'doses_per_day':
        return getDosesPerDay(dosesPerDay);
      case 'doses':
        return getDoses(dosesPerDay);
      default:
        return getFrequency(dosesPerDay);
    }
  };

  const customizedRXView = (rx: FullRX, format: string) => {
    // we need to translate this messages.
    // getTranslations should be a reference to formatMessage, extracted form react-intl

    // legacy
    const legacyDrugText = rx['drug_text'] ?? '';
    const legacyDoseText = rx['dose_text'] ?? '';
    const legacyRouteText = rx['route_text'] ?? '';
    const legacyDuration = rx['frequency_text'] ?? '';
    const legacyFrequency = rx['duration_text'] ?? '';
    const legacyDispense = rx['dispense_text'] ?? '';

    // drug
    const drugName = rx.drug.name;
    const drugClass = rx.drug.class;
    const drugDosingUnit = rx.drug.unit;
    const drugStockUnit = rx.drug.stock_unit ?? '';
    const drugMinimumDispense = rx.drug.minimum_units_to_dispense
      ? intl.formatNumber(rx.drug.minimum_units_to_dispense)
      : '';

    // guideline
    const preText = rx.guideline ? rx.guideline['pre_text']?.text : '';
    const postText = rx.guideline ? rx.guideline['post_text']?.text : '';
    const rangeMax = rx.guideline ? rx.guideline['dose_range_max'] : '';
    const rangeMin = rx.guideline ? rx.guideline['dose_range_min'] : '';

    // rx details
    const doseUnit = rx.details.drug_dose_units ?? '';
    const doseNumber = rx.details.drug_dose_number ? intl.formatNumber(rx.details.drug_dose_number) : '';
    const dosesPerDay = rx.details.doses_per_day
      ? rx.details.doses_per_day % 1
        ? intl.formatNumber(rx.details.doses_per_day, { maximumFractionDigits: 3 })
        : intl.formatNumber(rx.details.doses_per_day)
      : '';

    const unitsPerDose = intl.formatNumber(rx.details.units_per_dose);
    const days = getDaysMessage(rx.details.days);
    const route = rx.details.route;
    const dispense = intl.formatNumber(rx.details.dispense);
    const asNeeded = rx.details.as_needed ?? false;
    const frequency =
      rx.details.frequency_show && rx.details.doses_per_day
        ? getFrequencyMessage(rx.details.frequency_show, rx.details.doses_per_day)
        : '';
    const notes = rx.details.notes ?? '';

    const formatted = format
      // legacy
      .replace(/{legacy-drug-text}/g, legacyDrugText)
      .replace(/{legacy-dose-text}/g, legacyDoseText)
      .replace(/{legacy-route-text}/g, legacyRouteText)
      .replace(/{legacy-duration}/g, legacyDuration)
      .replace(/{legacy-frequency}/g, legacyFrequency)
      .replace(/{legacy-dispense}/g, legacyDispense)

      // drug
      .replace(/{drug-name}/g, drugName)
      .replace(/{drug-class}/g, drugClass)
      .replace(/{drug-unit}/g, drugDosingUnit)
      .replace(/{drug-stock-unit}/g, drugStockUnit)
      .replace(/{drug-minimum-dispense}/g, drugMinimumDispense)

      // guideline
      .replace(/{guideline-pre-text}/g, preText)
      .replace(/{guideline-post-text}/g, postText)
      .replace(/{guideline-range-min}/g, rangeMin)
      .replace(/{guideline-range-max}/g, rangeMax)

      // rx details
      .replace(/{dose-number}/g, doseNumber)
      .replace(/{dose-unit}/g, doseUnit)
      .replace(/{doses-per-day}/g, dosesPerDay)
      .replace(/{units-per-dose}/g, unitsPerDose)
      .replace(/{route}/g, route)
      .replace(/{uppercased-route}/g, route ? route.toUpperCase() : '')
      .replace(/{dispense}/g, dispense)
      .replace(/{days}/g, days)
      .replace(/{as-needed}/g, asNeeded ? intl.formatMessage(messages.asNeeded) : '')
      .replace(/{frequency}/g, frequency)
      .replace(/{notes}/g, notes);

    // to verify format errors
    return { value: formatted, error: formatted.match(/(\{[a-zA-Z -]+\})/g) };
  };

  return {
    customizedRXView,
    getFrequencyMessage,
  };
};

export const isCommonRouteType = (route?: string): route is CommonRouteType => {
  return Object.values(CommonRouteType).includes(route?.toLowerCase() as CommonRouteType);
};

export const isAValidUnit = (dose: string): dose is DoseUnit => {
  return Object.values(DoseUnit).includes(dose.toLowerCase() as DoseUnit);
};

export const isAValidFrequencyShow = (frequency: string): frequency is FrequencyShow => {
  return Object.values(FrequencyShow).includes(frequency.toLowerCase() as FrequencyShow);
};

/**
 * We have 2 different types of guidelines. One is generated by us, that's why the texts are translatable texts
 * and the other one is created by users, which means that are generated in their original languages.
 * So, lets take the name, which is a requiered field to check clasiffy a Guideline based on it origin
 */
export const isUserGeneratedGuideline = (
  guideline: NewSystemDrugGuideline | NewUserDrugGuideline
): guideline is NewUserDrugGuideline => {
  return 'name' in guideline && typeof guideline.name === 'string';
};

export const DEFAULT_RX_DETAILS = {
  frequencyShow: FrequencyShow.DOSES_PER_DAY,
  dosesPerDay: 3,
  days: 3,
  route: CommonRouteType.PO,
  dispense: 1,
  refillsAllowed: 0,
  notes: '',
};

export enum QUICKPICK_DAYS {
  ONE_DAY = 1,
  THREE_DAY = 3,
  FIVE_DAY = 5,
  SEVEN_DAY = 7,
}
