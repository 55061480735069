import React from 'react'
import { useIntl } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'

/**
 * Use this component if you need to translate dynamic text (i.e: Medicines)
 * @param {*} text: Text to be translated dynamically
 */
export const DynamicTranslation = props => {
  const { text, className } = props

  const intl = useIntl()

  if (typeof text !== 'string' || text.trim().length === 0) return null

  const prefix = 'Dynamic'
  const defaultMessage = text
  const id = `${prefix}.${defaultMessage}`

  return <T id={id} className={className}>
    {intl.formatMessage({ id, defaultMessage })}
  </T>
}

export const useDynamicTranslation = () => {
  const intl = useIntl()

  return {
    translateDynamic: (text) => {
      if (typeof text !== 'string' || text.trim().length === 0) return null

      const prefix = 'Dynamic'
      const defaultMessage = text
      const id = `${prefix}.${defaultMessage}`

      return intl.formatMessage({ id, defaultMessage })
    }
  }
}
