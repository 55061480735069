import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import { FullProcedureOrder } from 'api/interfaces';
import { useSimplifiedDateFromSignatures } from './utils';
import { Nullable } from 'components-ts/utils';
import { OrderStatus } from './OrderStatus';
import { OrderComments } from './OrderComments';
import { OrderActionMenu } from './OrderActions';
import { DynamicTranslation } from 'components-ts/i18n';
import { LastUpdated } from 'components-ts/LastUpdated';

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Note',
  },

  proceduresTitle: {
    id: 'OrderList.procedures_title',
    defaultMessage: 'Procedures',
  },

  noItemsFound: {
    id: 'ResourceList.no_items_found',
    defaultMessage: 'No items found',
  },
  guideline: {
    id: 'RxModal.label_guideline',
    defaultMessage: 'Guideline',
  },
});

type ActionableProps = {
  patientId: string;
  visitId: string;
  orders: Array<FullProcedureOrder>;
  isActionable: true;
  className?: string;
  hideCanceled?: boolean;
};

type ReadOnlyProps = {
  orders: Array<FullProcedureOrder>;
  isActionable?: false;
  className?: string;
  hideCanceled?: boolean;
};

type ProcedureOrderListProps = ActionableProps | ReadOnlyProps;

export const ProcedureOrderList: React.FC<ProcedureOrderListProps> = (props) => {
  const { orders, className, ...rest } = props;

  const intl = useIntl();

  return (
    <div className={`w-100 ${className || ''}`}>
      <h4>
        <T id={messages.proceduresTitle.id}>{intl.formatMessage(messages.proceduresTitle)}</T>
      </h4>
      <div className="border-top py-1">
        {orders.map((order, index, allOrders) => {
          const prevItem = index > 0 ? allOrders[index - 1] : null;
          const key = `${order.id}-${index}`;

          return <Item key={key} item={order} prevItem={prevItem} {...rest} />;
        })}
      </div>
    </div>
  );
};

type ActionableItemProps = {
  patientId: string;
  visitId: string;
  item: FullProcedureOrder;
  prevItem: Nullable<FullProcedureOrder>;
  isActionable: true;
  className?: string;
  hideCanceled?: boolean;
};

type ReadOnlyItemProps = {
  item: FullProcedureOrder;
  prevItem: Nullable<FullProcedureOrder>;
  isActionable?: false;
  className?: string;
  hideCanceled?: boolean;
};

type ItemProps = ReadOnlyItemProps | ActionableItemProps;

const Item: React.FC<ItemProps> = (props) => {
  const { item, prevItem, isActionable, hideCanceled } = props;

  const intl = useIntl();
  const { date, time } = useSimplifiedDateFromSignatures(item.order.created, prevItem?.order.created);

  if (hideCanceled && item.canceled) {
    return null;
  }

  return (
    <div className="border-bottom border-light py-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex mb-2">
          <div style={{ minWidth: 96 }}>
            {date && <p className="m-0">{date}</p>}
            <p className="m-0">{time}</p>
          </div>
          <div className="px-2">
            <b style={{ textDecoration: item.canceled ? 'line-through' : 'none' }}>{item.order.text}</b>
            {item.guideline && (
              <p className="small m-0">
                <T id={messages.guideline.id} className="mr-1">
                  {intl.formatMessage(messages.guideline)}:{' '}
                </T>
                <em>
                  <DynamicTranslation text={item.guideline.name?.text ?? ''} />
                </em>
              </p>
            )}
          </div>
        </div>
        {isActionable && (
          <OrderActionMenu
            className="section-not-printable my-2"
            patientId={props.patientId}
            visitId={props.visitId}
            order={item}
            isCancellable
            isAcknowledgeable
            isCommentable
          />
        )}
      </div>
      <OrderStatus className="section-not-printable" acknowledged={!!item.acknowledged} canceled={!!item.canceled} />
      {Array.isArray(item.comments) && <OrderComments comments={item.comments} className="my-2" />}
      {item.updated && (
        <div className="d-flex justify-content-end">
          <LastUpdated signature={item.updated} />
        </div>
      )}
    </div>
  );
};
