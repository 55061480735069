import { QueryHookOptions, useQuery } from '@apollo/client';
import { PaginatedResult, Pricelist } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { GET_PRICELISTS } from 'api/request/pricing';
import { PaginatedVariables } from 'api/interfaces';

const messages = defineMessages({
  internalServerError: {
    id: 'ErrorViewer.internal_server_error',
    defaultMessage: 'An internal error has ocurred.',
  },
});

interface UsePricelistsData {
  pricelists: PaginatedResult<Pricelist>;
}

type UsePricelistsVariables = PaginatedVariables;

type UsePricelistsParams = QueryHookOptions<UsePricelistsData, UsePricelistsVariables> & UsePricelistsVariables;

export const usePricelists = (params?: UsePricelistsParams) => {
  const { q, offset, limit } = params ?? {};
  const [error, setError] = useState<Nullable<MessageDescriptor>>(null);

  const onError = (errors) => {
    const errorCode = extractFirstErrorCode(errors);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const onCompleted = () => {
    setError(null);
  };

  const { loading, data, refetch } = useQuery<UsePricelistsData, UsePricelistsVariables>(GET_PRICELISTS, {
    ...params,
    variables: { q, offset, limit },
    onCompleted,
    onError,
  });

  const getPricelist = (id?: string) => {
    return data?.pricelists?.docs.find((pricelist) => pricelist.id === id);
  };

  return {
    docs: data?.pricelists.docs ?? [],
    count: data?.pricelists.count ?? 0,
    error,
    isLoading: loading,
    getPricelist,
    refetch,
  };
};
