// legacy
export const visitTypes = [
  'Sick Visit',
  'Follow-up',
  'Child Health',
  'Antenatal Care',
  'Delivery',
  'Family Planning',
  'Physical Exam',
  'Immunization',
  'Dental',
  'Transfer',
  'Other'
]

export const COMPLAINTS_TYPES = Object.freeze({
  GLOBAL: 'global complaint',
  SITE_SPECIFIC: 'site specific complaint'
})

export const VISIT_TYPES = Object.freeze({
  GENERAL_PRACTITIONER: 'General Practitioner',
  MEDICAL_CLEARANCE: 'Medical Clearance',
  OBGYN: 'OBGYN',
  ANTENATAL_CARE: 'Antenatal Care',
  PEDIATRICIAN: 'Pediatrician',
  LACTATION_COUNSELING: 'Lactation Counseling',
  FAMILY_PLANNING: 'Family Planning',
  INTERNIST: 'Internist',
  INMUNIZATION: 'Immunization',
  DENTAL: 'Dental',
  TESTING: 'Testing',
  OTHER: 'Other',
  MIDWIFE: 'Midwife',

  SICK_VISIT: 'Sick Visit', // deprecated
  FOLLOW_UP: 'Follow-up', // deprecated
  CHIILD_HEALTH: 'Child Health', // deprecated
  DELIVERY: 'Delivery', // deprecated
  PHYSICAL_EXAM: 'Physical Exam', // deprecated
  TRANSFER: 'Transfer' // deprecated
})

const DEPRECATED_VISIT_TYPES = [
  'SICK_VISIT',
  'FOLLOW_UP',
  'CHIILD_HEALTH',
  'DELIVERY',
  'PHYSICAL_EXAM',
  'TRANSFER'
]

export const FILTERED_VISIT_TYPES = Object.entries(VISIT_TYPES)
  .filter(([key, value]) => !DEPRECATED_VISIT_TYPES.includes(key))
  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

export const FOLLOW_UP = Object.freeze({
  TOMORROW: 'Tomorrow',
  TWO_DAYS: '2-3 Days',
  FOUR_DAYS: '4-6 Days',
  WEEK: 'One Week',
  TWO_WEEKS: 'Two Weeks',
  THREE_WEEKS: 'Three Weeks',
  MONTH: 'One Month',
  TWO_MONTHS: 'Two Months',
  THREE_MONTHS: 'Three Months',
  SIX_MONTHS: 'Six Months',
  YEAR: 'One Year'
})

export const DISPOSITION = Object.freeze({
  DISCHARGE_HOME: 'Discharge home',
  OBSERVE: 'Observe',
  INTERNAL_REFERRAL: 'Internal Referral',
  EXTERNARL_REFERRAL: 'External Referral',
  TRANSFER: 'Transfer',
  ADMIT: 'Admit'
})

export const STATUS_ASSESMENT = Object.freeze({
  STABLE: 'Stable',
  WELL: 'Well',
  GUARDED: 'Guarded',
  CRITICAL: 'Critical',
  DECEASED: 'Deceased'
})

export const CLINICAL_STATUS_PRIORITY = Object.freeze({
  ROUTINE: 'Routine',
  GUARDED: 'Guarded',
  EMERGENCY: 'Emergency'
})

export const HOW_LONG = Object.freeze({
  JUST_NOW: 'Just now',
  AN_HOUR: 'An hour',
  FEW_HOURS: 'Few hours',
  SEVERAL_HOURS: 'Several hours',
  A_DAY: 'A day',
  FEW_DAYS: 'Few days',
  SEVERAL_DAYS: 'Several days',
  A_WEEK: 'A week',
  FEW_WEEKS: 'Few weeks',
  SEVERAL_WEEKS: 'Several weeks',
  A_MONTH: 'A month',
  FEW_MONTHS: 'Few months',
  SEVERAL_MONTHS: 'Several months',
  A_YEAR: 'A year',
  FEW_YEARS: 'Few years',
  SEVERAL_YEARS: 'Several years'
})

export const HISTORY_TYPES = Object.freeze({
  MANUAL_MEDICATIONS: 'manualMedications',
  MANUAL_PROBLEMS: 'manualProblems',
  ALLERGIES: 'allergies',
  FAMILY: 'family',
  DIET: 'diet',
  DEVELOPMENT: 'development',
  SOCIAL: 'social',
  SURVEYS: 'surveys'
})

/**
 * We have 5 types of orders
 * Nurse order
 * Communication between doctor and nurses
 * Able to Cancel, acknowledge & comment
 *
 * Study order
 * It implies a Study creation from a template. linked to study
 * Able to Cancel, acknowledge & comment
 *
 * Procedure order
 * It'slinked to a procedure (no extra entry is created)
 * Able to Cancel, acknowledge & comment
 *
 * Medicine order
 * To administer a medicine. rx linked with origin = 'order'
 * Able to Cancel, acknowledge & comment
 *
 * Prescription
 * To prescribe a medicine. rx linked with origin = 'prescription'
 * Only able to cancel
 */
export const ORDER_TYPES = Object.freeze({
  PRESCRIPTION: 'prescription',
  NURSE_ORDER: 'dr_nurse_order',
  STUDY_ORDER: 'study_order',
  MEDICINE_ORDER: 'medicine_order',
  PROCEDURE_ORDER: 'procedure_order'
})
