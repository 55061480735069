import React, { useContext } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { GET_PHARMACY_ITEMS } from '../../scenes/Pharmacy/requests';
import { Drug, PaginatedResult } from 'api/interfaces';
import { extractFirstErrorCode, Nullable } from 'components-ts/utils';
import { Alert, Button } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { LoadingInline } from 'components-ts/Loading';

const messages = defineMessages({
  internalServerError: {
    id: 'PharmacyItemsProvider.phamacy_items_content_could_not_be_loaded',
    defaultMessage: 'Something went wrong while getting the pharmacy items.',
  },
  reload: {
    id: 'UI.button_reload',
    defaultMessage: 'Reload',
  },
});

type Result = { pharmacyItems: PaginatedResult<Drug> };

type UsePharmacyItemVariables = {
  q?: string;
  offset?: number;
  limit?: number;
};

export type PharmacyItemsContextValue = {
  pharmacyItems: Array<Drug>;
  count: number;
  getPharmacyItem: (id: string) => Nullable<Drug>;
  getPharmacyItems: ({ name }: { name: string }) => Array<Drug>;
  isLoading: boolean;
  classes: Set<string>;
  categories: Set<string>;
  stockUnits: Set<string>;
  doseUnits: Set<string>;
  tags: Set<string>;
  refetch: () => void;
  error: Nullable<MessageDescriptor>;
  onErrorClose: () => void;
};

export const PharmacyItemsContext = React.createContext<Nullable<PharmacyItemsContextValue>>(null);

export const PharmacyItemsProvider: React.FC = (props) => {
  const [error, setError] = React.useState<Nullable<MessageDescriptor>>(null);

  const onError = (error) => {
    const errorCode = extractFirstErrorCode(error);

    switch (errorCode) {
      default:
        return setError(messages.internalServerError);
    }
  };

  const { loading, data, refetch } = useQuery<Result, UsePharmacyItemVariables>(GET_PHARMACY_ITEMS, {
    onError,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
  });

  const pharmacyItems = data?.pharmacyItems?.docs ?? [];
  const count = data?.pharmacyItems?.count ?? 0;

  const doseUnits = React.useMemo(
    () => new Set(pharmacyItems.filter((f) => f.unit).map((f) => `${f.unit}`)),
    [pharmacyItems]
  );

  const stockUnits = React.useMemo(
    () => new Set(pharmacyItems.filter((f) => f.stock_unit).map((f) => `${f.stock_unit}`)),
    [pharmacyItems]
  );

  const classes = React.useMemo(
    () => new Set(pharmacyItems.filter((f) => f.class).map((f) => `${f.class}`)),
    [pharmacyItems]
  );

  const categories = React.useMemo(
    () => new Set(pharmacyItems.filter((f) => f.category).map((f) => `${f.category}`)),
    [pharmacyItems]
  );

  const tags = React.useMemo(() => {
    return pharmacyItems.reduce((set, item) => {
      item.tags?.forEach((trTag) => {
        if (trTag.text) {
          set.add(trTag.text);
        }
      });

      return set;
    }, new Set<string>());
  }, [pharmacyItems]);

  const getPharmacyItems = React.useCallback(
    ({ name }: { name: string }) => {
      if (!name.length) return pharmacyItems;

      return pharmacyItems.filter((d) => d.name.match(new RegExp(`${name}`, 'i')));
    },
    [pharmacyItems]
  );

  const getPharmacyItem = React.useCallback(
    (id: string) => {
      if (!data || !data.pharmacyItems) return null;

      return data.pharmacyItems.docs.find((item) => item.id === id) ?? null;
    },
    [data]
  );

  const onErrorClose = () => {
    setError(null);
  };

  const value: PharmacyItemsContextValue = {
    isLoading: loading,
    count,
    pharmacyItems,
    getPharmacyItem,
    getPharmacyItems,
    classes,
    categories,
    doseUnits,
    stockUnits,
    tags,
    refetch,
    error,
    onErrorClose,
  };

  return <PharmacyItemsContext.Provider value={value}>{props.children}</PharmacyItemsContext.Provider>;
};

export const PharmacyItemsProviderErrorHandler: React.FC = (props) => {
  const intl = useIntl();

  const ctx = useContext(PharmacyItemsContext);
  validateContextValue(ctx);

  const { error, refetch, isLoading } = ctx;

  const onClick = () => {
    refetch();
  };
  return (
    <>
      {props.children}
      {error && (
        <Alert
          className={'alert-danger d-flex align-items-center w-100 position-absolute m-0 justify-content-between'}
          style={{ bottom: 50 }}
        >
          <T id={error.id}>{intl.formatMessage(error)}</T>
          <Button className={'d-flex align-items-center'} onClick={onClick}>
            <T id={messages.reload.id} className="mr-1">
              {intl.formatMessage(messages.reload)}
            </T>
            {isLoading && <LoadingInline />}
          </Button>
        </Alert>
      )}
    </>
  );
};

function validateContextValue(value: Nullable<PharmacyItemsContextValue>): asserts value is PharmacyItemsContextValue {
  if (value === null) {
    throw TypeError('Using context out of the provider');
  }
}
