import React from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { ReactSelectInternalValue, ReactSelectInternalSingleValue, SelectorProps, isMultiSelector } from '.';
import { useIntl, defineMessages } from 'react-intl';
import { countries as countriesList, getEmojiFlag } from 'countries-list';

const messages = defineMessages({
  country: {
    id: 'CountrySelector.label',
    defaultMessage: 'Country',
  },
});

export type CountrySelectorProps = SelectorProps<string> & {
  hideLabel?: boolean;
  className?: string;
};

const formatOption = (countryCode: string): ReactSelectInternalSingleValue => {
  const country = countriesList[countryCode];

  return country
    ? {
        value: countryCode,
        label: `${getEmojiFlag(countryCode)}  ${country.name}`,
      }
    : {
        value: countryCode,
        label: countryCode,
      };
};

export const CountrySelector: React.FC<CountrySelectorProps> = (props) => {
  const { value, isClearable, isDisabled, className, hideLabel = false } = props;

  const intl = useIntl();

  const options = Object.keys(countriesList).map(formatOption);

  /**
   * Parse value prop to internal react select state by searching into the options
   * when they're loaded
   */
  const parseValue = (value: string | Array<string>): any => {
    if (options === null) {
      return {};
    }

    if (isMultiSelector(props)) {
      if (!Array.isArray(value)) {
        return {};
      }

      const formattedValues = value.reduce((prev: Array<ReactSelectInternalSingleValue>, country: string) => {
        if (country) {
          return [...prev, formatOption(country)];
        }

        return prev;
      }, []);

      return formattedValues;
    } else {
      if (value) {
        return formatOption(value as string);
      }

      return {};
    }
  };

  const _onChange = React.useCallback(
    (v: ReactSelectInternalValue): void => {
      if (isMultiSelector(props)) {
        if (!Array.isArray(v)) {
          return props.onChange([]);
        }

        const selectedValues = v as Array<ReactSelectInternalSingleValue>;
        const newValues = selectedValues.map((item) => item.value);

        props.onChange(newValues);
      } else {
        const selectedValue = v as ReactSelectInternalSingleValue;

        props.onChange(selectedValue.value);
      }
    },
    [props]
  );

  if (hideLabel) {
    return (
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    );
  }

  return (
    <FormGroup>
      <Label>
        <T id={messages.country.id}>{intl.formatMessage(messages.country)}</T>
      </Label>
      <Select
        className={className}
        value={parseValue(value)}
        onChange={_onChange}
        options={options}
        isMulti={isMultiSelector(props)}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isSearchable
        blurInputOnSelect
      />
    </FormGroup>
  );
};
