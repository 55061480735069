import React, { useEffect, useState } from 'react';
import * as router from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarNav } from '@coreui/react';
import { DropdownToggle, NavItem, UncontrolledDropdown } from 'reactstrap';
import {
  FaCalendarAlt as CalendarAlt,
  FaExclamation as SetupIcon,
  FaCheck as CheckIcon,
  FaPrescriptionBottle as DrugsIcon,
  FaFilePrescription as PrescriptionIcon,
  FaFileAlt as ReportsIcon,
  FaFlask as FlaskIcon,
  FaHospital as HospitalIcon,
  FaHeartbeat as HeartbeatIcon,
  FaHistory as HistoryIcon,
  FaLanguage as LenguageIcon,
  FaList as ListIcon,
  FaPills as QuickpicksIcon,
  FaRegClock as ClockIcon,
  FaTags as PricesIcon,
  FaFileInvoiceDollar as BillingIcon,
  FaSearch as SearchIcon,
  FaStethoscope as StethoscopeIcon,
  FaTachometerAlt as TachometerIcon,
  FaUsers as UsersIcon,
  FaUserClock as UserClockIcon,
  FaUserPlus as UserPlusIcon,
  FaCashRegister as ExpensesIcon,
  FaMoneyBillAlt as OutpatientReportIcon,
  FaDisease as DiseasesIcon,
  FaMoneyCheck as PaymentTypesIcon,
  FaCashRegister as SalesIcon,
  FaRegStickyNote as NotesIcon,
} from 'react-icons/fa';
import { useSitemapAccess } from './useSitemap';
import { AppScenes, sitemap } from './sitemap';
import { AccountStatus, useSession } from 'components-ts/Auth';
import { useSystems } from 'components-ts/HealthSystems';

const messages = defineMessages({
  setup: {
    id: 'Nav.setup',
    defaultMessage: 'Continue Setup',
  },
  patientSearch: {
    id: 'Nav.patient_search',
    defaultMessage: 'Patient Search',
  },
  waitingPatients: {
    id: 'Nav.waiting_patients',
    defaultMessage: 'Waiting Patients',
  },
  pastVisits: {
    id: 'Nav.past_visits',
    defaultMessage: 'Past Visits',
  },
  registerPatient: {
    id: 'Nav.register_patient',
    defaultMessage: 'Register Patient',
  },
  sales: {
    id: 'Nav.backoffice_sales',
    defaultMessage: 'Sales',
  },
  wdUsers: {
    id: 'Nav.backoffice_users',
    defaultMessage: 'WD Users',
  },
  billing: {
    id: 'Nav.billing',
    defaultMessage: 'Billing',
  },
  scheduledVisit: {
    id: 'Nav.scheduled_visit',
    defaultMessage: 'Scheduler',
  },
  calendar: {
    id: 'Nav.calendar',
    defaultMessage: 'Calendar',
  },
  pharmacy: {
    id: 'Nav.pharmacy',
    defaultMessage: 'Pharmacy',
  },
  prescriptions: {
    id: 'Nav.prescriptions',
    defaultMessage: 'Prescriptions',
  },
  pharmacyStock: {
    id: 'Nav.pharmacy_stock',
    defaultMessage: 'Stock',
  },
  pharmacyItems: {
    id: 'Nav.pharmacy_items',
    defaultMessage: 'Drugs',
  },
  studies: {
    id: 'Nav.studies',
    defaultMessage: 'Studies',
  },
  metrics: {
    id: 'Nav.metrics',
    defaultMessage: 'Metrics',
  },
  reports: {
    id: 'Nav.reports',
    defaultMessage: 'Reports',
  },
  outpatientsRevenue: {
    id: 'Nav.outpatients_revenue',
    defaultMessage: 'Outpatients revenue',
  },
  diseases: {
    id: 'Nav.Diseases',
    defaultMessage: 'Diseases',
  },
  expenses: {
    id: 'Nav.expenses_report_sidebar_menu',
    defaultMessage: 'Expenses',
  },
  commodities: {
    id: 'Nav.commodities_report_sidebar_menu',
    defaultMessage: 'Commodities',
  },
  quickpicks: {
    id: 'Nav.quickpicks',
    defaultMessage: 'Quick Picks',
  },
  admin: {
    id: 'Nav.admin',
    defaultMessage: 'Administration',
  },
  manageStaff: {
    id: 'Nav.staff_admin',
    defaultMessage: 'Manage Staff',
  },
  translation: {
    id: 'Nav.translation',
    defaultMessage: 'Translation',
  },
  healthSystems: {
    id: 'Nav.health_systems',
    defaultMessage: 'Health Systems',
  },
  healthSystemsEditor: {
    id: 'Nav.health_systems',
    defaultMessage: 'Health Systems',
  },
  prices: {
    id: 'Nav.prices',
    defaultMessage: 'Prices',
  },
  paymentTypes: {
    id: 'Nav.paymentTypes',
    defaultMessage: 'Payment Types',
  },
  priceEditor: {
    id: 'Nav.priceeditor',
    defaultMessage: 'Price Editor',
  },
  vitalsReference: {
    id: 'Nav.vitals_reference',
    defaultMessage: 'Vitals Reference',
  },
  listEditor: {
    id: 'Nav.list_editor',
    defaultMessage: 'List Editor',
  },
  historyEditor: {
    id: 'Nav.history_editor',
    defaultMessage: 'History Editor',
  },
  examEditor: {
    id: 'Nav.exam_editor',
    defaultMessage: 'Exam Editor',
  },
  checklistEditor: {
    id: 'Nav.checklist_editor',
    defaultMessage: 'Checklists Editor',
  },
  checklistReference: {
    id: 'Nav.checklist_reference',
    defaultMessage: 'Checklists Reference',
  },
  codeEditor: {
    id: 'Nav.code_editor',
    defaultMessage: 'Code Editor',
  },
  sharedPharmacyLibrary: {
    id: 'Nav.pharmacy_library',
    defaultMessage: 'Pharmacy Library',
  },
  apiTest: {
    id: 'Nav.api_test',
    defaultMessage: 'API Test',
  },
  uiReference: {
    id: 'Nav.ui_reference',
    defaultMessage: 'UI Reference',
  },
  referenceList: {
    id: 'Nav.ui_reference_list',
    defaultMessage: 'List',
  },
  referenceSearcher: {
    id: 'Nav.ui_reference_searcher',
    defaultMessage: 'Searcher',
  },
  lab: {
    id: 'Nav.lab',
    defaultMessage: 'Lab',
  },
  createStudy: {
    id: 'Nav.create_study_template',
    defaultMessage: 'New Template',
  },
  studyTemplates: {
    id: 'Nav.study_template_list',
    defaultMessage: 'Study templates',
  },
  study: {
    id: 'Nav.study_list',
    defaultMessage: 'Studies',
  },
  procedures: {
    id: 'Nav.Procedures',
    defaultMessage: 'Procedures',
  },
  surveys: {
    id: 'Nav.surveys',
    defaultMessage: 'Surveys',
  },
  notes: {
    id: 'Nav.notes',
    defaultMessage: 'Notes',
  },
  wdAdmin: {
    id: 'Nav.wd_admin',
    defaultMessage: 'WD Admin',
  },
});

const { Link, useLocation } = router;

export const Sidebar: React.VFC = () => {
  const { getAccountStatus } = useSession();
  const accountStatus = getAccountStatus();

  if (
    accountStatus === AccountStatus.PAYMENT_REQUIRED ||
    accountStatus === AccountStatus.REACTIVATION_REQUIRED ||
    accountStatus === AccountStatus.SETUP_PENDING ||
    accountStatus === AccountStatus.DEFAULT_MISSING
  ) {
    return <NotReadySidebar />;
  }

  return <FullSidebar />;
};

const GlobalAdminMenu: React.VFC = () => {
  const intl = useIntl();
  const { canUserAccessToScene } = useSitemapAccess();

  return (
    <>
      <NavItem className="nav-title">
        <T id={messages.wdAdmin.id}>{intl.formatMessage(messages.wdAdmin)}</T>
      </NavItem>

      {canUserAccessToScene(AppScenes.BACKOFFICE) && (
        <NavItem>
          <Link className="nav-link" to={sitemap.backoffice.routes.sales.path}>
            <SalesIcon className="nav-icon" />
            <T id={messages.sales.id}>{intl.formatMessage(messages.sales)}</T>
          </Link>
        </NavItem>
      )}
      {canUserAccessToScene(AppScenes.BACKOFFICE) && (
        <NavItem>
          <Link className="nav-link" to={sitemap.backoffice.routes.users.path}>
            <UsersIcon className="nav-icon" />
            <T id={messages.wdUsers.id}>{intl.formatMessage(messages.wdUsers)}</T>
          </Link>
        </NavItem>
      )}

      {canUserAccessToScene(AppScenes.PROCEDURES) && (
        <NavItem>
          <Link className="nav-link" to={sitemap.procedures.routes.templates.path}>
            <ListIcon className="nav-icon" />
            <T id={messages.procedures.id}>{intl.formatMessage(messages.procedures)}</T>
          </Link>
        </NavItem>
      )}

      {canUserAccessToScene(AppScenes.LAB) && (
        <li className="nav-item">
          <Link className="nav-link" to={sitemap.lab.routes.templates.path}>
            <ListIcon className="nav-icon" />
            <T id={messages.studyTemplates.id}>{intl.formatMessage(messages.studyTemplates)}</T>
          </Link>
        </li>
      )}

      {canUserAccessToScene(AppScenes.CONTENT_LIBRARIES) && (
        <li className="nav-item">
          <Link className="nav-link" to={sitemap.contentLibraries.routes.pharmacyItems.path}>
            <DrugsIcon className="nav-icon" />
            <T id={messages.sharedPharmacyLibrary.id}>{intl.formatMessage(messages.sharedPharmacyLibrary)}</T>
          </Link>
        </li>
      )}

      {(canUserAccessToScene(AppScenes.TRANSLATION) || canUserAccessToScene(AppScenes.MEDICAL_REFERENCE)) && (
        <NavItem>
          <a className="nav-link" href={sitemap.medicalReference.routes.vitalsReference.path}>
            <HeartbeatIcon className="nav-icon" />
            <T id={messages.vitalsReference.id}>{intl.formatMessage(messages.vitalsReference)}</T>
          </a>
        </NavItem>
      )}
      {canUserAccessToScene(AppScenes.MEDICAL_REFERENCE) && (
        <NavItem>
          <Link className="nav-link" to={sitemap.medicalReference.routes.lists.path}>
            <ListIcon className="nav-icon" />
            <T id={messages.listEditor.id}>{intl.formatMessage(messages.listEditor)}</T>
          </Link>
        </NavItem>
      )}
      {canUserAccessToScene(AppScenes.MEDICAL_REFERENCE) && (
        <NavItem>
          <Link
            className="nav-link"
            to={sitemap.medicalReference.routes.lists.routes.listEditor.buildPath('58eaf4b400610c2362dad5da')}
          >
            <HistoryIcon className="nav-icon" />
            <T id={messages.historyEditor.id}>{intl.formatMessage(messages.historyEditor)}</T>
          </Link>
        </NavItem>
      )}
      {canUserAccessToScene(AppScenes.MEDICAL_REFERENCE) && (
        <NavItem>
          <Link className="nav-link" to={sitemap.medicalReference.routes.exams.path}>
            <StethoscopeIcon className="nav-icon" />
            <T id={messages.examEditor.id}>{intl.formatMessage(messages.examEditor)}</T>
          </Link>
        </NavItem>
      )}
      {canUserAccessToScene(AppScenes.MEDICAL_REFERENCE) && (
        <NavItem>
          <Link className="nav-link" to={sitemap.medicalReference.routes.checklists.path}>
            <CheckIcon className="nav-icon" />
            <T id={messages.checklistEditor.id}>{intl.formatMessage(messages.checklistEditor)}</T>
          </Link>
        </NavItem>
      )}
      {canUserAccessToScene(AppScenes.NOTES) && (
        <NavItem>
          <Link className="nav-link" to={sitemap.notes.routes.list.path}>
            <NotesIcon className="nav-icon" />
            <T id={messages.notes.id}>{intl.formatMessage(messages.notes)}</T>
          </Link>
        </NavItem>
      )}
      {/* {canUserAccessToScene(AppScenes.MEDICAL_REFERENCE) && (
        <NavItem>
          <a className="nav-link" href={sitemap.medicalReference.routes.codes.path}>
            <BookIcon className="nav-icon" />
            <T id={messages.codeEditor.id}>{intl.formatMessage(messages.codeEditor)}</T>
          </a>
        </NavItem>
      )} */}
    </>
  );
};

const FullSidebar: React.VFC = () => {
  const intl = useIntl();

  const location = useLocation();
  const { canUserAccessToRoute, canUserAccessToScene } = useSitemapAccess();
  const { userHasRole } = useSession();
  const { getMyLocation, getMySystem } = useSystems();

  const mySystem = getMySystem();
  const myLocation = getMyLocation();

  // to manage the styles of the dropdowns
  const [menuState, setMenuState] = useState({
    prices: false,
    uiReference: false,
    reports: false,
  });

  useEffect(() => {
    const showClass = Array.from(document.body.classList).find((c) => c.includes('show'));
    if (showClass) {
      document.body.classList.remove(showClass);
    }
  }, [location.pathname]);

  return (
    <AppSidebar fixed>
      <AppSidebarHeader />
      <AppSidebarForm />
      <AppSidebarNav router={router} nav>
        <NavItem className="nav-title">{myLocation.name}</NavItem>
        {canUserAccessToRoute(AppScenes.PATIENTS, 'allPatients') && (
          <NavItem>
            <Link className="nav-link" to={sitemap.patients.routes.allPatients.path}>
              <SearchIcon className="nav-icon" />
              <T id={messages.patientSearch.id}>{intl.formatMessage(messages.patientSearch)}</T>
            </Link>
          </NavItem>
        )}
        {canUserAccessToRoute(AppScenes.PATIENTS, 'waitingPatients') && (
          <NavItem>
            <Link className="nav-link" to={sitemap.patients.routes.waitingPatients.path}>
              <UserClockIcon className="nav-icon" />
              <T id={messages.waitingPatients.id}>{intl.formatMessage(messages.waitingPatients)}</T>
            </Link>
          </NavItem>
        )}
        {canUserAccessToRoute(AppScenes.VISITS, 'pastVisits') && (
          <NavItem>
            <Link className="nav-link" to={sitemap.visits.routes.pastVisits.path}>
              <ClockIcon className="nav-icon" />
              <T id={messages.pastVisits.id}>{intl.formatMessage(messages.pastVisits)}</T>
            </Link>
          </NavItem>
        )}
        {canUserAccessToRoute(AppScenes.PATIENTS, 'newPatient') && (
          <NavItem>
            <Link className="nav-link" to={sitemap.patients.routes.newPatient.path}>
              <UserPlusIcon className="nav-icon" />
              <T id={messages.registerPatient.id}>{intl.formatMessage(messages.registerPatient)}</T>
            </Link>
          </NavItem>
        )}

        {canUserAccessToRoute(AppScenes.BILLING, 'list') && (
          <li className="nav-item">
            <Link className="nav-link" to={sitemap.billing.routes.list.path}>
              <BillingIcon className="nav-icon" />
              <T id={messages.billing.id}>{intl.formatMessage(messages.billing)}</T>
            </Link>
          </li>
        )}
        {canUserAccessToRoute(AppScenes.SCHEDULER, 'calendar') && (
          <li className="nav-item">
            <Link className="nav-link" to={sitemap.scheduler.routes.calendar.path}>
              <CalendarAlt className="nav-icon" />
              <T id={messages.scheduledVisit.id}>{intl.formatMessage(messages.scheduledVisit)}</T>
            </Link>
          </li>
        )}

        {canUserAccessToRoute(AppScenes.PHARMACY, 'prescriptions') && (
          <li className="nav-item">
            <Link className="nav-link" to={sitemap.pharmacy.routes.prescriptions.path}>
              <PrescriptionIcon className="nav-icon" />
              <T id={messages.prescriptions.id}>{intl.formatMessage(messages.prescriptions)}</T>
            </Link>
          </li>
        )}

        {canUserAccessToRoute(AppScenes.LAB, 'studies') && (
          <li className="nav-item">
            <Link className="nav-link" to={sitemap.lab.routes.studies.path}>
              <FlaskIcon className="nav-icon" />
              <T id={messages.studies.id}>{intl.formatMessage(messages.studies)}</T>
            </Link>
          </li>
        )}

        {canUserAccessToScene(AppScenes.METRICS) && (
          <NavItem>
            <Link className="nav-link" to={sitemap.metrics.routes.systemMetrics.path}>
              <TachometerIcon className="nav-icon" />
              <T id={messages.metrics.id}>{intl.formatMessage(messages.metrics)}</T>
            </Link>
          </NavItem>
        )}

        <NavItem className="divider" />
        <NavItem className="nav-title">
          <T id={messages.admin.id}>{intl.formatMessage(messages.admin)}</T>
        </NavItem>
        {canUserAccessToRoute(AppScenes.HEALTH_SYSTEMS, 'systemStaff') && (
          <NavItem>
            <Link className="nav-link" to={sitemap.healthSystems.routes.systemStaff.buildPath(mySystem.id)}>
              <UsersIcon className="nav-icon" />
              <T id={messages.manageStaff.id}>{intl.formatMessage(messages.manageStaff)}</T>
            </Link>
          </NavItem>
        )}

        <NavItem>
          <Link className="nav-link" to={sitemap.healthSystems.routes.systems.path}>
            <HospitalIcon className="nav-icon" />
            <T id={messages.healthSystemsEditor.id}>{intl.formatMessage(messages.healthSystemsEditor)}</T>
          </Link>
        </NavItem>

        {canUserAccessToRoute(AppScenes.PHARMACY, 'items') && (
          <li className="nav-item">
            <Link className="nav-link" to={sitemap.pharmacy.routes.items.path}>
              <DrugsIcon className="nav-icon" />
              <T id={messages.pharmacyItems.id}>{intl.formatMessage(messages.pharmacyItems)}</T>
            </Link>
          </li>
        )}
        {canUserAccessToScene(AppScenes.QUICKPICKS) && (
          <li className="nav-item">
            <Link className="nav-link" to={sitemap.quickpicks.routes.list.path}>
              <QuickpicksIcon className="nav-icon" />
              <T id={messages.quickpicks.id}>{intl.formatMessage(messages.quickpicks)}</T>
            </Link>
          </li>
        )}
        {canUserAccessToScene(AppScenes.PRICING) && (
          <UncontrolledDropdown className={`nav-item nav-dropdown ${menuState.prices && 'open'}`}>
            <DropdownToggle
              tag="a"
              onClick={() => setMenuState((prev) => ({ ...prev, prices: !prev.prices }))}
              className="nav-link nav-dropdown-toggle"
            >
              <PricesIcon className="nav-icon" />
              <T id={messages.prices.id}>{intl.formatMessage(messages.prices)}</T>
            </DropdownToggle>
            <ul className="nav-dropdown-items">
              <li className="nav-item">
                <Link className="nav-link" to={sitemap.pricing.routes.pricelists.path}>
                  <PaymentTypesIcon className="nav-icon" />
                  <T id={messages.paymentTypes.id}>{intl.formatMessage(messages.paymentTypes)}</T>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={sitemap.pricing.routes.priceEditor.path}>
                  <PricesIcon className="nav-icon" />
                  <T id={messages.priceEditor.id}>{intl.formatMessage(messages.priceEditor)}</T>
                </Link>
              </li>
            </ul>
          </UncontrolledDropdown>
        )}
        {canUserAccessToScene(AppScenes.REPORTS) && (
          <UncontrolledDropdown className={`nav-item nav-dropdown ${menuState.reports && 'open'}`}>
            <DropdownToggle
              tag="a"
              onClick={() => setMenuState((prev) => ({ ...prev, reports: !prev.reports }))}
              className="nav-link nav-dropdown-toggle"
            >
              <ReportsIcon className="nav-icon" />
              <T id={messages.reports.id}>{intl.formatMessage(messages.reports)}</T>
            </DropdownToggle>
            <ul className="nav-dropdown-items">
              {canUserAccessToRoute(AppScenes.REPORTS, 'outpatientRevenue') && (
                <li className="nav-item">
                  <Link className="nav-link" to={sitemap.reports.routes.outpatientRevenue.path}>
                    <OutpatientReportIcon className="nav-icon" />
                    <T id={messages.outpatientsRevenue.id}>{intl.formatMessage(messages.outpatientsRevenue)}</T>
                  </Link>
                </li>
              )}
              {canUserAccessToRoute(AppScenes.REPORTS, 'diseases') && (
                <li className="nav-item">
                  <Link className="nav-link" to={sitemap.reports.routes.diseases.path}>
                    <DiseasesIcon className="nav-icon" />
                    <T id={messages.diseases.id}>{intl.formatMessage(messages.diseases)}</T>
                  </Link>
                </li>
              )}

              {canUserAccessToRoute(AppScenes.REPORTS, 'pastVisits') && (
                <li className="nav-item">
                  <Link className="nav-link" to={sitemap.reports.routes.pastVisits.path}>
                    <ClockIcon className="nav-icon" />
                    <T id={messages.pastVisits.id}>{intl.formatMessage(messages.pastVisits)}</T>
                  </Link>
                </li>
              )}

              {canUserAccessToRoute(AppScenes.REPORTS, 'expenses') && (
                <li className="nav-item">
                  <Link className="nav-link" to={sitemap.reports.routes.expenses.path}>
                    <ExpensesIcon className="nav-icon" />
                    <T id={messages.expenses.id}>{intl.formatMessage(messages.expenses)}</T>
                  </Link>
                </li>
              )}
              {canUserAccessToRoute(AppScenes.REPORTS, 'commodities') && (
                <li className="nav-item">
                  <Link className="nav-link" to={sitemap.reports.routes.commodities.path}>
                    <ExpensesIcon className="nav-icon" />
                    <T id={messages.commodities.id}>{intl.formatMessage(messages.commodities)}</T>
                  </Link>
                </li>
              )}
            </ul>
          </UncontrolledDropdown>
        )}
        <NavItem>
          <Link className="nav-link" to={sitemap.medicalReference.routes.checklists.routes.reference.path}>
            <CheckIcon className="nav-icon" />
            <T id={messages.checklistReference.id}>{intl.formatMessage(messages.checklistReference)}</T>
          </Link>
        </NavItem>
        {canUserAccessToScene(AppScenes.TRANSLATION) && (
          <NavItem>
            <Link className="nav-link" to={sitemap.translation.path}>
              <LenguageIcon className="nav-icon" />
              <T id={messages.translation.id}>{intl.formatMessage(messages.translation)}</T>
            </Link>
          </NavItem>
        )}
        {userHasRole('Admin') && <GlobalAdminMenu />}
      </AppSidebarNav>
      <AppSidebarFooter />
    </AppSidebar>
  );
};

const NotReadySidebar: React.VFC = () => {
  const intl = useIntl();
  const location = useLocation();

  useEffect(() => {
    const showClass = Array.from(document.body.classList).find((c) => c.includes('show'));
    if (showClass) {
      document.body.classList.remove(showClass);
    }
  }, [location.pathname]);

  return (
    <AppSidebar fixed>
      <AppSidebarHeader />
      <AppSidebarForm />
      <AppSidebarNav router={router} nav>
        <NavItem>
          <Link className="nav-link" to={sitemap.setup.routes.checkout.path}>
            <SetupIcon className="nav-icon" />
            <T id={messages.setup.id}>{intl.formatMessage(messages.setup)}</T>
          </Link>
        </NavItem>
      </AppSidebarNav>
      <AppSidebarFooter />
    </AppSidebar>
  );
};
