import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  fromPromise,
  ServerError,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { captureException } from '@sentry/browser';
import { AccountStatus, useSession } from 'components-ts/Auth';
import { getAuthUri, getSetupUri, getUnAuthUri } from 'api/utils';

/**
 *  This is not a react context. It's an abstraction of the apollo provider
 *
 */
const MAX_REFRESH_TOKEN_ATTEMPT = 3;
const useHttpLink = (): HttpLink => {
  const { getToken, getUserLocation, getUserSystem, isLoggedIn, getAccountStatus } = useSession();

  if (isLoggedIn()) {
    const token = getToken();

    const accountStatus = getAccountStatus();

    switch (accountStatus) {
      case AccountStatus.FULL_ACCESS:
      case AccountStatus.READ_ONLY_ACCESS: {
        const wdLocation = getUserLocation();
        const wdSystem = getUserSystem();

        return new HttpLink({
          uri: getAuthUri(),
          credentials: 'same-origin',
          fetchOptions: {
            timeout: 60000,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'WD-System': wdSystem.id,
            'WD-Location': wdLocation.id,
          },
        });
      }

      case AccountStatus.PAYMENT_REQUIRED:
      case AccountStatus.SETUP_PENDING:
      case AccountStatus.REACTIVATION_REQUIRED:
      case AccountStatus.DEFAULT_MISSING:
      default: {
        return new HttpLink({
          uri: getSetupUri(),
          credentials: 'same-origin',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
  }

  const unauthURI = getUnAuthUri();
  return new HttpLink({
    uri: unauthURI,
    credentials: 'same-origin',
  });
};

const useErrorLink = (): ApolloLink => {
  const { isLoggedIn, refresh, getToken } = useSession();

  const ref = React.useRef({ isLoading: false, refreshTokenAttempt: 0 });

  const refreshToken = async () => {
    ref.current.isLoading = true;
    await refresh();
    const newToken = getToken();

    ref.current.isLoading = false;

    return newToken;
  };

  /**
   * Translate it
   */
  const onAPIDisconnect = () => {
    console.warn('API Disconnected');
  };

  return onError((errorResponse: ErrorResponse) => {
    const { graphQLErrors, networkError, forward, operation } = errorResponse;

    if (graphQLErrors) {
      captureException(graphQLErrors);
    }

    if (networkError !== null) {
      const isUnauthenticatedError = (networkError as ServerError)?.statusCode === 401;
      const isLog = isLoggedIn();

      if (isUnauthenticatedError && isLog) {
        if (ref.current.isLoading) {
          return;
        }

        if (ref.current.refreshTokenAttempt < MAX_REFRESH_TOKEN_ATTEMPT) {
          ref.current.refreshTokenAttempt += 1;

          return fromPromise(refreshToken()).flatMap((newToken) => {
            const oldHeaders = operation.getContext().headers;

            operation.setContext((prev) => ({
              ...prev,
              headers: {
                ...oldHeaders,
                Authorization: `Bearer ${newToken}`,
              },
            }));
            return forward(operation);
          });
        } else {
          onAPIDisconnect();
        }
      }
    }
  });
};

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: string) => (key === '__typename' ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

function useApolloClient() {
  const httpLink = useHttpLink();
  const errorLink = useErrorLink();

  // clean __typename from the mutations

  return new ApolloClient({
    link: from([cleanTypeName, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
}
const CustomApolloProvider = ({ children }) => {
  const apolloClient = useApolloClient();

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { CustomApolloProvider };
