import React from 'react';
import { FaMapMarkerAlt as LocationIcon } from 'react-icons/fa';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useIntl, defineMessages } from 'react-intl';
import { countries as countriesList } from 'countries-list'; // countries and timezones
import { FullAddress } from 'api/interfaces';
import { useMediaQuery } from 'hooks';

// static translations
const messages = defineMessages({
  address: {
    id: 'Address.address_label',
    defaultMessage: 'Address',
  },
});

type AddressProps = {
  address: FullAddress;
  withIcon?: boolean;
  withLabel?: boolean;
  inline?: boolean;
  className?: string;
};

export const Address: React.FC<AddressProps> = (props) => {
  // props
  const { address, withIcon, withLabel, inline, className = '' } = props;

  const intl = useIntl();

  const { formatMessage } = intl;

  const { line_1: l1, line_2: l2, line_3: l3, locality, postal_code: postalCode, province, country } = address;

  // If country is in a code format lookup for complete name

  const countryName = country && !!countriesList[country] ? countriesList[country].name : country;

  const isMobile = useMediaQuery('(max-width: 576px)');

  return (
    <div className={`d-flex ${className}`}>
      {withIcon && <LocationIcon style={{ margin: '.2em 0' }} className="text-muted mr-2" />}
      {withLabel && (
        <div className="mr-2">
          <T id={messages.address.id}>{formatMessage(messages.address)}:</T>
        </div>
      )}
      <div className={`${inline ? 'd-flex' : ''} ${isMobile ? 'd-flex flex-column' : ''}`}>
        {(l1 || l2) && (
          <div>
            {l1 && <span>{l1}</span>}
            {l1 && l2 && <Separator />}
            {l2 && <span>{l2}</span>}
            {inline && <Separator />}
          </div>
        )}

        {(l3 || locality || postalCode) && (
          <div>
            {l3 && <span>{l3}</span>}
            {l3 && locality && <Separator />}
            {locality && <span>{locality}</span>}
            {locality && postalCode && <Separator />}
            {postalCode && <span>{postalCode}</span>}
            {inline && <Separator />}
          </div>
        )}

        {(province || countryName) && (
          <div>
            {province && <span>{province}</span>}
            {province && countryName && <Separator />}
            {countryName && <span>{countryName}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

const Separator: React.VFC = () => {
  return <span className="mr-1">{','}</span>;
};
