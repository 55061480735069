import React from 'react';
import { Drug } from 'api/interfaces';
import { FormGroup, FormText, Input, Label, Row, Col } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import { DoseUnitSelector, StockUnitSelector, PharmacyItemClassSelector, PharmacyItemCategorySelector } from '.';
import { getDrugInfo } from 'wd-common/src/logic/pharmacy/utils';
import { Nullable } from 'components-ts/utils';
import { ErrorViewer } from 'components-ts/ErrorViewer';
import { DrugTagSelector } from 'components-ts/Selectors/DrugTagsSelector';

const messages = defineMessages({
  label_name: {
    id: 'PharmacyItem.label_name',
    defaultMessage: 'Name of Item',
  },
  label_dosing_unit: { id: 'PharmacyItem.label_dosing_unit', defaultMessage: 'Dosing Unit' },
  label_stock_unit: { id: 'PharmacyItem.label_stock_unit', defaultMessage: 'Stock Unit' },
  label_minimum_units: {
    id: 'PharmacyItem.label_minimum_units_to_dispense',
    defaultMessage: 'Minimum Units to Dispense',
  },
  label_wholesale: { id: 'PharmacyItem.label_wholesale_price', defaultMessage: 'Wholesale Price' },
  label_retail: { id: 'PharmacyItem.retail_prices', defaultMessage: 'Retail Prices' },
  label_category: { id: 'PharmacyItem.label_category', defaultMessage: 'Category' },
  label_recommended_dosing: { id: 'PharmacyItem.label_recommended_dosing', defaultMessage: 'Recommended Dosing' },
  label_interactions: { id: 'PharmacyItem.label_interactions', defaultMessage: 'Interactions' },
  label_comments: { id: 'PharmacyItem.label_comments', defaultMessage: 'Comments' },
  label_class: { id: 'PharmacyItem.label_class', defaultMessage: 'Class' },
  label_edit_prices: { id: 'PharmacyItem.label_edit_prices', defaultMessage: 'Edit Prices' },
  help_name: {
    id: 'PharmacyItem.help_name',
    defaultMessage:
      'The name of the item. If a drug, enter formulation and unit dose, e.g. Ibuprophen 200mg. The system will calculate the concentration:',
  },
  help_minimum_units: {
    id: 'PharmacyItem.help_minimum_units_to_dispense',
    defaultMessage:
      'Use this if you have a minimum container size, for example, if a tablet comes in a sealed bottle of 30, you can put 30 here.',
  },
  editButtonLabel: {
    id: 'UI.edit',
    defaultMessage: 'Edit',
  },
});

type ChildrenProps = {
  pristine: boolean;
};

type PharmacyItemFormProps = {
  onSubmit: (values) => void;
  validate: (values) => any;
  error: Nullable<MessageDescriptor>;
  classes: Set<string>;
  categories: Set<string>;
  stockUnits: Set<string>;
  doseUnits: Set<string>;
  pharmacyItem?: Drug;
  children?: React.ReactNode | ((childrenProps: ChildrenProps) => React.ReactNode);
  isLoading?: boolean;
  disabled?: boolean;
  showTagSelector?: boolean;
};

export const PharmacyItemForm: React.FC<PharmacyItemFormProps> = (props) => {
  const {
    pharmacyItem,
    onSubmit,
    error,
    isLoading,
    validate,
    disabled,
    classes,
    categories,
    stockUnits,
    doseUnits,
    showTagSelector = false,
  } = props;

  const intl = useIntl();

  const itemIsUsed = !!pharmacyItem?.stockInfo?.stock;

  const formInitialValues = {
    name: pharmacyItem?.name,
    unit: pharmacyItem?.unit,
    stock_unit: pharmacyItem?.stock_unit,
    minimum_units_to_dispense: pharmacyItem?.minimum_units_to_dispense,
    class: pharmacyItem?.class,
    category: pharmacyItem?.category,
    recommended_dosing: pharmacyItem?.recommended_dosing,
    interactions: pharmacyItem?.interactions,
    comments: pharmacyItem?.comments,
    tags: pharmacyItem?.tags?.map((t) => t.text) ?? undefined,
  };

  return (
    <div className="d-flex">
      <Form
        initialValues={formInitialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, pristine, values }) => {
          const { simpleName } = getDrugInfo({ name: values.name ?? '' });

          return (
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Row>
                  <Field name="name">
                    {({ input, meta }) => (
                      <Col xs={12} md={4}>
                        <Label for="name">{intl.formatMessage(messages.label_name)}</Label>
                        <Input {...input} autoComplete="off" disabled={disabled} />
                        {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                        <FormText className="text-muted">
                          {intl.formatMessage(messages.help_name)}
                          <br />
                          {simpleName}
                        </FormText>
                      </Col>
                    )}
                  </Field>
                  <Field name="unit">
                    {({ input, meta }) => (
                      <Col xs={12} md={4}>
                        <Label for="unit">{intl.formatMessage(messages.label_dosing_unit)}</Label>
                        <DoseUnitSelector
                          {...input}
                          doseUnits={doseUnits}
                          loading={isLoading}
                          isDisabled={disabled || itemIsUsed}
                        />
                        {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                      </Col>
                    )}
                  </Field>
                  <Field name="stock_unit">
                    {({ input, meta }) => (
                      <Col xs={12} md={4}>
                        <Label for="stock_unit">{intl.formatMessage(messages.label_stock_unit)}</Label>
                        <StockUnitSelector stockUnits={stockUnits} isDisabled={disabled || itemIsUsed} {...input} />
                        {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                      </Col>
                    )}
                  </Field>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Field name="minimum_units_to_dispense" type="number" parse={Number}>
                    {({ input, meta }) => (
                      <Col xs={12} md={4}>
                        <Label for="minimum_units_to_dispense">
                          {intl.formatMessage(messages.label_minimum_units)}
                        </Label>
                        <Input disabled={disabled} min={0} step={0.1} autoComplete="off" {...input} />
                        {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                        <FormText className="text-muted">{intl.formatMessage(messages.help_minimum_units)}</FormText>
                      </Col>
                    )}
                  </Field>
                  <Field name="class">
                    {({ input, meta }) => (
                      <Col xs={12} md={4}>
                        <Label for="class">{intl.formatMessage(messages.label_class)}</Label>
                        <PharmacyItemClassSelector classes={classes} isDisabled={disabled} {...input} />
                        {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                      </Col>
                    )}
                  </Field>
                  <Field name="category">
                    {({ input, meta }) => (
                      <Col xs={12} md={4}>
                        <Label for="category">{intl.formatMessage(messages.label_category)}</Label>
                        <PharmacyItemCategorySelector categories={categories} isDisabled={disabled} {...input} />
                        {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                      </Col>
                    )}
                  </Field>
                </Row>
              </FormGroup>
              {showTagSelector && (
                <Field name="tags">
                  {({ input, meta }) => (
                    <FormGroup>
                      <Label>Tags</Label>
                      <DrugTagSelector {...input} isDisabled={disabled} isMulti />
                      {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                    </FormGroup>
                  )}
                </Field>
              )}
              <Field name="recommended_dosing" type="textarea">
                {({ input, meta }) => (
                  <FormGroup>
                    <Label for="recommended_dosing">{intl.formatMessage(messages.label_recommended_dosing)}</Label>
                    <Input autoComplete="off" disabled={disabled} {...input} />
                    {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
              <Field name="interactions" type="textarea">
                {({ input, meta }) => (
                  <FormGroup>
                    <Label for="interactions">{intl.formatMessage(messages.label_interactions)}</Label>
                    <Input autoComplete="off" disabled={disabled} {...input} />
                    {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
              <Field name="comments" type="textarea">
                {({ input, meta }) => (
                  <FormGroup>
                    <Label for="comments">{intl.formatMessage(messages.label_comments)}</Label>
                    <Input autoComplete="off" disabled={disabled} {...input} />
                    {meta.error && meta.touched && <div className="invalid-feedback d-flex">{meta.error}</div>}
                  </FormGroup>
                )}
              </Field>
              {error && <ErrorViewer error={error} />}
              {props.children && (
                <>{typeof props.children === 'function' ? props.children({ pristine }) : props.children}</>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};
