import { Nullable } from '../../components-ts/utils';
import { Signature } from './common';

export enum VitalType {
  BMI = 'bmi',
  BLOOD_PRESSURE = 'blood_pressure',
  WEIGHT = 'weight',
  HEIGHT = 'height',
  HEAD_CIRCUMFERENCE = 'head_circumference',
  MID_UPPER_ARM_CIRCUMFERENCE = 'mid_upper_arm_circumference',
  TEMPERATURE = 'temperature',
  HEART_RATE = 'heart_rate',
  RESPIRATORY_RATE = 'respiratory_rate',
  O2_SATURATION = 'o2_saturation',
}

export interface BloodPressure {
  id: Nullable<string>;
  type: VitalType.BLOOD_PRESSURE;
  systolic: number;
  diastolic: number;
  updated: Nullable<Signature>;
}

export interface Weight {
  id: Nullable<string>;
  type: VitalType.WEIGHT;
  kg: number;
  updated: Nullable<Signature>;
}

export interface Height {
  id: Nullable<string>;
  type: VitalType.HEIGHT;
  cm: number;
  updated: Nullable<Signature>;
}

export interface Bmi {
  id: Nullable<string>;
  type: VitalType.BMI;
  bmi: number;
  updated: Nullable<Signature>;
}

export interface HeadCircumference {
  id: Nullable<string>;
  type: VitalType.HEAD_CIRCUMFERENCE;
  cm: number;
  updated: Nullable<Signature>;
}

export interface MidUpperArmCircumference {
  id: Nullable<string>;
  type: VitalType.MID_UPPER_ARM_CIRCUMFERENCE;
  cm: number;
  updated: Nullable<Signature>;
}

export interface Temperature {
  id: Nullable<string>;
  type: VitalType.TEMPERATURE;
  degrees_c: number;
  location: Nullable<string>;
  method: Nullable<string>;
  updated: Nullable<Signature>;
}

export interface HeartRate {
  id: Nullable<string>;
  type: VitalType.HEART_RATE;
  bpm: number;
  updated: Nullable<Signature>;
}

export interface RespiratoryRate {
  id: Nullable<string>;
  type: VitalType.RESPIRATORY_RATE;
  breaths_per_minute: number;
  updated: Nullable<Signature>;
}

export interface O2Saturation {
  id: Nullable<string>;
  type: VitalType.O2_SATURATION;
  percent: number;
  updated: Nullable<Signature>;
}

export type Measurement =
  | BloodPressure
  | Weight
  | Height
  | Bmi
  | HeadCircumference
  | MidUpperArmCircumference
  | Temperature
  | HeartRate
  | RespiratoryRate
  | O2Saturation;

export interface FullVitals {
  measurements: Array<Measurement>;
}
