export enum AgeCategories {
  NEW_BORN = 'newBorn',
  NEONATE = 'neonate',
  INFANT = 'infant',
  TODDLER = 'toddler',
  PRESCHOOLER = 'preschooler',
  SCHOOL_AGED_CHILD = 'schoolAgedChild',
  PREADOLESCENT = 'preadolescent',
  ADOLESCENT_13_15 = 'adolescent13to15',
  ADOLESCENT_16_19 = 'adolescent16to19',
  ADULT = 'adult',
}

export enum Sex {
  FEMALE = 'Female',
  MALE = 'Male',
}

export const isValidAgeCategory = (value: string): boolean => {
  return (Object.values(AgeCategories) as Array<string>).includes(value);
};

export const isValidSex = (value: string): boolean => {
  return (Object.values(Sex) as Array<string>).includes(value);
};

type JustPatientName = {
  first_name: string;
  last_name: string;
};

export const getPatientName = (patient: JustPatientName): string => {
  const patientName = `${patient.first_name} ${patient.last_name}`;

  return patientName;
};
