import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select'
import { FormGroup, Label } from 'reactstrap'
import { injectIntl, defineMessages } from 'react-intl'
import { TranslationWrapper as T } from 'components-ts/Translations'
import { CUSTOM_FIELD_PRIMITIVE_TYPE, CUSTOM_FIELD_NON_PRIMITIVE_TYPE } from 'wd-common/src/logic/health-systems/definitions'
const debug = require('debug')('wd:CustomFieldTypeSelector')

// static translations
const messages = defineMessages({
  fieldType: {
    id: 'CustomFieldTypeSelector.type_label',
    defaultMessage: 'Type'
  },
  booleanType: {
    id: 'CustomFieldTypeSelector.boolean_label',
    defaultMessage: 'Yes/No'
  },
  textType: {
    id: 'CustomFieldTypeSelector.text_type',
    defaultMessage: 'Text'
  },
  numberType: {
    id: 'CustomFieldTypeSelector.number_type',
    defaultMessage: 'Number'
  },
  dateType: {
    id: 'CustomFieldTypeSelector.date_label',
    defaultMessage: 'Date'
  },
  textListType: {
    id: 'CustomFieldTypeSelector.text_list_label',
    defaultMessage: 'Text list'
  }
})

/**
 * It allows the user to select a field type in the
 * custom registration fields tab
 * @component
 * @example
 *
 * const onChange = (customFieldType) => debug(customFieldType)
 * return (
 *   <CustomFieldTypeSelector
 *      value={customFieldType}
 *      onChange={onChange}
 *   />
 * )
 */
const CustomFieldTypeSelector = (props) => {
  const {
    value: _type,
    onChange,
    className,
    intl
  } = props

  const { formatMessage } = intl

  if (!_type) debug('Missing prop: type')
  if (typeof onChange !== 'function') debug('Missing prop: onChange')

  const formatOption = useCallback(type => {
    switch (type) {
      case CUSTOM_FIELD_PRIMITIVE_TYPE.BOOLEAN:
        return {
          value: type,
          label: <T id={messages.booleanType.id}>{ formatMessage(messages.booleanType)}</T>
        }

      case CUSTOM_FIELD_PRIMITIVE_TYPE.NUMBER:
        return {
          value: type,
          label: <T id={messages.numberType.id}>{ formatMessage(messages.numberType)}</T>
        }

      case CUSTOM_FIELD_PRIMITIVE_TYPE.TEXT:
        return {
          value: type,
          label: <T id={messages.textType.id}>{ formatMessage(messages.textType)}</T>
        }

      case CUSTOM_FIELD_PRIMITIVE_TYPE.DATE:
        return {
          value: type,
          label: <T id={messages.dateType.id}>{ formatMessage(messages.dateType)}</T>
        }
      case CUSTOM_FIELD_NON_PRIMITIVE_TYPE.TEXT_LIST:
        return {
          value: type,
          label: <T id={messages.textListType.id}>{ formatMessage(messages.textListType)}</T>
        }
      default:
        return ''
    }
  }, [formatMessage])

  const options = Object.values({ ...CUSTOM_FIELD_PRIMITIVE_TYPE, ...CUSTOM_FIELD_NON_PRIMITIVE_TYPE }).map(formatOption)

  const initialValue = _type ? formatOption(_type) : ''

  const [type, setType] = useState(initialValue)

  const _onChange = (val) => {
    setType(val)
    if (typeof onChange === 'function') onChange((val || {}).value)
  }

  useEffect(() => {
    setType(_type
      ? formatOption(_type)
      : ''
    )
  }, [_type, formatOption])
  debug(type)
  return <FormGroup>
    <Label><T id={messages.fieldType.id}>{formatMessage(messages.fieldType)}</T></Label>
    <Select
      value={type}
      className={`dropdownZIndex ${className}`}
      onChange={_onChange}
      options={options}
      isSearchable
    />
  </FormGroup>
}

CustomFieldTypeSelector.defaultProps = {
  value: '',
  onChange: () => {}
}
export default injectIntl(CustomFieldTypeSelector)
