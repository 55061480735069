import React from 'react';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { defineMessages, useIntl } from 'react-intl';
import { StudyItemEditor } from 'components/Study';
import { PAGE_STYLE_A4_LANDSCAPE, PrintButton } from 'components-ts/PrintButton';
import { DynamicTranslation } from 'components/DynamicTranslation';
import { BasicPatient, FullStudyWithOrder, Signature } from 'api/interfaces';
import { OrderComments } from 'components-ts/ActiveVisit/Orders';
import { PrintHeader } from 'components-ts/PrintHeader';

const messages = defineMessages({
  noteLabel: {
    id: 'UI.note_label',
    defaultMessage: 'Notes',
  },
  results: {
    id: 'StudyEditor.results',
    defaultMessage: 'Results',
  },
  commentsTitle: {
    id: 'StudyEditor.comments_title',
    defaultMessage: 'Comments',
  },
  completedBy: {
    id: 'StudyEditor.completed_by',
    defaultMessage: 'Completed by',
  },
  orderedBy: {
    id: 'StudyEditor.ordered_by',
    defaultMessage: 'Ordered by',
  },
});

interface StudyPrintButtonProps {
  study: any;
  patient: BasicPatient;
  className?: string;
}

export const StudyPrintButton: React.FC<StudyPrintButtonProps> = (props) => {
  const { study, patient, className = '' } = props;

  const ref = React.useRef(null);

  if (!study.completed) {
    return null;
  }

  return (
    <>
      <div className={`printable better-print ${className}`} ref={ref}>
        <div className="d-flex flex-column w-100">
          <PrintHeader patient={patient} locationId={study.location} />
          <StudyPrint study={study} />
        </div>
      </div>
      <PrintButton componentToPrint={ref} pageStyle={PAGE_STYLE_A4_LANDSCAPE} />
    </>
  );
};

interface CompletedByProps {
  signature: Signature;
  className?: string;
}

const PlainSignature: React.FC<CompletedByProps> = (props) => {
  const { signature, className } = props;

  const intl = useIntl();

  const givenName = (signature || {}).given_name;
  const familyName = (signature || {}).family_name;
  const timestamp = (signature || {}).timestamp;

  const formattedName = `${givenName} ${familyName}`;

  const date = intl.formatDate(timestamp, { dateStyle: 'long', timeStyle: 'short' });
  return <span className={className ?? ''}>{`${formattedName} - ${date}`}</span>;
};

type StudyPrintProps = {
  study: FullStudyWithOrder;
};

export const StudyPrint = React.forwardRef<HTMLDivElement, StudyPrintProps>((props, ref) => {
  const { study } = props;

  const intl = useIntl();

  const { name, description, created, completed } = study;

  return (
    <div ref={ref} className="printable better-print w-100">
      <div className="w-100 py-4 px-5">
        <div>
          {name && (
            <h3 className="m-0">
              <DynamicTranslation text={name.text} />
            </h3>
          )}
          {description && (
            <p className="text-muted mb-1">
              <DynamicTranslation text={description.text} />
            </p>
          )}
          {created && (
            <div className="d-flex align-items-center mt-2">
              <T id={messages.orderedBy.id}>{intl.formatMessage(messages.orderedBy)}</T>
              {':'}
              <PlainSignature className="ml-1" signature={created} />
            </div>
          )}
          {completed && (
            <div className="d-flex align-items-center">
              <T id={messages.completedBy.id}>{intl.formatMessage(messages.completedBy)}</T>
              {':'}
              <PlainSignature className="ml-1" signature={completed} />
            </div>
          )}
        </div>
        <h5 className="mt-4">
          <b>
            <T id={messages.results.id}>{intl.formatMessage(messages.results)}</T>
          </b>
        </h5>
        <div className="row">
          {(study.items || []).map((item) => (
            <div className="col-6 my-1 font-weight-bold" key={item.id}>
              <StudyItemEditor item={item} studyId={study.studyId} />
            </div>
          ))}
        </div>
        {study.note && (
          <div className="my-1">
            <b>
              <T id={messages.noteLabel.id}>{intl.formatMessage(messages.noteLabel)}</T>
            </b>
            <p className="m-0">{study.note}</p>
          </div>
        )}
        {study.order && study.order.comments && study.order.comments.length > 0 && (
          <div>
            <h5 className="border-bottom m-1">
              <T id={messages.commentsTitle.id}>
                {intl.formatMessage(messages.commentsTitle)} ({study.order.comments.length})
              </T>
            </h5>
            {Array.isArray(study.order.comments) && <OrderComments comments={study.order.comments} />}
          </div>
        )}
      </div>
    </div>
  );
});
