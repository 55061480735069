import { useLayoutEffect, useState } from 'react'

// this hooks receives a media query: string and returns true if that query match
// example
// const isMobile = useMediaQuery('(max-width: 480px)');
function useMediaQuery (mediaQuery) {
  // initial value
  const initialValue = window.matchMedia(mediaQuery).matches
  const [matches, setMatches] = useState(initialValue)

  useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    // listener
    const listener = (e) => setMatches(e.matches)
    // set listeners
    mediaQueryList.addListener(listener)
    // remove listener
    return () => mediaQueryList.removeListener(listener)
  }, [mediaQuery])

  return matches
}
export default useMediaQuery

// bootstrap breakpoints
//
// <540px   xs
// 540px    sm
// 720px    md
// 960px    lg
// 1140px   xl
