import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { FaCircle as StepIcon } from 'react-icons/fa';
import { LoadingInline } from 'components-ts/Loading';

const messages = defineMessages({
  next: {
    id: 'UI.button_next',
    defaultMessage: 'Next',
  },
  previousAbbr: {
    id: 'UI.button_previous_abbr',
    defaultMessage: 'Prev',
  },
  finish: {
    id: 'UI.button_finish',
    defaultMessage: 'Finish',
  },
});

export type FooterProps = {
  next: () => void;
  prev: () => void;
  current: number;
  count: number;
  finishing: boolean;
  isNextLoading?: boolean;
  isStepLoading?: boolean;
};

export const Footer: React.FC<FooterProps> = (props) => {
  const { next, prev, current, count, finishing, isNextLoading, isStepLoading } = props;

  const intl = useIntl();

  const disableButtons = finishing || isNextLoading || isStepLoading;

  return (
    <div className="row mt-4">
      <div className="d-flex justify-content-start col-3">
        {current > 0 && (
          <Button onClick={prev} className="btn-secondary" disabled={disableButtons}>
            <T id={messages.previousAbbr.id}>{intl.formatMessage(messages.previousAbbr)}</T>
          </Button>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center col-6">
        {Array.from(Array(count).keys()).map((i) => (
          <span key={i} className={`mx-1 ${i === current ? '' : 'text-muted'}`}>
            <StepIcon fontSize="0.5rem" />
          </span>
        ))}
      </div>
      <div className="d-flex justify-content-end col-3">
        <Button
          type="submit"
          className="d-flex align-items-center"
          onClick={next}
          color="primary"
          disabled={disableButtons}
        >
          {current + 1 === count ? (
            <T id={messages.finish.id}>{intl.formatMessage(messages.finish)}</T>
          ) : (
            <T id={messages.next.id}>{intl.formatMessage(messages.next)}</T>
          )}
          {(isNextLoading || finishing) && <LoadingInline className="ml-1" />}
        </Button>
      </div>
    </div>
  );
};
