import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TranslationWrapper as T } from 'components-ts/Translations';
import { useSchedulerContext } from './useSchedulerContext';
import { CreateRoomEventTab, UpdateRoomEventTab } from './RoomEventTab';
import { CreateScheduleVisitTab, UpdateScheduleVisitTab } from './ScheduledVisitTab';
import { Nullable } from 'components-ts/utils';
import { calendarEventToRoomEvent, calendarEventToScheduledVisit } from './utils';
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

const messages = defineMessages({
  createEvent: {
    id: 'TelehealthTab.button_create_an_event',
    defaultMessage: 'Create event',
  },
  updateEvent: {
    id: 'TelehealthTab.button_update_an_event',
    defaultMessage: 'Update event',
  },
  scheduledVisit: {
    id: 'EventViewer.scheduled_viewer',
    defaultMessage: 'Scheduled Visit',
  },
  roomEvent: {
    id: 'EventViewer.personal_appointment',
    defaultMessage: 'Personal appointment',
  },
});

interface CreateEventModalProps {
  roomId: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  onClose: () => void;
  onCompleted?: () => void;
}

enum TABS {
  SCHEDULED_VISIT = 'scheduledVisit',
  ROOM_EVENT = 'roomEvent',
}

export const CreateEventModal: React.FC<CreateEventModalProps> = (props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = React.useState<TABS>(TABS.SCHEDULED_VISIT);

  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>{intl.formatMessage(messages.createEvent)}</ModalHeader>
      <ModalBody py={0}>
        <Nav tabs>
          <NavItem>
            <NavLink
              active={activeTab === TABS.SCHEDULED_VISIT}
              className={activeTab === TABS.SCHEDULED_VISIT ? 'active' : ''}
              onClick={() => {
                setActiveTab(TABS.SCHEDULED_VISIT);
              }}
            >
              <T id={messages.scheduledVisit.id}>{intl.formatMessage(messages.scheduledVisit)}</T>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === TABS.ROOM_EVENT}
              className={activeTab === TABS.ROOM_EVENT ? 'active' : ''}
              onClick={() => {
                setActiveTab(TABS.ROOM_EVENT);
              }}
            >
              <T id={messages.roomEvent.id}>{intl.formatMessage(messages.roomEvent)}</T>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className={'border-top-0 mt-0'}>
          <TabPane tabId={TABS.SCHEDULED_VISIT} className="p-2 px-3">
            <CreateScheduleVisitTab {...props} />
          </TabPane>
          <TabPane tabId={TABS.ROOM_EVENT} className="p-2 px-3">
            <CreateRoomEventTab {...props} />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

interface UpdateEventModalProps {
  roomId: string;
  eventId: string;
  start: Nullable<Date>;
  end: Nullable<Date>;
  onClose: () => void;
}

export const UpdateEventModal: React.FC<UpdateEventModalProps> = (props) => {
  const { eventId } = props;

  const { events } = useSchedulerContext();

  const event = events.find((event) => event.id === eventId);
  const isScheduledVisit = event?.extendedProps?.isScheduledVisit;

  const intl = useIntl();

  if (!event) {
    return null;
  }

  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>{intl.formatMessage(messages.updateEvent)}</ModalHeader>
      <ModalBody>
        {isScheduledVisit ? (
          <UpdateScheduleVisitTab {...props} event={calendarEventToScheduledVisit(event)} />
        ) : (
          <UpdateRoomEventTab {...props} event={calendarEventToRoomEvent(event)} />
        )}
      </ModalBody>
    </Modal>
  );
};

export type EventModalProps = UpdateEventModalProps | CreateEventModalProps;
const isUpdateModalProps = (props: EventModalProps): props is UpdateEventModalProps => {
  return 'eventId' in props;
};

export const EventModal: React.FC<EventModalProps> = (props) => {
  if (isUpdateModalProps(props)) {
    return <UpdateEventModal {...props} />;
  }

  return <CreateEventModal {...props} />;
};
