import parsePhoneNumber, { isValidPhoneNumber as isValidPhoneNumberLib, CountryCode } from 'libphonenumber-js';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import { Nullable } from 'components-ts/utils';

export type ValidationError<T> = {
  [K in keyof T]?: string;
};

export type ValidateFn<T> = (values: T) => ValidationError<T> | Promise<ValidationError<T>>;

export const isSecure = async (value: string, userInputs?: Array<string>) => {
  const options = {
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      userInputs: userInputs ?? [],
    },
  };

  zxcvbnOptions.setOptions(options);

  const result = await zxcvbn(value);
  switch (result.score) {
    case 4:
    case 3:
      return true;

    case 2:
    case 1:
    case 0:
    default:
      return false;
  }
};

export const isValidEmail = (value: string) => {
  const EXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return EXP.test(value);
};

export const sanitizePhone = (value: string, country?: string): Nullable<string> => {
  const result = parsePhoneNumber(`${value}`, country as CountryCode);

  if (!result) {
    return null;
  }

  return result.format('E.164');
};

export const isValidPhoneNumber = (value: string, country?: string): boolean => {
  return isValidPhoneNumberLib(`${value}`, country as CountryCode);
};
